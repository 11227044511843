import useTimer from '@general/react-hooks/use-timer';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useNewChallengeAvailability } from 'hooks';

import validationSchema from './schema';

export const TwoFaContext = createContext({});

function TwoFaProvider({ twoFaData, children }) {
  const [ isNonceExpired, setIsNonceExpired ] = useState(false);
  const [ handleSubmitFunc, setHandleSubmitFunc ] = useState(() => () => null);

  useEffect(() => {
    startTimer();

    return stopTimer;
  }, []);

  const {
    codeExpirationSec,
    nonce,
    newChallengeAvailableAt,
    id: challengeId,
    scaId,
  } = twoFaData;

  const onSubmit = ({ nonce: nonceValue }) => {
    handleSubmitFunc(nonceValue);
  };

  const formik = useFormik({
    initialValues: { nonce },
    onSubmit,
    validationSchema,
    enableReinitialize: true,
  });

  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    setErrors,
    values,
    touched,
    errors,
    isSubmitting,
    setSubmitting,
    setFieldTouched,
  } = formik;

  const clearNonceInput = () => setFieldValue('nonce', '');

  const onTimeIsOver = useCallback(() => {
    setIsNonceExpired(true);
    clearNonceInput();
  }, []);
  const { remainingTime, play: startTimer, stop: stopTimer } = useTimer(codeExpirationSec, onTimeIsOver);
  const newChallenge = useNewChallengeAvailability({
    availableAt: newChallengeAvailableAt,
  });

  const initData = useMemo(() => ({
    handleSubmit,
    handleChange,
    setFieldValue,
    setErrors,
    values,
    touched,
    errors,
    isSubmitting,
    setSubmitting,
    startTimer,
    stopTimer,
    newChallenge,
    isNonceExpired,
    setIsNonceExpired,
    clearNonceInput,
    remainingTime,
    twoFaData,
    challengeId,
    scaId,
    setHandleSubmitFunc,
    setFieldTouched,
  }), [
    scaId,
    challengeId,
    clearNonceInput,
    errors,
    handleChange,
    handleSubmit,
    isNonceExpired,
    isSubmitting,
    newChallenge,
    remainingTime,
    setErrors,
    setFieldValue,
    setSubmitting,
    startTimer,
    stopTimer,
    touched,
    values,
    twoFaData,
    setFieldTouched,
  ]);

  return (
    <TwoFaContext.Provider value={initData}>
      {children}
    </TwoFaContext.Provider>
  );
}

TwoFaProvider.propTypes = {
  children: PropTypes.node.isRequired,
  twoFaData: PropTypes.shape({
    codeExpirationSec: PropTypes.number,
    nonce: PropTypes.string,
    truncatedPhoneNumber: PropTypes.string,
    id: PropTypes.string,
    scaId: PropTypes.string,
    newChallengeAvailableAt: PropTypes.number,
  }),
};

export default TwoFaProvider;
