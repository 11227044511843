import React, { memo, useContext, useEffect, useState } from 'react';

import { TransactionAmount, TransactionIcon, TransactionTo } from 'components';
import { TransactionContext } from 'contexts/transaction';
import { transactionActivityShape } from 'local-prop-types';

import S from './styles';
import TransactionFrom from '../transaction-from';

function Transaction({
  createdAt,
  createdBy,
  fromAccount,
  toAccount,
  debitedAmount,
  id,
  netTransactionAmount,
  sourceCurrency,
  status,
  statusReasons,
  targetCurrency,
  isMultiCurrencyTransaction,
  type,
}) {
  const { setActiveTransactionById, activeTransaction } = useContext(TransactionContext);
  const [ isActive, setIsActive ] = useState(false);

  useEffect(() => {
    setIsActive(Boolean(activeTransaction?.id === id));
  }, [activeTransaction]);

  return (
    <S.TransactionActivityRow
      isActive={isActive}
      data-testid="transaction-activity-row"
      onClick={() => setActiveTransactionById(id)}
    >
      <S.TransactionIconWrapper data-testid="transaction-icon-wrapper">
        <TransactionIcon type={type} isActive={isActive} status={status} />
      </S.TransactionIconWrapper>

      <S.TransactionFromWrapper data-testid="transaction-from-wrapper">
        <TransactionFrom sourceName={fromAccount.name} createdAt={createdAt} createdBy={createdBy.name} />
      </S.TransactionFromWrapper>

      <S.TransactionToWrapper data-testid="transaction-to-wrapper">
        <TransactionTo
          targetName={toAccount.name}
          status={status}
          type={type}
          statusReasons={statusReasons}
          createdAt={createdAt}
          createdBy={createdBy.name}
          showDetailsOnMobile
        />
      </S.TransactionToWrapper>

      <div>
        <TransactionAmount
          debitedAmount={debitedAmount}
          netTransactionAmount={netTransactionAmount}
          sourceCurrency={sourceCurrency}
          targetCurrency={targetCurrency}
          isMultiCurrencyTransaction={isMultiCurrencyTransaction}
          type={type}
        />
      </div>
    </S.TransactionActivityRow>
  );
}

Transaction.propTypes = transactionActivityShape;

export default memo(Transaction);
