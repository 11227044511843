import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { dataTestIds } from 'pages/api/constants';
import { ModalContent } from 'components';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';

import S from './styles';

export function DeleteModal({ isOpen, setIsOpen, apiKey, onSubmit }) {
  const { t } = useTranslation();
  const CTAButtons = (
    <>
      <S.Button
        data-testid={dataTestIds.removeSubmitButton}
        label="Delete"
        category="primary"
        isDanger
        icon={<TrashIcon />}
        type="submit"
        onClick={() => onSubmit(apiKey.id)}
      />
      <S.Button label="Cancel" category="secondary" onClick={() => setIsOpen(false)} />
    </>
  );

  return (
    <ModalContent title="Delete API key" hasCloseIcon isOpen={isOpen} CTAButtons={CTAButtons} setIsOpen={setIsOpen}>
      <S.Content>
        {t('api.table.deleteModalConfirmation', { apiKeyName: apiKey.name })}
      </S.Content>
    </ModalContent>
  );
}

DeleteModal.propTypes = {
  apiKey: PropTypes.instanceOf(Object),
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
