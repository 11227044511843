import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { resetPin } from 'api/card';
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';
import { AlertBlock, ModalContent } from 'components';
import { cardActivationSuccessMessage } from 'constants/card';
import { showGeneralToastError } from 'utils/errors';

import S from './styles';

function ResetModalPin({ id, isOpen, setIsOpen, setCard }) {
  const { t } = useTranslation();

  const onSubmit =  async () => {
    try {
      const { data } = await resetPin(id);
      setCard(data);
      toast.success(cardActivationSuccessMessage);
    } catch (error) {
      showGeneralToastError(error);
    } finally {
      setIsOpen(false);
    }
  };

  const CTAButtons = (
    <>
      <S.Button label={t('cards.retryPinEntry.reset')} category="primary" type="submit" onClick={onSubmit} />
      <S.Button label={t('cards.retryPinEntry.cancel')} category="secondary" onClick={() => setIsOpen(false)} />
    </>
  );

  return (
    <ModalContent isOpen={isOpen} setIsOpen={setIsOpen} title={t('cards.retryPinEntry.title')} hasCloseIcon CTAButtons={CTAButtons}>
      <AlertBlock icon={WarningIcon} text={t('cards.retryPinEntry.text')} />
    </ModalContent>
  );
}

ResetModalPin.propTypes = {
  id: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setCard: PropTypes.func.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default ResetModalPin;
