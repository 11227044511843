import {
  Button as DefaultButton,
  Input as IntergiroInput,
  PhoneInput as IntergiroPhoneInput,
} from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';
import { hex2rgba } from 'helpers/styles';

import { formFields } from './constants';

const Wrapper = styled.div`
  max-width: 32.5rem;
  padding: 0.625rem 1.25rem 1.25rem;

  @media ${DEVICE.tablet} {
    padding-left: 0;
  }
`;

const Heading = styled.h3`
  font: ${props => props.theme.fonts.bodyLSemi};

  @media ${DEVICE.tablet} {
    font-size: 1.375rem;
  }
`;

const Button = styled(DefaultButton)`
  margin-top: 1.875rem;
  margin-right: 0;
  min-width: fit-content;
  padding: 0.75rem 1.5rem;
  width: 100%;

  @media ${DEVICE.tablet} {
    margin-right: 0.625rem;
    width: auto;
  }
`;

const Input = styled(IntergiroInput)`
  margin-top: 1.25rem;
  margin-bottom: ${props => props.name === formFields.newPassword.name ? '1.25rem' : '0'};
`;

const PhoneInput = styled(IntergiroPhoneInput)`
  .react-tel-input .form-control {
    background: ${props => props.theme.colors.greyBright};
    border-color: transparent;
  }
`;

const ConfirmPasswordInput = styled(Input)`
  @media ${DEVICE.tablet} {
    margin: 0;
  }
`;

const InputContainer = styled.div`
  @media ${DEVICE.tablet} {
    padding: 1.25rem 0;
  }
`;

const ModalConfirmationHeading = styled(Heading)`
  font: ${props => props.theme.fonts.bodyL};
  margin: 1.25rem 0 1.875rem;

  @media ${DEVICE.tablet} {
    font: ${props => props.theme.fonts.h4Bold};
    line-height: ${34 / 24};
    margin-bottom: 1.25rem;
    max-width: 26.75rem;
  }
`;

const ModalApproveButton = styled(Button)`
  margin-bottom: 1rem;
  margin-top: 0;

  @media ${DEVICE.tablet} {
    margin: 0 0.625rem 0;
    min-width: auto;
    width: 7.375rem;
  }
`;

const ModalCancelButton = styled(Button)`
  margin: 0;

  @media ${DEVICE.tablet} {
    min-width: auto;
    width: 6.875rem;
  }
`;

const ModalButtonWrapper = styled.div`
  align-items: center;
  border-top: 0.0625rem solid ${props => hex2rgba(props.theme.colors.black, 0.1)};
  display: flex;
  flex-direction: column;
  padding: 1.25rem 0;
  width: 100%;

  @media ${DEVICE.tablet} {
    border: none;
    flex-direction: row;
    padding: 0 1.25rem;
  }
`;

const Form = styled.form`
  margin-top: 2.5rem;

  @media ${DEVICE.tablet} {
    margin-top: 3.75rem;
  }
`;

export default {
  Button,
  ConfirmPasswordInput,
  Heading,
  Input,
  InputContainer,
  ModalApproveButton,
  ModalButtonWrapper,
  ModalCancelButton,
  ModalConfirmationHeading,
  PhoneInput,
  Wrapper,
  Form,
};
