import bff from 'utils/bff';

export async function isSepaEnabledIban(iban) {
  try {
    const { data } = await bff.lookup.isSepaEnabledIban({}, { iban });

    return { data };
  } catch (error) {
    return { error };
  }
}
