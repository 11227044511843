import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { dataTestIds } from 'pages/api/constants';
import { UserContext } from 'contexts/user';
import { defaultDateFormat } from 'constants/date-time';
import { ReactComponent as RenewIcon } from 'assets/icons/renew.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { Dot, EmptyState, Hint } from 'components';
import { DAYS_BEFORE_API_KEY_EXPIRATION, tableRows } from 'constants/api';
import { navigation } from 'constants/navigation';
import { checkIfExpired, checkIfExpireSoon } from 'utils/date-time';

import S from './styles';
import { RenewModal } from '../renew-modal';
import { DeleteModal } from '../delete-modal';

function APIKeysTable({ data, removeApiKey, renewApiKey }) {
  const { t } = useTranslation();
  const { permissions: { canUpdateApiKeys, canDeleteApiKeys } } = useContext(UserContext);
  const [ renewModalOpen, setRenewModalOpen ] = useState(false);
  const [ deleteModalOpen, setDeleteModalOpen ] = useState(false);
  const [ chosenApiKey, setChosenApiKey ] = useState({});
  const currentDate = dayjs().utc();

  const handleRenewSubmit = async id => {
    await renewApiKey(id);
    setRenewModalOpen(false);
  };

  const openRenewModal = apiKey => () => {
    setChosenApiKey(apiKey);
    setRenewModalOpen(true);
  };

  const handleDeleteSubmit = async id => {
    await removeApiKey(id);
    setDeleteModalOpen(false);
  };

  const openDeleteModal = apiKey => () => {
    setChosenApiKey(apiKey);
    setDeleteModalOpen(true);
  };

  const getDaysBeforeExpiration = date => {
    const days = Math.abs(currentDate.diff(date, 'days'));

    return `${days + 1  } ${  days < 1 ? 'day' : 'days'}`;
  };

  useEffect(() => {
    if (!renewModalOpen && !deleteModalOpen) setChosenApiKey({});
  }, [ renewModalOpen, deleteModalOpen ]);

  return (
    <>
      <S.Table>
        <S.Header>
          <S.Section name={tableRows.name}>
            <S.HeaderLabel>
              {t('api.table.name')}
            </S.HeaderLabel>
          </S.Section>
          <S.Section name={tableRows.creationDate}>
            <S.HeaderLabel>
              {t('api.table.creationDate')}
            </S.HeaderLabel>
          </S.Section>
          <S.Section name={tableRows.freezeDate}>
            <S.HeaderLabel>
              {t('api.table.freezeDate')}
            </S.HeaderLabel>
          </S.Section>
          <S.Section />
        </S.Header>
        <S.Content>
          {data.length ? data.map(apiKey => (
            <S.Row key={apiKey.id}>
              <S.Section name={tableRows.name}>
                <S.Text>
                  {apiKey.name}
                </S.Text>
                <S.Subtext>
                  {apiKey.permissions.join(', ')}
                </S.Subtext>
                <S.Subtext mobileOnly>
                  {dayjs(apiKey.expiresAt).format(defaultDateFormat)}
                  {checkIfExpireSoon(currentDate, apiKey.expiresAt, DAYS_BEFORE_API_KEY_EXPIRATION) && (
                    <>
                      <Dot />
                      <S.Subtext isWarning>
                        {t('api.table.lapsesIn')}
                        {' '}
                        {getDaysBeforeExpiration(apiKey.expiresAt)}
                      </S.Subtext>
                    </>
                  )}
                </S.Subtext>
              </S.Section>
              <S.Section name={tableRows.creationDate}>
                <S.Text>
                  {dayjs(apiKey.createdAt).format(defaultDateFormat)}
                </S.Text>
              </S.Section>
              <S.Section name={tableRows.freezeDate}>
                <S.Text>
                  {dayjs(apiKey.expiresAt).format(defaultDateFormat)}
                </S.Text>
                {checkIfExpireSoon(currentDate, apiKey.expiresAt, DAYS_BEFORE_API_KEY_EXPIRATION) ? (
                  <S.Subtext isWarning>
                    {t('api.table.lapsesIn')}
                    {' '}
                    {getDaysBeforeExpiration(apiKey.expiresAt)}
                    .
                    {' '}
                    <br />
                    {t('api.table.renewMessage')}
                  </S.Subtext>
                ) : !checkIfExpired(currentDate, apiKey.expiresAt) && (
                  <S.Subtext>
                    {getDaysBeforeExpiration(apiKey.expiresAt)}
                  </S.Subtext>
)}
              </S.Section>
              <S.Section>
                <S.Actions>
                  {checkIfExpired(currentDate, apiKey.expiresAt) && (
                    <Hint text={t('api.table.expiredBadgeHint')}>
                      <S.Badge data-testid={dataTestIds.badge}>
                        {t('api.table.expiredBadge')}
                      </S.Badge>
                    </Hint>
                  )}
                  {canUpdateApiKeys && (
                    <>
                      <S.RenewLink data-testid={dataTestIds.renewModalButton} onClick={openRenewModal(apiKey)}>
                        {t('api.table.renewAccess')}
                      </S.RenewLink>
                      <S.IconButton onClick={openRenewModal(apiKey)} renew>
                        <RenewIcon />
                      </S.IconButton>
                    </>
                  )}
                  {canDeleteApiKeys && (
                    <S.IconButton data-testid={dataTestIds.removeModalButton} onClick={openDeleteModal(apiKey)}>
                      <TrashIcon />
                    </S.IconButton>
                  )}
                </S.Actions>
              </S.Section>
            </S.Row>
          )) : (
            <EmptyState
              title={t('api.table.emptyState.title')}
              subTitle={(
                <span data-testid={dataTestIds.emptyState}>
                  <Trans
                    i18nKey="api.table.emptyState.subTitle"
                  >
                    Click on
                    <S.Link to={navigation.createApiKey}>Create new key</S.Link>
                    {' '}
                    to create a new key.
                  </Trans>
                </span>
              )}
            />
)}
        </S.Content>
      </S.Table>
      <RenewModal
        isOpen={renewModalOpen}
        setIsOpen={setRenewModalOpen}
        apiKey={chosenApiKey}
        hasStickyFooter
        onSubmit={handleRenewSubmit}
      />
      <DeleteModal
        isOpen={deleteModalOpen}
        setIsOpen={setDeleteModalOpen}
        apiKey={chosenApiKey}
        hasStickyFooter
        onSubmit={handleDeleteSubmit}
      />
    </>
  );
}

APIKeysTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    expiresAt: PropTypes.string.isRequired,
    permissions: PropTypes.instanceOf(Array),
  })),
  renewApiKey: PropTypes.func.isRequired,
  removeApiKey: PropTypes.func.isRequired,
};

export default APIKeysTable;
