import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useHistory } from 'react-router';

import { ControlSet } from 'components';
import { navigation } from 'constants/navigation';
import { AccountsContext } from 'contexts/accounts';
import { UserContext } from 'contexts/user';

import { canCreateBulkPayments, canCreateMovement } from './helper';
import S from './styles';

function getActivityButtons(account, accounts) {
  return [
    {
      label: 'Move',
      to: account ? `${navigation.moveFunds}/${account.id}` : navigation.moveFunds,
      icon: <S.ArrowRightIcon />,
      enable: canCreateMovement(accounts),
    },
    {
      label: 'Send',
      to: account ? `${navigation.sendFunds}/${account.id}` : navigation.sendFunds,
      icon: <S.ArrowRightTopIcon />,
      enable: true,
    },
    {
      label: 'Bulk payment',
      to: account ? `${navigation.bulkPayments}/${account.id}` : navigation.bulkPayments,
      icon: <S.BulkPaymentsIcon />,
      enable: canCreateBulkPayments(account, accounts),
      isHiddenOnMobile: true,
    },
  ];
}

function ActivityButtons({ account }) {
  const history = useHistory();
  const { accounts } = useContext(AccountsContext);
  const { permissions: { canCreateTransaction } } = useContext(UserContext);

  const buttons = canCreateTransaction
    ? getActivityButtons(account, accounts)
      .filter(button => button.enable)
      .map(button => ({ ...button, onClick: () => history.push(button.to) }))
    : [];

  return (
    <ControlSet
      controls={buttons}
    />
  );
}

ActivityButtons.propTypes = {
  account: PropTypes.instanceOf(Object),
};

export default ActivityButtons;
