import React from 'react';

import { ReactComponent as AccountsIcon } from 'assets/icons/accounts.svg';
import { ReactComponent as ActivityIcon } from 'assets/icons/activity.svg';
import { ReactComponent as APIIcon } from 'assets/icons/api.svg';
import { ReactComponent as BeneficiariesIcon } from 'assets/icons/beneficiaries.svg';
import { ReactComponent as CardsIcon } from 'assets/icons/cards.svg';
import { ReactComponent as MerchantsIcon } from 'assets/icons/merchants.svg';
import { ReactComponent as PaymentsIcon } from 'assets/icons/payments.svg';
import { ReactComponent as UsersIcon } from 'assets/icons/users.svg';
import { permissionsMap } from 'constants/permissions';
import { resourceType } from 'constants/resource';
import i18n from 'config/i18n';

export const navigation = {
  accounts: '/',
  account: '/accounts',
  activity: '/activity',
  api: '/api',
  beneficiaries: '/beneficiaries',
  bulkPayments: '/bulk-payments',
  cards: '/cards',
  company: '/company',
  createApiKey: '/create-api-key',
  inviteUser: '/invite-user',
  login: '/login',
  merchants: '/merchants',
  moveFunds: '/move-funds',
  newRole: '/roles/new',
  orderCards: '/order-cards',
  notFound: '/not-found',
  profile: '/profile',
  resetPassword: '/reset-password',
  roles: '/roles',
  sendFunds: '/send-funds',
  setPassword: '/set-password/:token',
  users: '/users',
  addRole: '/add-role',
  payments: '/payments',
  apiPayments: '/payments/api-payments',
  pendingApproval: '/payments/pending-approval',
};

export const resourceNavigationLinks = {
  [resourceType.account]: {
    title: i18n.t('resource.account'),
    linkTitle: i18n.t('navigation.navbar.accounts'),
    navigateTo: navigation.accounts,
  },
  [resourceType.beneficiary]: {
    title: i18n.t('resource.beneficiary'),
    linkTitle: i18n.t('navigation.navbar.beneficiaries'),
    navigateTo: navigation.beneficiaries,
  },
  [resourceType.card]: {
    title: i18n.t('resource.card'),
    linkTitle: i18n.t('navigation.navbar.cards'),
    navigateTo: navigation.cards,
  },
  [resourceType.cardOrder]: {
    title: i18n.t('resource.cardOrder'),
    linkTitle: i18n.t('navigation.navbar.cards'),
    navigateTo: navigation.cards,
  },
  [resourceType.merchant]: {
    title: i18n.t('resource.merchant'),
    linkTitle: i18n.t('navigation.navbar.merchants'),
    navigateTo: navigation.merchants,
  },
  [resourceType.user]: {
    title: i18n.t('resource.user'),
    linkTitle: i18n.t('navigation.navbar.users'),
    navigateTo: navigation.users,
  },
  [resourceType.role]: {
    title: i18n.t('resource.role'),
    linkTitle: i18n.t('roles.tabs.rolesAndPermissions'),
    navigateTo: navigation.roles,
  },
  [resourceType.apiPayment]: {
    title: i18n.t('resource.apiPayment'),
    linkTitle: i18n.t('pendingApproval.tabs.apiPayments'),
    navigateTo: navigation.apiPayments,
  },
  [resourceType.apiKey]: {
    title: i18n.t('resource.apiKey'),
    linkTitle: i18n.t('navigation.navbar.api'),
    navigateTo: navigation.api,
  },
};

export const navigationLinks = [
  {
    icon: <AccountsIcon />,
    label: i18n.t('navigation.navbar.accounts'),
    to: navigation.accounts,
    permissions: {
      list: [permissionsMap.corporateWalletsManagementRead],
      atLeastOne: true,
    },
    extra: [`${navigation.account}/:id`],
  },
  {
    icon: <BeneficiariesIcon />,
    label: i18n.t('navigation.navbar.beneficiaries'),
    to: navigation.beneficiaries,
    permissions: {
      list: [permissionsMap.transactionsManagementCreateTransfer],
      atLeastOne: false,
    },
    extra: [`${navigation.beneficiaries}/:id`],
  },
  {
    count: true,
    icon: <PaymentsIcon />,
    label: i18n.t('navigation.navbar.payments'),
    to: navigation.payments,
    permissions: {
      list: [ permissionsMap.transactionsManagementRead, permissionsMap.transactionsManagementApproveTransfer ],
      atLeastOne: false,
    },
    extra: [ navigation.pendingApproval, navigation.apiPayments ],
  },
  {
    extra: ['/cards/:id'],
    icon: <CardsIcon />,
    label: i18n.t('navigation.navbar.cards'),
    to: navigation.cards,
    permissions: {
      list: [ permissionsMap.individualCardsManagementRead, permissionsMap.otherCardsManagementRead ],
      atLeastOne: true,
    },
  },
  {
    count: true,
    icon: <ActivityIcon />,
    label: i18n.t('navigation.navbar.activity'),
    permissions: {
      list: [permissionsMap.transactionsManagementRead],
      atLeastOne: true,
    },
    to: navigation.activity,
  },
  {
    extra: ['/merchants/:id'],
    icon: <MerchantsIcon />,
    label: i18n.t('navigation.navbar.merchants'),
    permissions: {
      list: [permissionsMap.corporateAccountsManagementRead],
      atLeastOne: true,
    },
    to: navigation.merchants,
  },
  {
    count: true,
    icon: <APIIcon />,
    label: i18n.t('navigation.navbar.api'),
    permissions: {
      list: [permissionsMap.corporateApiKeysManagementRead],
      atLeastOne: true,
    },
    to: navigation.api,
  },
  {
    icon: <UsersIcon />,
    label: i18n.t('navigation.navbar.users'),
    extra: [ '/roles', `${navigation.users}/:id`, `${navigation.users}/:id/edit` ],
    to: navigation.users,
    permissions: {
      list: [ permissionsMap.personsManagementRead, permissionsMap.personsRolesManagementRead ],
      atLeastOne: true,
    },
  },
];
