import { Avatar as DefaultAvatar } from '@general/intergiro-ui-kit';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { ReactComponent as MastercardIcon } from 'assets/icons/mastercard.svg';
import { ReactComponent as VisaIcon } from 'assets/icons/visa.svg';
import { CardStatus as CardStatusComponent } from 'components';
import { DEVICE } from 'constants/breakpoints';
import { hex2rgba } from 'helpers/styles';

const CardLink = styled(Link)`
  margin: 0 auto;

  @media ${DEVICE.tablet} {
    margin: 0;
  }
`;

const Card = styled.article`
  box-shadow: 0rem 0.3125rem 1rem ${props => hex2rgba(props.theme.colors.black, 0.05)},
    inset 0rem 0rem 0.0625rem ${props => hex2rgba(props.theme.colors.black, 0.3)};
  border-radius: 0.5rem;
  padding: 1.25rem;
  background: ${props => props.dimmed ? props.theme.colors.greyBright : props.theme.colors.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 10.5rem;
  width: 20.9375rem;

  @media ${DEVICE.laptop} {
    height: 11.25rem;
    width: 22.375rem;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: ${props => props.hasInnerRow ? 'inherit' : 'flex-end'};
  justify-content: space-between;
  flex-direction: ${props => props.hasInnerRow ? 'column' : 'row'};
`;

const InnerRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0.6875rem;
`;

const BalanceContainer = styled.div`
  color: ${props => props.dimmed ? props.theme.colors.greyMedium : props.theme.colors.black};
  font: ${props => props.theme.fonts.bodyS};
`;

const BalanceAmount = styled.p`
  font-weight: 600;
  display: inline-block;
  margin: 0;
`;

const BalanceCurrency = styled.p`
  display: inline-block;
  margin: 0;
  font-weight: 600;
`;

const Account = styled.p`
  margin: 0;
  color: ${props => props.theme.colors.greyMedium};
  font: ${props => props.theme.fonts.bodyXsSemi};
`;

const LastFour = styled.div`
  color: ${props => props.dimmed ? props.theme.colors.greyMedium : props.theme.colors.black};
  font: ${props => props.theme.fonts.bodyXsSemi};
  text-align: right;
  padding-right: 0.625rem;
  display: inline-block;
`;

const Visa = styled(VisaIcon)`
  fill: #15195A;
  width: 2.5rem;
  height: 0.8125rem;
`;

const Mastercard = styled(MastercardIcon)`
  width: 2rem;
  height: 1.25rem;
`;

const Expiry = styled.p`
  margin: 0;
  color: ${props => props.dimmed ? props.theme.colors.greyMedium : props.theme.colors.black};
  font: ${props => props.theme.fonts.bodyXsSemi};
  display: inline-block;
`;

const Status = styled.div`
  text-align: right;
`;

const User = styled.div`
  display: flex;
  align-items: center;
`;

const DetailsContainerBottom = styled.div`
  padding-top: 0.625rem;
`;

const Avatar = styled(DefaultAvatar)`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.3125rem;
  border-radius: 50%;
  overflow: hidden;
  opacity: ${props => props.dimmed ? '0.5' : '1'};

  > svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  > p {
    top: 50%;
    font-size: 0.625rem;
  }
`;

const Text = styled.p`
  margin: 0;
  color: ${props => props.dimmed ? props.theme.colors.greyMedium : props.theme.colors.black};
  font: ${props => props.theme.fonts.bodySSemi};
`;

const CardStatus = styled(CardStatusComponent)`
  font-size: .8125rem;
`;

const Brand = styled.div`
  display: flex;
  align-items: center;
`;

const FundingType = styled.p`
  margin: 0;
  padding-right: 0.625rem;
  color: ${props => props.dimmed ? props.theme.colors.greyMedium : props.theme.colors.black};
  font: ${props => props.theme.fonts.bodyXsSemi};
`;

export default {
  Card,
  Row,
  BalanceContainer,
  BalanceCurrency,
  Account,
  Expiry,
  Status,
  BalanceAmount,
  LastFour,
  Visa,
  Mastercard,
  DetailsContainerBottom,
  InnerRow,
  Avatar,
  Text,
  User,
  CardLink,
  CardStatus,
  Brand,
  FundingType,
};
