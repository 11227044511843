import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import { AccountsContext } from 'contexts/accounts';
import { useFilterAccounts } from 'hooks';
import DropdownFilterCheckbox from 'pages/activity/components/dropdown-filter-checkbox';
import DropdownFilterControl from 'pages/activity/components/dropdown-filter-control';
import { filterNames } from 'pages/activity/constants';
import { getActiveAccounts, getInvertedCheckboxList, getParameterFromURL, setFiltersToUnchecked } from 'utils/helpers';

import FilterDropdown from './dropdown';
import updateURLToMatchParameters from './helpers';
import { handleSelectAll } from '../utils';

function AccountsType() {
  const { t } = useTranslation();
  const history = useHistory();
  const { accounts } = useContext(AccountsContext);
  const ref = useRef(null);
  const { accounts: activeAccounts, setAccounts, selectedCount } = useFilterAccounts();
  const [ availableAccounts, setAvailableAccounts ] = useState();

  useEffect(() => {
    setAvailableAccounts(activeAccounts);
  }, [activeAccounts]);

  useEffect(() => {
    // This useEffect is waiting until the accounts API will resolve
    // to generate the list of the accounts that are clickable (to later on filter using the checkboxes).
    const selectedAccounts = getParameterFromURL(filterNames.accounts, history.location);
    const resolvedInitialAccountList = getActiveAccounts(accounts, selectedAccounts);
    setAccounts(resolvedInitialAccountList);
  }, [accounts]);

  const onChange = useCallback(event => {
    const selectedId = event.target.name;
    const updatedList = getInvertedCheckboxList(selectedId, availableAccounts);

    setAvailableAccounts(updatedList);
  }, [availableAccounts]);

  const onClear = useCallback(() => {
    const updatedFilter = setFiltersToUnchecked(activeAccounts);
    setAccounts(updatedFilter);
    setAvailableAccounts(updatedFilter);
    updateURLToMatchParameters(updatedFilter, filterNames.accounts, history);
    ref.current.closeDropdown();
  }, [ activeAccounts, filterNames, history ]);

  const onSelectAll = useCallback(() => {
    const allSelectedAccounts = handleSelectAll(availableAccounts);
    setAvailableAccounts(allSelectedAccounts);
  }, [availableAccounts]);

  const onApply = useCallback(() => {
    setAccounts(availableAccounts);
    updateURLToMatchParameters(availableAccounts, filterNames.accounts, history);

    ref.current.closeDropdown();
  }, [ activeAccounts, availableAccounts ]);

  return (
    <DropdownFilterControl
      ref={ref}
      label={(
        <FilterDropdown
          count={selectedCount}
          defaultLabel={t('components.filterBar.account.dropdown.defaultLabel')}
          name="account"
          selectedLabel={t(selectedCount > 1 ? 'components.filterBar.account.dropdown.selectedPluralLabel' : 'components.filterBar.account.dropdown.selectedLabel')}
        />
      )}
    >
      <DropdownFilterCheckbox
        name={filterNames.accounts}
        onChange={onChange}
        list={availableAccounts}
        onClear={onClear}
        onApply={onApply}
        onSelectAll={onSelectAll}
      />
    </DropdownFilterControl>
  );
}

export default memo(AccountsType);
