import styled from 'styled-components';

import { TransactionHighlighted, TransactionLabel, TransactionSubHeading } from 'components/transaction-list/styles';

const TransactionFromDetailsWrapper = styled.div`
  margin: 0;
`;

export default {
  TransactionLabel,
  TransactionHighlighted,
  TransactionSubHeading,
  TransactionFromDetailsWrapper,
};
