import { useContext, useState } from 'react';

import { showGeneralToastError } from 'utils/errors';
import { listApiPayments } from 'api/api-payments';
import { GlobalLoaderContext } from 'contexts/global-loader';
import { PaymentContext } from 'contexts/payment';

const useApiPayments = () => {
  const { startLoading, endLoading } = useContext(GlobalLoaderContext);
  const { setPayments } = useContext(PaymentContext);
  const [ isLoading, setIsLoading ] = useState(false);

  const fetchApiPayments = async () => {
    setIsLoading(true);
    startLoading();

    try {
      const payments = await listApiPayments();

      setPayments(payments.data);
    } catch (error) {
      showGeneralToastError(error);
    } finally {
      endLoading();
      setIsLoading(false);
    }
  };

  return { fetchApiPayments, isLoading };
};

export default useApiPayments;
