import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { Line } from 'rc-progress';
import React, { useContext, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { createBulkPaymentAndUploadCsv } from 'api/bulk-payments';
import { ReactComponent as CSVIcon } from 'assets/icons/csv.svg';
import { ReactComponent as DocIcon } from 'assets/icons/doc.svg';
import { ReactComponent as QuestionIcon } from 'assets/icons/question.svg';
import csvTemplate from 'assets/Intergiro EUR SEPA.csv';
import { GlobalLoaderContext } from 'contexts/global-loader';
import { emptyFileError, unableToReadFileError, wrongDataFormatError } from 'pages/bulk-payments/constants/errors';
import { formatBeneficiaries } from 'pages/bulk-payments/helpers';
import { theme } from 'theme';
import { getUiErrorMessage } from 'utils/errors';

import S from './styles';

function CSVUploader({
  accountId,
  addBeneficiaryToList,
  setCsvUploaded,
  setBulkPayment,
  setTotalsByCurrency,
  setGlobalErrors,
  setDisableContinueButton,
}) {
  const { startLoading, endLoading } = useContext(GlobalLoaderContext);
  const hiddenFileInput = useRef(null);

  const [ uploading, setUploading ] = useState(false);
  const [ fileName, setFileName ] = useState(null);
  const [ error, setError ] = useState(null);
  const [ progressPercent, setProgressPercent ] = useState(0);
  const [ progressColor, setProgressColor ] = useState(theme.colors.blue);

  const uploadCsvRequest = async file => {
    startLoading();

    setDisableContinueButton(true);
    setProgressPercent(50);

    try {
      const formData = new FormData();

      formData.append('account_id', accountId);
      formData.append('csv', file);

      const data = await createBulkPaymentAndUploadCsv(formData);

      if (data) {
        setProgressPercent(100);
        setUploading(false);

        if (isEmpty(data.items)) {
          setError(emptyFileError);
          setDisableContinueButton(false);

          return;
        }

        if (!isEmpty(data.errors)) {
          toast.error(wrongDataFormatError);
        }

        const { items, totals, errors } = formatBeneficiaries(data);

        setBulkPayment(data);
        setGlobalErrors(errors);
        setTotalsByCurrency(totals);
        addBeneficiaryToList(items, true);
        setCsvUploaded(true);
        setDisableContinueButton(false);
      }
    } catch (err) {
      const uiErrorMessage = getUiErrorMessage(err?.data?.csv, unableToReadFileError);

      setProgressColor(theme.colors.red);
      setProgressPercent(50);
      toast.error(uiErrorMessage);
      setProgressPercent(20);

      setTimeout(() => setProgressPercent(0), 500);
    } finally {
      endLoading();
    }
  };

  const onChangeHandler = event => {
    const uploadedFile = event.target.files[0];

    setUploading(true);
    setProgressPercent(20);

    setFileName(uploadedFile.name);
    uploadCsvRequest(uploadedFile);
  };

  const handleUpload = () => {
    hiddenFileInput.current.click();
  };

  const hintText = (
    <>
      <S.HintText> - only EUR transactions</S.HintText>
      <S.HintText> - max 300 transactions per CSV file</S.HintText>
      <S.HintText>
        {' '}
        - columns in the template should not be removed or changed
      </S.HintText>
      <S.HintText>
        {' '}
        - dots (not commas) must be used for decimals
      </S.HintText>
      <S.HintText> - use only 2 digits after each dot</S.HintText>
      <S.HintText>
        {' '}
        - file import settings must be set to separate values by comma
      </S.HintText>
    </>
  );

  return (
    <>
      <S.UploadCSVContainer onClick={handleUpload}>
        {uploading ? <CSVIcon /> : <DocIcon />}
        {uploading ? (
          <S.UploadCSVFileNameWrapper>
            <span>
              {fileName}
            </span>
            <S.ProgressBarContainer>
              <Line
                trailWidth="1"
                strokeWidth="1"
                percent={progressPercent}
                strokeColor={progressColor}
                trailColor={theme.colors.greyBright}
              />
            </S.ProgressBarContainer>
          </S.UploadCSVFileNameWrapper>
        ) : (
          <S.UploadCSVDetails>
            <S.UploadCSVTitle>Upload CSV</S.UploadCSVTitle>
            <S.UploadCSVSubtitle>
              Upload bulk payment template file
            </S.UploadCSVSubtitle>
          </S.UploadCSVDetails>
        )}
        <S.HintContainer text={hintText} width="22.625rem">
          <S.HelpIconWrapper>
            <S.Icon
              icon={<QuestionIcon />}
              category="link"
            />
          </S.HelpIconWrapper>
        </S.HintContainer>
      </S.UploadCSVContainer>
      {error && (
        <S.CSVErrorText>
          {error}
        </S.CSVErrorText>
      )}
      <S.CSVTemplate>
        Don&apos;t have the template?
        {' '}
        <a href={csvTemplate} download="Intergiro EUR SEPA.csv">
          <S.CSVTemplateLink>Download it here</S.CSVTemplateLink>
        </a>
      </S.CSVTemplate>
      <S.HiddenFileUploadInput
        type="file"
        name="file"
        ref={hiddenFileInput}
        onChange={onChangeHandler}
      />
    </>
  );
}

CSVUploader.propTypes = {
  accountId: PropTypes.string.isRequired,
  addBeneficiaryToList: PropTypes.func.isRequired,
  setCsvUploaded: PropTypes.func.isRequired,
  setBulkPayment: PropTypes.func.isRequired,
  setTotalsByCurrency: PropTypes.func.isRequired,
  setGlobalErrors: PropTypes.func.isRequired,
  setDisableContinueButton: PropTypes.func.isRequired,
};

export default CSVUploader;
