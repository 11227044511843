import { Button as DefaultButton } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right-grey.svg';
import { ReactComponent as DefaultTrustedIcon } from 'assets/icons/trusted.svg';
import { CancelButton as Cancel, TotalBlock } from 'components';
import { DEVICE } from 'constants/breakpoints';

const Title = styled.p`
  margin: 0;
  font: ${props => props.theme.fonts.bodyL};

  @media ${DEVICE.tablet} {
    font: ${props => props.theme.fonts.h3Bold};
  }

  @media ${DEVICE.laptop} {
    margin-bottom: 1.25rem;
  }
`;

const Container = styled.div`
  @media ${DEVICE.laptop} {
    width: 28.75rem;
  }
`;

const AmountAndAccountContainer = styled.div`
  display: flex;
  align-items: center;
  font: ${props => props.theme.fonts.bodyLBold};
  margin: 3.125rem 0 0 0;
  flex-wrap: wrap;

  @media ${DEVICE.tablet} {
    font: ${props => props.theme.fonts.h3Bold};
  }

  @media ${DEVICE.laptop} {
    margin: 3.125rem 0 3.75rem 0;
    flex-wrap: nowrap;
  }
`;

const BeneficiaryName = styled.p`
  margin-top: 1.25rem;
  flex-basis: 100%;

  @media ${DEVICE.tablet} {
    margin: 0;
    flex-basis: auto;
  }
`;

const ArrowRight = styled(ArrowRightIcon)`
  margin: 0 1rem;
  flex: 1;

  path {
    fill: ${props => props.theme.colors.greyLight};
  }
  @media ${DEVICE.laptop} {
    flex: none;
  }
`;

const TotalContainer = styled(TotalBlock)`
  margin-top: 1.25rem;

  @media ${DEVICE.tablet} {
    max-width: 30rem;
    width: 100%;
  }

  @media ${DEVICE.laptop} {
    margin-top: 1.25rem;
  }
`;

const SwitchBlockContainer = styled.div`
  margin-top: 1.25rem;

  @media ${DEVICE.tablet} {
    margin-top: 1.25rem;
  }
`;

const TrustedIcon = styled(DefaultTrustedIcon)`
  width: 0.875rem;
  height: 0.875rem;
  margin-right: 0.375rem;

  path {
    fill: ${props => props.theme.colors.greyMedium};
    fill-rule: evenodd;
  }
`;

const Button = styled(DefaultButton)`
  font-weight: 500;
  margin: 0;
  margin-bottom: 0.9375rem;
  width: 100%;

  @media ${DEVICE.tablet} {
    margin-top: 3.75rem;
    min-width: auto;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    width: 9.0625rem;
  }
`;

const CancelButton = styled(Cancel)`
  display: block;
  width: 100%;

  @media ${DEVICE.tablet} {
    display: none;
  }
`;

export default {
  AmountAndAccountContainer,
  ArrowRight,
  CancelButton,
  Button,
  Title,
  Container,
  TotalContainer,
  SwitchBlockContainer,
  TrustedIcon,
  BeneficiaryName,
};
