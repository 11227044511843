import { number, object, string } from 'yup';

const validationSchema = object().shape({
  amount: number(),
  beneficiaryName: string(),
  reference: string(),
  iban: string(),
  bic: string(),
});

export default validationSchema;
