export const errorNetAmountField = 'net_amount';
export const errorNameField = 'name';
export const errorBicField = 'account_bic';
export const errorIbanField = 'account_iban';
export const errorReferenceField = 'reference';

export const missingValuesError = 'Please add missing values';
export const emptyFileError = 'This file is empty.';
export const unableToReadFileError = 'Unable to read file.';
export const wrongDataFormatError = 'Template file has some missing information and/or wrong data format. Please fix it before continue.';
