import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CheckboxGroup } from 'components';

import S from './styles';

function DropdownFilterCheckbox({ list = [], onChange, onClear, onApply, onSelectAll }) {
  const { t } = useTranslation();

  return (
    <S.DropdownWrapper>
      <CheckboxGroup list={list} onChange={onChange} showIntermediate={false} isInverse groupName="Select all" onSelectAll={onSelectAll} />
      <S.DropdownClearFilters>
        <S.DropdownClearFilterButton size="small" label="Cancel" category="secondary" onClick={onClear}>
          {t('activity.filters.dropdown.buttons.clearFilter')}
        </S.DropdownClearFilterButton>
        <S.DropdownApplyFilterButton size="small" label="Apply filters" category="primary" onClick={onApply}>
          {t('activity.filters.dropdown.buttons.apply')}
        </S.DropdownApplyFilterButton>
      </S.DropdownClearFilters>
    </S.DropdownWrapper>
  );
}

DropdownFilterCheckbox.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({
    checked: PropTypes.bool,
    label: PropTypes.string.isRequired,
    name: PropTypes.string,
    value: PropTypes.oneOfType([ PropTypes.string, PropTypes.arrayOf ]),
  })),
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  onApply: PropTypes.func,
  onSelectAll: PropTypes.func,
};

export default DropdownFilterCheckbox;
