import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { Fragment, memo, useEffect, useState } from 'react';

import { defaultTimeFormat } from 'constants/date-time';
import { Dot } from 'components';

import S from './styles';

function TransactionFrom({ sourceName, createdAt, createdBy }) {
  const [ formattedCreatedAt, setFormattedCreatedAt ] = useState();

  useEffect(() => {
    setFormattedCreatedAt(dayjs(createdAt).format(defaultTimeFormat));
  }, []);

  if (!formattedCreatedAt) return null;

  return (
    <>
      <S.TransactionLabel>
        From:
        {' '}
        <S.TransactionHighlighted>
          {sourceName}
        </S.TransactionHighlighted>
      </S.TransactionLabel>
      <S.TransactionFromDetailsWrapper>
        <S.TransactionSubHeading data-testid="transaction-time">
          {formattedCreatedAt}
          <Dot />
        </S.TransactionSubHeading>
        <S.TransactionSubHeading data-testid="transaction-sender">
          {createdBy}
        </S.TransactionSubHeading>
      </S.TransactionFromDetailsWrapper>
    </>
  );
}

TransactionFrom.propTypes = {
  sourceName: PropTypes.string,
  createdAt: PropTypes.string.isRequired,
  createdBy: PropTypes.string.isRequired,
};

export default memo(TransactionFrom);
