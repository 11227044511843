import countriesList from 'countries-list';
import { printFormat as formatIban } from 'iban';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';

import { useAccountBankDetails } from 'hooks';
import { CopyButton } from 'components';
import { paymentRails } from 'constants/fee';
import { TransactionContext } from 'contexts/transaction';
import { formatBankCode } from 'utils/format';

import S from '../styles';

const sepaNote = 'Please use these details when transferring money to your account inside EU.';
const nonSepaNote = 'Please use these details to wire money to your account.';

function BankDetails({ id }) {
  const { account } = useAccountBankDetails(id);
  const { setActiveTransactionById } = useContext(TransactionContext);

  const isSepaAccount = account.paymentRail === paymentRails.sepa.toLocaleLowerCase();

  const getAddress = () => {
    const { city, countryCode, postalCode, street1 } = account.beneficiaryAddress;

    return (
      <>
        {account.beneficiary}
        {' '}
        {street1}
        <br />
        {postalCode}
        {' '}
        -
        {city}
        {', '}
        {countriesList.countries[countryCode].name}
      </>
    );
  };

  function getDetails() {
    const { iban, bic } = account.details;
    const { city, countryCode, postalCode, street1 } = account.beneficiaryAddress;

    return `IBAN: ${iban}\nBIC: ${bic}\nInstitution address: ${account.beneficiary}\n${street1} ${postalCode} - ${city} ${countriesList.countries[countryCode].name}`;
  }

  useEffect(() => {
    setActiveTransactionById(null);
  }, []);

  if (!account.details) return null;

  return (
    <S.Container>
      {isSepaAccount && <S.SubHeading>Local (SEPA)</S.SubHeading>}
      <S.Note marginTop={!isSepaAccount}>
        {isSepaAccount ? sepaNote : nonSepaNote}
      </S.Note>
      <S.Section>
        <S.Subtitle>
          {`IBAN ${isSepaAccount ? '(within SEPA)' : ''}`}
        </S.Subtitle>
        <S.Paragraph>
          {formatIban(account.details.iban)}
          <CopyButton text={account.details.iban} />
        </S.Paragraph>
      </S.Section>
      <S.Section>
        <S.Subtitle>BIC</S.Subtitle>
        <S.Paragraph>
          {formatBankCode(account.details.bic)}
          <CopyButton text={account.details.bic} />
        </S.Paragraph>
      </S.Section>
      <S.Section>
        <S.Subtitle>Institution address</S.Subtitle>
        <S.Paragraph>
          {account.beneficiaryAddress && getAddress()}
        </S.Paragraph>
        {account.details && (
          <S.CopyButtonWrapper>
            <CopyButton label="Copy all details" text={getDetails()} />
          </S.CopyButtonWrapper>
        )}
      </S.Section>
    </S.Container>
  );
}

BankDetails.propTypes = {
  id: PropTypes.string.isRequired,
};

export default BankDetails;
