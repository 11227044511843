const config = {
  bffUrl: 'https://portal-bff.frigg.intergiro.tech/v1/',
  corporateSiteUrl: 'https://intergiro.com/',
  envName: 'development',
  hubspotTrackingCode: '8471493',
  inactivityTimeout: 5,
  intercomId: '',
  survicateId: '',
  gtmId: '',
  onboardingUrl: 'http://localhost:3039/onboarding',
  registrationUrl: 'http://localhost:3039/onboarding',
  pciUrl: 'http://localhost:3061/v1',
  sentryApiKey: '',
  featureFlags: {
    enableBeneficiariesTrustedFlow: false,
  },
};

export default config;
