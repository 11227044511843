import { useMediaQuery } from 'react-responsive';

import { VIEWPORT_VALUES } from 'constants/breakpoints';
import { convertRemToPixels } from 'utils/styles-helper';

const useView = () => {
  const isMobile = useMediaQuery({
    maxWidth: convertRemToPixels(VIEWPORT_VALUES.tablet),
  });

  const isTablet = useMediaQuery({
    minWidth: convertRemToPixels(VIEWPORT_VALUES.tablet),
    maxWidth: convertRemToPixels(VIEWPORT_VALUES.laptop),
  });
  const isDesktop = useMediaQuery({
    minWidth: convertRemToPixels(VIEWPORT_VALUES.laptop),
  });

  return {
    isMobile,
    isTablet,
    isDesktop,
  };
};

export default useView;
