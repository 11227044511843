import { CURRENCIES } from 'constants/currencies';

export const emptyCurrencyMessages = [
  'Local payments in EUR, GBP, USD, SEK, NOK, DKK, PLN, AUD sent via local networks.',
  'Faster and cheaper alternative to international payments.',
];
const commonLocalMessages = [
  'Sent by our partners without a reference.',
  'You will not be able to recall this transaction.',
  'Faster and cheaper alternative to international payments.',
];

export const localCurrenciesMessages = {
  [CURRENCIES.EUR]: [
    'Payments to EU IBANs, via SEPA network.',
    'Sent by you with a reference.',
    'Faster and cheaper alternative to international payments.',
  ],
  [CURRENCIES.GBP]: [
    'Payments to local UK accounts, via Faster Payments Service.',
    ...commonLocalMessages,
  ],
  [CURRENCIES.USD]: [
    'Payments to local US accounts, via FedACH.',
    ...commonLocalMessages,
  ],
  [CURRENCIES.SEK]: [
    'Payments to local Swedish accounts, via RIX.',
    ...commonLocalMessages,
  ],
  [CURRENCIES.DKK]: [
    'Payments to local Danish accounts, via Straksclearing.',
    ...commonLocalMessages,
  ],
  [CURRENCIES.NOK]: [
    'Payments to local Norweigian accounts, via NICS.',
    ...commonLocalMessages,
  ],
  [CURRENCIES.PLN]: [
    'Payments to local Polish accounts, via Elixir network.',
    ...commonLocalMessages,
  ],
  [CURRENCIES.AUD]: [
    'Payments to local Australian accounts, via AusPayNet.',
    ...commonLocalMessages,
  ],
};
export const internationalMessages = [
  'Payments to International IBANs, via SWIFT network.',
  'Sent by you with a reference.',
  'Slower (normally a day or two longer) and more expensive than local payments.',
  'Payments sent via SWIFT are easier to trace or recall which is why many businesses choose to pay bills via this method.',
  'Please note that additional fees may be charged by intermediary banks or your beneficiary\'s bank when paying via SWIFT.',
];
