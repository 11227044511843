import React, { useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { WizardLayout } from 'layouts';
import { useRoles } from 'hooks';
import { ViewContext } from 'contexts/view';
import { navigation } from 'constants/navigation';

import { INVITE_USER_FINAL, INVITE_USER_FORM } from './constants';
import InviteUserForm from './components/invite-user-form';
import InviteUserFinal from './components/invite-user-final';

const layoutProps = {
  title: 'Invite user',
  displayCancelButton: false,
};

function InviteUser() {
  const { roles } = useRoles();
  const history = useHistory();
  const { id: userId } = useParams();

  const [ currentView, setCurrentView ] = useState(INVITE_USER_FORM);
  const [ createdUser, setCreatedUser ] = useState(null);

  const returnLink = userId
    ? `${navigation.users}/${userId}`
    : navigation.users;

  const triggerView = view => {
    switch (view) {
      case INVITE_USER_FORM:
      case INVITE_USER_FINAL:
        return history.push(returnLink);
      default:
        return {};
    }
  };

  const getPageContent = () => {
    switch (currentView) {
      case INVITE_USER_FORM:
        return (
          <InviteUserForm
            roles={roles}
            userId={userId}
            setCurrentView={setCurrentView}
            setCreatedUser={setCreatedUser}
          />
        );

      case INVITE_USER_FINAL:
        return (
          <InviteUserFinal
            user={createdUser}
            userId={userId}
            history={history}
            setCurrentView={setCurrentView}
          />
        );

      default:
        return null;
    }
  };

  const viewValues = useMemo(() => ({ currentView, triggerView }), [currentView]);

  return (
    <ViewContext.Provider value={viewValues}>
      <WizardLayout {...layoutProps}>
        {getPageContent()}
      </WizardLayout>
    </ViewContext.Provider>
  );
}

export default InviteUser;
