import PropTypes from 'prop-types';
import React from 'react';

import S from './styles';

function DownloadStatementsButton({ setIsOpen, className }) {
  const label = 'Statements';

  const showStatementsModal = () => {
    setIsOpen(true);
  };

  return (
    <S.Button
      className={className}
      category="link"
      icon={<S.DownloadIcon />}
      label={label}
      onClick={() => showStatementsModal()}
    />
  );
}

DownloadStatementsButton.propTypes = {
  setIsOpen: PropTypes.func,
  className: PropTypes.string,
};

export default DownloadStatementsButton;
