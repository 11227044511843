import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ERRORS } from 'constants/errors';
import authLinks from 'constants/links';
import { GlobalLoaderContext } from 'contexts/global-loader';
import { LoginLayout } from 'layouts';
import { verifyResetPasswordChallenge } from 'api/auth';
import { getParameterFromURL } from 'utils/helpers';

import { ResetPasswordConfirmation, ResetPasswordForm, SetNewPassword } from './components';

function ResetPassword() {
  const { startLoading, endLoading } = useContext(GlobalLoaderContext);
  const history = useHistory();
  const challengeId = getParameterFromURL('challenge_id', history.location);
  const nonce = getParameterFromURL('nonce', history.location);
  const [ isConfirmation, setIsConfirmation ] = useState(false);
  const [ isNewPassword, setIsNewPassword ] = useState(false);

  useEffect(() => {
    if (!challengeId || !nonce) {
      return;
    }

    async function verifyResetPassword() {
      try {
        startLoading();
        await verifyResetPasswordChallenge(challengeId);
        setIsNewPassword(true);
      } catch (error) {
        toast.error(ERRORS.requestExpired);
        history.push('/login');
      } finally {
        endLoading();
      }
    }

    verifyResetPassword();
  }, []);

  const getContent = () => {
    if (isNewPassword) {
      return <SetNewPassword challengeId={challengeId} nonce={nonce} />;
    }

    if (isConfirmation) {
      return <ResetPasswordConfirmation />;
    }

    return <ResetPasswordForm onResetPassword={() => setIsConfirmation(true)} />;
  };

  return (
    <LoginLayout links={[ authLinks.login, authLinks.noAccount ]}>
      {getContent()}
    </LoginLayout>
  );
}

export default ResetPassword;
