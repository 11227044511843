import PropTypes from 'prop-types';
import React, { useEffect, useState, useContext } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { downloadTransferConfirmationPDF } from 'api/statements';
import { ERRORS } from 'constants/errors';
import { transactionType, transactionStatus } from 'constants/transactions';
import { FORBIDDEN } from 'constants/status-code';
import { GlobalLoaderContext } from 'contexts/global-loader';

import S from './styles';

function TransferConfirmation({ id, type, status }) {
  const { t } = useTranslation();
  const { startLoading, endLoading } = useContext(GlobalLoaderContext);
  const [ isVisible, setIsVisible ] = useState();

  useEffect(() => {
    setIsVisible(type === transactionType.bankOutgoing && status && status !== transactionStatus.pendingApproval );
  }, [ type, status ]);

  const onDownload = async () => {
    try {
      startLoading();
      const { reportPath } = await downloadTransferConfirmationPDF(id);
      window.open(reportPath, '_self');
    } catch (error) {
      const errorMessage = error.status === FORBIDDEN
        ? ERRORS.invalidPermissions
        : ERRORS.generalError;

      toast.error(errorMessage);
    } finally {
      endLoading();
    }

    return null;
  };

  if (!isVisible) return null;

  return (
    <S.Wrapper >
      <S.Grid>
        <S.Label>
          {t('activity.transactionSidebar.details.statement')}
        </S.Label>
        <S.Detail>
          <S.Button
            category="link"
            icon={<S.DownloadIcon />}
            label={t('activity.transactionSidebar.details.download')}
            onClick={() => onDownload()}
          />
        </S.Detail>
      </S.Grid>

    </S.Wrapper>
  );
}

TransferConfirmation.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};

export default TransferConfirmation;
