import { useEffect } from 'react';
import TagManager from 'react-gtm-module';

import config from 'config';

const useGtm = () => {
  const { gtmId } = config;

  useEffect(() => {
    if (gtmId) {
      TagManager.initialize({
        gtmId,
      });
    }
  }, []);
};

export default useGtm;
