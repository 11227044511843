import { Button as DefaultButton, Calendar, Title as DefaultTitle } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { ReactComponent as DownloadIconComponent } from 'assets/icons/download.svg';
import { hex2rgba } from 'helpers/styles';

const Content = styled.div`
  position: relative;
  padding: 1.875rem;
  width: 30rem;
`;

const Title = styled(DefaultTitle)`
  font: ${props => props.theme.fonts.h4Bold};
  padding: 0;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
`;

const Button = styled(DefaultButton)`
  margin-right: 0.625rem;
  min-width: fit-content;
  padding: 0.75rem 1.5rem;
`;

const DownloadButton = styled(Button)`
  color: ${props => props.theme.colors.white};
`;

const CancelButton = styled(Button)`
  color: ${props => props.theme.colors.black};
  font-weight: 700;
`;

const DownloadIcon = styled(DownloadIconComponent)`
  path {
    fill: ${props => props.theme.colors.white};
  }
`;

const Separator = styled.hr`
  border-top: ${props => hex2rgba(props.theme.colors.black, 0.1)};
  margin: 1.25rem 0;
`;

const DropdownWrapper = styled.div `
  margin-bottom: 1.25rem;
`;

const DateRange = styled(Calendar)`
  position: relative;
`;

export default {
  Content,
  DropdownWrapper,
  Title,
  ButtonsContainer,
  CancelButton,
  DownloadButton,
  DownloadIcon,
  Separator,
  DateRange,
};
