import { Theme as UIKitTheme } from '@general/intergiro-ui-kit';

export const theme = {
  colors: {
    ...UIKitTheme?.colors,
  },
  fonts: {
    ...UIKitTheme?.fonts,
  },
  boxShadow: '0px 5px 16px rgba(17, 17, 17, 0.05), inset 0px 0px 1px rgba(17, 17, 17, 0.3)',
  // TODO: combine both boxShadows into a group
  boxShadow2: '0rem 0.125rem 1.5rem rgba(64, 60, 57, 0.08), inset 0rem 0rem 0.0625rem rgba(64, 60, 57, 0.4)',
  transition: 'all 200ms cubic-bezier(0.645, 0.045, 0.355, 1)',
  button: {
    ...UIKitTheme?.button,
    borderRadius: '1.875rem',
    hoverColor: UIKitTheme?.colors.greyMedium,
  },
  icons: {
    small: '1rem',
    medium: '1.5rem',
    large: '2rem',
  },
};
