import { t } from 'i18next';

import { cardStatusesMap } from 'constants/card';

export const statusDropdownName = 'status';

export const statusDropdownValues = {
  active: cardStatusesMap.active,
  notActivated: cardStatusesMap.notActivated,
  frozen: cardStatusesMap.frozen,
  securityBlock: cardStatusesMap.securityBlock,
  expired: cardStatusesMap.expired,
  blocked: cardStatusesMap.blocked,
};

export const defaultActiveStatusDropdownOptions = [
  statusDropdownValues.active,
  statusDropdownValues.notActivated,
  statusDropdownValues.frozen,
  statusDropdownValues.securityBlock,
];

export const statusDropdownOptions = [
  {
    label: t('cards.filters.status.active'),
    value: statusDropdownValues.active,
    name: statusDropdownName,
    id: statusDropdownValues.active,
  },
  {
    label: t('cards.filters.status.notActivated'),
    value: statusDropdownValues.notActivated,
    name: statusDropdownName,
    id: statusDropdownValues.notActivated,
  },
  {
    label: t('cards.filters.status.frozen'),
    value: statusDropdownValues.frozen,
    name: statusDropdownName,
    id: statusDropdownValues.frozen,
  },
  {
    label: t('cards.filters.status.securityBlock'),
    value: statusDropdownValues.securityBlock,
    name: statusDropdownName,
    id: statusDropdownValues.securityBlock,
  },
  {
    label: t('cards.filters.status.expired'),
    value: statusDropdownValues.expired,
    name: statusDropdownName,
    id: statusDropdownValues.expired,
  },
  {
    label: t('cards.filters.status.blocked'),
    value: statusDropdownValues.blocked,
    name: statusDropdownName,
    id: statusDropdownValues.blocked,
  },
];
