import styled from 'styled-components';

import { ReactComponent as DotsIcon } from 'assets/icons/dots.svg';
import { hex2rgba } from 'helpers/styles';

const getWrapperBgColor = props => {
  if (props.isActive) return hex2rgba(props.theme.colors.blue, 0.16);

  if (props.disabled) return hex2rgba(props.theme.colors.blue, 0.08);

  return props.theme.colors.white;
};

const DotsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  width: 1.75rem;
  height: 1.75rem;
  background: ${getWrapperBgColor};
  pointer-events: ${props => props.disabled ? 'none' : 'initial'};
  opacity: ${props => props.disabled ? 0.4 : 1};
  
  &:active {
    background: ${props => hex2rgba(props.theme.colors.blue, 0.16)};
  }
  
  &:hover {
    background: ${props => props.isActive ? hex2rgba(props.theme.colors.blue, 0.16) : hex2rgba(props.theme.colors.blue, 0.08)};
  }
`;

const Dots = styled(DotsIcon)`
  path {
    fill: ${props => props.theme.colors.blue};
  }
`;

export default {
  DotsWrapper,
  Dots,
};
