import styled from 'styled-components';

export const DotWrapper = styled.span`
  padding: 0 0.25rem;
  display: ${props => props.isHidden ? 'none' : 'inline'};
  font-weight: 600;
`;

export default {
  DotWrapper,
};
