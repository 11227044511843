export const userProfileFieldsList = [
  { label: 'First name', key: 'firstName' },
  { label: 'Last name', key: 'lastName' },
  { label: 'Role', key: 'roles' },
  { label: 'Position', key: 'position' },
  { label: 'Ownership', key: 'ownership' },
  { label: 'Company', key: 'corporationName' },
  { label: 'Gender', key: 'gender' },
  { label: 'Email', key: 'email' },
  { label: 'Nationality', key: 'nationalityName' },
  { label: 'Other phone', key: 'otherPhone' },
  { label: 'Country of residence', key: 'address.countryName' },
  { label: 'Social security number', key: 'socialSecurityNumber' },
  { label: 'Region', key: 'address.region' },
  { label: 'Date of birth', key: 'dateOfBirth' },
  { label: 'City', key: 'address.city' },
  { label: 'Postal code', key: 'address.postalCode' },
  { label: 'Street', key: 'address.street' },
];
