import camelCase from 'lodash/camelCase';
import styled from 'styled-components';

import DefaultHint from 'components/hint';
import { cardStatusesMap } from 'constants/card';
import { hex2rgba } from 'helpers/styles';

const getColor = props => {
  switch (props.status) {
    case cardStatusesMap.active:
      return hex2rgba(props.theme.colors.green, 0.8);
    case cardStatusesMap.frozen:
    case cardStatusesMap.closed:
      return props.theme.colors.greyMedium;
    case cardStatusesMap.securityBlock:
      return props.theme.colors.orange;
    case cardStatusesMap.expired:
    case cardStatusesMap.blocked:
    case cardStatusesMap.lost:
    case cardStatusesMap.stolen:
    case cardStatusesMap.fraud:
      return props.theme.colors.red;
    case cardStatusesMap.notActivated:
      return props.theme.colors.greyMediumDark;
    default:
      return props.theme.colors.white;
  }
};

const getStatusCardColor = (status, colors) => {
  const statusCamelCased = camelCase(status);

  return cardStatusesMap[statusCamelCased] ? colors.white : colors.black;
};

const StatusWrapper = styled.div`
  align-items: flex-end;
  display: inline-flex;
`;

const Status = styled.div`
  background: ${props => getColor(props)};
  border-radius: 0.8125rem;
  color: ${props => getStatusCardColor(props.status, props.theme.colors)};
  display: inline-block;
  font-weight: 500;
  font-size: 0.8125rem;
  padding: 0.0625rem 0.625rem 0.1875rem 0.625rem;
  text-align: center;

  + span {
    margin-left: 0.375rem;
  }
`;

const Hint = styled(DefaultHint)`
  padding-bottom: 0.15rem;
`;

export default {
  StatusWrapper,
  Status,
  Hint,
};
