import {
  Button as ButtonComponent,
  Logo as LogoComponent,
  Paragraph as ParagraphComponent,
  Title as TitleComponent,
} from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';

const Main = styled.main`
	display: flex;
	height: auto;
	max-height: 100vh;
	min-height: calc(var(--vh, 1vh) * 100);
`;

const Container = styled.div`
	background-color: ${props => props.theme.colors.white};
	display: flex;
	height: auto;
	justify-content: flex-end;
	width: 100%;
	
	@media ${DEVICE.laptop} {
		width: 60vw;
	}
`;

const Content = styled.div`
	flex: 1;
	margin-top: 6.25rem;
	
	@media ${DEVICE.tablet} {
		margin-top: 11.75rem;
	}
`;

const Inner = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	max-width: 58.5rem;
	padding: 3.75rem 1.25rem 1.25rem;
	width: 100%;
	
	@media ${DEVICE.tablet} {
		padding: 3.75rem 3.75rem 3.125rem;
	}
	
	@media ${DEVICE.laptop} {
		padding: 3.75rem 3.75rem 3.125rem 12.5rem;
	}
`;

const AnimatedContainer = styled.div`
	background-color: ${props => props.theme.colors.intergiroBlack};
	display: none;
	min-height: 100vh;
	width: 40vw;
	
	@media ${DEVICE.laptop} {
		display: block;
	}
`;

const AnimatedInner = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: flex-start;
	max-width: 37.5rem;
	padding: 3.125rem;
`;

const Link = styled(ButtonComponent)`
	color: ${props => props.theme.colors.greyMedium};
	display: block;
	font: ${props => props.theme.fonts.bodyS};
	margin-right: 1rem;
	height: auto;
	line-height: 1.4;

	&:last-child {
		margin-top: 0.8125rem;
		margin-right: 0;
	}

	@media ${DEVICE.tablet} {
		font: ${props => props.theme.fonts.bodyS};
		margin-right: 1.25rem;

		&:last-child {
			margin-right: 0;
			margin-top: 0;
		}
	}
`;

const LinksContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 3rem;
	flex-direction: column;
	
	@media ${DEVICE.tablet} {
		justify-content: flex-start;
		flex-direction: row;
	}
`;

const Video = styled.video`
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
`;

const VideoContainer = styled.div`
	flex: 1;
	position: relative;
`;

const Logo = styled(LogoComponent)`
	width: 12.25rem;

	@media ${DEVICE.tablet} {
		width: auto;
		max-width: 14.25rem;
	}
`;

const Button = styled(ButtonComponent)`
	align-self: flex-start;
	border-color: ${props => props.theme.colors.white};
	color: ${props => props.theme.colors.white};
	margin-top: 1rem;
	min-width: 8rem;
	z-index: 2;
`;

const Paragraph = styled(ParagraphComponent)`
	color: ${props => props.theme.colors.white};
	font-size: 1.25rem;
	letter-spacing: 0.02em;
	max-width: 23.25rem;
`;

const Title = styled(TitleComponent)`
	color: ${props => props.theme.colors.white};
	font-size: 2.25rem;
	line-height: 1.2;
	margin-bottom: 1rem;
	max-width: 16.5rem;
	z-index: 2;
`;

const Header = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
`;

export default {
  AnimatedContainer,
  AnimatedInner,
  Button,
  Container,
  Content,
  Inner,
  Header,
  Link,
  LinksContainer,
  Logo,
  Main,
  Paragraph,
  Title,
  Video,
  VideoContainer,
};
