import { Dropdown, Input } from '@general/intergiro-ui-kit';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import { INVITE_USER_FINAL, INVITE_USER_FORM, inviteUserDefaultValues } from 'pages/invite-user/constants';
import { useUser } from 'hooks';
import { inviteUser, reinviteUser } from 'api/users';
import { showGeneralToastError } from 'utils/errors';
import { BAD_REQUEST } from 'constants/status-code';

import { mapRolesToOptions, mapOptionsToRoles, getInitialValues } from './helpers';
import S from './styles';
import validationSchema from './validation-schema';

function InviteUserForm({ roles, userId, setCurrentView, setCreatedUser }) {
  const { user: preloadedUser } = useUser(userId);

  const formik = useFormik({
    initialValues: getInitialValues(preloadedUser, inviteUserDefaultValues),
    onSubmit,
    validationSchema,
    enableReinitialize: true,
  });

  const disableFirstNameField = preloadedUser.firstName;
  const disableLastNameField = preloadedUser.lastName;
  const disableEmailField = preloadedUser.email;

  const rolesOptions = mapRolesToOptions(roles);

  const handleRolesChange = useCallback(roleValues => {
    setFieldValue('roles', roleValues);
  }, []);

  const handleRolesBlur = useCallback(() => {
    setFieldTouched('roles', true);
  }, []);

  async function onSubmit(values) {
    const data = {
      ...values,
      roles: mapOptionsToRoles(values.roles),
    };

    try {
      const invitedUser = userId
        ? await reinviteUser(preloadedUser.id, data)
        : await inviteUser(data);

      setCreatedUser(invitedUser);
      setCurrentView(INVITE_USER_FINAL);
    } catch (error) {
      if (error.status === BAD_REQUEST) {
        setErrors(error.data);

        return;
      }

      showGeneralToastError(error, { extractFromApi: true });
    }
  }

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    setErrors,
    values,
    touched,
    errors,
    isSubmitting,
  } = formik;

  return (
    <form onSubmit={handleSubmit} name={INVITE_USER_FORM}>
      <S.Container>
        <S.Row>
          <S.InputContainer>
            <Input
              name="firstName"
              label="First name"
              value={values.firstName}
              error={touched.firstName && errors.firstName}
              disabled={isSubmitting || disableFirstNameField}
              onChange={handleChange}
              onBlur={handleBlur}
              type="string"
            />
          </S.InputContainer>
          <S.InputContainer>
            <Input
              name="lastName"
              label="Last name"
              value={values.lastName}
              error={touched.lastName && errors.lastName}
              disabled={isSubmitting || disableLastNameField}
              onChange={handleChange}
              onBlur={handleBlur}
              type="string"
            />
          </S.InputContainer>
        </S.Row>
        <S.Row>
          <S.InputContainer>
            <Input
              name="email"
              label="Email"
              value={values.email}
              error={touched.email && (errors.email?.message || errors.email)}
              disabled={isSubmitting || disableEmailField}
              onChange={handleChange}
              onBlur={handleBlur}
              type="string"
            />
          </S.InputContainer>
        </S.Row>
        <S.Row>
          <S.InputContainer>
            <Dropdown
              name="roles"
              label="Roles"
              placeholder="Choose roles"
              value={values.roles}
              error={touched.roles && (errors.roles?.message || errors.roles)}
              isDisabled={isSubmitting}
              options={rolesOptions}
              onChange={handleRolesChange}
              onBlur={handleRolesBlur}
              isMulti
            />
          </S.InputContainer>
        </S.Row>
        <S.Button
          label="Send invitation"
          category="primary"
          type="submit"
          disabled={isSubmitting}
        />
      </S.Container>
    </form>
  );
}

InviteUserForm.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  userId: PropTypes.string,
  setCurrentView: PropTypes.func.isRequired,
  setCreatedUser: PropTypes.func.isRequired,
};

export default InviteUserForm;
