import PropTypes from 'prop-types';
import React from 'react';

import S from './styles';

function TotalContainer({
  totalAmount,
  currency,
  sidebar,
  fees,
  netAmount,
}) {
  return (
    <S.TotalContainer sidebar={sidebar}>
      <S.Row>
        <S.Text>Total without fees</S.Text>
        <S.Text black>
          {netAmount}
          {' '}
          {currency}
        </S.Text>
      </S.Row>
      <S.Row paddingTop="1.5625rem">
        <S.Text>Transfer fees</S.Text>
        <S.Text black>
          {fees}
          {' '}
          {currency}
        </S.Text>
      </S.Row>
      <S.Separator marginTop="0.9375rem" marginBottom="0" />
      <S.Row paddingTop="0.9375rem">
        <S.Text black bold>
          Total
        </S.Text>
        <S.Text black bold>
          {totalAmount}
          {' '}
          {currency}
        </S.Text>
      </S.Row>
    </S.TotalContainer>
  );
}

TotalContainer.propTypes = {
  totalAmount: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  sidebar: PropTypes.bool,
  fees: PropTypes.number,
  netAmount: PropTypes.number.isRequired,
};

export default TotalContainer;
