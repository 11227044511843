import { isValid as isValidIban, printFormat as formatIban } from 'iban';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import isString from 'lodash/isString';

export const trimAllSpaces = str => isString(str) ? str.replace(/\s/g, '') : str;

export function formatIbanIsValid(iban) {
  return isValidIban(iban) ? formatIban(iban) : iban;
}

export const formatBankCode = str => formatString(str, bankCodeFormatter);

export function formatPhone(phone) {
  const phoneWithPlus = phone.startsWith('+') ? phone : `+${phone}`;
  const phoneNumberObject = parsePhoneNumberFromString(phoneWithPlus);

  return phoneNumberObject.format('INTERNATIONAL');
}

// Private functions

function formatString(str, formatter) {
  return isString(str) ? formatter(str) : str;
}

function bankCodeFormatter(str) {
  // apply mask or XX XX XX or XXXX XX XX XXX or XXXX XX XX to a string
  // inspired by https://stackoverflow.com/a/17650408

  if (str.length === 6) {
    return str.replace(/(.{2})/g, '$1 ');
  }

  return str.replace(/^(.{4})(.{2})(.{2})(.{3})?/, '$1 $2 $3 $4');
}
