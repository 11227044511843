import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

export default function useNewChallengeAvailability({
  availableAt: initialAvailableAt = 0,
}) {
  const [ availableAt, setAvailableAt ] = useState(initialAvailableAt);
  const isAvailable = !availableAt || availableAt < dayjs();

  const makeAvailable = () => setAvailableAt(0);

  const updateAvailableAt = newValue => {
    if (newValue < Date.now()) {
      makeAvailable();

      return;
    }
    setAvailableAt(newValue);
  };

  useEffect(() => {
    if (!isAvailable) {
      const timeout = setTimeout(makeAvailable, availableAt - Date.now());

      return () => {
        clearTimeout(timeout);
      };
    }

    return undefined;
  }, [ availableAt, isAvailable ]);

  return {
    isAvailable,
    availableAt,
    setAvailableAt: updateAvailableAt,
  };
}

