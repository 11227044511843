import {
  mixed,
  number,
  object,
  ref,
  string,
} from 'yup';

import {
  AMOUNT_IS_TOO_SMALL_ERROR_MESSAGE,
  fromAccountFieldName,
  MIN_AMOUNT,
  moveAmountFieldName,
  receiveAmountFieldName,
  SMALLEST_MOVE_FX_AMOUNT,
  toAccountFieldName,
} from 'pages/move-funds/constants';
import { accountsHaveDifferentCurrency } from 'pages/move-funds/services/utils';
import { transformSchemaAmount } from 'utils/amount';

export const MoveFundsSchema = object().shape({
  [fromAccountFieldName]: object()
    .nullable()
    .default(null)
    .shape({
      name: string(),
      amount: string(),
      currency: string(),
    })
    .required('Please select an account'),
  [toAccountFieldName]: object()
    .nullable()
    .default(null)
    .shape({
      name: string(),
      amount: string(),
      currency: string(),
    })
    .required('Please select an account'),
  [moveAmountFieldName]: mixed().when([ fromAccountFieldName, toAccountFieldName ], {
    is: (fromAccount, toAccount) => fromAccount && toAccount,
    then: number()
      .transform(transformSchemaAmount)
      .when([ fromAccountFieldName, toAccountFieldName ], {
        is: (fromAccount, toAccount) =>
          accountsHaveDifferentCurrency({ fromAccount, toAccount }),
        then: number()
          .min(SMALLEST_MOVE_FX_AMOUNT,
            AMOUNT_IS_TOO_SMALL_ERROR_MESSAGE),
      })
      .min(MIN_AMOUNT,
        `Amount should be equal or more than ${MIN_AMOUNT}`)
      .max(ref(`${fromAccountFieldName}.amount`), 'Not enough funds')
      .required('Required field'),
    otherwise: mixed(),
  }),
  [receiveAmountFieldName]: mixed().when([ fromAccountFieldName, toAccountFieldName ], {
    is: (fromAccount, toAccount) =>
      accountsHaveDifferentCurrency({ fromAccount, toAccount }),
    then: string().required('Required field'),
    otherwise: mixed(),
  }),
});
