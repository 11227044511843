import { Button as DefaultButton } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { ReactComponent as DefaultTrustedIcon } from 'assets/icons/trusted.svg';
import { DEVICE } from 'constants/breakpoints';

const Button = styled(DefaultButton)`
  margin-right: 0;
  min-width: fit-content;
  padding: 0.75rem 1.5rem;
  margin-top: ${props => props.category === 'secondary' ? '1rem' : 0};
  width: 100%;

  @media ${DEVICE.tablet} {
    margin-top: 0;
    margin-right: 0.625rem;
    width: auto;
  }
`;

const TrustedIcon = styled(DefaultTrustedIcon)`
  width: 0.875rem;
  height: 0.875rem;
  margin-right: 0.3rem;
  vertical-align: baseline;

  path {
    fill: ${props => props.theme.colors.greyMedium};
    fill-rule: evenodd;
  }
`;

const DetailsContainer = styled.div`
  font-size: 1.2rem;
  font-weight: 500;
  border: 0.0625rem solid ${props => props.theme.colors.greyBright};
  padding: 1rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  margin-bottom: ${props => props.marginBottom ? '1.5rem' : 0};
`;

const DetailsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SubTitle = styled.p`
  font: ${props => props.theme.fonts.h4Bold};
`;

const Label = styled.p`
  color: ${props => props.theme.colors.greyMedium};
  padding: 0.7rem 0;
  margin: 0;
`;

export default {
  Button,
  TrustedIcon,
  DetailsContainer,
  DetailsRow,
  SubTitle,
  Label,
};
