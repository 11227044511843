export const addBeneficiaryFieldName = 'add-beneficiary';

export const amountFieldName = 'amount';
export const amountRowFieldName = 'amountRow';
export const beneficiaryNameFieldName = 'beneficiaryName';
export const referenceFieldName = 'reference';
export const referenceRowFieldName = 'referenceRow';
export const ibanFieldName = 'iban';
export const bicFieldName = 'bic';

export const addBeneficiaryForm = 'addBeneficiaryForm';
export const beneficiaryRowForm = 'beneficiaryRowForm';

export const selectAccountFieldName = 'selectAccount';
export const selectBeneficiaryFieldName = 'selectBeneficiary';

export const notEnoughFundsCode = 'insufficient_funds';

export const SEPA = 'sepa';
export const EUR = 'EUR';

export const thresholdHintText = `As you’ve reached your monthly allowance 
    for free SEPA transfers, this transfer will 
    incur a fee. To upgrade your plan and get more 
    free SEPA transfers, please contact Support.`;

export const velocityOverflow = 'velocity-overflow';
export const volumeOverflow = 'volume-overflow';
