import get from 'lodash/fp/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { usePermissionCategories, useRoles } from 'hooks';
import { NavigationContext } from 'contexts/navigation';
import { GlobalLoaderContext } from 'contexts/global-loader';
import PermissionsList from 'components/permissions-list';
import { updateUser } from 'api/users';

import S from './styles';
import { successMessage } from './constants';

function UserRoles({ user, setUser, isEditable, isCurrentUser }) {
  const { roles } = useRoles();
  const { startLoading, endLoading } = useContext(GlobalLoaderContext);
  const { sidebarWidth } = useContext(NavigationContext);

  const [ combinedPermissionCategories, setCombinedPermissionCategories ] = useState();
  const [ footerIsVisible, setFooterIsVisible ] = useState(false);
  const [ errors, setErrors ] = useState(null);

  const userRoles = user.roles.map(role => ({ label: role.name, value: role.id }));
  const roleOptions = roles.map(role => ({ label: role.name, value: role.id }));
  const [ selectedRoles, setSelectedRoles ] = useState(userRoles);

  const { permissionCategories: emptyPermissionCategories } = usePermissionCategories();
  const disableRolesSelect = !isEditable || isCurrentUser;

  const handleSelectRole = useCallback(data => {
    setSelectedRoles(data);

    setErrors(null);
  }, []);

  const handleConfirm = useCallback(async () => {
    startLoading();

    const data = {
      roles: selectedRoles.map(get('value')),
    };

    try {
      const updatedUser = await updateUser(user.id, data);

      setUser(updatedUser);
      setFooterIsVisible(false);
      toast.success(successMessage);
    } catch (error) {
      setErrors(error.data);
    } finally {
      endLoading();
    }
  }, [ user.id, startLoading, setUser, endLoading, selectedRoles ]);

  const handleDiscard = useCallback(() => {
    setSelectedRoles(userRoles);
    setErrors(null);
  }, [userRoles]);

  useEffect(() => {
    if (!isEmpty(roles)) {
      const permissionCategories =
        getCombinedPermissionCategories(roles, selectedRoles, emptyPermissionCategories);

      setCombinedPermissionCategories(permissionCategories);
    }
  }, [ selectedRoles, roles, emptyPermissionCategories ]);

  useEffect(() => {
    const areRolesChanged = !isEqualArray(userRoles, selectedRoles);

    setFooterIsVisible(areRolesChanged);
  }, [ selectedRoles, userRoles ]);

  return (
    <S.Container footerIsVisible={footerIsVisible}>
      <S.Select
        name="roles"
        label="Roles"
        value={selectedRoles}
        error={errors?.roles?.message}
        options={roleOptions}
        onChange={handleSelectRole}
        isDisabled={disableRolesSelect}
        isMulti
      />

      <PermissionsList permissionCategories={combinedPermissionCategories} disabled />
      <S.SaveChangesFooter
        visible={footerIsVisible}
        sidebarWidth={sidebarWidth}
      >
        <S.SaveButton
          label="Save changes"
          category="primary"
          type="submit"
          onClick={handleConfirm}
        />
        <S.DiscardButton
          label="Discard"
          category="secondary"
          type="reset"
          width="" // hack to set minimal width
          onClick={handleDiscard}
        />
      </S.SaveChangesFooter>
    </S.Container>
  );
}

UserRoles.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    roles: PropTypes.arrayOf(PropTypes.shape).isRequired,
  }).isRequired,
  setUser: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
  isCurrentUser: PropTypes.bool.isRequired,
};

export default UserRoles;

// Private functinos

// Idea is to get all permissions from every selected role, and then update in empty role permissions with `true` value.
function getCombinedPermissionCategories(roles, selectedRoles, emptyPermissionCategories) {
  return selectedRoles
    .map(getSelectedRoleWithPermissionCategories(roles))
    .flatMap(get('permissionCategories'))
    .flatMap(getPermissionList)
    .reduce(reduceToPermissionCategories, emptyPermissionCategories);
}

function getSelectedRoleWithPermissionCategories(roles) {
  return roleOption => roles.find(role => roleOption.value === role.id);
}

function getPermissionList(category) {
  return category.groups.reduce((permissionList, group) =>
    permissionList.concat(group.permissions.flatMap(permission => ({ groupTitle: group.title, ...permission }))), []);
}

function reduceToPermissionCategories(baseRole, permission) {
  return baseRole.map(pc => ({
    ...pc,
    groups: pc.groups.map(group => group.title === permission.groupTitle
      ? updatePermissionInGroup(group, permission)
      : group),
  }));
}

function updatePermissionInGroup(group, permission) {
  return ({
    ...group,
    key: group.title,
    permissions: group.permissions.map(permissionInGroup => permissionInGroup.key === permission.key
      ? assertTruthyPermission(permissionInGroup, permission)
      : permissionInGroup),
  });
}

// If true - updates, if false keeps previous value
function assertTruthyPermission(permissionInGroup, permission) {
  return { ...permissionInGroup, value: permissionInGroup.value || permission.value };
}

function isEqualArray(value, other) {
  return isEqual(sortBy(value, 'label'),
    sortBy(other, 'label'));
}
