export const VIEWPORT_VALUES = {
  mobile: 20, // 320px
  tablet: 48, // 768px
  laptop: 64.0625, // 1025px
  desktop: 80.0625, // 1281px
  wideDesktop: 120, // 1920px
};

export const SIZE = {
  mobile: `${VIEWPORT_VALUES.mobile}rem`,
  tablet: `${VIEWPORT_VALUES.tablet}rem`,
  laptop: `${VIEWPORT_VALUES.laptop}rem`,
  desktop: `${VIEWPORT_VALUES.desktop}rem`,
  wideDesktop: `${VIEWPORT_VALUES.wideDesktop}rem`,
};

export const DEVICE = {
  mobile: `only screen and (min-width: ${SIZE.mobile})`,
  tablet: `only screen and (min-width: ${SIZE.tablet})`,
  laptop: `only screen and (min-width: ${SIZE.laptop})`,
  desktop: `only screen and (min-width: ${SIZE.desktop})`,
  wideDesktop: `only screen and (min-width: ${SIZE.wideDesktop})`,
};
