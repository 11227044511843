import bff from 'utils/bff';

export async function moveFunds(request) {
  const { data } = await bff.payment.moveFunds(request);

  return data;
}

export async function sendFunds(request) {
  const { data } = await bff.payment.sendFunds(request);

  return data;
}

export const getPaymentsCount = async () => {
  const { data } = await bff.payment.getPaymentsCount({});

  return data;
};
