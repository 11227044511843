import PropTypes from 'prop-types';
import React from 'react';

import { MODAL_TYPE } from 'constants/modal';
import { ModalContent } from 'components';

import S from './styles';

function LimitReachedErrorModal({ isOpen, setIsOpen }) {
  const CTAButtons = (
    <S.Button label="Got it" category="primary" type="submit" onClick={() => setIsOpen(false)} />
  );

  return (
    <ModalContent
      title="Card issuing limit reached"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      CTAButtons={CTAButtons}
      type={MODAL_TYPE.message}
      hasCloseIcon
    >
      <S.Message>
        If you would like to order any additional cards please contact customer support for assistance.
      </S.Message>
    </ModalContent>
  );
}

LimitReachedErrorModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default LimitReachedErrorModal;
