import intersectionBy from 'lodash/intersectionBy';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';

import { isBol, isSpark } from 'constants/payment-processor';

export function canCreateMovement(accounts) {
  return (
    hasMoreThanOneBolAccounts(accounts) ||
    hasMoreThanOneSparkAccounts(accounts) ||
    hasAtLeastOneBolAndSparkAccountInSameCurrencry(accounts)
  );
}

export function canCreateBulkPayments(account, accounts) {
  return account
    ? isBol(account?.paymentProcessorAdapter)
    : hasAtLeastOneBolAccount(accounts);
}

// Private functions

function hasMoreThanOneBolAccounts(accounts) {
  const bolAccounts = accounts.filter(({ paymentProcessorAdapter }) => isBol(paymentProcessorAdapter));

  return size(bolAccounts) > 1;
}

function hasMoreThanOneSparkAccounts(accounts) {
  const sparkAccounts = accounts.filter(({ paymentProcessorAdapter }) => isSpark(paymentProcessorAdapter));

  return size(sparkAccounts) > 1;
}

function hasAtLeastOneBolAndSparkAccountInSameCurrencry(accounts) {
  const bolAccounts = accounts.filter(({ paymentProcessorAdapter }) => isBol(paymentProcessorAdapter));
  const sparkAccounts = accounts.filter(({ paymentProcessorAdapter }) => isSpark(paymentProcessorAdapter));

  const accountsInSameCurrencry = intersectionBy(bolAccounts, sparkAccounts, 'currency');

  return !isEmpty(accountsInSameCurrencry);
}

function hasAtLeastOneBolAccount(accounts) {
  const bolAccounts = accounts.filter(({ paymentProcessorAdapter }) => isBol(paymentProcessorAdapter));

  return !isEmpty(bolAccounts);
}
