import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Details, SwitchBlock, TwoFaModal } from 'components';
import { beneficiaryShape } from 'local-prop-types';
import { navigation } from 'constants/navigation';
import { NOT_FOUND } from 'constants/status-code';
import { resourceType } from 'constants/resource';
import TwoFaProvider from 'contexts/two-fa';
import { updateBeneficiary } from 'api/beneficiary';
import { navigateToNotFoundPage, showGeneralToastError, rethrowTwoFaError } from 'utils/errors';
import config from 'config';

import S from './styles';
import { detailsFields } from './constants';
import DeleteModal from '../delete-modal';

function BeneficiaryDetails({ beneficiary, onTrusting }) {
  const history = useHistory();
  const { t } = useTranslation();
  const { isTrusted } = beneficiary;
  const [ isDeleteModalOpen, setDeleteModalOpen ] = useState(false);
  const [ twoFaModalIsOpen, setTwoFaModalIsOpen ] = useState(false);
  const [ twoFaData, setTwoFaData ] = useState(null);

  const detailsList = useMemo(() =>
    detailsFields.map(({ label, fields, formatValue, formatLabel }) => {
      const value = fields.map(f => get(beneficiary, f)).join(' ').trim();

      return {
        label: formatLabel ? formatLabel(beneficiary) : label,
        value: formatValue ? formatValue(value, beneficiary) : value || undefined,
      };
    }), [beneficiary]);

  const handleDelete = () => {
    history.push(navigation.beneficiaries);
  };

  const handleMakeTrusted = async () => {
    try {
      const data = await updateBeneficiary(beneficiary.id, { isTrusted: !isTrusted });

      if (data?.challenge) {
        setTwoFaData(data.challenge);
      }
      setTwoFaModalIsOpen(true);
    } catch (error) {
      error.status === NOT_FOUND
        ? navigateToNotFoundPage(history, resourceType.beneficiary)
        : showGeneralToastError(error, { extractFromApi: true });
    }
  };

  const handleConfirmCode = async challenge => {
    try {
      await updateBeneficiary(beneficiary.id, { isTrusted: !isTrusted, challenge });
      setTwoFaModalIsOpen(false);
      toast.success(t('beneficiary.trusted.successMessage'));
      onTrusting();
    } catch (error) {
      if (error.status === NOT_FOUND) {
        navigateToNotFoundPage(history, resourceType.beneficiary);

        return;
      }

      rethrowTwoFaError(error);
      setTwoFaModalIsOpen(false);
      showGeneralToastError(error, { extractFromApi: true });
    }
  };

  const handleResendCode = async factor => {
    const data = await updateBeneficiary(beneficiary.id,
      { isTrusted: !isTrusted, challenge: { preferredFactor: factor } });
    setTwoFaData(data.challenge);

    return data;
  };

  return (
    <>
      <S.Wrapper>
        <S.DetailsContainer>
          <Details list={detailsList.filter(({ value }) => value)} />
        </S.DetailsContainer>
        {config.featureFlags.enableBeneficiariesTrustedFlow && isTrusted && (
          <SwitchBlock
            title={t('beneficiary.trusted.title')}
            titleIcon={<S.TrustedIcon />}
            text={t('beneficiary.trusted.text')}
            checked={isTrusted}
            onChange={handleMakeTrusted}
          />
        )}
        <S.ActionRow>
          <S.Button
            size="medium"
            label={t('beneficiary.deleteButton')}
            isDanger
            icon={<S.DeleteIcon />}
            onClick={() => { setDeleteModalOpen(true); }}
          />
        </S.ActionRow>
      </S.Wrapper>
      <DeleteModal
        isOpen={isDeleteModalOpen}
        setIsOpen={setDeleteModalOpen}
        beneficiary={beneficiary}
        onDelete={handleDelete}
      />
      {twoFaModalIsOpen && (
        <TwoFaProvider twoFaData={twoFaData}>
          <TwoFaModal
            isOpen={twoFaModalIsOpen}
            setIsOpen={setTwoFaModalIsOpen}
            onSubmit={handleConfirmCode}
            onResend={handleResendCode}
            challenge={twoFaData}
            isVoiceCallEnabled
          />
        </TwoFaProvider>
      )}
    </>
  );
}

BeneficiaryDetails.propTypes = {
  beneficiary: PropTypes.shape(beneficiaryShape).isRequired,
  onTrusting: PropTypes.func.isRequired,
};

export default BeneficiaryDetails;
