import { Avatar } from '@general/intergiro-ui-kit';
import { useFormik } from 'formik';
import { printFormat as formatIban } from 'iban';
import isEqual from 'lodash/isEqual';
import isNull from 'lodash/isNull';
import trim from 'lodash/trim';
import PropTypes from 'prop-types';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import TotalContainer from 'pages/bulk-payments/components/total-container';
import {
  errorBicField,
  errorIbanField,
  errorNameField,
  errorNetAmountField,
  errorReferenceField,
} from 'pages/bulk-payments/constants/errors';
import {
  addBeneficiaryForm,
  amountFieldName,
  beneficiaryNameFieldName,
  bicFieldName,
  ibanFieldName,
  referenceFieldName,
} from 'pages/bulk-payments/constants/form';
import { formatAmount, formatBeneficiaries, getBeneficiaryPayload } from 'pages/bulk-payments/helpers';
import { INPUT_PATTERNS } from 'constants/inputs';
import { addBulkPaymentItem, createBulkPayment, updateBulkPaymentItem } from 'api/bulk-payments';
import { showGeneralToastError, getUiErrorMessage } from 'utils/errors';

import S from './styles';
import validationSchema from './validation-schema';

function BeneficiarySidebar({
  isOpen,
  onBeneficiarySidebarClose,
  account,
  beneficiary,
  isEditMode,
  bulkPaymentId,
  addBeneficiaryToList,
  setTotalsByCurrency,
  setBulkPayment,
  handleSidebarArrowClick,
  setGlobalErrors,
  numberOfDisplayedBeneficiaries,
  chosenBeneficiaryIndex,
  setChosenBeneficiaryIndex,
  setDisableContinueButton,
}) {
  const referenceRef = useRef(null);
  const bicRef = useRef(null);
  const amountRef = useRef(null);
  const ibanRef = useRef(null);
  const nameRef = useRef(null);
  const formikRef = useRef();
  const [ formattedIban, setFormattedIban ] = useState('');
  const [ avatar, setAvatar ] = useState(null);
  const [ fees, setFees ] = useState(null);
  const [ totalAmount, setTotalAmount ] = useState(null);
  const [ netAmount, setNetAmount ] = useState(null);
  const [ reference, setReference ] = useState('');
  const [ bic, setBic ] = useState('');
  const [ beneficiaryName, setBeneficiaryName ] = useState('');
  const [ error, setError ] = useState(null);
  const [ totalContainerHeight, setTotalContainerHeight ] = useState(0);
  const formValuesRef = useRef(null);
  const valuesRef = useRef(null);
  const beneficiaryRef = useRef(null);

  beneficiaryRef.current = beneficiary;
  const { currency, id: accountId } = account.value;
  const initialValues = {
    [amountFieldName]: undefined,
    [beneficiaryNameFieldName]: undefined,
    [referenceFieldName]: undefined,
    [ibanFieldName]: undefined,
    [bicFieldName]: undefined,
  };

  const formValues = beneficiary
    ? {
        [amountFieldName]: beneficiary.amount,
        [beneficiaryNameFieldName]: beneficiary.name,
        [referenceFieldName]: beneficiary.reference,
        [ibanFieldName]: beneficiary.account.number.value,
        [bicFieldName]: beneficiary.account.bankCode.value,
      }
    : initialValues;

  formValuesRef.current = formValues;
  const formatAndSetErrors = (errorArr = []) => {
    const errors = errorArr.reduce((acc, err) => ({ ...acc, [err.fieldName]: err }), {});

    setError(errors);
  };

  const handleIban = () => {
    if (beneficiary.account?.number?.value) {
      const formatted = formatIban(beneficiary.account.number.value);
      setFormattedIban(formatted);
    }
  };

  useEffect(() => {
    if (beneficiary) {
      handleIban();
      setNetAmount(formatAmount(beneficiary.net));
      setFees(formatAmount(beneficiary.fee));
      setTotalAmount(formatAmount(beneficiary.amount));
      setReference(beneficiary.reference);
      setBic(beneficiary.account?.bankCode.value);
      setBeneficiaryName(beneficiary.name);
      formatAndSetErrors(beneficiary.errors);
    }
  }, [beneficiary]);

  const measuredRef = useCallback(node => {
    if (node !== null) {
      setTotalContainerHeight(node.getBoundingClientRect().height);
    }
  },
  [ beneficiary, netAmount ]);

  const getAvatar = (name, number, bankCode) => (
    <S.AvatarWrapper>
      <Avatar name={name} param1={number} param2={bankCode} />
    </S.AvatarWrapper>
  );

  const onSubmit = async values => {
    setDisableContinueButton(true);

    try {
      let bulkPaymentInstanceId = bulkPaymentId;

      if (!bulkPaymentId) {
        const bulkPaymentData = await createBulkPayment({ accountId });
        setBulkPayment(bulkPaymentData);
        bulkPaymentInstanceId = bulkPaymentData.id;
      }

      const payload = getBeneficiaryPayload(values, accountId, currency);

      const data = await addBulkPaymentItem(bulkPaymentInstanceId, payload);
      const { items, totals, errors } = formatBeneficiaries(data);

      setBulkPayment(data);
      setTotalsByCurrency(totals);
      setGlobalErrors(errors);
      addBeneficiaryToList(items);
      handleClose(items);
    } catch (err) {
      showGeneralToastError(err);
    } finally {
      setDisableContinueButton(false);
    }
  };

  const formik = useFormik({
    initialValues: formValues,
    onSubmit,
    validationSchema,
  });

  const {
    handleSubmit,
    setFieldValue,
    values,
    isSubmitting,
    setFieldTouched,
  } = formik;
  valuesRef.current = values;

  const handleClose = beneficiariesResponse => {
    const items = Array.isArray(beneficiariesResponse);
    const fieldRefs = [ referenceRef, bicRef, amountRef, ibanRef, nameRef ];
    const activeField = fieldRefs.find(({ current: { name } }) => name === document.activeElement.name);
    const formHasValues = fieldRefs.find(({ current: { value } }) => value);
    const formTouched =
      !isEqual(values, initialValues) ||
      (activeField?.current.value && activeField?.current.value !== '') ||
      Boolean(formHasValues);
    const displayPopUp = !isEditMode && formTouched && !items;
    if (isEditMode) {
      if (isEqual(valuesRef.current, formValuesRef.current) && !activeField?.current) {
        onBeneficiarySidebarClose(false);
      } else {
        onBlurHandle(activeField?.current.value,
          activeField?.current.name,
          true);
      }
    }
    onBeneficiarySidebarClose(displayPopUp);
    setChosenBeneficiaryIndex(null);
  };

  const onChange = (e, fieldName) => {
    const { value } = e.target;
    setFieldValue(fieldName, value);
    setFieldTouched(fieldName);
    switch (fieldName) {
      case beneficiaryNameFieldName:
        setBeneficiaryName(value);
        break;
      case referenceFieldName:
        setReference(value);
        break;
      case bicFieldName:
        setBic(value);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (isEditMode && beneficiary) {
      const {
        name,
        account: {
          number: { value: iban },
          bankCode: { value: bicValue },
        },
      } = beneficiary;

      setAvatar(getAvatar(name, iban, bicValue));
    } else if (
      values[beneficiaryNameFieldName] ||
      values[ibanFieldName] ||
      values[bicFieldName]
    ) {
      setAvatar(getAvatar(values[beneficiaryNameFieldName],
        values[ibanFieldName],
        values[bicFieldName]));
    } else {
      setAvatar(<S.BulkPaymentsIcon />);
    }
  }, [
    values[beneficiaryNameFieldName],
    values[ibanFieldName],
    values[bicFieldName],
    beneficiary,
  ]);

  const getActionButton = () => {
    if (isEditMode) {
      return null;
    }

    return (
      <S.ActionButton label="Confirm" category="primary" type="submit" />
    );
  };

  const handleIbanChange = e => {
    const field = e.target;
    const { value } = field;
    const ibanStr = trim(value);
    const formatted = formatIban(value);

    setFieldValue(ibanFieldName, ibanStr);
    setFormattedIban(formatted);
    setFieldTouched(ibanFieldName);

    // Temporary fix of cursor issue
    const caret = field.selectionStart;
    window.requestAnimationFrame(() =>
      field.setSelectionRange(caret, caret));
  };

  const onBlurHandle = async (value,
    fieldName,
    ignoreComponentState = false) => {
    const fieldValue = fieldName === bicFieldName ? trim(value) : value;
    const patchData = { [fieldName]: fieldValue };
    const payload = getBeneficiaryPayload(patchData, accountId, currency);

    setDisableContinueButton(true);

    try {
      const data = await updateBulkPaymentItem(bulkPaymentId, beneficiaryRef.current.id, payload);

      const { items, totals, errors: allErrors } = formatBeneficiaries(data);
      const currentBeneficiary = items.find(item => item.id === beneficiary.id);
      const { fee, net, amount } = currentBeneficiary;

      setBulkPayment(data);
      addBeneficiaryToList(items);
      setTotalsByCurrency(totals);
      setGlobalErrors(allErrors);
      formatAndSetErrors(currentBeneficiary.errors);

      if (!ignoreComponentState) {
        setFees(formatAmount(fee));
        setTotalAmount(formatAmount(amount));
        setNetAmount(formatAmount(net));
      }
    } catch (err) {
      showGeneralToastError(err);
    } finally {
      setDisableContinueButton(false);
    }
  };

  const onAmountChangeHandler = e => {
    if (e.target.value === '' || INPUT_PATTERNS.amount.test(e.target.value)) {
      setNetAmount(e.target.value);
      setFieldValue(amountFieldName, e.target.value);
    }
  };

  return (
    <S.Sidebar
      isOpen={isOpen}
      isUpArrowDisabled={isNull(chosenBeneficiaryIndex) || chosenBeneficiaryIndex === 0}
      isDownArrowDisabled={
        isNull(chosenBeneficiaryIndex) || (numberOfDisplayedBeneficiaries - 1 === chosenBeneficiaryIndex)
      }
      handleUpArrowClick={() => handleSidebarArrowClick(beneficiary.id, true)}
      handleDownArrowClick={() => handleSidebarArrowClick(beneficiary.id, false)}
      handleClose={handleClose}
      customHeaderIcon={avatar}
    >
      <S.AddBeneficiaryForm
        onSubmit={handleSubmit}
        id={addBeneficiaryForm}
        name={addBeneficiaryForm}
        innerRef={formikRef}
      >
        <S.Content totalContainerHeight={totalContainerHeight}>
          <AmountInput
            currency={currency}
            isSubmitting={isSubmitting}
            isEditMode={isEditMode}
            netAmount={netAmount}
            onBlurHandle={onBlurHandle}
            onAmountChangeHandler={onAmountChangeHandler}
            error={error?.[errorNetAmountField]}
            ref={amountRef}
          />
          <S.Separator />
          <TextInput
            label="Beneficiary name"
            isEditMode={isEditMode}
            value={beneficiaryName}
            isSubmitting={isSubmitting}
            fieldName={beneficiaryNameFieldName}
            errorFieldName={errorNameField}
            onBlurHandle={onBlurHandle}
            onChange={onChange}
            error={error}
            ref={nameRef}
          />
          <IbanInput
            isEditMode={isEditMode}
            formattedIban={formattedIban}
            isSubmitting={isSubmitting}
            onBlurHandle={onBlurHandle}
            handleIbanChange={handleIbanChange}
            error={error?.[errorIbanField]}
            ref={ibanRef}
          />
          <TextInput
            label="BIC"
            isEditMode={isEditMode}
            value={bic}
            isSubmitting={isSubmitting}
            fieldName={bicFieldName}
            errorFieldName={errorBicField}
            onBlurHandle={onBlurHandle}
            onChange={onChange}
            error={error}
            ref={bicRef}
          />
          <S.Separator />
          <TextInput
            label="Payment reference"
            isEditMode={isEditMode}
            value={reference}
            isSubmitting={isSubmitting}
            fieldName={referenceFieldName}
            errorFieldName={errorReferenceField}
            onBlurHandle={onBlurHandle}
            onChange={onChange}
            error={error}
            ref={referenceRef}
          />
        </S.Content>
        <S.TotalWrapper
          ref={measuredRef}
          visible={Boolean(netAmount) || !isEditMode}
        >
          {isEditMode && (
            <TotalContainer
              totalAmount={totalAmount}
              currency={currency}
              fees={fees}
              netAmount={netAmount}
              sidebar
            />
          )}
          {getActionButton()}
        </S.TotalWrapper>
      </S.AddBeneficiaryForm>
    </S.Sidebar>
  );
}

// eslint-disable-next-line react/display-name
const AmountInput = forwardRef(({
  currency,
  isSubmitting,
  isEditMode,
  netAmount,
  onBlurHandle,
  onAmountChangeHandler,
  error,
}, ref) => (
  <S.Input
    helperText={(
      <S.CurrencyLabel>
        {currency}
      </S.CurrencyLabel>
)}
    label="I send"
    value={netAmount || ''}
    id={amountFieldName}
    name={amountFieldName}
    type="text"
    disabled={isSubmitting}
    placeholder="0.00"
    bold="true"
    onBlur={e =>
          isEditMode
            ? onBlurHandle(e.target.value, amountFieldName)
            : null
        }
    onChange={onAmountChangeHandler}
    error={error && error.message}
    ref={ref}
  />
));

// eslint-disable-next-line react/display-name
const IbanInput = forwardRef(({
  isEditMode,
  formattedIban,
  isSubmitting,
  onBlurHandle,
  handleIbanChange,
  error,
}, ref) => (
  <S.Input
    label="IBAN"
    onChange={handleIbanChange}
    value={formattedIban || ''}
    id={ibanFieldName}
    name={ibanFieldName}
    type="text"
    disabled={isSubmitting}
    error={error && getUiErrorMessage(error.message, 'Please enter a valid IBAN')
        }
    onBlur={e =>
          isEditMode
            ? onBlurHandle(e.target.value, ibanFieldName)
            : null
        }
    ref={ref}
  />
));

// eslint-disable-next-line react/display-name
const TextInput = forwardRef((props, ref) => {
  const {
    isEditMode,
    value,
    isSubmitting,
    fieldName,
    errorFieldName,
    onBlurHandle,
    onChange,
    error,
    label,
  } = props;
  const fieldError = getUiErrorMessage(error?.[errorFieldName]?.message);

  return (
    <S.Input
      label={label}
      onChange={e => onChange(e, fieldName)}
      value={value || ''}
      id={fieldName}
      name={fieldName}
      type="text"
      disabled={isSubmitting}
      error={error?.[errorFieldName] && fieldError}
      onBlur={e =>
        isEditMode ? onBlurHandle(e.target.value, fieldName) : null
      }
      ref={ref}
    />
  );
});

AmountInput.propTypes = {
  currency: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isEditMode: PropTypes.bool,
  netAmount: PropTypes.number.isRequired,
  onBlurHandle: PropTypes.func.isRequired,
  onAmountChangeHandler: PropTypes.func.isRequired,
  error: PropTypes.instanceOf(Object),
};

IbanInput.propTypes = {
  isEditMode: PropTypes.bool.isRequired,
  formattedIban: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onBlurHandle: PropTypes.func.isRequired,
  handleIbanChange: PropTypes.func.isRequired,
  error: PropTypes.instanceOf(Object),
};

TextInput.propTypes = {
  isEditMode: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  fieldName: PropTypes.string.isRequired,
  errorFieldName: PropTypes.string.isRequired,
  onBlurHandle: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.instanceOf(Object),
};

BeneficiarySidebar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onBeneficiarySidebarClose: PropTypes.func.isRequired,
  account: PropTypes.instanceOf(Object).isRequired,
  beneficiary: PropTypes.instanceOf(Object).isRequired,
  isEditMode: PropTypes.bool.isRequired,
  bulkPaymentId: PropTypes.string,
  addBeneficiaryToList: PropTypes.func.isRequired,
  setTotalsByCurrency: PropTypes.func.isRequired,
  setBulkPayment: PropTypes.func.isRequired,
  handleSidebarArrowClick: PropTypes.func.isRequired,
  setGlobalErrors: PropTypes.func.isRequired,
  numberOfDisplayedBeneficiaries: PropTypes.number.isRequired,
  chosenBeneficiaryIndex: PropTypes.number,
  setChosenBeneficiaryIndex: PropTypes.func.isRequired,
  setDisableContinueButton: PropTypes.func.isRequired,
};

export default BeneficiarySidebar;
