import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import S from './styles';

function CheckboxGroup(props) {
  const {
    className,
    groupName,
    isInverse = true,
    isLabelRenderedSeparately = false,
    list = [],
    onChange,
    onSelectAll,
    showIntermediate,
    showSelectAll = true,
    testId,
  } = props;
  const getIsSelectedAllStatus = useCallback(() => list.every(element => element.checked), [list]);
  const [ isAllSelected, setIsAllSelected ] = useState(getIsSelectedAllStatus());
  const [ isIntermediate, setIsIntermediate ] = useState(false);

  const getIsNotSelectedAllStatus = useCallback(() => list.every(element => !element.checked), [list]);

  useEffect(() => {
    setIsAllSelected(getIsSelectedAllStatus());
  }, [ list, getIsSelectedAllStatus ]);

  useEffect(() => {
    if (!showIntermediate) {
      return;
    }

    const isNotSelectedAll = getIsNotSelectedAllStatus();
    const isAllStatusesSelected = getIsSelectedAllStatus();

    setIsIntermediate(!isAllStatusesSelected && !isNotSelectedAll);
  }, [ list, showIntermediate, getIsNotSelectedAllStatus, getIsSelectedAllStatus ]);

  return (
    <div data-testid="checkbox-group" className={className}>
      {showSelectAll && (
        <S.CheckboxGroupSelectAllWrapper data-testid="checkbox-group-select-all">
          <S.Checkbox
            onChange={onSelectAll}
            checked={isAllSelected}
            intermediate={isIntermediate}
            isInverse={isInverse}
            data-testid={testId}
          >
            {groupName}
          </S.Checkbox>
        </S.CheckboxGroupSelectAllWrapper>
      )}
      <S.CheckboxGroupAllList data-testid="checkbox-group-list">
        {list?.length > 0 &&
          list.map(({ label, value, id, checked, secondaryLabel }) => (
            <S.CheckboxSingleWrapper key={id} data-testid="checkbox-group-single-checkbox">
              <S.Checkbox checked={checked} name={id} onChange={onChange} value={value} isInverse={isInverse}>
                {!isLabelRenderedSeparately && label}
                {secondaryLabel && (
                  <S.SecondaryLabel>
                    {secondaryLabel}
                  </S.SecondaryLabel>
                )}
              </S.Checkbox>
              {isLabelRenderedSeparately && label}
            </S.CheckboxSingleWrapper>
          ))}
      </S.CheckboxGroupAllList>
    </div>
  );
}

CheckboxGroup.propTypes = {
  className: PropTypes.string,
  groupName: PropTypes.oneOfType([ PropTypes.string, PropTypes.node ]),
  isLabelRenderedSeparately: PropTypes.bool,
  isInverse: PropTypes.bool,
  list: PropTypes.arrayOf(PropTypes.shape({
    checked: PropTypes.bool,
    id: PropTypes.string,
    label: PropTypes.oneOfType([ PropTypes.string, PropTypes.node ]),
    secondaryLabel: PropTypes.oneOfType([ PropTypes.string, PropTypes.node ]),
    value: PropTypes.oneOfType([ PropTypes.string, PropTypes.arrayOf ]),
  })),
  onChange: PropTypes.func,
  onSelectAll: PropTypes.func,
  showIntermediate: PropTypes.bool,
  showSelectAll: PropTypes.bool,
  testId: PropTypes.string,
};

export default CheckboxGroup;
