import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';

const DetailItem = styled.aside`
  padding-bottom: 1.875rem;

  @media ${DEVICE.tablet} {
    padding-bottom: 2.5rem;
  }
`;

const Label = styled.p`
  font: ${props => props.theme.fonts.bodyMSemi};
  color: ${props => props.theme.colors.greyMedium};
  margin: 0;
`;

const Value = styled.div`
  color: ${props => props.theme.colors.black};
  font: ${props => props.theme.fonts.bodyMSemi};
  margin: 0;
  padding-top: 0.3125rem;

  @media ${DEVICE.tablet} {
    padding-top: 0.5rem;
  }
`;

export default {
  DetailItem,
  Label,
  Value,
};
