import PropTypes from 'prop-types';
import React from 'react';

import { CURRENCY_FLAGS } from 'constants/currencies';

import S from './styles';

function AccountName({
  currency,
  name,
  isLarger,
  className,
}) {
  return (
    <S.Account className={className}>
      <S.FlagIcon isLarger={isLarger} countryCode={CURRENCY_FLAGS[currency]} />
      <S.Name isLarger={isLarger}>
        {name}
      </S.Name>
    </S.Account>
  );
}

AccountName.propTypes = {
  currency: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isLarger: PropTypes.bool,
  className: PropTypes.string.isRequired,
};

export default AccountName;
