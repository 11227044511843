import { Sidebar as IntergiroSidebar } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';

const ActivityWrapper = styled.div`
  position: relative;
  z-index: 2;
`;

const Sidebar = styled(IntergiroSidebar)`
  padding-right: 0;
  overflow-x: hidden;
  display: ${props => props.isOpen ? 'block' : 'none'};
  ${props => !props.isOpen && 'box-shadow: none'};

  [aria-label='Close sidebar'] {
    margin-right: 1.25rem;
  }

  > :last-of-type {
    height: calc((var(--vh) * 100) - 7.275rem);

    @media ${DEVICE.tablet} {
      height: calc((var(--vh) * 100) - 7.775rem);
    }
  }

  /* TODO Remove when https://ftcs-tech.atlassian.net/browse/IDE-11519 is implemented. */
  > :nth-child(2) {
    display: none;
  }
`;

export default {
  ActivityWrapper,
  Sidebar,
};
