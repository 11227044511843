import styled from 'styled-components';

import { ReactComponent as Check } from 'assets/icons/check.svg';

const SortWrapper = styled.div`
  border-radius: 0.375rem;
  box-shadow: ${props => props.theme.boxShadow2};
  padding: 0.5rem 0;
`;

const SortItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${props => props.theme.colors.black};
  padding: 0.625rem 1rem;
  cursor: pointer;

  &:hover {
    background: ${props => props.theme.colors.greyBright};
  }
`;

const CheckIcon = styled(Check)`
  fill: ${props => props.theme.colors.blue};
`;

const Dropdown = styled.div`
  margin-right: 1rem;

  button {
    padding-bottom: 0.1rem;
    margin: 0;
  }
`;

export default {
  SortWrapper,
  SortItem,
  CheckIcon,
  Dropdown,
};
