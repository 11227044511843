import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';

import { Dash } from 'components';

import S from './styles';

function UserDetails({ data, fieldsList }) {
  return (
    <S.Grid data-testid="details-wrapper">
      {fieldsList.map(({ label, key }) => (
        <S.GridChild key={key}>
          <S.Heading>
            {label}
          </S.Heading>
          <S.SubHeading>
            {get(data, key) || <Dash />}
          </S.SubHeading>
        </S.GridChild>
      ))}
    </S.Grid>
  );
}

UserDetails.propTypes = {
  data: PropTypes.instanceOf(Object),
  fieldsList: PropTypes.instanceOf(Array),
};

export default UserDetails;
