/* eslint-disable import/no-cycle */
/* eslint-disable no-underscore-dangle */
// ! the import/no-cycle rule was disabled for this file
// ! consider another file and import structure when refactoring this file

import apispecApiClient from '@general/apispec-api-client';
import isFunction from 'lodash/isFunction';
import isString from 'lodash/isString';

import { REDUCED_GENERAL_ERROR } from 'constants/errors';
import { FORBIDDEN, UNAUTHORIZED } from 'constants/status-code';
import { signOut } from 'utils/auth';
import { reduceApiErrorsToObject, mapBusinessError } from 'utils/errors';
import { redirectToLogin } from 'utils/location';
import { getRefreshToken, getToken, setToken } from 'utils/storage';

class ApiError extends Error {
  constructor(data, status) {
    super(data);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiError);
    }

    this.constructor = ApiError;

    // TODO check if we can use an alternative to __proto__
    // eslint-disable-next-line no-proto
    this.__proto__ = ApiError.prototype;
    this.data = data;
    this.status = status;
  }
}

async function refreshAccessToken(baseApiClient) {
  const refreshToken = getRefreshToken();

  const handleSignOut = () =>
    signOut()
      .finally(redirectToLogin);

  if (!refreshToken) {
    return handleSignOut();
  }

  try {
    const { data: { session: renewedSession } } = await baseApiClient.auth.refreshSession({ refreshToken });

    return renewedSession;
  } catch {
    return handleSignOut();
  }
}

export default function createApiClient({
  apiSpec,
  options,
  tokenBuilder,
  baseApiClient,
  responseStatusActions = {},
}) {
  const apiClient = apispecApiClient(apiSpec, options);

  // If baseApiClient isn't provided it means the current apiClient is the base.
  const defaultBaseApiClient = baseApiClient || apiClient;

  // automatically insert access token in every request
  apiClient.instance.interceptors.request.use(c => {
    const configObj = c;
    const accessToken = getToken();

    if (accessToken) {
      configObj.headers[tokenBuilder.name] = tokenBuilder.build(accessToken);
    }

    return configObj;
  },
  error => Promise.reject(error));

  // automatically refresh access token when expires
  apiClient.instance.interceptors.response.use(response => response,
    async error => {
      const originalRequest = error.config;
      const status = error.response?.status;
      const data = error.response?.data;

      if (status === UNAUTHORIZED && !originalRequest._retry) {
        originalRequest._retry = true;
        const { accessToken, refreshToken } = await refreshAccessToken(defaultBaseApiClient);

        apiClient.instance.defaults.headers.common[tokenBuilder.name] = tokenBuilder.build(accessToken);

        setToken(accessToken, refreshToken);

        if (originalRequest.data && isString(originalRequest.data)) {
          try {
            originalRequest.data = JSON.parse(originalRequest.data);
          } catch (err) {
            // Do not do anything;
          }
        }

        return apiClient.instance.request(originalRequest);
      }

      if (status === FORBIDDEN) {
        if (isFunction(responseStatusActions[FORBIDDEN])) {
          const isHandled = responseStatusActions[FORBIDDEN](data.errors);
          if (isHandled) return null;
        }
      }

      if (data?.errors) {
        const errors = reduceApiErrorsToObject(data.errors);

        throw new ApiError(errors, status);
      }

      if (data?.error) {
        const err = mapBusinessError(data.error);

        throw new ApiError(err, status);
      }

      throw new ApiError(REDUCED_GENERAL_ERROR, status);
    });

  return apiClient;
}
