import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';

const HiddenOnMobile = styled.div`
  display: none;

  @media ${DEVICE.tablet} {
    display: ${props => (props.isInline ? 'inline' : 'block')};
  }
`;

export default HiddenOnMobile;
