import { FilterStore } from 'contexts/filters';
import { filterNames } from 'pages/activity/constants';
import { getCheckedCount } from 'utils/helpers';
import { filterStatusByPermissions, filterTypeByPermissions } from 'utils/permissions';

function useFilters(canViewAccounts, userPermissions) {
  const [ filters, setFilters ] = FilterStore.useStore();

  return {
    filters,
    setFilters: filterValues =>
      setFilters(draft => {
        const copy = draft;
        copy[filterNames.type] = filterTypeByPermissions(filterValues.type, userPermissions);
        copy[filterNames.accounts] = filterValues.accounts;
        copy[filterNames.status] = filterStatusByPermissions(filterValues.status, canViewAccounts);
        copy[filterNames.date.general] = filterValues.date;
      }),
  };
}

function useFilterType(userPermissions) {
  const [{ type }, setFilters ] = FilterStore.useStore();

  return {
    type: filterTypeByPermissions(type, userPermissions),
    setType: typeValue =>
      setFilters(draft => {
        const copy = draft;
        copy[filterNames.type] = typeValue;
      }),
    selectedCount: getCheckedCount(type),
  };
}

function useFilterStatus(canViewAccounts) {
  const [{ status }, setFilters ] = FilterStore.useStore();

  return {
    status: filterStatusByPermissions(status, canViewAccounts),
    setStatus: statusValue =>
      setFilters(draft => {
        const copy = draft;
        copy[filterNames.status] = statusValue;
      }),
    selectedCount: getCheckedCount(status),
  };
}

function useFilterAccounts() {
  const [{ accounts }, setFilters ] = FilterStore.useStore();

  return {
    accounts,
    setAccounts: accountValues =>
      setFilters(draft => {
        const copy = draft;
        copy[filterNames.accounts] = accountValues;
      }),
    selectedCount: getCheckedCount(accounts),
  };
}

function useFilterDates() {
  const [{ date }, setFilters ] = FilterStore.useStore();

  return {
    date,
    setDate: dateValue =>
      setFilters(draft => {
        const copy = draft;
        copy[filterNames.date.general] = dateValue;
      }),
  };
}

export { useFilters, useFilterType, useFilterStatus, useFilterAccounts, useFilterDates };
