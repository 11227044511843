import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';

const Row = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media ${DEVICE.tablet} {
		flex-direction: row;
    width: 30rem;
	}

  @media ${DEVICE.laptop} {
		flex-direction: row;
    margin-bottom: 1.25rem;
    width: 30rem;
	}

  @media ${DEVICE.tablet} {
		flex-direction: row;
    width: 30rem;
	}
`;

const InputContainer = styled.div`
  width: 100%;
  margin-bottom: 1.25rem;

  @media ${DEVICE.laptop} {
		margin-bottom: 0;
	}
`;

export default {
  Row,
  InputContainer,
};
