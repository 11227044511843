import PropTypes from 'prop-types';
import React from 'react';

import UserItem from '../user-item';
import S from './styles';

function UsersList({ users }) {
  const renderUserItem = (user, idx) => {
    const isLastChild = users.length - 2 <= idx;

    return <UserItem user={user} key={user.id} openToTop={isLastChild} />;
  };

  return (
    <S.ListContainer>
      {users.map(renderUserItem)}
    </S.ListContainer>
  );
}

UsersList.propTypes = {
  users: PropTypes.instanceOf(Array),
};

export default UsersList;
