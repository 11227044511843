import { Button as ButtonUi } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { ReactComponent as Error } from 'assets/icons/error.svg';
import { ReactComponent as Success } from 'assets/icons/success.svg';
import { DEVICE } from 'constants/breakpoints';

const IconWrapper = styled.div`
  width: 4.5rem;
  margin: 0 auto;

  @media ${DEVICE.tablet} {
    margin: 0;
  }
`;

const ErrorIcon = styled(Error)`
  width: 4.5rem;
  height: 4.5rem;
`;

const SuccessIcon = styled(Success)`
  width: 4.5rem;
  height: 4.5rem;
`;

const Text = styled.p`
  margin: 0;
  font: ${props => props.theme.fonts.bodyM};
  padding: 2.5rem 0 1.875rem 0;
  text-align: center;

  @media ${DEVICE.tablet} {
    margin: 0;
    text-align: left;
    font: ${props => props.theme.fonts.h3Bold};
    padding: 1.875rem 0 3.75rem 0;
  }
`;

const Button = styled(ButtonUi)`
  width: calc(100vw - 3.5rem);
  margin-bottom: 0.9375rem;

  @media ${DEVICE.tablet} {
    width: inherit;
    margin-right: ${({ back }) => back && '0.5rem'};
  }
`;

export default {
  Button,
  IconWrapper,
  ErrorIcon,
  SuccessIcon,
  Text,
};
