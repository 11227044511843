import { Tabs } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { Dot as DotComponent } from 'components';
import { DEVICE } from 'constants/breakpoints';

const TabHelperText = styled.span`
  font: ${props => props.theme.fonts.bodyXsSemi};
  color: ${props => props.theme.colors.greyMediumDark};
  padding-left: 0.3125rem;
`;

const TabsHead = styled(Tabs.Head)`
  margin: 0 0 0 1.1rem;
  padding: 0 0 0.8125rem 0;

  @media ${DEVICE.tablet} {
    margin: 0;
  }
`;

const SortBarWrapper = styled.div`
  align-items: center;
  display: none;
  gap: 1.4906rem;
  grid-template-columns: repeat(2, max-content);

  @media ${DEVICE.laptop} {
    display: grid;
  }
`;

const CardContentWrapper = styled.div`
  align-items: center;
  display: flex;
`;

const LastFour = styled.div`
  color: ${props => props.theme.colors.black};
  display: inline;
  font: ${props => props.theme.fonts.bodyMSemi};
  margin: 0 0.25rem;
`;

const CardName = styled.p`
  display: inline;
  margin: 0.125rem 0 0;
  color: ${props => props.theme.colors.black};
  font: ${props => props.theme.fonts.bodyMSemi};
`;

const Dot = styled(DotComponent)`
  color: ${props => props.theme.colors.black};
`;

export default {
  CardContentWrapper,
  CardName,
  Dot,
  LastFour,
  TabsHead,
  TabHelperText,
  SortBarWrapper,
};
