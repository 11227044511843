import { object, string } from 'yup';

import {
  ADDRESS_FIELD,
  CARD_HOLDER_FIELD,
  DISPLAY_NAME_FIELD,
  DISPLAY_NAME_MAX_ALLOWED_LENGTH,
  DISPLAY_NAME_MAX_ALLOWED_LENGTH_ERROR_MESSAGE,
  EXPIRY_MONTH,
  EXPIRY_YEAR,
} from '../../constants';

export const CardDetailsSchema = object().shape({
  // eslint-disable-next-line react/forbid-prop-types
  [CARD_HOLDER_FIELD]: object(),
  [DISPLAY_NAME_FIELD]: string()
    .max(DISPLAY_NAME_MAX_ALLOWED_LENGTH, DISPLAY_NAME_MAX_ALLOWED_LENGTH_ERROR_MESSAGE),
  [EXPIRY_MONTH]: string().required('Required field'),
  [EXPIRY_YEAR]: string().required('Required field'),
  [ADDRESS_FIELD]: object()
    .nullable()
    .shape({
      city: string(),
      countryCode: string(),
      postalCode: string(),
      street: string(),
      type: string(),
    })
    .required('Please update the delivery address'),
});
