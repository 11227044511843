import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Dropdown from 'components/dots-action-dropdown';
import { accountDetailsShape } from 'pages/accounts/components/prop-types';

import S from './styles';

function DotsActionDropdown({
  accountDetails,
  setSelectedAccountDetails,
  setIsHidingModalOpen,
  setIsRenamingModalOpen,
  toggleIsAccountHidden,
  balance,
  isMainAccount,
} ) {
  const { t } = useTranslation();

  const handleDropdownClick = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const toggleAccountHidden = () => {
    const { id, displayName, isHidden, currency } = accountDetails;

    setSelectedAccountDetails({ id, displayName, isHidden, currency });

    if (!isHidden) {
      setIsHidingModalOpen(true);
    } else {
      toggleIsAccountHidden({ id, isHidden, displayName, currency });
    }
  };

  const renameAccount = () => {
    const { id, displayName, labels } = accountDetails;

    setSelectedAccountDetails({ id, displayName, labels });

    setIsRenamingModalOpen(true);
  };

  const renderActionButtons = () => {
    const actions = [
      {
        action: (<S.Action onClick={toggleAccountHidden}>
          {t('accounts.hideAccount')}
        </S.Action>),
        enabled: !accountDetails.isHidden && !isMainAccount && balance === 0,
      },
      {
        action: (<S.Action onClick={toggleAccountHidden}>
          {t('accounts.unhideAccount')}
        </S.Action>),
        enabled: accountDetails.isHidden,
      },
      {
        action: (<S.Action onClick={renameAccount}>
          {t('accounts.renameAccount')}
        </S.Action>),
        enabled: true,
      },
    ];

    return actions
      .filter(action => action.enabled)
      .map(action => action.action);
  };

  // eslint-disable-next-line
  // eslint-disable-next-line testing-library/render-result-naming-convention
  const actionButtons = renderActionButtons();
  if (actionButtons.length === 0) {
    return null;
  }

  return (
    <S.DotsActionDropdown onClick={handleDropdownClick} role="listbox" $isHidden={accountDetails.isHidden}>
      <Dropdown list={actionButtons}/>
    </S.DotsActionDropdown>
  );
}

DotsActionDropdown.propTypes = {
  balance: PropTypes.number.isRequired,
  isMainAccount: PropTypes.bool.isRequired,
  accountDetails: accountDetailsShape.isRequired,
  setSelectedAccountDetails: PropTypes.func.isRequired,
  setIsHidingModalOpen: PropTypes.func.isRequired,
  setIsRenamingModalOpen: PropTypes.func.isRequired,
  toggleIsAccountHidden: PropTypes.func.isRequired,
};

export default DotsActionDropdown;
