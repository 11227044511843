import { Button as DefaultButton } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import Hint from 'components/hint';

const UploadCSVContainer = styled.div`
  border: 0.125rem dashed ${props => props.theme.colors.greyLight};
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 0.5rem;
  cursor: pointer;
  position: relative;

  &:hover {
    background-color: ${props => props.theme.colors.greyBright};
  }
`;

const UploadCSVFileNameWrapper = styled.div`
  margin: 0 1rem;
  flex: 1
`;

const UploadCSVDetails = styled.div`
  position: relative;
  margin: 0;
  padding-left: 1rem;
`;

const UploadCSVTitle = styled.p`
  font: ${props => props.theme.fonts.bodyL};
  margin: 0;
`;

const UploadCSVSubtitle = styled.p`
  font: ${props => props.theme.fonts.bodyXs};
  font-weight: 400;
  margin: 0;
`;

const HiddenFileUploadInput = styled.input`
  display: none;
`;

const CSVTemplate = styled.p`
  margin: 0.9375rem 0 0 0;
  font: ${props => props.theme.fonts.bodyXs};
`;

const CSVTemplateLink = styled.span`
  margin: 0;
  font-weight: 600;
`;

const CSVErrorText = styled.p`
  margin: 0;
  color: ${props => props.theme.colors.red};
  font: ${props => props.theme.fonts.bodyXs};
  padding-top: 0.625rem;
`;

const HelpIconWrapper = styled.span`
  display: inline-flex;
  align-self: start;
  position: absolute;
  top: 1rem;
  right: 1rem;
  button {
    pointer-events: none;
  }
`;

const Icon = styled(DefaultButton)`
  color: ${props => props.theme.colors.black};
  align-items: center;
  margin: 0;
  padding: 0;

  svg {
    height: 0.875rem;
    width: 0.875rem;
  }
`;

const HintText = styled.div`
  font-size: 0.75rem;
  line-height: 1rem;
`;

const HintContainer = styled(Hint)`
  position: absolute;
  top: 0;
  right: 0;
`;

const ProgressBarContainer = styled.div`
  width: 100%;
`;

export default {
  UploadCSVContainer,
  UploadCSVFileNameWrapper,
  UploadCSVDetails,
  UploadCSVTitle,
  UploadCSVSubtitle,
  HiddenFileUploadInput,
  CSVTemplate,
  CSVTemplateLink,
  CSVErrorText,
  HelpIconWrapper,
  Icon,
  HintText,
  HintContainer,
  ProgressBarContainer,
};
