import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { filterCardsByType } from 'utils/card';
import { isCardsAccordionOpen, toggleCardsAccordionOpenState } from 'utils/storage';
import { PHYSICAL_CARD_TYPE, VIRTUAL_CARD_TYPE } from 'constants/card';

import S from './styles';
import Section from '../section';

function AllCards({ allCards, isGrid }) {
  const [ physicalCards, setPhysicalCards ] = useState(null);
  const [ virtualCards, setVirtualCards ] = useState(null);

  useEffect(() => {
    if (!allCards && !allCards?.length) {
      return;
    }
    const physicalCardsArr = filterCardsByType(allCards, PHYSICAL_CARD_TYPE);
    const virtualCardsArr = filterCardsByType(allCards, VIRTUAL_CARD_TYPE);
    setPhysicalCards(physicalCardsArr);
    setVirtualCards(virtualCardsArr);
  }, [allCards]);

  const getPhysicalSection = cards => ({
    id: PHYSICAL_CARD_TYPE,
    title: 'Physical cards',
    isActive: isCardsAccordionOpen(PHYSICAL_CARD_TYPE),
    content: <Section isGrid={isGrid} cards={cards} />,
  });
  const getVirtualSection = cards => ({
    id: VIRTUAL_CARD_TYPE,
    title: 'Virtual cards',
    isActive: isCardsAccordionOpen(VIRTUAL_CARD_TYPE),
    content: <Section isGrid={isGrid} cards={cards} />,
  });

  if (!allCards) return null;

  const handleAccordionClick = type => {
    toggleCardsAccordionOpenState(type);
  };

  return (
    <S.Section data-testid="cards-page-physical-cards">
      {
        physicalCards && (
          <S.Accordion
            onClick={() => handleAccordionClick(PHYSICAL_CARD_TYPE)}
            options={[getPhysicalSection(physicalCards)]}
          />
        )}
      {
        virtualCards && (
          <S.Accordion
            onClick={() => handleAccordionClick(VIRTUAL_CARD_TYPE)}
            options={[getVirtualSection(virtualCards)]}
          />
        )}
    </S.Section>
  );
}

AllCards.propTypes = {
  allCards: PropTypes.instanceOf(Array),
  isGrid: PropTypes.bool,
};

export default AllCards;
