import PropTypes from 'prop-types';
import React, { memo } from 'react';

import S from './styles';

function Dot({ className, isHidden }) {
  return (
    <S.DotWrapper className={className} isHidden={isHidden}>·</S.DotWrapper>
  );
}

Dot.propTypes = {
  className: PropTypes.string,
  isHidden: PropTypes.bool,
};

export default memo(Dot);
