import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import Dropdown from 'components/filter-bar/components/dropdown';
import DropdownFilterControl from 'pages/activity/components/dropdown-filter-control';

import S from './styles';

function VisibleAccountsSwitch({ showHiddenAccounts, onChange }) {
  const { t } = useTranslation();

  const dropdownRef = useRef();

  const handleClick = value => {
    dropdownRef.current.closeDropdown();

    onChange(value);
  };

  return (
    <S.Dropdown>
      <DropdownFilterControl
        ref={dropdownRef}
        label={(
          <Dropdown
            defaultLabel={showHiddenAccounts ? t('accounts.filters.allAccounts') : t('accounts.filters.activeAccounts')}
            name="sort"
          />
      )}
      >
        <S.SortWrapper>
          <S.SortItem onClick={() => handleClick(false)}>
            {t('accounts.filters.activeAccounts')}
            {!showHiddenAccounts && <S.CheckIcon />}
          </S.SortItem>
          <S.SortItem onClick={() => handleClick(true)}>
            {t('accounts.filters.allAccounts')}
            {showHiddenAccounts && <S.CheckIcon />}
          </S.SortItem>
        </S.SortWrapper>
      </DropdownFilterControl>
    </S.Dropdown>
  );
}

VisibleAccountsSwitch.propTypes = {
  showHiddenAccounts: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default VisibleAccountsSwitch;
