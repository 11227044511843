import PropTypes from 'prop-types';

import { CURRENCIES } from 'constants/currencies';

export const labelShape = PropTypes.shape({
  isManageable: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
});

export const accountDetailsShape = PropTypes.shape({
  id: PropTypes.string,
  displayName: PropTypes.string,
  currency: PropTypes.oneOf(Object.values(CURRENCIES)),
  isHidden: PropTypes.bool,
  labels: PropTypes.arrayOf(
    labelShape,
  ),
});
