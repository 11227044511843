import config from 'config';
import { navigation } from 'constants/navigation';

export const redirectToOnboarding = () => {
  window.location.href = `${config.onboardingUrl}/login`;
};

export const redirectToPage = url => {
  window.location.href = `${config.publicUrl}${url}`;
};

export const redirectToLogin = action => {
  const returnUrl = extractPublicUrlPartFromUrl(window.location.pathname);
  const queryParams = mapRedirectQueryParams(returnUrl, action);

  redirectToPage(`${navigation.login}${queryParams}`);
};

// Private functions

function extractPublicUrlPartFromUrl(url) {
  const publicUrlRegExp = new RegExp(`^${  config.publicUrl}`);
  const replacedUrl = url.replace(publicUrlRegExp, '');

  return replacedUrl === '/'
    ? null
    : replacedUrl;
}

function mapRedirectQueryParams(url, action) {
  const queryUrl = url ? `?return-url=${url}` : '';
  let queryAction = '';
  if (action) {
    if (url) {
      queryAction = `&action=${action}`;
    } else {
      queryAction = `?action=${action}`;
    }
  }

  return queryUrl + queryAction;
}
