import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { CircleFlag } from 'react-circle-flags';
import { v4 as uuidv4 } from 'uuid';

import { CURRENCY_FLAGS } from 'constants/currencies';
import { maxTransactionLimit } from 'constants/transactions';
import { PaymentCountContext } from 'contexts/payment-count';
import { TransactionContext } from 'contexts/transaction';
import { useView } from 'hooks';
import TransactionSummary from 'pages/activity/components/transaction-summary';
import {
  getInvertedCheckboxList,
  getSelectedAllCheckboxesList,
  getSelectedCheckboxCountDetails,
  getSelectedTransactionsById,
  getTransactionsGroupedByCurrency,
  updateElementInArray,
} from 'utils/helpers';
import { groupTransactionsByAccounts } from 'utils/transactions';

import TransactionCheckboxGroup from '../transaction-checkbox-group';
import TransactionPendingActivity from '../transaction-pending-activity';
import S from './styles';

function mapInitialAccountCheckboxList(activities) {
  return activities.map(activity => ({
    checked: false,
    label: <TransactionPendingActivity {...activity} key={`${activity.key}-${uuidv4()}`} />,
    id: activity.id,
    value: activity.id,
  }));
}

function TransactionGroupHeader({ name, length }) {
  return (
    <S.TransactionGroupContainer>
      <S.TransactionGroupNameWrapper>
        <S.TransactionGroupAccountWrapper data-testid="transaction-group-from-count">
          <S.TransactionSubHeading>From:</S.TransactionSubHeading>
          {' '}
          <S.TransactionAccountFrom>
            {name}
          </S.TransactionAccountFrom>
          <S.TransactionSubHeading marginLeft isSmall>
            (
            {length}
            )
          </S.TransactionSubHeading>
        </S.TransactionGroupAccountWrapper>
      </S.TransactionGroupNameWrapper>
    </S.TransactionGroupContainer>
  );
}
function TransactionSelectAllAccountsCheckbox({ number, isIntermediate, checked, onChange }) {
  return (
    <S.Checkbox isInverse intermediate={isIntermediate} checked={checked} onChange={onChange}>
      <S.TransactionSelectAllText>Select all</S.TransactionSelectAllText>
      {' '}
      <S.TransactionSubHeading marginLeft marginRight isSmall>
        (
        {number > maxTransactionLimit ? maxTransactionLimit : number}
        )
      </S.TransactionSubHeading>
    </S.Checkbox>
  );
}

const defaultSummary = {
  count: 0,
  groupedByCurrency: [],
};

function TransactionPendingActivities() {
  const { isDesktop } = useView();
  const { count: { numberOfPendingApprovalPayments } } = useContext(PaymentCountContext);
  const { transactions } = useContext(TransactionContext);

  const groupedTransactions = Object.entries(groupTransactionsByAccounts(transactions));
  const initialAccountCheckboxList = groupedTransactions.map(([ accountId, activity ], index) => ({
    content: mapInitialAccountCheckboxList(activity),
    currency: activity.currency,
    id: accountId,
    name: activity.name,
    key: `${accountId}-${Math.random().toString().slice(3)}-${index}`,
  }));
  const [ accountCheckboxList, setAccountCheckboxList ] = useState(initialAccountCheckboxList);
  const [ isAllChecked, setIsAllChecked ] = useState(false);
  const [ isIntermediate, setIsIntermediate ] = useState(false);
  const [ isSummaryVisible, setIsSummaryVisible ] = useState(false);
  const [ rerender, setRerender ] = useState(false);
  const [ summary, setSummary ] = useState(defaultSummary);

  function onChange(event, accountId) {
    const selectedId = event.target.id;
    const updatedList = getInvertedCheckboxList(selectedId, accountCheckboxList[accountId].content);
    const updatedObject = { ...accountCheckboxList[accountId], content: updatedList };
    const updatedArray = updateElementInArray(accountId, accountCheckboxList, updatedObject);

    setAccountCheckboxList(updatedArray);
    setRerender(true);
  }

  function onSelectedAllChange(accountId) {
    const updatedAllSelected = getSelectedAllCheckboxesList(accountCheckboxList[accountId].content);
    const updatedObject = { ...accountCheckboxList[accountId], content: updatedAllSelected };
    const updatedArray = updateElementInArray(accountId, accountCheckboxList, updatedObject);

    setAccountCheckboxList(updatedArray);
    setRerender(true);
  }

  const onSelectedAllAccounts = useCallback(() => {
    const updatedAccountCheckboxList = accountCheckboxList.map(element => ({
      ...element,
      content: element.content.map(contentElement => ({ ...contentElement, checked: !isAllChecked })),
    }));
    setIsAllChecked(!isAllChecked);
    setAccountCheckboxList(updatedAccountCheckboxList);
    setRerender(true);
  }, [ accountCheckboxList, isAllChecked ]);

  function renderTransactionTable({ content, currency, name, index }) {
    return (
      <div data-testid="pending-transaction-desktop-checkboxes">
        <TransactionCheckboxGroup
          isDesktop={isDesktop}
          groupName={<TransactionGroupHeader name={name} length={content.length} />}
          groupIcon={<CircleFlag countryCode={CURRENCY_FLAGS[currency]} height="32" />}
          list={content}
          onChange={event => onChange(event, index)}
          onSelectAll={() => onSelectedAllChange(index)}
        />
      </div>
    );
  }

  useEffect(() => {
    if (!rerender) {
      return;
    }

    const { checkedCount, checkedIds, totalCount } = getSelectedCheckboxCountDetails(accountCheckboxList);
    const selectedTransactions = getSelectedTransactionsById(groupedTransactions, checkedIds);
    const { groupedByCurrency } = getTransactionsGroupedByCurrency(selectedTransactions);

    const summaryObject = {
      count: checkedCount,
      groupedByCurrency,
    };

    const intermediate = checkedCount > 0 && checkedCount < totalCount;
    const isAllSelected = checkedCount === totalCount;
    const showShowSummary = checkedCount > 0;

    setIsIntermediate(intermediate);
    setIsAllChecked(isAllSelected);
    setIsSummaryVisible(showShowSummary);
    setSummary(sum => ({ ...sum, ...summaryObject }));
    setRerender(false);
  }, [rerender]);

  return (
    <S.PendingTransactionWrapper data-testid="pending-approval-activities">
      <S.Header data-testid="select-all-pending-transaction-wrapper">
        {isDesktop && (
          <TransactionSelectAllAccountsCheckbox
            checked={isAllChecked}
            isIntermediate={isIntermediate}
            number={numberOfPendingApprovalPayments}
            onChange={onSelectedAllAccounts}
          />
        )}
        <S.TransactionSelectAllHintWrapper>
          {maxTransactionLimit}
          {' '}
          payments max can be displayed at once
          {' '}
          <S.Hint
            text="Any additional payments will be queued and displayed when space becomes available."
            variant="warning"
          />
        </S.TransactionSelectAllHintWrapper>
      </S.Header>
      <S.Content>
        {accountCheckboxList.map(({ content, currency, name, key }, index) => (
          <S.TransactionGroupContainer key={key}>
            {renderTransactionTable({ content, currency, name, key, index })}
          </S.TransactionGroupContainer>
        ))}
      </S.Content>
      {isDesktop && <TransactionSummary {...summary} isSummaryVisible={isSummaryVisible} />}
    </S.PendingTransactionWrapper>
  );
}

TransactionGroupHeader.propTypes = {
  length: PropTypes.number,
  name: PropTypes.string,
};

TransactionSelectAllAccountsCheckbox.propTypes = {
  number: PropTypes.number,
  isIntermediate: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

export default TransactionPendingActivities;
