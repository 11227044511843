import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ModalContent } from 'components';
import { millisecondsToSeconds, minutesToMilliseconds } from 'utils/time';
import { useWindowHeight } from 'hooks';

import S from './styles';

function TimeoutModal({ onSessionContinue, onSessionStop, sessionTimeout, isOpen, setIsOpen }) {
  useWindowHeight();
  const [ timeLeft, setTimeLeft ] = useState(minutesToMilliseconds(sessionTimeout));
  const { t } = useTranslation();

  useEffect(() => {
    if (timeLeft > 0) {
      setTimeout(() => setTimeLeft(timeLeft - 1000), 1000);

      return;
    }

    onSessionStop();
  }, [timeLeft]);

  const CTAButtons = (
    <S.ButtonsWrapper>
      <S.Button
        label={t('timeoutModal.buttons.stayLoggedIn')}
        category="primary"
        onClick={() => onSessionContinue()}
        offset="true"
      />
      <S.Button
        label={t('timeoutModal.buttons.logOut')}
        category="secondary"
        onClick={() => onSessionStop()}
      />
    </S.ButtonsWrapper>
  );

  return (
    <ModalContent
      title={t('timeoutModal.title')}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onRequestClose={onSessionContinue}
      shouldCloseOnOverlayClick
      CTAButtons={CTAButtons}
    >
      <S.Text>
        {t('timeoutModal.text')}
      </S.Text>
      <S.TimeLeftText>
        <Trans i18nKey="timeoutModal.timeLeftText">
          Automatic logout in
          {' '}
          <span>
            {{ secondsLeft: millisecondsToSeconds(timeLeft) }}
          </span>
          {' '}
          seconds.
        </Trans>
      </S.TimeLeftText>
    </ModalContent>
  );
}

TimeoutModal.propTypes = {
  onSessionContinue: PropTypes.func.isRequired,
  onSessionStop: PropTypes.func.isRequired,
  sessionTimeout: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default TimeoutModal;
