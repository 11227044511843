import { Tabs } from '@general/intergiro-ui-kit';
import { Link as DefaultLink } from 'react-router-dom';
import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';

const PageWrapper = styled.div`
  @media ${DEVICE.laptop} {
    height: 100%;
  }
`;

const PageContentWrapper = styled.div`
  @media ${DEVICE.laptop} {
    height: calc(100% - 5rem);
  }
`;

const TransactionActivityWrapper = styled.div`
  transition: ${props => props.theme.transition};
  width: 100%;

  @media ${DEVICE.laptop} {
    height: 100%;
    ${props => (props.isSidebarOpen ? 'width: calc(100% - 23rem)' : '')};
  }
`;

const TabsPanel = styled(Tabs.Panel)`
  @media ${DEVICE.laptop} {
    height: calc(100% - 2.45rem);
  }
`;

const TransactionPendingActivitiesWrapper = styled.div`
  max-width: 100vw;
  
  @media ${DEVICE.laptop} {
    height: inherit;
  }
`;

const Link = styled(DefaultLink)`
  color: ${props => props.theme.colors.blue};
`;

export default {
  PageWrapper,
  PageContentWrapper,
  TabsPanel,
  TransactionPendingActivitiesWrapper,
  TransactionActivityWrapper,
  Link,
};
