import { Input } from '@general/intergiro-ui-kit';
import { isValid as isValidIban } from 'iban';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';

import { showGeneralToastError } from 'utils/errors';
import { GlobalLoaderContext } from 'contexts/global-loader';
import NotificationBlock from 'pages/send-funds/components/notification-block';
import {
  BENEFICIARY_BIC,
  BENEFICIARY_DETAILS_RESPONSE,
  BENEFICIARY_FIELDS_TO_TRIM,
  BENEFICIARY_IBAN,
  BENEFICIARY_PATHS_MAP,
  DEBOUNCE_DELAY,
  NON_SEPA_IBAN_NOTIFICATION,
  OWN_IBAN_NOTIFICATION,
} from 'pages/send-funds/constants';
import {
  formOption,
  isTransferInsideCompany,
  shouldShowNotSepaNotification,
  sortBeneficiaryDetails,
} from 'pages/send-funds/utils';
import { trimAllSpaces } from 'utils/format';
import { isSepaEnabledIban } from 'api/lookup';

import S from './styles';

function BeneficiaryDetails({
  values,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  accounts,
  setSendingToOwnIban,
}) {
  const { startLoading, endLoading } = useContext(GlobalLoaderContext);
  const [ isSepaEnabled, setIsSepaEnabled ] = useState(null);
  const mountedRef = useRef(true);

  const checkSepaIbanCallback = debounce(async value => {
    startLoading();
    const { data, error } = await isSepaEnabledIban(value);

    if (error) {
      showGeneralToastError(error);
      endLoading();

      return;
    }

    if (mountedRef.current) {
      setIsSepaEnabled(data.isSepaEnabledIban);
    }
    endLoading();
  }, DEBOUNCE_DELAY);

  useEffect(() => () => {
    mountedRef.current = false;
  }, [mountedRef]);

  const ibanValue = values[BENEFICIARY_IBAN];

  useEffect(() => {
    const value = ibanValue?.label;

    if (!value) {
      setIsSepaEnabled(null);

      return;
    }

    if (!isValidIban(value)) {
      setIsSepaEnabled(false);

      return;
    }

    checkSepaIbanCallback(value);
  }, [ibanValue]);

  const beneficiaryDetailsResponse = get(values,
    BENEFICIARY_DETAILS_RESPONSE,
    []);

  const onChange = fieldName => async event => {
    const { value } = event.target;

    const targetValue = BENEFICIARY_FIELDS_TO_TRIM.includes(fieldName)
      ? trimAllSpaces(value)
      : value;
    const option = formOption(targetValue);

    await setFieldValue(fieldName, option);
    await setFieldTouched(fieldName);
  };

  const onClear = fieldName => {
    const option = formOption('');

    setFieldValue(fieldName, option);
  };

  const showNotSepaNotification = shouldShowNotSepaNotification(values,
    isSepaEnabled);

  const showOwnIbanNotification = isTransferInsideCompany(accounts, values);

  useEffect(() => {
    setSendingToOwnIban(showOwnIbanNotification);
  }, [showOwnIbanNotification]);

  return (
    sortBeneficiaryDetails(beneficiaryDetailsResponse, BENEFICIARY_PATHS_MAP)
      .map(({ name, type }, index) => {
        const beneficiaryField = type
          ? BENEFICIARY_PATHS_MAP[`${name}.${type}`]
          : BENEFICIARY_PATHS_MAP[name];

        const beneficiaryFieldName = beneficiaryField?.fieldName;

        const enableNotification =
          (beneficiaryFieldName === BENEFICIARY_IBAN)
            && (showNotSepaNotification || showOwnIbanNotification);

        const enableOwnIbanNotification = (beneficiaryFieldName === BENEFICIARY_BIC)
          && showOwnIbanNotification;

        return (
          beneficiaryField && (
            <div key={beneficiaryFieldName} data-testid={beneficiaryFieldName}>
              <S.Row>
                <S.InputContainer>
                  <Input
                    name={beneficiaryFieldName}
                    label={beneficiaryField.label}
                    value={values[beneficiaryFieldName]?.value?.value}
                    error={Boolean(touched[beneficiaryFieldName])
                      && (errors[beneficiaryFieldName]?.label || errors[beneficiaryFieldName])}
                    onChange={onChange(beneficiaryFieldName)}
                    onClear={() => onClear(beneficiaryFieldName)}
                    autoFocus={!values[beneficiaryFieldName] && index === 0}
                    isClearable
                  />
                </S.InputContainer>
              </S.Row>
              {enableNotification && showNotSepaNotification && (
                <S.Row>
                  <NotificationBlock type={NON_SEPA_IBAN_NOTIFICATION} />
                </S.Row>
              )}
              {enableOwnIbanNotification && showOwnIbanNotification && (
                <S.Row>
                  <NotificationBlock type={OWN_IBAN_NOTIFICATION} />
                </S.Row>
              )}
            </div>
          )
        );
      })
  );
}

BeneficiaryDetails.propTypes = {
  accounts: PropTypes.instanceOf(Array).isRequired,
  beneficiaries: PropTypes.instanceOf(Array).isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
  errors: PropTypes.instanceOf(Object).isRequired,
  touched: PropTypes.instanceOf(Object).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  setSendingToOwnIban: PropTypes.func.isRequired,
};

export default BeneficiaryDetails;
