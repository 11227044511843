import { DropdownCurrencyOption as CurrencyOption } from '@general/intergiro-ui-kit';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import React, { memo, useContext, useEffect, useState } from 'react';
import { CircleFlag } from 'react-circle-flags';
import { useTranslation } from 'react-i18next';

import { getBeneficiaryRequirements } from 'api/beneficiaries';
import { ValidationWrapper } from 'components';
import { CURRENCY_FLAGS, CURRENCY_NAMES } from 'constants/currencies';
import { showGeneralToastError } from 'utils/errors';
import { GlobalLoaderContext } from 'contexts/global-loader';
import { useView } from 'hooks';
import RailsNotificationBlock from 'pages/send-funds/components/rails-notification-block';
import {
  BENEFICIARY_BANK_ACCOUNT_COUNTRY,
  BENEFICIARY_COUNTRY,
  BENEFICIARY_CURRENCY,
  BENEFICIARY_DETAILS_RESPONSE,
  BENEFICIARY_PATHS_MAP,
  BENEFICIARY_TYPE,
  BENEFICIARY_TYPE_OPTIONS,
  INTERNATIONAL_RAILS,
  LOCAL_RAILS,
  PRESELECTED_LOCAL_BANK_ACCOUNT_COUNTRIES,
  RAILS_FIELD_NAME,
  RAILS_MAP,
} from 'pages/send-funds/constants';
import {
  allowedCountries,
  checkIfEurLocalRail,
  clearAdditionalBeneficiaryFields,
  clearRequiredBeneficiaryFields,
  customerHasSparkAccounts,
  formOption,
  getAdditionalRequiredFields,
  getBeneficiaryDetailsQuery,
  getCurrenciesData,
  isBeneficiaryDetailsQueryReady,
  wasCurrencyChanged,
} from 'pages/send-funds/utils';
import config from 'config';

import S from './styles';

function SendFundsForm({
  accounts,
  values,
  setFieldValue,
  setFieldTouched,
  selectedBeneficiary,
  setSelectedBeneficiary,
  errors,
  touched,
}) {
  const { t } = useTranslation('translation', { keyPrefix: 'sendFunds.beneficiaryRequiredFields' });
  const { isMobile } = useView();
  const { startLoading, endLoading } = useContext(GlobalLoaderContext);
  const [ displayBeneficiaryFields, setDisplayBeneficiaryFields ] = useState(false);
  const [ currencyOptions, setCurrencyOptions ] = useState([]);
  const [ triggerPreselect, setTriggerPreselect ] = useState(false);
  const [ toggleValues, setToggleValues ] = useState([
    {
      name: t(isMobile ? 'local' : 'localPayment'),
      value: LOCAL_RAILS,
      selected: values[RAILS_FIELD_NAME] === LOCAL_RAILS,
      disabled: false,
    },
    {
      name: t(isMobile ? 'international' : 'internationalPayment'),
      value: INTERNATIONAL_RAILS,
      selected: values[RAILS_FIELD_NAME] === INTERNATIONAL_RAILS,
      disabled: false,
    },
  ]);

  useEffect(() => {
    setToggleValues([
      { ...toggleValues[0], name: t(isMobile ? 'local' : 'localPayment') },
      { ...toggleValues[1], name: t(isMobile ? 'international' : 'internationalPayment') },
    ]);
  }, [isMobile]);

  const [
    showPreselectedBankAccountCountry,
    setShowPreselectedBankAccountCountry,
  ] = useState(false);

  useEffect(() => {
    const newValues = toggleValues.map(switchValue => ({
      ...switchValue,
      disabled: switchValue.value === INTERNATIONAL_RAILS ? !customerHasSparkAccounts(accounts) : switchValue.disabled,
      selected: values[RAILS_FIELD_NAME] ? switchValue.value === values[RAILS_FIELD_NAME] : switchValue.selected,
    }));
    setToggleValues(newValues);
  }, [accounts]);

  useEffect(() => {
    const currencies = getCurrenciesData(accounts);
    const options = getCurrencyOptions(currencies);
    setCurrencyOptions(options);
  }, [accounts]);

  // https://ftcs-tech.atlassian.net/browse/IDE-18490
  useEffect(() => {
    const disabledLocalCurrencies = config.featureFlags.disabledLocalCurrencies.split(',');

    const newValues =
      disabledLocalCurrencies.includes(values[BENEFICIARY_CURRENCY]?.value)
        ? toggleValues.map(switchValue => ({
          ...switchValue,
          selected: switchValue.value === INTERNATIONAL_RAILS,
          disabled: switchValue.value === LOCAL_RAILS,
        }))
        : toggleValues.map(switchValue => ({
          ...switchValue,
          selected: switchValue.value === LOCAL_RAILS,
          disabled: false,
        }));

    if (isEmpty(selectedBeneficiary)) {
      const railsValue = newValues.find(switchValue => switchValue.selected).value;

      setFieldValue(RAILS_FIELD_NAME, railsValue);
    }

    setToggleValues(newValues);
  }, [values[BENEFICIARY_CURRENCY]]);

  useEffect(() => {
    if (isEmpty(selectedBeneficiary)) return;

    clearAdditionalBeneficiaryFields(setFieldValue, setFieldTouched);
    clearRequiredBeneficiaryFields(setFieldValue, setFieldTouched);

    const isInternationalPayment =
      RAILS_MAP[selectedBeneficiary.account?.paymentRail].binaryRail === INTERNATIONAL_RAILS;
    if (isInternationalPayment) {
      setToggleValues(toggleValues.map(switchValue => ({
        ...switchValue,
        selected: switchValue.value === INTERNATIONAL_RAILS,
      })));

      setFieldValue(RAILS_FIELD_NAME, INTERNATIONAL_RAILS);
    } else {
      setFieldValue(RAILS_FIELD_NAME, LOCAL_RAILS);
    }

    setTriggerPreselect(true);
  }, [selectedBeneficiary]);

  useEffect(() => {
    if (isEmpty(selectedBeneficiary)) return;

    const currencyOption = selectedBeneficiary?.account?.currency;
    const isNotLocalEurSend = checkIfEurLocalRail(currencyOption, values[RAILS_FIELD_NAME]);

    if (isNotLocalEurSend) {
      setFieldValue(BENEFICIARY_CURRENCY, renderCurrencyOption(currencyOption, CURRENCY_NAMES[currencyOption]));
      setFieldValue(BENEFICIARY_TYPE, BENEFICIARY_TYPE_OPTIONS
        .find(option => option.value === selectedBeneficiary?.entityType));
      setFieldValue(BENEFICIARY_BANK_ACCOUNT_COUNTRY, allowedCountries
        .find(country => country.value === selectedBeneficiary?.account?.country));
      setFieldValue(BENEFICIARY_COUNTRY, allowedCountries
        .find(country => country.value === selectedBeneficiary?.address?.country));
    } else if (currencyOption) {
      setFieldValue(BENEFICIARY_CURRENCY, renderCurrencyOption(currencyOption, CURRENCY_NAMES[currencyOption]));
    }
  }, [triggerPreselect]);

  const handleSwitch = value => {
    clearAdditionalBeneficiaryFields(setFieldValue, setFieldTouched);
    clearRequiredBeneficiaryFields(setFieldValue, setFieldTouched);
    const newValues = toggleValues.map(switchValue => ({
      ...switchValue,
      selected: switchValue.value === value,
    }));
    setToggleValues(newValues);

    setFieldValue(RAILS_FIELD_NAME, value);
  };

  const handleCurrencySelection = selectedOption => {
    if (!wasCurrencyChanged(selectedOption, values)) return;

    clearAdditionalBeneficiaryFields(setFieldValue, setFieldTouched);
    clearRequiredBeneficiaryFields(setFieldValue, setFieldTouched);
    setFieldValue(BENEFICIARY_CURRENCY, selectedOption);
  };

  const getCurrencyOptions = currenciesData => currenciesData?.map(({ currency, name }) =>
    renderCurrencyOption(currency, name));

  const renderCurrencyOption = (currency, name) => ({
    value: currency,
    label: (
      <CurrencyOption
        icon={memo(() => <CircleFlag countryCode={CURRENCY_FLAGS[currency]} height="20" />)}
        name={currency}
        amount={name}
      />
    ),
  });

  const getRequirements = async (beneficiaryDetailsQuery, isNotLocalEurSend) => {
    startLoading();

    const {
      data: beneficiaryDetailsResponse,
      error,
    } = await getBeneficiaryRequirements(beneficiaryDetailsQuery);

    if (error) {
      showGeneralToastError(error);

      return;
    }

    if (!isEmpty(selectedBeneficiary)) {
      tryToPrefillDetailFields(beneficiaryDetailsResponse);
      setSelectedBeneficiary({});
    }

    await setFieldValue(BENEFICIARY_DETAILS_RESPONSE, [
      ...beneficiaryDetailsResponse,
      ...getAdditionalRequiredFields(isNotLocalEurSend),
    ]);
    endLoading();
  };

  const tryToPrefillDetailFields = details => {
    details.forEach(({ name, type }) => {
      const fieldName = type ? BENEFICIARY_PATHS_MAP[`${name}.${type}`]?.fieldName
        : BENEFICIARY_PATHS_MAP[name]?.fieldName;

      const value = type ? get(selectedBeneficiary, name).value : get(selectedBeneficiary, name);

      if (fieldName && value) setFieldValue(fieldName, formOption(value));
    });
  };

  const shouldShowPreselectedBankAccountCountry = formValues =>
    (formValues[RAILS_FIELD_NAME] === LOCAL_RAILS)
      && Object.keys(PRESELECTED_LOCAL_BANK_ACCOUNT_COUNTRIES)
        .includes(formValues[BENEFICIARY_CURRENCY].value);

  useEffect(() => {
    const updatedCurrency = values[BENEFICIARY_CURRENCY]?.value;
    const isNotLocalEurSend = checkIfEurLocalRail(updatedCurrency, values[RAILS_FIELD_NAME]);
    const beneficiaryDetailsQuery = getBeneficiaryDetailsQuery(isNotLocalEurSend, values, updatedCurrency);

    !values[BENEFICIARY_DETAILS_RESPONSE]?.length && setFieldValue(BENEFICIARY_DETAILS_RESPONSE, []);

    if (isBeneficiaryDetailsQueryReady(beneficiaryDetailsQuery)) {
      getRequirements(beneficiaryDetailsQuery, isNotLocalEurSend);
    }
    setDisplayBeneficiaryFields(isNotLocalEurSend);
    const displayPreselectedBankAccountCountry = shouldShowPreselectedBankAccountCountry(values);
    setShowPreselectedBankAccountCountry(displayPreselectedBankAccountCountry);
  }, [
    values[RAILS_FIELD_NAME],
    values[BENEFICIARY_CURRENCY],
    values[BENEFICIARY_TYPE],
    values[BENEFICIARY_COUNTRY],
    values[BENEFICIARY_BANK_ACCOUNT_COUNTRY],
  ]);

  useEffect(() => {
    const preselectedLocalBankAccountCountry = PRESELECTED_LOCAL_BANK_ACCOUNT_COUNTRIES[
      values[BENEFICIARY_CURRENCY].value
    ];

    const canBePreselected = showPreselectedBankAccountCountry && values[RAILS_FIELD_NAME] !== INTERNATIONAL_RAILS;
    if (canBePreselected && preselectedLocalBankAccountCountry) {
      const selectedOption = allowedCountries.find(o => o.value === preselectedLocalBankAccountCountry);
      setFieldValue(BENEFICIARY_BANK_ACCOUNT_COUNTRY, selectedOption);
    }
  }, [
    showPreselectedBankAccountCountry,
    values[BENEFICIARY_CURRENCY],
    values[RAILS_FIELD_NAME],
  ]);

  if (isEmpty(accounts)) {
    return null;
  }

  const onSelectChange = fieldName => selectedOption => {
    if (isEqual(values[fieldName], selectedOption)) {
      return;
    }
    clearAdditionalBeneficiaryFields(setFieldValue, setFieldTouched);
    setFieldValue(fieldName, selectedOption);
  };

  const beneficiaryFields = [
    {
      name: BENEFICIARY_TYPE,
      label: 'Beneficiary type',
      options: BENEFICIARY_TYPE_OPTIONS,
      onChange: onSelectChange(BENEFICIARY_TYPE),
    },
    {
      name: BENEFICIARY_BANK_ACCOUNT_COUNTRY,
      label: 'Bank account country',
      options: allowedCountries,
      onChange: onSelectChange(BENEFICIARY_BANK_ACCOUNT_COUNTRY),
    },
    {
      name: BENEFICIARY_COUNTRY,
      label: 'Beneficiary country',
      options: allowedCountries,
      onChange: onSelectChange(BENEFICIARY_COUNTRY),
    },
  ];

  return (
    <>
      <S.Row>
        <S.InputContainer>
          <ValidationWrapper
            error={errors[BENEFICIARY_CURRENCY]}
            touched={Boolean(touched[BENEFICIARY_CURRENCY])}
          >
            <S.Dropdown
              label="Currency"
              name={BENEFICIARY_CURRENCY}
              id={BENEFICIARY_CURRENCY}
              options={currencyOptions}
              onChange={handleCurrencySelection}
              value={values[BENEFICIARY_CURRENCY]}
              isClearable={false}
            />
          </ValidationWrapper>
        </S.InputContainer>
      </S.Row>
      <S.Row>
        <S.Toggle
          values={toggleValues}
          handleChange={handleSwitch}
          id={RAILS_FIELD_NAME}
        />
      </S.Row>
      <S.Row>
        <RailsNotificationBlock
          paymentRails={values[RAILS_FIELD_NAME]}
          currency={values[BENEFICIARY_CURRENCY]?.value}
        />
      </S.Row>

      {displayBeneficiaryFields && beneficiaryFields.map(field => (
        <S.Row key={field.name}>
          <S.InputContainer>
            <ValidationWrapper
              error={errors[field.name]}
              touched={Boolean(touched[field.name])}
            >
              <S.Dropdown
                label={field.label}
                name={field.name}
                id={field.name}
                options={field.options}
                onChange={field.onChange}
                value={values[field.name]}
                isClearable={false}
                isDisabled={
                  field.name === BENEFICIARY_BANK_ACCOUNT_COUNTRY
                    ? showPreselectedBankAccountCountry
                    : false
                }
                disabled
              />
            </ValidationWrapper>
          </S.InputContainer>
        </S.Row>
      ))}
    </>
  );
}

SendFundsForm.propTypes = {
  accounts: PropTypes.instanceOf(Array).isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
  errors: PropTypes.instanceOf(Object).isRequired,
  touched: PropTypes.instanceOf(Object).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  selectedBeneficiary: PropTypes.instanceOf(Object).isRequired,
  setSelectedBeneficiary: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
};

export default SendFundsForm;
