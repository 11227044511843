import { Dropdown } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { ReactComponent as Plus } from 'assets/icons/plus.svg';
import { DEVICE } from 'constants/breakpoints';

const Wrapper = styled.div`
  max-width: 30rem;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1.875rem;
`;

const Title = styled.span`
  font: ${props => props.theme.fonts.bodyLBold};

  @media ${DEVICE.tablet} {
    font: ${props => props.theme.fonts.h3Bold};
  }
`;

const ActionButton = styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;
  font: ${props => props.theme.fonts.bodyMSemi};
  color: ${props => props.theme.colors.blue};
`;

const PlusIcon = styled(Plus)`
  margin-right: 0.5rem;
  
  path {
    fill: ${props => props.theme.colors.blue};
  }
`;

const BeneficiaryPlusIcon = styled(Plus)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.667);

  fill: ${props => props.theme.colors.black};
`;

const PlusIconWrapper = styled.div`
  width: 2rem;
  height: 2rem;
  position: relative;
  background: ${props => props.theme.colors.greyBright};
  border-radius: 50%;
  margin-right: 0.75rem;
`;

const BeneficiaryDropdown = styled(Dropdown)`
  .select__option:hover ${/* sc-selector */ PlusIconWrapper},
  .select__option--is-focused ${/* sc-selector */ PlusIconWrapper} {
    background: ${props => props.theme.colors.greyLight};
  }

  .select__indicators {
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const AddBeneficiaryOption = styled.div`
  display: flex;
  align-items: center;
  height: 2.5rem;
`;

const AddBeneficiaryOptionLabel = styled.p`
  margin: 0;
`;

const BeneficiaryName = styled.p`
  display: inline;
  margin: 0;
  color: ${props => props.theme.colors.black};
`;

const Subtitle = styled.div`
  font: ${props => props.theme.fonts.bodyLBold};
  margin: 2.5rem 0 1.25rem;

  @media ${DEVICE.tablet} {
    font: ${props => props.theme.fonts.h4Bold};
  }
`;

const BeneficiaryWrapper = styled.div`
  display: flex;
  height: 4rem;
  cursor: pointer;
  padding: 0;
  
  &:hover {
    background: ${props => props.theme.colors.greyBright};
  }

  @media ${DEVICE.tablet} {
    padding: 0 1rem;
  }
`;

export default {
  Wrapper,
  Row,
  Title,
  ActionButton,
  PlusIcon,
  PlusIconWrapper,
  AddBeneficiaryOption,
  AddBeneficiaryOptionLabel,
  BeneficiaryName,
  BeneficiaryPlusIcon,
  BeneficiaryWrapper,
  BeneficiaryDropdown,
  Subtitle,
};
