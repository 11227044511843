import { Calendar } from '@general/intergiro-ui-kit';
import debounce from 'lodash/debounce';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router';

import { dateRangeResetValues } from 'constants/date-time';
import { transformDateToURL } from 'utils/helpers';
import { useFilterDates } from 'hooks';

import S from './styles';

function DateRangeFilter() {
  const history = useHistory();
  const { date, setDate } = useFilterDates();

  const delayedOnChange = debounce(value => {
    const {
      location: { search, pathname },
    } = history;
    const url = transformDateToURL(value, search, pathname);

    if (!value) {
      setDate([ undefined, undefined ]);
      history.push(url);

      return;
    }

    setDate(value);
    history.push(url);
  }, 500);

  const onChange = useCallback(selectedDate => {
    delayedOnChange(selectedDate);
  }, [delayedOnChange]);

  const onClear = useCallback(() => {
    const url = transformDateToURL(undefined, history.location.search, history.location.pathname);
    setDate(dateRangeResetValues);
    history.push(url);
  }, []);

  return (
    <S.DateRangeWrapper>
      <Calendar isPopup displayCustomRanges value={date} onChange={onChange} onClear={onClear} />
    </S.DateRangeWrapper>
  );
}

export default DateRangeFilter;
