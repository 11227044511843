import { Button as ButtonComponent, Input as IntergiroInput, Title as TitleComponent } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';

const Button = styled(ButtonComponent)`
  font-weight: 500;
  height: 3.375rem;
  width: 100%;

  @media ${DEVICE.tablet} {
    width: 13.25rem;
  }
`;

const ControlWrapper = styled.div`
  display: flex;
  margin-top: 2rem;

  @media ${DEVICE.tablet} {
    justify-content: flex-start;
  }
`;

const Form = styled.form`
  margin-top: 2.5rem;
  max-width: 30rem;
`;

const Title = styled(TitleComponent)`
  font: ${props => props.theme.fonts.h3Bold};
  line-height: 1.2;
  margin-bottom: 0.625rem;

  @media ${DEVICE.tablet} {
    font: ${props => props.theme.fonts.h1Bold};
  }
`;

const Wrapper = styled.div`
  max-width: 34.375rem;
`;

const Input = styled(IntergiroInput)`
  margin-top: 1.25rem;
`;

const Link = styled.a`
  color: ${props => props.theme.colors.blue};
  cursor: pointer;
`;

const CheckboxWrapper = styled.div`
  margin-top: 1.875rem;

  label:last-of-type {
    margin-top: 1.375rem;
  }
`;

// temporary code, remove once IDE-13416 is done
const MerchantCheckboxWrapper = styled.div`
  margin-top: 1.375rem;
`;

export default {
  Button,
  CheckboxWrapper,
  ControlWrapper,
  Form,
  Link,
  Input,
  Title,
  Wrapper,

  // temporary code, remove once IDE-13416 is done
  MerchantCheckboxWrapper,
};
