
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { navigation } from 'constants/navigation';
import { ViewContext } from 'contexts/view';
import { useAccounts, useBeneficiaries, useView } from 'hooks';
import { WizardLayout } from 'layouts';
import { defaultLayoutProps, viewMap } from 'pages/bulk-payments/constants';
import { beneficiariesRequestOrderBy } from 'constants/beneficiaries';

import BulkPaymentsConfirmation from './components/bulk-payments-confirmation';
import BulkPaymentsFinal from './components/bulk-payments-final';
import BulkPaymentsForm from './components/bulk-payments-form';
import { filterBeneficiaries, filterSepaAccounts, getTitle } from './helpers';

function BulkPayments() {
  const { id: accountId } = useParams();
  const { isMobile } = useView();
  const history = useHistory();

  const { accounts } = useAccounts(true);

  const [ currentView, setCurrentView ] = useState(viewMap.bulkPaymentsForm);
  const [ layoutProps, setLayoutProps ] = useState(defaultLayoutProps);
  const [ beneficiariesQuery, setBeneficiariesQuery ] = useState('');

  const {
    beneficiaries, isFetching: isFetchingBeneficiaries,
  } = useBeneficiaries(
    {
      orderBy: beneficiariesRequestOrderBy.lastUsedAt,
      limit: 10,
      ...(beneficiariesQuery && { name: beneficiariesQuery }),
    },
  );
  const [ numberOfDisplayedBeneficiaries, setNumberOfDisplayedBeneficiaries ] = useState(0);
  const [ bulkPaymentData, setBulkPaymentData ] = useState(null);
  const [ totals, setTotals ] = useState(null);
  const [ currency, setCurrency ] = useState(null);
  const [ account, setAccount ] = useState(null);
  const [ success, setSuccess ] = useState(false);

  const sepaAccounts = filterSepaAccounts(accounts);
  const filteredBeneficiaries = filterBeneficiaries(beneficiaries);

  useEffect(() => {
    const title = getTitle(currentView, numberOfDisplayedBeneficiaries, success);
    const hideBackButton = currentView === viewMap.bulkPaymentsFinal && isMobile;

    setLayoutProps({ ...defaultLayoutProps, title, hideBackButton });
  }, [ currentView, numberOfDisplayedBeneficiaries ]);

  const triggerView = view => {
    switch (view) {
      case viewMap.bulkPaymentsForm:
      case viewMap.bulkPaymentsFinal:
        return history.push(navigation.accounts);
      case viewMap.bulkPaymentsConfirmation:
        return setCurrentView(viewMap.bulkPaymentsForm);
      default:
        return {};
    }
  };

  const getPageContent = () => {
    switch (currentView) {
      case viewMap.bulkPaymentsForm:
        return (
          <BulkPaymentsForm
            accounts={sepaAccounts}
            account={account}
            beneficiaries={filteredBeneficiaries}
            isFetchingBeneficiaries={isFetchingBeneficiaries}
            setBeneficiariesQuery={setBeneficiariesQuery}
            predefinedAccountId={accountId}
            bulkPaymentData={bulkPaymentData}
            setNumberOfDisplayedBeneficiaries={setNumberOfDisplayedBeneficiaries}
            setBulkPaymentData={setBulkPaymentData}
            setTotals={setTotals}
            setCurrency={setCurrency}
            setAccount={setAccount}
            setCurrentView={setCurrentView}
          />
        );
      case viewMap.bulkPaymentsConfirmation:
        return (
          <BulkPaymentsConfirmation
            bulkPaymentId={bulkPaymentData.id}
            bulkPaymentData={bulkPaymentData}
            numberOfPayments={numberOfDisplayedBeneficiaries}
            totals={totals}
            currency={currency}
            sourceAccountName={account.value.name}
            setSuccess={setSuccess}
            setCurrentView={setCurrentView}
          />
        );
      case viewMap.bulkPaymentsFinal:
        return (
          <BulkPaymentsFinal
            success={success}
            totals={totals}
            currency={currency}
          />
        );
      default:
        return null;
    }
  };

  const viewValues = useMemo(() => ({ currentView, triggerView }), [currentView]);

  return (
    <ViewContext.Provider value={viewValues}>
      <WizardLayout {...layoutProps}>
        {getPageContent()}
      </WizardLayout>
    </ViewContext.Provider>
  );
}

export default BulkPayments;
