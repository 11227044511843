import { ActionDropdown } from '@general/intergiro-ui-kit';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import S from './styles';

function DotsActionDropdown({ list, disabled, openToTop }) {
  const [ isActive, setIsActive ] = useState(false);

  const handleDropdownToggle = isOpen => {
    setIsActive(isOpen);
  };

  return (
    <ActionDropdown openToTop={openToTop} items={list} onDropdownToggle={handleDropdownToggle}>
      <S.DotsWrapper disabled={disabled} isActive={isActive}>
        <S.Dots />
      </S.DotsWrapper>
    </ActionDropdown>
  );
}

DotsActionDropdown.propTypes = {
  list: PropTypes.instanceOf(Array).isRequired,
  disabled: PropTypes.bool,
  openToTop: PropTypes.bool,
};

export default DotsActionDropdown;
