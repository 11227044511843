import styled from 'styled-components';

const TotalContainer = styled.div`
  padding-left: ${props => (props.sidebar ? '0' : '0.9375rem')};
  padding-bottom: ${props => (props.sidebar ? '1.875rem' : '0')};
`;

const Row = styled.div`
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: ${props => props.paddingTop || 0};
`;

const Text = styled.p`
  margin: 0;
  font: ${props => props.theme.fonts.bodyM};
  font-weight: ${props => (props.bold ? 600 : 'inherit')};
  color: ${props => props.black ? props.theme.colors.black : props.theme.colors.greyMedium};
`;

const Separator = styled.hr`
  opacity: 0.1;
  border-top: 0.0625rem solid ${props => props.theme.colors.black};
  margin: 1.25rem 0;
  margin-top: ${props => props.marginTop || '1.25rem'};
  margin-bottom: ${props => props.marginBottom || '1.25rem'};
`;

export default {
  TotalContainer,
  Row,
  Text,
  Separator,
};
