export const BAD_REQUEST = 400;

export const UNAUTHORIZED = 401;

export const FORBIDDEN = 403;

export const NOT_FOUND = 404;

export const CONFLICT = 409;

export const TOO_MANY_REQUESTS = 429;
