import bff from 'utils/bff';
import pci from 'utils/pci';

export async function listCards(params) {
  const { data } = await bff.card.listCards({}, params);

  return data;
}

export async function getCard(id) {
  const { data } = await bff.card.getCard({}, { id });

  return data;
}

export async function getCardLimits(id) {
  const { data } = await bff.card.getCardLimits({}, { id });

  return data;
}

export async function freezeCard(id) {
  const { data } = await bff.card.freezeCard({}, { id });

  return data;
}

export async function unFreezeCard(id) {
  const { data } = await bff.card.unFreezeCard({}, { id });

  return data;
}

export async function fraudCard(id) {
  const { data } = await bff.card.fraudCard({}, { id });

  return data;
}

export async function lostCard(id) {
  const { data } = await bff.card.lostCard({}, { id });

  return data;
}

export async function stolenCard(id) {
  const { data } = await bff.card.stolenCard({}, { id });

  return data;
}

export async function terminateCard(id) {
  const { data } = await bff.card.terminateCard({}, { id });

  return data;
}

export async function resetPin(id) {
  try {
    const { data } = await bff.card.resetPin({}, { id });

    return { data };
  } catch (error) {
    return { error };
  }
}

// PCI API

export async function activateCard(id, payload, authenticationCode) {
  const headers = { 'x-authentication-code': authenticationCode };

  const { data } = await pci.card.activateCard(payload, { id }, headers);

  return data;
}

export async function setPin(id, payload, authenticationCode) {
  const headers = { 'x-authentication-code': authenticationCode };

  const { data } = await pci.card.setPin(payload, { id }, headers);

  return data;
}

export async function getCardCredentials(id, authenticationCode) {
  const headers = { 'x-authentication-code': authenticationCode };

  const { data } = await pci.card.getCard({}, { id }, headers);

  return data;
}

export async function createSca(data) {
  const { data: sca } = await pci.sca.createSca(data);

  return sca;
}

export async function confirmSca(id, payload) {
  const { data } = await pci.sca.confirmSca(payload, { id });

  return data;
}
