import { adapters } from 'constants/payment-processor';

export const purposeMap = {
  bank: 'BANK',
};

export const purposes = Object.values(purposeMap);

export const cardAccountsQuery = {
  purpose: purposeMap.bank,
  paymentProcessorAdapter: adapters.bol,
};
