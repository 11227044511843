import React from 'react';
import { Trans } from 'react-i18next';
import PropTypes from 'prop-types';

function InfoText({ amount, account }) {
  return (
    <Trans i18nKey="accounts.outstandingAmountMessage">
      Please recover the outstanding amount of
      {' '}
      <b>
        {{ amount }}
      </b>
      {' '}
      for
      {' '}
      <b>
        {{ account }}
      </b>
      {' '}
      to enable outgoing
      transfers. This can be done either by depositing corresponding amount directly to your
      {' '}
      {{ account }}
      , or
      by sending equivalent amount to your
      {' '}
      {{ account }}
      {' '}
      from any other of your accounts with sufficient
      balance.
    </Trans>
  );
}

InfoText.propTypes = {
  amount: PropTypes.number.isRequired,
  account: PropTypes.string.isRequired,
};

export default InfoText;
