import styled from 'styled-components';

import { hex2rgba } from 'helpers/styles';

export const StyledTrigger = styled.i`
  display: flex;
  
  svg {
    fill: ${props => props.theme.colors.greyMediumDark};
    transition: ${props => props.theme.transition};
    
    &:hover {
      fill: ${props => props.theme.colors.black};
    }
  }
`;

export const StyledWrapper = styled.span`
  display: inline-flex;
`;

export const StyledTooltip = styled.span`
  display: block;
  background-color: ${props => props.theme.colors.black};
  border-radius: 0.5rem;
  box-shadow: 0 0.375rem 1rem ${props => hex2rgba(props.theme.colors.black, 0.1)};
  box-sizing: border-box;
  color: ${props => props.theme.colors.greyBright};
  font: ${props => props.theme.fonts.bodyXsSemi};
  letter-spacing: 0.01em;
  width: ${props => props.width || '12rem'};
  padding: 0.625rem;
  text-align: left;
  z-index: 1;

  @media only screen and (max-width: 14.25rem) {
    max-width: 100%;
  }
`;
