import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import {
  FRIDAY_DAY_NUMBER,
  FX_DEFAULT_TIMEZONE,
  FX_FRIDAY_END_HOUR,
  FX_SATURDAY_END_HOUR,
  FX_SATURDAY_END_MINUTE,
  FX_SATURDAY_END_SECOND,
  FX_SUNDAY_START_HOUR,
  FX_SUNDAY_START_MINUTE,
  SATURDAY_DAY_NUMBER,
  SUNDAY_DAY_NUMBER,
} from 'constants/fx';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isBetween);

export function isFxWeekend() {
  const isBetweenWeekStartPart = dayjs()
    .tz(FX_DEFAULT_TIMEZONE)
    .isBetween(makeCetDate(),
      makeCetDate(SUNDAY_DAY_NUMBER,
        FX_SUNDAY_START_HOUR,
        FX_SUNDAY_START_MINUTE));
  const isBetweenWeekEndPart = dayjs()
    .tz(FX_DEFAULT_TIMEZONE)
    .isBetween(makeCetDate(FRIDAY_DAY_NUMBER, FX_FRIDAY_END_HOUR),
      makeCetDate(
        SATURDAY_DAY_NUMBER,
        FX_SATURDAY_END_HOUR,
        FX_SATURDAY_END_MINUTE,
        FX_SATURDAY_END_SECOND,
      ));

  return isBetweenWeekStartPart || isBetweenWeekEndPart;
}

function makeCetDate(
  day = 0, hour = 0, minute = 0, second = 0,
) {
  return dayjs()
    .tz(FX_DEFAULT_TIMEZONE)
    .day(day)
    .hour(hour)
    .minute(minute)
    .second(second);
}
