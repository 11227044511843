import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ModalContent } from 'components';
import { accountDetailsShape } from 'pages/accounts/components/prop-types';

import S from '../../styles';

function HideAccountModal({ isOpen, setIsOpen, hideAccount, accountDetails }) {
  const { t } = useTranslation();

  const handleHide = () => {
    hideAccount(accountDetails);

    setIsOpen(false);
  };

  const CTAButtons = (
    <>
      <S.ModalButton
        label={t('accounts.hide')}
        category="primary"
        type="button"
        onClick={handleHide}
      />
      <S.ModalButton label={t('accounts.cancel')} category="secondary" onClick={() => setIsOpen(false)} />
    </>
  );

  return (
    <ModalContent
      title={`${t('accounts.hideAccountModalTitle')} ${accountDetails.displayName}`}
      hasCloseIcon
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      type="message"
      CTAButtons={CTAButtons}
    >
      <S.ModalContent>
        {t('accounts.hideAccountModalMessage')}
      </S.ModalContent>
    </ModalContent>
  );
}

HideAccountModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  hideAccount: PropTypes.func.isRequired,
  accountDetails: accountDetailsShape.isRequired,
};

export default HideAccountModal;
