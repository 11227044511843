import { useContext, useEffect, useState } from 'react';

import { showGeneralToastError } from 'utils/errors';
import { getMerchants } from 'api/merchants';
import { GlobalLoaderContext } from 'contexts/global-loader';

const useMerchants = () => {
  const [ merchants, setMerchants ] = useState(null);
  const { startLoading, endLoading } = useContext(GlobalLoaderContext);

  useEffect(() => {
    const fetchMerchants = async () => {
      startLoading();
      try {
        const data = await getMerchants();

        setMerchants(data);
      } catch (error) {
        showGeneralToastError(error);
      } finally {
        endLoading();
      }
    };

    fetchMerchants();
  }, []);

  return { merchants };
};

export default useMerchants;
