import bff from 'utils/bff';

export const getMerchants = async () => {
  const { data: merchants } = await bff.merchant.listMerchants();

  return merchants;
};

export const getMerchantPrivateKey = async id => {
  const { data } = await bff.merchant.getMerchantPrivateKey({}, { id });

  return data.privateKey;
};
