import { Button as DefaultButton, Title as TitleComponent } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { ReactComponent as PencilIcon } from 'assets/icons/pencil.svg';
import { DEVICE } from 'constants/breakpoints';

const Title = styled(TitleComponent)`
	font-size: 1.375rem;
	margin: 1.875rem 0 0;
	padding: 0 1.25rem;

	@media ${DEVICE.tablet} {
		margin: 2.5rem 0 1.25rem;
		padding: 0;
	}
`;

const Wrapper = styled.div`
  padding: 1.875rem 1.25rem;

  @media ${DEVICE.tablet} {
    padding: 1.875rem 0;
  }

  @media ${DEVICE.laptop} {
    align-items: flex-start;
    display: grid;
    grid-gap: 3.75rem;
    grid-template-columns: 20.9375rem 1fr;
    margin-top: 1.875rem;
    padding: 0;
  }
`;

const Button = styled(DefaultButton)`
  margin-right: 0.625rem;
`;

const EditValue = styled.div`
  display: inline-block;
  margin-right: 0.75rem;
`;

const EditBtn = styled(PencilIcon)`
  cursor: pointer;
  fill: ${props => props.theme.colors.blue};
`;

export default {
  Title,
  Wrapper,
  Button,
  EditValue,
  EditBtn,
};
