import { permissionsMap } from 'constants/permissions';
import { transactionViewFilterMap } from 'constants/transactions';

const typeList = [
  {
    value: transactionViewFilterMap.bankOutgoing,
    label: 'Outgoing transfer',
    permissions: [permissionsMap.corporateAccountsManagementRead],
  },
  {
    value: transactionViewFilterMap.bankIncoming,
    label: 'Incoming transfer',
    permissions: [permissionsMap.corporateAccountsManagementRead],
  },
  {
    value: transactionViewFilterMap.cardIncoming,
    label: 'Incoming card payment',
    permissions: [
      permissionsMap.individualCardsManagementRead,
      permissionsMap.otherCardsManagementRead,
    ],
  },
  {
    value: transactionViewFilterMap.cardOutgoing,
    label: 'Outgoing card payment',
    permissions: [
      permissionsMap.individualCardsManagementRead,
      permissionsMap.otherCardsManagementRead,
    ],
  },
  {
    value: transactionViewFilterMap.feePayment,
    label: 'Fee payment',
    permissions: [permissionsMap.corporateAccountsManagementRead],
  },
];

export default typeList;
