import PropTypes from 'prop-types';

export const profileTypes = PropTypes.shape({
  address: PropTypes.shape({
    city: PropTypes.string,
    country_code: PropTypes.string,
    postal_code: PropTypes.string,
    street: PropTypes.string,
  }),
  created_at: PropTypes.string,
  date_of_birth: PropTypes.string,
  email: PropTypes.string,
  first_name: PropTypes.string,
  gender: PropTypes.string,
  id: PropTypes.string,
  last_name: PropTypes.string,
  phone: PropTypes.string,
  role: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  status: PropTypes.string,
}).isRequired;
