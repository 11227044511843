import dayjs from 'dayjs';
import jwtDecode from 'jwt-decode';
import get from 'lodash/get';
import isObject from 'lodash/isObject';
import truncate from 'lodash/truncate';

import { cardExpirationDateFormat, defaultFullDateTimeLocalFormat, MONTH, YEAR } from 'constants/date-time';
import { CARD_EXPIRATION_IN_YEARS, defaultCardCreationData, cardScaAuthenticationElements } from 'constants/card';
import { getToken } from 'utils/storage';

const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);

export const filterCardsByType = (cards, type) => cards.filter(card => card.type === type);

export const isUserCard = (card, userId) => {
  const {
    cardholder: { referenceId },
  } = card;

  return referenceId === userId;
};

// parse UTC time as local time - don't apply local timezone offset
const parseInLocalTimezone = time => dayjs(time.format(defaultFullDateTimeLocalFormat));

export const maxExpirationDate = parseInLocalTimezone(dayjs()
  .utc()
  .add(CARD_EXPIRATION_IN_YEARS, YEAR)
  .endOf(MONTH));

/**
 * Return object of expiration year and month
 * @returns {{ year: string, month: string }}
 */
export const getFormattedExpiry = () => ({
  [YEAR]: String(maxExpirationDate.get(YEAR)),
  [MONTH]: String(maxExpirationDate.get(MONTH) + 1).padStart(2, '0'),
});

/**
 * Converts object of expiration year and month into Date format
 * @param {{ year: string, month: string }}
 * @returns Date
 */
export const unFormatDate = ({ month, year }) => {
  const monthNumber = month > 0 ? month - 1 : 0;
  const date = new Date(year, monthNumber);

  return date;
};

export const formatExpirationDate = (expiry, template = cardExpirationDateFormat) => {
  const date = unFormatDate(expiry);

  return dayjs(date).format(template);
};

/**
 * Converts object of expiration year and month into "year-month" format
 * @param {object} { year: string, month: string }
 * @returns string
 */

export const convertDateToApiFormat = ({ month, year }) => `${year}-${month}`;

/**
 * Converts string of expiration year and month from "year-month" format to object {month, year}
 * @param {string} year-month
 * @returns object {month, year}
 */
export const convertDateFromApiFormatToObject = expiration => {
  if (isObject(expiration)) {
    return expiration;
  }

  const expArr = expiration.split('-');

  return {
    month: expArr[1],
    year: expArr[0],
  };
};

export const cleanCardOrderRequestBody = cardOrder => {
  const token = getToken();
  const decodedData = jwtDecode(token);
  const corporationId = get(decodedData, 'claims.corporationId');
  const lineItems = cardOrder.lineItems.map(item => ({
    ...item,
    issueRequest: {
      ...item.issueRequest,
      card: {
        ...item.issueRequest.card,
        ...defaultCardCreationData.card,
        type: cardOrder.cardType.toUpperCase(),
        expiry: convertDateFromApiFormatToObject(item.issueRequest.card.expiry),
        cardHolder: undefined,
        companyName: undefined,
        isExistingUser: undefined,
        profile: undefined,
      },
      index: undefined,
      templateId: defaultCardCreationData.templateId,
      account: item.issueRequest.existingAccount ? { referenceId: item.issueRequest.existingAccount } : undefined,
      cardholder: { referenceId: item.issueRequest.card.cardHolder },
      company: { id: corporationId },
    },
    cardOrderId: undefined,
    createdAt: undefined,
    updatedAt: undefined,
    feeAmount: undefined,
    id: undefined,
    status: undefined,
  }));

  return {
    ...cardOrder,
    lineItems,
  };
};

export function getCardHolderTruncatedName(cardholder, limit = 17) {
  const cardHolderName = `${cardholder.firstName} ${cardholder.lastName}`;
  const truncatedName = truncate(cardHolderName, { length: limit });

  return truncatedName;
}

export function getMonthInTwoDigits(month) {
  return String(month).padStart(2, '0');
}

export function getScaData(sca) {
  const { challenge } = sca.authenticationElements
    .find(authenticationElement => authenticationElement.type === cardScaAuthenticationElements.smsOtp);

  return {
    id: challenge.challengeId,
    scaId: sca.id,
    ...challenge,
  };
}

export function getConfirmScaData(challenge) {
  return {
    authenticationElements: [
      {
        type: cardScaAuthenticationElements.smsOtp,
        challenge: {
          challengeId: challenge.id,
          nonce: challenge.nonce,
        },
      },
    ],
  };
}
