import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { navigateToNotFoundPage, showGeneralToastError } from 'utils/errors';
import { getRole } from 'api/roles';
import { GlobalLoaderContext } from 'contexts/global-loader';
import { NOT_FOUND } from 'constants/status-code';
import { resourceType } from 'constants/resource';

const useRole = id => {
  const history = useHistory();
  const { startLoading, endLoading } = useContext(GlobalLoaderContext);
  const [ role, setRole ] = useState({});

  useEffect(() => {
    if (!id) return;

    const fetchRole = async () => {
      startLoading();

      try {
        const roleData = await getRole(id);

        setRole(roleData);
      } catch (error) {
        error.status === NOT_FOUND
          ? navigateToNotFoundPage(history, resourceType.role)
          : showGeneralToastError(error);
      } finally {
        endLoading();
      }
    };

    fetchRole();
  }, [ id, startLoading, endLoading ]);

  return { role, setRole };
};

export default useRole;
