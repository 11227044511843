import { Button as ButtonComponent } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';

const Button = styled(ButtonComponent)`
  display: ${props => (props.isHiddenOnMobile ? 'none' : 'flex')};
  font-size: 1rem;
  line-height: 1.375;
  min-width: auto;
  letter-spacing: 0.02em;
  padding: 0.375rem 0.875rem;

  &:first-child {
    margin-right: 0.5rem;
  }

  @media ${DEVICE.tablet} {
    font-size: 1.125rem;
    font-weight: 500;
    padding-left: 1.125rem;
    padding-right: 0.875rem;
    width: auto;

    &:last-child {
      display: flex;
    }

    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
`;

const Controls = styled.div`
  display: flex;
  align-items: center;
`;

export default { Button, Controls };
