import React from 'react';

import { WithNavbarHeader } from 'components';
import AccountsProvider from 'contexts/accounts';
import FiltersProvider from 'contexts/filters';
import TransactionProvider from 'contexts/transaction';

import Transactions from './components/transactions';

function Activity() {
  return (
    <AccountsProvider>
      <TransactionProvider>
        <WithNavbarHeader title="Activity" />
        <FiltersProvider>
          <Transactions />
        </FiltersProvider>
      </TransactionProvider>
    </AccountsProvider>
  );
}

export default Activity;
