import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';

const Amount = styled.span``;

const CustomFormattedAmount = styled.h3`
  font: ${props => props.theme.fonts.h3Bold};
  margin: 0;
  text-align: center;
  width: 100%;

  @media ${DEVICE.tablet} {
    font-size: 2.625rem;
  }
`;

const DecimalAmount = styled.span`
  font: ${props => props.theme.fonts.bodyLBold};

  @media ${DEVICE.tablet} {
    font-size: 2rem;
  }
`;

const Currency = styled.span`
  font: ${props => props.theme.fonts.h3};

  @media ${DEVICE.tablet} {
    font: ${props => props.theme.fonts.h1};
  }
`;

export default {
  Amount,
  CustomFormattedAmount,
  DecimalAmount,
  Currency,
};
