import styled from 'styled-components';

import { userStatusesMap } from 'constants/user';
import { hex2rgba } from 'helpers/styles';

const Status = styled.div`
  background: ${props => getColorByStatus(props)};
  color: ${props => props.theme.colors.white};
  font-size: 0.8125rem;
  font-weight: 500;
  border-radius: 0.8125rem;
  padding: 0.0625rem 0.625rem 0.1875rem;
  text-align: center;
  display: inline-block;
`;

// Private functions

function getColorByStatus(props) {
  switch (props.status) {
    case userStatusesMap.activated:
      return hex2rgba(props.theme.colors.green, 0.8);
    case userStatusesMap.invited:
      return props.theme.colors.blue;
    case userStatusesMap.blocked:
    case userStatusesMap.offboarded:
      return props.theme.colors.red;
    case userStatusesMap.notInvited:
    case userStatusesMap.dropped:
    case userStatusesMap.rejected:
      return props.theme.colors.greyMediumDark;
    default:
      return props.theme.colors.white;
  }
}

export default {
  Status,
};
