import bff from 'utils/bff';

export const getBulkPayment = async bulkPaymentId => {
  const { data: bulkPaymentItem } = await bff.bulkPayment.getBulkPayment({}, { id: bulkPaymentId });

  return bulkPaymentItem;
};

export const createBulkPayment = async payload => {
  const { data: bulkPayment } = await bff.bulkPayment.createBulkPayment(payload, {});

  return bulkPayment;
};

export const createBulkPaymentAndUploadCsv = async payload => {
  const headers = { 'Content-Type': 'multipart/form-data' };

  const { data: bulkPayment } = await bff.bulkPayment.createBulkPaymentAndUploadCsv(payload, {}, headers);

  return bulkPayment;
};

export const updateBulkPayment = async (bulkPaymentId, accountId) => {
  const { data: bulkPayment } = await bff.bulkPayment.updateBulkPayment({ accountId },
    { id: bulkPaymentId });

  return bulkPayment;
};

export const sendBulkPayment = async (bulkPaymentId, payload = {}) => {
  const { data: bulkPayment } = await bff.bulkPayment.sendBulkPayment(payload, { id: bulkPaymentId });

  return bulkPayment;
};

export const removeBulkPayment = async bulkPaymentId => {
  const { data: bulkPayment } = await bff.bulkPayment.removeBulkPayment({}, { id: bulkPaymentId });

  return bulkPayment;
};

// Bulk payment item

export const addBulkPaymentItem = async (bulkPaymentId, payload) => {
  const { data: bulkPaymentItem } = await bff.bulkPayment.addBulkPaymentItem(payload, { id: bulkPaymentId });

  return bulkPaymentItem;
};

export const updateBulkPaymentItem = async (bulkPaymentId, itemId, payload) => {
  const { data: bulkPaymentItem } = await bff.bulkPayment.updateBulkPaymentItem(payload, { id: bulkPaymentId, itemId });

  return bulkPaymentItem;
};

export const removeBulkPaymentItem = async (bulkPaymentId, itemId) => {
  const { data: bulkPaymentItem } = await bff.bulkPayment.removeBulkPaymentItem({}, { id: bulkPaymentId, itemId });

  return bulkPaymentItem;
};
