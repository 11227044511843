import { object, string } from 'yup';
import { isValidNumber } from 'libphonenumber-js';

export const updateUserDetailsSchema = object().shape({
  otherPhone: string()
    .required('user.validation.otherPhone.required')
    .test('phone_validation', 'user.validation.otherPhone.invalid', value => {
      const number = value?.charAt(0) === '+' ? value : `+${value}`;

      return isValidNumber(number);
    }),
});

export default updateUserDetailsSchema;
