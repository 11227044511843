import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import config from '../config';

export default function setupSentry() {
  if (!config.sentryApiKey) {
    return;
  }

  // Will be removed when we'll prepare the releases with codebase mapping
  // https://ftcs-tech.atlassian.net/browse/IDE-12062
  const release = 'cp-new';
  Sentry.init({
    dsn: config.sentryApiKey,
    environment: config.envName,
    enabled: config.envName !== 'development' && config.envName !== 'test',
    integrations: [new Integrations.BrowserTracing()],
    dist: 'customer-portal',
    release,
    tracesSampleRate: 1.0,
  });
}
