import bff from 'utils/bff';

export const listKeys = async () => {
  const { data: apiKeys } = await bff.apiKey.listKeys({});

  return apiKeys;
};

export const renewKey = async apiKeyId => {
  const { data: apiKey } = await bff.apiKey.renewKey({}, { id: apiKeyId });

  return apiKey;
};

export const removeKey = async apiKeyId => {
  const { data: apiKey } = await bff.apiKey.removeKey({}, { id: apiKeyId });

  return apiKey;
};

export const createKey = async payload => {
  const { data: apiKey } = await bff.apiKey.createKey(payload);

  return apiKey;
};
