import PropTypes from 'prop-types';
import React, { cloneElement, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ResolveModal, TwoFaModal } from 'components';
import { approvalActions } from 'constants/api';
import TwoFaProvider from 'contexts/two-fa';
import { showGeneralToastError, rethrowTwoFaError } from 'utils/errors';
import { getApprovalRequestMethodByAction, showSuccessNotificationByAction } from 'utils/pending-approval';
import { getBeneficiaries } from 'api/beneficiary';
import config from 'config';

function ResolveApiPayment({
  netAmount,
  currency,
  bulkPaymentId,
  approveButton,
  rejectButton,
  onSuccess,
}) {
  const { t } = useTranslation();
  const [ resolveApiPaymentModalIsOpen, setResolveApiPaymentModalIsOpen ] = useState(false);
  const [ resolveAction, setResolveAction ] = useState(null);

  const [ twoFaModalIsOpen, setTwoFaModalIsOpen ] = useState(false);
  const [ twoFaData, setTwoFaData ] = useState(null);

  const [ showTrustBeneficiaryToggle, setShowTrustBeneficiaryToggle ] = useState(false);
  const [ resolveParams, setResolveParams ] = useState(null);

  function handleOpenResolveTransactionModal(action) {
    return async event => {
      event.stopPropagation();

      if (config.featureFlags.enableBeneficiariesTrustedFlow && action === approvalActions.approveApiPayment) {
        const {
          numberOfRecords: numberOfNonTrustedBeneficiaries,
        } = await getBeneficiaries({ bulkPaymentId, isTrusted: false, limit: 1 });

        setShowTrustBeneficiaryToggle(numberOfNonTrustedBeneficiaries > 0);
      }

      if (action === approvalActions.rejectApiPayment) {
        setShowTrustBeneficiaryToggle(false);
      }

      setResolveAction(action);
      setResolveApiPaymentModalIsOpen(true);
    };
  }

  function getScaData(challenge) {
    const sca = challenge ? { challenge } : {};

    return {
      currency,
      amount: netAmount,
      bulkPaymentId,
      beneficiary: t('beneficiary.sca.multipleBeneficiaries'),
      ...sca,
    };
  }

  const handleResolveTransaction = useCallback(async ({ isTrusted }) => {
    const data = getScaData();

    try {
      const { challenge } = await getApprovalRequestMethodByAction(resolveAction, { ...data, isTrusted });

      if (challenge) {
        setTwoFaData(challenge);
        setResolveParams({ isTrusted });
        setTwoFaModalIsOpen(true);

        return;
      }

      onSuccess();
      showSuccessNotificationByAction(resolveAction);
    } catch (error) {
      showGeneralToastError(error, { extractFromApi: true });
    }
  }, [ bulkPaymentId, resolveAction ]);

  const handleConfirmCode = useCallback(async challenge => {
    const data = getScaData(challenge);

    try {
      await getApprovalRequestMethodByAction(resolveAction, { ...data, ...resolveParams });
      setTwoFaModalIsOpen(false);
      showSuccessNotificationByAction(resolveAction);
      onSuccess();
    } catch (error) {
      rethrowTwoFaError(error);
      setTwoFaModalIsOpen(false);
      showGeneralToastError(error, { extractFromApi: true });
    }
  }, [ bulkPaymentId, resolveAction, resolveParams ]);

  const handleResendCode = useCallback(async (factor, scaId) => {
    const data = getScaData({ preferredFactor: factor, scaId });
    const response = await getApprovalRequestMethodByAction(resolveAction, data);
    setTwoFaData(response?.challenge);

    return response;
  }, [ bulkPaymentId, resolveAction ]);

  return (
    <>
      {cloneElement(approveButton, { onClick: handleOpenResolveTransactionModal(approvalActions.approveApiPayment) })}
      {cloneElement(rejectButton, { onClick: handleOpenResolveTransactionModal(approvalActions.rejectApiPayment) })}

      {resolveApiPaymentModalIsOpen && (
        <ResolveModal
          isOpen={resolveApiPaymentModalIsOpen}
          setIsOpen={setResolveApiPaymentModalIsOpen}
          onSubmit={handleResolveTransaction}
          action={resolveAction}
          showTrustBeneficiaryToggle={showTrustBeneficiaryToggle}
        />
      )}
      {twoFaModalIsOpen && (
        <TwoFaProvider twoFaData={twoFaData}>
          <TwoFaModal
            isOpen={twoFaModalIsOpen}
            setIsOpen={setTwoFaModalIsOpen}
            onSubmit={handleConfirmCode}
            onResend={handleResendCode}
            challenge={twoFaData}
          />
        </TwoFaProvider>
      )}
    </>
  );
}

ResolveApiPayment.propTypes = {
  netAmount: PropTypes.number,
  currency: PropTypes.string,
  bulkPaymentId: PropTypes.string,
  approveButton: PropTypes.element,
  rejectButton: PropTypes.element,
  onSuccess: PropTypes.func,
};

export default ResolveApiPayment;
