export const adapters = {
  saxo: 'Saxo',
  bol: 'BoL',
  onpex: 'Onpex',
  spark: 'Spark',
};

export const isBol = adapter => adapter === adapters.bol;

export const isSpark = adapter => adapter === adapters.spark;

export const supportedUIPaymentRails = [
  'swift',
  'sepa',
];
