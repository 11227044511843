import bff from 'utils/bff';

export function approve(data) {
  return bff.approvalRequest
    .approve(data)
    .then(({ data: approvalRequest }) => approvalRequest)
    .catch(error => Promise.reject(error));
}

export function reject(data) {
  return bff.approvalRequest
    .reject(data)
    .then(({ data: approvalRequest }) => approvalRequest)
    .catch(error => Promise.reject(error));
}

export function bulkApprove(data) {
  return bff.approvalRequest
    .bulkApprove(data)
    .then(({ data: approvalRequests }) => approvalRequests)
    .catch(error => Promise.reject(error));
}

export function bulkReject(data) {
  return bff.approvalRequest
    .bulkReject(data)
    .then(({ data: approvalRequests }) => approvalRequests)
    .catch(error => Promise.reject(error));
}
