import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { theme } from 'theme';
import { BACK_PREVIEW_IN_S } from 'constants/card';

import S from '../details/styles';

function CountdownProgressBar({ flipped, setFlipped, onClick }) {
  const [ counter, setCounter ] = useState(0);

  useEffect(() => {
    let timer;
    if (counter > 0) {
      timer = setTimeout(() => setCounter(counter - 1), 1000);
    } else {
      setFlipped(false);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [counter]);

  useEffect(() => {
    if (flipped) {
      setCounter(BACK_PREVIEW_IN_S);
    }
  }, [flipped]);

  const radius = 23;
  const circumference = Math.ceil(2 * Math.PI * radius);
  const fillPercents =
    Math.abs(Math.ceil(circumference - (circumference / BACK_PREVIEW_IN_S) * (BACK_PREVIEW_IN_S - counter)));
  const transition = {
    duration: 1,
    delay: 0.05,
    ease: 'easeIn',
  };
  const variants = {
    hidden: {
      strokeDashoffset: circumference,
      transition,
    },
    show: {
      strokeDashoffset: fillPercents,
      transition,
    },
  };

  return (
    <S.ActionButton label="Card details"
      icon={flipped ? (
        <S.CountDownContainer key="progressBar">
          <AnimatePresence>
            <svg
              viewBox="0 0 100 100"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              width={100}
              height={100}
              key="progressBarStroke"
            >
              <circle
                cx="50"
                cy="50"
                r={radius}
                className="circle"
                strokeWidth={0}
                stroke={theme.colors.black}
                strokeOpacity={0.25}
                fill="transparent"
                key="progressBar"
              />
            </svg>
            <svg
              viewBox="0 0 100 100"
              width={100}
              height={100}
              style={{
                position: 'absolute',
                transform: 'rotate(-90deg)',
                overflow: 'visible',
                marginLeft: -100,
              }}
              key="progressBar"
            >
              <motion.circle
                cx="50"
                cy="50"
                r={radius}
                strokeWidth={3}
                stroke={theme.colors.black}
                fill="transparent"
                strokeDashoffset={fillPercents}
                strokeDasharray={circumference}
                variants={variants}
                initial="hidden"
                animate="show"
                key="progressBar"
              />
            </svg>
          </AnimatePresence>
          <S.BackIcon />
        </S.CountDownContainer>
      ) : <S.BackIcon />}
      onClick={onClick}
    />
  );
}

CountdownProgressBar.propTypes = {
  flipped: PropTypes.bool,
  setFlipped: PropTypes.func,
  onClick: PropTypes.func,
};
export default CountdownProgressBar;
