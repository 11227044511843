import 'config/i18n';

import React from 'react';
import ReactDOM from 'react-dom';

import setupSentry from 'integration/sentry';
import plausibleTrackPageviews from 'integration/plausible';

import App from './App';
import suppressFitWarning from './helpers/console';

// Integration
suppressFitWarning();
setupSentry();

plausibleTrackPageviews();

ReactDOM.render(<App />, document.getElementById('root'));
