import PropTypes from 'prop-types';
import React, { memo } from 'react';

import { apiPaymentShape, mappedTransactionShape } from 'local-prop-types';

import Payment from './components/payment';

function PaymentList({ payments, handlePaymentClick, activePayment, isGroupPayments }) {
  return (
    <>
      {payments.map(payment => (
        <Payment
          key={payment.createdAt}
          handlePaymentClick={handlePaymentClick}
          activePayment={activePayment}
          payment={payment}
          isGroupPayment={isGroupPayments}
        />
      ))}
    </>
  );
}

PaymentList.propTypes = {
  payments: PropTypes.oneOfType([
    PropTypes.arrayOf(apiPaymentShape),
    PropTypes.arrayOf(PropTypes.shape(mappedTransactionShape)),
  ]),
  handlePaymentClick: PropTypes.func.isRequired,
  activePayment: PropTypes.oneOfType([ apiPaymentShape, PropTypes.shape(mappedTransactionShape) ]),
  isGroupPayments: PropTypes.bool,
};

export default memo(PaymentList);
