export const MOVE_FUNDS_GENERAL_MESSAGE = `
  You can move funds from your EUR SEPA account to any other EUR account.
  To move funds to a different currency account or send funds in a non-EUR
  currency, you will need to move the funds to your EUR SWIFT account first.
`;

export const MOVE_FUNDS_NOT_SAME_PAYMENT_PROCESSOR_MESSAGE = `
  Typically your moved funds will arrive in your destination
  account within a couple of hours. However this may take longer
  if outside normal business hours.
`;

export const MOVE_FUNDS_FX_WEEKEND_MESSAGE = `
  Currency conversion services are currently only available Sunday 
  23:02 CET - Friday 23:00 CET. Please try again during these hours.
`;
