import { Input } from '@general/intergiro-ui-kit';
import { useFormik } from 'formik';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { BAD_REQUEST, UNAUTHORIZED } from 'constants/status-code';
import { HIGH_SCORE_COMPLEXITY } from 'constants/inputs';
import { ERRORS } from 'constants/errors';
import { showGeneralToastError } from 'utils/errors';
import { confirmResetPassword } from 'api/auth';

import S from './styles';
import { SetNewPasswordSchema } from './schema';

function SetNewPassword({ challengeId, nonce }) {
  const history = useHistory();
  const [ passwordScore, setPasswordScore ] = useState({});
  const isLowScore = passwordScore?.score < passwordScore?.minScore;

  const onPasswordScoreChange = event => {
    if (!isEqual(passwordScore, event)) {
      setPasswordScore(event);
    }
  };

  const setPassword = async password => {
    if (isLowScore) {
      setFieldError('password', 'Password is not strong enough');

      return;
    }

    try {
      await confirmResetPassword({
        challenge: { password, id: challengeId, nonce },
      });
      toast.success('You have successfully reset password. Please login to use your account');
      history.push('/login');
    } catch (error) {
      if (error.status === BAD_REQUEST) {
        setErrors(error.data);

        return;
      }
      if (error.status === UNAUTHORIZED) {
        toast.error(ERRORS.requestExpired);

        return;
      }

      showGeneralToastError(error);
    }
  };

  const formik = useFormik({
    initialValues: { password: '' },
    onSubmit: values => setPassword(values.password),
    validationSchema: SetNewPasswordSchema,
  });

  const {
    handleSubmit,
    handleChange,
    setErrors,
    setFieldError,
    touched,
    errors,
    isSubmitting,
  } = formik;

  return (
    <S.Wrapper>
      <S.Title>Set new password</S.Title>
      <S.Form onSubmit={handleSubmit}>
        <Input label="Create password"
          onChange={handleChange}
          onScoreChange={onPasswordScoreChange}
          id="password"
          name="password"
          isPassword
          isCheckingStrength
          scoreComplexity={HIGH_SCORE_COMPLEXITY}
          disabled={isSubmitting}
          error={touched.password && errors.password}
          autoFocus
        />
        <S.ControlWrapper>
          <S.Button type="submit" label="Submit" disabled={isSubmitting} />
        </S.ControlWrapper>
      </S.Form>
    </S.Wrapper>
  );
}

SetNewPassword.propTypes = {
  challengeId: PropTypes.string.isRequired,
  nonce: PropTypes.string.isRequired,
};

export default SetNewPassword;
