import { toIndivisibleUnit, toMainUnit, formatAmount as formatAmountToCommaFormat } from 'utils/amount';
import { trimAllSpaces } from 'utils/format';

import { titlesMap, viewMap } from './constants';
import {
  amountFieldName,
  beneficiaryNameFieldName,
  bicFieldName,
  EUR,
  ibanFieldName,
  referenceFieldName,
  SEPA,
  velocityOverflow,
  volumeOverflow,
} from './constants/form';

export function filterSepaAccounts(accounts) {
  return accounts.filter(account => account.requisites.paymentRail === SEPA && account.currency === EUR);
}

export function filterBeneficiaries(beneficiaries) {
  return beneficiaries.filter(beneficiary =>
    beneficiary?.account?.number?.type === ibanFieldName
    && beneficiary?.account?.bankCode?.type === bicFieldName);
}

export function formatBeneficiaries(data) {
  const { items, errors, totalsByCurrency } = data;

  const totals = totalsByCurrency;

  const beneficiaries = items.map(beneficiary => {

    const { currency } = beneficiary;

    return {
      id: beneficiary.id,
      name: beneficiary.beneficiary.name,
      reference: beneficiary.reference,
      amount: toMainUnit(beneficiary.netAmount, currency),
      account: {
        currency,
        number: { value: beneficiary.beneficiary.accountIban },
        bankCode: { value: beneficiary.beneficiary.accountBic },
      },
      fee: toMainUnit(beneficiary.feeAmount, currency),
      feeReason: beneficiary.feeReason,
      net: toMainUnit(beneficiary.netAmount, currency),
      totalAmount: toMainUnit(beneficiary.totalAmount, currency),
      order: beneficiary.order,
      errors: [],
    };
  });

  errors.map(error => {
    const errorArr = error.field.split('.');
    const index = Number(errorArr[1]);

    beneficiaries[index].errors.push({ ...error, fieldName: errorArr[3] || errorArr[2] });

    return error;
  });

  return {
    items: beneficiaries,
    totals,
    errors,
  };
}

export function getBeneficiaryPayload(values, accountId, currency) {
  return {
    accountId,
    currency,
    netAmount: values[amountFieldName] === ''
      ? 0
      : toIndivisibleUnit(values[amountFieldName], currency) || undefined,
    reference: values[referenceFieldName],
    beneficiary: {
      name: values[beneficiaryNameFieldName],
      accountIban: trimAllSpaces(values[ibanFieldName]),
      accountBic: trimAllSpaces(values[bicFieldName]),
    },
  };
}

export function hasThreshold(feeReason) {
  return feeReason === velocityOverflow || feeReason === volumeOverflow;
}

export function getTitle(currentView, numberOfBeneficiaries, success) {
  switch (true) {
    case currentView === viewMap.bulkPaymentsForm && numberOfBeneficiaries === 1:
      return `${numberOfBeneficiaries} ${titlesMap.paymentTitle}`;

    case currentView === viewMap.bulkPaymentsForm && numberOfBeneficiaries > 1:
      return `${numberOfBeneficiaries} ${titlesMap.paymentsTitle}`;

    case currentView === viewMap.bulkPaymentsConfirmation:
      return titlesMap.confirmationTitle;

    case currentView === viewMap.bulkPaymentsFinal && success:
      return titlesMap.successTitle;

    case currentView === viewMap.bulkPaymentsFinal && !success:
      return titlesMap.errorTitle;

    default:
      return titlesMap.defaultTitle;
  }
}

// Private function

const formatNumberAmount = amount => (Math.round(amount * 100) / 100).toFixed(2);

const formatStringAmount = amount => {
  if (amount.indexOf('.') > 0) {
    return amount;
  }

  const value = formatNumberAmount(Number(amount));

  return value;
};

const formatApiAmount = amount => {
  if (amount.indexOf('.') > 0) {
    return amount;
  }

  const fractionalPart = String(amount).slice(String(amount).length - 2);
  const integerPart = String(Math.floor(amount / 100));
  const combined = `${integerPart}.${fractionalPart}`;

  return combined;
};

export const formatAmount = (amount, api = false) => {
  if (!amount) {
    return amount;
  }

  if (typeof amount === 'string' || amount instanceof String) {
    return api ? formatApiAmount(amount) : formatStringAmount(amount);
  }

  return formatNumberAmount(amount);
};

export const getConfirmationContent = (accountName, currency, totals, accountTitleComponent) => ({
  from: {
    label: 'From',
    value: accountTitleComponent({ currency, name: accountName }),
  },
  transferFee: {
    label: 'Transfer fees',
    value: `${formatAmountToCommaFormat(totals[currency.toLowerCase()]?.fee)} ${currency}`,
  },
  total: {
    label: 'Total',
    value: `${formatAmountToCommaFormat(totals[currency.toLowerCase()]?.total)} ${currency}`,
  },
});
