import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';

const Heading = styled.p`
  color: ${props => props.theme.colors.greyMedium};
  font: ${props => props.theme.fonts.bodyM};
  margin: 0 0 0.5rem;
`;

const Grid = styled.div`
  display: flex;
  flex-direction: column;

  @media ${DEVICE.laptop} {
    display: grid;
    grid-template-columns: repeat(2, 20rem);
    column-gap: 4rem;
  }
`;

const GridChild = styled.div`
  margin-top: 1.25rem;

  @media ${DEVICE.laptop} {
    margin-top: 0;
    margin-bottom: 2.5rem;
  }
`;

const SubHeading = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  display: inline-block;
  color: ${props => props.theme.colors.black};
  font: ${props => props.theme.fonts.bodyMSemi};
`;

export default { Heading, Grid, GridChild, SubHeading };
