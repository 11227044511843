import { useContext, useEffect, useState } from 'react';

import { showGeneralToastError } from 'utils/errors';
import { listPendingApprovalPayments } from 'api/pending-approval-payments';
import { GlobalLoaderContext } from 'contexts/global-loader';
import { PaymentCountContext } from 'contexts/payment-count';
import { TransactionContext } from 'contexts/transaction';

function usePendingApprovalList() {
  const { startLoading, endLoading, resetLoadingDelay } = useContext(GlobalLoaderContext);
  const { count: { numberOfPendingApprovalPayments } } = useContext(PaymentCountContext);
  const { setTransactions } = useContext(TransactionContext);

  const [ isLoading, setIsLoading ] = useState(true);
  const [ meta, setMeta ] = useState(null);

  async function getAllActivities() {
    try {
      setIsLoading(true);
      startLoading();
      const { data, numberOfRecords, hasMore } = await listPendingApprovalPayments();

      setMeta({ numberOfRecords, hasMore });
      setTransactions(data);
    } catch (error) {
      showGeneralToastError(error);
    } finally {
      setIsLoading(false);
      resetLoadingDelay();
      endLoading();
    }
  }

  useEffect(() => {
    if (numberOfPendingApprovalPayments === null) {
      return;
    }

    getAllActivities(true);
  }, [numberOfPendingApprovalPayments]);

  return { isLoading, meta };
}

export default usePendingApprovalList;
