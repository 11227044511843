import { Tabs } from '@general/intergiro-ui-kit';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useHistory } from 'react-router-dom';

import { WithNavbarHeader } from 'components';
import { navigation } from 'constants/navigation';
import AccountsProvider from 'contexts/accounts';
import FiltersProvider from 'contexts/filters';
import PaymentProvider from 'contexts/payment';
import { PaymentCountContext } from 'contexts/payment-count';
import TransactionProvider from 'contexts/transaction';

import APIPayments from './components/api-payments';
import PendingApproval from './components/pending-approval';
import S from './styles';

function Payments() {
  const { t } = useTranslation();
  const history = useHistory();
  const { count: { numberOfPendingApprovalPayments, numberOfApiPayments } } = useContext(PaymentCountContext);

  useEffect(() => {
    if (history.location.pathname === navigation.payments) history.push(navigation.pendingApproval);
  }, [history.location.pathname]);

  return (
    <AccountsProvider>
      <WithNavbarHeader title={t('payments.title')} />
      <Tabs.Container name="tabs">
        <S.TabsHead id="pending-approval" isActive={history.location.pathname === navigation.pendingApproval} handleChange={() => history.push(navigation.pendingApproval)}>
          {t('pendingApproval.tabs.pendingApproval')}
          {' '}
          {numberOfPendingApprovalPayments && numberOfPendingApprovalPayments > 0 ? (
            <S.TransactionCounter>
              {numberOfPendingApprovalPayments}
            </S.TransactionCounter>
            ) : null}
        </S.TabsHead>
        <S.TabsHead id="api-payments" isActive={history.location.pathname === navigation.apiPayments} handleChange={() => history.push(navigation.apiPayments)}>
          {t('pendingApproval.tabs.apiPayments')}
          {' '}
          {numberOfApiPayments && numberOfApiPayments > 0 ? (
            <S.TransactionCounter>
              {numberOfApiPayments}
            </S.TransactionCounter>
            ) : null}
        </S.TabsHead>
      </Tabs.Container>
      <Switch>
        <Route path={navigation.pendingApproval}>
          <TransactionProvider isPendingApproval>
            <FiltersProvider>
              <PendingApproval />
            </FiltersProvider>
          </TransactionProvider>
        </Route>
        <Route path={navigation.apiPayments}>
          <PaymentProvider>
            <APIPayments />
          </PaymentProvider>
        </Route>
      </Switch>
    </AccountsProvider>
  );
}

export default Payments;
