import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

import gellixMediumWoff from 'assets/fonts/Gellix-Medium.woff';
import gellixMediumWoff2 from 'assets/fonts/Gellix-Medium.woff2';
import gellixRegularWoff from 'assets/fonts/Gellix-Regular.woff';
import gellixRegularWoff2 from 'assets/fonts/Gellix-Regular.woff2';
import gellixSemiBoldWoff from 'assets/fonts/Gellix-SemiBold.woff';
import gellixSemiBoldWoff2 from 'assets/fonts/Gellix-SemiBold.woff2';
import { DEVICE } from 'constants/breakpoints';

import { theme } from './theme';

export const GlobalStyle = createGlobalStyle`
    ${normalize}

    @font-face {
      font-display: fallback;
      font-family: "Gellix";
      font-style: normal;
      font-weight: 400;
      src: local("Gellix"),
        url(${gellixRegularWoff2}) format("woff2"),
        url(${gellixRegularWoff}) format("woff");
    }

    @font-face {
      font-display: fallback;
      font-family: "Gellix";
      font-style: normal;
      font-weight: 500;
      src: local("Gellix-Medium"),
        url(${gellixMediumWoff2}) format("woff2"),
        url(${gellixMediumWoff}) format("woff");
    }

    @font-face {
      font-display: fallback;
      font-family: "Gellix";
      font-style: normal;
      font-weight: 600;
      src: local("Gellix-SemiBold"),
        url(${gellixSemiBoldWoff2}) format("woff2"),
        url(${gellixSemiBoldWoff}) format("woff");
    }


    html, body {
      height: 100%;
      font-size: 1rem;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-rendering: optimizeLegibility;
      background-color: ${theme.colors.white};
    }

    #root {
      height: 100%;
    }

    body {
      font-family: Gellix, Arial, sans-serif;
    }

    * {
      box-sizing: border-box;

      &:focus {
        outline: none;
      }
    }

    button, a {
      -webkit-tap-highlight-color: transparent;
    }

    a {
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }
    }

    .overflow-hidden-mobile {
      overflow: hidden;

      @media ${DEVICE.tablet} {
        overflow: auto;
      }
    }

    .intercom-lightweight-app {
      display: none;

      @media ${DEVICE.tablet} {
        display: block;
      }
    }
`;
