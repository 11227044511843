// for testing
export const dataTestIds = {
  pendingApprovalDataTestId: 'pending-approval-data-test-id',
  transactionsDataTestId: 'transactions-data-test-id',
  isSidebarOpenDataTestId: 'is-sidebar-open-data-test-id',
  activeTransactionDataTestId: 'active-transaction-data-test-id',
  activityWrapperDataTestId: 'activity-wrapper-data-test-id',
  transactionSidebarDataTestId: 'transaction-sidebar-data-test-id',
};

export const contextProps = {
  activeTransaction: null,
  isSidebarOpen: false,
  transactions: [
    {
      createdAt: '2022-05-17',
      createdBy: { name: 'System', userId: 'Hk0egjObl' },
      currency: 'EUR',
      debitedAmount: 100,
      externalFee: 0,
      fromAccount: { code: 'FTCSSESS', displayName: 'EUR SEPA Account', iban: 'SE7597700000010009577825', id: 'e7ax1f5KO', name: 'EUR SEPA Account' },
      id: 'Hvy8fWROY',
      isMultiCurrencyTransaction: false,
      netTransactionAmount: 100,
      reference: 'Movement between Accounts in EUR',
      sourceAmount: 100,
      sourceCurrency: 'EUR',
      status: 'completed',
      targetAmount: 100,
      targetCurrency: 'EUR',
      toAccount: { code: 'FTCSSESS', displayName: 'EUR SEPA Account 3', iban: 'SE1497700000010008744723', id: 'I8shLYs9r', name: 'EUR SEPA Account 3' },
      totalFee: 0,
      type: 'bank-incoming',
    },
    {
      createdAt: '2022-05-17',
      createdBy: { name: 'Miopt Miopt', userId: '79ea98c0-1110-452d-9eba-dbf335034e73' },
      currency: 'EUR',
      debitedAmount: 100,
      externalFee: 0,
      fromAccount: { code: 'FTCSSESS', displayName: 'EUR SEPA Account', iban: 'SE7597700000010009577825', id: 'e7ax1f5KO', name: 'EUR SEPA Account' },
      id: 'sFKmStc7uM',
      isMultiCurrencyTransaction: false,
      netTransactionAmount: 100,
      reference: 'Movement between Accounts in EUR',
      sourceAmount: 100,
      sourceCurrency: 'EUR',
      status: 'completed',
      targetAmount: 100,
      targetCurrency: 'EUR',
      toAccount: { code: 'FTCSSESS', displayName: 'EUR SEPA Account 3', iban: 'SE1497700000010008744723', id: 'I8shLYs9r', name: 'EUR SEPA Account 3' },
      totalFee: 0,
      type: 'bank-outgoing',
    },
  ],
};
