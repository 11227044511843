import pluralize from 'pluralize';

export const ERRORS = {
  generalError: 'Something went wrong. \n Please try again.',
  tryInXMinutes: minutes => `The previous attempt is in progress. Try again in ${minutes > 1 ? pluralize('minute', minutes, true) : 'a minute'}`,
  invalidPermissions: 'Sorry, you have no permissions to view the content',
  requestExpired: 'Your request has expired. Please try again',
};

// The general error snapshot stub
// the reducer: src/utils/errors.js:reduceApiErrorsToObject()
export const REDUCED_GENERAL_ERROR = { default: { message: ERRORS.generalError } };

export const SYSTEM_TO_UI_ERROR_MESSAGES = [
  {
    system: 'There should be no more than 300 transactions in bulk payment',
    ui: 'Transaction item limit (300) reached',
  },
  {
    system: 'Bulk payment is already sent',
    ui: 'Bulk payment is already sent',
  },
  {
    system: 'MVP: only EUR transactions allowed',
    ui: 'Only EUR transactions are allowed',
  },
  {
    system: 'MVP: only one wallet can be used for the bulk payment',
    ui: 'Only one source wallet can be used',
  },
  { system: 'CSV: unable to parse file', ui: 'Unable to read file' },
  {
    system: 'Amount +"\\S+" must have no more than 2 fractional digits',
    ui: 'File with invalid amount',
  },
  {
    system: 'At least one transaction should be defined for sending',
    ui: 'This file is empty',
  },
  {
    system: 'Bulk payment was updated while 2FA is in progress',
    ui: 'Bulk payment was updated while 2FA is in progress',
  },
  {
    system: 'Payment Details is required',
    ui: 'Payment details is required',
  },
  {
    system: 'Payment Details length should be not more than 140 characters',
    ui: 'Max 140 character limit',
  },
  {
    system: 'Beneficiary Account Number is invalid',
    ui: 'Please enter a valid IBAN',
  },
  {
    system: 'Beneficiary Account Code is invalid',
    ui: 'Please enter a valid BIC',
  },
  {
    system: 'Beneficiary Name is required',
    ui: 'Beneficiary Name is required',
  },
  {
    system: 'Not allowed',
    ui: 'Not allowed: ^ & # $ @',
  },
  {
    system: 'Beneficiary first and last name length should be not more than 35 characters',
    ui: 'Max 35 character limit for first and last name combined',
  },
];
