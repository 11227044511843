import { Button as DefaultButton } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { CancelButton as Cancel } from 'components';
import { DEVICE } from 'constants/breakpoints';

const Button = styled(DefaultButton)`
  margin: 0;
  margin-bottom: 0.9375rem;
  width: 100%;
  ${props => props.currencySelected ? '' : 'display: none'};

  @media ${DEVICE.tablet} {
    margin-top: 2.625rem;
    width: auto;
  }
`;

const MoveButton = styled(DefaultButton)`
  width: 100%;
  margin-bottom: 0.9375rem;
  
  @media ${DEVICE.tablet} {
    width: auto;
    margin-left: 0.5rem;
  }
`;

const CancelButton = styled(Cancel)`
  display: block;
  width: 100%;
  ${props => props.currencySelected ? '' : 'display: none'};

  @media ${DEVICE.tablet} {
    display: none;
  }
`;

export default {
  Button,
  CancelButton,
  MoveButton,
};
