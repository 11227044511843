import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React from 'react';

import { transactionActivityShape } from 'local-prop-types';
import { transactionGroupedByDateFormat } from 'constants/date-time';

import Transaction from './components/transaction';
import S from './styles';

function TransactionList(props) {
  const { activities, date } = props;

  const formattedDate = dayjs(date).format(transactionGroupedByDateFormat);

  return (
    <section data-testid="transaction-activity-group">
      <S.TransactionActivitiesHeading>
        {formattedDate}
      </S.TransactionActivitiesHeading>
      {activities.map(activity => (
        <Transaction {...activity} key={`transaction-list-${activity.id}`} />
      ))}
    </section>
  );
}

TransactionList.propTypes = {
  activities: PropTypes.arrayOf(PropTypes.shape(transactionActivityShape)).isRequired,
  date: PropTypes.string.isRequired,
};

export default TransactionList;
