import PropTypes from 'prop-types';
import React, { useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import { useFilterStatus } from 'hooks';
import DropdownFilterCheckbox from 'pages/activity/components/dropdown-filter-checkbox';
import DropdownFilterControl from 'pages/activity/components/dropdown-filter-control';
import { filterNames } from 'pages/activity/constants';
import { getInvertedCheckboxList, setFiltersToUnchecked } from 'utils/helpers';

import updateURLToMatchParameters from './helpers';
import FilterDropdown from './dropdown';
import { handleSelectAll } from '../utils';

function StatusFilter({ canViewAccounts }) {
  const { t } = useTranslation();
  const history = useHistory();
  const ref = useRef(null);
  const { selectedCount, status, setStatus } = useFilterStatus(canViewAccounts);
  const [ availableStatuses, setAvailableStatuses ] = useState(status);

  const onChange = useCallback(event => {
    const selectedId = event.target.name;
    const updatedList = getInvertedCheckboxList(selectedId, availableStatuses);
    setAvailableStatuses(updatedList);
  }, [ setStatus, history, status ]);

  const onClear = useCallback(() => {
    const updatedFilter = setFiltersToUnchecked(status);
    setStatus(updatedFilter);
    setAvailableStatuses(updatedFilter);
    updateURLToMatchParameters(updatedFilter, filterNames.status, history);
    ref.current.closeDropdown();
  }, [ status, history, setStatus ]);

  const onApply = useCallback(() => {
    setStatus(availableStatuses);
    updateURLToMatchParameters(availableStatuses, filterNames.status, history);

    ref.current.closeDropdown();
  }, [ availableStatuses, status ]);

  const onSelectAll = useCallback(() => {
    const allSelectedStatuses = handleSelectAll(availableStatuses);
    setAvailableStatuses(allSelectedStatuses);
  }, [availableStatuses]);

  return (
    <DropdownFilterControl
      label={(
        <FilterDropdown
          count={selectedCount}
          defaultLabel={t('components.filterBar.status.dropdown.defaultLabel')}
          name="status"
          selectedLabel={t(selectedCount > 1 ? 'components.filterBar.status.dropdown.selectedPluralLabel' : 'components.filterBar.status.dropdown.selectedLabel')}
        />
      )}
      ref={ref}
    >
      <DropdownFilterCheckbox
        name={filterNames.status}
        list={availableStatuses}
        onChange={onChange}
        onClear={onClear}
        onApply={onApply}
        onSelectAll={onSelectAll}
      />
    </DropdownFilterControl>
  );
}

StatusFilter.propTypes = {
  canViewAccounts: PropTypes.bool.isRequired,
};

export default StatusFilter;
