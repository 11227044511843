import filter from 'lodash/filter';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import set from 'lodash/set';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';

import { canOrderCards, updatePendingCardOrder } from 'api/cards';
import getCorporation from 'api/corporation';
import getApprovedAccountUsers from 'api/persons';
import { showGeneralToastError } from 'utils/errors';
import { GlobalLoaderContext } from 'contexts/global-loader';
import { UserContext } from 'contexts/user';
import CanOrderErrorModal from 'pages/order-cards/components/can-order-error-modal';
import {
  ADDRESS_FIELD,
  CARD_HOLDER_FIELD,
  CARD_TYPE_SWITCH,
  COMPANY_NAME,
  deliveryAddressTypeMap,
  DISPLAY_NAME_FIELD,
  EXPIRY,
  SUMMARY,
  sidebarMode,
  newAccount,
} from 'pages/order-cards/constants';
import { getFormattedExpiry } from 'utils/card';
import CardOrderTable from 'pages/order-cards/components/card-order-table';
import { useView } from 'hooks';
import { VIRTUAL_CARD_TYPE } from 'constants/card';
import CardDetailsItem from 'pages/order-cards/components/card-details-item';

import CardAccountDetails from '../card-account-details';
import S from './styles';

const initialCardObject = {
  [CARD_HOLDER_FIELD]: null,
  [DISPLAY_NAME_FIELD]: '',
  [EXPIRY]: getFormattedExpiry(),
  [ADDRESS_FIELD]: {},
  [COMPANY_NAME]: '',
  errors: {},
};

function CardsDetails({ setCurrentView, orderDetailsData, setOrderId, cards, setCards }) {
  const { startLoading, endLoading } = useContext(GlobalLoaderContext);
  const { t } = useTranslation();
  const {
    user,
    permissions: { canViewUsers, canOrderOwnCard, canOrderOthersCards },
  } = useContext(UserContext);
  const [ selectedCard, setSelectedCard ] = useState({});
  const [ sidebarState, setSidebarState ] = useState(sidebarMode.hidden);
  const [ corporation, setCorporation ] = useState(null);
  const [ errorModalIsOpen, setErrorModalIsOpen ] = useState(false);
  const [ persons, setPersons ] = useState([]);
  const [ canOrderError, setCanOrderError ] = useState();
  const [ displayErrors, setDisplayErrors ] = useState(false);
  const { isDesktop } = useView();
  const isCardEdition = sidebarState === sidebarMode.edit;
  const [ addressModalIsOpen, setAddressModalIsOpen ] = useState(false);

  useEffect(() => {
    getPersons();
  }, []);

  const getPersons = async () => {
    let allPersons = [];
    let availablePersons = [];

    if (canViewUsers) {
      try {
        startLoading();
        allPersons = await getApprovedAccountUsers();
      } catch (error) {
        showGeneralToastError(error);
      } finally {
        endLoading();
      }
    } else {
      allPersons.push(user);
    }

    if (!canOrderOwnCard) {
      availablePersons = allPersons.filter(({ id }) => id !== user.id);
    } else if (canOrderOwnCard && canOrderOthersCards) {
      availablePersons = allPersons;
    } else {
      availablePersons = allPersons.filter(({ id }) => id === user.id);
    }

    setPersons(availablePersons);
  };

  const addNewCard = async corporationData => {
    setSidebarState(sidebarMode.hidden);
    const address = corporationData.address
      ? { ...corporationData.address, type: deliveryAddressTypeMap.companyAddress }
      : null;

    const newCard = {
      ...initialCardObject,
      [ADDRESS_FIELD]: address,
      [COMPANY_NAME]: corporationData.name,
      id: uuidv4(),
    };
    startLoading();

    const { canOrder, error } = await canOrderCards({
      cardType: orderDetailsData[CARD_TYPE_SWITCH],
      cardsRequested: cards.length + 1,
    });
    endLoading();

    if (error) {
      setCanOrderError(error);
      setErrorModalIsOpen(true);

      return;
    }

    if (!canOrder) return;

    setSelectedCard(newCard);
    setSidebarState(sidebarMode.addNew);
  };

  useEffect(() => {
    getCorporationData();
  }, []);

  const getCorporationData = async () => {
    const corporationData = await getCorporation();
    setCorporation(corporationData);
    if (isDesktop && !cards.length) addNewCard(corporationData);
  };

  const updateCardholderCardsDeliveryAddress = (cardItems, cardHolder, deliveryAddress) =>
    cardItems.map(card =>
      get(card, 'cardHolder.id') === get(cardHolder, 'id') ? set(card, 'address', deliveryAddress) : card);

  const updateAddress = (newAddress, cardHolder) => {
    setCards(updateCardholderCardsDeliveryAddress(cards, cardHolder, newAddress));
    setSelectedCard(prevState => ({
      ...prevState,
      address: newAddress,
    }));
  };

  if (isEmpty(persons) || isEmpty(corporation)) {
    return null;
  }

  const handleContinue = async () => {
    const cardsErrors = cards.find(card => !isEmpty(card.errors));
    if (cardsErrors) {
      setDisplayErrors(true);

      return;
    }
    startLoading();

    const { data, error } = await updatePendingCardOrder(null, orderDetailsData, cards);

    if (error) {
      showGeneralToastError(error);
      endLoading();

      return;
    }

    setOrderId(data.id);
    setCurrentView(SUMMARY);
    endLoading();
  };

  const updateItem = (cardId, fieldName, value) => {
    if (sidebarState === sidebarMode.addNew) {
      setSelectedCard(prevState => ({ ...prevState, [fieldName]: value }));
    } else {
      const newCards = [...cards];
      const card = newCards.find(newCard => newCard.id === cardId);

      if (card) {
        card[fieldName] = value;
        setSelectedCard(card);
        setCards(newCards);
      }
    }
  };

  const handleViewCard = card => {
    setSelectedCard(card);
    setSidebarState(sidebarMode.edit);
  };

  const confirmCard = async (deliveryAddress, cardHolder) => {
    const newCards = [ ...cards, selectedCard ];
    const cardsErrors = newCards.find(card => !isEmpty(card.errors));

    if (cardsErrors) {
      setDisplayErrors(true);

      return;
    }

    setDisplayErrors(false);
    setSidebarState(sidebarMode.hidden);

    startLoading();

    const { canOrder, error } = await canOrderCards({
      cardType: orderDetailsData[CARD_TYPE_SWITCH],
      cardsRequested: getNumberOfCardsByCardholderId(cardHolder.id) + 1,
      cardholderReferenceId: cardHolder.id,
    });

    endLoading();

    if (error) {
      setCanOrderError(error);
      setErrorModalIsOpen(true);

      return;
    }

    if (!canOrder) return;

    const updatedCards = updateCardholderCardsDeliveryAddress(newCards, cardHolder, deliveryAddress);
    setCards(updatedCards);
    setSelectedCard({});
  };

  const getNumberOfCardsByCardholderId = cardHolderId =>
    cards.filter(card => card.cardHolder.id === cardHolderId).length;

  const deleteItem = cardId => {
    const updatedCards = filter(cards, c => c.id !== cardId);

    setCards(updatedCards);
    setSidebarState(sidebarMode.hidden);
  };

  const isFirstCardAddressElement = (card, index) =>
    findIndex(cards, [ 'cardHolder.id', get(card, 'cardHolder.id') ]) === index;

  const handleSidebarClose = () => {
    setSidebarState(sidebarMode.hidden);
    setSelectedCard({});
  };

  const handleUpArrowClick = () => {
    const previousCardIndex = cards.findIndex(c => c.id === selectedCard.id) - 1;
    setSelectedCard(cards[previousCardIndex]);
  };

  const handleDownArrowClick = () => {
    const nextCardIndex = cards.findIndex(card => card.id === selectedCard.id) + 1;
    setSelectedCard(cards[nextCardIndex]);
  };

  return (
    <S.Container>
      <CardAccountDetails
        companyName={corporation?.name}
        account={orderDetailsData?.accountSelection || newAccount}
        isVirtual={orderDetailsData?.cardTypeSwitch === VIRTUAL_CARD_TYPE}
      />
      {cards.length ? (
        <S.TableWrapper>
          <CardOrderTable orderCardData={cards}
            handleRowClick={handleViewCard}
            selectedCard={selectedCard}
            onEdit={handleViewCard}
            onDelete={deleteItem}
            account={orderDetailsData?.accountSelection || newAccount}
            isVirtual={orderDetailsData?.cardTypeSwitch === VIRTUAL_CARD_TYPE}
          />
        </S.TableWrapper>
      ) : null}
      <S.Sidebar
        isOpen={sidebarState !== sidebarMode.hidden}
        handleClose={handleSidebarClose}
        isControlPanelVisible={isCardEdition}
        isCloseOutsideEnabled={addressModalIsOpen}
        isUpArrowDisabled={selectedCard.id === cards[0]?.id}
        isDownArrowDisabled={selectedCard.id === cards[cards.length - 1]?.id}
        handleUpArrowClick={handleUpArrowClick}
        handleDownArrowClick={handleDownArrowClick}
        disableHeaderIcon
      >
        {sidebarState !== sidebarMode.hidden && (
          <CardDetailsItem
            displayErrors={displayErrors}
            updateItem={updateItem}
            confirmCard={confirmCard}
            setAddressModalIsOpen={setAddressModalIsOpen}
            persons={persons}
            handleSidebarClose={handleSidebarClose}
            deleteItem={deleteItem}
            addressModalIsOpen={addressModalIsOpen}
            orderDetailsData={orderDetailsData}
            sidebarState={sidebarState}
            isFirstCardAddressElement={isFirstCardAddressElement(selectedCard,
              cards.findIndex(c => c.id === selectedCard.id))}
            updateAddress={updateAddress}
            selectedCard={selectedCard}
            corporation={corporation}
            cards={cards}
          />
        )}
      </S.Sidebar>
      <S.Button label={t('orderCards.cardDetails.addNewCard')} category="secondary" onClick={() => addNewCard(corporation)} />
      <S.Button extraMargin label={t('orderCards.cardDetails.continue')} disabled={!cards.length}  category="primary" onClick={handleContinue} />

      {errorModalIsOpen && (
        <CanOrderErrorModal
          isOpen={errorModalIsOpen}
          setIsOpen={setErrorModalIsOpen}
          error={canOrderError}
          isFirstItemInOrderList={false}
        />
      )}
    </S.Container>
  );
}

CardsDetails.propTypes = {
  setCurrentView: PropTypes.func.isRequired,
  setOrderId: PropTypes.func.isRequired,
  orderDetailsData: PropTypes.instanceOf(Object).isRequired,
  cards: PropTypes.instanceOf(Array).isRequired,
  setCards: PropTypes.func.isRequired,
};

export default CardsDetails;
