import apiSpec from '@apispec/pci-gateway';

import config from 'config';

import createApiClient from './api';
import baseApiClient from './bff';

export default createApiClient({
  apiSpec,
  options: {
    baseURL: config.pciUrl,
  },
  tokenBuilder: {
    name: 'x-access-token',
    build: token => token,
  },
  baseApiClient,
});
