import { useFormik } from 'formik';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { cleanStorage } from 'utils/storage';
import { redirectToLogin } from 'utils/location';
import TwoFaProvider from 'contexts/two-fa';
import { GlobalLoaderContext } from 'contexts/global-loader';
import { HIGH_SCORE_COMPLEXITY } from 'constants/inputs';
import { redirectActions } from 'constants/auth';
import { ModalContent, TwoFaModal } from 'components';
import { showGeneralToastError, rethrowTwoFaError } from 'utils/errors';
import { updateDetails } from 'api/profile';

import S from './styles';
import { PhoneSchema as validationSchema } from './schema';
import { generateRequestDetails, parsePhoneNumber } from './helpers';
import { CONFIRM_PASSWORD_FORM, formFields, modalTypes, dataTestIds } from './constants';

function Security({ user, setUser }) {
  const { startLoading, endLoading } = useContext(GlobalLoaderContext);
  const { t } = useTranslation();

  const [ isOpen, setIsOpen ] = useState(false);
  const [ type, setType ] = useState(null);
  const [ twoFaModalIsOpen, setTwoFaModalIsOpen ] = useState(false);
  const [ twoFaData, setTwoFaData ] = useState(null);
  const [ passwordScore, setPasswordScore ] = useState({});

  const { phone, email } = user;
  const parsedPhone = parsePhoneNumber(phone);

  const formik = useFormik({
    initialValues: {
      [formFields.phone.name]: phone || '',
      [formFields.newPassword.name]: '',
      [formFields.confirmNewPassword.name]: '',
      [formFields.currentPassword.name]: '',
    },
    enableReinitialize: true,
    validationSchema,
    validateOnChange: true,
  });

  const {
    errors,
    isSubmitting,
    setFieldValue,
    values,
    touched,
    setFieldTouched,
    setFieldError,
    setSubmitting,
  } = formik;

  function openModal(modalType) {
    setType(modalType);
    setIsOpen(true);
  }

  const closeModal = useCallback((resetValues = true) => {
    if (resetValues) {
      setIsOpen(false);

      setType(null);
      setSubmitting(false);
      setFieldTouched(formFields.currentPassword.name, false);
      setFieldValue(formFields.currentPassword.name, '');
    }
  }, [ setFieldTouched, setFieldValue, setSubmitting ]);

  function onPhoneSubmit(event) {
    event.preventDefault();
    setSubmitting(true);
    openModal(modalTypes.phone);
  }

  const onPasswordSubmit = event => {
    event.preventDefault();
    const isNewPasswordStrong = passwordScore.score >= passwordScore.minScore;

    if (!isNewPasswordStrong) {
      setFieldError(formFields.newPassword.name, t('security.validation.password.notStrongEnough'));

      return;
    }
    setSubmitting(true);
    openModal(modalTypes.password);
  };

  const onPasswordScoreChange = useCallback(event => {
    if (!isEqual(passwordScore, event)) {
      setPasswordScore(event);
    }
  }, [passwordScore]);

  const updateDetailsTwoFa = useCallback(event => {
    event.preventDefault();
    const data = generateRequestDetails(type, values);
    closeModal(false);

    switch (type) {
      case modalTypes.phone:
        return updatePhone(data);

      case modalTypes.password:
        return updatePassword(data);

      default:
        return null;
    }
  }, [ closeModal, type, updatePassword, updatePhone, values ]);

  async function updatePhone(data) {
    startLoading();

    try {
      const { challenge } = await updateDetails(data);

      setTwoFaData(challenge);
      setTwoFaModalIsOpen(true);
    } catch (error) {
      setFieldError(formFields.currentPassword.name, error.data.default?.message);
    } finally {
      endLoading();
    }
  }

  async function updatePassword(data) {
    startLoading();

    try {
      const { challenge } = await updateDetails(data);
      setIsOpen(false);
      setTwoFaData(challenge);
      setTwoFaModalIsOpen(true);
    } catch (error) {
      setFieldError(formFields.currentPassword.name, error.data.default?.message);
    } finally {
      endLoading();
    }
  }

  function updateUser(data) {
    const { newPhone } = data.update;

    setUser({ ...user, phone: newPhone });
  }

  const onPhoneChange = (name, value) => {
    setFieldTouched(name, true);
    setFieldValue(name, value, true);
  };

  const onChange = event => {
    setFieldValue(event.target.name, event.target.value, true);
  };

  const onBlur = event => {
    setFieldTouched(event.target.name, true);
  };

  const handleConfirmCode = async challenge => {
    const details = generateRequestDetails(type, values);
    const data = { challenge, ...details };

    try {
      await updateDetails(data);
      updateUser(data);
      setTwoFaModalIsOpen(false);
      setIsOpen(false);

      // Only for password update CASE: Redirect to Login and clear the cash.
      if (type === modalTypes.password) {
        cleanStorage();
        redirectToLogin(redirectActions.passwordChange);
      }
    } catch (error) {
      rethrowTwoFaError(error);

      setTwoFaModalIsOpen(false);
      setIsOpen(false);
      showGeneralToastError(error);
    }
  };

  const handleResendCode = async factor => {
    const details = generateRequestDetails(type, values);

    const data = {
      challenge: { preferredFactor: factor },
      ...details,
    };
    const response = await updateDetails(data);
    setTwoFaData(response?.challenge);

    return response;
  };

  return (
    <S.Wrapper>
      <form onSubmit={onPhoneSubmit} method="POST">
        <S.Heading>
          {t('security.phoneForm.title')}
        </S.Heading>
        <S.PhoneInput
          autoComplete={formFields.phone.autocomplete}
          error={touched.phone && t(errors.phone)}
          label={t('security.phoneForm.phone.label')}
          name={formFields.phone.name}
          onChange={onPhoneChange}
          value={values[formFields.phone.name] ?? ''}
        />
        <S.Button
          disabled={
            !touched[formFields.phone.name] ||
            errors.phone ||
            isSubmitting ||
            values[formFields.phone.name] === parsedPhone
          }
          label={t('security.phoneForm.submitButton')}
          type="submit"
        />
      </form>
      <S.Form onSubmit={onPasswordSubmit} method="POST">
        <S.Heading>
          {t('security.passwordForm.title')}
        </S.Heading>
        <input
          autoComplete={formFields.username.autocomplete}
          disabled
          hidden
          name={formFields.username}
          type="text"
          value={email}
        />
        <S.Input
          autoComplete={formFields.newPassword.autocomplete}
          error={(touched[formFields.newPassword.name] || touched[formFields.confirmNewPassword.name])
            && t(errors[formFields.newPassword.name])}
          isCheckingStrength
          scoreComplexity={HIGH_SCORE_COMPLEXITY}
          isPassword
          label={t('security.passwordForm.newPassword.label')}
          name={formFields.newPassword.name}
          onScoreChange={onPasswordScoreChange}
          onChange={onChange}
          onBlur={onBlur}
        />
        <S.Input
          autoComplete={formFields.confirmNewPassword.autocomplete}
          error={(touched[formFields.newPassword.name] || touched[formFields.confirmNewPassword.name])
            && t(errors[formFields.confirmNewPassword.name])}
          isCheckingStrength
          scoreComplexity={HIGH_SCORE_COMPLEXITY}
          isPassword
          label={t('security.passwordForm.confirmNewPassword.label')}
          name={formFields.confirmNewPassword.name}
          onChange={onChange}
          onBlur={onBlur}
        />
        <S.Button
          disabled={
            isSubmitting ||
            errors[formFields.newPassword.name] ||
            errors[formFields.confirmNewPassword.name] ||
            !values[formFields.newPassword.name] ||
            values[formFields.newPassword.name] !== values[formFields.confirmNewPassword.name]
          }
          label={t('security.passwordForm.submitButton')}
          type="submit"
        />
      </S.Form>

      {isOpen && (
        <ConfirmPasswordModal
          callback={updateDetailsTwoFa}
          errors={errors}
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          onBlur={onBlur}
          onChange={onChange}
          touched={touched}
          closeModal={closeModal}
        />
      )}

      {twoFaModalIsOpen && (
        <TwoFaProvider twoFaData={twoFaData}>
          <TwoFaModal
            isOpen={twoFaModalIsOpen}
            setIsOpen={setTwoFaModalIsOpen}
            onSubmit={handleConfirmCode}
            onResend={handleResendCode}
            challenge={twoFaData}
            isVoiceCallEnabled
          />
        </TwoFaProvider>
      )}
    </S.Wrapper>
  );
}

function ConfirmPasswordModal({ callback, closeModal, errors, isOpen, onBlur, onChange, touched, setIsOpen }) {
  const { t } = useTranslation();

  const CTAButtons = (
    <>
      <S.ModalApproveButton
        data-testid={dataTestIds.confirmUserUpdateButton}
        type="submit"
        label={t('security.confirmPasswordForm.submitButton')}
        disabled={errors[formFields.currentPassword.name]}
      />
      <S.ModalCancelButton label={t('security.confirmPasswordForm.cancelButton')} category="secondary" onClick={closeModal} />
    </>
  );

  return (
    <ModalContent
      title={t('security.confirmPasswordForm.title')}
      hasCloseIcon
      isOpen={isOpen}
      CTAButtons={CTAButtons}
      setIsOpen={setIsOpen}
      formName={CONFIRM_PASSWORD_FORM}
      onSubmit={callback}
    >
      <S.InputContainer>
        <S.ConfirmPasswordInput
          autoComplete={formFields.currentPassword.autocomplete}
          error={touched[formFields.currentPassword.name] && t(errors[formFields.currentPassword.name])}
          label={t('security.confirmPasswordForm.currentPassword.label')}
          name={formFields.currentPassword.name}
          onBlur={onBlur}
          onChange={onChange}
          isPassword
          autoFocus
        />
      </S.InputContainer>
    </ModalContent>
  );
}

Security.propTypes = {
  user: PropTypes.instanceOf(Object).isRequired,
  setUser: PropTypes.func.isRequired,
};

ConfirmPasswordModal.propTypes = {
  callback: PropTypes.func,
  errors: PropTypes.instanceOf(Object),
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  onBlur: PropTypes.func,
  closeModal: PropTypes.func,
  onChange: PropTypes.func,
  touched: PropTypes.instanceOf(Object),
};

export default Security;
