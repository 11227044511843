import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { redirectActions } from 'constants/auth';
import { ERRORS } from 'constants/errors';
import authLinks from 'constants/links';
import { LoginLayout } from 'layouts';
import { getParameterFromURL, removeParameterFromURL } from 'utils/helpers';
import { redirectToPage } from 'utils/location';
import { getDefaultPathByPermissions } from 'utils/permissions';
import { setToken } from 'utils/storage';
import { isTokenPermitted } from 'utils/token';

import { LoginConfirmation, LoginForm } from './components';

function Login() {
  const { t } = useTranslation();
  const history = useHistory();
  const [ twoFa, setTwoFa ] = useState(null);
  const [ email, setEmail ] = useState('');
  const [ password, setPassword ] = useState('');

  useEffect(() => {
    mapActionFromUrl();
  }, []);

  const mapActionFromUrl = () => {
    const action = getParameterFromURL('action', history.location);

    if (action === redirectActions.passwordChange) handlePasswordChangeAction();
  };

  const handlePasswordChangeAction = () => {
    toast.success(t('login.action.passwordChange'));
    removeParameterFromURL('action', history, history.location);
  };

  const handleLogin = ({ accessToken, refreshToken }) => {
    setToken(accessToken, refreshToken);

    // Redirect user either to return url or default accessible page.
    const returnUrl = getParameterFromURL('return-url', history.location);
    redirectToPage(returnUrl || getDefaultPathByPermissions());
  };

  const onConfirm = response => {
    const { accessToken, refreshToken } = response.session;

    if (isTokenPermitted(accessToken) && refreshToken) {
      handleLogin(response.session);
    } else {
      // Case when token doesn't exist or is not permitted.
      toast.error(ERRORS.invalidPermissions);
    }
  };

  const onLogIn = response => {
    const { session, challenge, email: emailValue, password: passwordValue } = response;

    // Case when 2FA is disabled. Session object is returned.
    if (session) {
      onConfirm(response);

      return;
    }

    // Case when 2FA is enabled. Challenge object is returned.
    if (challenge) {
      setTwoFa(challenge);
      setEmail(emailValue);
      setPassword(passwordValue);
    }
  };

  const onResend = response => setTwoFa(response.challenge);
  const links = [ authLinks.forgotPassword, authLinks.noAccount ];

  if (twoFa) {
    links.unshift(authLinks.login);
  }

  return (
    <LoginLayout links={links}>
      {twoFa ? (
        <LoginConfirmation twoFa={twoFa} onResend={onResend} onSubmit={onConfirm} email={email} password={password} />
      ) : (
        <LoginForm onSubmit={onLogIn} />
      )}
    </LoginLayout>
  );
}

export default Login;
