import { Title as DefaultTitle } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';

const Container = styled.div`
  padding-bottom: 2.5rem;
`;

const Title = styled(DefaultTitle)`
  font: ${props => props.theme.fonts.h3Bold};
  margin-bottom: 1.625rem;

  @media ${DEVICE.tablet} {
    font: ${props => props.theme.fonts.h1Bold};
    margin: 0;
  }
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  padding: 0 1.25rem;
  flex-direction: column;

  @media ${DEVICE.tablet} {
    padding: 1.0625rem 0 1.4375rem;
    flex-direction: row;
  }
`;

export default {
  Container,
  Header,
  Title,
};
