import config from 'config';
import { navigation } from 'constants/navigation';

const authLinks = {
  login: { label: 'Log in', href: `${config.publicUrl}${navigation.login}` },
  noAccount: { label: 'Don\'t have an account?', href: config.registrationUrl },
  forgotPassword: { label: 'Forgot password', href: `${config.publicUrl}${navigation.resetPassword}` },
};

export default authLinks;
