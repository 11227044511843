import { Button as DefaultButton, Title as DefaultTitle } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';

const Content = styled.div`
  position: relative;
  padding: 3.625rem 3.125rem 3.125rem 3.125rem;
  width: 36.25rem;
`;

const Title = styled(DefaultTitle)`
  font: ${props => props.theme.fonts.h4Bold};
  padding-bottom: 1.25rem;
`;

const SubTitle = styled.p`
  font: ${props => props.theme.fonts.bodyM};
  color: ${props => props.theme.colors.greyMedium};
  padding-bottom: 1.875rem;
`;

const ButtonsContainer = styled.div`
  margin: 0;
  display: flex;
  align-items: center;
`;

const Button = styled(DefaultButton)`
  margin-right: 0.625rem;
`;

const CloseIcon = styled(CrossIcon)`
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
`;

export default {
  Content,
  Title,
  SubTitle,
  ButtonsContainer,
  Button,
  CloseIcon,
};
