import { Title as TitleComponent } from '@general/intergiro-ui-kit';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import { Badge as BadgeElement } from 'components';
import { DEVICE, VIEWPORT_VALUES } from 'constants/breakpoints';
import { convertRemToPixels } from 'utils/styles-helper';
import { hex2rgba } from 'helpers/styles';

function getOpenStyles(isOpen) {
  if (isOpen) {
    return css`
      opacity: 1;
      pointer-events: all;
      z-index: 2;
    `;
  }

  return css`
    opacity: 0;
    pointer-events: none;
  `;
}

const Main = styled.main`
  display: flex;
  min-height: 100vh;
`;

const Sidebar = styled.div`
  background-color: ${props => props.theme.colors.greyBright};
  border-right: 0.125rem solid ${props => props.theme.colors.greyBright};
  display: none;
  height: 100%;
  justify-content: flex-end;
  left: 0;
  position: fixed;
  top: 0;
  transition: ${props => props.theme.transition};
  width: 17.625rem;
  z-index: 2;

  @media ${DEVICE.tablet} {
    display: block;

    ${props => props.isHidden &&
      css`
        width: 0;
        overflow: hidden;
      `
    };
  }

  @media ${DEVICE.desktop} {
    background-color: ${props => hex2rgba(props.theme.colors.greyBright, 0.5)};
    width: 17.625rem;
  }

  @media ${DEVICE.wideDesktop} {
    width: ${props => {
      const remainingSpace = props.innerWidth - convertRemToPixels(VIEWPORT_VALUES.wideDesktop);
      const menuWidth = `${remainingSpace / 3  }px`;

      return menuWidth;
    }};
    min-width: 17.625rem;
  }
`;

const Container = styled.div`
  background-color: ${props => props.theme.colors.white};
  flex: 1;

  @media ${DEVICE.desktop} {
    margin-left: 17.625rem;
    padding: 0 1.25rem;
  }
`;

const Header = styled.header`
  align-items: center;
  display: flex;
  justify-content: space-between;
  min-height: 1.25rem;
  padding: 1.875rem 1.25rem;
  width: 100%;
  ${props => props.skipPadding ? 'padding: 0' : ''};

  @media ${DEVICE.tablet} {
    padding: 1.5625rem 2.5rem;
    ${props => props.skipPadding ? 'padding: 0' : ''};
  }

  @media ${DEVICE.wideDesktop} {
    padding: 1.5625rem 2.5rem;
    max-width: 120rem;
    margin: 0 auto;
    ${props => props.skipPadding ? 'padding: 0' : ''};
  }
`;

const MobileMenu = styled.nav`
  background: ${props => props.theme.colors.greyBright};
  bottom: 0;
  height: 100%;
  flex-direction: column;
  left: 0;
  padding: 2rem;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  transition: ${props => props.theme.transition};

  ${props => getOpenStyles(props.isOpen)}

  @media ${DEVICE.tablet} {
    display: none;
  }
`;

const CrossWrapper = styled.button`
  cursor: pointer;
  align-items: center;
  background: none;
  border: none;
  display: flex;
  height: 1.875rem;
  justify-content: center;
  width: 1.875rem;

  ${props =>
  props.skipBorder &&
    css`
      circle {
        fill: none;
      }
    `}

  @media ${DEVICE.tablet} {
    display: none;
  }
`;

const Cross = styled(CrossIcon)`
  path {
    fill: ${props => props.theme.colors.black};
  }
`;

const Content = styled.div`
  flex: 1;
  max-width: 120rem;
  padding: 0;

  @media ${DEVICE.tablet} {
    padding: 0 2.5rem;
  }
  
  @media ${DEVICE.laptop} {
    height: calc(100% - 6.9rem);
  }

  @media ${DEVICE.wideDesktop} {
    margin: 0 auto;
  }
`;

const CorporationNameWrapper = styled.div`
  margin: 1.25rem 0 0.9375rem;
  height: 6rem;
`;

const CorporationName = styled(TitleComponent)`
  font: ${props => props.theme.fonts.bodyMBold};
`;

const SidebarInner = styled.div`
  max-width: 17.625rem;
  padding: 1.625rem 1.625rem 1.625rem 1.875rem;
  transition: ${props => props.theme.transition};
  width: 100%;
  overflow: auto;
`;

const activeClassName = 'active';
const Link = styled(NavLink).attrs({ activeClassName })`
  align-items: center;
  color: ${props => props.theme.colors.greyMedium};
  display: flex;
  font: ${props => props.theme.fonts.bodyMSemi};
  margin-bottom: 1.4375rem;

  svg {
    fill: ${props => props.theme.colors.greyMediumDark};
  }

  &:hover {
    color: ${props => props.theme.colors.black};

    svg {
      fill: ${props => props.theme.colors.black};
    }
  }

  &.${props => props.activeClassName} {
    color: ${props => props.theme.colors.black};

    svg {
      fill: ${props => props.theme.colors.black};
    }
  }
`;

const IconWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: 0.75rem;
  width: 1.75rem;
`;

const Profile = styled(NavLink)`
  display: block;
  height: 2.25rem;
  width: 2.25rem;
`;

const Badge = styled(BadgeElement)`
  margin-left: auto;
`;

const LogoWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;
export default {
  Badge,
  Container,
  Content,
  CorporationNameWrapper,
  CorporationName,
  Cross,
  CrossWrapper,
  IconWrapper,
  Link,
  LogoWrapper,
  Main,
  Header,
  MobileMenu,
  Profile,
  Sidebar,
  SidebarInner,
};
