import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Dot, TransactionIcon as PaymentIcon } from 'components/index';
import { defaultDateFormat, defaultTimeFormat } from 'constants/date-time';
import { transactionStatusLabels, transactionType } from 'constants/transactions';
import { apiPaymentShape, mappedTransactionShape } from 'local-prop-types';
import { formatAmount } from 'utils/amount';

import S from './styles';

function Payment({
  payment,
  handlePaymentClick,
  activePayment,
  isGroupPayment,
}) {
  const {
    status,
    createdAt,
    id,
    bulkPaymentId,
    netTransactionAmount,
    sourceCurrency,
    debitedAmount,
    numberOfTransactions,
    toAccount,
  } = payment;
  const [ isActive, setIsActive ] = useState(false);
  const { t } = useTranslation();
  const identifier = isGroupPayment ? 'bulkPaymentId' : 'id';
  const toName = isGroupPayment ? numberOfTransactions : toAccount.name;
  const idToCompare = isGroupPayment ? bulkPaymentId : id;

  const creationDate = useMemo(() => ({
    date: dayjs(createdAt).format(defaultDateFormat),
    time: dayjs(createdAt).format(defaultTimeFormat),
  }), [createdAt]);

  const getFormattedAmount = amount => `- ${formatAmount(amount, sourceCurrency)} ${sourceCurrency}`;

  const formattedDebitedAmount = useMemo(() => getFormattedAmount(debitedAmount), [ debitedAmount, sourceCurrency ]);

  const formattedNetAmount = useMemo(() =>
    getFormattedAmount(netTransactionAmount), [ netTransactionAmount, sourceCurrency ]);

  const getNumberOfTransactions = () => numberOfTransactions ?
      `${numberOfTransactions === 1 ? t('payments.apiPayments.label.single') : t('payments.apiPayments.label.multiple')}` :
    null;

  useEffect(() => {
    setIsActive(activePayment?.[identifier] === idToCompare);
  }, [activePayment]);

  return (
    <S.Payment isActive={isActive}
      onClick={() => {
      handlePaymentClick(idToCompare);
    }}
    >
      <S.PaymentActivity>
        <S.PaymentIcon>
          <PaymentIcon isActive={isActive} type={transactionType.apiPayment} status={status} />
        </S.PaymentIcon>
        <S.PaymentToWrapper>
          <S.PaymentToLabel>
            {t('payments.apiPayments.to')}
            {' '}
            <S.PaymentToBold>
              {toName}
              {' '}
              {isGroupPayment && getNumberOfTransactions()}
            </S.PaymentToBold>
          </S.PaymentToLabel>
          <S.PaymentToDetails>
            <S.PaymentStatus>
              <S.PaymentSubHeading isWarning>
                {transactionStatusLabels[status]}
              </S.PaymentSubHeading>
              <Dot />
            </S.PaymentStatus>
            <S.PaymentSubHeading>
              {creationDate.date}
              <Dot />
            </S.PaymentSubHeading>
            <S.PaymentSubHeading>
              {creationDate.time}
            </S.PaymentSubHeading>
            <S.PaymentSubHeading isHiddenOnMobile>
              <Dot />
              API
            </S.PaymentSubHeading>
            <S.PaymentSubHeading isMobile>API</S.PaymentSubHeading>
          </S.PaymentToDetails>
        </S.PaymentToWrapper>
      </S.PaymentActivity>
      <S.PaymentAmount>
        <S.PaymentAmountTop>
          {formattedDebitedAmount}
        </S.PaymentAmountTop>
        <S.PaymentAmountBottom>
          <S.PaymentSubHeading>
            {t('payments.apiPayments.fees')}
          </S.PaymentSubHeading>
          <S.PaymentSubHeading marginLeft>
            {formattedNetAmount}
          </S.PaymentSubHeading>
        </S.PaymentAmountBottom>
      </S.PaymentAmount>
    </S.Payment>
  );
}

Payment.propTypes = {
  payment: PropTypes.oneOfType([ apiPaymentShape, PropTypes.shape(mappedTransactionShape) ]),
  activePayment: PropTypes.oneOfType([ apiPaymentShape, PropTypes.shape(mappedTransactionShape) ]),
  handlePaymentClick: PropTypes.func.isRequired,
  isGroupPayment: PropTypes.bool,
};

export default memo(Payment);
