import { Title as TitleComponent } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';

const Header = styled.header`
	align-items: center;
	display: flex;
	padding: 0 1.25rem 2.5rem 1.25rem;

  @media ${DEVICE.tablet} {
    padding: 0 1.25rem 2.5rem 0;
  }

	@media ${DEVICE.laptop} {
		padding: 0 0 2.625rem;
	}
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: baseline;
  padding-left: 0.625rem;
`;

const Balance = styled(TitleComponent)`
  display: inline-block;
  font: ${props => props.theme.fonts.h3Bold};
  padding-left: 0.5rem;

  @media ${DEVICE.tablet} {
    font: ${props => props.theme.fonts.h2Bold};
  }

  @media ${DEVICE.laptop} {
    font: ${props => props.theme.fonts.h1Bold};
  }
`;

const BalanceFractionalPart = styled(TitleComponent)`
  font: ${props => props.theme.fonts.bodyLBold};
  display: inline-block;

  @media ${DEVICE.tablet} {
    font-size: 1.75rem;
  }

  @media ${DEVICE.laptop} {
    font-size: 2rem;
  }
`;

const Currency = styled(TitleComponent)`
  font: ${props => props.theme.fonts.h3};
  display: inline-block;

  @media ${DEVICE.tablet} {
    font: ${props => props.theme.fonts.h2};
  }

  @media ${DEVICE.laptop} {
    font: ${props => props.theme.fonts.h1};
  }
`;

export default {
  Header,
  TitleContainer,
  Balance,
  Currency,
  BalanceFractionalPart,
};
