import memoize from 'memoize-one';
import get from 'lodash/get';

import { navigation, navigationLinks } from 'constants/navigation';
import { permissionsMap } from 'constants/permissions';
import { transactionStatusCodes } from 'constants/transactions';
import { getToken } from 'utils/storage';
import { getTokenPayload } from 'utils/token';

export const permit = memoize((permissions, userPermissions, atLeastOne) => {
  const checker = permission => userPermissions[permission];

  if (atLeastOne) {
    return permissions.some(checker);
  }

  return permissions.every(checker);
});

export const permitByModifiers = memoize((permissions, modifiers, userModifiers) => permissions.every(permission => {
  const userModifier = get(userModifiers, permission);
  const permissionModifiers = get(modifiers, permission, []);

  return permissionModifiers.includes(userModifier);
}));

export const getDefaultPathByPermissions = () => {
  const { permissions } = getTokenPayload(getToken());

  const link = navigationLinks
    .find(l => l.permissions?.list && permit(l.permissions.list, permissions, l.permissions.atLeastOne));

  return link?.to || navigation.profile;
};

export const calculateUserPermissions = ({ permissions, permissionModifiers }) => {
  const hasPermission = permission => Boolean(permissions[permission]);

  return {
    // Cards
    canViewOwnCards: hasPermission(permissionsMap.individualCardsManagementRead),
    canViewOthersCards: hasPermission(permissionsMap.otherCardsManagementRead),
    canOrderOwnCard: hasPermission(permissionsMap.individualCardsManagementCreate),
    canOrderOthersCards: hasPermission(permissionsMap.otherCardsManagementCreate),
    canOrderCard:
      hasPermission(permissionsMap.individualCardsManagementCreate) ||
      hasPermission(permissionsMap.otherCardsManagementCreate),

    // Transactions
    canViewTransactions: hasPermission(permissionsMap.transactionsManagementRead),

    // Accounts
    canViewAccounts: hasPermission(permissionsMap.corporateWalletsManagementRead),
    canManageAccounts: hasPermission(permissionsMap.corporateWalletsManagementCreate),

    // Company
    canViewCompanyDetails: hasPermission(permissionsMap.corporateAccountsManagementRead),

    // Transfers
    canCreateTransaction: hasPermission(permissionsMap.transactionsManagementCreateTransfer),
    canApproveTransaction:
      hasPermission(permissionsMap.transactionsManagementRead) &&
      hasPermission(permissionsMap.transactionsManagementApproveTransfer),

    // Users
    canViewUsers: hasPermission(permissionsMap.personsManagementRead),
    canCreateUser: hasPermission(permissionsMap.personsManagementCreate),
    canUpdateUser: hasPermission(permissionsMap.personsManagementUpdate),
    canDeleteUser: hasPermission(permissionsMap.personsManagementDelete),

    // Roles
    canViewUserRoles: hasPermission(permissionsMap.personsRolesManagementRead),
    canCreateUserRole: hasPermission(permissionsMap.personsRolesManagementCreate),
    canUpdateUserRole: hasPermission(permissionsMap.personsRolesManagementUpdate),
    canDeleteUserRole: hasPermission(permissionsMap.personsRolesManagementDelete),

    // API Keys
    canViewApiKeys: hasPermission(permissionsMap.corporateApiKeysManagementRead),
    canCreateApiKeys: hasPermission(permissionsMap.corporateApiKeysManagementCreate),
    canUpdateApiKeys: hasPermission(permissionsMap.corporateApiKeysManagementUpdate),
    canDeleteApiKeys: hasPermission(permissionsMap.corporateApiKeysManagementDelete),

    // Statements
    canDownloadStatements: permitByModifiers([permissionsMap.transactionsManagementRead],
      { [permissionsMap.transactionsManagementRead]: [ 'all', 'other' ] },
      permissionModifiers),
  };
};

export const filterTypeByPermissions = (options, userPermissions) => userPermissions
  ? options.filter(({ permissions }) => permit(permissions, userPermissions, true))
  : options;

export const filterStatusByPermissions = (options, canViewAccounts) => {
  const excludedStatus = transactionStatusCodes.groupedCode.pendingApproval;

  return canViewAccounts
    ? options
    : options.filter(option => option.value !== excludedStatus);
};
