import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';

const Row = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  @media ${DEVICE.tablet} {
		flex-direction: column;
    width: 30rem;
	}

  @media ${DEVICE.laptop} {
		flex-direction: row;
    width: 66.25rem;
    margin-top: 1.25rem;
	}
`;

const MoveFundsNotificationBlock = styled.div`
  @media ${DEVICE.laptop} {
    width: 30rem;
	}
`;

export default { Row, MoveFundsNotificationBlock };
