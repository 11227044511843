import bff from 'utils/bff';

export async function viewProfile() {
  const { data: profileDetails } = await bff.personalProfile.viewProfile();

  return profileDetails;
}

export async function updateDetails(data) {
  const { data: profileDetails } = await bff.personalProfile.updateSecurity(data);

  return profileDetails;
}
