import { Tabs } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';

const TabWrapper = styled.div`
  [role='presentation']:last-of-type {
    margin: auto 0 auto auto;
  }
`;

const TransactionsWrapper = styled.div`
  transition: ${props => props.theme.transition};
  width: 100%;

  @media ${DEVICE.laptop} {
    ${props => (props.isSidebarOpen ? 'width: calc(100% - 23rem)' : '')};
  }
`;

const TabsHead = styled(Tabs.Head)`
  margin: 0 0 0 1.1rem;
  padding: 0 0 0.8125rem 0;

  @media ${DEVICE.tablet} {
    margin: 0;
  }
`;

const Wrapper = styled.div`
  height: 100%;
`;

export default {
  TabWrapper,
  TabsHead,
  TransactionsWrapper,
  Wrapper,
};
