import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { ModalContent, TwoFaModal } from 'components';
import { beneficiaryShape } from 'local-prop-types';
import { archiveBeneficiary } from 'api/beneficiary';
import { NOT_FOUND } from 'constants/status-code';
import { resourceType } from 'constants/resource';
import TwoFaProvider from 'contexts/two-fa';
import { navigateToNotFoundPage, showGeneralToastError, rethrowTwoFaError } from 'utils/errors';

import S from './styles';

function DeleteModal({ isOpen, setIsOpen, beneficiary, onDelete }) {
  const history = useHistory();
  const { t } = useTranslation();
  const [ twoFaModalIsOpen, setTwoFaModalIsOpen ] = useState(false);
  const [ twoFaData, setTwoFaData ] = useState(null);

  const handleCancel = () => setIsOpen(false);

  const handleDelete = async () => {
    try {
      const data = await archiveBeneficiary(beneficiary.id);
      setIsOpen(false);

      if (data?.challenge) {
        setTwoFaData(data.challenge);
        setTwoFaModalIsOpen(true);
      } else {
        onDelete?.();
        toast.info(t('beneficiaries.deleteModal.successMessage'));
      }
    } catch (error) {
      error.status === NOT_FOUND
        ? navigateToNotFoundPage(history, resourceType.beneficiary)
        : showGeneralToastError(error, { extractFromApi: true });
    }
  };

  const handleConfirmCode = async challenge => {
    try {
      await archiveBeneficiary(beneficiary.id, { challenge });
      setTwoFaModalIsOpen(false);
      onDelete?.();
      toast.info(t('beneficiaries.deleteModal.successMessage'));
    } catch (error) {
      if (error.status === NOT_FOUND) {
        navigateToNotFoundPage(history, resourceType.beneficiary);

        return;
      }
      rethrowTwoFaError(error);
      setTwoFaModalIsOpen(false);
      showGeneralToastError(error, { extractFromApi: true });
    }
  };

  const handleResendCode = async factor => {
    const data = await archiveBeneficiary(beneficiary.id,
      { challenge: { preferredFactor: factor } });
    setTwoFaData(data.challenge);

    return data;
  };

  const CTAButtons = (
    <>
      <S.Button label={t('beneficiaries.deleteModal.deleteBtn')} category="primary" type="submit" onClick={handleDelete} />
      <S.Button label={t('beneficiaries.deleteModal.cancelBtn')} category="secondary" type="submit" onClick={handleCancel} />
    </>
  );

  return (
    <>
      <ModalContent
        title={t('beneficiaries.deleteModal.title', { name: beneficiary.name })}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        CTAButtons={CTAButtons}
      />
      {twoFaModalIsOpen && (
        <TwoFaProvider twoFaData={twoFaData}>
          <TwoFaModal
            isOpen={twoFaModalIsOpen}
            setIsOpen={setTwoFaModalIsOpen}
            onSubmit={handleConfirmCode}
            onResend={handleResendCode}
            challenge={twoFaData}
            isVoiceCallEnabled
          />
        </TwoFaProvider>
      )}
    </>
  );
}

DeleteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  beneficiary: PropTypes.shape(beneficiaryShape).isRequired,
  onDelete: PropTypes.func,
};

export default DeleteModal;
