import styled, { css } from 'styled-components';

import { Hint as DefaultHint } from 'components';
import { DEVICE } from 'constants/breakpoints';

const Container = styled.div`
  border: 0.0625rem solid ${props => props.theme.colors.greyBright};
  border-radius: 0.5rem;
  padding: 1rem;
  width: 100%;
  margin-top: 2.5rem;
  margin-bottom: 1.25rem;
  position: relative;

  @media ${DEVICE.laptop} {
    margin-top: 5rem;
    margin-bottom: 0;
    width: 30rem;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 2.125rem;

  &:first-child {
    padding-top: 0;
  }
`;

const Text = styled.span`
  font: ${props => props.theme.fonts.bodyS};
  color: ${props => props.theme.colors.black};
  ${props => props.isTotal && 'font-weight: 600'};
  ${props =>
  props.isLabel &&
    !props.isTotal &&
    css`
      color: ${props.theme.colors.greyMedium};
    `};
  ${props =>
      !props.isLabel &&
    !props.isTotal &&
    css`
      text-align: right;
    `};

    @media ${DEVICE.tablet} {
      font: ${props => props.theme.fonts.bodyM};
      ${props => props.isTotal && 'font-weight: 600'};
    }
`;

const Hint = styled(DefaultHint)`
  margin-right: 0.5rem;
`;

export default {
  Container,
  Row,
  Text,
  Hint,
};
