import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';
import { ThemeProvider } from 'styled-components';

import { Notification, ScrollToTop } from 'components';
import config from 'config';
import GlobalLoaderProvider from 'contexts/global-loader';
import UserProvider from 'contexts/user';
import Router from 'router';
import { GlobalStyle, theme } from 'theme';

import useSurvicate from './integration/survicate';
import useGtm from './integration/gtm';

function App() {
  useGtm();
  useSurvicate();
  const { intercomId: intercomAppId, intercomApiBase }  = config;

  return (
    <ThemeProvider theme={theme}>
      <IntercomProvider appId={intercomAppId} apiBase={intercomApiBase}>
        <GlobalLoaderProvider>
          <UserProvider>
            <BrowserRouter basename={config.publicUrl}>
              <ScrollToTop />
              <GlobalStyle />
              <Route path="/" component={Router} />
            </BrowserRouter>
          </UserProvider>
        </GlobalLoaderProvider>
        <Notification />
      </IntercomProvider>
    </ThemeProvider>
  );
}

export default App;
