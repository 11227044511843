import { Tabs, Title as DefaultTitle } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';

const Title = styled(DefaultTitle)`
  font-size: 1.625rem;
  margin-bottom: 1.625rem;

  @media ${DEVICE.tablet} {
    font-size: 2.625rem;
    margin: 0;
  }
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  padding: 0 1.25rem 2.5rem 1.25rem;
  flex-direction: column;

  @media ${DEVICE.tablet} {
    padding: 1.0625rem 0 1.4375rem;
    flex-direction: row;
  }
`;

const Wrapper = styled.div`
  padding: 1.875rem 1.25rem;

  @media ${DEVICE.tablet} {
    padding: 1.875rem 0;
  }

  @media ${DEVICE.laptop} {
    align-items: flex-start;
    display: grid;
    grid-gap: 3.75rem;
    grid-template-columns: 20.9375rem 1fr;
    margin-top: 1.875rem;
    padding: 0;
  }
`;

const TabsHead = styled(Tabs.Head)`
  margin: 0 0 0 1.1rem;

  @media ${DEVICE.tablet} {
    margin: 0;
  }
`;

export default { Header, Wrapper, TabsHead, Title };
