import styled from 'styled-components';

import S from 'components/filter-bar/components/styles';
import { hex2rgba } from 'helpers/styles';

const Bar = styled.div`
  position: relative;
  z-index: 2;
`;

const Container = styled.div`
  background-color: ${props => props.theme.colors.white};
  display: ${props => (props.visible ? 'flex' : 'none')};
  flex-direction: column;
  position: relative;
`;

const FilterBarButton = styled.button`
  background: transparent;
  border: none;
  border-radius: 0.1875rem;
  color: ${props => props.theme.colors.blue};
  cursor: pointer;
  font: ${props => props.theme.fonts.bodyMSemi};
  padding: 0 0.375rem 0 0.25rem;
  transition: ${props => props.theme.transition};

  &:focus {
    background: ${props => hex2rgba(props.theme.colors.blue, 0.08)};

    ${S.CountBadge} {
      background: ${props => props.theme.colors.white};
    }
  }
`;

const Wrapper = styled.div`
  left: 0;
  min-width: 15rem;
  margin-top: 0.25rem;
  position: absolute;
  top: 100%;
  z-index: 1;
`;

export default { Bar, Container, FilterBarButton, Wrapper };
