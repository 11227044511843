import { Button, Tabs } from '@general/intergiro-ui-kit';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useParams } from 'react-router-dom';

import { useUser } from 'hooks';
import { UserContext } from 'contexts/user';
import { activatedUserStatuses, userStatusesMap } from 'constants/user';
import { navigation } from 'constants/navigation';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';

import S from './styles';
import UserRoles from './components/user-roles';
import UserDetails from './components/user-details';
import PaymentApprovalRule from './components/payment-approval-rule';

const USER_DETAILS = 'userDetails';
const USER_ROLES = 'userRoles';
const PAYMENT_APPROVAL_RULE = 'paymentApprovalRule';

function User() {
  const { id } = useParams();
  const { user, setUser } = useUser(id);
  const { t } = useTranslation();
  const {
    user: currentUser,
    permissions: { canCreateUser, canViewUsers, canViewUserRoles, canUpdateUser },
  } = useContext(UserContext);

  const [ activeTab, setActiveTab ] = useState(USER_DETAILS);

  const isCurrentUser = user.id === currentUser.id;
  const isUserActivated = activatedUserStatuses.includes(user?.status) || false;

  const isEditable = canUpdateUser && isUserActivated;
  const showUserRolesTab = canViewUsers && canViewUserRoles && isUserActivated;
  const showPaymentApprovalRuleTab = canUpdateUser && isUserActivated;
  const showInviteUserButton = user.canBeReinvited && canCreateUser;
  const isUserInvited = user.status === userStatusesMap.invited;

  const handleTabChange = tab => () => setActiveTab(tab);

  return (
    <S.Container>
      <S.Header>
        <S.Title>
          {user.firstName && `${user.firstName} ${user.lastName}`}
        </S.Title>
        {showInviteUserButton && (
          <NavLink to={`${navigation.inviteUser}/${id}`}>
            <Button size="small" label={isUserInvited ? t('user.resendInvite') : t('user.sendInvite')} icon={<PlusIcon />} type="primary" />
          </NavLink>
        )}
      </S.Header>
      <Tabs.Container name="tabs">
        <Tabs.Head isActive={activeTab === USER_DETAILS} handleChange={handleTabChange(USER_DETAILS)}>
          User details
        </Tabs.Head>
        {showUserRolesTab && (
          <Tabs.Head isActive={activeTab === USER_ROLES} handleChange={handleTabChange(USER_ROLES)}>
            Role and permissions
          </Tabs.Head>
          )}
        {showPaymentApprovalRuleTab && (
          <Tabs.Head
            isActive={activeTab === PAYMENT_APPROVAL_RULE}
            handleChange={handleTabChange(PAYMENT_APPROVAL_RULE)}
          >
            Payment approval rules
          </Tabs.Head>
          )}
      </Tabs.Container>
      <Tabs.Panel>
        {activeTab === USER_DETAILS && <UserDetails user={user} setUser={setUser} isEditable={isEditable} />}
        {activeTab === USER_ROLES && (
          <UserRoles user={user} setUser={setUser} isEditable={isEditable} isCurrentUser={isCurrentUser} />
        )}
        {activeTab === PAYMENT_APPROVAL_RULE && <PaymentApprovalRule user={user} setUser={setUser} />}
      </Tabs.Panel>
    </S.Container>
  );
}

export default User;
