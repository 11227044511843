import { Paragraph as ParagraphComponent, Title as TitleComponent } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';

const Title = styled(TitleComponent)`
	font-size: 1.375rem;
	margin: 1.875rem 0 0;
	padding: 0 1.25rem;

	@media ${DEVICE.tablet} {
    margin-bottom: 0.625rem;
		padding: 0;
	}
`;

const Subtitle = styled(ParagraphComponent)`
	color: ${props => props.theme.colors.black};
	font: ${props => props.theme.fonts.bodyM};
	margin-bottom: 0.3125rem;
`;

const Container = styled.div`
  padding: 1.25rem;

  @media ${DEVICE.tablet} {
    padding: 0;
  }
`;

const Description = styled(ParagraphComponent)`
  color: ${props => props.theme.colors.greyMedium};
  font-size: ${props => props.small && '0.8rem'};
`;

const Section = styled.div`
  margin-top: 2.0625rem;
`;

export default {
  Title,
  Subtitle,
  Container,
  Description,
  Section,
};
