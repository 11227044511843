import bff from 'utils/bff';

async function getApprovedAccountUsers() {
  const { data } = await bff.cardOrder.listApprovedAccountUsers({
    mode: 'select-options',
  });

  return data;
}

export default getApprovedAccountUsers;
