import { Dropdown, Modal } from '@general/intergiro-ui-kit';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { downloadCSV, downloadPDF } from 'api/statements';
import { ESCAPE_KEY } from 'constants/key-codes';
import { dateRangeResetValues } from 'constants/date-time';
import { GlobalLoaderContext } from 'contexts/global-loader';
import { getPreviousMonthDateRange } from 'utils/date-time';
import { showGeneralToastError } from 'utils/errors';

import { statementFormatsMap, statementFormatsOptions } from './constants';
import S from './styles';
import { formatQuery, isPdfFormat } from './utils';

function DownloadStatementsModal({ isOpen, setIsOpen, isAccountDisabled, accountList, defaultDateRange }) {
  const [ format, setFormat ] = useState(statementFormatsMap.pdf);
  const [ accounts, setAccounts ] = useState();
  const [ touchedFormFields, setTouchedFormFields ] = useState({
    accounts: false,
    dateRange: false,
  });
  const { t } = useTranslation();
  const [ dateRange, setDateRange ] = useState(dateRangeResetValues);
  const { startLoading, endLoading } = useContext(GlobalLoaderContext);

  useEffect(() => {
    setDateRange(defaultDateRange || getPreviousMonthDateRange());
  }, []);

  useEffect(() => {
    setSelectedAccounts(accountList.filter(w => w.checked));
  }, [accountList]);

  const handleDocumentKeyUp = event => {
    if (event.keyCode === ESCAPE_KEY) {
      closeModal();
    }
  };

  useEffect(() => {
    document.addEventListener('keyup', handleDocumentKeyUp);

    return () => document.removeEventListener('keyup', handleDocumentKeyUp);
  }, []);

  const closeModal = () => {
    setIsOpen(false);
  };

  const setSelectedAccounts = acc => {
    if (!acc || (Array.isArray(acc) && acc.length === 0)) {
      return setAccounts(null);
    }

    setAccounts(Array.isArray(acc) ? acc : [acc]);

    return null;
  };

  const setSelectFormat = ({ value }) => {
    if (value === format) return;

    setFormat(value);

    if (isAccountDisabled) return;

    if (isPdfFormat(value)) {
      setAccounts(null);
      setTouchedFormFields({
        ...touchedFormFields,
        accounts: true,
      });
    }
  };

  const setSelectedDateRange = value => {
    if (!value) {
      setTouchedFormFields({
        ...touchedFormFields,
        dateRange: true,
      });

      return setDateRange(null);
    }
    setDateRange(value);

    return null;
  };

  const onDownload = async () => {
    if (!accounts || !dateRange?.from || !dateRange?.to) {
      return setTouchedFormFields({
        dateRange: true,
        accounts: true,
      });
    }

    const query = formatQuery(format, dateRange, accounts);
    const downloadStatement = isPdfFormat(format)
      ? downloadPDF
      : downloadCSV;

    try {
      startLoading();
      const { reportPath } = await downloadStatement(query);
      window.open(reportPath, '_self');
    } catch (error) {
      showGeneralToastError(error);
    } finally {
      endLoading();
      closeModal();
    }

    return null;
  };

  return (
    <Modal isOpen={isOpen} shouldCloseOnOverlayClick visibleOverflowY>
      <S.Content>
        <S.Title>
          {t('activity.downloadStatement.title')}
        </S.Title>
        <S.Separator />

        <S.DropdownWrapper>
          <Dropdown
            placeholder={t('activity.downloadStatement.format')}
            label={t('activity.downloadStatement.format')}
            id="format"
            name={t('activity.downloadStatement.format')}
            onChange={setSelectFormat}
            options={statementFormatsOptions}
            defaultValue={statementFormatsOptions[0]}
            isClearable={false}
          />
        </S.DropdownWrapper>

        <S.DropdownWrapper>
          <Dropdown
            placeholder={t('activity.downloadStatement.selectAccount')}
            label={t('activity.downloadStatement.account')}
            id="account"
            name={t('activity.downloadStatement.account')}
            onChange={setSelectedAccounts}
            error={(touchedFormFields.accounts && !accounts) ? t('activity.downloadStatement.accountError') : ''}
            options={accountList}
            isMulti={!isAccountDisabled && !isPdfFormat(format)}
            isDisabled={isAccountDisabled}
            value={accounts}
            isClearable={false}
          />
        </S.DropdownWrapper>

        <S.DateRange
          value={dateRange}
          onChange={setSelectedDateRange}
          onClear={() => setDateRange(null)}
          inputError={(touchedFormFields.dateRange && !dateRange) && t('activity.downloadStatement.dateRangeError')}
          hasExternalInputFields
          isPopup
          displayCustomRanges
        />

        <S.Separator />

        <S.ButtonsContainer>
          <S.DownloadButton
            label={t('activity.downloadStatement.download')}
            icon={<S.DownloadIcon />}
            category="primary"
            onClick={() => onDownload()}
            offset="true"
          />
          <S.CancelButton
            label={t('activity.downloadStatement.cancel')}
            category="secondary"
            onClick={() => closeModal()}
          />
        </S.ButtonsContainer>
      </S.Content>
    </Modal>
  );
}

DownloadStatementsModal.propTypes = {
  isOpen: PropTypes.bool,
  isAccountDisabled: PropTypes.bool,
  setIsOpen: PropTypes.func,
  accountList: PropTypes.instanceOf(Array),
  defaultDateRange: PropTypes.instanceOf(Array),
};

export default DownloadStatementsModal;
