import { Checkbox as IntergiroCheckbox } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';
import { hex2rgba } from 'helpers/styles';

const CheckboxGroupSelectAllWrapper = styled.div`
  border-bottom: 0.0625rem solid ${props => hex2rgba(props.theme.colors.black, 0.1)};
  margin: 1rem 1.25rem 0 1.25rem;
  display: flex;
  padding-bottom: 0.6875rem;

  @media ${DEVICE.tablet} {
    margin: 1rem 0 0;
    padding-left: 1rem;
    padding-right: 1.125rem;
  }
`;

const Checkbox = styled(IntergiroCheckbox)`
  width: auto;

  > span:last-of-type {
    display: block;
    height: 100%;
    width: 100%;
  }
`;

const CheckboxSingleWrapper = styled.div`
  align-items: center;
  display: flex;
  margin: 0 1.25rem;

  background: ${props => props.isActive
  ? `${hex2rgba(props.theme.colors.cream, 0.3)  } !important`
  : props.theme.colors.white};
  border-bottom: 0.06rem solid ${props => props.isActive
    ? `${hex2rgba(props.theme.colors.black, 0.5)  } !important`
    : props.theme.colors.white};

  &:hover {
    background: ${props => hex2rgba(props.theme.colors.greyBright, 0.3)};
    border-bottom: 0.06rem solid ${props => hex2rgba(props.theme.colors.black, 0.1)};
  }  

  @media ${DEVICE.tablet} {
    margin: 0;
  }

  @media ${DEVICE.laptop} {
    padding-left: 1rem;
  }
`;

const GroupNameWrapper = styled.div`
  width: 100%;
  padding-top: 0.25rem;

  @media ${DEVICE.laptop} {
    padding-top: 0;
  }
`;

const GroupIconWrapper = styled.div`
  margin-left: auto;
  height: 2rem;
`;

export default {
  CheckboxGroupSelectAllWrapper,
  GroupNameWrapper,
  GroupIconWrapper,
  Checkbox,
  CheckboxSingleWrapper,
};
