import { Button as DefaultButton } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { ReactComponent as DefaultCopyIcon } from 'assets/icons/copy.svg';
import { ReactComponent as DefaultSuccessIcon } from 'assets/icons/success.svg';

const Button = styled(DefaultButton)`
  font-weight: ${props => props.showLable ? 500 : 400};
  font-size: ${props => !props.showLable && '0.875rem'};
  color: ${props => props.showLable ? props.theme.colors.blue : props.theme.colors.greyMedium};
  margin-left: ${props => !props.showLable && '0.5rem'};

  &:enabled:hover {
    color: ${props => props.showLable && props.theme.colors.blue};
    opacity: 0.8; 
  }
`;

const SuccessIcon = styled(DefaultSuccessIcon)`
  width: 1.5rem;
  height: 1.5rem;
`;

const CopyIcon = styled(DefaultCopyIcon)`
  path {
    fill: ${props => props.theme.colors.blue};
  }
`;

export default {
  Button,
  CopyIcon,
  SuccessIcon,
};
