import { printFormat as formatIban } from 'iban';
import PropTypes from 'prop-types';
import React, { memo, useEffect, useState } from 'react';

import { accountShape } from 'local-prop-types';
import { formatBankCode } from 'utils/format';
import { isCardTransaction as checkForCardTransaction } from 'utils/transactions';

import S from './styles';

function DescriptionBlock({ reference, category, type, purposeCode, account, isFeeTransaction }) {
  const [ isCardTransaction, setIsCardTransaction ] = useState(false);

  useEffect(() => {
    setIsCardTransaction(checkForCardTransaction(type));
  }, []);

  return (
    <S.DescriptionWrapper>
      {reference && (
        <S.SectionWrapper>
          <S.Label isSpacedBottom>Reference</S.Label>
          <S.Text>
            {reference}
          </S.Text>
        </S.SectionWrapper>
)}

      {category && (
        <S.SectionWrapper>
          <S.Label isSpacedBottom>Category</S.Label>
          <S.Text>
            {category}
          </S.Text>
        </S.SectionWrapper>
)}

      {purposeCode && (
        <S.SectionWrapper>
          <S.Label isSpacedBottom>Purpose code</S.Label>
          <S.Text>
            {purposeCode}
          </S.Text>
        </S.SectionWrapper>
)}
      {account && (
        <S.SectionWrapper isHidden={isCardTransaction || isFeeTransaction}>
          <S.Label isSpacedBottom>Account details</S.Label>
          <S.Text>
            {`${formatIban(account.iban)  } / ${  formatBankCode(account.code)}`}
          </S.Text>
        </S.SectionWrapper>
)}
    </S.DescriptionWrapper>
  );
}

DescriptionBlock.propTypes = {
  type: PropTypes.string.isRequired,
  reference: PropTypes.string,
  category: PropTypes.string,
  purposeCode: PropTypes.string,
  account: accountShape,
  isFeeTransaction: PropTypes.bool.isRequired,
};

export default memo(DescriptionBlock);
