import { CURRENCIES } from 'constants/currencies';
import { INTERNATIONAL_RAILS, LOCAL_RAILS } from 'pages/send-funds/constants';

export function generateDefaultPaymentReference(
  currency,
  rails,
  companyName,
  iban,
) {
  const case1Text = `
    We have populated your Payment Reference with your name, which beneficiaries can use to identify your payments.
    You can change this information to specify a different reference if you wish, but please be aware that your name
    will not be provided if you remove them from this field
  `;

  const case2Text = `
    We have populated your Payment Reference with your unique SWIFT IBAN details, which beneficiaries can use to send
    return payments to you. You can change this information to specify a different reference if you wish, but please be
    aware that your IBAN details will not be provided if you remove them from this field
  `;

  const case2Currencies = [
    CURRENCIES.EUR,
    CURRENCIES.GBP,
    CURRENCIES.USD,
    CURRENCIES.SEK,
    CURRENCIES.DKK,
    CURRENCIES.NOK,
    CURRENCIES.PLN,
    CURRENCIES.AUD,
  ];

  const case1Currencies = [
    CURRENCIES.USD,
    CURRENCIES.SEK,
    CURRENCIES.DKK,
    CURRENCIES.NOK,
    CURRENCIES.PLN,
    CURRENCIES.AUD,
  ];

  const defaultCaseCurrencies = [ CURRENCIES.EUR, CURRENCIES.GBP ];

  switch (true) {
    case case1Currencies.includes(currency) && rails === LOCAL_RAILS:
      return { value: companyName, hintText: case1Text };
    case case2Currencies.includes(currency) && rails === INTERNATIONAL_RAILS && Boolean(iban):
      return { value: iban, hintText: case2Text };
    case defaultCaseCurrencies.includes(currency) && rails === LOCAL_RAILS:
    default:
      return { value: '' };
  }
}
