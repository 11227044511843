import { Dropdown as Select, Toggle as DefaultToggle } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';

const Row = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: ${props => props.total ? '0.625rem' : 0};
  margin-bottom: 1.25rem;

  @media ${DEVICE.laptop} {
		flex-direction: row;
    width: 30rem;
	}

  @media ${DEVICE.tablet} {
		flex-direction: row;
    width: 30rem;
	}
`;

const InputContainer = styled.div`
  width: 100%;

  @media ${DEVICE.laptop} {
		margin-bottom: 0;
	}
`;

const Dropdown = styled(Select)`
  span[data-testid="currency-option-amount"] {
    display: none;
  }

  .select__menu {
    span[data-testid="currency-option-amount"] {
      display: block;
    }
  }
`;

const Toggle = styled(DefaultToggle)`
  margin-top: 0;
  width: 100%;

  @media ${DEVICE.laptop} {
    margin-top: 0.625rem;
  }
`;

export default {
  Row,
  Dropdown,
  InputContainer,
  Toggle,
};
