import PropTypes from 'prop-types';
import React from 'react';

import CardGridItem from '../card-grid-item';
import CardListItem from '../card-list-item';
import S from './styles';

function Section({ cards, isGrid }) {
  return isGrid ? (
    <S.GridContainer>
      {cards.map(card => <CardGridItem card={card} key={card.id} />)}
    </S.GridContainer>
  ) : (
    <S.ListContainer>
      {cards.map(card => <CardListItem card={card} key={card.id} />)}
    </S.ListContainer>
  );
}

Section.propTypes = {
  cards: PropTypes.instanceOf(Array),
  isGrid: PropTypes.bool,
};
export default Section;
