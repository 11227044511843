import PropTypes from 'prop-types';
import React, { memo, useEffect, useState } from 'react';

import { transactionType } from 'constants/transactions';
import { accountShape } from 'local-prop-types';

import { CARD_ISSUANCE_FEE_PAYMENTS_DETAILS } from '../constants';
import AccountInfoBlock from '../account-info-block';

function CardAccountInfoBlock({
  type,
  reference,
  fromAccount,
  toAccount,
  sourceCurrency,
  targetCurrency,
  isIncomingTransaction,
  isCardTransaction,
}) {
  const [ readyToBeRendered, setReadyToBeRendered ] = useState(false);
  const [ currency, setCurrency ] = useState();
  const [ account, setAccount ] = useState();
  const [ isAccountVisible, setIsAccountVisible ] = useState(false);

  useEffect(() => {
    recalculateIsAccountVisible();
    recalculateCurrency();
    recalculateAccount();

    setReadyToBeRendered(true);
  }, []);

  useEffect(() => {
    recalculateIsAccountVisible();
  }, [ reference, isCardTransaction, type ]);

  useEffect(() => {
    recalculateCurrency();
  }, [ isIncomingTransaction, targetCurrency, sourceCurrency ]);

  useEffect(() => {
    recalculateAccount();
  }, [ isIncomingTransaction, toAccount, fromAccount ]);

  function recalculateAccount() {
    setAccount(isIncomingTransaction ? toAccount : fromAccount);
  }

  function recalculateCurrency() {
    setCurrency(isIncomingTransaction ? targetCurrency : sourceCurrency);
  }

  function recalculateIsAccountVisible() {
    const isCardIssuanceFee = CARD_ISSUANCE_FEE_PAYMENTS_DETAILS.includes(reference);
    setIsAccountVisible((isCardTransaction || type === transactionType.cardFee) && !isCardIssuanceFee);
  }

  if (!readyToBeRendered || !isAccountVisible) return null;

  return (
    <AccountInfoBlock label="Account" currency={currency} name={account.displayName} showNameAsLink />
  );
}

CardAccountInfoBlock.propTypes = {
  type: PropTypes.string.isRequired,
  reference: PropTypes.string,
  fromAccount: accountShape.isRequired,
  toAccount: accountShape,
  sourceCurrency: PropTypes.string.isRequired,
  targetCurrency: PropTypes.string.isRequired,
  isIncomingTransaction: PropTypes.bool.isRequired,
  isCardTransaction: PropTypes.bool.isRequired,
};

export default memo(CardAccountInfoBlock);
