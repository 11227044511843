import { Link as DefaultLink } from 'react-router-dom';
import styled from 'styled-components';

import { Badge as DefaultBadge } from 'components';
import { tableRows } from 'constants/api';
import { DEVICE } from 'constants/breakpoints';
import { hex2rgba } from 'helpers/styles';

const getSectionWidth = props => {
  switch (props.name) {
    case 'name':
      return '29.5%';
    case 'creation_date':
      return '19%';
    case 'freeze_date':
      return '22%';
    default:
      return '29.5%';
  }
};

const getSectionDisplay = props => {
  if (props.name === tableRows.creationDate || props.name === tableRows.freezeDate) return 'none';

  return 'flex';
};

const Table = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.header`
  height: 3.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 1rem;
  border-bottom: 0.0625rem solid ${props => props.theme.colors.greyBright};
`;

const Section = styled.div`
  display: ${getSectionDisplay};
  width: ${props => props.name === 'name' ? '77%' : 'auto'};
  flex-direction: column;
  margin-left: ${props => props.name ? 'none' : 'auto'};
  padding-right: ${props => props.name ? '1rem' : 'unset'};
  box-sizing: border-box;

  @media ${DEVICE.tablet} {
    width: 50%;
  }

  @media ${DEVICE.laptop} {
    display: flex;
    width: ${getSectionWidth};
  }
`;

const HeaderLabel = styled.span`
  font: ${props => props.theme.fonts.bodyS};
  color: ${props => props.theme.colors.greyMedium};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: row;
  transition: ${props => props.theme.transition};

  &:hover {
    background-color: ${props => props.theme.colors.greyBright}
  }
`;

const Text = styled.span`
  font: ${props => props.theme.fonts.bodyMSemi};
  margin-bottom: 0.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Subtext = styled.span`
  font: ${props => props.theme.fonts.bodyXs};
  color: ${props => props.isWarning ? props.theme.colors.orange : props.theme.colors.greyMedium};
  margin-bottom: 0.25rem;

  @media ${DEVICE.laptop} {
    display: ${props => props.mobileOnly ? 'none' : 'inherit'};
  }
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
`;

const Badge = styled(DefaultBadge)`
  ${props => props.isHidden && 'display: none'};
  margin-right: 0.625rem;
  cursor: pointer;

  @media ${DEVICE.tablet} {
    margin-right: 1.375rem;
  }
`;

const RenewLink = styled.span`
  display: none;
  align-items: center;
  font: ${props => props.theme.fonts.bodyMSemi};
  color: ${props => props.theme.colors.blue};
  margin-right: 1rem;
  cursor: pointer;
  white-space: nowrap;

  @media ${DEVICE.laptop} {
    display: flex;
  }
`;

const IconButton = styled.button`
  padding: 0;
  margin-right: ${props => props.renew ? '1rem' : 'unset'};
  align-items: center;
  border-radius: 0.25rem;
  border: none;
  background: ${props => props.renew ? hex2rgba(props.theme.colors.blue, 0.08) : hex2rgba(props.theme.colors.red, 0.08)};
  opacity: ${props => (props.disabled ? 0.6 : 1)};
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  display: flex;
  height: 1.75rem;
  justify-content: center;
  width: 1.75rem;
  z-index: 1;

  @media ${DEVICE.laptop} {
    display: ${props => props.renew ? 'none' : 'flex'};
  }

  svg {
    fill: ${props => props.renew ? props.theme.colors.blue : props.theme.colors.red};
    opacity: ${props => (props.disabled ? 0.6 : 1)};
    width: ${props => props.renew ? '1.5rem' : 'auto'};
    height: ${props => props.renew ? '1.5rem' : 'auto'};
  }

  &:hover {
    svg {
      fill: ${props => props.renew ? props.theme.colors.blue : props.theme.colors.red};
      opacity: 0.8;  
    }
  }
`;

const Link = styled(DefaultLink)`
  color: ${props => props.theme.colors.blue};
`;

export default {
  Table,
  Header,
  Section,
  HeaderLabel,
  Content,
  Row,
  Text,
  Subtext,
  Actions,
  Badge,
  RenewLink,
  IconButton,
  Link,
};
