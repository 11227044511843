import { Button as DefaultButton } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { ReactComponent as ChipIcon } from 'assets/icons/chip.svg';
import { ReactComponent as FrozenIcon } from 'assets/icons/frozen.svg';
import { ReactComponent as IntergiroIcon } from 'assets/icons/intergiro.svg';
import { ReactComponent as MastercardIcon } from 'assets/icons/mastercard.svg';
import { ReactComponent as VisaIcon } from 'assets/icons/visa.svg';
import { hex2rgba } from 'helpers/styles';

const getDimmedColors = props => {
  if (props.physical || props.$physical) {
    if (props.dimmed || props.$dimmed) {
      return hex2rgba(props.theme.colors.white, 0.5);
    }

    return props.theme.colors.greyBright;
  }

  if (props.dimmed || props.$dimmed) {
    return hex2rgba(props.theme.colors.black, 0.3);
  }

  return props.theme.colors.greyDark;
};

const CardContainer = styled.div`
  position: relative;
  height: 12.5rem;
  width: 20rem;
`;

const Card = styled.div`
  background: ${props => (props.physical ? props.theme.colors.intergiroBlack : props.theme.colors.white)};
  box-shadow: 0 0.125rem 1.5rem ${props => hex2rgba(props.theme.colors.greyDark, 0.1)},
    inset 0 0 0.0625rem ${props => hex2rgba(props.theme.colors.greyDark, 0.4)};
  border-radius: 0.5rem;
  height: 12.5rem;
  width: 20rem;
  padding: 1.875rem 1.5rem 1.5rem 1.5rem;
  flex-direction: column;
  display: flex;
  position: absolute;
  ${props => (props.back ? 'justify-content: space-between;' : '')};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  ${props => (props.isCardDetails ? 'width: 10.5rem;' : '')};
`;

const Intergiro = styled(IntergiroIcon)`
  fill: ${props => getDimmedColors(props)};
`;

const Type = styled.p`
  color: ${props => getDimmedColors(props)};
  font: ${props => props.theme.fonts.bodyXsBold};
  margin: 0;
  padding-top: 0.1875rem;
`;

const LastFour = styled.div`
  color: ${props =>
  props.physical ? hex2rgba(props.theme.colors.white, 0.5) : hex2rgba(props.theme.colors.black, 0.4)};
  font: ${props => props.theme.fonts.bodySBold};
  line-height: 1.5rem;
  margin-top: 0.625rem;
  letter-spacing: 0.02em;
`;

const CardName = styled.p`
  max-width: 11.25rem;
  margin: 0.125rem 0 0;
  color: ${props => getDimmedColors(props)};
  font: ${props => props.theme.fonts.bodyS};
  line-height: 1.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Visa = styled(VisaIcon)`
  fill: ${props => (props.$physical ? props.theme.colors.white : '#15195A')};
  opacity: ${props => (props.$dimmed ? 0.5 : 1.0)};
`;

const Mastercard = styled(MastercardIcon)`
  opacity: ${props => (props.$dimmed ? 0.5 : 1.0)};
`;

const Frozen = styled(FrozenIcon)`
  position: absolute;
  fill: ${props => (props.$physical ? props.theme.colors.white : props.theme.colors.black)};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ActivateButton = styled(DefaultButton)`
  position: absolute;
  min-width: 6.5rem;
  border-color: ${props => (props.physical ? props.theme.colors.white : props.theme.colors.black)};
  color: ${props => (props.physical ? props.theme.colors.white : props.theme.colors.black)};
  padding: 0.5rem 1rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Label = styled.p`
  margin: 0;
  color: ${props =>
  props.physical ? hex2rgba(props.theme.colors.white, 0.5) : hex2rgba(props.theme.colors.black, 0.3)};
  font: ${props => props.theme.fonts.bodyXxsBold};
`;

const Value = styled.p`
  margin: 0;
  color: ${props => (props.physical ? props.theme.colors.white : props.theme.colors.black)};
  font: ${props => props.theme.fonts.bodyM};
  padding-top: 0.125rem;
`;

const Chip = styled(ChipIcon)`
  ${props => (props.$dimmed ? 'opacity: 0.5' : '')};
  margin-top: 1.875rem;
`;

const Processor = styled.div`
  position: absolute;
  right: 1.5rem;
  bottom: 1.5rem;
`;

const Details = styled.div`
  margin-top: ${props => props.isPhysical ? '0' : '3.875rem'};
  height: 3.75rem;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
`;

export default {
  CardContainer,
  Intergiro,
  Card,
  Details,
  Row,
  Type,
  LastFour,
  CardName,
  Visa,
  Frozen,
  Mastercard,
  ActivateButton,
  Label,
  Value,
  Chip,
  Processor,
};
