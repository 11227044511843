import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';

const LimitItem = styled.div`
  margin: 1.875rem 0 1.3125rem 0;

  @media ${DEVICE.laptop} {
    margin: 2.5rem 0 0 0;
  }
`;

const Subtitle = styled.p`
  font: ${props => props.theme.fonts.bodyLBold};
  margin: 0;
`;

const ProgressContainer = styled.div`
  background: ${props => props.theme.colors.greyBright};
  height: 0.25rem;
  border-radius: 0.1875rem;
  margin: 0.75rem 0;
`;

function getProgressColor({ progress, theme, warningProgress }) {
  const isWarning = progress >= warningProgress;

  return isWarning ? theme.colors.red : theme.colors.blue;
}

const Progress = styled.div`
  background: ${props => getProgressColor(props)};
  width: ${props => `${props.progress}%`};
  height: 0.25rem;
`;

const Label = styled.span`
  font: ${props => props.theme.fonts.bodyXs};

  @media ${DEVICE.tablet} {
    font: ${props => props.theme.fonts.bodyS};
  }
`;

const TextValue = styled.span`
  font: ${props => props.theme.fonts.bodyM};
`;

const DetailsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const DimmedText = styled.div`
  color: ${props => props.theme.colors.greyMedium};
`;

export default {
  Subtitle,
  ProgressContainer,
  Progress,
  Label,
  TextValue,
  DetailsContainer,
  LimitItem,
  DimmedText,
};
