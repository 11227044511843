export const defaultLayoutProps = {
  title: 'Bulk payments',
  displayCancelButton: true,
};

export const viewMap = {
  bulkPaymentsForm: 'bulkPaymentsForm',
  bulkPaymentsConfirmation: 'bulkPaymentsConfirmation',
  bulkPaymentsFinal: 'bulkPaymentsFinal',
};

export const titlesMap = {
  paymentTitle: 'payment',
  paymentsTitle: 'payments',
  confirmationTitle: 'Bulk payment',
  successTitle: 'Bulk payment sent',
  errorTitle: 'Bulk payment unsuccessful',
  defaultTitle: 'Bulk payments',
};
