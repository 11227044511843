import styled from 'styled-components';

const AssistiveTextIcon = styled.span`
  position: relative;
  top: 0.16rem;
`;

const LabelsWrapper = styled.div`
    margin-top: 1rem;
`;

export default {
  AssistiveTextIcon,
  LabelsWrapper,
};
