import styled from 'styled-components';

import { hex2rgba } from 'helpers/styles';
import { DEVICE } from 'constants/breakpoints';

const Container = styled.label`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  border-radius: 0.5rem;
  
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    box-shadow: ${props => props.theme.boxShadow};
    box-sizing: border-box;
    border-radius: 0.5rem;
    border: ${props => props.isChecked ? `0.125rem solid ${props.theme.colors.greyMedium}` : null};
    transition: ${props => props.theme.transition};
  }

  &:hover {
    ${props => !props.isChecked && `
      cursor: pointer;
    `}

    &::before {
      ${props => !props.isChecked && `
        box-shadow: 0 0.3125rem 1.25rem rgba(17, 17, 17, 0.12), inset 0 0 0.0625rem rgba(17, 17, 17, 0.3);
      `}
    }
  }

  @media ${DEVICE.tablet} {
    flex-direction: column;
  }
`;

const Content = styled.div`
  padding: 1.25rem;
`;

const Title = styled.div`
  font: ${props => props.theme.fonts.bodyMBold};
  margin-bottom: 0.25rem;
`;

const Description = styled.span`
  display: inline-block;
  font: ${props => props.theme.fonts.bodyXxsSemi};
  color: ${props => props.theme.colors.greyMedium};
  margin-bottom: 1.25rem;
  white-space: pre-wrap;
`;

const Image = styled.div`
  background: ${props => props.isChecked ? hex2rgba(props.theme.colors.blue, 0.2) : props.theme.colors.greyBright};
  display: flex;
  width: 6.5rem;
  flex-shrink: 0;
  
  svg {
    width: 100%;
  }
  
  @media ${DEVICE.tablet} {
    width: auto;
    height: 10.125rem;
    border-radius: 0.5rem 0.5rem 0 0;
  }

  @media ${DEVICE.desktop} {
    width: 14.6875rem;
  }
`;

export default {
  Container,
  Image,
  Content,
  Title,
  Description,
};
