import { Modal as DefaultModal, Title as DefaultTitle } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { ReactComponent as Cross } from 'assets/icons/cross-2.svg';
import { DEVICE } from 'constants/breakpoints';
import { hex2rgba } from 'helpers/styles';

const Modal = styled(DefaultModal)`
  .Modal {
    ${props => props.unsetOverflow && 'overflow-y: unset'};
  }
`;

const Content = styled.div`
  position: relative;
  max-width: unset;
  width: auto;
  -webkit-overflow-scrolling: touch;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;

  @media ${DEVICE.tablet} {
    width: ${props => props.isLarge ? '50rem' : '33.75rem'};
    min-height: auto;
  }
`;

const Header = styled.header`
  position: sticky;
  position: -webkit-sticky; /* stylelint-disable-line value-no-vendor-prefix */
  top: 0;
  left: 0;
  right: 0;
  background: ${props => props.theme.colors.white};
  z-index: 2;

  @media ${DEVICE.tablet} {
    width: 100%;
  }
`;

const HeaderInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1.25rem;
  padding: 2.125rem 1.25rem 2.3125rem 1.25rem;

  @media ${DEVICE.tablet} {
    padding: 1.875rem 1.875rem 0 1.875rem;
  }
`;

const CloseIcon = styled(Cross)`
  cursor: pointer;
  path {
    fill: ${props => props.theme.colors.black};
  }
`;

const BodyWrapper = styled.div`
  padding: 1.875rem 1.25rem;
  flex: 1;

  @media ${DEVICE.tablet} {
    margin-top: 0;
    padding: 1.25rem 1.875rem 0 1.875rem;
  }
`;

const Title = styled(DefaultTitle)`
  padding: 0;
  font: ${props => props.theme.fonts.h3Bold};

  @media ${DEVICE.tablet} {
    font: ${props => props.theme.fonts.h4Bold};
  }
`;

const Separator = styled.hr`
  border-top: ${props => hex2rgba(props.theme.colors.black, 0.1)};
  margin: 0;

  @media ${DEVICE.tablet} {
    margin: 1.25rem 1.875rem 0 1.875rem;
  }
`;

const Footer = styled.div`
  position: sticky;
  position: -webkit-sticky; /* stylelint-disable-line value-no-vendor-prefix */
  bottom: 0;
  left: 0;
  right: 0;
  background: ${props => props.theme.colors.white};
  z-index: 1;

  @supports (-webkit-touch-callout: none) {
    bottom: 4.3125rem;
  }

  @media ${DEVICE.tablet} {
    position: ${({ hasStickyFooter }) => hasStickyFooter ? 'sticky' : 'relative'};
    width: 100%;
  }
`;

const CTAContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1.25rem;

  @media ${DEVICE.tablet} {
    flex-direction: row;
    align-items: center;
    justify-content: start;
    padding: 0 1.25rem 1.875rem 1.25rem;
    padding-top: ${props => props.hasExtraTopPadding ? '1.875rem' : '1.25rem'};
  }
`;

export default {
  Title,
  CloseIcon,
  Content,
  Separator,
  CTAContainer,
  Header,
  HeaderInner,
  BodyWrapper,
  Footer,
  Modal,
};
