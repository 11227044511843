import { lazy, object, string } from 'yup';
import { t } from 'i18next';

import { fxCurrenciesMap } from 'constants/fx';
import {
  BENEFICIARY_BANK_ACCOUNT_COUNTRY,
  BENEFICIARY_COUNTRY,
  BENEFICIARY_CURRENCY,
  BENEFICIARY_DETAILS_RESPONSE,
  BENEFICIARY_IBAN,
  BENEFICIARY_NAME,
  BENEFICIARY_PATHS_MAP,
  BENEFICIARY_TYPE,
  LOCAL_RAILS,
  RAILS_FIELD_NAME,
} from 'pages/send-funds/constants';
import { beneficiariesFieldsLabelMap, getFieldRegexFromBeneficiaryDetails } from 'pages/send-funds/utils';
import { trimAllSpaces } from 'utils/format';

function prepareRequiredFields(values = {}) {
  const isLocalEur =
    values[BENEFICIARY_CURRENCY]?.value === fxCurrenciesMap.EUR &&
    values[RAILS_FIELD_NAME] === LOCAL_RAILS;

  return isLocalEur
    ? {
        [BENEFICIARY_CURRENCY]: object().shape({
          label: object()
            .required(t('sendFunds.validation.required')),
          value: string().required(),
        }),
        [RAILS_FIELD_NAME]: string()
          .nullable()
          .required(t('sendFunds.validation.required')),
      }
    : {
        [BENEFICIARY_CURRENCY]: object().shape({
          label: object()
            .required(t('sendFunds.validation.required')),
          value: string().required(),
        }),
        [RAILS_FIELD_NAME]: string()
          .nullable()
          .required(t('sendFunds.validation.required')),
        [BENEFICIARY_BANK_ACCOUNT_COUNTRY]: object().shape({
          label: string().nullable().required(t('sendFunds.validation.required')),
          value: string().nullable().required(t('sendFunds.validation.required')),
        }).nullable().required(t('sendFunds.validation.required')),
        [BENEFICIARY_COUNTRY]: object().shape({
          label: string().nullable().required(t('sendFunds.validation.required')),
          value: string().nullable().required(t('sendFunds.validation.required')),
        }).nullable().required(t('sendFunds.validation.required')),
        [BENEFICIARY_TYPE]: object().shape({
          label: string().nullable().required(t('sendFunds.validation.required')),
          value: string().nullable().required(t('sendFunds.validation.required')),
        }).nullable().required(t('sendFunds.validation.required')),
      };
}

function prepareAdditionalBeneficiaryFields(values = {}) {
  const beneficiaryDetailsResponse = values[BENEFICIARY_DETAILS_RESPONSE];

  return beneficiaryDetailsResponse
    ?.map(({ name, type }) => type
      ? BENEFICIARY_PATHS_MAP[`${name}.${type}`]
      : BENEFICIARY_PATHS_MAP[name])
    .filter(beneficiaryField => Boolean(beneficiaryField))
    .map(({ fieldName }) => {
      const regex = getFieldRegexFromBeneficiaryDetails(fieldName, beneficiaryDetailsResponse);

      return { fieldName, regex };
    })
    .reduce((result, conditions) => {
      if (!conditions) {
        return result;
      }

      if (conditions.fieldName === BENEFICIARY_NAME) {
        return {
          ...result,
          [conditions.fieldName]: object().shape({
            label: string().nullable().max(70, t('sendFunds.validation.nameMaxLength')).required(t('sendFunds.validation.required')),
            value: object().required(t('sendFunds.validation.required')),
          }).nullable().required(t('sendFunds.validation.required')),
        };
      }

      return {
        ...result,
        [conditions.fieldName]: object().shape({
          label: string()
            .transform(value => {
              if (conditions.fieldName === BENEFICIARY_IBAN) {
                const trimmedValue = trimAllSpaces(value);

                return trimmedValue;
              }

              return value;
            })
            .nullable()
            .matches(new RegExp(conditions.regex),
            `
              Please enter a valid
              ${beneficiariesFieldsLabelMap[conditions.fieldName] || conditions.fieldName}
            `)
            .required(t('sendFunds.validation.required')),
          value: object().required(),
        })
          .nullable().required(t('sendFunds.validation.required')),
      };
    }, {});
}

export default function createBeneficiaryValidationSchema() {
  return lazy(values => {
    const requiredFields = prepareRequiredFields(values);
    const additionalBeneficiaryFields = prepareAdditionalBeneficiaryFields(values);

    return object().shape({
      ...requiredFields,
      ...additionalBeneficiaryFields,
    });
  });
}
