import { printFormat as formatIban } from 'iban';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { cardStatusesMap, PHYSICAL_CARD_TYPE } from 'constants/card';
import { cardCredentialsShape, cardShape } from 'local-prop-types';
import { formatExpirationDate } from 'utils/card';
import { mapCardHolderFullName, prepareEmbossedCompanyName } from 'utils/card-details';

import S from './styles';

function CardBack({ card, credentials, isNewCard }) {
  const { t } = useTranslation();
  const [isPhysical] = useState(card.type === PHYSICAL_CARD_TYPE);
  const [isDimmed] = useState(card.status !== cardStatusesMap.active);
  const nameAndSurnameData = {
    firstName: card.embossedData?.embossedFirstName || card.cardholder?.firstName,
    lastName: card.embossedData?.embossedLastName || card.cardholder?.lastName,
  };

  return (
    <S.Card physical={isPhysical} dimmed={isDimmed} back>
      <div>
        <S.Label physical={isPhysical}>
          {t('components.card.back.cardHolder')}
        </S.Label>
        <S.Value physical={isPhysical}>
          {mapCardHolderFullName(nameAndSurnameData)}
        </S.Value>
      </div>
      {card.companyName && (
        <div>
          <S.Label physical={isPhysical}>
            {t('components.card.back.company')}
          </S.Label>
          <S.Value physical={isPhysical}>
            {prepareEmbossedCompanyName(card.companyName)}
          </S.Value>
        </div>
      )}
      {!isNewCard && (
        <div>
          <S.Label physical={isPhysical}>
            {t('components.card.back.cardNumber')}
          </S.Label>
          <S.Value physical={isPhysical}>
            {credentials?.pan?.full && formatIban(credentials?.pan?.full)}
          </S.Value>
        </div>
      )}
      <S.Row isCardDetails>
        <div>
          <S.Label physical={isPhysical}>
            {t('components.card.back.validThru')}
          </S.Label>
          <S.Value physical={isPhysical}>
            {formatExpirationDate(card.expiry)}
          </S.Value>
        </div>
        {!isNewCard && (
          <div>
            <S.Label physical={isPhysical}>CVC</S.Label>
            <S.Value physical={isPhysical}>
              {credentials?.cvc2}
            </S.Value>
          </div>
        )}
      </S.Row>
    </S.Card>
  );
}

CardBack.propTypes = {
  card: cardShape.card.isRequired,
  credentials: cardCredentialsShape,
  isNewCard: PropTypes.bool,
};

export default CardBack;
