import { Button } from '@general/intergiro-ui-kit';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';

function CancelButton({ className, handleClick }) {
  const history = useHistory();

  const onCancel = () => {
    if (handleClick) {
      handleClick();
    } else {
      history.push('/');
    }
  };

  return (
    <Button size="small" label="Cancel" category="secondary" className={className} onClick={onCancel} />
  );
}

CancelButton.propTypes = {
  className: PropTypes.string,
  handleClick: PropTypes.func,
};

export default CancelButton;
