import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';
import { hex2rgba } from 'helpers/styles';

const TransactionActivityRow = styled.button`
  align-items: flex-start;
  background: ${props => props.isActive
  ? `${hex2rgba(props.theme.colors.cream, 0.3)  } !important`
  : props.theme.colors.white};
  border: none;
  border-bottom: 0.06rem solid ${props => props.isActive
    ? `${hex2rgba(props.theme.colors.black, 0.5)  } !important`
    : props.theme.colors.white};
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: space-between;
  margin: 0 auto;
  max-width: calc(100vw - 2.5rem);
  padding: 1.25rem 0 1rem;
  text-align: left;
  transition: ${props => props.theme.transition};
  width: 100%;

  &:hover {
    background: ${props => hex2rgba(props.theme.colors.greyBright, 0.3)};
    border-bottom: 0.06rem solid ${props => hex2rgba(props.theme.colors.black, 0.1)};
  }

  @media ${DEVICE.tablet} {
    max-width: 100%;
    padding: 1.125rem 1.375rem 1.125rem 0.75rem;
  }
`;

const TransactionIconWrapper = styled.div`
  margin-top: 0.25rem;
  margin-right: 1rem;
`;

const TransactionFromWrapper = styled.div`
  display: none;

  @media ${DEVICE.tablet} {
    /* TODO Update to Grid view https://ftcs-tech.atlassian.net/browse/IDE-11546 */
    max-width: 32%;
    margin-right: 4rem;
    width: 100%;
    display: block;
  }
`;

const TransactionToWrapper = styled.div`
  margin-right: auto;
  /* TODO Update to Grid view https://ftcs-tech.atlassian.net/browse/IDE-11546 */
  max-width: 70%;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${DEVICE.tablet} {
    /* TODO Update to Grid view https://ftcs-tech.atlassian.net/browse/IDE-11546 */
    max-width: 32%;
  }
`;

export default {
  TransactionActivityRow,
  TransactionIconWrapper,
  TransactionFromWrapper,
  TransactionToWrapper,
};
