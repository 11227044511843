import { DropdownBeneficiaryOption as BeneficiaryOption } from '@general/intergiro-ui-kit';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { removeBulkPaymentItem, updateBulkPaymentItem } from 'api/bulk-payments';
import { ReactComponent as QuestionIcon } from 'assets/icons/question.svg';
import Hint from 'components/hint';
import { INPUT_PATTERNS } from 'constants/inputs';
import { GlobalLoaderContext } from 'contexts/global-loader';
import {
  errorBicField,
  errorIbanField,
  errorNameField,
  errorNetAmountField,
  errorReferenceField,
} from 'pages/bulk-payments/constants/errors';
import {
  amountFieldName,
  amountRowFieldName,
  referenceFieldName,
  referenceRowFieldName,
  thresholdHintText,
} from 'pages/bulk-payments/constants/form';
import { formatAmount, formatBeneficiaries, getBeneficiaryPayload, hasThreshold } from 'pages/bulk-payments/helpers';
import { showGeneralToastError, getUiErrorMessage } from 'utils/errors';

import S from './styles';

function BeneficiaryRow({
  isTouched,
  beneficiaryRowRefs,
  value,
  label,
  handleBeneficiaryClick,
  account,
  bulkPaymentId,
  addBeneficiaryToList,
  setTotalsByCurrency,
  isChosen,
  setGlobalErrors,
  setDisableContinueButton,
  setBulkPayment,
}) {
  const [ fees, setFees ] = useState(null);
  const [ hasThresholdMessage, setHasThresholdMessage ] = useState(null);
  const [ totalAmount, setTotalAmount ] = useState(null);
  const [ netAmount, setNetAmount ] = useState(value.amount || '');
  const [ reference, setReference ] = useState(value.reference || '');
  const [ beneficiaryData, setBeneficiaryData ] = useState(value);
  const [ error, setError ] = useState(null);
  const [ labelError, setLabelError ] = useState(false);
  const { currency, id: accountId } = account.value;

  const { startLoading, endLoading } = useContext(GlobalLoaderContext);

  const formik = useFormik({
    initialValues: { [amountRowFieldName]: value.amount || '' },
  });

  const { touched, setFieldTouched, setFieldValue } = formik;

  const markFieldsTouched = () => {
    setFieldTouched(referenceRowFieldName, true);
    setFieldTouched(amountRowFieldName, true);
  };

  beneficiaryRowRefs.push({ markFieldsTouched });

  const formatAndSetErrors = (errorArr = []) => {
    const errors = errorArr.reduce((acc, err) => ({ ...acc, [err.fieldName]: err }), {});

    setError(errors);
  };

  useEffect(() => {
    if (value) {
      setNetAmount(formatAmount(value.net));
      setTotalAmount(formatAmount(value.amount));
      setFees(formatAmount(value.fee));
      setReference(value.reference);
      setHasThresholdMessage(hasThreshold(value.feeReason));
      formatAndSetErrors(value.errors);
    }
  }, [value]);

  useEffect(() => {
    if (isTouched) {
      markFieldsTouched();
    }
  }, [isTouched]);

  useEffect(() => {
    setLabelError(error?.[errorNameField] ||
      error?.[errorIbanField] ||
      error?.[errorBicField]);
  }, [error]);

  const openSidebar = () => {
    handleBeneficiaryClick(value);
  };

  const getPatchData = (event, fieldName) => {
    const inputValue = event.target.value;

    switch (fieldName) {
      case amountRowFieldName:
        return { [amountFieldName]: inputValue };
      case referenceRowFieldName:
        return { [referenceFieldName]: inputValue };
      default:
        return {};
    }
  };

  const onBlurHandle = async (event, fieldName) => {
    setDisableContinueButton(true);
    const patchData = getPatchData(event, fieldName);
    const payload = getBeneficiaryPayload(patchData, accountId, currency);

    try {
      const data = await updateBulkPaymentItem(bulkPaymentId, value.id, payload);

      const { fee, net, total } = data.totalsByCurrency[currency.toLowerCase()];
      const { items, totals, errors } = formatBeneficiaries(data);
      const currentBeneficiary = items.find(item => item.id === value.id);

      setBulkPayment(data);
      setFees(fee);
      setTotalAmount(total);
      setNetAmount(formatAmount(net, true));
      markFieldsTouched();
      setBeneficiaryData(currentBeneficiary);
      formatAndSetErrors(currentBeneficiary.errors);
      setTotalsByCurrency(totals);
      addBeneficiaryToList(items);
      setGlobalErrors(errors);
    } catch (err) {
      showGeneralToastError(err);
    } finally {
      setDisableContinueButton(false);
    }
  };

  const onHandleChange = (e, isAmount) => {
    if (isAmount) {
      if (e.target.value === '' || INPUT_PATTERNS.amount.test(e.target.value)
      ) {
        setNetAmount(e.target.value);
        setFieldValue(amountFieldName, e.target.value);
      }
    } else {
      setReference(e.target.value);
    }
  };

  const removeBeneficiary = async () => {
    startLoading();
    setDisableContinueButton(true);

    try {
      const data = await removeBulkPaymentItem(bulkPaymentId, value.id);
      const { items, totals, errors } = formatBeneficiaries(data);

      setBulkPayment(data);
      setTotalsByCurrency(totals);
      addBeneficiaryToList(items);
      setGlobalErrors(errors);
    } catch (err) {
      showGeneralToastError(err);
    } finally {
      endLoading();
      setDisableContinueButton(false);
    }
  };

  const bulkIcon = useCallback(() => <S.BulkPaymentsIcon />, []);

  return (
    <S.TableRowContainer>
      <S.TableRow isChosen={isChosen}>
        <S.TableLabel>
          <S.LabelWrapper onClick={openSidebar}>
            {labelError ? (
              <BeneficiaryOption
                currencyShort={(
                  <S.BeneficiaryMissingInfo>
                    Missing information
                  </S.BeneficiaryMissingInfo>
                )}
                avatar={bulkIcon}
                name={(
                  <S.BeneficiaryName error>
                    {value.name || 'Beneficiary name'}
                  </S.BeneficiaryName>
                )}
                sortCode=" Click for details"
              />
            ) : (label)}
          </S.LabelWrapper>
        </S.TableLabel>
        <ReferenceInput
          reference={reference}
          onBlurHandle={onBlurHandle}
          onHandleChange={onHandleChange}
          error={error?.[errorReferenceField]}
          touched={touched && touched[referenceRowFieldName]}
        />
        <AmountInput
          netAmount={netAmount}
          onBlurHandle={onBlurHandle}
          beneficiaryData={beneficiaryData}
          onHandleChange={onHandleChange}
          error={error?.[errorNetAmountField]}
          touched={touched && touched[amountRowFieldName]}
        />
        <S.TableTotal
          error={
            Boolean(error?.[errorReferenceField]) ||
            error?.[errorNetAmountField]
          }
        >
          <S.TableTotalNet>
            {!totalAmount || totalAmount === ''
              ? '0.00'
              : totalAmount}
            {' '}
            {beneficiaryData.account.currency}
          </S.TableTotalNet>
          {Boolean(fees) && (
            <S.TableTotalFee>
              fees:
              {' '}
              {fees}
              {' '}
              {beneficiaryData.account.currency}
              {hasThresholdMessage && (
                <Hint text={thresholdHintText} width="16.875rem">
                  <S.HelpIconWrapper>
                    <S.Icon
                      icon={
                        <QuestionIcon />
                      }
                      category="link"
                    />
                  </S.HelpIconWrapper>
                </Hint>
              )}
            </S.TableTotalFee>
          )}
        </S.TableTotal>
        <S.ActionColumn>
          <S.ActionColumnWrapper>
            <S.ActionEditIcon onClick={openSidebar} />
            <S.ActionDeleteIcon onClick={removeBeneficiary} />
          </S.ActionColumnWrapper>
        </S.ActionColumn>
      </S.TableRow>
    </S.TableRowContainer>
  );
}

function ReferenceInput({
  reference,
  onBlurHandle,
  onHandleChange,
  error,
  touched,
}) {
  const showError = Boolean(error && touched);

  return (
    <S.TableAmountReferenceInputWrapper offset="true">
      <S.TableAmountReferenceInput
        type="text"
        value={reference || ''}
        name={referenceRowFieldName}
        id={referenceRowFieldName}
        onBlur={e => onBlurHandle(e, referenceRowFieldName)}
        onChange={e => onHandleChange(e, false)}
        error={showError}
      />
      {showError && (
        <S.ErrorMessage>
          {getUiErrorMessage(error?.message, 'Payment details is required')}
        </S.ErrorMessage>
      )}
    </S.TableAmountReferenceInputWrapper>
  );
}

function AmountInput({
  netAmount,
  onBlurHandle,
  beneficiaryData,
  onHandleChange,
  error,
  touched,
}) {
  const showError = Boolean(error && touched);

  return (
    <S.TableAmountReferenceInputWrapper error={showError}>
      <S.TableAmountReferenceInput
        type="text"
        left="true"
        onChange={e => onHandleChange(e, true)}
        value={netAmount || ''}
        onBlur={e => onBlurHandle(e, amountRowFieldName)}
        rtl
        name={amountRowFieldName}
        id={amountRowFieldName}
        placeholder="0.00"
        helperText={(
          <S.CurrencyLabel>
            {beneficiaryData.account.currency}
          </S.CurrencyLabel>
        )}
        error={showError}
      />
      {showError && (
        <S.ErrorMessage>
          {error?.message}
        </S.ErrorMessage>
)}
    </S.TableAmountReferenceInputWrapper>
  );
}

ReferenceInput.propTypes = {
  reference: PropTypes.string,
  onBlurHandle: PropTypes.func.isRequired,
  onHandleChange: PropTypes.func.isRequired,
  error: PropTypes.instanceOf(Object),
  touched: PropTypes.bool,
};

AmountInput.propTypes = {
  netAmount: PropTypes.number.isRequired,
  onBlurHandle: PropTypes.func.isRequired,
  beneficiaryData: PropTypes.instanceOf(Object).isRequired,
  onHandleChange: PropTypes.func.isRequired,
  error: PropTypes.instanceOf(Object),
  touched: PropTypes.bool,
};

BeneficiaryRow.propTypes = {
  isTouched: PropTypes.bool.isRequired,
  beneficiaryRowRefs: PropTypes.instanceOf(Array).isRequired,
  value: PropTypes.instanceOf(Object).isRequired,
  label: PropTypes.instanceOf(Object).isRequired,
  handleBeneficiaryClick: PropTypes.func.isRequired,
  account: PropTypes.instanceOf(Object).isRequired,
  bulkPaymentId: PropTypes.string.isRequired,
  addBeneficiaryToList: PropTypes.func.isRequired,
  setTotalsByCurrency: PropTypes.func.isRequired,
  isChosen: PropTypes.bool.isRequired,
  setGlobalErrors: PropTypes.func.isRequired,
  setDisableContinueButton: PropTypes.func.isRequired,
  setBulkPayment: PropTypes.func.isRequired,
};

export default BeneficiaryRow;
