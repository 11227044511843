import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useApiPayments } from 'hooks';
import { PaymentContext } from 'contexts/payment';
import { EmptyState, PaymentListGroup } from 'components';

import S from './styles';
import { dataTestIds } from './constants';

function APIPayments() {
  const { t } = useTranslation();
  const MAX_AMOUNT_OF_PAYMENTS = 100;
  const {
    payments,
    isSidebarOpen,
    setActivePaymentById,
    activePayment,
  } = useContext(PaymentContext);
  const { isLoading, fetchApiPayments } = useApiPayments();

  useEffect(() => {
    fetchApiPayments();
  }, []);

  if (payments.length === 0 && !isLoading) {
    return <EmptyState title={t('payments.apiPayments.emptyState')} testId={dataTestIds.emptyState} />;
  }

  if (isLoading) return null;

  return (
    <S.PaymentsWrapper isSidebarOpen={isSidebarOpen} data-testid={dataTestIds.wrapper}>
      {payments.length >= MAX_AMOUNT_OF_PAYMENTS && (
        <S.Header data-testid={dataTestIds.maxPayments}>
          <S.MaxPaymentsWrapper>
            {t('payments.apiPayments.maxPayments.title', { amountOfPayments: MAX_AMOUNT_OF_PAYMENTS  })}
            <S.Hint
              text={t('payments.apiPayments.maxPayments.hint')}
              variant="warning"
            />
          </S.MaxPaymentsWrapper>
        </S.Header>
      )}
      <S.PaymentSection>
        <PaymentListGroup
          payments={payments}
          handlePaymentClick={id => setActivePaymentById(id)}
          activePayment={activePayment}
          isGroupPayments
        />
      </S.PaymentSection>
    </S.PaymentsWrapper>
  );
}

export default APIPayments;
