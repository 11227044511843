import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import isNull from 'lodash/isNull';
import isObject from 'lodash/isObject';
import merge from 'lodash/merge';
import omit from 'lodash/omit';
import set from 'lodash/set';

import {
  BENEFICIARY_CURRENCY,
  BENEFICIARY_DETAILS_RESPONSE,
  BUY_FX_SIDE,
  FORM_FIELDS_TO_BENEFICIARY_REQUEST_MAP,
  FROM_ACCOUNT,
  FX_SIDE,
  FX_TRANSACTION_TYPE,
  PAYMENT_REFERENCE,
  PURPOSE_CODE,
  RAILS_FIELD_NAME,
  RAILS_MAP,
  RECEIVE_AMOUNT,
  SELL_FX_SIDE,
  SEND_AMOUNT,
  TRANSFER_TRANSACTION_TYPE,
} from 'pages/send-funds/constants';
import getRate from 'api/fx';
import { reverseFormatNumber, toIndivisibleUnit } from 'utils/amount';

export function isFxOutgoing(beneficiaryFormValues, amountFormValues) {
  const fromAccountCurrency = get(amountFormValues, `${FROM_ACCOUNT}.value.currency`);
  const beneficiaryCurrency = get(beneficiaryFormValues, `${BENEFICIARY_CURRENCY}.value`);

  return beneficiaryCurrency && fromAccountCurrency && beneficiaryCurrency !== fromAccountCurrency;
}

export function canGetRate(beneficiaryFormValues, amountFormValues, selectedFxSide) {
  const amount = selectedFxSide === SELL_FX_SIDE ? amountFormValues[SEND_AMOUNT] : amountFormValues[RECEIVE_AMOUNT];

  return isFxOutgoing(beneficiaryFormValues, amountFormValues) && !isNull(amount);
}

export async function getExchangeRate(beneficiaryFormValues, amountFormValues, fxSide) {
  const { [SEND_AMOUNT]: sendAmount, [RECEIVE_AMOUNT]: receiveAmount } = amountFormValues;
  const fixedAmount = fxSide === BUY_FX_SIDE ? receiveAmount : sendAmount;

  const fromAccountCurrency = get(amountFormValues, `${FROM_ACCOUNT}.value.currency`);

  const beneficiaryCurrency = get(beneficiaryFormValues, `${BENEFICIARY_CURRENCY}.value`);
  const fixedCurrency = fxSide === BUY_FX_SIDE ? beneficiaryCurrency : fromAccountCurrency;
  const query = {
    sellCurrency: fromAccountCurrency,
    buyCurrency: beneficiaryCurrency,
    fixedSide: fxSide,
    amount: toIndivisibleUnit(fixedAmount, fixedCurrency),
  };

  try {
    if (!isFxOutgoing(beneficiaryFormValues, amountFormValues)) {
      return {};
    }
    const rate = await getRate(query);

    return { rate };
  } catch (error) {
    return { error };
  }
}

export function shouldShowRate(beneficiaryFormValues, amountFormValues, fxRate) {
  return !isEmpty(fxRate) && isFxOutgoing(beneficiaryFormValues, amountFormValues);
}

export function getConfirmationBeneficiaryAmount(beneficiaryFormValues, amountFormValues) {
  return isFxOutgoing(beneficiaryFormValues, amountFormValues)
    ? `${amountFormValues[RECEIVE_AMOUNT]}`
    : `${amountFormValues[SEND_AMOUNT]}`;
}

function getRails() {
  return Object.keys(RAILS_MAP).reduce((result, rail) => [ ...result, { rail, ...RAILS_MAP[rail] }], []);
}

export function getRailByCurrency(selectedRails, selectedCurrency) {
  return getRails().find(rail => rail.binaryRail === selectedRails && rail.currencies.includes(selectedCurrency));
}

export function getRailsByBeneficiaryFormValues(beneficiaryFormValues) {
  const { [RAILS_FIELD_NAME]: selectedRails, [BENEFICIARY_CURRENCY]: selectedCurrency } = beneficiaryFormValues;
  const rail = getRailByCurrency(selectedRails, selectedCurrency.value);

  return rail?.rail;
}

export function getBeneficiaryFieldRequirementsByPath(beneficiaryDetailsResponse = [], path = '') {
  return beneficiaryDetailsResponse.find(requirement => requirement.name === path);
}

function mapBeneficiaryFormValuesToBeneficiaryRequest(beneficiaryFormValues) {
  const beneficiary = {};

  Object.keys(FORM_FIELDS_TO_BENEFICIARY_REQUEST_MAP).map(fieldName => {
    const fieldRequirements = getBeneficiaryFieldRequirementsByPath(beneficiaryFormValues[BENEFICIARY_DETAILS_RESPONSE],
      FORM_FIELDS_TO_BENEFICIARY_REQUEST_MAP[fieldName]);
    const beneficiaryFormValue = isObject(beneficiaryFormValues[fieldName]?.value)
      ? beneficiaryFormValues[fieldName]?.label
      : beneficiaryFormValues[fieldName]?.value;
    const beneficiaryFormValueExists = !isNil(beneficiaryFormValue);
    const fieldValue =
      fieldRequirements?.type && beneficiaryFormValueExists
        ? {
            type: fieldRequirements.type,
            value: beneficiaryFormValue,
          }
        : beneficiaryFormValue;

    return (
      fieldRequirements && fieldValue && set(beneficiary, FORM_FIELDS_TO_BENEFICIARY_REQUEST_MAP[fieldName], fieldValue)
    );
  });

  return beneficiary;
}

export function prepareBeneficiaryFromBeneficiaryFormValues(beneficiaryFormValues) {
  const beneficiary = mapBeneficiaryFormValuesToBeneficiaryRequest(beneficiaryFormValues);

  return merge(beneficiary, {
    account: {
      paymentRail: getRailsByBeneficiaryFormValues(beneficiaryFormValues),
    },
  });
}

export function prepareTransaction(beneficiaryFormValues, amountFormValues) {
  const type = isFxOutgoing(beneficiaryFormValues, amountFormValues) ? FX_TRANSACTION_TYPE : TRANSFER_TRANSACTION_TYPE;

  const {
    [FROM_ACCOUNT]: {
      value: { id: sourceAccountId },
    },
    [SEND_AMOUNT]: formattedAmount,
    [PURPOSE_CODE]: purposeCode,
    [PAYMENT_REFERENCE]: paymentDetails,
    [FX_SIDE]: fxSide,
  } = amountFormValues;

  const amount = toIndivisibleUnit(Number(reverseFormatNumber(formattedAmount)));

  const {
    [BENEFICIARY_CURRENCY]: { value: currency },
  } = beneficiaryFormValues;

  const transaction = {
    type,
    beneficiary: prepareBeneficiaryFromBeneficiaryFormValues(beneficiaryFormValues),
    amount,
    currency,
    purposeCode: purposeCode?.value,
    paymentDetails,
    [FX_SIDE]: fxSide,
    accountId: sourceAccountId,
  };

  return isFxOutgoing(beneficiaryFormValues, amountFormValues) ? transaction : omit(transaction, [FX_SIDE]);
}
