import styled from 'styled-components';

import {
  TransactionHighlighted as PaymentToBold,
  TransactionLabel,
  TransactionSubHeading,
} from 'components/transaction-list/styles';
import { DEVICE } from 'constants/breakpoints';
import { hex2rgba } from 'helpers/styles';

const Payment = styled.button`
  align-items: flex-start;
  background: ${props => props.isActive
  ? hex2rgba(props.theme.colors.cream, 0.3)
  : props.theme.colors.white};
  border: none;
  border-bottom: 0.06rem solid ${props => props.isActive
    ? hex2rgba(props.theme.colors.black, 0.5)
    : props.theme.colors.white};
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 100%;
  padding: 1.125rem 0;
  text-align: left;
  transition: ${props => props.theme.transition};
  width: 100%;

  &:hover {
    background: ${props => props.isActive
      ? hex2rgba(props.theme.colors.cream, 0.3)
      : hex2rgba(props.theme.colors.greyBright, 0.3)};
    border-bottom: 0.06rem solid ${props => props.isActive
        ? hex2rgba(props.theme.colors.black, 0.5)
        : hex2rgba(props.theme.colors.black, 0.1)};
  }

  @media ${DEVICE.tablet} {
    padding: 1rem 1.375rem 1rem 0.75rem;
  }
`;

const PaymentActivity = styled.div`
  max-width: 65%;
  display: flex;
  flex-direction: row;
  flex: 1;
`;

const PaymentIcon = styled.div`
  margin-top: 0.25rem;
  margin-right: 1rem;
`;

const PaymentToWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 2rem);
`;

const PaymentToDetails = styled.div`
  display: block;
`;

const PaymentToLabel = styled(TransactionLabel)`
  margin-bottom: 0.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media ${DEVICE.tablet} {
    margin-bottom: 0;
  }
`;

const PaymentSubHeading = styled(TransactionSubHeading)`
  display: ${props => {
  if (props.isHiddenOnMobile) {
    return 'none';
  } else if (props.isMobile) {
    return 'block';
  }

  return 'inline';
}};
  ${props => props.isWarning && `color: ${  props.theme.colors.orange}`};
  ${props => props.marginLeft && 'margin-left: 0.25rem'};

  @media ${DEVICE.tablet} {
    display: ${props => props.isMobile ? 'none' : 'inline'};
  }
`;

const PaymentAmount = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-shrink: 0;
  width: auto;
  padding-left: 0.625rem;
`;

const PaymentAmountTop = styled.span`
  font: ${props => props.theme.fonts.bodySBold};
  margin-bottom: 0.25rem;
`;

const PaymentAmountBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media ${DEVICE.tablet} {
    flex-direction: row;
  }
`;

const PaymentStatus = styled.div`
  display: none;

  @media ${DEVICE.tablet} {
    display: inline;
  }
`;

export default {
  Payment,
  PaymentActivity,
  PaymentIcon,
  PaymentToWrapper,
  PaymentToLabel,
  PaymentToBold,
  PaymentSubHeading,
  PaymentToDetails,
  PaymentAmount,
  PaymentAmountTop,
  PaymentAmountBottom,
  PaymentStatus,
};
