import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Tabs, Search, RadioButton } from '@general/intergiro-ui-kit';
import { useHistory, useLocation } from 'react-router';
import { Trans, useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';

import { BeneficiaryRow, EmptyState, WithNavbarHeader, FilterBarMobile } from 'components';
import DropdownFilterControl from 'pages/activity/components/dropdown-filter-control';
import { useBeneficiaries } from 'hooks';
import { addParameterToURL, getParameterFromURL, removeParameterFromURL } from 'utils/helpers';
import { navigation } from 'constants/navigation';
import SortDropdown from 'components/filter-bar/components/dropdown';
import FilterDropdown from 'components/filter-bar-mobile/components/filter-dropdown';

import S from './styles';
import {
  sortType,
  sortTypeParamsMap,
  sortTypeTranslationsMap,
  DEBOUNCE_TIME,
  defaultMetadata,
  metaTypes,
  paramsSortTypeMap,
  WRAPPER_CLASS_NAME,
  RADIO_SORT_MOBILE_NAME,
} from './constants';

function Beneficiaries() {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const [ meta, setMeta ] = useState({});
  const [ searchValue, setSearchValue ] = useState(getParameterFromURL(metaTypes.name, location) || '');
  const [ sortOrder, setSortOrder ] =
    useState(paramsSortTypeMap[getParameterFromURL(metaTypes.orderBy, location)] || sortType.name);
  const [ isMobileFiltersOpen, setMobileFiltersOpen ] = useState(false);
  const dropdownRef = useRef();
  const debounceRef = useRef();

  const beneficiariesRequestQuery = useMemo(() => {
    const nameQuery = getParameterFromURL(metaTypes.name, location);
    const orderByQuery = getParameterFromURL(metaTypes.orderBy, location) ?? defaultMetadata.orderBy;
    const limitQuery =
      getParameterFromURL(metaTypes.limit, location) ?? meta?.limit ?? defaultMetadata.limit;
    const startingAfterQuery = getParameterFromURL(metaTypes.startingAfter, location);
    const endingBeforeQuery = getParameterFromURL(metaTypes.endingBefore, location);

    return {
      ...(nameQuery && { name: nameQuery }),
      ...(startingAfterQuery && { startingAfter: startingAfterQuery }),
      ...(endingBeforeQuery && { endingBefore: endingBeforeQuery }),
      orderBy: orderByQuery,
      limit: limitQuery,
    };
  }, [ location, sortOrder, searchValue ]);

  const { isInitiallyFetched, beneficiaries, beneficiariesMeta, isFetching, fetchBeneficiaries }
    = useBeneficiaries(beneficiariesRequestQuery);

  const isEmptyMessageShown = useMemo(() => !searchValue && isInitiallyFetched && !beneficiaries.length && !isFetching,
    [ searchValue, isFetching, isInitiallyFetched, beneficiaries.length ]);
  const paginationProps = useMemo(() => {
    const paginationMeta = {
      ...beneficiariesMeta,
    };

    return {
      paginationMeta,
      location,
      history,
      cleanQueryTriggers: { searchValue, sortOrder },
      firstItemId: beneficiaries?.[0]?.id || undefined,
      lastItemId: beneficiaries?.[beneficiaries.length - 1]?.id || undefined,
    };
  }, [ location, beneficiariesMeta, history, beneficiaries ]);

  const debounceSearchValue = value => {
    clearTimeout(debounceRef.current);
    debounceRef.current = setTimeout( () => {
      setSearchValue(value);
    }, DEBOUNCE_TIME);
  };

  const handleSort = type => () => {
    addParameterToURL(metaTypes.orderBy, sortTypeParamsMap[type], history, location);
    setSortOrder(type);
    dropdownRef.current.closeDropdown();
  };

  const handleSearch = value => debounceSearchValue(value);

  const handleSearchClear = () => debounceSearchValue('');

  const toggleMobileFilter = isOpen => {
    setMobileFiltersOpen(isOpen);
    resetMobileFilters();
  };

  const resetMobileFilters = () => {
    setSortOrder(paramsSortTypeMap[getParameterFromURL(metaTypes.orderBy, location)] || sortType.date);
  };

  const handleMobileFilterApply = () => {
    addParameterToURL(metaTypes.orderBy, sortTypeParamsMap[sortOrder], history, location);
  };

  const handleMobileFilterCancel = () => {
    removeParameterFromURL(metaTypes.orderBy, history, location);
    setMobileFiltersOpen(false);
  };

  const renderBeneficiary = ( beneficiary, idx ) => {
    const isLastChild = beneficiaries.length - 2 <= idx;

    return (
      <BeneficiaryRow
        key={beneficiary.id}
        beneficiary={beneficiary}
        onDelete={fetchBeneficiaries}
        openToTop={isLastChild}
      />
    );
  };

  useEffect(() => {
    removeParameterFromURL(metaTypes.name, history, location);
    if (searchValue) {
      addParameterToURL(metaTypes.name, searchValue, history, location);
    }
  }, [searchValue]);

  useEffect(() => {
    setMeta(beneficiariesMeta);
  }, [beneficiariesMeta]);

  return (
    <>
      <WithNavbarHeader hideActivityButtons title={t('beneficiaries.title')} />
      <S.TabsWrapper>
        <Tabs.Container name="tabs">
          <S.TabsHead isActive>
            {t('beneficiaries.tabs.all')}
          </S.TabsHead>
          <S.SortBarWrapper>
            <S.ListActionsWrapper>
              <DropdownFilterControl
                ref={dropdownRef}
                label={(
                  <SortDropdown
                    defaultLabel={`${t('beneficiaries.sort.title')} ${t(sortTypeTranslationsMap[sortOrder])}`}
                    name="sort"
                  />
                )}
              >
                <S.SortWrapper>
                  <S.SortItem onClick={handleSort(sortType.name)}>
                    {t('beneficiaries.sort.name')}
                    {sortOrder === sortType.name && <S.CheckIcon />}
                  </S.SortItem>
                  <S.SortItem onClick={handleSort(sortType.date)}>
                    {t('beneficiaries.sort.date')}
                    {sortOrder === sortType.date && <S.CheckIcon />}
                  </S.SortItem>
                </S.SortWrapper>
              </DropdownFilterControl>
              <S.SearchAction>
                <Search
                  onChange={handleSearch}
                  onClear={handleSearchClear}
                  value={searchValue}
                />
              </S.SearchAction>
            </S.ListActionsWrapper>
          </S.SortBarWrapper>
        </Tabs.Container>
      </S.TabsWrapper>
      <S.MobileActionsWrapper>
        <S.FiltersWrapper onClick={() => toggleMobileFilter(true)}>
          <S.FiltersIcon />
          {t('beneficiaries.filters')}
        </S.FiltersWrapper>
        <S.SearchAction>
          <Search
            wrapperClassName={WRAPPER_CLASS_NAME}
            onChange={handleSearch}
            onClear={handleSearchClear}
            value={getParameterFromURL(metaTypes.name, location) || ''}
          />
        </S.SearchAction>
      </S.MobileActionsWrapper>
      {isInitiallyFetched && (
        <div>
          {isEmptyMessageShown ? (
            <EmptyState
              title={t('beneficiaries.emptyState.title')}
              subTitle={(
                <Trans
                  i18nKey="beneficiaries.emptyState.subTitle"
                >
                  To store a beneficiary you need to send them a
                  <S.Link to={navigation.sendFunds}>payment</S.Link>
                  first
                </Trans>
              )}
            />
          ) : (
            <div>
              {!isEmpty(beneficiaries) ? (
                <Tabs.Panel>
                  <S.Pagination {...paginationProps}>
                    <div>
                      { beneficiaries?.map(renderBeneficiary) }
                    </ div>
                  </S.Pagination>
                </Tabs.Panel>
              ) : (
                <EmptyState
                  title={t('beneficiaries.emptyState.noResults')}
                />
              )}
            </div>
          )}
        </div>
      )}
      <FilterBarMobile
        isOpen={isMobileFiltersOpen}
        closeFilterBar={() => toggleMobileFilter(false)}
        applyFilters={handleMobileFilterApply}
        cancelFilters={handleMobileFilterCancel}
        hideLabel
      >
        <FilterDropdown title={t('beneficiaries.sort.title')}>
          <S.Radio
            id={sortType.date}
            label={`${t('beneficiaries.sort.title')} ${t('beneficiaries.sort.date')}`}
            name={RADIO_SORT_MOBILE_NAME}
            checked={sortOrder === sortType.date}
            onChange={() => setSortOrder(sortType.date)}
          />
          <RadioButton
            id={sortType.name}
            label={`${t('beneficiaries.sort.title')} ${t('beneficiaries.sort.name')}`}
            name={RADIO_SORT_MOBILE_NAME}
            checked={sortOrder === sortType.name}
            onChange={() => setSortOrder(sortType.name)}
          />
        </FilterDropdown>
      </FilterBarMobile>
    </>
  );
}

export default Beneficiaries;
