import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 2.5rem auto 0;
  max-width: 47rem;
  padding: 1rem;
  text-align: center;
  width: 100%;
`;

const ImageWrapper = styled.div`
  margin-bottom: 2.5rem;
`;

const Image = styled.img`
  width: 11.25rem;
  height: 11.25rem;

  @media ${DEVICE.tablet} {
    margin-top: 2.5rem;
    width: 18.75rem;
    height: 18.75rem;
  }
`;

const Paragraph = styled.p`
  font: ${props => (props.isSmall ? props.theme.fonts.bodyS : props.theme.fonts.bodyL)};
  margin: 0 0 1rem;
`;

const Strong = styled.strong`
  font-weight: 500;
`;

export default { Paragraph, ImageWrapper, Image, Strong, Wrapper };
