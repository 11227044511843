import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { navigateToNotFoundPage, showGeneralToastError } from 'utils/errors';
import { getBeneficiary } from 'api/beneficiary';
import { GlobalLoaderContext } from 'contexts/global-loader';
import { NOT_FOUND } from 'constants/status-code';
import { resourceType } from 'constants/resource';

const useBeneficiary = id => {
  const history = useHistory();
  const { startLoading, endLoading } = useContext(GlobalLoaderContext);
  const [ beneficiary, setBeneficiary ] = useState({});

  const fetchBeneficiary = async () => {
    startLoading();

    try {
      const response = await getBeneficiary(id);

      setBeneficiary(response);
    } catch (error) {
      error.status === NOT_FOUND
        ? navigateToNotFoundPage(history, resourceType.beneficiary)
        : showGeneralToastError(error);
    } finally {
      endLoading();
    }
  };

  useEffect(async () => {
    if (!id) return;

    await fetchBeneficiary();
  }, [id]);

  return { beneficiary, fetchBeneficiary };
};

useBeneficiary.propTypes = {
  id: PropTypes.string,
  onError: PropTypes.func,
};

export default useBeneficiary;
