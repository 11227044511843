import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import { ReactComponent as ArrowCounterclockwiseIcon } from 'assets/icons/arrow-counterclockwise.svg';
import { ReactComponent as BlockIcon } from 'assets/icons/block.svg';
import { ModalContent } from 'components';

import S from './styles';

const titleActionMap = {
  block: 'Block',
  restore: 'Restore',
};

const buttonLabelActionMap = {
  block: 'Block',
  restore: 'Restore',
};

const buttonIconActionMap = {
  block: <BlockIcon />,
  restore: <ArrowCounterclockwiseIcon />,
};

function ConfirmationModal({ isOpen, setIsOpen, onSubmit, action, userFullName }) {
  const handleConfirm = useCallback(event => {
    event.stopPropagation();

    onSubmit();
  }, [onSubmit]);

  const handleCancel = useCallback(event => {
    event.stopPropagation();

    setIsOpen(false);
  }, [setIsOpen]);

  const CTAButtons = (
    <>
      <S.Button
        label={buttonLabelActionMap[action]}
        icon={buttonIconActionMap[action]}
        category="primary"
        onClick={handleConfirm}
      />
      <S.Button
        label="Cancel"
        category="secondary"
        onClick={handleCancel}
      />
    </>
  );

  return (
    <ModalContent
      title={`${titleActionMap[action]} ${userFullName}?`}
      hasCloseIcon
      isOpen={isOpen}
      CTAButtons={CTAButtons}
      setIsOpen={setIsOpen}
    />
  );
}

ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  action: PropTypes.string.isRequired,
  userFullName: PropTypes.string.isRequired,
};

export default ConfirmationModal;
