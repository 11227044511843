import React from 'react';
import { useHistory } from 'react-router';
import { Tabs } from '@general/intergiro-ui-kit';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import { navigation } from 'constants/navigation';
import { RAILS_MAP, LOCAL_RAILS } from 'pages/send-funds/constants';
import { useBeneficiary } from 'hooks';
import config from 'config';

import S from './styles';
import BeneficiaryDetails from './components/beneficiary-details';

function Beneficiary() {
  const history = useHistory();
  const { t } = useTranslation();
  const { id: beneficiaryId } = useParams();
  const { beneficiary, fetchBeneficiary } = useBeneficiary(beneficiaryId);

  const handleSend = () => {
    history.push(`${navigation.sendFunds}?beneficiaryId=${beneficiary.id}`);
  };

  // https://ftcs-tech.atlassian.net/browse/IDE-18490
  const disabledLocalCurrencies = config.featureFlags.disabledLocalCurrencies.split(',');
  const beneficiaryCurrency = beneficiary?.account?.currency;
  const beneficiaryPaymentRail = beneficiary?.account?.paymentRail;
  const disableSendButton = disabledLocalCurrencies.includes(beneficiaryCurrency)
    && RAILS_MAP[beneficiaryPaymentRail].binaryRail === LOCAL_RAILS;

  return (
    <S.Wrapper>
      <S.HeadingRow>
        <S.Title>
          {beneficiary?.name}
        </S.Title>
        <S.Button
          size="small"
          label={t('beneficiary.sendButton')}
          icon={<S.ArrowRightTopIcon />}
          disabled={disableSendButton}
          onClick={handleSend}
        />
      </S.HeadingRow>
      <Tabs.Container name="tabs">
        <S.TabsHead id="details" isActive>
          {t('beneficiary.tabs.details')}
        </S.TabsHead>
      </Tabs.Container>
      <Tabs.Panel>
        {!isEmpty(beneficiary) && <BeneficiaryDetails beneficiary={beneficiary} onTrusting={fetchBeneficiary} />}
      </Tabs.Panel>
    </S.Wrapper>
  );
}

export default Beneficiary;
