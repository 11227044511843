import styled from 'styled-components';

import { hex2rgba } from 'helpers/styles';

const Role = styled.div`
  display: grid;
  grid-template-columns: 1fr [name] 0fr [actions];
  align-items: center;
  padding: 1rem 1.25rem;
  background: ${props => props.theme.colors.white};
  box-shadow: 0 0.3125rem 1rem ${props => hex2rgba(props.theme.colors.black, 0.05)}, inset 0 0 0.0625rem ${props => hex2rgba(props.theme.colors.black, 0.3)};
  border-radius: 0.5rem;
  margin: 0.625rem 0;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0.3125rem 1rem ${props => hex2rgba(props.theme.colors.black, 0.12)}, inset 0 0 0.0625rem ${props => hex2rgba(props.theme.colors.black, 0.3)};
  }
`;

const Title = styled.p`
  font-weight: 600;
  display: inline-block;
  margin: 0;
  color: ${props => props.theme.colors.black};
`;

const Actions = styled.div`
  margin: 0;
  display: flex;
  align-items: center;
`;

const Action = styled.div`
  font: ${props => props.theme.fonts.bodyS};
  color: ${props => props.red ? props.theme.colors.red : props.theme.colors.black};
  padding: 0.625rem 1rem;
`;

export default {
  Role,
  Title,
  Actions,
  Action,
};
