import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { navigateToNotFoundPage, showGeneralToastError } from 'utils/errors';
import { getApiPayment } from 'api/api-payments';
import { GlobalLoaderContext } from 'contexts/global-loader';
import { TransactionContext } from 'contexts/transaction';
import { NOT_FOUND } from 'constants/status-code';
import { resourceType } from 'constants/resource';

const useApiPayment = id => {
  const history = useHistory();
  const { startLoading, endLoading } = useContext(GlobalLoaderContext);
  const { transactions } = useContext(TransactionContext);
  const [ apiPayment, setApiPayment ] = useState(null);
  const [ isLoading, setIsLoading ] = useState(false);

  const fetchApiPayment = async () => {
    setIsLoading(true);
    startLoading();

    try {
      const data = await getApiPayment(id);

      setApiPayment(data);
    } catch (error) {
      error.status === NOT_FOUND
        ? navigateToNotFoundPage(history, resourceType.apiPayment)
        : showGeneralToastError(error);
    } finally {
      endLoading();
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (transactions.length && !apiPayment) fetchApiPayment();
  }, [transactions]);

  return { isLoading, apiPayment };
};

export default useApiPayment;
