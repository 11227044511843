import immer from 'immer';
import PropTypes from 'prop-types';
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

function makeStore() {
  const Context = createContext();
  const useStore = () => useContext(Context);

  function Provider({ children, initialState }) {
    const [ state, preSetState ] = useState(initialState);
    const setState = useCallback(updater => preSetState(old => immer(old, draft => updater(draft))),
      [preSetState]);
    const contextValue = useMemo(() => [ state, setState ], [ state, setState ]);

    return (
      <Context.Provider value={contextValue}>
        {children}
      </Context.Provider>
    );
  }

  Provider.propTypes = {
    children: PropTypes.node,
    initialState: PropTypes.instanceOf(Object),
  };

  return {
    Provider,
    useStore,
  };
}

export default makeStore;
