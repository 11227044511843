import { ConfigManager } from '@general/config-manager';

import defaultConfig from './default';
import devConfig from './development';
import envConfig from './env-variables';
import testConfig from './test';

const configMap = {
  development: devConfig,
  test: testConfig,
};

const configManager = new ConfigManager(defaultConfig, envConfig, configMap);

export default configManager.getConfigForEnvironment(window.env.REACT_APP_ENVIRONMENT_NAME);
