import PropTypes from 'prop-types';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { ReactComponent as ThumbsDownIcon } from 'assets/icons/thumbs-down.svg';
import { ReactComponent as ThumbsUpIcon } from 'assets/icons/thumbs-up.svg';
import { TransactionAmount, TransactionApproveReject, TransactionIcon, TransactionTo } from 'components';
import { PaymentCountContext } from 'contexts/payment-count';
import { TransactionContext } from 'contexts/transaction';
import { transactionActivityShape } from 'local-prop-types';

import S from './styles';

function TransactionPendingActivity({
  createdAt,
  createdBy,
  toAccount,
  debitedAmount,
  status,
  statusReasons,
  id,
  netTransactionAmount,
  sourceCurrency,
  targetCurrency,
  isMultiCurrencyTransaction,
  type,
  approvalRequestId,
}) {
  const { setActiveTransactionById, activeTransaction } = useContext(TransactionContext);
  const { updateCount } = useContext(PaymentCountContext);
  const [ isActive, setIsActive ] = useState(false);
  const sidebarRef = useRef(null);

  useEffect(() => {
    setIsActive(Boolean(activeTransaction?.id === id));
  }, [activeTransaction]);

  const handleOpen = useCallback(event => {
    event.stopPropagation();

    const isElementInParent = sidebarRef?.current?.contains(event.target);
    const isModalOverlay = event.target?.className?.includes('overlay');
    if (isModalOverlay || !isElementInParent) {
      return;
    }

    setActiveTransactionById(id);
  }, []);

  return (
    <S.TransactionActivityRow
      isActive={isActive}
      onClick={handleOpen}
      data-testid="transaction-activity-row"
      key={id}
      ref={sidebarRef}
    >
      <S.TransactionIconWrapper data-testid="transaction-icon-wrapper">
        <TransactionIcon type={type} isActive={isActive} status={status} />
      </S.TransactionIconWrapper>

      <S.TransactionToWrapper data-testid="transaction-to-wrapper">
        <TransactionTo
          targetName={toAccount.name}
          status={status}
          statusReasons={statusReasons}
          createdAt={createdAt}
          createdBy={createdBy.name}
          showDetailsAlways
        />
      </S.TransactionToWrapper>

      <S.TransactionAmountWithControlsWrapper>
        <S.TransactionAmountWrapper>
          <TransactionAmount
            debitedAmount={debitedAmount}
            netTransactionAmount={netTransactionAmount}
            showNetAmount
            sourceCurrency={sourceCurrency}
            targetCurrency={targetCurrency}
            isMultiCurrencyTransaction={isMultiCurrencyTransaction}
            type={type}
          />
        </S.TransactionAmountWrapper>

        <S.ThumbsButtonsWrapper>
          <TransactionApproveReject
            approvalRequestId={approvalRequestId}
            createdAt={createdAt}
            createdBy={createdBy}
            toAccount={toAccount}
            debitedAmount={debitedAmount}
            netAmount={netTransactionAmount}
            targetCurrency={targetCurrency}
            approveButton={(
              <S.ThumbsButton
                data-testid="approve-transaction-button"
              >
                <ThumbsUpIcon />
              </S.ThumbsButton>
            )}
            rejectButton={(
              <S.ThumbsButton
                data-testid="reject-transaction-button"
                isDown
              >
                <ThumbsDownIcon />
              </S.ThumbsButton>
            )}
            onSuccess={() => {
              setActiveTransactionById(null);
              updateCount();
            }}
          />
        </S.ThumbsButtonsWrapper>

      </S.TransactionAmountWithControlsWrapper>
    </S.TransactionActivityRow>
  );
}

TransactionPendingActivity.propTypes = {
  ...transactionActivityShape,
  isMultiCurrencyTransaction: PropTypes.bool,
};

export default TransactionPendingActivity;
