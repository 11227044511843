import { beneficiariesRequestOrderBy } from 'constants/beneficiaries';

export const WRAPPER_CLASS_NAME = 'actions-wrapper';

export const sortType = {
  name: 'by name',
  date: 'by date',
};

export const paramsSortTypeMap = {
  [beneficiariesRequestOrderBy.name]: sortType.name,
  [beneficiariesRequestOrderBy.lastUsedAt]: sortType.date,
};

export const sortTypeParamsMap = {
  [sortType.name]: beneficiariesRequestOrderBy.name,
  [sortType.date]: beneficiariesRequestOrderBy.lastUsedAt,
};

export const sortTypeTranslationsMap = {
  [sortType.name]: 'beneficiaries.sort.name',
  [sortType.date]: 'beneficiaries.sort.date',
};

export const DEBOUNCE_TIME = 600;
export const DEFAULT_LIMIT = 10;
export const DEFAULT_ORDER_BY = beneficiariesRequestOrderBy.name;

export const defaultMetadata = {
  limit: DEFAULT_LIMIT,
  orderBy: DEFAULT_ORDER_BY,
};

export const metaTypes = {
  limit: 'meta[limit]',
  orderBy: 'order_by',
  name: 'name',
  startingAfter: 'meta[starting_after]',
  endingBefore: 'meta[ending_before]',
};

export const RADIO_SORT_MOBILE_NAME = 'mobileSortName';
