import React, { useContext, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import { showGeneralToastError } from 'utils/errors';
import { getAccounts } from 'api/accounts';
import { navigation } from 'constants/navigation';
import { GlobalLoaderContext } from 'contexts/global-loader';
import { ViewContext } from 'contexts/view';
import { WizardLayout } from 'layouts';
import {
  exchangeRateFieldName,
  excludedPaymentProcessorAdapters,
  fromAccountFieldName,
  MOVE_FUNDS_CONFIRMATION,
  MOVE_FUNDS_FINAL,
  MOVE_FUNDS_FORM,
  moveAmountFieldName,
  receiveAmountFieldName,
  toAccountFieldName,
} from 'pages/move-funds/constants';

import MoveFundsForm from './components/move-funds-form';
import MoveFundsFinal from './components/move-funds-final';
import MoveFundsConfirmation from './components/move-funds-confirmation';

function MoveFunds() {
  const history = useHistory();
  const { startLoading, endLoading } = useContext(GlobalLoaderContext);
  const { id } = useParams();
  const [ accounts, setAccounts ] = useState([]);
  const [ currentView, setCurrentView ] = useState(MOVE_FUNDS_FORM);
  const [ predefinedFromAccountId, setPredefinedFromAccountId ] = useState(id);
  const [ predefinedToAccountId, setPredefinedToAccountId ] = useState(new URLSearchParams(history.location.search).get('to'));
  const [ createdTransaction, setCreatedTransaction ] = useState();
  const [ submissionErrors, setSubmissionErrors ] = useState({});
  const [ currentPollId, setCurrentPollId ] = useState();
  const [ fxSide, setFxSide ] = useState();
  const [ layoutProps, setLayoutProps ] = useState({
    title: 'Move funds',
    displayCancelButton: true,
  });
  const [ formValues, setFormValues ] = useState({
    [fromAccountFieldName]: null,
    [toAccountFieldName]: null,
    [moveAmountFieldName]: '',
    [receiveAmountFieldName]: '',
    [exchangeRateFieldName]: {},
  });

  const updateAccounts = async () => {
    try {
      startLoading();
      const fetchedAccounts = await getAccounts();
      const filteredAccounts = fetchedAccounts.filter(account =>
        !excludedPaymentProcessorAdapters.includes(account?.paymentProcessorAdapter));

      setAccounts(filteredAccounts);

      return filteredAccounts;
    } catch (error) {
      showGeneralToastError(error);

      return null;
    } finally {
      endLoading();
    }
  };

  const triggerView = view => {
    switch (view) {
      case MOVE_FUNDS_FORM:
        return history.push(navigation.accounts);
      case MOVE_FUNDS_CONFIRMATION:
        return setCurrentView(MOVE_FUNDS_FORM);
      default:
        return {};
    }
  };

  const getPageContent = () => {
    switch (currentView) {
      case MOVE_FUNDS_FORM:
        return (
          <MoveFundsForm
            accounts={accounts}
            getAccounts={updateAccounts}
            setCurrentView={setCurrentView}
            predefinedFromAccountId={predefinedFromAccountId}
            predefinedToAccountId={predefinedToAccountId}
            setPredefinedFromAccountId={setPredefinedFromAccountId}
            setPredefinedToAccountId={setPredefinedToAccountId}
            formValues={formValues}
            submissionErrors={submissionErrors}
            setSubmissionErrors={setSubmissionErrors}
            setFormValues={setFormValues}
            currentPollId={currentPollId}
            setCurrentPollId={setCurrentPollId}
            fxSide={fxSide}
            setFxSide={setFxSide}
          />
        );
      case MOVE_FUNDS_CONFIRMATION:
        return (
          <MoveFundsConfirmation
            formValues={formValues}
            setFormValues={setFormValues}
            setCurrentView={setCurrentView}
            currentPollId={currentPollId}
            setCurrentPollId={setCurrentPollId}
            setCreatedTransaction={setCreatedTransaction}
            setSubmissionErrors={setSubmissionErrors}
            getAccounts={updateAccounts}
            fxSide={fxSide}
          />
        );
      case MOVE_FUNDS_FINAL:
        return (
          <MoveFundsFinal
            setLayoutProps={setLayoutProps}
            createdTransaction={createdTransaction}
            history={history}
            currentPollId={currentPollId}
          />
        );
      default:
        return null;
    }
  };

  const viewValues = useMemo(() => ({ currentView, triggerView }), [currentView]);

  return (
    <ViewContext.Provider value={viewValues}>
      <WizardLayout {...layoutProps}>
        {getPageContent()}
      </WizardLayout>
    </ViewContext.Provider>
  );
}

export default MoveFunds;
