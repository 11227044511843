import { array, object, string } from 'yup';

export const ApiCreationSchema = object().shape({
  name: string().trim()
    .required('createApi.errors.name.required')
    .max(255, 'createApi.errors.name.max'),

  permissions: array()
    .min(1, 'createApi.errors.permissions'),
});
