import bff from 'utils/bff';

export const downloadCSV = async params => {
  const { data: statements } = await bff.statement.downloadCSV({}, params);

  return statements;
};

export const downloadPDF = async params => {
  const { data: statements } = await bff.statement.downloadPDF({}, params);

  return statements;
};

export const downloadTransferConfirmationPDF = async id => {
  const { data: statement } = await bff.transaction.downloadTransferConfirmationPDF({}, { id });

  return statement;
};
