import { Checkbox as IntergiroCheckbox, Hint as IntergiroHint } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';
import { hex2rgba } from 'helpers/styles';

const Checkbox = styled(IntergiroCheckbox)`
  > span {
    align-items: flex-end;
    display: flex;
    flex-wrap: wrap;
  }
`;

const Hint = styled(IntergiroHint)`
  position: relative;
  margin-left: 0.3125rem;
`;

const TransactionSubHeading = styled.span`
  color: ${props => props.theme.colors.greyMedium};
  font-size: ${props => (props.isSmall ? '0.8rem' : '1.125rem')};

  ${props => (props.marginLeft ? 'margin-left: 0.25rem' : '')};
  ${props => (props.marginRight ? 'margin-right: 0.4rem' : '')};

  @media ${DEVICE.tablet} {
    font: ${props => props.theme.fonts.bodySSemi};
  }
`;

const TransactionGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TransactionGroupNameWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  max-width: 100%;
`;

const TransactionGroupAccountWrapper = styled.div`
  @media ${DEVICE.tablet} {
    max-width: 100%;
    border: none;
  }

  @media ${DEVICE.laptop} {
    margin-left: 0.75rem;
  }
`;

const Header = styled.div`
  border-bottom: 0.0625rem solid ${props => hex2rgba(props.theme.colors.black, 0.3)};
  padding: 1rem 1.25rem 1.25rem;

  @media ${DEVICE.tablet} {
    padding: 1rem 0 1.25rem;
  }

  @media ${DEVICE.laptop} {
    display: flex;
    padding-left: 1rem;
  }
`;

const Content = styled.div`
  @media ${DEVICE.laptop} {
    height: calc(100% - 12rem);
  }
`;

const TransactionSelectAllText = styled.span`
  font: ${props => props.theme.fonts.bodySSemi};
  margin-left: 0.75rem;
`;

const TransactionSelectAllHintWrapper = styled.div`
  position: relative;
  color: ${props => props.theme.colors.greyMedium};
  font: ${props => props.theme.fonts.bodyXxs};
  margin-left: auto;
  margin-top: auto;
  padding-right: 1.25rem;
  padding-left: 0;
`;

const TransactionAccountFrom = styled.span`
  color: ${props => props.theme.colors.black};
  font: ${props => props.theme.fonts.bodyMSemi};

  @media ${DEVICE.tablet} {
    font: ${props => props.theme.fonts.bodySSemi};
  }
`;

const PendingTransactionWrapper = styled.div`
  position: relative;

  @media ${DEVICE.tablet} {
    height: inherit;
  }
`;

export default {
  Checkbox,
  Hint,
  PendingTransactionWrapper,
  TransactionGroupContainer,
  TransactionGroupNameWrapper,
  TransactionGroupAccountWrapper,
  TransactionSelectAllHintWrapper,
  TransactionSelectAllText,
  Header,
  Content,
  TransactionSubHeading,
  TransactionAccountFrom,
};
