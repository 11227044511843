import { bool, string } from 'prop-types';
import React from 'react';

import { ActivityButtons } from 'components';

import S from './styles';

function WithNavbarHeader({ title, hideActivityButtons }) {
  return (
    <S.Header isActivityButtonsHidden={hideActivityButtons} data-testid="with-navbar-title-wrapper">
      <S.Title>
        {title}
      </S.Title>
      {!hideActivityButtons && <ActivityButtons />}
    </S.Header>
  );
}

WithNavbarHeader.propTypes = {
  title: string.isRequired,
  hideActivityButtons: bool,
};

export default WithNavbarHeader;
