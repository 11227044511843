import styled from 'styled-components';

import { TransactionHighlighted, TransactionLabel, TransactionSubHeading } from 'components/transaction-list/styles';

const TransactionDetailsWrapper = styled.div`
  margin: 0.0625rem 0;
  display: flex;
`;

const TransactionStatusWrapper = styled(TransactionSubHeading)`
  font-weight: 500;
  display: flex;
  align-items: center;
`;

const DetailsWrapper = styled.div`
  display: ${props => props.nextLine ? 'block' : 'inline-block'};
`;

const TransactionToLabel = styled(TransactionLabel)`
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

const TransactionHint = styled.div`
  display: flex;
  margin-left: 0.375rem;
`;

export default {
  TransactionToLabel,
  TransactionSubHeading,
  TransactionHighlighted,
  TransactionStatusWrapper,
  TransactionDetailsWrapper,
  DetailsWrapper,
  TransactionHint,
};
