import { Title as TitleComponent } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';
import { hex2rgba } from 'helpers/styles';

const CategoryTitle = styled(TitleComponent)`
  font: ${props => props.theme.fonts.h3Bold};
  margin-top: 3.125rem;
  margin-bottom: 1.875rem;

  @media ${DEVICE.laptop} {
    margin-top: 4.8125rem;
    margin-bottom: 2.3125rem;
  }
`;

const CategoryDescription = styled.div`
  width: 66.6%;
  font: ${props => props.theme.fonts.bodyS};
  color: ${props => props.theme.colors.greyMedium};
  margin-bottom: 1.5rem;
`;

const GroupTitle = styled.span`
  font: ${props => props.theme.fonts.bodyS};
`;

const Group = styled.div`
  padding-bottom: 1rem;
  margin-top: 0.6rem;
  margin-bottom: 0.6rem;
  border-bottom: solid 0.03125rem ${props => props.theme.colors.greyLight};

  @media ${DEVICE.tablet} {
    display: grid;
    grid-template-columns: 1fr [title] 2fr [permissions];
    align-items: center;

    border-bottom: none;
    padding-bottom: 0;
  }

  @media ${DEVICE.desktop} {
    display: grid;
    grid-template-columns: 1fr [title] 2fr [permissions];
    align-items: center;

    border-bottom: none;
    padding-bottom: 0;
  }
`;

// TODO: Move to UI Kit
const Button = styled.span`
  display: inline-block;
  padding: 0.3125rem 0.5rem 0.375rem 0.5rem;
  margin-top: 0.8rem;
  margin-right: 0.8rem;
  font: ${props => props.theme.fonts.bodyS};
  line-height: 1;
  color: ${props => !props.selected && props.disabled ? props.theme.colors.greyMediumDark : props.theme.colors.black};
  background: ${props => getButtonBackgroundColor(props)};
  border: 0.14rem solid ${props => getButtonBorderColor(props)};
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  cursor: ${props => props.disabled ? 'default' : 'pointer'};

  &:not(${props => !props.disabled}):hover { /* stylelint-disable-line selector-type-no-unknown */
    border: 0.14rem solid ${props => props.theme.colors.greyMedium};
  }

  svg {
    fill: ${props => getButtonIconColor(props)};
  }
`;

// Private functions

function getButtonBackgroundColor(props) {
  const { colors } = props.theme;

  switch (true) {
    case props.selected && props.disabled:
      return colors.greyBright;

    case props.selected:
      return hex2rgba(colors.green, 0.1);

    default:
      return colors.white;
  }
}

function getButtonBorderColor(props) {
  const { colors } = props.theme;

  switch (true) {
    case props.selected && !props.disabled:
      return 'transparent';

    case props.disabled && !props.selected:
      return colors.greyBright;

    default:
      return colors.greyBright;
  }
}

function getButtonIconColor(props) {
  const { colors } = props.theme;

  switch (true) {
    case props.selected && props.disabled:
      return colors.black;

    case props.selected:
      return colors.green;

    default:
      return colors.greyMediumDark;
  }
}

export default {
  CategoryTitle,
  CategoryDescription,
  GroupTitle,
  Group,
  Button,
};
