import bff from 'utils/bff';

export const getBeneficiaryRequirements = async beneficiaryDetailsQuery => {
  try {
    const { data } = await bff.beneficiary.getBeneficiaryRequirements({}, beneficiaryDetailsQuery);

    return { data, error: null };
  } catch (error) {

    return { data: null, error };
  }
};

export const validateBeneficiary = async details => {
  try {
    const { data } = await bff.beneficiary.validateBeneficiary(details);

    return { data, error: null };

  } catch (error) {
    return { data: null, error };
  }
};
