import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';

const Amount = styled.span`
  color: ${props => (props.isIncoming && !props.applyCustomFormatting
  ? props.theme.colors.green
  : props.theme.colors.black)};
  display: block;
  font: ${props => props.theme.fonts.bodySBold};

  ${props => props.applyCustomFormatting ? '' : 'text-align: right;'};
  ${props => props.applyCustomFormatting ? 'width: 100%;' : 'min-width: 5rem'};
  ${props => props.applyCustomFormatting ? 'margin-top: 0.438rem;' : ''};
  ${props => props.applyCustomFormatting ? 'margin-bottom: 1.25rem;' : ''};
`;

const SubAmount = styled(Amount)`
  color: ${props => props.theme.colors.greyMedium};
  font: ${props => props.theme.fonts.bodyS};

  font-size: ${props => props.applyCustomFormatting ? '1.125rem' : '0.875rem'};
  margin: ${props => props.applyCustomFormatting ? '0.3125rem 0 0' : '0.25rem 0 0'};
  text-align: ${props => props.applyCustomFormatting ? 'center' : 'right'};
`;

const NetAmount = styled(SubAmount)`
  white-space: nowrap;
  display: block;
  min-width: auto;
  margin-left: 0.25rem;
  margin-top: 0;
`;

const NetAmountText = styled(SubAmount)`
  white-space: nowrap;
  display: block;
  margin-top: 0;
`;

const NetAmountContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${DEVICE.tablet} {
    flex-direction: row;
  }
`;

export default {
  Amount,
  SubAmount,
  NetAmount,
  NetAmountText,
  NetAmountContainer,
};
