import PropTypes from 'prop-types';
import React from 'react';

import S from './styles';

function ControlSet({ controls }) {
  return (
    <S.Controls data-testid="header-activity-buttons">
      {controls.map(({ label, icon, onClick, isHiddenOnMobile }) => (
        <S.Button
          size="small"
          isHiddenOnMobile={isHiddenOnMobile}
          label={label}
          icon={icon}
          key={label}
          onClick={onClick}
          width="auto"
          data-intercom-target={`control-set-item-anchor-${label.toLowerCase().replace(/\s/g, '')}`}
        />
      ))}
    </S.Controls>
  );
}

ControlSet.propTypes = {
  controls: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
    onClick: PropTypes.func.isRequired,
    isHiddenOnMobile: PropTypes.bool,
  })).isRequired,
};

export default ControlSet;
