import { Button as DefaultButton, Dropdown as Select, Input as DataInput } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right-thin.svg';
import { CancelButton as Cancel, TotalBlock } from 'components';
import { DEVICE } from 'constants/breakpoints';

const Row = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto auto;
  row-gap: 0;
  justify-items: start;
  align-items: baseline;
  justify-content: space-between;
  align-content: center;
  grid-auto-flow: column;
  margin-top: ${props => props.hasTopMargin ? '2.5rem' : '0'};

  @media ${DEVICE.tablet} {
    grid-template-columns: minmax(15rem, 30rem);
    margin-top: ${props => props.hasTopMargin ? '3.75rem' : '0'};
  }

  @media ${DEVICE.laptop} {
    grid-template-rows: unset;
    grid-template-columns: minmax(15rem, 30rem) minmax(6.25rem, 1fr) minmax(15rem, 30rem);
    width: 100%;
    max-width: 66.25rem;
    margin-top: ${props => props.hasTopMargin ? '5rem' : '0'};
	}
`;

const ElementWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 1.25rem;

  @media ${DEVICE.tablet} {
    padding-bottom: 1.875rem;
  }

  @media ${DEVICE.laptop} {
		margin-bottom: 0;
    max-width: 30rem;
    padding-bottom: ${props => props.skipBottomPadding ? 0 : '2.5rem'};
	}
`;

const Dropdown = styled(Select)`
  width: 100%;

  @media ${DEVICE.laptop} {
    margin-top: ${props => props.extraMargin ? '2.5rem' : ''};
    max-width: 30rem;
	}
`;

const PurposeCodeDropdown = styled(Select)`
  width: 100%;

  span[data-testid="currency-option-amount"] {
    display: none;
  }

  .select__menu {
    span[data-testid="currency-option-amount"] {
      display: block;
    }
  }

  @media ${DEVICE.laptop} {
    max-width: 30rem;
  }
`;

const DropdownPlaceholder = styled(Select)`
  display: block;
  opacity: 1 !important;
  > div {
    > div {
      > div {
        color: ${props => props.theme.colors.black};
      }
    }
  }

  svg {
    display: none;
  }
`;

const ArrowRight = styled(ArrowRightIcon)`
  display: none;

  path {
    fill: ${props => props.theme.colors.greyLight};
  }

  @media ${DEVICE.laptop} {
    display: block;
		width: 1.9375rem;
    margin: 0 2.125rem;
	}
`;

const BeneficiaryInfo = styled.span`
  font: ${props => props.theme.fonts.bodyXs};
  color: ${props => props.theme.colors.greyMedium};
  padding-left: 1rem;
`;

const Input = styled(DataInput)`
  width: 100%;

  @media ${DEVICE.laptop} {
    max-width: 30rem;
  }
`;

const Button = styled(DefaultButton)`
  margin: 0;
  margin-bottom: 0.9375rem;
  width: 100%;

  @media ${DEVICE.tablet} {
    margin-top: 3.75rem;
    width: auto;
  }
`;

const CancelButton = styled(Cancel)`
  display: block;
  width: 100%;

  @media ${DEVICE.tablet} {
    display: none;
  }
`;

const CurrencyLabel = styled.span`
  font: ${props => props.theme.fonts.bodyS};
  color: ${props => props.theme.colors.greyMedium};
`;

const TotalRow = styled(TotalBlock)`
  width: 100%;
  height: 100%;
  margin: 0;

  @media ${DEVICE.tablet} {
		flex-direction: column;
    width: 100%;
    margin: 0;
	}

  @media ${DEVICE.laptop} {
		flex-direction: row;
    width: 100%;
    margin: 0;
	}
`;

export default {
  Row,
  ArrowRight,
  Dropdown,
  PurposeCodeDropdown,
  ElementWrapper,
  DropdownPlaceholder,
  BeneficiaryInfo,
  Input,
  Button,
  CancelButton,
  CurrencyLabel,
  TotalRow,
};
