import { Button, Tabs } from '@general/intergiro-ui-kit';
import React, { useCallback, useContext } from 'react';
import { useHistory } from 'react-router';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useRoles } from 'hooks';
import { UserContext } from 'contexts/user';
import { navigation } from 'constants/navigation';

import S from './styles';
import RolesList from './components/roles-list';

function Roles() {
  const { t } = useTranslation();
  const history = useHistory();
  const { roles, removeRoleById } = useRoles();
  const {
    permissions: { canCreateUserRole },
  } = useContext(UserContext);

  const showAddRoleButton = canCreateUserRole;

  const pushToUsers = useCallback(() => {
    history.push(navigation.users);
  }, []);

  return (
    <>
      <S.Header>
        <S.Title>
          {t('roles.title')}
        </S.Title>
        {showAddRoleButton && (
          <NavLink to={navigation.addRole}>
            <Button size="small" label={t('roles.actions.add')} icon={<S.PlusIcon />} type="primary" />
          </NavLink>
        )}
      </S.Header>
      <div>
        <Tabs.Container name="tabs">
          <Tabs.Head id="all-users" handleChange={pushToUsers}>
            {t('roles.tabs.users')}
          </Tabs.Head>
          <Tabs.Head id="all-roles" isActive>
            {t('roles.tabs.rolesAndPermissions')}
          </Tabs.Head>
        </Tabs.Container>
      </div>
      <Tabs.Panel>
        <RolesList roles={roles} onRemoveRole={removeRoleById} />
      </Tabs.Panel>
    </>
  );
}

export default Roles;
