import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { navigateToNotFoundPage, showGeneralToastError } from 'utils/errors';
import { GlobalLoaderContext } from 'contexts/global-loader';
import { NOT_FOUND } from 'constants/status-code';
import { resourceType } from 'constants/resource';
import { getCard, getCardLimits } from 'api/card';

const useCard = id => {
  const history = useHistory();
  const { startLoading, endLoading } = useContext(GlobalLoaderContext);
  const [ card, setCard ] = useState();

  useEffect(() => {
    const fetchCard = async () => {
      startLoading();
      try {
        const data = await getCard(id);

        setCard(data);
      } catch (error) {
        error.status === NOT_FOUND
          ? navigateToNotFoundPage(history, resourceType.card)
          : showGeneralToastError(error);
      } finally {
        endLoading();
      }
    };

    fetchCard();
  }, [ id, startLoading, endLoading ]);

  return { card };
};

const useCardLimits = id => {
  const { startLoading, endLoading } = useContext(GlobalLoaderContext);
  const [ limits, setLimits ] = useState({});

  useEffect(() => {
    const fetchLimits = async () => {
      startLoading();
      try {
        const data = await getCardLimits(id);
        setLimits(data);
      } catch (error) {
        showGeneralToastError(error);
      } finally {
        endLoading();
      }
    };

    fetchLimits();
  }, [ id, startLoading, endLoading ]);

  return { limits };
};

export {
  useCard,
  useCardLimits,
};
