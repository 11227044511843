import { Button } from '@general/intergiro-ui-kit';
import styled, { css } from 'styled-components';

import { ReactComponent as ChevronDownIcon } from 'assets/icons/chevron-down.svg';
import { DEVICE } from 'constants/breakpoints';
import { hex2rgba } from 'helpers/styles';

const TransactionSummary = styled.div`
  backdrop-filter: blur(1.6875rem);
  -webkit-backdrop-filter: blur(1.6875rem); /* stylelint-disable-line property-no-vendor-prefix */
  border-top: 0.0625rem solid ${props => props.theme.colors.greyLight};
  bottom: 0;
  left: 0;
  margin: 0 -3.75rem;
  padding: 1.25rem 3.75rem 1.25rem 3rem;
  position: sticky;
  transform: translateY(100%);
  transition: ${props => props.theme.transition};
  width: calc(100% + 3.75rem);
  z-index: 1;
  visibility: hidden;
  pointer-events: none;

  ${props => props.isSummaryVisible
  ? css`
      opacity: 1;
      pointer-events: all;
      transform: translateY(0);
      visibility: visible;
    `
  : 'display: none;'};

  @media ${DEVICE.tablet} {
    width: calc(100% + 6.2rem);
  } 

  @media ${DEVICE.desktop} {
    width: calc(100% + 7.5rem);
  }
`;

const TransactionSummaryContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${DEVICE.tablet} {
    padding-left: 1.5rem;
    padding-right:0.875rem;
  } 
`;

const TransactionSummaryTotal = styled.div`
  align-self: flex-start;
  display: flex;
  width: 100%;

  @media ${DEVICE.tablet} {
    align-self: flex-end;
    width: auto;
  }
`;

const TransactionTotalAccordion = styled.button`
  width: 100%;
  align-items: baseline;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;

  svg {
    transition: 0.3s all ease;
    ${props => (!props.isTotalVisible ? 'transform: rotate(180deg)' : '')};
  }

  @media ${DEVICE.tablet} {
    width: auto;
  }
`;

const TransactionTotalCount = styled.span`
  align-self: flex-end;
  color: ${props => props.theme.colors.greyMedium};
  font: ${props => props.theme.fonts.bodyXs};
  margin-right: auto;
`;

const TransactionTotalHeading = styled.p`
  font: ${props => props.theme.fonts.bodyMBold};
  line-height: 1.375rem;
  color: ${props => props.theme.colors.black};
  margin: 0 0.3125rem 0 0;
`;

const TransactionApproveButton = styled(Button)`
  background: ${props => props.theme.colors.green};
  border: 0.13rem solid ${props => props.theme.colors.green};
  color: ${props => props.theme.colors.white};
  margin-right: 0.625rem;
  margin-bottom: 1rem;
  width: 100%;

  &:enabled:hover {
    background: ${props => props.theme.colors.green};
    border: 0.13rem solid ${props => props.theme.colors.green};
    color: ${props => props.theme.colors.white};
    opacity: 0.8;
  }

  @media ${DEVICE.tablet} {
    margin-bottom: 0;
    width: auto;
  }
`;

const TransactionRejectButton = styled(Button)`
  background: ${props => props.theme.colors.white};
  border: 0.13rem solid ${props => props.theme.colors.red};
  color: ${props => props.theme.colors.red};
  width: 100%;

  &:enabled:hover {
    background: ${props => props.theme.colors.white};
    border: 0.13rem solid ${props => props.theme.colors.red};
    color: ${props => props.theme.colors.red};
    opacity: 0.8; 
  }

  @media ${DEVICE.tablet} {
    width: auto;
  }
`;

const TransactionChevronWrapper = styled.div`
  align-self: flex-start;
  min-height: 0.625rem;
  min-width: 1.125rem;
  padding: 0 0.8125rem;
`;

const TransactionSummaryRow = styled.div`
  display: flex;
  justify-content: space-between;

  ${props => props.isTotalHeading &&
    css`
      width: 100%;
      justify-content: flex-start;
    `};

  ${props => props.isTotalRow &&
    css`
      align-items: flex-start;
      flex-direction: column;
    `};

  @media ${DEVICE.tablet} {
    flex-direction: row;
    width: auto;
  }
`;

const TransactionSummaryColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: auto;

  @media ${DEVICE.tablet} {
    margin-right: unset;
  }
`;

const TransactionSummaryAmount = styled.span`
  display: block;
  font: ${props => props.theme.fonts.bodyS};
  text-align: right;
  margin-bottom: ${props => props.hasDoubleMargin ? '2.25rem' : '0.75rem'};
  min-height: 1.2rem;

  ${props =>
  props.isBold &&
    css`
      font: ${props.theme.fonts.bodyMBold};
    `};

  @media ${DEVICE.tablet} { 
    margin-bottom: ${props => props.hasDoubleMargin ? '1.25rem' : '0.75rem'};
  }
`;

const TransactionSummarySubHeading = styled.span`
  color: ${props => props.theme.colors.greyMedium};
  display: block;
  font: ${props => props.theme.fonts.bodyS};
  text-align: left;
`;

const TransactionSummaryLeftColumn = styled.div`
  margin-bottom: ${props => props.hasDoubleMargin ? '1.25rem' : '0.75rem'};
  min-width: 8rem;
`;

const TransactionSummaryMultiCurrencyContainer = styled.div`
  align-self: baseline;
  border-left: 0.0625rem solid ${props => hex2rgba(props.theme.colors.black, 0.1)};
  min-width: 1rem;

  @media ${DEVICE.tablet} { 
    margin-left: 1.25rem;
    padding-left: 1.25rem;
  }
`;

const TransactionsByCurrenciesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  > * {
    &:first-child {
      border-left: none;
    }
  }
`;

const TransactionDropdownRevealedContent = styled.div`
  max-height: 0;
  overflow: hidden;
  transition: 0.3s max-height ease;

  ${props =>
  props.isVisible &&
    css`
      max-height: 5rem;
    `}
`;

const TransactionButtonContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${DEVICE.tablet} {
    flex-direction: row;
  }
`;

const ChevronDown = styled(ChevronDownIcon)`
  fill: ${props => props.theme.colors.greyMedium};
`;

export default {
  TransactionSummary,
  TransactionSummaryContainer,
  TransactionSummaryTotal,
  TransactionTotalAccordion,
  TransactionTotalHeading,
  TransactionApproveButton,
  TransactionRejectButton,
  TransactionTotalCount,
  TransactionSummaryRow,
  TransactionSummaryColumn,
  TransactionChevronWrapper,
  TransactionSummaryAmount,
  TransactionSummarySubHeading,
  TransactionSummaryLeftColumn,
  TransactionSummaryMultiCurrencyContainer,
  TransactionDropdownRevealedContent,
  TransactionsByCurrenciesContainer,
  TransactionButtonContainer,
  ChevronDown,
};
