import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';
import { hex2rgba } from 'helpers/styles';

const TransactionActivityRow = styled.div`
  align-items: center;
  border: none;
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: space-between;
  margin: 1.125rem auto;
  max-width: 100%;
  text-align: left;
  width: 100%;
  transition: ${props => props.theme.transition};
  z-index: 1;
  
  @media ${DEVICE.tablet} {
    margin: 0;
    padding: 1.125rem 1.25rem 1.125rem 0.75rem;
  }
`;

const TransactionToWrapper = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  display: block;
  margin-right: auto;
  text-align: left;
  width: 50%;
`;

const ThumbsButton = styled.a`
  align-items: center;
  border-radius: 0.25rem;
  border: none;
  cursor: pointer;
  background: ${props => hex2rgba(props.isDown ? props.theme.colors.red : props.theme.colors.green, 0.18)};
  display: flex;
  height: 1.75rem;
  justify-content: center;
  width: 1.75rem;
  z-index: 1;

  svg {
    fill: ${props => (props.isDown ? props.theme.colors.red : props.theme.colors.green)};
  }

  &:hover {
    opacity: 0.8;
  }
`;

const TransactionIconWrapper = styled.div`
  margin-top: 0.25rem;
  margin-right: 0.625rem;

  @media ${DEVICE.tablet} {
    margin-right: 1rem;
  }
`;

const TransactionAmountWrapper = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

const ThumbsButtonsWrapper = styled.div`
  align-items: center;
  display: none;
  grid-template-columns: repeat(2, auto);
  grid-gap: 0.9375rem;
  margin-top: 0.625rem;
  padding: 0 0.9375rem;
  z-index: 1;

  @media ${DEVICE.tablet} {
    display: grid;
    flex-direction: row;
    padding: 0 0 0 0.9375rem;
    margin-top: 0.4rem;
  }
`;

const TransactionAmountWithControlsWrapper = styled.div`
  align-items: flex-start;
  align-self: baseline;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${DEVICE.tablet} {
    flex-direction: row;
  }
`;

export default {
  TransactionIconWrapper,
  ThumbsButton,
  ThumbsButtonsWrapper,
  TransactionActivityRow,
  TransactionAmountWithControlsWrapper,
  TransactionAmountWrapper,
  TransactionToWrapper,
};
