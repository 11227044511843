export const loginTypes = {
  person: 'person',
};

export const ONBOARDING_STATUSES = {
  active: 'active',
  pendingOnboarding: 'pending_onboarding',
};

export const redirectActions = {
  passwordChange: 'password-change',
};
