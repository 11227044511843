import PropTypes from 'prop-types';
import React, { useCallback, useContext } from 'react';

import { GlobalLoaderContext } from 'contexts/global-loader';
import { showGeneralToastError } from 'utils/errors';
import { updateUser } from 'api/users';
import { SwitchBlock } from 'components';

import S from './styles';

function PaymentApprovalRule({ user, setUser }) {
  const { startLoading, endLoading } = useContext(GlobalLoaderContext);

  const handleToggle = useCallback(async () => {
    startLoading();

    try {
      const data = { isPaymentApprovalEnabled: !user.isPaymentApprovalEnabled };
      const updatedUser = await updateUser(user.id, data);

      setUser(updatedUser);
    } catch (error) {
      showGeneralToastError(error);
    } finally {
      endLoading();
    }
  }, [ user.isPaymentApprovalEnabled, user.id, startLoading, endLoading, setUser ]);

  return (
    <>
      <S.Title>Payment approval</S.Title>
      <S.Container>
        <S.Description>
          Where a user has this feature applied to their profile, any payments they initiate
        </S.Description>
        <S.Description>will need to be approved by a user with ‘payment approval’ permissions.</S.Description>
        <S.Section>
          <SwitchBlock
            title="Set an approval rule for this user"
            text="Only apply to outgoing transfers without currency conversion"
            checked={user.isPaymentApprovalEnabled}
            onChange={handleToggle}
          />
        </S.Section>
      </S.Container>
    </>
  );
}

PaymentApprovalRule.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    isPaymentApprovalEnabled: PropTypes.bool.isRequired,
  }).isRequired,
  setUser: PropTypes.func.isRequired,
};

export default PaymentApprovalRule;
