export const resourceType = {
  account: 'account',
  beneficiary: 'beneficiary',
  card: 'card',
  cardOrder: 'cardOrder',
  merchant: 'merchant',
  user: 'user',
  role: 'role',
  apiPayment: 'apiPayment',
  apiKey: 'apiKey',
};
