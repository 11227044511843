import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { showGeneralToastError } from 'utils/errors';
import { ModalContent } from 'components';
import { updateUser } from 'api/users';

import validationSchema from './schema';
import { EDIT_USER_DETAILS_FORM } from './constants';
import S from './styles';

function EditUserDetailsModal({ isOpen, setIsOpen, user, setUser }) {
  const { t } = useTranslation();
  const onSubmit = async values => {
    const data = {
      otherPhone: values.otherPhone,
    };

    try {
      const updatedUser = await updateUser(user.id, data);

      setUser(updatedUser);
      setIsOpen(false);
    } catch (error) {
      showGeneralToastError(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      otherPhone: user?.otherPhone,
    },
    onSubmit,
    validationSchema,
    enableReinitialize: true,
  });

  const {
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    setErrors,
    values,
    touched,
    errors,
    isSubmitting,
  } = formik;

  function handleBlur(fieldName) {
    return () => setFieldTouched(fieldName, true);
  }

  const handleOtherPhoneChange = useCallback((name, value) => {
    setFieldValue(name, value, true);
    setErrors({});
  }, [ setFieldValue, setErrors ]);

  const handleCancel = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const CTAButtons = (
    <>
      <S.Button label={t('user.edit.submitBtn')} category="primary" type="submit" disabled={isSubmitting} />
      <S.Button label={t('user.edit.cancelBtn')} category="secondary" onClick={handleCancel} />
    </>
  );

  return (
    <ModalContent
      title={t('user.edit.title')}
      hasCloseIcon
      isOpen={isOpen}
      CTAButtons={CTAButtons}
      setIsOpen={setIsOpen}
      formName={EDIT_USER_DETAILS_FORM}
      onSubmit={handleSubmit}
      unsetOverflow
    >
      <S.PhoneInput
        name="otherPhone"
        label={t('user.edit.label')}
        value={values.otherPhone}
        error={touched.otherPhone && t(errors.otherPhone)}
        disabled={isSubmitting}
        onChange={handleOtherPhoneChange}
        onBlur={handleBlur('otherPhone')}
      />
    </ModalContent>
  );
}

EditUserDetailsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  user: PropTypes.shape.isRequired,
  setUser: PropTypes.func.isRequired,
};

export default EditUserDetailsModal;
