import jwtDecode from 'jwt-decode';

import { loginTypes } from 'constants/auth';
import { getToken } from 'utils/storage';

export const getTokenPayload = token => parseToken(token);

export const isTokenValid = token => {
  const tokenPayload = parseToken(token);

  if (!tokenPayload) return false;

  const currentDate = new Date().getTime();

  return tokenPayload.exp * 1000 > currentDate;
};

export const isTokenPermitted = token => {
  const tokenPayload = parseToken(token);

  if (!tokenPayload) return false;

  return tokenPayload.login.type === loginTypes.person;
};

export const isSessionValid = () => isTokenPermitted(getToken());

// Private functions

function parseToken(token) {
  try {
    return token ? jwtDecode(token) : null;
  } catch (err) {
    return null;
  }
}
