import PropTypes from 'prop-types';
import React, { memo } from 'react';

import { navigation } from 'constants/navigation';

import S from './styles';

function MerchantList({ merchants }) {
  return (
    <S.Merchants>
      { merchants.map(({ name, id }) => (
        <S.Merchant to={`${navigation.merchants}/${id}`} key={id}>
          <S.Inner>
            <S.Avatar
              name={name}
              small
            />
            <S.Name>
              {name}
            </S.Name>
          </S.Inner>
        </S.Merchant>
        )) }
    </S.Merchants>
  );
}

MerchantList.propTypes = {
  merchants: PropTypes.instanceOf(Object),
};

export default memo(MerchantList);
