import PropTypes from 'prop-types';
import React from 'react';

import Numbers from 'assets/images/numbers.svg';

import S from './styles';

function EmptyState({ title, subTitle, testId }) {
  return (
    <S.Wrapper data-testid={testId}>
      <S.ImageWrapper>
        <S.Image alt="Empty state" src={Numbers} />
      </S.ImageWrapper>
      <S.Paragraph>
        <S.Strong>
          {title}
        </S.Strong>
      </S.Paragraph>
      <S.Paragraph isSmall>
        {subTitle}
      </S.Paragraph>
    </S.Wrapper>
  );
}

EmptyState.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.node,
  testId: PropTypes.string,
};
export default EmptyState;
