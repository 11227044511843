import 'react-toastify/dist/ReactToastify.css';

import React, { Fragment, useState } from 'react';
import IdleTimer from 'react-idle-timer';

import { signOut } from 'utils/auth';
import { redirectToLogin } from 'utils/location';
import config from 'config';

import { SESSION_TIMEOUT } from './constants';
import TimeoutModal from './components/timeout-modal';

function InactivityTimeout() {
  const inactivityTimeout = config.inactivityTimeout - SESSION_TIMEOUT;
  const [ isOpen, setIsOpen ] = useState(false);

  const onSessionContinue = () => {
    setIsOpen(false);
  };

  const onSessionStop = () => {
    setIsOpen(false);
    signOut()
      .finally(redirectToLogin);
  };

  const handleOnIdle = () => {
    // Close the session immediately if current tab is inactive.
    if (document.hidden) {
      onSessionStop();

      return;
    }

    setIsOpen(true);
  };

  return (
    <>
      <IdleTimer
        crossTab
        element={document}
        onIdle={handleOnIdle}
        debounce={500}
        timeout={1000 * 60 * inactivityTimeout}
      />
      {isOpen && (
        <TimeoutModal
          sessionTimeout={SESSION_TIMEOUT}
          onSessionContinue={onSessionContinue}
          onSessionStop={onSessionStop}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      )}
    </>
  );
}

export default InactivityTimeout;
