import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';

const TransactionsWrapper = styled.div`
  transition: ${props => props.theme.transition};
  width: 100%;

  @media ${DEVICE.laptop} {
    ${props => (props.isSidebarOpen ? 'width: calc(100% - 23rem)' : '')};
  }
`;

export default {
  TransactionsWrapper,
};
