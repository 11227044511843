import get from 'lodash/get';
import { useCallback, useContext, useEffect, useRef } from 'react';
import { useIntercom } from 'react-use-intercom';

import { UserContext } from 'contexts/user';
import config from 'config';

const INTERCOM_CUSTOMER_PORTAL_USER_INTERFACE = 'customer_portal_and_apps';

function useChatbot() {
  const { user, tokenPayload } = useContext(UserContext);
  const isIntercomMounted = useRef(false);
  const { boot, shutdown: shutdownIntercom } = useIntercom();

  function getHubSpotWidget() {
    return new Promise(resolve => {
      function onConversationsAPIReady() {
        resolve(window.HubSpotConversations.widget);
      }

      window.HubSpotConversations
        ? onConversationsAPIReady()
        : window.hsConversationsOnReady = [onConversationsAPIReady];
    });
  }

  function initializeHubSpot() {
    const hubSpotCode = config.hubspotTrackingCode;

    if (!hubSpotCode) return;

    const script = document.createElement('script');

    script.src = `//js.hs-scripts.com/${hubSpotCode}.js`;
    script.async = true;
    script.id = 'hs-script-loader';

    document.body.appendChild(script);

    // Setup hubSpot chat.
    window.hsConversationsSettings = {
      loadImmediately: false,
      disableAttachment: true,
    };
  }

  const initializeIntercom = useCallback(() => {
    if (isIntercomMounted.current) return null;

    const intercomUser = {
      userId: get(user, 'id'),
      email: get(user, 'email'),
      name: get(user, 'firstName'),
      userHash: get(user, 'intercomUserHash'),
      customAttributes: {
        user_interface: INTERCOM_CUSTOMER_PORTAL_USER_INTERFACE,
      },
      companies: [
        {
          companyId: get(tokenPayload, 'claims.corporationId'),
          name: get(user, 'corporationName') || '',
        },
      ],
    };

    isIntercomMounted.current = true;

    return boot(intercomUser);
  }, [ user, boot, tokenPayload ]);

  const loadHubSpot = useCallback(() => {
    getHubSpotWidget().then(widget => widget.load());
  }, []);

  const removeHubSpot = useCallback(() => {
    getHubSpotWidget().then(widget => widget.remove());
  }, []);

  useEffect(() => {
    initializeHubSpot();

    return shutdownIntercom;
  }, [shutdownIntercom]);

  useEffect(() => {
    if (!tokenPayload) {
      shutdownIntercom();
      loadHubSpot();
    }
  }, [ tokenPayload, shutdownIntercom, loadHubSpot ]);

  useEffect(() => {
    if (user) {
      initializeIntercom();
      removeHubSpot();
    }
  }, [ user, removeHubSpot, initializeIntercom ]);
}

export default useChatbot;
