import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';

const InfoBlock = styled.div`
  padding: 1rem 1rem 1rem 0.5rem;
  border: 0.0625rem solid ${props => props.theme.colors.greyBright};
  border-radius: 0.375rem;

  @media ${DEVICE.laptop} {
    margin-top: 0;
  }
`;

const List = styled.ul`
  margin: 0;
`;

const InfoText = styled.li`
  margin: 0;
  font: ${props => props.theme.fonts.bodyXs};
  margin-bottom: 0.5rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

export default {
  InfoBlock,
  List,
  InfoText,
};
