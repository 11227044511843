import { Button as ButtonComponent, Title as TitleComponent } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';

const Button = styled(ButtonComponent)`
	width: 100%;
	
	@media ${DEVICE.tablet} {
		width: 12.4375rem;
	}
`;

const ControlWrapper = styled.div`
	margin-top: 1.875rem;
`;

const Form = styled.form`
	margin-top: 2.5rem;
	max-width: 30rem;

	> * {
		margin-bottom: 0.625rem;
	}
`;

const Title = styled(TitleComponent)`
  font: ${props => props.theme.fonts.h3Bold};
  line-height: 1.2;
  margin-bottom: 1.875rem;
	
	@media ${DEVICE.tablet} {
      font: ${props => props.theme.fonts.h1Bold};
	}
`;

const Wrapper = styled.div`
	max-width: 43rem;
`;

export default {
  Button,
  ControlWrapper,
  Form,
  Title,
  Wrapper,
};
