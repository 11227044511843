import PropTypes from 'prop-types';
import React, {
  createContext,
  useContext,
  useMemo,
  useEffect,
  useRef,
  useState,
} from 'react';

import { showGeneralToastError } from 'utils/errors';
import { getPaymentsCount } from 'api/payment';
import { UserContext } from 'contexts/user';
import { setIntervalImmediately } from 'utils/misc';

export const PaymentCountContext = createContext({});

const delay = 10;

function PaymentCountProvider({ children }) {
  const [ count, setCount ] = useState({});
  const [ isLoading, setIsLoading ] = useState(false);
  const timeoutId = useRef(null);

  const {
    permissions: { canApproveTransaction },
  } = useContext(UserContext);

  async function getCount() {
    try {
      const updatedCount = await getPaymentsCount();
      setCount(updatedCount);
    } catch (error) {
      showGeneralToastError(error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (!canApproveTransaction) {
      return null;
    }

    timeoutId.current = setIntervalImmediately(getCount, delay * 1000);

    return () => {
      clearInterval(timeoutId.current);
    };
  }, []);

  useEffect(() => {
    if (!isLoading || !canApproveTransaction) {
      return;
    }

    clearInterval(timeoutId.current);
    timeoutId.current = setIntervalImmediately(getCount, delay * 1000);
  }, [isLoading]);

  function updateCount() {
    setIsLoading(true);
  }

  const initialValues = useMemo(() => ({
    isLoading,
    count,
    updateCount,
  }), [ isLoading, count ]);

  return (
    <PaymentCountContext.Provider
      value={initialValues}
    >
      {children}
    </PaymentCountContext.Provider>
  );
}

PaymentCountProvider.propTypes = {
  children: PropTypes.node,
};

export default PaymentCountProvider;
