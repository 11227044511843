import React, { useContext } from 'react';

import { CardContext } from 'contexts/card';
import { useCardLimits } from 'hooks';
import { prettifyAmount } from 'utils/amount';

import S from './styles';
import CardLimitItem from '../limit-item';

function CardLimits() {
  const { card: { id, currency } } = useContext(CardContext);
  const { limits = {} } = useCardLimits(id);

  const spentAmount = limits.spend;
  const withdrawnAmount = limits.withdrawal;

  const getLimitValue = value => value ?? 0;

  return (
    <S.Main>
      <S.Column>
        <S.Title data-testid="card-limits-title">Card spend</S.Title>
        <CardLimitItem
          name="Today"
          currency={currency}
          max={getLimitValue(spentAmount?.dailyLimitAmount)}
          remaining={getLimitValue(spentAmount?.remainingDailyLimitAmount)}
          data-testid="card-limits-spent-today"
        />
        <CardLimitItem
          name="This month"
          currency={currency}
          max={getLimitValue(spentAmount?.monthlyLimitAmount)}
          remaining={getLimitValue(spentAmount?.remainingMonthlyLimitAmount)}
        />
        <S.SinglePayment>
          <S.Subtitle>Single payment:</S.Subtitle>
          {' '}
          {' '}
          {prettifyAmount(currency, getLimitValue(spentAmount?.maxTransactionAmount))}
        </S.SinglePayment>
      </S.Column>

      <S.Column>
        <S.Title>ATM withdrawals</S.Title>
        <CardLimitItem
          name="Today"
          currency={currency}
          max={getLimitValue(withdrawnAmount?.dailyLimitAmount)}
          remaining={getLimitValue(withdrawnAmount?.remainingDailyLimitAmount)}
        />
        <CardLimitItem
          name="This month"
          currency={currency}
          max={getLimitValue(withdrawnAmount?.monthlyLimitAmount)}
          remaining={getLimitValue(withdrawnAmount?.remainingMonthlyLimitAmount)}
        />
        <S.SinglePayment>
          <S.Subtitle>Single withdrawal:</S.Subtitle>
          {' '}
          {' '}
          {prettifyAmount(currency, getLimitValue(withdrawnAmount?.maxTransactionAmount))}
        </S.SinglePayment>
      </S.Column>
    </S.Main>
  );
}

export default CardLimits;
