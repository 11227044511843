import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { navigation } from 'constants/navigation';
import AccountsProvider from 'contexts/accounts';
import FiltersProvider from 'contexts/filters';
import TransactionProvider from 'contexts/transaction';
import { ViewContext } from 'contexts/view';
import { WizardLayout } from 'layouts';

import PaymentList from './components/payment-list';

function APIPaymentsList() {
  const { t } = useTranslation();
  const history = useHistory();

  const redirectToPayments = () => {
    history.push(navigation.apiPayments);
  };

  const initialValues = useMemo(() => ({ triggerView: redirectToPayments }), [redirectToPayments]);

  return (
    <ViewContext.Provider value={initialValues}>
      <AccountsProvider>
        <TransactionProvider>
          <FiltersProvider>
            <WizardLayout
              title={t('payments.apiPayments.title')}
              displayCancelButton={false}
              isFullscreen
            >
              <PaymentList />
            </WizardLayout>
          </FiltersProvider>
        </TransactionProvider>
      </AccountsProvider>
    </ViewContext.Provider>
  );
}

export default APIPaymentsList;
