import PropTypes from 'prop-types';
import React from 'react';

import S from './styles';

function LastFour({ lastFour, className }) {

  return (
    <S.LastFour className={className}>
      ••
      {' '}
      {lastFour}
    </S.LastFour>
  );
}

LastFour.propTypes = {
  lastFour: PropTypes.string,
  className: PropTypes.string,
};

export default LastFour;
