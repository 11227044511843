import PropTypes from 'prop-types';
import React from 'react';

import { INVITE_USER_FORM } from 'pages/invite-user/constants';
import { navigation } from 'constants/navigation';

import S from './styles';

function InviteUserFinal({ user, userId, history, setCurrentView }) {
  const cameFromUserDetails = Boolean(userId);

  const backToUsers = () => {
    history.push(navigation.users);
  };

  const toInviteUser = () => {
    setCurrentView(INVITE_USER_FORM);
  };

  const backToUserDetails = () => {
    history.push(`${navigation.users}/${userId}`);
  };

  return (
    <div>
      <S.Icon />
      <S.Text>
        {`Your invite was succesfully sent to ${user.email}`}
      </S.Text>
      <S.ButtonContainer>
        {cameFromUserDetails
          ? <S.Button back onClick={backToUserDetails} label="Back to user details" category="secondary" />
          : (
            <>
              <S.Button back onClick={backToUsers} label="Back to users" category="secondary" />
              <S.Button onClick={toInviteUser} label="Invite another user" icon={<S.PlusIcon />} category="primary" />
            </>
            )}
      </S.ButtonContainer>
    </div>
  );
}

InviteUserFinal.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string.isRequired,
  }).isRequired,
  userId: PropTypes.string,
  history: PropTypes.shape.isRequired,
  setCurrentView: PropTypes.func.isRequired,
};

export default InviteUserFinal;
