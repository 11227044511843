import { ErrorCodes } from '@general/error-codes';
import { t } from 'i18next';
import every from 'lodash/every';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import isNull from 'lodash/isNull';
import isObject from 'lodash/isObject';
import snakeCase from 'lodash/snakeCase';
import some from 'lodash/some';
import truncate from 'lodash/truncate';
import uniq from 'lodash/uniq';
import upperCase from 'lodash/upperCase';
import { transliterate } from 'transliteration';

import {
  ACCOUNT_NUMBER_FIELD_NAMES,
  ADDITIONAL_FIELDS_NAMES_DEFAULTS_MAP,
  ADDITIONAL_REQUIRED_FIELDS,
  BANK_CODE_FIELD_NAMES,
  BENEFICIARY_BANK_ACCOUNT_COUNTRY,
  BENEFICIARY_BIC,
  BENEFICIARY_COUNTRY,
  BENEFICIARY_CURRENCY,
  BENEFICIARY_FIRST_NAME,
  BENEFICIARY_IBAN,
  BENEFICIARY_LAST_NAME,
  BENEFICIARY_NAME,
  BENEFICIARY_PATHS_MAP,
  BENEFICIARY_TYPE,
  BONDED_FIELDS,
  EXCLUDED_COUNTRIES,
  FORM_FIELDS_TO_BENEFICIARY_REQUEST_MAP,
  FROM_ACCOUNT,
  FX_RATE,
  FX_SIDE,
  LOCAL_RAILS,
  PURPOSE_CODE,
  RAILS_FIELD_NAME,
  RECEIVE_AMOUNT,
  REQUIRED_FIELDS_NAMES_DEFAULTS_MAP,
  SEND_AMOUNT,
  TOTAL_FEE_AMOUNT,
} from 'pages/send-funds/constants';
import createAmountValidationSchema from 'pages/send-funds/schemas/amount';
import { adapters } from 'constants/payment-processor';
import { feeReasonsMap, feeTypes, paymentRails, sepaThresholdsReachedText } from 'constants/fee';
import { CURRENCIES, CURRENCY_NAMES } from 'constants/currencies';
import getFee from 'api/fee';
import { getAmount, reverseFormatNumber, toIndivisibleUnit, toMainUnit } from 'utils/amount';
import { countries } from 'utils/countries';
import { errorsConsistOneOfErrorCodes, getUiErrorMessage } from 'utils/errors';
import { formatIbanIsValid, trimAllSpaces } from 'utils/format';
import {
  isFxOutgoing,
  getExchangeRate,
  shouldShowRate,
  getRailByCurrency,
  getRailsByBeneficiaryFormValues,
  getBeneficiaryFieldRequirementsByPath,
} from 'pages/send-funds/utils/fx';

export const beneficiariesFieldsLabelMap = Object.keys(BENEFICIARY_PATHS_MAP).reduce((resultObject, path) => ({
  ...resultObject,
  [BENEFICIARY_PATHS_MAP[path]?.fieldName]: BENEFICIARY_PATHS_MAP[path]?.label,
}),
{});

export function getFieldRegexFromBeneficiaryDetails(fieldName, beneficiaryDetailsResponse) {
  const fieldRequirements = getBeneficiaryFieldRequirementsByPath(beneficiaryDetailsResponse,
    FORM_FIELDS_TO_BENEFICIARY_REQUEST_MAP[fieldName]);

  return fieldRequirements?.regex;
}

export function isBeneficiaryDetailsQueryReady(beneficiaryDetailsQuery = {}) {
  return !Object.values(beneficiaryDetailsQuery).some(isNil);
}

export const getAdditionalRequiredFields = isNotLocalEurSend => (isNotLocalEurSend ? ADDITIONAL_REQUIRED_FIELDS : []);

export const checkIfEurLocalRail = (updatedCurrency, selectedRails) =>
  updatedCurrency && !(updatedCurrency === CURRENCIES.EUR && selectedRails === LOCAL_RAILS);

export const getBeneficiaryDetailsQuery = (isNotLocalEurSend, values, updatedCurrency) => isNotLocalEurSend
  ? {
      rail: getRailsByBeneficiaryFormValues(values),
      currency: updatedCurrency,
      entityType: values[BENEFICIARY_TYPE]?.value,
      beneficiaryCountry: values[BENEFICIARY_COUNTRY]?.value,
      accountCountry: values[BENEFICIARY_BANK_ACCOUNT_COUNTRY]?.value,
    }
  : {
      rail: getRailsByBeneficiaryFormValues(values),
      currency: updatedCurrency,
    };

export const customerHasSparkAccounts = accounts =>
  accounts.some(account => account.paymentProcessorAdapter === adapters.spark);

export function clearAdditionalBeneficiaryFields(setFieldValue, setFieldTouched) {
  Object.keys(ADDITIONAL_FIELDS_NAMES_DEFAULTS_MAP).map(fieldName => {
    setFieldValue(fieldName, ADDITIONAL_FIELDS_NAMES_DEFAULTS_MAP[fieldName], false);
    setFieldTouched(fieldName, false);

    return null;
  });
}

export function clearRequiredBeneficiaryFields(setFieldValue, setFieldTouched) {
  Object.keys(REQUIRED_FIELDS_NAMES_DEFAULTS_MAP).map(fieldName => {
    const defaultValue = REQUIRED_FIELDS_NAMES_DEFAULTS_MAP[fieldName];

    setFieldValue(fieldName, defaultValue);
    setFieldTouched(fieldName, false);

    return null;
  });
}

export const wasCurrencyChanged = (selectedCurrencyOption, values) =>
  selectedCurrencyOption?.value !== values[BENEFICIARY_CURRENCY].value;

const getSortValues = (beneficiaryPathsMap, type, name) => (type ? beneficiaryPathsMap[`${name}.${type}`]?.sort : beneficiaryPathsMap[name]?.sort) || 0;

export function sortBeneficiaryDetails(beneficiaryDetailsResponse, beneficiaryPathsMap) {
  return beneficiaryDetailsResponse
    ?.sort(({ name: nameLeft, type: typeLeft }, { name: nameRight, type: typeRight }) => {
      const leftSort = getSortValues(beneficiaryPathsMap, typeLeft, nameLeft);
      const rightSort = getSortValues(beneficiaryPathsMap, typeRight, nameRight);

      return leftSort - rightSort;
    });
}

function isLocalEurTransfer(beneficiaryFormValues) {
  const beneficiaryCurrency = beneficiaryFormValues[BENEFICIARY_CURRENCY].value;
  const rails = beneficiaryFormValues[RAILS_FIELD_NAME];

  return beneficiaryCurrency === CURRENCIES.EUR && rails === LOCAL_RAILS;
}

export function getAccountNumber(beneficiaryFormValues = {}) {
  const accountNumberFieldName = ACCOUNT_NUMBER_FIELD_NAMES.find(fieldName => beneficiaryFormValues[fieldName]);

  return {
    label: beneficiariesFieldsLabelMap[accountNumberFieldName],
    ...beneficiaryFormValues[accountNumberFieldName]?.value,
  };
}

export function isTransferInsideCompany(accounts, beneficiaryFormValues) {
  const accountNumber = trimAllSpaces(getAccountNumber(beneficiaryFormValues)?.value);

  const accountRequisites = accounts.reduce((acc, account) =>
    [ ...acc, { number: account.requisites.number, currency: account.currency }], []);

  const accountRequisitesNumbers = uniq(accountRequisites.reduce((acc, { number, currency }) => {
    if (number === beneficiaryFormValues?.beneficiaryIban?.label
      && currency === beneficiaryFormValues?.currency?.value) {
      return [ ...acc, number ];
    }

    return acc;
  }, []));

  return accountNumber && accountRequisitesNumbers.includes(accountNumber);
}

export function shouldShowNotSepaNotification(values, isSepaEnabled) {
  const ibanValue = values[BENEFICIARY_IBAN];

  return every([ ibanValue, isLocalEurTransfer(values), !isSepaEnabled, !isNull(isSepaEnabled) ]);
}

export function updateRate(beneficiaryFormValues, amountFormValues, setAmountFormValues) {
  const selectedFxSide = get(amountFormValues, FX_SIDE);

  return getExchangeRate(beneficiaryFormValues, amountFormValues, selectedFxSide).then(fetchedRate => {
    setAmountFormValues({
      ...amountFormValues,
      [SEND_AMOUNT]: fetchedRate.sellAmount,
      [RECEIVE_AMOUNT]: fetchedRate.buyAmount,
      [FX_RATE]: fetchedRate,
    });

    return fetchedRate;
  });
}

const mapBondedFieldErrors = (fieldName, errorMessage) => {
  const triggeredFields = BONDED_FIELDS[fieldName];
  if (!triggeredFields) {
    return null;
  }

  const mappedMessages = Object.entries(triggeredFields).reduce((acc, [ field, value ]) => {
    const errObj = acc;
    errObj[field] = value ? errorMessage : true;

    return errObj;
  }, {});

  return mappedMessages;
};

/**
 * @param {ApiError} error
 * @param {function}setApiValidationErrors
 * @param beneficiaryFormValues
 */
export function handleApiValidationErrors(error, setApiValidationErrors, beneficiaryFormValues) {
  const resultErrors = Object.keys(FORM_FIELDS_TO_BENEFICIARY_REQUEST_MAP)
    .filter(fieldName => !isNil(beneficiaryFormValues[fieldName]))
    .reduce((acc, fieldName) => {
      const rawErrorMessage = getFieldErrorMessage(error, fieldName);
      const errorMessage = getUiErrorMessage(rawErrorMessage, rawErrorMessage);
      const bondedFieldErrors = mapBondedFieldErrors(fieldName, errorMessage);

      if (!errorMessage) {
        return acc;
      }

      return bondedFieldErrors ? { ...acc, ...bondedFieldErrors } : { ...acc, [fieldName]: errorMessage };

    }, {});
  setApiValidationErrors(resultErrors);
}

export function canUseAccountForFxOutgoing(account, beneficiaryFormValues) {
  const {
    [RAILS_FIELD_NAME]: selectedRails,
    [BENEFICIARY_CURRENCY]: { value: selectedCurrency },
  } = beneficiaryFormValues;
  const rail = getRailByCurrency(selectedRails, selectedCurrency);

  return (
    rail.currencies.includes(account.currency)
    && rail.paymentProcessorAdapters.includes(account.paymentProcessorAdapter)
  );
}

export const fromAccountsFilter = (account, beneficiaryFormValues) => {
  const canUseAccount = canUseAccountForFxOutgoing(account, beneficiaryFormValues);

  return account.currency === beneficiaryFormValues[BENEFICIARY_CURRENCY].value && canUseAccount;
};

export const formOption = (value, label) => ({
  label: label || value,
  value: isObject(value) ? value : { value },
});

export function updateSelectedAccountIfRequired(values, accounts, setFieldValue) {
  const selectedAccountId = values[FROM_ACCOUNT]?.id;
  if (selectedAccountId) {
    const updatedAccount = accounts.find(account => account.id === selectedAccountId);
    setFieldValue(FROM_ACCOUNT, updatedAccount);
  }
}

export async function getTransferFee(accounts, beneficiaryFormValues, amountFormValues) {
  const sourceCurrency = get(amountFormValues, `${FROM_ACCOUNT}.value.currency`);
  const amount = toIndivisibleUnit(get(amountFormValues, SEND_AMOUNT, 0), sourceCurrency);
  const paymentRail =
    (beneficiaryFormValues && paymentRails[getRailsByBeneficiaryFormValues(beneficiaryFormValues)]) ||
    paymentRails.local;
  const transferFeeQuery = {
    amount,
    paymentRail,
    currency: sourceCurrency,
    action: feeTypes.transferOutgoing,
  };
  const fxTransferFeeQuery = {
    amount,
    currency: sourceCurrency,
    action: feeTypes.currencyExchange,
  };
  const feeQuery = isFxOutgoing() ? fxTransferFeeQuery : transferFeeQuery;

  const queryIsNotReady = some(feeQuery, isNil);
  if (queryIsNotReady) {
    return { rate: null };
  }

  try {
    if (isTransferInsideCompany(accounts, beneficiaryFormValues)) {
      return { [TOTAL_FEE_AMOUNT]: 0 };
    }

    const res = await getFee(feeQuery);
    const marginAmount = toMainUnit(res?.marginAmount, sourceCurrency);
    const totalFeeAmount = toMainUnit(res?.totalFeeAmount, sourceCurrency);

    const rate = { ...res, marginAmount, totalFeeAmount };

    return { rate };
  } catch (error) {
    return { error };
  }
}

function getTotalWithFeesAmount(amountFormValues, totalFeeAmount = 0) {
  const amountWithoutFee = get(amountFormValues, SEND_AMOUNT, 0);
  const unformattedAmount = reverseFormatNumber(amountWithoutFee);
  const amountHasValue = isNull(amountWithoutFee) || amountWithoutFee === '';
  const preciseTotal = parseFloat(amountHasValue ? 0 : unformattedAmount) + parseFloat(totalFeeAmount);

  return getAmount(preciseTotal);
}

function getTotalWithoutFees(amountFormValues) {
  return {
    label: 'Send',
    value: amountFormValues
      ? `${getTotalWithFeesAmount(amountFormValues)} ${amountFormValues?.[FROM_ACCOUNT]?.value.currency || ''}`
      : 0,
  };
}

function mapRate(fxRate) {
  const { sellCurrency, buyCurrency, rate } = fxRate;
  const roundedRate = parseFloat(rate).toFixed(4);

  return {
    label: t('sendFunds.totalContainer.exchangeRate'),
    value: `1 ${sellCurrency} = ${roundedRate} ${buyCurrency}`,
    hintText: t('sendFunds.totalContainer.hintText'),
  };
}

function mapTransferFee(totalFeeAmount, amountFormValues, chargeReason) {
  const fromCurrency = get(amountFormValues, `${FROM_ACCOUNT}.value.currency`, '');

  const showChargeReasonText =
    totalFeeAmount > 0 && [ feeReasonsMap.velocityOverflow, feeReasonsMap.volumeOverflow ].includes(chargeReason);

  const formattedAmount = getAmount(totalFeeAmount);

  return {
    label: 'Transfer fee',
    value: `${formattedAmount} ${fromCurrency}`,
    hintText: showChargeReasonText && sepaThresholdsReachedText,
  };
}

function getTotalWithFees(amountFormValues, totalFeeAmount) {
  return {
    label: t('sendFunds.totalContainer.totalWithFees'),
    value: amountFormValues
      ? `${getTotalWithFeesAmount(amountFormValues, totalFeeAmount)} ${
          amountFormValues?.[FROM_ACCOUNT]?.value?.currency || ''
        }`
      : 0,
  };
}

export function getTotal(
  beneficiaryFormValues, amountFormValues, showAmountWithoutFee = false, hideNullFee = false,
) {
  const totalFeeAmount = get(amountFormValues, `${TOTAL_FEE_AMOUNT}.totalFeeAmount`, 0);

  const chargeReason = get(amountFormValues, `${TOTAL_FEE_AMOUNT}.reason`);
  const fxRate = get(amountFormValues, FX_RATE);
  const shouldHideZeroFee = totalFeeAmount === 0 && hideNullFee;

  return {
    amountWithoutFee: showAmountWithoutFee ? getTotalWithoutFees(amountFormValues) : undefined,
    rate: shouldShowRate(beneficiaryFormValues, amountFormValues, fxRate) ? mapRate(fxRate) : undefined,
    transferFee:
      !isEmpty(amountFormValues[TOTAL_FEE_AMOUNT]) && !shouldHideZeroFee
        ? mapTransferFee(totalFeeAmount, amountFormValues, chargeReason)
        : undefined,
    total: getTotalWithFees(amountFormValues, totalFeeAmount),
  };
}

export function getConfirmationFromBoxContent(fromAccount, beneficiaryFormValues, accountTitleComponent) {
  const { name: beneficiaryName } = getBeneficiaryName(beneficiaryFormValues);
  const accountNumber = getAccountNumber(beneficiaryFormValues);
  const bankCode = getBankCode(beneficiaryFormValues);

  return {
    from: {
      label: 'From',
      value:
        fromAccount &&
        accountTitleComponent({
          currency: beneficiaryFormValues[BENEFICIARY_CURRENCY]?.value,
          name: fromAccount?.value.name,
        }),
    },
    fromIban: {
      label: 'IBAN',
      value: fromAccount && formatIbanIsValid(fromAccount?.value.requisites.number),
    },
    fromBic: {
      label: 'BIC',
      value: fromAccount && fromAccount?.value.requisites.code,
    },
    to: {
      label: 'To',
      value: beneficiaryName,
    },
    toAccountNumber: {
      label: accountNumber?.label,
      value: formatIbanIsValid(accountNumber?.value),
    },
    toBankCode: {
      label: bankCode?.label,
      value: bankCode?.value,
    },
  };
}

export function getBankCode(beneficiaryFormValues = {}) {
  const bankCodeFieldName = BANK_CODE_FIELD_NAMES.find(fieldName => beneficiaryFormValues[fieldName]);

  return {
    label: beneficiariesFieldsLabelMap[bankCodeFieldName],
    ...beneficiaryFormValues[bankCodeFieldName]?.value,
  };
}

export function getConfirmationPaymentReferenceContent(purposeCode, paymentReference = '') {
  const maxSymbols = 23;

  const purposeCodeValue = truncate(upperCase(purposeCode?.value), { length: maxSymbols });

  const transliteratedPaymentReference = transliterate(paymentReference);
  const paymentReferenceValue = truncate(transliteratedPaymentReference, { length: maxSymbols });

  return purposeCodeValue ? {
    purposeCode: {
      label: 'Purpose code',
      value: purposeCodeValue,
    },
    paymentReference: {
      label: 'Payment reference',
      value: paymentReferenceValue,
    },
  } : {
    paymentReference: {
      label: 'Payment reference',
      value: paymentReferenceValue,
    },
  };
}

export function shouldRedirectToAmountError(errors) {
  const errorCodes = [ ErrorCodes.insufficientFunds, ErrorCodes.rateChangedTooMuch ];

  return errorsConsistOneOfErrorCodes(errors, errorCodes);
}

export function validateAmountFormValues(amountFormValues, redirectOnCatch = () => {}) {
  return createAmountValidationSchema()
    .validate(amountFormValues)
    .catch(() => redirectOnCatch());
}

export function getBeneficiaryName(beneficiaryFormValues) {
  const beneficiaryName = get(beneficiaryFormValues, `${BENEFICIARY_NAME}.label`);
  const firstName = get(beneficiaryFormValues, `${BENEFICIARY_FIRST_NAME}.label`);
  const lastName = get(beneficiaryFormValues, `${BENEFICIARY_LAST_NAME}.label`);
  const iban = get(beneficiaryFormValues, `${BENEFICIARY_IBAN}.label`);
  const bic = get(beneficiaryFormValues, `${BENEFICIARY_BIC}.label`);
  const currency = get(beneficiaryFormValues, `${BENEFICIARY_CURRENCY}.value`);

  return {
    name: beneficiaryName || `${firstName} ${lastName}`,
    currency,
    bic,
    iban,
  };
}

export const allowedCountries = Object.keys(countries).reduce((acc, countryCode) => {
  if (EXCLUDED_COUNTRIES.includes(countries[countryCode])) {
    return acc;
  }

  return [ ...acc, { label: countries[countryCode], value: countryCode }];
}, []);

export const getCurrenciesData = accounts =>
  accounts
    .reduce((possibleCurrencies, { currency: currentCurrency }) => {
      const currencyOption = { currency: currentCurrency, name: CURRENCY_NAMES[currentCurrency] };

      return !possibleCurrencies.find(value => value.currency === currentCurrency)
        ? [ ...possibleCurrencies, currencyOption ]
        : possibleCurrencies;
    }, [])
    .sort();

export function isPurposeCodeFieldRequired(beneficiaryFormValues) {
  const purposeCodeField = beneficiaryFormValues.beneficiaryDetailsResponse.find(field => field.name === PURPOSE_CODE);

  return Boolean(purposeCodeField);
}

// Private functions

/**
 * @param {ApiError} error
 * @param {string} fieldName
 * @return {string|undefined}
 */
function getFieldErrorMessage(error, fieldName) {
  const preparedApiPropertyName = FORM_FIELDS_TO_BENEFICIARY_REQUEST_MAP[fieldName]
    .split('.')
    .map(propertyPath => snakeCase(propertyPath)).join('.');
  const reducedError =
      get(error.data, `${preparedApiPropertyName}.value`)
      || get(error.data, preparedApiPropertyName);

  return reducedError?.message;
}
