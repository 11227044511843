import styled from 'styled-components';

import { ReactComponent as ChevronDownIcon } from 'assets/icons/chevron-down.svg';
import { hex2rgba } from 'helpers/styles';

const DropdownWrapper = styled.div`
  border-bottom: 0.0625rem solid ${props => (props.isOpen ? 'transparent' : hex2rgba(props.theme.colors.black, 0.1))};

  :last-of-type {
    border-bottom-color: transparent;
  }
`;

const DropdownContainer = styled.div`
  align-items: flex-start;
  background: none;
  border: none;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  text-align: left;
  width: 100%;
`;

const DropdownTitleWrapper = styled.div`
  align-items: center;
  background: none;
  border: none;
  display: flex;
  height: 100%;
  justify-content: space-between;
  width: 100%;

  svg {
    ${props => (props.isOpen ? 'transform: rotate(180deg)' : 'transform: rotate(0deg)')};
    transition: 0.2s transform ease;

    path {
      fill: ${props => props.theme.colors.greyDark};
    }
  }
`;

const DropdownContent = styled.div`
  ${props => (props.isOpen ? 'display: block' : 'display: none')};
  height: 100%;
  width: 100%;
`;

const DropdownTitle = styled.h4`
  font: ${props => props.theme.fonts.bodyMSemi};
`;

const ChevronDown = styled(ChevronDownIcon)`
  fill: ${props => props.theme.colors.blue};
`;

export default {
  DropdownContainer,
  DropdownContent,
  DropdownTitle,
  DropdownTitleWrapper,
  DropdownWrapper,
  ChevronDown,
};
