import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Dash, Hint } from 'components';

import S from './styles';

function EditButton({ value, onEdit }) {
  const { t } = useTranslation();

  return (
    <>
      <S.EditValue>
        {value || <Dash />}
      </S.EditValue>
      <Hint text={t('user.edit.hint')} width="auto">
        <S.EditBtn onClick={onEdit} />
      </Hint>
    </>
  );
}

EditButton.propTypes = {
  value: PropTypes.string,
  onEdit: PropTypes.func.isRequired,
};

export default EditButton;
