import dayjs from 'dayjs';

import {
  cardTransactionTypes,
  incomingTransactionTypes,
  outgoingTransactionTypes,
  transactionReferences,
  transactionStatus,
  transactionType,
} from 'constants/transactions';

// TODO Remove.
export function isInternalMovement(paymentReference) {
  if (!paymentReference) {
    return false;
  }

  return paymentReference.toLowerCase()
    .includes(transactionReferences.movementBetweenAccounts);
}

export function isIncomingTransaction(type) {
  return incomingTransactionTypes.includes(type);
}

export function isOutgoingTransaction(type) {
  return outgoingTransactionTypes.includes(type);
}

export function isCardTransaction(type) {
  return cardTransactionTypes.includes(type);
}

export function isFxTransaction(type) {
  return type === transactionType.fx;
}

export function isFeeTransaction(type) {
  return type === transactionType.cardFee || type === transactionType.invoicePayment;
}

export function isTransactionFailed(status) {
  return status === transactionStatus.failed || status === transactionStatus.rejected;
}

export function groupTransactionsByDate(data) {
  return data.reduce((groups, transaction) => {
    const transactionGroups = groups;

    const formattedDate = dayjs(transaction.createdAt).format('YYYY-MM-DD');
    if (!groups[formattedDate]) {
      transactionGroups[formattedDate] = [];
    }
    groups[formattedDate].push(transaction);

    return groups;
  }, {});
}

export function groupTransactionsByAccounts(data) {
  return data.reduce((groups, transaction) => {
    const transactionGroups = groups;

    const {
      fromAccount: { id, displayName },
      sourceCurrency,
    } = transaction;
    if (!groups[id]) {
      transactionGroups[id] = [];
      transactionGroups[id].name = displayName;
      transactionGroups[id].currency = sourceCurrency;
    }

    groups[id].push(transaction);

    return groups;
  }, {});
}

export function getAccountListMapped(accounts) {
  return accounts.map(({ displayName, id }) => ({
    label: displayName,
    value: id,
  }));
}
