import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import set from 'lodash/set';
import React, { useMemo, useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { deleteRole, updateRole } from 'api/roles';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import PermissionsList from 'components/permissions-list';
import { showGeneralToastError } from 'utils/errors';
import { navigation } from 'constants/navigation';
import { GlobalLoaderContext } from 'contexts/global-loader';
import { UserContext } from 'contexts/user';
import { ViewContext } from 'contexts/view';
import { useRole } from 'hooks';
import { WizardLayout } from 'layouts';
import ConfirmationModal from 'pages/roles/components/confirmation-modal';

import S from './styles';

function Role() {
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const { role, setRole } = useRole(id);
  const { startLoading, endLoading } = useContext(GlobalLoaderContext);
  const {
    permissions: { canUpdateUserRole, canDeleteUserRole },
  } = useContext(UserContext);

  const [ roleUpdateData, setRoleUpdateData ] = useState({ permissions: [] });
  const [ confirmationModalIsOpen, setConfirmationModalIsOpen ] = useState(false);
  const [ error, setError ] = useState(null);

  const notSystemRole = !role.isSystem;
  const isEditable = canUpdateUserRole && notSystemRole;
  const showEditButton = canUpdateUserRole && notSystemRole;
  const showDeleteButton = canDeleteUserRole && notSystemRole;

  const hasChanges = !isEmpty(roleUpdateData);

  function handleBackToRoles() {
    history.push(navigation.roles);
  }

  const handleChangeName = event => {
    const name = event.target.value;

    setError(null);

    setRole({ ...role, name });
    setRoleUpdateData({ ...roleUpdateData, name });
  };

  const handlePermissionChange = (key, path) => {
    const permission = get(role, path);
    const updatedRole = set(role, `${path}.value`, !permission.value);

    setRole({ ...updatedRole });
    setRoleUpdateData({
      name: role.name,
      permissions: [
        ...roleUpdateData.permissions,
        {
          title: permission.title,
          key,
          value: permission.value,
        },
      ],
    });
  };

  const handleUpdate = async () => {
    startLoading();

    try {
      const updatedRole = await updateRole(role.id, roleUpdateData);
      setRole(updatedRole);
      toast.success(t('role.update.successMessage'));
    } catch (err) {
      setError(err.data);
    } finally {
      endLoading();
    }
  };

  const handleDelete = () => {
    setConfirmationModalIsOpen(true);
  };

  const handleDeleteConfirm = async () => {
    startLoading();

    try {
      await deleteRole(role.id);
      history.push(navigation.roles);
      toast.success(t('role.delete.successMessage'));
    } catch (err) {
      showGeneralToastError(err);
    } finally {
      endLoading();
    }
  };

  const viewValues = useMemo(() => ({ triggerView: handleBackToRoles }), []);
  const isContentLoading = isEmpty(role);

  return (
    <ViewContext.Provider value={viewValues}>
      <WizardLayout title={t('role.title')}>
        {!isContentLoading && (
          <S.Container>
            <S.Input
              name="name"
              label={t('role.form.name.label')}
              value={role.name}
              error={error?.name?.message}
              disabled={!isEditable}
              onChange={handleChangeName}
            />
            <PermissionsList
              permissionCategories={role.permissionCategories}
              handlePermissionChange={handlePermissionChange}
              disabled={!isEditable}
            />
            {showEditButton && <S.Button primary label={t('role.form.button.update')} disabled={!hasChanges} onClick={handleUpdate} />}
            {showDeleteButton && (
              <S.Button
                label={t('role.form.button.delete')}
                category="link"
                icon={<DeleteIcon />}
                onClick={handleDelete}
                leftMargin
                isDanger
              />
          )}
            <ConfirmationModal
              isOpen={confirmationModalIsOpen}
              setIsOpen={setConfirmationModalIsOpen}
              onSubmit={handleDeleteConfirm}
            />
          </S.Container>
)}
      </WizardLayout>
    </ViewContext.Provider>
  );
}

export default Role;
