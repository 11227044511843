import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { CircleFlag } from 'react-circle-flags';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';

import { Card, Dash } from 'components';
import { cardStatusesMap, PHYSICAL_CARD_TYPE } from 'constants/card';
import { CURRENCY_FLAGS } from 'constants/currencies';
import { cardCreationStatusMap, cardProfileOptions } from 'pages/order-cards/constants';
import { getDeliveryAddress } from 'pages/order-cards/utils';
import { convertDateFromApiFormatToObject, formatExpirationDate } from 'utils/card';
import { orderCardShape } from 'local-prop-types';

import S from './styles';

function CardSummarySidebar({
  cardHolder,
  companyName,
  displayName,
  address,
  expiration,
  type,
  account,
  status,
  id,
  isSidebarOpen,
  setSelectedCard,
  handleSidebarClose,
  cards,
}) {
  const isSuccess = status === cardCreationStatusMap.success;
  const { t } = useTranslation();
  const expirationDateObject = expiration && convertDateFromApiFormatToObject(expiration);

  const handleUpArrowClick = useCallback(() => {
    const previousCardIndex = cards.findIndex(card => card.id === id) - 1;
    setSelectedCard(cards[previousCardIndex]);
  }, [ cards, id ]);

  const handleDownArrowClick = useCallback(() => {
    const nextCardIndex = cards.findIndex(card => card.id === id) + 1;
    setSelectedCard(cards[nextCardIndex]);
  }, [ cards, id ]);

  return (
    <S.Sidebar
      isOpen={isSidebarOpen}
      handleClose={handleSidebarClose}
      isControlPanelVisible
      isUpArrowDisabled={id === cards[0]?.id}
      isDownArrowDisabled={id === cards[cards.length - 1]?.id}
      handleUpArrowClick={handleUpArrowClick}
      handleDownArrowClick={handleDownArrowClick}
      disableHeaderIcon
    >
      {!isEmpty(cardHolder) && (
        <S.Container>
          <S.CardContainer>
            <Card
              card={{
                id,
                currency: account.currency,
                type,
                account: {
                  name: account.displayName,
                },
                cardholder: {
                  firstName: cardHolder.firstName,
                  lastName: cardHolder.lastName,
                },
                expiry: expirationDateObject,
                status: cardStatusesMap.active,
                companyName,
              }}
              flipped
              isNewCard
            />
          </S.CardContainer>
          <S.InputsContainer>
            <S.DataContainer>
              <S.DataContainerLabel>
                {t('orderCards.summary.sidebar.cardholder')}
              </S.DataContainerLabel>
              <S.DataContainerValue lineBreak>
                {cardHolder.firstName}
                {' '}
                {cardHolder.lastName}
              </S.DataContainerValue>
            </S.DataContainer>
            <S.DataContainer>
              <S.DataContainerLabel>
                {t('orderCards.summary.sidebar.cardName')}
              </S.DataContainerLabel>
              <S.DataContainerValue>
                {displayName || <Dash />}
              </S.DataContainerValue>
            </S.DataContainer>
            <S.DataContainer>
              <S.DataContainerLabel>
                {t('orderCards.summary.sidebar.email')}
              </S.DataContainerLabel>
              <S.DataContainerValue>
                {cardHolder.email}
              </S.DataContainerValue>
            </S.DataContainer>
            <S.DataContainer>
              <S.DataContainerLabel>
                {t('orderCards.summary.sidebar.cardAccount')}
              </S.DataContainerLabel>
              <S.DataContainerValue>
                <S.CurrencyContainer>
                  <CircleFlag countryCode={CURRENCY_FLAGS[account.currency]} height="20" />
                  <S.Currency>
                    {account.displayName}
                  </S.Currency>
                </S.CurrencyContainer>
              </S.DataContainerValue>
            </S.DataContainer>
            <S.DataContainer>
              <S.DataContainerLabel>
                {t('orderCards.summary.sidebar.validThru')}
              </S.DataContainerLabel>
              <S.DataContainerValue>
                {formatExpirationDate(expirationDateObject)}
              </S.DataContainerValue>
            </S.DataContainer>
            <S.DataContainer>
              <S.DataContainerLabel>
                {t('orderCards.summary.sidebar.profile')}
              </S.DataContainerLabel>
              <S.DataContainerValue>
                {cardProfileOptions[0].value}
              </S.DataContainerValue>
            </S.DataContainer>
            {type === PHYSICAL_CARD_TYPE && (
              <S.DataContainer isVertical>
                <S.DataContainerLabel>
                  {t('orderCards.summary.sidebar.delivery')}
                </S.DataContainerLabel>
                <S.DataContainerValue lineBreak>
                  {getDeliveryAddress(address)}
                </S.DataContainerValue>
              </S.DataContainer>
            )}
            {(status === cardCreationStatusMap.success || status === cardCreationStatusMap.fail) && (
              <S.DataContainer>
                <S.DataContainerLabel>
                  {t('orderCards.summary.sidebar.status')}
                </S.DataContainerLabel>
                <S.DataContainerValue>
                  <S.Status success={isSuccess}>
                    {isSuccess ? t('orderCards.summary.sidebar.issued') : t('orderCards.summary.sidebar.notIssued')}
                  </S.Status>
                </S.DataContainerValue>
              </S.DataContainer>
            )}
          </S.InputsContainer>
        </S.Container>
      )}
    </S.Sidebar>
  );
}

CardSummarySidebar.propTypes = {
  address: PropTypes.instanceOf(Object),
  cardHolder: PropTypes.instanceOf(Object),
  companyName: PropTypes.string.isRequired,
  displayName: PropTypes.string,
  expiration: PropTypes.oneOfType([ PropTypes.string, PropTypes.instanceOf(Object) ]),
  id: PropTypes.string,
  status: PropTypes.string,
  type: PropTypes.string,
  account: PropTypes.instanceOf(Object).isRequired,
  cards: PropTypes.arrayOf(orderCardShape),
  isSidebarOpen: PropTypes.bool.isRequired,
  handleSidebarClose: PropTypes.func.isRequired,
  setSelectedCard: PropTypes.func.isRequired,
};

export default CardSummarySidebar;
