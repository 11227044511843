import {
  Button as ButtonComponent,
  Paragraph as ParagraphComponent,
  Title as TitleComponent,
} from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';

const Button = styled(ButtonComponent)`
	width: 100%;
	padding: 0;
		
	@media ${DEVICE.tablet} {
		width: 15.8125rem;
	}
`;

const ControlWrapper = styled.div`
	display: flex;
	margin-top: 1.875rem;
	
	@media ${DEVICE.tablet} {
		justify-content: flex-start;
	}
`;

const Form = styled.form`
	margin-top: 2.5rem;
	max-width: 30rem;
`;

const Paragraph = styled(ParagraphComponent)`
  	font: ${props => props.theme.fonts.bodyS};
	color: ${props => props.theme.colors.greyMedium};
	max-width: 27.625rem;
`;

const Subtitle = styled(ParagraphComponent)`
	color: ${props => props.theme.colors.intergiroBlack};
	font: ${props => props.theme.fonts.bodyMSemi};
	margin-bottom: 2.5rem;
	max-width: 27.625rem;
`;

const Title = styled(TitleComponent)`
	font: ${props => props.theme.fonts.h3Bold};
	line-height: 1.2;
	margin-bottom: 0.625rem;
	
	@media ${DEVICE.tablet} {
		font: ${props => props.theme.fonts.h1Bold};
	}
`;

const Wrapper = styled.div`
	max-width: 34.375rem;
`;

export default {
  Button, ControlWrapper, Form, Paragraph, Subtitle, Title, Wrapper,
};
