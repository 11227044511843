import { Button } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right-thin.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { CancelButton as Cancel, TotalBlock } from 'components';
import { DEVICE } from 'constants/breakpoints';

const Row = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media ${DEVICE.tablet} {
		flex-direction: column;
    align-items: flex-start;
    width: 100%;
	}

  @media ${DEVICE.laptop} {
		flex-direction: row;
    width: 100%;
    align-items: center;
    max-width: 66.25rem;
	}
`;

const AmountRow = styled(Row)`
  margin-top: 1.25rem;
  align-items: flex-start;

  @media ${DEVICE.laptop} {
    margin-top: 2.5rem;
	}
`;

const TotalRow = styled(TotalBlock)`
  margin-top: 1.25rem;

  @media ${DEVICE.tablet} {
		flex-direction: column;
    width: 100%;
    margin-top: 2.5rem;
    max-width: 30rem;
	}

  @media ${DEVICE.laptop} {
		flex-direction: row;
    max-width: 30rem;
    margin-top: 5rem;
    margin-bottom: 3.75rem;
	}
`;

const ArrowRight = styled(ArrowRightIcon)`
  display: none;
  path {
    fill: ${props => props.theme.colors.greyLight};
  }

  @media ${DEVICE.laptop} {
    display: block;
		width: 7.9375rem;
    padding: 0 2.125rem;
	}
`;

const Separator = styled.div`
  display: none;

  @media ${DEVICE.laptop} {
    display: block;
    width: 7.9375rem;
    padding: 0 2.125rem;
  }
`;

const InputContainer = styled.div`
  width: 100%;
  margin-bottom: 1.25rem;
  height: 100%;
  max-width: 30rem;

  @media ${DEVICE.laptop} {
		margin-bottom: 0;
	}
`;

const RateAndTotalContainer = styled.div`
  border: 0.0625rem solid ${props => props.theme.colors.greyBright};
  border-radius: 0.5rem;
`;

const Total = styled.div`
  display: flex;
  justify-content: space-between;
  font: ${props => props.theme.fonts.bodyLBold};
  padding: 0.3125rem 1rem;
  font-weight: 600;
`;

const InfoHint = styled(InfoIcon)`
  width: 1.25rem;
  path {
		fill: ${props => props.theme.colors.greyMediumDark};
	}
`;

const CurrencyLabel = styled.span`
  font: ${props => props.theme.fonts.bodyS};
  color: ${props => props.theme.colors.greyMedium};
`;

const SubmitButton = styled(Button)`
  margin: 0;
  margin-bottom: 0.9375rem;
  width: 100%;

  @media ${DEVICE.tablet} {
    margin-top: 2.5rem;
    width: auto;
  }
`;

const CancelButton = styled(Cancel)`
  display: block;
  width: 100%;

  @media ${DEVICE.tablet} {
    display: none;
  }
`;

export default {
  ArrowRight,
  CancelButton,
  CurrencyLabel,
  InfoHint,
  InputContainer,
  RateAndTotalContainer,
  Row,
  AmountRow,
  TotalRow,
  Separator,
  SubmitButton,
  Total,
};
