import { Button as DefaultButton, RadioButton } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';

const HelperText = styled.p`
  margin: 0;
  font: ${props => props.theme.fonts.bodyMBold};
  margin-bottom: 1.4375rem;
`;

const Button = styled(DefaultButton)`
  margin-right: 0;
  min-width: fit-content;
  padding: 0.75rem 1.5rem;
  margin-top: ${props => props.category === 'secondary' ? '1rem' : 0};
  width: 100%;

  @media ${DEVICE.tablet} {
    margin-top: 0;
    margin-right: 0.625rem;
    width: auto;
  }
`;

const Radio = styled(RadioButton)`
  margin-bottom: 1.625rem;
`;

const ValidationMessageBlock = styled.div`
  color: ${props => props.theme.colors.red};
  margin-top: 1.375rem;
  margin-bottom: 1.875rem;
`;

export default {
  HelperText,
  ValidationMessageBlock,
  Button,
  Radio,
};
