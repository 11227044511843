import { Button as DefaultButton, Title as DefaultTitle } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { ReactComponent as ChevronUpIcon } from 'assets/icons/chevron-up.svg';
import { CancelButton as Cancel, Badge as DefaultBadge, AlertBlock } from 'components';
import { DEVICE } from 'constants/breakpoints';
import { hex2rgba } from 'helpers/styles';
import { ReactComponent as Icon } from 'assets/icons/info.svg';

const Container = styled.div`
  padding-bottom: 1.875rem;
`;

const Title = styled(DefaultTitle)`
  font: ${props => props.theme.fonts.h3Bold};
  margin-bottom: 1.25rem;
`;

const Button = styled(DefaultButton)`
  margin: 0;
  margin-top: 1.875rem;
  width: 100%;

  @media ${DEVICE.tablet} {
    margin-right: 0.5rem;
    min-width: auto;
    width: auto;
  }
`;

const CancelButton = styled(Cancel)`
  display: block;
  width: 100%;
  margin-top: 1rem;

  @media ${DEVICE.tablet} {
    display: none;
  }
`;

const Separator = styled.hr`
  border-top: ${props => hex2rgba(props.theme.colors.black, 0.1)};
  margin: 1.875rem 0;
`;

const ConfirmationBlock = styled.div`
  margin-top: 1.25rem;
`;

const Total = styled.p`
  font: ${props => props.theme.fonts.bodyM};
  font-weight: 600;
  margin: 0;
  
  @media ${DEVICE.tablet} {
    font-size: 1.25rem;
  }
`;

const TotalBlock = styled.div`
  border-radius: 0.5rem;
  border: 0.0625rem solid ${props => props.theme.colors.greyBright};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.1875rem;
  margin-bottom: 1rem;
  width: 100%;

  @media ${DEVICE.tablet} {
    padding: 1.1875rem;
    width: 30rem;
  }
`;

const TotalLabel = styled.div`
  font: ${props => props.theme.fonts.bodyMBold};
`;

const TotalText = styled.div`
  font: ${props => props.theme.fonts.bodyMSemi};
`;

const TotalContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const Issue = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  
  &:nth-child(2) {
    margin-top: 0.8125rem;
  }
`;

const Badge = styled(DefaultBadge)`
  ${props => props.isSuccess && `
    background: ${props.theme.colors.green};
  `}
`;

const IssueText = styled.span`
  font: ${props => props.theme.fonts.bodyMSemi};
  margin-left: 0.5rem;
`;

const SubmittedContainer = styled.div`
  width: 100%;
`;

const Table = styled.div`
  margin-bottom: 3.75rem;
`;

const Alert = styled(AlertBlock)`
  margin-bottom: 0;
  width: 100%;

  @media ${DEVICE.tablet} {
    width: 30rem;
  }
`;

const InfoIcon = styled(Icon)`
  width: 2rem;
  height: 2rem;
  fill: ${props => props.theme.colors.blue};
`;

const InfoContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const InfoLink = styled.div`
  font: ${props => props.theme.fonts.bodySBold};
  color: ${props => props.theme.colors.blue};
  cursor: pointer;
  margin-bottom: 0.5rem;
`;

const InfoText = styled.p`
  font: ${props => props.theme.fonts.bodyXsSemi};
  margin: 0;
`;

const DetailsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 3.75rem;

  & svg {
    ${props => props.isActive && `
      transform: rotate(0)
    `}
  }
`;

const DetailsLabel = styled.div`
  font: ${props => props.theme.fonts.bodyLSemi};
  color: ${props => props.theme.colors.blue};
  cursor: pointer;
  margin-right: 0.375rem;
`;

const ChevronIcon = styled(ChevronUpIcon)`
  fill: ${props => props.theme.colors.blue};
  transform: rotate(180deg);
  cursor: pointer;
`;

export default {
  Container,
  Title,
  Button,
  CancelButton,
  Separator,
  Total,
  Issue,
  IssueText,
  ConfirmationBlock,
  TotalBlock,
  TotalLabel,
  TotalText,
  TotalContent,
  SubmittedContainer,
  Table,
  Alert,
  InfoIcon,
  InfoContent,
  InfoLink,
  InfoText,
  Badge,
  DetailsContainer,
  DetailsLabel,
  ChevronIcon,
};
