import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { ModalContent } from 'components';
import { MODAL_TYPE } from 'constants/modal';
import { navigation } from 'constants/navigation';

import S from './styles';

function InsufficientFundsErrorModal({ isOpen, setIsOpen, account }) {
  const history = useHistory();

  const handleGoToCards = () => {
    setIsOpen(false);
    history.push(navigation.cards);
  };

  const handleAddFunds = () => {
    setIsOpen(false);
    history.push(`${navigation.account}/${account.id}`);
  };

  const CTAButtons = (
    <>
      <S.Button label="Add funds" category="primary" type="submit" onClick={handleAddFunds} />
      <S.Button label="Go to cards" category="secondary" type="submit" onClick={handleGoToCards} />
    </>
  );

  return (
    <ModalContent
      title="Insufficient funds"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      CTAButtons={CTAButtons}
      type={MODAL_TYPE.message}
      hasCloseIcon
    >
      <S.Message>
        Sorry, you have insufficient funds on your account
        {' '}
        <S.Name>
          {account.displayName}
        </S.Name>
        {' '}
        to
        complete the card order.
        Please top up your account by moving funds internally or transferring from an external source.
      </S.Message>
    </ModalContent>
  );
}

InsufficientFundsErrorModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  account: PropTypes.instanceOf(Object).isRequired,
};

export default InsufficientFundsErrorModal;
