import { ErrorCodes } from '@general/error-codes';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { TwoFa } from 'components';
import { ERRORS } from 'constants/errors';
import { BAD_REQUEST, TOO_MANY_REQUESTS } from 'constants/status-code';
import TwoFaProvider, { TwoFaContext } from 'contexts/two-fa';
import { showGeneralToastError, errorsConsistOneOfErrorCodes } from 'utils/errors';
import { minutesTill } from 'utils/time';
import { signin, confirmChallenge } from 'api/auth';

import S from './styles';

const duration = require('dayjs/plugin/duration');

dayjs.extend(duration);

function LoginConfirmation({ onResend, onSubmit, twoFa, email, password }) {
  return (
    <TwoFaProvider twoFaData={twoFa}>
      <LoginConfirmationForm email={email} password={password} onResend={onResend} onSubmit={onSubmit} />
    </TwoFaProvider>
  );
}

function LoginConfirmationForm({ password, email, onResend, onSubmit }) {
  const { t } = useTranslation();
  const {
    setErrors,
    isSubmitting,
    setSubmitting,
    stopTimer,
    newChallenge,
    setIsNonceExpired,
    isNonceExpired,
    startTimer,
    clearNonceInput,
    challengeId,
    setHandleSubmitFunc,
  } = useContext(TwoFaContext);

  useEffect(() => {
    setHandleSubmitFunc(() => signIn);
  }, [ email, password, challengeId ]);

  const signIn = async nonce => {
    try {
      const response = await confirmChallenge({
        email,
        password,
        challenge: {
          nonce,
          id: challengeId,
        },
      });

      await onSubmit(response);
    } catch (error) {
      if (error.status === BAD_REQUEST) {
        setErrors({ nonce: error.data.nonce.message });

        if (errorsConsistOneOfErrorCodes(error.data, [ErrorCodes.expired])) {
          setIsNonceExpired(true);
        }

        return;
      }

      showGeneralToastError(error);
    } finally {
      setSubmitting(false);
    }
  };

  const resend = async factor => {
    stopTimer();
    setSubmitting(true);

    try {
      const response = await signin({ email, password, preferredFactor: factor });
      newChallenge.setAvailableAt(response.challenge.newChallengeAvailableAt);
      setIsNonceExpired(false);
      startTimer();
      clearNonceInput();
      await onResend(response);
    } catch (error) {
      if (error.status === TOO_MANY_REQUESTS) {
        toast.error(ERRORS.tryInXMinutes(minutesTill(error.data.newChallengeAvailableAt?.message)));

        return;
      }

      if (errorsConsistOneOfErrorCodes(error.data, [ ErrorCodes.messageBlocked, ErrorCodes.regionBlocked ])) {
        toast.error(error.data.default?.message, { autoClose: false });

        return;
      }

      showGeneralToastError(error);
    } finally {
      setSubmitting(false);
    }
  };

  const CTAButtons = (
    <S.ControlWrapper>
      <S.Button size="large" type="submit" label={t('login.confirmation.button')} disabled={isSubmitting || isNonceExpired} />
    </S.ControlWrapper>
  );

  return (
    <S.Wrapper>
      <S.Title>
        {t('login.confirmation.title')}
      </S.Title>
      <TwoFa resend={resend} CTAButtons={CTAButtons} isVoiceCallEnabled />
    </ S.Wrapper>
  );
}

LoginConfirmation.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onResend: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  twoFa: PropTypes.shape({
    codeExpirationSec: PropTypes.number,
    nonce: PropTypes.string,
    truncatedPhoneNumber: PropTypes.string,
    id: PropTypes.string,
    newChallengeAvailableAt: PropTypes.number,
  }),
};

LoginConfirmationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onResend: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
};

export default LoginConfirmation;
