import { Title as TitleComponent } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';

const Subtitle = styled.p`
  font: ${props => props.theme.fonts.bodyLBold};
  margin: 0;
  display: inline-block;
`;

const Main = styled.main`
  display: flex;
  justify-content: start;
  flex-direction: column;
  padding: 0 1.25rem;

  @media ${DEVICE.tablet} {
    padding: 0;
	}

  @media ${DEVICE.laptop} {
		flex-direction: row;
    padding: 0;
	}
`;

const Column = styled.div`
  width: 100%;
  margin: 1.875rem 0 1.875rem 0;
  @media ${DEVICE.laptop} {
		width: 40.5%;
    margin-right:6.25rem;
	}
`;

const SinglePayment = styled.div`
  margin-top: 2.5rem;
`;

const Title = styled(TitleComponent)`
  font: ${props => props.theme.fonts.bodyLBold};

  @media ${DEVICE.tablet} {
    font-size: 1.375rem;
	}
`;

export default {
  Subtitle,
  Title,
  Main,
  Column,
  SinglePayment,
};
