import isNil from 'lodash/isNil';

import { GRID_VIEW } from 'constants/viewType';
import { getCardsView } from 'utils/storage';
import { getParameterFromURL } from 'utils/helpers';

import { defaultActiveStatusDropdownOptions, statusDropdownName } from './constants';

export const getInitialViewType = () => {
  const viewValue = getCardsView();
  const view = isNil(viewValue) ? GRID_VIEW : viewValue;

  return view;
};

export const getStatusesQuery = location => ({
  statuses: getParameterFromURL(statusDropdownName, location)?.split(',') || defaultActiveStatusDropdownOptions,
});

export const getStatusOption = (option, location) => {
  const statusOptionsFromUrl = getParameterFromURL(statusDropdownName, location);

  return {
    ...option,
    checked: statusOptionsFromUrl ? statusOptionsFromUrl.includes(option.value)
    : defaultActiveStatusDropdownOptions.includes(option.value),
  };
};
