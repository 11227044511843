import { Tabs, Pagination as IntergiroPagination, RadioButton } from '@general/intergiro-ui-kit';
import styled from 'styled-components';
import { Link as DefaultLink } from 'react-router-dom';

import { ReactComponent as Check } from 'assets/icons/check.svg';
import { ReactComponent as Filters } from 'assets/icons/filters.svg';
import { DEVICE } from 'constants/breakpoints';

import { WRAPPER_CLASS_NAME } from './constants';

const TabsWrapper = styled.div`
  display: block;
`;

const TabsHead = styled(Tabs.Head)`
  margin: 0 0 0 1.1rem;
  padding: 0 0 0.8125rem 0;

  @media ${DEVICE.tablet} {
    margin: 0;
  }
`;

const SortBarWrapper = styled.div`
  display: none;

  @media ${DEVICE.tablet} {
    display: flex;
  }
`;

const ListActionsWrapper = styled.div`
  height: 1.25rem;
  align-items: center;
  align-self: flex-end;
  border: none;
  color: ${props => props.theme.colors.blue};
  cursor: pointer;
  display: flex;
  font: ${props => props.theme.fonts.bodyM};
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

const Pagination = styled(IntergiroPagination)`
  padding: 1.4375rem 0.5rem 1.0625rem;

  @media ${DEVICE.tablet} {
    padding: 1.4375rem 0 1.0625rem;
  }
`;

const SortWrapper = styled.div`
  border-radius: 0.375rem;
  box-shadow: ${props => props.theme.boxShadow2};
  padding: 0.5rem 0;
`;

const SortItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${props => props.theme.colors.black};
  padding: 0.625rem 1rem;

  &:hover {
    background: ${props => props.theme.colors.greyBright};
  }
`;

const CheckIcon = styled(Check)`
  fill: ${props => props.theme.colors.blue};
`;

const SearchAction = styled.div`
  margin-left: 1.5rem;
`;

const Link = styled(DefaultLink)`
  color: ${props => props.theme.colors.blue};
`;

const MobileActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0.75rem 1.25rem 0;
  width: calc(100% - 2.5rem);
  justify-content: space-between;

  @media ${DEVICE.tablet} {
    display: none;
  }
  
  .${WRAPPER_CLASS_NAME} {
    width: 100%;
    max-width: 15.1875rem;
  }
`;

const FiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.blue};
  font: ${props => props.theme.fonts.bodyMSemi};
`;

const FiltersIcon = styled(Filters)`
  fill: ${props => props.theme.colors.blue};
  margin-right: 0.4375rem;
`;

const Radio = styled(RadioButton)`
  margin-bottom: 0.75rem;
`;

export default {
  TabsWrapper,
  TabsHead,
  SortBarWrapper,
  ListActionsWrapper,
  SearchAction,
  SortWrapper,
  SortItem,
  CheckIcon,
  Pagination,
  Link,
  MobileActionsWrapper,
  FiltersWrapper,
  FiltersIcon,
  Radio,
};
