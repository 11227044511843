import { object, string } from 'yup';

import { ACCOUNT_SELECTION, ACCOUNT_TYPE, CARD_TYPE_SWITCH, EXISTING_ACCOUNT } from '../../constants';

export const OrderCardsSchema = object().shape({
  [CARD_TYPE_SWITCH]: string().required('Required field'),
  [ACCOUNT_TYPE]: string().required('Required field'),
  [ACCOUNT_SELECTION]: object()
    .nullable()
    .when([ACCOUNT_TYPE], {
      is: EXISTING_ACCOUNT,
      then: object()
        .nullable()
        .shape({
          amount: string(),
          balance: string(),
          currency: string(),
          name: string(),
          displayName: string(),
          id: string(),
        })
        .required('Please select Card Account to proceed'),
    }),
});
