import styled from 'styled-components';

import { Label } from 'components/transaction-sidebar/styles';

const DescriptionWrapper = styled.span`
  margin-bottom: 1.875rem;
  display: block;
  font: ${props => props.theme.fonts.bodyMSemi};
`;

const Text = styled.div`
  color: ${props => props.theme.colors.black};
`;

const SectionWrapper = styled.div`
  display: ${props => props.isHidden ? 'none' : 'block'};
  margin-top: 0.938rem;
  &:first-of-type {
    margin-top: 0;
  }
`;

export default {
  DescriptionWrapper,
  SectionWrapper,
  Label,
  Text,
};
