import React, { useMemo } from 'react';

import { LastFourDigits } from 'components';
import {
  cardExpiredAndBlockedStatuses,
  cardStatusesMap,
  labels,
  MASTERCARD,
  PREPAID_FUNDING_TYPE,
} from 'constants/card';
import { navigation } from 'constants/navigation';
import { cardShape } from 'local-prop-types';
import { formatAmount } from 'utils/amount';
import { formatExpirationDate, getCardHolderTruncatedName } from 'utils/card';

import S from './styles';

function CardGridItem({ card }) {
  const isDimmed = useMemo(() => (card.status === cardStatusesMap.notActivated), [card.status]);
  const truncatedName = useMemo(() => getCardHolderTruncatedName(card.cardholder, 26), [card.cardholder]);

  return (
    <S.CardLink to={`${navigation.cards}/${card.id}`}>
      <S.Card dimmed={isDimmed} data-testid="cards-page-grid-item">
        <S.Row hasInnerRow>
          <S.InnerRow>
            <S.Text dimmed={isDimmed}>
              {card.displayName}
            </S.Text>

            <S.BalanceContainer dimmed={isDimmed}>
              <S.BalanceCurrency>
                {card.currency}
              </S.BalanceCurrency>
              {' '}
              <S.BalanceAmount>
                {formatAmount(card.availableBalance, card.currency)}
              </S.BalanceAmount>
            </S.BalanceContainer>
          </S.InnerRow>
          <S.InnerRow>
            <S.Account>
              {card.account.name}
            </S.Account>
            <S.Status>
              <S.CardStatus status={card.status} statusReason={card.statusReason} />
            </S.Status>
          </S.InnerRow>
        </S.Row>

        <S.Row>
          <div>
            <S.User>
              <S.Avatar
                name={card.cardholder.firstName}
                param1={card.cardholder.lastName}
                param2={card.cardholder.id}
                small
                dimmed={isDimmed}
              />
              <S.Text dimmed={isDimmed}>
                {truncatedName}
              </S.Text>
            </S.User>
            <S.DetailsContainerBottom>
              <S.LastFour dimmed={isDimmed}>
                <LastFourDigits lastFour={card.pan.lastFour} />
              </S.LastFour>
              {' '}
              <S.Expiry dimmed={isDimmed}>
                {cardExpiredAndBlockedStatuses.includes(card.status) || formatExpirationDate(card.expiry)}
              </S.Expiry>
            </S.DetailsContainerBottom>
          </div>
          <S.Brand>
            <S.FundingType dimmed={card.fundingType === PREPAID_FUNDING_TYPE || isDimmed}>
              {labels[card.fundingType]}
            </S.FundingType>
            {card.brand === MASTERCARD
              ? <S.Mastercard $dimmed={isDimmed} />
              : <S.Visa $dimmed={isDimmed} />}
          </S.Brand>
        </S.Row>
      </S.Card>
    </S.CardLink>
  );
}

CardGridItem.propTypes = cardShape;

export default CardGridItem;
