import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as GridIcon } from 'assets/icons/grid.svg';
import { ReactComponent as ListIcon } from 'assets/icons/list.svg';
import { GRID_VIEW, LIST_VIEW } from 'constants/viewType';

import S from './styles';

function ViewTypeSwitch({ viewType, onChange, className, absolute }) {
  const { t } = useTranslation();
  const content = viewType ===  GRID_VIEW ? (
    <>
      <ListIcon />
      {t('accounts.listView')}
    </>
  ) : (
    <>
      <GridIcon />
      {t('accounts.gridView')}
    </>
  );

  const handleClick = () => {
    const value = viewType === GRID_VIEW ? LIST_VIEW : GRID_VIEW;

    onChange(value);
  };

  return (
    <S.ViewSwitcher className={className} onClick={handleClick} type="button" absolute={absolute}>
      {content}
    </S.ViewSwitcher>
  );
}

ViewTypeSwitch.propTypes = {
  viewType: PropTypes.oneOf([ GRID_VIEW, LIST_VIEW ]),
  onChange: PropTypes.func,
  className: PropTypes.string,
  absolute: PropTypes.bool,
};

export default ViewTypeSwitch;
