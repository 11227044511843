export const permissionsMap = {
  personsManagementRead: 'personsManagement.read',
  personsManagementAssign: 'personsManagement.assign',
  personsManagementCreate: 'personsManagement.create',
  personsManagementDelete: 'personsManagement.delete',
  personsManagementUpdate: 'personsManagement.update',
  otherCardsManagementRead: 'otherCardsManagement.read',
  otherCardsManagementCreate: 'otherCardsManagement.create',
  personsRolesManagementRead: 'personsRolesManagement.read',
  personsRolesManagementCreate: 'personsRolesManagement.create',
  personsRolesManagementDelete: 'personsRolesManagement.delete',
  personsRolesManagementUpdate: 'personsRolesManagement.update',
  othersIndividualQuestionnaireManagementRead: 'othersIndividualQuestionnaireManagement.read',
  transactionsManagementCreateTransfer: 'transactionsManagement.createTransfer',
  transactionsManagementRead: 'transactionsManagement.read',
  transactionsManagementApproveTransfer: 'transactionsManagement.approveTransfer',
  individualCardsManagementRead: 'individualCardsManagement.read',
  individualCardsManagementCreate: 'individualCardsManagement.create',
  individualCardsManagementActivate: 'individualCardsManagement.activate',
  personsOtherKycManagementRead: 'personsOtherKycManagement.read',
  personsOtherKycManagementCreate: 'personsOtherKycManagement.create',
  personsOtherKycManagementDelete: 'personsOtherKycManagement.delete',
  personsOtherKycManagementUpdate: 'personsOtherKycManagement.update',
  corporateWalletsManagementRead: 'corporateWalletsManagement.read',
  corporateWalletsManagementCreate: 'corporateWalletsManagement.create',
  corporateAccountsManagementRead: 'corporateAccountsManagement.read',
  corporateAccountsKycManagementRead: 'corporateAccountsKycManagement.read',
  corporateAccountsKycManagementCreate: 'corporateAccountsKycManagement.create',
  corporateAccountsKycManagementDelete: 'corporateAccountsKycManagement.delete',
  corporateAccountsKycManagementUpdate: 'corporateAccountsKycManagement.update',
  personsIndividualKycManagementRead: 'personsIndividualKycManagement.read',
  personsIndividualKycManagementCreate: 'personsIndividualKycManagement.create',
  personsIndividualKycManagementDelete: 'personsIndividualKycManagement.delete',
  personsIndividualKycManagementUpdate: 'personsIndividualKycManagement.update',
  corporateQuestionnaireManagementRead: 'corporateQuestionnaireManagement.read',
  initialApplicationFormManagementRead: 'initialApplicationFormManagement.read',
  individualQuestionnaireManagementRead: 'individualQuestionnaireManagement.read',
  corporateApiKeysManagementRead: 'corporateApiKeysManagement.read',
  corporateApiKeysManagementCreate: 'corporateApiKeysManagement.create',
  corporateApiKeysManagementUpdate: 'corporateApiKeysManagement.update',
  corporateApiKeysManagementDelete: 'corporateApiKeysManagement.delete',
};

export const permissionDeniedErrorFieldName = 'permissions';
