import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';

import { defaultMetadata, metaTypes, queryTypes } from 'constants/transactions';
import { useFilters } from 'hooks';
import { transactionActivityShape } from 'local-prop-types';
import { getParameterFromURL } from 'utils/helpers';

import S from './styles';

function TransactionPagination({ children, meta, refreshQuery, transactions }) {
  const location = useLocation();
  const history = useHistory();
  const { filters } = useFilters(true);
  const paginationProps = getPaginationProps();

  function getPaginationProps() {
    const limit = getParameterFromURL(metaTypes.limit, location) ?? meta?.limit ?? defaultMetadata[queryTypes.limit];
    const paginationMeta = {
      ...meta,
      limit,
    };

    const firstItemId = transactions[0]?.id || undefined;
    const lastItemId = transactions[transactions.length - 1]?.id || undefined;

    return { enableScrollReset: true, isSticky: true, firstItemId, history, lastItemId, location, paginationMeta };
  }

  useEffect(() => {
    if (typeof refreshQuery !== 'function') {
      return;
    }

    refreshQuery();
  }, [ location, filters.accounts ]);

  return (
    <S.Pagination {...paginationProps}>
      {children}
    </S.Pagination>
  );
}

TransactionPagination.propTypes = {
  children: PropTypes.node,
  meta: PropTypes.shape({ limit: PropTypes.string, hasMore: PropTypes.bool }),
  refreshQuery: PropTypes.func,
  transactions: PropTypes.arrayOf(PropTypes.shape(transactionActivityShape)),
};

export default TransactionPagination;
