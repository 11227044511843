import styled from 'styled-components';

import { ReactComponent as ArrowRightIconComponent } from 'assets/icons/arrow-right.svg';
import { ReactComponent as BulkPaymentsIconComponent } from 'assets/icons/bulk-payments.svg';

const ArrowRightIcon = styled(ArrowRightIconComponent)`
  width: ${props => props.theme.icons.medium};
  height: ${props => props.theme.icons.medium};
`;

const ArrowRightTopIcon = styled(ArrowRightIconComponent)`
  width: ${props => props.theme.icons.medium};
  height: ${props => props.theme.icons.medium};
  transform: rotate(-45deg);
`;

const BulkPaymentsIcon = styled(BulkPaymentsIconComponent)`
  width: ${props => props.theme.icons.medium};
  height: ${props => props.theme.icons.medium};
`;

export default {
  ArrowRightIcon,
  ArrowRightTopIcon,
  BulkPaymentsIcon,
};
