import PropTypes from 'prop-types';
import React, { memo, useEffect, useState } from 'react';

import { formatAmount } from 'utils/amount';
import { transactionType } from 'constants/transactions';

import S from './styles';

function FeesBlock({
  type,
  totalFee,
  sourceAmount,
  targetAmount,
  targetCurrency,
  sourceCurrency,
  externalFee,
  exchangeRate,
  isFeeTransaction,
  isIncomingTransaction,
  isMultiCurrencyTransaction,
  netTransactionAmount,
  showNetAmount,
  isApiPayment,
}) {
  const [ readyToBeRendered, setReadyToBeRendered ] = useState(false);
  const [ feeAmount, setFeeAmount ] = useState();
  const [ netAmount, setNetAmount ] = useState();
  const [ externalFeeAmount, setExternalFeeAmount ] = useState();
  const [ feeCurrency, setFeeCurrency ] = useState();

  useEffect(() => {
    recalculateFees();
    recalculateNetAmount();

    setReadyToBeRendered(true);
  }, []);

  useEffect(() => {
    recalculateFees();
  }, [ isIncomingTransaction, totalFee, externalFee, targetCurrency, sourceCurrency ]);

  useEffect(() => {
    recalculateNetAmount();
  }, [ netTransactionAmount, sourceCurrency ]);

  function recalculateFees() {
    const currencyFee = isIncomingTransaction ? targetCurrency : sourceCurrency;
    setFeeCurrency(currencyFee);
    setFeeAmount(formatAmount(totalFee, currencyFee));
    setExternalFeeAmount(formatAmount(externalFee, currencyFee));
  }

  function recalculateNetAmount() {
    setNetAmount(`${formatAmount(netTransactionAmount)} ${sourceCurrency}`);
  }

  if (!readyToBeRendered) return null;

  return (
    <S.FeesWrapper >
      <S.Grid isHidden={!showNetAmount}>
        <S.Label>Excluding fees</S.Label>
        <S.Detail>
          {netAmount}
        </S.Detail>
      </S.Grid>

      <S.Section isHidden={type !== transactionType.fx}>
        <S.Grid>
          <S.Detail>Sent</S.Detail>
          <S.Detail>
            {sourceAmount}
            {' '}
            {sourceCurrency}
          </S.Detail>
        </S.Grid>

        <S.Grid>
          <S.Detail>Received</S.Detail>
          <S.Detail>
            $
            {targetAmount}
            {' '}
            $
            {targetCurrency}
          </S.Detail>
        </S.Grid>
      </S.Section>

      <S.Grid isHidden={isFeeTransaction || (isIncomingTransaction && isMultiCurrencyTransaction)}>
        <S.Label>Fees</S.Label>
        <S.Detail>
          {feeAmount}
          {' '}
          {feeCurrency}
        </S.Detail>
      </S.Grid>

      <S.Grid isHidden={externalFee === 0 || isApiPayment}>
        <S.Label>Third party charges</S.Label>
        <S.Detail>
          {externalFeeAmount}
          {' '}
          {feeCurrency}
        </S.Detail>
      </S.Grid>

      <S.Grid isHidden={!exchangeRate || isIncomingTransaction || isApiPayment}>
        <S.Label>Exchange rate</S.Label>
        <S.Detail>
          {exchangeRate}
        </S.Detail>
      </S.Grid>
    </S.FeesWrapper>
  );
}

FeesBlock.propTypes = {
  type: PropTypes.string.isRequired,
  totalFee: PropTypes.number.isRequired,
  netTransactionAmount: PropTypes.number.isRequired,
  sourceAmount: PropTypes.number.isRequired,
  targetAmount: PropTypes.number.isRequired,
  sourceCurrency: PropTypes.string.isRequired,
  targetCurrency: PropTypes.string.isRequired,
  externalFee: PropTypes.number,
  exchangeRate: PropTypes.string,
  isFeeTransaction: PropTypes.bool.isRequired,
  isIncomingTransaction: PropTypes.bool.isRequired,
  isMultiCurrencyTransaction: PropTypes.bool.isRequired,
  isApiPayment: PropTypes.bool,
  showNetAmount: PropTypes.bool,
};

export default memo(FeesBlock);
