import { ErrorCodes } from '@general/error-codes';
import { t } from 'i18next';
import get from 'lodash/get';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import omit from 'lodash/omit';

import { adapters } from 'constants/payment-processor';
import { fxCurrenciesMap } from 'constants/fx';
import getRate from 'api/fx';
import {
  AMOUNT_IS_TOO_SMALL_ERROR_MESSAGE,
  BUY_FX_SIDE,
  CONVERSION_BELOW_LIMIT_ERROR_CODE,
  exchangeRateFieldName,
  fromAccountFieldName,
  MIN_AMOUNT_TO_GET_RATE,
  moveAmountFieldName,
  POLL_TIMEOUT,
  receiveAmountFieldName,
  SELL_FX_SIDE,
  toAccountFieldName,
} from 'pages/move-funds/constants';
import { getAmount, reverseFormatNumber, toIndivisibleUnit, toMainUnit } from 'utils/amount';
import { errorsConsistOneOfErrorCodes } from 'utils/errors';
import { isFxWeekend } from 'utils/fx';

export function accountsHaveDifferentCurrency(formValues) {
  const { fromAccount, toAccount } = formValues;

  const fromCurrency = fromAccount?.currency;
  const toCurrency = toAccount?.currency;

  return (
    !(isNil(fromCurrency) || isNil(toCurrency)) &&
      fromCurrency !== toCurrency
  );
}

function mapRate(formValues) {
  const {
    exchangeRate: { sellCurrency, buyCurrency },
  } = formValues;
  const rate = Number(parseFloat(formValues?.exchangeRate?.rate || 0).toFixed(4));

  return shouldShowRate(formValues)
    ? {
        label: t('moveFunds.totalContainer.exchangeRate'),
        value: `1 ${sellCurrency} = ${rate} ${buyCurrency}`,
        hintText: t('moveFunds.totalContainer.hintText'),
      }
    : {};
}

function shouldShowRate(formValues) {
  const { exchangeRate } = formValues;

  return !isEmpty(exchangeRate) && accountsHaveDifferentCurrency(formValues);
}

function getTotal(formValues) {
  return {
    label: 'Total',
    value: `${getTotalAmount(formValues)} ${getTotalCurrency(formValues)}`,
  };
}

function getTotalCurrency(formValues) {
  return get(formValues, 'fromAccount.currency', '');
}

function getTotalAmount(formValues) {
  const unformattedAmount = reverseFormatNumber(formValues[moveAmountFieldName]);

  return getAmount(unformattedAmount);
}

export function getRateAndTotal(formValues) {
  return {
    [exchangeRateFieldName]: isEmpty(formValues[exchangeRateFieldName])
      ? undefined
      : mapRate(formValues),
    total: getTotal(formValues),
  };
}

export function getConfirmationFromBoxContent(formValues, accountTitleComponent) {
  const {
    fromAccount,
    toAccount,
    fromAccount: { currency: fromCurrency, name: fromName },
    toAccount: { currency: toCurrency, name: toName },
  } = formValues;

  return {
    from: {
      label: 'From',
      value: fromAccount && accountTitleComponent({
        currency: fromCurrency,
        name: fromName,
        isLarger: false,
      }),
    },
    to: {
      label: 'To',
      value: toAccount && accountTitleComponent({
        currency: toCurrency,
        name: toName,
        isLarger: false,
      }),
    },
    [exchangeRateFieldName]: isEmpty(formValues[exchangeRateFieldName])
      ? undefined
      : mapRate(formValues),
    total: getTotal(formValues),
  };
}

export function sanitizePolls(currentPollId, removeAll = false) {
  let pollIdToClear = currentPollId - (removeAll ? 0 : 1);
  while (pollIdToClear) {
    clearTimeout(pollIdToClear);
    pollIdToClear -= 1;
  }
}

export function updatePoll(context) {
  return runUpdates(context).then(startPoll);
}

function runUpdates(context) {
  return Promise.resolve(maybeUpdateRate(context)).then(evaluateUpdateAccounts);
}

async function maybeUpdateRate(context) {
  const { formValues, fxSide, setAmountsByRate, setRate } = context;

  if (
    fxSide &&
      hasCorrectFxAmount(formValues, fxSide) &&
      accountsHaveDifferentCurrency(formValues) &&
      hasValidAmount(context) &&
      !isFxWeekend()
  ) {
    const rate = await getExchangeRate(context);
    setAmountsByRate(rate);
    setRate(rate);
  }

  return context;
}

async function evaluateUpdateAccounts(context) {
  const { updateAccounts, setSelectedAccounts } = context;
  const accounts = await updateAccounts();
  setSelectedAccounts(accounts);

  return context;
}

function hasCorrectFxAmount(formValues, fxSide) {
  const { moveAmount, receiveAmount } = formValues;

  return Boolean(fxSide === BUY_FX_SIDE ? receiveAmount : moveAmount);
}

function startPoll(context) {
  const { setCurrentPollId } = context;
  const currentPollId = setTimeout(() => runUpdates(context).then(startPoll),
    POLL_TIMEOUT);
  sanitizePolls(currentPollId);
  setCurrentPollId(currentPollId);
}

function hasValidAmount(context) {
  const { errors, fxSide } = context;

  return (
    isEmpty(errors) ||
    !has(errors,
      fxSide === BUY_FX_SIDE
        ? receiveAmountFieldName
        : moveAmountFieldName)
  );
}

async function getExchangeRate(context) {
  const { formValues, fxSide } = context;
  const { fromAccount, toAccount, moveAmount, receiveAmount } = formValues;

  const fixedAmount = fxSide === BUY_FX_SIDE ? receiveAmount : moveAmount;
  const fixedCurrency = fxSide === BUY_FX_SIDE ? toAccount.currency : fromAccount.currency;
  const amountInNumber = Number(reverseFormatNumber(fixedAmount));
  const amount = toIndivisibleUnit(amountInNumber, fixedCurrency);

  const query = {
    sellCurrency: fromAccount.currency,
    buyCurrency: toAccount.currency,
    fixedSide: fxSide,
    amount,
  };
  const isDifferentCurrenciesSetUp =
    fromAccount.currency &&
    toAccount.currency &&
    accountsHaveDifferentCurrency(formValues);

  try {
    return isDifferentCurrenciesSetUp ? await getRateAndFormatAmount(query) : ({});
  } catch (error) {
    await handleGetRateExceptions({ ...context, fixedCurrency })(error);
  }

  return null;
}

function handleGetRateExceptions(context) {
  return rateException =>
    Promise.resolve(maybeSetAmountIsTooSmallError({ ...context, rateException: rateException.response }));
}

function maybeSetAmountIsTooSmallError(context) {
  const { rateException, setFieldError, fxSide } = context;
  const errors = get(rateException, 'message.errors', []);
  const errorFieldName =
      fxSide === BUY_FX_SIDE ? receiveAmountFieldName : moveAmountFieldName;
  const isTooSmallError = error =>
    error.code === ErrorCodes.amountTooSmall;

  errors.every(error =>
    isTooSmallError(error) &&
          setFieldError(errorFieldName, AMOUNT_IS_TOO_SMALL_ERROR_MESSAGE));

  return context;
}

export function preparePollContext(
  formValues,
  fxSide,
  setFieldValue,
  updateAccounts,
  currentPollId,
  setCurrentPollId,
  setFieldError,
  errors,
) {
  const setAmount = async (fieldName, value) => {
    value && await setFieldValue(fieldName, value);
  };
  const setAmountsByRate = rate => formValues[fromAccountFieldName] &&
      setAmount(moveAmountFieldName, rate?.sellAmount) &&
      setAmount(receiveAmountFieldName, rate?.buyAmount);

  const setRate = rate =>
    rate?.rate && setFieldValue(exchangeRateFieldName, rate);

  const setSelectedAccount = (accountFieldName, accounts) => {
    const accountId = get(formValues, `${accountFieldName}.id`);
    const selectedAccount = accounts.find(account => account && account.id === accountId);
    if (selectedAccount) {
      setFieldValue(accountFieldName, selectedAccount);
    }
  };

  const setSelectedAccounts = accounts => {
    if (!accounts) return;

    setSelectedAccount(toAccountFieldName, accounts);
    setSelectedAccount(fromAccountFieldName, accounts);
  };

  return {
    formValues,
    fxSide,
    setAmountsByRate,
    setRate,
    updateAccounts,
    currentPollId,
    setCurrentPollId,
    setSelectedAccounts,
    setFieldError,
    errors,
  };
}

export function getDefaultExchangeRate(formValues) {
  const context = {
    formValues: { ...formValues, moveAmount: MIN_AMOUNT_TO_GET_RATE },
    fxSide: SELL_FX_SIDE,
  };

  return getExchangeRate(context);
}

/**
 * @param {ApiError} error
 * @return {boolean}
 */
export function shouldRedirectToAmountError(error) {
  const errorCodes = [
    ErrorCodes.insufficientFunds,
    ErrorCodes.rateChangedTooMuch,
    ErrorCodes.amountTooSmall,
    CONVERSION_BELOW_LIMIT_ERROR_CODE,
  ];

  return errorsConsistOneOfErrorCodes(error, errorCodes);
}

export function getTransactionAmount(formValues, fixedSide) {
  const { moveAmount, receiveAmount } = formValues;
  const amount = fixedSide === BUY_FX_SIDE ? receiveAmount : moveAmount;

  return Number(reverseFormatNumber(amount));
}

export function getReceiveAmount(formValues) {
  const receiveAmount = formValues[receiveAmountFieldName] || 0;
  const moveAmount = formValues[moveAmountFieldName] || 0;
  const currency = formValues[toAccountFieldName]?.currency || '';

  const amount = accountsHaveDifferentCurrency(formValues) ? receiveAmount : moveAmount;

  return `${amount} ${currency}`;
}

export function prepareMoveFundsRequest(formValues, fxSide) {
  const {
    fromAccount: { id: sourceAccountId },
    toAccount: { id: targetAccountId, currency },
  } = formValues;
  const fixedSide = accountsHaveDifferentCurrency(formValues) ? fxSide : null;

  const amount = toIndivisibleUnit(getTransactionAmount(formValues, fixedSide), currency);
  const transaction = {
    amount,
    currency,
    fixedSide,
    sourceAccountId,
    targetAccountId,
  };

  return accountsHaveDifferentCurrency(formValues)
    ? transaction
    : omit(transaction, ['fixedSide']);
}

export function filterSelectedAccountOptions(selectedAccount, accountOptions) {
  return accountOptions.filter(option => selectedAccount?.id !== option.value.id);
}

export function mapActiveOptions(selectedAccount, accountOptions) {
  return accountOptions.map(option => {
    const optionValue = option?.value;

    const samePaymentProcessor = selectedAccount?.paymentProcessorAdapter !== optionValue.paymentProcessorAdapter;
    const sameCurrency = selectedAccount?.currency === optionValue.currency;

    const eurAccountSelected = selectedAccount?.currency === fxCurrenciesMap.EUR;
    const boLAccountSelected = selectedAccount?.paymentProcessorAdapter === adapters.bol;
    const sparkAccountSelected = selectedAccount?.paymentProcessorAdapter === adapters.spark;

    const isNotSameCurrencyBol = boLAccountSelected && !sameCurrency;
    const isNonEuroSparkSelected = sparkAccountSelected && !eurAccountSelected && samePaymentProcessor;

    return isNotSameCurrencyBol || isNonEuroSparkSelected
      ? { ...option, isDisabled: true }
      : option;
  });
}

export const checkOptionIncludesValue = (option, str) => {
  const value = option?.value?.name;

  if (!value) return false;

  const caseInsensitiveValue = value.toLowerCase();
  const caseInsensitiveSearchString = str.toLowerCase();

  return caseInsensitiveValue.includes(caseInsensitiveSearchString);
};

// Private functions

async function getRateAndFormatAmount(query) {
  const { buyAmount, sellAmount, ...rate } = await getRate(query);

  return {
    ...rate,
    buyAmount: toMainUnit(buyAmount),
    sellAmount: toMainUnit(sellAmount),
  };
}
