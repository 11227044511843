import PropTypes from 'prop-types';
import React, { memo, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { EmptyState, TransactionList, TransactionPagination } from 'components';
import { defaultMetadata, metaTypes, queryTypes } from 'constants/transactions';
import { TransactionContext } from 'contexts/transaction';
import { useFilterDates, useTransactionList } from 'hooks';
import { filterNames } from 'pages/activity/constants';
import { getParameterFromURL } from 'utils/helpers';
import { groupTransactionsByDate } from 'utils/transactions';

import S from './styles';

let currentMeta = {};
function Transactions({ accountId }) {
  const { date } = useFilterDates();
  const pageQuery = getPageQuery();
  const { isLoading, meta } = useTransactionList(pageQuery);
  currentMeta = meta;
  const { isSidebarOpen, transactions } = useContext(TransactionContext);

  function getPageQuery() {
    const filters = {
      [filterNames.date.general]: date,
      [filterNames.accounts]: [{ value: accountId, checked: true }],
    };
    const limit = getParameterFromURL(metaTypes.limit, window.location);
    const startingAfter = getParameterFromURL(metaTypes.startingAfter, window.location);
    const endingBefore = getParameterFromURL(metaTypes.endingBefore, window.location);

    return {
      filters,
      [metaTypes.general]: {
        ...defaultMetadata,
        ...currentMeta,
        ...(limit && { [queryTypes.limit]: limit }),
        ...(startingAfter && { [queryTypes.startingAfter]: startingAfter }),
        ...(endingBefore && { [queryTypes.endingBefore]: endingBefore }),
      },
    };
  }

  function renderTransactions() {
    if (!isLoading && transactions.length === 0) {
      return <EmptyState title="There is no activity to display yet." />;
    }

    const groupedTransactionsByDate = Object.entries(groupTransactionsByDate(transactions));

    return (
      <TransactionPagination transactions={transactions} meta={meta}>
        <div data-testid="transactions-all-activity-wrapper">
          {groupedTransactionsByDate.map(([ transactionDate, activities ]) => (
            <TransactionList
              key={uuidv4()}
              date={transactionDate}
              activities={activities}
            />
          ))}
        </div>
      </TransactionPagination>
    );
  }

  return (
    <S.TransactionsWrapper isSidebarOpen={isSidebarOpen}>
      {renderTransactions()}
    </S.TransactionsWrapper>
  );
}

Transactions.propTypes = {
  accountId: PropTypes.string,
};

export default memo(Transactions);
