import React, { useContext, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { getAccounts } from 'api/accounts';
import { showGeneralToastError } from 'utils/errors';
import { navigation } from 'constants/navigation';
import { GlobalLoaderContext } from 'contexts/global-loader';
import { ViewContext } from 'contexts/view';
import { WizardLayout } from 'layouts';
import AmountForm from 'pages/send-funds/components/amount-form';
import BeneficiaryForm from 'pages/send-funds/components/beneficiary-form';
import RecentBeneficiaries from 'pages/send-funds/components/recent-beneficiaries';
import SendFundsConfirmation from 'pages/send-funds/components/send-funds-confirmation';
import SendFundsFinal from 'pages/send-funds/components/send-funds-final';
import {
  AMOUNT_FORM_INITIAL_STATE,
  BENEFICIARY_FORM_INITIAL_STATE,
  EXCLUDED_PAYMENT_PROCESSOR_ADAPTERS,
  FROM_ACCOUNT_ID,
  SEND_FUNDS_AMOUNT_FORM,
  SEND_FUNDS_BENEFICIARY_FORM,
  SEND_FUNDS_CONFIRMATION,
  SEND_FUNDS_FINAL,
  SEND_FUNDS_RECENT_BENEFICIARIES,
} from 'pages/send-funds/constants';
import { useBeneficiaries } from 'hooks';

function SendFunds() {
  const history = useHistory();
  const { id: accountId } = useParams();
  const { beneficiaries } = useBeneficiaries();
  const beneficiaryId = useMemo(() =>
    new URLSearchParams(history.location.search).get('beneficiaryId'), [history.location.search]);
  const { startLoading, endLoading } = useContext(GlobalLoaderContext);
  const [ accounts, setAccounts ] = useState([]);
  const [ predefinedValues, setPredefinedValues ] = useState({
    [FROM_ACCOUNT_ID]: accountId,
  });
  const [ currentView, setCurrentView ] = useState(beneficiaryId
    ? SEND_FUNDS_BENEFICIARY_FORM : SEND_FUNDS_RECENT_BENEFICIARIES);
  const [ selectedBeneficiary, setSelectedBeneficiary ] = useState({});
  const [ createdTransaction, setCreatedTransaction ] = useState();
  const [ submissionErrors, setSubmissionErrors ] = useState({});
  const [ layoutProps, setLayoutProps ] = useState({
    title: 'Send funds',
    displayCancelButton: true,
  });
  const [ beneficiaryFormValues, setBeneficiaryFormValues ] = useState(BENEFICIARY_FORM_INITIAL_STATE);
  const [ amountFormValues, setAmountFormValues ] = useState(AMOUNT_FORM_INITIAL_STATE);

  const updateAccounts = async () => {
    startLoading();
    try {
      const fetchedAccounts = await getAccounts();
      const filteredAccounts = fetchedAccounts.filter(account =>
        !EXCLUDED_PAYMENT_PROCESSOR_ADAPTERS.includes(account?.paymentProcessorAdapter));

      setAccounts(filteredAccounts);
    } catch (error) {
      showGeneralToastError(error);
    } finally {
      endLoading();
    }
  };

  const onBeneficiarySelect = beneficiary => {
    setSelectedBeneficiary(beneficiary);
    setCurrentView(SEND_FUNDS_BENEFICIARY_FORM);
  };

  const getPageContent = () => {
    switch (currentView) {
      case SEND_FUNDS_RECENT_BENEFICIARIES:
        return (
          <RecentBeneficiaries
            setCurrentView={setCurrentView}
            onBeneficiarySelect={onBeneficiarySelect}
          />
        );
      case SEND_FUNDS_BENEFICIARY_FORM:
        return (
          <BeneficiaryForm
            setCurrentView={setCurrentView}
            updateAccounts={updateAccounts}
            accounts={accounts}
            beneficiaries={beneficiaries}
            beneficiaryFormValues={beneficiaryFormValues}
            setBeneficiaryFormValues={setBeneficiaryFormValues}
            selectedBeneficiary={selectedBeneficiary}
            setSelectedBeneficiary={setSelectedBeneficiary}
            resetAmountForm={() =>
              setAmountFormValues(AMOUNT_FORM_INITIAL_STATE)
            }
          />
        );
      case SEND_FUNDS_AMOUNT_FORM:
        return (
          <AmountForm
            setCurrentView={setCurrentView}
            accounts={accounts}
            updateAccounts={updateAccounts}
            beneficiaryFormValues={beneficiaryFormValues}
            amountFormValues={amountFormValues}
            setAmountFormValues={setAmountFormValues}
            submissionErrors={submissionErrors}
            predefinedValues={predefinedValues}
            setPredefinedValues={setPredefinedValues}
          />
        );
      case SEND_FUNDS_CONFIRMATION:
        return (
          <SendFundsConfirmation
            setCurrentView={setCurrentView}
            beneficiaryFormValues={beneficiaryFormValues}
            amountFormValues={amountFormValues}
            setAmountFormValues={setAmountFormValues}
            setCreatedTransaction={setCreatedTransaction}
            updateAccounts={updateAccounts}
            setSubmissionErrors={setSubmissionErrors}
          />
        );
      case SEND_FUNDS_FINAL:
        return (
          <SendFundsFinal
            createdTransaction={createdTransaction}
            beneficiaryFormValues={beneficiaryFormValues}
            setLayoutProps={setLayoutProps}
          />
        );
      default:
        return null;
    }
  };

  const handleBackFromBeneficiariesForm = () => {
    const redirectIfBeneficiariesHaveLength = () => beneficiaries.length
      ? setCurrentView(SEND_FUNDS_RECENT_BENEFICIARIES) :
      history.push(navigation.accounts);

    beneficiaryId
      ? history.push(`${navigation.beneficiaries}/${beneficiaryId}`)
      : redirectIfBeneficiariesHaveLength();
  };

  const triggerView = view => {
    switch (view) {
      case SEND_FUNDS_RECENT_BENEFICIARIES:
      case SEND_FUNDS_FINAL:
        return history.push(navigation.accounts);
      case SEND_FUNDS_BENEFICIARY_FORM:
        return handleBackFromBeneficiariesForm();
      case SEND_FUNDS_AMOUNT_FORM:
        return setCurrentView(SEND_FUNDS_BENEFICIARY_FORM);
      case SEND_FUNDS_CONFIRMATION:
        return setCurrentView(SEND_FUNDS_AMOUNT_FORM);
      default:
        return {};
    }
  };

  const viewValues = useMemo(() => ({ currentView, triggerView }), [currentView]);

  return (
    <div>
      <ViewContext.Provider value={viewValues}>
        <WizardLayout {...layoutProps}>
          {getPageContent()}
        </WizardLayout>
      </ViewContext.Provider>
    </div>
  );
}

export default SendFunds;
