import debounce from 'lodash/debounce';
import { useEffect } from 'react';

function useWindowHeight() {
  const DEBOUNCE_TIME = 200;
  const VH_FACTOR = 0.01;

  const setVh = () => {
    const vh = window.innerHeight * VH_FACTOR;

    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  useEffect(() => {
    const debouncedFn = debounce(setVh, DEBOUNCE_TIME);

    setVh();
    window.addEventListener('resize', debouncedFn, { passive: true });

    return () => {
      window.removeEventListener('resize', debouncedFn);
    };
  }, []);
}

export default useWindowHeight;
