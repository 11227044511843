import PropTypes from 'prop-types';
import { useContext } from 'react';

import { UserContext } from 'contexts/user';
import { permit, permitByModifiers } from 'utils/permissions';

function Permit({ permissions, modifiers, atLeastOne, children }) {
  const { tokenPayload: {
    permissions: userPermissions,
    permissionModifiers: userPermissionModifiers,
  } } = useContext(UserContext);

  if (!permissions) return children;

  const isPermittedByModifiers = modifiers
    ? permitByModifiers(permissions, modifiers, userPermissionModifiers)
    : true;

  const isPermitted = permit(permissions, userPermissions, atLeastOne);

  return isPermitted && isPermittedByModifiers ? children : null;
}

Permit.propTypes = {
  children: PropTypes.node.isRequired,
  permissions: PropTypes.instanceOf(Array),
  modifiers: PropTypes.instanceOf(Object),
  atLeastOne: PropTypes.bool,
};

export default Permit;
