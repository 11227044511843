import { Link as DefaultLink } from 'react-router-dom';
import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';

const PaymentsWrapper = styled.div`
  transition: ${props => props.theme.transition};
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;

  @media ${DEVICE.laptop} {
    height: 100%;
    ${props => (props.isSidebarOpen ? 'width: calc(100% - 23rem)' : '')};
  }
`;

const Content = styled.div`
  flex: 1;
`;

const Link = styled(DefaultLink)`
  color: ${props => props.theme.colors.blue};
`;

export default {
  PaymentsWrapper,
  Content,
  Link,
};
