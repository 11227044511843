import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';
import { hex2rgba } from 'helpers/styles';

// TODO move to common styles.
// Start
export const TransactionLabel = styled.span`
  color: ${props => props.theme.colors.greyMedium};
  font: ${props => props.theme.fonts.bodyS};
`;

export const TransactionSubHeading = styled(TransactionLabel)`
  font: ${props => props.theme.fonts.bodyXs};
  line-height: 1.25rem;
`;

export const TransactionHighlighted = styled.strong`
  color: ${props => props.theme.colors.black};
  font-weight: 500;
`;
// End

const TransactionActivitiesHeading = styled.h2`
  border-bottom: 0.0625rem solid ${props => hex2rgba(props.theme.colors.black, 0.1)};
  font: ${props => props.theme.fonts.bodySSemi};
  margin: 1.25rem auto 0 auto;
  max-width: calc(100% - 2.5rem);
  padding: 0 0 0.625rem;
  width: 100%;

  @media ${DEVICE.tablet} {
    border-bottom: none;
    max-width: 100%;
  }
`;

export default { TransactionActivitiesHeading };
