import { useContext, useEffect, useState } from 'react';

import { showGeneralToastError } from 'utils/errors';
import getCompanyDetails from 'api/company';
import { GlobalLoaderContext } from 'contexts/global-loader';

const useCompanyDetails = () => {
  const { startLoading, endLoading } = useContext(GlobalLoaderContext);
  const [ companyDetails, setCompanyDetails ] = useState({});

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      startLoading();

      try {
        const data = await getCompanyDetails();

        setCompanyDetails(data);
      } catch (error) {
        showGeneralToastError(error);
      } finally {
        endLoading();
      }
    };

    fetchCompanyDetails();
  }, []);

  return { companyDetails };
};

export default useCompanyDetails;
