import styled from 'styled-components';

const Badge = styled.div`
  align-items: center;
  background-color: ${props => props.theme.colors.red};
  border-radius: 0.625rem;
  color: ${props => props.theme.colors.white};
  display: flex;
  justify-content: center;
  padding: 0.125rem 0.625rem;
  min-width: 1.25rem;
  max-height: 1.25rem;
`;

const Indicator = styled.span`
  font: ${props => props.theme.fonts.bodyXsSemi};
`;

export default { Badge, Indicator };
