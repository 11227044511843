import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import { TransactionContext } from 'contexts/transaction';

import S from './styles';

function TransactionCheckboxGroup({ className, groupIcon, groupName, list = [], onChange, onSelectAll, isDesktop }) {
  const { activeTransaction } = useContext(TransactionContext);
  const [ isAllSelected, setIsAllSelected ] = useState(getIsSelectedAllStatus());
  const [ isIntermediate, setIsIntermediate ] = useState(false);

  function getIsNotSelectedAllStatus() {
    return list.every(element => !element.checked);
  }

  function getIsSelectedAllStatus() {
    return list.every(element => element.checked);
  }

  useEffect(() => {
    const isNotSelectedAll = getIsNotSelectedAllStatus();
    const isAllStatusesSelected = getIsSelectedAllStatus();

    setIsAllSelected(isAllStatusesSelected);
    setIsIntermediate(!isAllStatusesSelected && !isNotSelectedAll);
  }, [list]);

  return (
    <div className={className}>
      <S.CheckboxGroupSelectAllWrapper data-testid="transaction-checkbox-group-select-all">
        {!isDesktop ? (
          <S.GroupNameWrapper>
            {groupName}
          </S.GroupNameWrapper>
        ) : (
          <S.Checkbox
            onChange={onSelectAll}
            checked={isAllSelected}
            intermediate={isIntermediate}
            isInverse
            isGroupLabel
          >
            <S.GroupNameWrapper>
              {groupName}
            </S.GroupNameWrapper>
          </S.Checkbox>
        )}
        <S.GroupIconWrapper>
          {groupIcon}
        </S.GroupIconWrapper>
      </S.CheckboxGroupSelectAllWrapper>

      <div data-testid="transaction-checkbox-group-list">
        {list?.map(({ label, value, id, checked }) => (
          <S.CheckboxSingleWrapper
            isActive={id === activeTransaction?.id}
            key={id}
            data-testid="transaction-checkbox-group-single-checkbox"
          >
            {isDesktop && (
              <S.Checkbox
                checked={checked}
                id={id}
                onChange={onChange}
                value={value}
                isInverse
              />
            )}
            {label}
          </S.CheckboxSingleWrapper>
        ))}
      </div>
    </div>
  );
}

TransactionCheckboxGroup.propTypes = {
  className: PropTypes.string,
  groupName: PropTypes.node,
  groupIcon: PropTypes.node,
  list: PropTypes.arrayOf(PropTypes.shape({
    checked: PropTypes.bool,
    id: PropTypes.string,
    label: PropTypes.oneOfType([ PropTypes.string, PropTypes.node ]),
    value: PropTypes.oneOfType([ PropTypes.string, PropTypes.arrayOf ]),
  })),
  onChange: PropTypes.func,
  onSelectAll: PropTypes.func,
  isDesktop: PropTypes.bool,
};

export default TransactionCheckboxGroup;
