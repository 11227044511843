import styled from 'styled-components';

import { ReactComponent as DefaultInfoIcon } from 'assets/icons/info.svg';

const Title = styled.p`
  font: ${props => props.theme.fonts.h3Bold};
  margin: 0 0 1.25rem;
`;

const InputWrapper = styled.div`
  width: 100%;
  max-width: 30rem;
  margin-bottom: 1.25rem;
`;

const AlertBlockWrapper = styled.div`
  width: 100%;
  max-width: 30rem;
  margin-bottom: 2.5rem;
`;

const InfoIcon = styled(DefaultInfoIcon)`
  width: 2rem;
  height: 2rem;
  fill: ${props => props.theme.colors.blue};
`;

const NotificationContent = styled.div`
    width: 100%;
`;

const NotificationSection = styled.p`
  font: ${props => props.theme.fonts.bodySBold}; 
  margin: .3rem 0;
`;

export default {
  Title,
  InputWrapper,
  AlertBlockWrapper,
  InfoIcon,
  NotificationContent,
  NotificationSection,
};
