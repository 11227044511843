import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { getDeliveryAddress } from 'pages/order-cards/utils';
import { formatExpirationDate } from 'utils/card';
import { Dash, Dot } from 'components';
import { orderCardShape } from 'local-prop-types';
import { CURRENCIES, CURRENCY_NAMES } from 'constants/currencies';

import S from './styles';
import { cardCreationStatusMap, tableRows } from '../../constants';

function CardOrderTable({
  orderCardData,
  handleRowClick,
  selectedCard,
  account,
  onEdit,
  onDelete,
  isVirtual,
}) {
  const { t } = useTranslation();

  const getCardHolderLabel = cardHolder => cardHolder?.label || `${cardHolder?.firstName} ${cardHolder?.lastName}`;

  const mapCardsStatusLabel = status => {
    switch (status) {
      case cardCreationStatusMap.fail:
        return t('orderCards.summary.submitted.notIssued');
      case cardCreationStatusMap.success:
        return t('orderCards.summary.submitted.issued');
      default:
        return null;
    }
  };

  return (
    <S.Table>
      <S.Header>
        <S.Section name={tableRows.name} isVirtual={isVirtual}>
          <S.HeaderLabel>
            {t('orderCards.cardDetails.table.name')}
          </S.HeaderLabel>
        </S.Section>
        <S.Section name={tableRows.cardName} isVirtual={isVirtual}>
          <S.HeaderLabel>
            {t('orderCards.cardDetails.table.cardName')}
          </S.HeaderLabel>
        </S.Section>
        {!isVirtual && (
          <S.Section name={tableRows.deliverTo}>
            <S.HeaderLabel>
              {t('orderCards.cardDetails.table.deliverTo')}
            </S.HeaderLabel>
          </S.Section>
        )}
        <S.Section name={tableRows.dueDate} isVirtual={isVirtual}>
          <S.HeaderLabel>
            {orderCardData?.[0]?.status ? t('orderCards.cardDetails.table.status') : t('orderCards.cardDetails.table.dueDate')}
          </S.HeaderLabel>
        </S.Section>
      </S.Header>
      <S.Content>
        {orderCardData?.map((card, idx) => (
          <React.Fragment key={card.id}>
            <S.Row>
              <S.RowContent
                isLastChild={idx === orderCardData.length - 1}
                isSelected={card?.id === selectedCard?.id}
                isUnclickable={!handleRowClick}
                onClick={() => handleRowClick?.(card)}
              >
                <S.Section name={tableRows.name} isVirtual={isVirtual}>
                  <S.Avatar
                    name={getCardHolderLabel(card.cardHolder)}
                    small
                  />
                  <S.SectionColumn>
                    <S.SectionValue>
                      <S.SectionName>
                        {getCardHolderLabel(card.cardHolder)}
                      </S.SectionName>
                    </S.SectionValue>
                    <S.SectionValue>
                      <S.AccountDetails>
                        {CURRENCY_NAMES[account.currency]}
                        <Dot />
                        {account.displayName}
                      </S.AccountDetails>
                    </S.SectionValue>
                  </S.SectionColumn>
                </S.Section>
                <S.Section name={tableRows.cardName} isVirtual={isVirtual}>
                  <S.SectionValue>
                    {card.displayName || <Dash />}
                  </S.SectionValue>
                </S.Section>
                {!isVirtual && (
                  <S.Section name={tableRows.deliverTo}>
                    <S.SectionValue>
                      {getDeliveryAddress(card.address)}
                    </S.SectionValue>
                  </S.Section>
                )}
                <S.Section name={tableRows.dueDate} isVirtual={isVirtual}>
                  {card.status ? (
                    <S.Badge isSuccess={card.status === cardCreationStatusMap.success}>
                      {mapCardsStatusLabel(card.status)}
                    </S.Badge>
                ) : formatExpirationDate(card.expiry)}
                </S.Section>
              </S.RowContent>
              {handleRowClick && onDelete && onEdit && (
                <S.Actions>
                  <S.EditIcon onClick={() => onEdit(card)} />
                  <S.DeleteIcon onClick={() => onDelete(card.id)} />
                </S.Actions>
              )}
            </S.Row>
            <S.MobileRow onClick={() => handleRowClick?.(card)} isLastChild={idx === orderCardData.length - 1}>
              <S.Avatar
                name={getCardHolderLabel(card.cardHolder)}
                small
              />
              <S.MobileContent>
                <S.MobileTitle>
                  {getCardHolderLabel(card.cardHolder)}
                </S.MobileTitle>
                <S.MobileSection>
                  <S.MobileSectionRow>
                    {card.status && (
                      <>
                        <S.Badge isSuccess={card.status === cardCreationStatusMap.success}>
                          {mapCardsStatusLabel(card.status)}
                        </S.Badge>
                        <Dot />
                      </>
                    )}
                    {card.displayName && (
                      <>
                        {card.displayName}
                        <Dot />
                      </>
                    )}
                    {formatExpirationDate(card.expiry)}
                  </S.MobileSectionRow>
                </S.MobileSection>
                {!isVirtual && (
                  <S.MobileSection>
                    {getDeliveryAddress(card.address)}
                  </S.MobileSection>
                )}
              </S.MobileContent>
            </S.MobileRow>
          </React.Fragment>
        ))}
      </S.Content>
    </S.Table>
  );
}

CardOrderTable.propTypes = {
  orderCardData: PropTypes.arrayOf(orderCardShape),
  handleRowClick: PropTypes.func,
  selectedCard: orderCardShape,
  account: PropTypes.shape({
    currency: PropTypes.oneOf(Object.values(CURRENCIES)),
    displayName: PropTypes.string,
  }),
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  isVirtual: PropTypes.bool,
};

export default CardOrderTable;
