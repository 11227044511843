import PropTypes from 'prop-types';

export const accountShape = PropTypes.shape({
  displayName: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  iban: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
});

export const transactionActivityShape = {
  createdAt: PropTypes.string.isRequired,
  createdBy: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  currency: PropTypes.string.isRequired,
  debitedAmount: PropTypes.number.isRequired,
  exchangeRate: PropTypes.string,
  fromAccount: accountShape.isRequired,
  toAccount: accountShape.isRequired,
  id: PropTypes.string.isRequired,
  netTransactionAmount: PropTypes.number.isRequired,
  reference: PropTypes.string.isRequired,
  sourceCurrency: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  statusReasons: PropTypes.arrayOf(PropTypes.string),
  targetCurrency: PropTypes.string.isRequired,
  isMultiCurrencyTransaction: PropTypes.bool.isRequired,
  totalFee: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
};

export const mappedTransactionShape = {
  id: PropTypes.string,
  bulkPaymentId: PropTypes.string,
  type: PropTypes.string.isRequired,
  fromAccount: accountShape.isRequired,
  toAccount: accountShape,
  merchant: PropTypes.instanceOf(Object),
  debitedAmount: PropTypes.number.isRequired,
  netTransactionAmount: PropTypes.number.isRequired,
  numberOfTransactions: PropTypes.number,
  sourceAmount: PropTypes.number.isRequired,
  targetAmount: PropTypes.number.isRequired,
  externalFee: PropTypes.number,
  author: PropTypes.string,
  createdAt: PropTypes.string.isRequired,
  createdBy: PropTypes.instanceOf(Object).isRequired,
  exchangeRate: PropTypes.string,
  reference: PropTypes.string,
  purposeCode: PropTypes.string,
  sourceCurrency: PropTypes.string.isRequired,
  targetCurrency: PropTypes.string.isRequired,
  isMultiCurrencyTransaction: PropTypes.bool,
  status: PropTypes.string.isRequired,
  statusReasons: PropTypes.arrayOf(PropTypes.string),
  cardholder: PropTypes.string,
  totalFee: PropTypes.number.isRequired,
  isPendingApproval: PropTypes.bool,
  isApiPayment: PropTypes.bool,
  approvalRequestId: PropTypes.string,
};
