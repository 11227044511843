const checkIfLinkIsActive = (match, to, extra) => {
  if (match?.url === to) {
    return true;
  }

  if (!extra) {
    return false;
  }

  return Boolean(extra.find(path => path === match?.path));
};

export default checkIfLinkIsActive;
