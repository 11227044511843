import PropTypes from 'prop-types';
import React, { createContext, useMemo, useState } from 'react';

import { cardShape } from 'local-prop-types';

export const CardContext = createContext({});

function CardProvider({ card: initialCardData, isMyCard: initialIsMyCardData, children }) {
  const [ card, setCard ] = useState(initialCardData);
  const [ isMyCard, setIsMyCard ] = useState(initialIsMyCardData);

  const initialValues = useMemo(() => ({ card, isMyCard, setIsMyCard, setCard }), [ card, isMyCard ]);

  return (
    <CardContext.Provider value={initialValues}>
      {children}
    </CardContext.Provider>
  );
}

CardProvider.propTypes = {
  card: cardShape.card.isRequired,
  isMyCard: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default CardProvider;
