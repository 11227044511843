import { useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';

import { isValidAddress } from 'pages/order-cards/utils';
import {
  ADDRESS_TYPE,
  addressTypeOptions,
  CHANGE_ADDRESS_FORM,
  CITY_ADDRESS_FIELD,
  COUNTRY_CODE,
  DELIVERY_ADDRESS_TYPE_SELECTION,
  deliveryAddressTypeMap,
  deliveryCountryOptions,
  POSTAL_CODE_ADDRESS_FIELD,
  REGION_ADDRESS_FIELD,
  STREET_ADDRESS_FIELD,
} from 'pages/order-cards/constants';
import { ModalContent, AlertBlock } from 'components';
import { orderCardAddressShape } from 'local-prop-types';

import S from './styles';
import { ChangeAddressSchema as validationSchema } from './schema';

function ChangeAddressModal({
  isOpen,
  setIsOpen,
  selectedAddress,
  corporationAddress,
  cardHolderAddress,
  setDeliveryAddress,
  hasManyCardHolders,
}) {
  const { t } = useTranslation();
  const [ disabled, setDisabled ] = useState(true);
  const [ deliveryAddressTypeOptions, setDeliveryAddressTypeOptions ] = useState([]);

  useEffect(() => {
    setDeliveryAddressTypeOptions(getDeliveryAddressTypeOptions());

    if (selectedAddress) {
      setValues({
        street: selectedAddress.street,
        city: selectedAddress.city,
        postalCode: selectedAddress.postalCode,
        region: selectedAddress.region,
        countryCode: selectedAddress.countryCode,
        type: selectedAddress.type,
      });

      setDisabled(selectedAddress.type !== deliveryAddressTypeMap.specifiedAddress);
    }
  }, []);

  const onSubmit = () => {
    if (!isEmpty(errors)) {
      return;
    }

    const filteredValues = omitBy(values, isEmpty);

    setDeliveryAddress(filteredValues);
    setIsOpen(false);
  };

  const initialValues = {
    [COUNTRY_CODE]: '',
    [CITY_ADDRESS_FIELD]: '',
    [STREET_ADDRESS_FIELD]: '',
    [REGION_ADDRESS_FIELD]: '',
    [POSTAL_CODE_ADDRESS_FIELD]: '',
    [ADDRESS_TYPE]: '',
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const {
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    handleChange,
    touched,
    errors,
    values,
    setValues,
  } = formik;

  const onAddressTypeSelect = selectedOption => {
    setDisabled(selectedOption.value !== deliveryAddressTypeMap.specifiedAddress);

    if (selectedOption.value === deliveryAddressTypeMap.specifiedAddress) {
      setValues({
        street: '',
        city: '',
        postalCode: '',
        region: '',
        countryCode: '',
        type: deliveryAddressTypeMap.specifiedAddress,
      });
    } else if (selectedOption.value === deliveryAddressTypeMap.cardholderAddress) {
      setValues({ ...cardHolderAddress, type: deliveryAddressTypeMap.cardholderAddress });
    } else {
      setValues({ ...corporationAddress, type: deliveryAddressTypeMap.companyAddress });
    }
  };

  const onCountrySelect = selectedOption => {
    setFieldValue(COUNTRY_CODE, selectedOption.value);
  };

  const getDeliveryAddressTypeOptions = () => addressTypeOptions
    .filter(option =>
      isValidAddress(corporationAddress)
        ? true
        : option.value !== deliveryAddressTypeMap.companyAddress)
    .filter(option =>
      isValidAddress(cardHolderAddress)
        ? true
        : option.value !== deliveryAddressTypeMap.cardholderAddress);

  const getGetCountryOption = () => {
    if (values.countryCode === '') {
      return null;
    }

    return deliveryCountryOptions.find(country => country.value === values.countryCode);
  };

  const handleBlur = fieldName => {
    setFieldTouched(fieldName, true);
  };

  const CTAButtons = (
    <>
      <S.Button label={t('orderCards.changeAddressModal.submitBtn')} category="primary" type="submit" />
      <S.Button hasMargin label={t('orderCards.changeAddressModal.cancelBtn')} category="secondary" onClick={() => setIsOpen(false)} />
    </>
  );

  return (
    <ModalContent
      title={t('orderCards.changeAddressModal.title')}
      isOpen={isOpen}
      CTAButtons={CTAButtons}
      setIsOpen={setIsOpen}
      formName={CHANGE_ADDRESS_FORM}
      onSubmit={handleSubmit}
    >
      <S.Dropdown
        id={DELIVERY_ADDRESS_TYPE_SELECTION}
        name={DELIVERY_ADDRESS_TYPE_SELECTION}
        onChange={onAddressTypeSelect}
        options={deliveryAddressTypeOptions}
        label={t('orderCards.changeAddressModal.deliveryAddress')}
        isClearable={false}
        value={addressTypeOptions.find(option => option.value === values[ADDRESS_TYPE])}
      />
      <S.DoubleInputContainer>
        <S.CountryDropdown
          id={COUNTRY_CODE}
          name={COUNTRY_CODE}
          onChange={onCountrySelect}
          options={deliveryCountryOptions}
          placeholder="Select..."
          label={t('orderCards.changeAddressModal.country')}
          error={touched[COUNTRY_CODE] && errors[COUNTRY_CODE]}
          isClearable={false}
          isDisabled={disabled}
          value={getGetCountryOption()}
        />
        <S.ShortTextInput
          id={CITY_ADDRESS_FIELD}
          name={CITY_ADDRESS_FIELD}
          type="text"
          label={t('orderCards.changeAddressModal.city')}
          error={touched[CITY_ADDRESS_FIELD] && errors[CITY_ADDRESS_FIELD]}
          onBlur={() => handleBlur(CITY_ADDRESS_FIELD)}
          onChange={handleChange}
          disabled={disabled}
          value={values.city || ''}
          hasMargin
        />
      </S.DoubleInputContainer>
      <S.Input
        onBlur={() => handleBlur(STREET_ADDRESS_FIELD)}
        onChange={handleChange}
        id={STREET_ADDRESS_FIELD}
        name={STREET_ADDRESS_FIELD}
        label={t('orderCards.changeAddressModal.street')}
        disabled={disabled}
        error={touched[STREET_ADDRESS_FIELD] && errors[STREET_ADDRESS_FIELD]}
        type="text"
        value={values[STREET_ADDRESS_FIELD]}
      />
      <S.DoubleInputContainer>
        <S.ShortTextInput
          id={REGION_ADDRESS_FIELD}
          name={REGION_ADDRESS_FIELD}
          type="text"
          label={t('orderCards.changeAddressModal.state')}
          value={values.region}
          error={touched[REGION_ADDRESS_FIELD] && errors[REGION_ADDRESS_FIELD]}
          onBlur={() => handleBlur(REGION_ADDRESS_FIELD)}
          onChange={handleChange}
          disabled={disabled}
        />
        <S.ShortTextInput
          id={POSTAL_CODE_ADDRESS_FIELD}
          name={POSTAL_CODE_ADDRESS_FIELD}
          type="text"
          label={t('orderCards.changeAddressModal.postalCode')}
          value={values.postalCode}
          error={touched[POSTAL_CODE_ADDRESS_FIELD] && errors[POSTAL_CODE_ADDRESS_FIELD]}
          onBlur={() => handleBlur(POSTAL_CODE_ADDRESS_FIELD)}
          onChange={handleChange}
          disabled={disabled}
          hasMargin
        />
      </S.DoubleInputContainer>
      {hasManyCardHolders && (
        <S.AlertBlockWrapper>
          <AlertBlock icon={S.InfoIcon} text={t('orderCards.changeAddressModal.infoText')} />
        </S.AlertBlockWrapper>
      )}
    </ModalContent>
  );
}

ChangeAddressModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  hasManyCardHolders: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  selectedAddress: orderCardAddressShape,
  corporationAddress: orderCardAddressShape,
  cardHolderAddress: orderCardAddressShape,
  setDeliveryAddress: PropTypes.func.isRequired,
};

export default ChangeAddressModal;
