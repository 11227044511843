import defaults from 'lodash/defaults';
import pick from 'lodash/pick';

export function mapRolesToOptions(roles) {
  return roles.map(role => ({ value: role.id, label: role.name }));
}

export function mapOptionsToRoles(options) {
  return options.map(option => ({ id: option.value, name: option.label }));
}

export function getInitialValues(user, defaultValues) {
  const requiredFields = Object.keys(defaultValues);
  const userData = pick(user, requiredFields);

  const initialValues = defaults(userData, defaultValues);

  return { ...initialValues, roles: mapRolesToOptions(initialValues.roles) };
}
