import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { navigateToNotFoundPage, showGeneralToastError } from 'utils/errors';
import { cardListQuery } from 'constants/card';
import { GlobalLoaderContext } from 'contexts/global-loader';
import { NOT_FOUND } from 'constants/status-code';
import { resourceType } from 'constants/resource';
import { listCards } from 'api/card';
import { getCardOrder } from 'api/cards';

const useCards = () => {
  const [ cards, setCards ] = useState([]);
  const [ isFetching, setIsFetching ] = useState(true);
  const { startLoading, endLoading } = useContext(GlobalLoaderContext);

  const fetchCards = async params => {
    startLoading();
    setIsFetching(true);

    try {
      const { data: cardsData } = await listCards({ ...cardListQuery, statuses: params?.statuses });
      setCards(cardsData);
    } catch (error) {
      showGeneralToastError(error);
    } finally {
      endLoading();
      setIsFetching(false);
    }
  };

  return { cards, fetchCards, isFetching };
};

const useGetCardOrder = id => {
  const history = useHistory();
  const [ cardOrder, setCardOrder ]  = useState({});
  const { startLoading, endLoading } = useContext(GlobalLoaderContext);

  useEffect(() => {
    const getOrderById = async () => {
      startLoading();
      try {
        const data = await getCardOrder(id);
        setCardOrder(data);
      } catch (error) {
        error.status === NOT_FOUND
          ? navigateToNotFoundPage(history, resourceType.cardOrder)
          : showGeneralToastError(error);
      } finally {
        endLoading();
      }
    };
    getOrderById();
  }, [ id, startLoading, endLoading ]);

  if (!id) return null;

  return { cardOrder };
};

export { useCards, useGetCardOrder };
