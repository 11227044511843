import styled from 'styled-components';
import { Sidebar as DefaultSidebar } from '@general/intergiro-ui-kit';

import { hex2rgba } from 'helpers/styles';

const Sidebar = styled(DefaultSidebar)`
  z-index: 1;
  overflow-y: auto;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;
`;

const DataContainerLabel = styled.p`
  width: 38%;
  padding: 0;
  color: ${props => props.theme.colors.greyMedium};
  font: ${props => props.theme.fonts.bodyM};
  margin: 0;
`;

const DataContainerValue = styled.div`
  padding: 0;
  color: ${props => props.theme.colors.black};
  font: ${props => props.theme.fonts.bodyMSemi};
  text-align: right;
  word-wrap: break-word;
  width: 62%;
  display: flex;
  justify-content: flex-end;
  line-break: ${props => props.lineBreak ? 'auto' : 'anywhere'};
`;

const DataContainer = styled.div`
  display: flex;
  ${props => props.isVertical ? `
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    
    ${DataContainerLabel} {
      width: 100%;
    }
    
    ${DataContainerValue} {
      text-align: left;
      width: 100%;
      justify-content: flex-start;
      margin-top: 0.5rem;
    }
  ` : `
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `}
  margin-bottom: 1.5rem;
`;

const InputsContainer = styled.div`
  margin: 0;
  width: 100%;
`;

const CardContainer = styled.div`
  display: block;
  margin: 1.875rem auto;
`;

const CurrencyContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Currency = styled.p`
  padding-left: 0.5rem;
  margin: 0;
`;

const Status = styled.div`
  padding: 0.0625rem 0.625rem 0.1875rem 0.625rem;
  text-align: center;
  color: ${props => props.theme.colors.white};
  font: ${props => props.theme.fonts.bodyXxs};
  font-size: 0.8125rem;
  background: ${props => props.success ? hex2rgba(props.theme.colors.green, 0.8) : props.theme.colors.red};
  border-radius: 0.8125rem;
`;

export default {
  Sidebar,
  InputsContainer,
  Container,
  CardContainer,
  CurrencyContainer,
  Currency,
  DataContainer,
  DataContainerLabel,
  DataContainerValue,
  Status,
};
