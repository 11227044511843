import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { HiddenOnMobile } from 'components';
import { useMerchantPrivateKey } from 'hooks';
import { minutesToMilliseconds } from 'utils/time';

import MerchantMonitor from './components/merchant-monitor';
import MerchantTimeoutModal from './components/merchant-timeout-modal';
import { MERCHANT_KEY_EXPIRATION, SESSION_TIMEOUT } from './constants';
import S from './styles';

function MerchantPageContainer() {
  const [ isOpen, setIsOpen ] = useState(false);
  const { id } = useParams();
  const { privateKey } = useMerchantPrivateKey(id);

  useEffect(() => {
    setTimeout(() => {
      setIsOpen(true);
    }, minutesToMilliseconds(MERCHANT_KEY_EXPIRATION - SESSION_TIMEOUT));
  }, []);

  if (!privateKey) {
    return null;
  }

  return (
    <HiddenOnMobile>
      <S.Container>
        <MerchantMonitor privateKey={privateKey} />
        {isOpen && (
          <MerchantTimeoutModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            sessionTimeout={SESSION_TIMEOUT}
          />
        )}
      </S.Container>
    </HiddenOnMobile>
  );
}

export default MerchantPageContainer;
