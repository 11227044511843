import PropTypes from 'prop-types';
import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';

import { ModalContent } from 'components';
import { AccountsContext } from 'contexts/accounts';
import { ERRORS } from 'constants/errors';

import { accountDetailsShape } from '../prop-types';
import UpdateAccountForm from './update-account-form';
import validationSchema from './validation-schema';
import S from '../../styles';

function RenameAccountModal({
  isOpen,
  setIsOpen,
  accountDetails,
  updateAccount,
}) {
  const { t } = useTranslation();
  const { fetchAccounts } = useContext(AccountsContext);
  const closeModal = useCallback(() => setIsOpen(false), [setIsOpen]);

  const {
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    values,
    touched,
    errors,
    isSubmitting,
    submitForm,
    setErrors,
  } = useFormik({
    initialValues: {
      name: accountDetails.displayName,
      labels: accountDetails.labels
        ?.filter(({ isManageable }) => isManageable)
        ?.map(({ label }) => ({ value: label, label })),
    },
    onSubmit: async ({ name, labels }) => {
      try {
        const mappedLabels = labels.map(({ value }) => value);

        await updateAccount(accountDetails.id, { name, labels: mappedLabels });

        toast.info(t('accounts.renameAccountModal.accountUpdated'));
      } catch (error) {
        setErrors(error.data);

        if (error.data.default) {
          toast.error(ERRORS.generalError);
        }

        return;
      }

      await fetchAccounts();

      setIsOpen(false);
    },
    validationSchema,
    enableReinitialize: true,
  });

  const CTAButtons = (
    <>
      <S.ModalButton
        label={t('accounts.renameAccountModal.update')}
        category="primary"
        type="button"
        onClick={submitForm}
        disabled={isSubmitting}
      />
      <S.ModalButton
        label={t('accounts.renameAccountModal.cancel')}
        category="secondary"
        onClick={closeModal}
        disabled={isSubmitting}
      />
    </>
  );

  return (
    <ModalContent
      title={t('accounts.renameAccountModal.title')}
      hasCloseIcon
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      CTAButtons={CTAButtons}
    >
      <UpdateAccountForm
        handleChange={handleChange}
        handleBlur={handleBlur}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
        values={values}
        touched={touched}
        errors={errors}
        isSubmitting={isSubmitting}
        submitForm={submitForm}
      />
    </ModalContent>
  );
}

RenameAccountModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  updateAccount: PropTypes.func.isRequired,
  accountDetails: accountDetailsShape.isRequired,
};

export default RenameAccountModal;
