import { ErrorCodes } from '@general/error-codes';
import { DropdownCurrencyOption as CurrencyOption } from '@general/intergiro-ui-kit';
import { useFormik } from 'formik';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import truncate from 'lodash/truncate';
import upperCase from 'lodash/upperCase';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';

import { Dot, getDropdownCurrencyOptions, ValidationWrapper } from 'components';
import { INPUT_PATTERNS } from 'constants/inputs';
import { GlobalLoaderContext } from 'contexts/global-loader';
import { UserContext } from 'contexts/user';
import { useInterval, useView } from 'hooks';
import NotificationBlock from 'pages/send-funds/components/notification-block';
import PaymentReference from 'pages/send-funds/components/payment-reference-field';
import {
  AMOUNT_FORM,
  BENEFICIARY_CURRENCY,
  EXCHANGE_RATE_CHANGED_NOTIFICATION,
  FROM_ACCOUNT,
  FROM_ACCOUNT_ID,
  FX_RATE,
  FX_SIDE,
  MOBILE_MAX_ACCOUNT_NUMBER_CHARS,
  PAYMENT_REFERENCE,
  PAYMENT_REFERENCE_TOUCHED,
  POLL_TIMEOUT,
  PURPOSE_CODE,
  PURPOSE_CODE_REQUIRED,
  RAILS_FIELD_NAME,
  RECEIVE_AMOUNT,
  SELL_FX_SIDE,
  SEND_AMOUNT,
  SEND_FUNDS_CONFIRMATION,
  TOTAL_FEE_AMOUNT,
} from 'pages/send-funds/constants';
import createAmountValidationSchema from 'pages/send-funds/schemas/amount';
import {
  fromAccountsFilter,
  getAccountNumber,
  getBankCode,
  getBeneficiaryName,
  getTotal,
  getTransferFee,
  isPurposeCodeFieldRequired,
  updateSelectedAccountIfRequired,
} from 'pages/send-funds/utils';
import { isFxOutgoing, getExchangeRate, canGetRate } from 'pages/send-funds/utils/fx';
import { showGeneralToastError, errorsConsistOneOfErrorCodes } from 'utils/errors';
import { formatIbanIsValid } from 'utils/format';
import { isFxWeekend } from 'utils/fx';

import S from './styles';
import { dataTestIds } from './constants';

function AmountForm({
  setCurrentView,
  accounts,
  updateAccounts,
  beneficiaryFormValues,
  amountFormValues,
  setAmountFormValues,
  submissionErrors,
  predefinedValues,
  setPredefinedValues,
}) {
  const { startLoading, endLoading } = useContext(GlobalLoaderContext);
  const { user: { corporationName } } = useContext(UserContext);
  const { isMobile } = useView();
  const [ formattedAccountNumber, setFormattedAccountNumber ] = useState('');
  const updateCallbackRef = useRef(() => updateAccounts());

  const onSubmit = async () => {
    try {
      startLoading();
      const { rate: transferFee } = await getTransferFee(accounts, beneficiaryFormValues, values);
      const isFx = isFxOutgoing(beneficiaryFormValues, transferFee);

      const amountFormVal = {
        ...values,
        [TOTAL_FEE_AMOUNT]: transferFee,
        isFx,
      };

      if (isFx) {
        delete amountFormVal[FX_SIDE];
      }

      setAmountFormValues(amountFormVal);
      setCurrentView(SEND_FUNDS_CONFIRMATION);
    } catch (error) {
      showGeneralToastError(error);
    } finally {
      endLoading();
    }
  };

  const { currency, name } = getBeneficiaryName(beneficiaryFormValues);
  const accountNumber = getAccountNumber(beneficiaryFormValues);
  const bankCode = getBankCode(beneficiaryFormValues);

  const formik = useFormik({
    initialValues: {
      ...amountFormValues,
      [BENEFICIARY_CURRENCY]: currency,
    },
    initialTouched: {
      [PAYMENT_REFERENCE]: amountFormValues.formMetaData[PAYMENT_REFERENCE_TOUCHED],
    },
    onSubmit,
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: createAmountValidationSchema,
  });

  const {
    handleSubmit,
    setFieldValue,
    handleChange,
    handleBlur,
    setFieldTouched,
    validateField,
    isSubmitting,
    touched,
    values,
    errors,
  } = formik;

  useEffect(() => {
    if (isMobile) {
      const truncatedAccountNumber = truncate(accountNumber?.value, {
        length: MOBILE_MAX_ACCOUNT_NUMBER_CHARS,
      });
      setFormattedAccountNumber(formatIbanIsValid(truncatedAccountNumber));
    } else {
      setFormattedAccountNumber(formatIbanIsValid(accountNumber?.value));
    }
  }, [ isMobile, beneficiaryFormValues ]);

  const onAccountChange = selectedAccountOption => {
    setFieldValue(FROM_ACCOUNT, selectedAccountOption);
    setFieldTouched(FROM_ACCOUNT);
  };

  const onPurposeCodeChange = selectedPurposeCodeOption => {
    setFieldValue(PURPOSE_CODE, selectedPurposeCodeOption);
  };

  const updatePredefinedAccount = () => {
    const accountsFilter = account => fromAccountsFilter(account, beneficiaryFormValues);

    const predefinedAccountId = get(predefinedValues, FROM_ACCOUNT_ID);
    const predefinedAccount = predefinedAccountId
      && accounts.filter(accountsFilter).find(({ id }) => id === predefinedAccountId);

    if (!isEmpty(predefinedAccount)) {
      const [predefinedAccountOption] = predefinedAccount && getDropdownCurrencyOptions([predefinedAccount]);

      onAccountChange(predefinedAccountOption);
      setPredefinedValues(omit(predefinedValues, [FROM_ACCOUNT_ID]));
    }
  };

  useInterval(() => {
    updateCallbackRef.current();
  }, POLL_TIMEOUT);

  useEffect(() => {
    updateAccounts();

    const purposeCodeRequired = isPurposeCodeFieldRequired(beneficiaryFormValues);

    setFieldValue(`formMetaData.${PURPOSE_CODE_REQUIRED}`, purposeCodeRequired);
  }, []);

  useEffect(() => {
    updateSelectedAccountIfRequired(values, accounts, setFieldValue);
    if (!isFxOutgoing(beneficiaryFormValues, values)) {
      updateCallbackRef.current = () => {
        updateAccounts();
      };
    }
    if (isEmpty(values[FROM_ACCOUNT])) {
      updatePredefinedAccount();
    }
  }, [accounts]);

  const onAmountChange = fieldName => changeEvent => {
    setFieldTouched(fieldName);
    handleChange(changeEvent);
  };

  const updateRate = async selectedFxSide => {
    try {
      startLoading();
      const exchangeRate = await getExchangeRate(beneficiaryFormValues, values, selectedFxSide);
      await setFieldValue(SEND_AMOUNT, exchangeRate.sellAmount);
      await setFieldValue(RECEIVE_AMOUNT, exchangeRate.buyAmount);
      await setFieldValue(FX_RATE, exchangeRate);
    } catch (error) {
      showGeneralToastError(error);
    } finally {
      endLoading();
    }
  };

  const onAmountBlur = async (selectedFxSide, event, fieldName) => {
    const { value } = event.target;

    if (!isEmpty(value)) {
      await setFieldValue(fieldName, value);
    }

    await setFieldValue(FX_SIDE, selectedFxSide);

    const allowedToGetRate = canGetRate(beneficiaryFormValues, values, selectedFxSide);
    if (allowedToGetRate && !isFxWeekend()) {
      await updateRate(selectedFxSide);

      updateCallbackRef.current = () => updateAccounts().then(() => updateRate(selectedFxSide));
    }
    try {
      startLoading();
      const { rate: transferFee } = await getTransferFee(accounts, beneficiaryFormValues, values);
      await setFieldValue(TOTAL_FEE_AMOUNT, transferFee);
    } catch (error) {
      showGeneralToastError(error);
    } finally {
      endLoading();
    }
  };

  const getPurposeCodeOptions = beneficiaryFormVal => {
    const purposeCodeField =
    beneficiaryFormVal.beneficiaryDetailsResponse.find(field => field.name === PURPOSE_CODE);

    return purposeCodeField?.values.map(({ value: code, description }) => {
      const label =  description ? `${description} (${upperCase(code)})` : upperCase(code);

      return {
        value: code,
        label: <CurrencyOption name={upperCase(code)} amount={label}/>,
      };
    });
  };

  useEffect(() => {
    updateSelectedAccountIfRequired(values, accounts, setFieldValue);
    if (!isFxOutgoing(beneficiaryFormValues, values)) {
      updateCallbackRef.current = () => {
        updateAccounts();
      };
    }
    if (isEmpty(values[FROM_ACCOUNT])) {
      updatePredefinedAccount();
    }
  }, [accounts]);

  useEffect(() => {
    Object.keys(amountFormValues).map(fieldName => amountFormValues[fieldName] && setFieldTouched(fieldName));
  }, [amountFormValues]);

  useEffect(() => {
    const fetchTransferFee = async () => {
      const currencyValue = get(values, `${FROM_ACCOUNT}.value.currency`);
      if (!currencyValue) {
        return;
      }
      try {
        startLoading();
        const { rate: transferFee } = await getTransferFee(accounts, beneficiaryFormValues, values);
        setFieldValue(TOTAL_FEE_AMOUNT, transferFee);
      } catch (error) {
        showGeneralToastError(error);
      } finally {
        endLoading();
      }
    };
    fetchTransferFee();
    validateField(SEND_AMOUNT);
  }, [values[FROM_ACCOUNT]]);

  const filteredFromAccounts = accounts.filter(account => fromAccountsFilter(account, beneficiaryFormValues));
  const fromAccountDropdownValue = !isEmpty(values[FROM_ACCOUNT]) ? values[FROM_ACCOUNT] : undefined;

  const purposeCodeOptions = getPurposeCodeOptions(beneficiaryFormValues);

  const disableContinueButton = isSubmitting;

  return (
    <form id={AMOUNT_FORM} data-testid={dataTestIds.wrapper} name={AMOUNT_FORM} onSubmit={handleSubmit}>
      <S.Row>
        <S.ElementWrapper>
          <ValidationWrapper error={errors[FROM_ACCOUNT]} touched={Boolean(touched[FROM_ACCOUNT])}>
            <S.Dropdown
              placeholder="Select account"
              label="From"
              options={getDropdownCurrencyOptions(filteredFromAccounts)}
              name={FROM_ACCOUNT}
              isClearable={false}
              isCurrencyOption
              disabled={isSubmitting}
              onChange={onAccountChange}
              value={fromAccountDropdownValue}
            />
          </ValidationWrapper>
        </S.ElementWrapper>
        <S.ArrowRight />
        <S.ElementWrapper>
          <S.DropdownPlaceholder
            label="To"
            isCurrencyOption
            value={{
              value: name,
              label: <CurrencyOption currencyShort={currency} name={name} />,
            }}
            isDisabled
          />
          <S.BeneficiaryInfo>
            {currency}
            <Dot />
            {formattedAccountNumber}
            <Dot />
            {bankCode?.value}
          </S.BeneficiaryInfo>
        </S.ElementWrapper>
      </S.Row>
      <S.Row>
        <ValidationWrapper error={errors[SEND_AMOUNT]} touched={Boolean(touched[SEND_AMOUNT])}>
          <S.Input
            name={SEND_AMOUNT}
            label="I send"
            currency={values[FROM_ACCOUNT]?.currency}
            onChange={onAmountChange(SEND_AMOUNT)}
            onBlur={e => onAmountBlur(SELL_FX_SIDE, e, SEND_AMOUNT)}
            value={values[SEND_AMOUNT] || ''}
            disabled={isSubmitting}
            placeholder="0.00"
            type="text"
            inputPattern={INPUT_PATTERNS.amount}
            helperText={(
              <S.CurrencyLabel>
                {currency}
              </S.CurrencyLabel>
)}
            isClearable={false}
          />
        </ValidationWrapper>
      </S.Row>

      <S.Row hasTopMargin>
        <S.ElementWrapper>
          <S.TotalRow content={getTotal(
            beneficiaryFormValues, values, false, true,
            )}
          />
        </S.ElementWrapper>
      </S.Row>
      {errorsConsistOneOfErrorCodes(submissionErrors, [ErrorCodes.rateChangedTooMuch]) && (
        <S.Row>
          <NotificationBlock type={EXCHANGE_RATE_CHANGED_NOTIFICATION} />
        </S.Row>
      )}
      <S.Row>
        <S.ElementWrapper>
          {purposeCodeOptions && (
            <S.PurposeCodeDropdown
              name={PURPOSE_CODE}
              label="Purpose code"
              value={values[PURPOSE_CODE]}
              options={purposeCodeOptions}
              error={touched[PAYMENT_REFERENCE] && errors[PURPOSE_CODE]}
              touched={touched[PURPOSE_CODE]}
              isCurrencyOption
              isClearable={false}
              onChange={onPurposeCodeChange}
              onBlur={handleBlur}
            />
)}
        </S.ElementWrapper>
      </S.Row>
      <S.Row>
        <S.ElementWrapper>
          <PaymentReference
            error={errors[PAYMENT_REFERENCE]}
            touched={Boolean(touched[PAYMENT_REFERENCE])}
            name={PAYMENT_REFERENCE}
            value={values[PAYMENT_REFERENCE] || ''}
            currency={beneficiaryFormValues[BENEFICIARY_CURRENCY].value}
            rails={beneficiaryFormValues[RAILS_FIELD_NAME]}
            corporationName={corporationName}
            accountNumber={get(values, `${FROM_ACCOUNT}.value.requisites.number`)}
            setPaymentReferenceFieldValue={value => setFieldValue(PAYMENT_REFERENCE, value)}
            setPaymentReferenceFieldTouched={() => setFieldValue(`formMetaData.${PAYMENT_REFERENCE_TOUCHED}`, true)}
          />
        </S.ElementWrapper>
      </S.Row>

      <S.Button label="Continue" data-testid={dataTestIds.submitBtn} category="primary" type="submit" disabled={disableContinueButton} />
      <S.CancelButton />
    </form>
  );
}

AmountForm.propTypes = {
  setCurrentView: PropTypes.func.isRequired,
  accounts: PropTypes.instanceOf(Array),
  updateAccounts: PropTypes.func.isRequired,
  beneficiaryFormValues: PropTypes.instanceOf(Object).isRequired,
  amountFormValues: PropTypes.instanceOf(Object),
  setAmountFormValues: PropTypes.func.isRequired,
  submissionErrors: PropTypes.oneOfType([ PropTypes.instanceOf(Array), PropTypes.instanceOf(Object) ]),
  predefinedValues: PropTypes.instanceOf(Object),
  setPredefinedValues: PropTypes.func.isRequired,
};

export default AmountForm;
