import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

export const minutesTill = time => {
  const diff = dayjs(time).diff(dayjs());

  return diff > 0 ? dayjs.duration(diff).minutes() : 0;
};

export const minutesToMilliseconds = minutes => dayjs.duration({ minutes } ).asMilliseconds();

export const millisecondsToSeconds = milliseconds => dayjs.duration(milliseconds).asSeconds();
