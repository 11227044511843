import styled from 'styled-components';

import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { AlertBlock } from 'components';
import { DEVICE } from 'constants/breakpoints';

const Container = styled(AlertBlock)`
  margin-bottom: 1.25rem;

  @media ${DEVICE.laptop} {
    margin-bottom: 2.5rem;
	}
`;

const Icon = styled(InfoIcon)`
  fill: ${props => props.theme.colors.blue};
  width: 2rem;
  height: 2rem;
`;

export default {
  Container,
  Icon,
};
