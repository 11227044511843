import { Button, Input as DataInput, Sidebar as DefaultSidebar } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { ReactComponent as DefaultBulkPaymentsIcon } from 'assets/icons/bulk-payments.svg';

const Sidebar = styled(DefaultSidebar)`
  z-index: 2;
`;

const AddBeneficiaryForm = styled.form`
  margin-top: 1.25rem;
`;

const AvatarWrapper = styled.div`
  box-sizing: border-box;
  margin-top: 0.15rem;
  margin-left: 0.75rem;
  position: relative;
  display: inline-block;
  text-align: center;
`;

const CurrencyLabel = styled.span`
  font: ${props => props.theme.fonts.bodyL};
`;

const Input = styled(DataInput)`
  font: ${props => props.bold ? props.theme.fonts.h3Bold : props.theme.fonts.bodyL};
  margin-top: 1.25rem;
`;

const Separator = styled.hr`
  opacity: 0.1;
  border-top: 0.0625rem solid ${props => props.theme.colors.black};
  margin: 1.25rem 0;
  margin-top: ${props => props.marginTop || '1.25rem'};
  margin-bottom: ${props => props.marginBottom || '1.25rem'};
`;

const Text = styled.p`
  margin: 0;
  font: ${props => props.theme.fonts.bodyM};
  color: ${props => props.theme.colors.greyMedium};
`;

const Row = styled.div`
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: ${props => props.paddingTop || 0};
`;

const Account = styled.div`
  margin: 0;
  display: flex;
  align-items: center;
`;

const AccountName = styled.p`
  margin: 0 0 0 0.5rem;
  font: ${props => props.theme.fonts.bodyM};
  color: ${props => props.theme.colors.black};
`;

const ActionButton = styled(Button)`
  width: 100%;
`;

const TotalWrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
  padding: 1.875rem 1.25rem 1.25rem 1.25rem;
  margin-top: 0.6875rem;
  background: ${props => props.theme.colors.white};
  display: ${props => (props.visible ? 'block' : 'none')};
  box-shadow: 0 0.125rem 1.5rem rgba(62, 60, 57, 0.1), inset 0 0 0.0625rem rgba(62, 60, 57, 0.4);
  border-radius: 0.5rem 0.5rem 0 0;
`;

const Content = styled.div`
  overflow-y: scroll;
  height: ${props => `calc(100vh - ${143 + props.totalContainerHeight}px)`};
`;

const BulkPaymentsIcon = styled(DefaultBulkPaymentsIcon)`
  width: ${props => props.theme.icons.large};
  height: ${props => props.theme.icons.large};
`;

export default {
  AddBeneficiaryForm,
  AvatarWrapper,
  CurrencyLabel,
  Input,
  Separator,
  Text,
  Row,
  Account,
  AccountName,
  ActionButton,
  Sidebar,
  TotalWrapper,
  Content,
  BulkPaymentsIcon,
};
