import React from 'react';
import { Link } from 'react-router-dom';

import S from './styles';

function ResetPasswordConfirmation() {
  return (
    <S.Wrapper>
      <S.Title>Please check your email now</S.Title>
      <S.Subtitle>Instructions for resetting your password have been sent to stated email address.</S.Subtitle>
      <S.Paragraph>
        If you haven’t received an email from us within a couple of minutes, please check your spam folder.
      </S.Paragraph>
      <S.ControlWrapper>
        <S.Button label="Go to login" link={Link} linkProps={{ to: '/' }} />
      </S.ControlWrapper>
    </S.Wrapper>
  );
}

export default ResetPasswordConfirmation;
