import bff from 'utils/bff';

export const getBeneficiaries = async params => {
  const { data } = await bff.beneficiary.listBeneficiaries({}, params);

  return data;
};

export const getBeneficiary = async id => {
  const { data } = await bff.beneficiary.getBeneficiary({}, { id });

  return data;
};

export const archiveBeneficiary = async (id, params) => {
  const { data } = await bff.beneficiary.archiveBeneficiary(params, { id });

  return data;
};

export const updateBeneficiary = async (id, params) => {
  const { data } = await bff.beneficiary.updateBeneficiary(params, { id });

  return data;
};
