import { Button as DefaultButton } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';

const Text = styled.div`
  font: ${props => props.theme.fonts.bodyMSemi};
`;

const TimeLeftText = styled(Text)`
  margin-top: 1.25rem;

  & > span {
    font: ${props => props.theme.fonts.bodyMBold};
  }
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0.625rem;
  flex-direction: column;

  @media ${DEVICE.tablet} {
    flex-direction: row;
  }
`;

const Button = styled(DefaultButton)`
  min-width: 100%;

  @media ${DEVICE.tablet} {
    min-width: fit-content;
  }
`;

export default {
  TimeLeftText,
  Text,
  ButtonsWrapper,
  Button,
};
