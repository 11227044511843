import { useContext, useEffect, useState } from 'react';

import { showGeneralToastError } from 'utils/errors';
import { getAccounts } from 'api/accounts';
import { GlobalLoaderContext } from 'contexts/global-loader';

const useAccounts = (canViewAccounts, queryParams) => {
  const { startLoading, endLoading } = useContext(GlobalLoaderContext);
  const [ accounts, setAccounts ] = useState([]);

  const fetchAccounts = async () => {
    startLoading();

    try {
      const data = await getAccounts(queryParams);

      setAccounts(data);
    } catch (error) {
      showGeneralToastError(error);
    } finally {
      endLoading();
    }
  };

  useEffect(() => {
    canViewAccounts && fetchAccounts();
  }, [queryParams]);

  return { accounts, fetchAccounts };
};

export default useAccounts;
