import PropTypes from 'prop-types';
import React, { useContext, useState, useEffect } from 'react';
import { ErrorCodes } from '@general/error-codes';
import { useTranslation } from 'react-i18next';
import chunk from 'lodash/chunk';

import { sendBulkPayment } from 'api/bulk-payments';
import { getBeneficiaries } from 'api/beneficiary';
import { TwoFaModal, SwitchBlock, AccountName } from 'components';
import { GlobalLoaderContext } from 'contexts/global-loader';
import TwoFaProvider from 'contexts/two-fa';
import { viewMap } from 'pages/bulk-payments/constants';
import { getConfirmationContent } from 'pages/bulk-payments/helpers';
import { formatAmount } from 'utils/amount';
import { errorsConsistOneOfErrorCodes } from 'utils/errors';

import S from './styles';

function BulkPaymentsConfirmation({
  totals,
  currency,
  numberOfPayments,
  sourceAccountName,
  bulkPaymentId,
  setSuccess,
  setCurrentView,
  bulkPaymentData,
}) {
  const { t } = useTranslation();
  const { startLoading, endLoading } = useContext(GlobalLoaderContext);

  const [ isSubmitting, setIsSubmitting ] = useState(false);
  const [ twoFaModalIsOpen, setTwoFaModalIsOpen ] = useState(false);
  const [ challenge, setChallenge ] = useState(null);

  const [ showTrustBeneficiaryToggle, setShowTrustBeneficiaryToggle ] = useState(false);
  const [ isTrusted, setIsTrusted ] = useState(false);

  useEffect(async () => {
    // TODO: Use single request approach (IDE-17235)
    const accountNumbers = bulkPaymentData.items.map(item => item.beneficiary.accountIban);
    const beneficiaryPromises = chunk(accountNumbers, 20)
      .map(nums => getBeneficiaries({ accountNumbers: nums, isTrusted: false, limit: 1 }));
    const beneficiaryResults = await Promise.all(beneficiaryPromises);

    setShowTrustBeneficiaryToggle(beneficiaryResults.some(({ numberOfRecords }) => numberOfRecords > 0));
  }, [bulkPaymentData]);

  function getScaData(challengeValue) {
    const sca = challengeValue ? { challenge: challengeValue } : {};

    return {
      amount: totals[currency.toLowerCase()]?.net,
      currency,
      beneficiary: t('beneficiary.sca.multipleBeneficiaries'),
      ...sca,
    };
  }

  const showSuccessPage = () => {
    setSuccess(true);
    setCurrentView(viewMap.bulkPaymentsFinal);
  };

  const showErrorPage = () => {
    setSuccess(false);
    setCurrentView(viewMap.bulkPaymentsFinal);
  };

  const handleSwitchChange = () => {
    setIsTrusted(!isTrusted);
  };

  const handleSend = async () => {
    startLoading();
    setIsSubmitting(true);

    try {
      const bulkPayment = await sendBulkPayment(bulkPaymentId, { ...getScaData(), isTrusted });
      const challengeData = bulkPayment?.challenge;

      if (challengeData) {
        setChallenge(challengeData);
        setTwoFaModalIsOpen(true);
      } else {
        showSuccessPage();
      }
    } catch (error) {
      showErrorPage();
    } finally {
      endLoading();
      setIsSubmitting(false);
    }
  };

  const handleSubmitCode = async challengeValue => {
    try {
      const data = await sendBulkPayment(bulkPaymentId, { ...getScaData(challengeValue), isTrusted });

      showSuccessPage();

      return data;
    } catch (error) {
      if (errorsConsistOneOfErrorCodes(error.data, [ErrorCodes.insufficientFunds])) {
        showErrorPage();
      }

      return Promise.reject(error);
    }
  };

  const handleResendCode = async (factor, scaId) => {
    const response = await sendBulkPayment(bulkPaymentId, getScaData({ preferredFactor: factor, scaId }));
    setChallenge(response?.challenge);

    return response;
  };

  return (
    <>
      <S.Title>Review and confirm</S.Title>
      <S.Row>
        <S.AmountAndAccountContainer>
          <S.Amount>
            {formatAmount(totals[currency.toLowerCase()]?.net)}
            {' '}
            {currency}
          </S.Amount>
          <S.ArrowRight />
          <S.Payments>
            {numberOfPayments > 1 ? `${numberOfPayments} payments` : `${numberOfPayments} payment`}
          </S.Payments>
        </S.AmountAndAccountContainer>
      </S.Row>
      <S.Row>
        <S.TotalContainer
          content={getConfirmationContent(sourceAccountName, currency, totals, AccountName)}
        />
      </S.Row>
      {showTrustBeneficiaryToggle && (
        <S.Row marginTop>
          <SwitchBlock
            title="Trust these beneficiaries"
            titleIcon={<S.TrustedIcon />}
            text="We won’t ask for authentication for future payments to these accounts"
            checked={isTrusted}
            onChange={handleSwitchChange}
          />
        </S.Row>
      )}
      <S.ContinueButton
        label="Send funds"
        category="primary"
        disabled={isSubmitting}
        onClick={handleSend}
      />
      {twoFaModalIsOpen && (
        <TwoFaProvider twoFaData={challenge}>
          <TwoFaModal
            isOpen={twoFaModalIsOpen}
            challenge={challenge}
            setIsOpen={setTwoFaModalIsOpen}
            onSubmit={handleSubmitCode}
            onResend={handleResendCode}
          />
        </TwoFaProvider>
      )}
    </>
  );
}

BulkPaymentsConfirmation.propTypes = {
  totals: PropTypes.instanceOf(Object).isRequired,
  currency: PropTypes.string.isRequired,
  numberOfPayments: PropTypes.number.isRequired,
  sourceAccountName: PropTypes.string.isRequired,
  bulkPaymentId: PropTypes.string.isRequired,
  setSuccess: PropTypes.func.isRequired,
  setCurrentView: PropTypes.func.isRequired,
  bulkPaymentData: PropTypes.instanceOf(Object).isRequired,
};

export default BulkPaymentsConfirmation;
