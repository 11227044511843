import get from 'lodash/get';
import { transliterate } from 'transliteration';
import {
  lazy,
  mixed,
  number,
  object,
  string,
} from 'yup';

import {
  BENEFICIARY_CURRENCY,
  FROM_ACCOUNT,
  MAX_PAYMENT_REFERENCE_LENGTH,
  MIN_AMOUNT,
  PAYMENT_REFERENCE,
  PAYMENT_REFERENCE_INVALID_CHARACTERS_PATTERN,
  PURPOSE_CODE,
  PURPOSE_CODE_REQUIRED,
  RECEIVE_AMOUNT,
  SEND_AMOUNT,
  TOTAL_FEE_AMOUNT,
} from 'pages/send-funds/constants';
import { isFxOutgoing } from 'pages/send-funds/utils/fx';
import { toIndivisibleUnit, transformSchemaAmount } from 'utils/amount';

const validationWithoutCalculations = {
  [FROM_ACCOUNT]: object()
    .nullable()
    .default(null)
    .shape({
      name: string(),
      amount: string(),
      currency: string(),
    })
    .required('Please select an account'),
  [RECEIVE_AMOUNT]: mixed().when([ FROM_ACCOUNT, BENEFICIARY_CURRENCY ],
    {
      is: (fromAccount, beneficiaryCurrency) =>
        isFxOutgoing({ [BENEFICIARY_CURRENCY]: beneficiaryCurrency },
          { [FROM_ACCOUNT]: fromAccount }),
      then: number()
        .nullable()
        .default(null)
        .required('Required field'),
      otherwise: mixed(),
    }),
  [PURPOSE_CODE]: object()
    .when(`formMetaData.${PURPOSE_CODE_REQUIRED}`, {
      is: purposeCodeRequired => purposeCodeRequired,
      then: object()
        .nullable()
        .required('Required field'),
      otherwise: object()
        .nullable()
        .notRequired(),
    }),
  [PAYMENT_REFERENCE]: string()
    .required('Required field')
    .max(MAX_PAYMENT_REFERENCE_LENGTH,
      `Max ${MAX_PAYMENT_REFERENCE_LENGTH} character limit`)
    .trim()
    .test('characters-are-valid', (value, context) => {
      const transliteratedValue = transliterate(value || '');
      const notAllowedCharacters = transliteratedValue.match(PAYMENT_REFERENCE_INVALID_CHARACTERS_PATTERN);

      return notAllowedCharacters
        ? context.createError({
          message: `Not allowed: ${notAllowedCharacters.join(' ')}`,
        })
        : true;
    }),
};

function prepareSendAmountValidation(values = {}) {
  const fromAccountAmount = get(values, `${FROM_ACCOUNT}.value.amount`, 0);
  const totalFeeAmount = toIndivisibleUnit(get(values, `${TOTAL_FEE_AMOUNT}.totalFeeAmount`, 0));

  return {
    [SEND_AMOUNT]: mixed().when([FROM_ACCOUNT], {
      is: fromAccount => fromAccount,
      then: number()
        .transform(transformSchemaAmount)
        .typeError('Required field')
        .when([ FROM_ACCOUNT, BENEFICIARY_CURRENCY ], {
          is: (fromAccount, beneficiaryCurrency) =>
            isFxOutgoing({
              [BENEFICIARY_CURRENCY]: beneficiaryCurrency,
            },
            { [FROM_ACCOUNT]: fromAccount }),
          then: number(),
        })
        .min(MIN_AMOUNT,
          `Amount should be equal or more than ${MIN_AMOUNT}`)
        .max(fromAccountAmount - totalFeeAmount, 'Not enough funds')
        .required('Required field'),
      otherwise: mixed(),
    }),
  };
}

export default function createAmountValidationSchema() {
  return lazy(values => {
    const sendAmountValidation = prepareSendAmountValidation(values);

    return object().shape({
      ...validationWithoutCalculations,
      ...sendAmountValidation,
    });
  });
}
