import dayjs from 'dayjs';

import { shortDateFormat } from 'constants/date-time';
import { getTimezone } from 'utils/helpers';

import { statementFormatsMap } from './constants';

export function isPdfFormat(value) {
  return value === statementFormatsMap.pdf;
}

export function formatQuery(format, dateRange, accounts) {
  let query = {
    timezone: getTimezone(),
    from: dayjs(dateRange.from).format(shortDateFormat),
    to: dayjs(dateRange.to).format(shortDateFormat),
  };

  switch (format) {
    case statementFormatsMap.csv:
      query = {
        ...query,
        accounts: accounts.map(({ value }) => value),
      };

      break;
    case statementFormatsMap.pdf:
      query = {
        ...query,
        accountId: accounts[0].value,
      };

      break;
    default:
      return null;
  }

  return query;
}
