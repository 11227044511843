import PropTypes from 'prop-types';
import React, { cloneElement, useState } from 'react';

import { approvalActions } from 'constants/api';
import { ResolveModal, TwoFaModal } from 'components';
import TwoFaProvider from 'contexts/two-fa';
import { showGeneralToastError, rethrowTwoFaError } from 'utils/errors';
import { getApprovalRequestMethodByAction, showSuccessNotificationByAction } from 'utils/pending-approval';
import { getBeneficiaries } from 'api/beneficiary';
import config from 'config';

function TransactionApproveReject({
  approvalRequestId,
  approveButton,
  rejectButton,
  onSuccess,
  createdAt,
  createdBy,
  toAccount,
  debitedAmount,
  netAmount,
  targetCurrency,
}) {
  const [ resolveTransactionModalIsOpen, setResolveTransactionModalIsOpen ] = useState(false);
  const [ resolveTransactionAction, setResolveTransactionAction ] = useState(null);
  const [ resolveTransactionData, setResolveTransactionData ] = useState(null);

  const [ showTrustBeneficiaryToggle, setShowTrustBeneficiaryToggle ] = useState(false);
  const [ resolveParams, setResolveParams ] = useState(null);

  const [ twoFaModalIsOpen, setTwoFaModalIsOpen ] = useState(false);
  const [ twoFaData, setTwoFaData ] = useState(null);

  function getScaData(challenge) {
    const sca = challenge ? { challenge } : {};

    return {
      approvalRequestId,
      amount: netAmount,
      currency: targetCurrency,
      beneficiary: toAccount.name,
      ...sca,
    };
  }

  function handleOpenResolveTransactionModal(action) {
    return async event => {
      event.stopPropagation();

      if (config.featureFlags.enableBeneficiariesTrustedFlow && action === approvalActions.approve) {
        const {
          numberOfRecords: numberOfNonTrustedBeneficiaries,
        } = await getBeneficiaries({ approvalRequestId, isTrusted: false, limit: 1 });

        setShowTrustBeneficiaryToggle(numberOfNonTrustedBeneficiaries > 0);
      }

      if (action === approvalActions.reject) {
        setShowTrustBeneficiaryToggle(false);
      }

      const data = {
        action,
        createdAt,
        createdByAuthor: createdBy.name,
        amount: debitedAmount,
        currency: targetCurrency,
      };

      setResolveTransactionData(data);
      setResolveTransactionModalIsOpen(true);
      setResolveTransactionAction(action);
    };
  }

  const handleResolveTransaction = async ({ isTrusted }) => {
    const data = getScaData();

    try {
      const { challenge } =
        await getApprovalRequestMethodByAction(resolveTransactionData.action, { ...data, isTrusted });

      if (challenge) {
        setTwoFaData(challenge);
        setResolveParams({ isTrusted });
        setTwoFaModalIsOpen(true);

        return;
      }

      onSuccess();
      showSuccessNotificationByAction(resolveTransactionData.action);
    } catch (error) {
      showGeneralToastError(error, { extractFromApi: true });
    }
  };

  const handleConfirmCode = async challenge => {
    const data = getScaData(challenge);

    try {
      await getApprovalRequestMethodByAction(resolveTransactionData.action, { ...data, ...resolveParams });
      onSuccess();
      setTwoFaModalIsOpen(false);
      showSuccessNotificationByAction(resolveTransactionData.action);
    } catch (error) {
      rethrowTwoFaError(error);
      setTwoFaModalIsOpen(false);
      showGeneralToastError(error, { extractFromApi: true });
    }
  };

  const handleResendCode = async (factor, scaId) => {
    const data = getScaData({ preferredFactor: factor, scaId });

    const response = await getApprovalRequestMethodByAction(resolveTransactionData.action, data);
    setTwoFaData(response?.challenge);

    return response;
  };

  return (
    <>
      {cloneElement(approveButton, { onClick: handleOpenResolveTransactionModal(approvalActions.approve) })}
      {cloneElement(rejectButton, { onClick: handleOpenResolveTransactionModal(approvalActions.reject) })}

      {/* Modals */}
      {resolveTransactionModalIsOpen && (
        <ResolveModal
          isOpen={resolveTransactionModalIsOpen}
          setIsOpen={setResolveTransactionModalIsOpen}
          onSubmit={handleResolveTransaction}
          action={resolveTransactionAction}
          data={resolveTransactionData}
          showTrustBeneficiaryToggle={showTrustBeneficiaryToggle}
        />
      )}
      {twoFaModalIsOpen && (
        <TwoFaProvider twoFaData={twoFaData}>
          <TwoFaModal
            isOpen={twoFaModalIsOpen}
            setIsOpen={setTwoFaModalIsOpen}
            onSubmit={handleConfirmCode}
            onResend={handleResendCode}
            challenge={twoFaData}
          />
        </TwoFaProvider>
      )}
    </>
  );
}

TransactionApproveReject.propTypes = {
  approvalRequestId: PropTypes.string,
  approveButton: PropTypes.element,
  rejectButton: PropTypes.element,
  onSuccess: PropTypes.func,
  createdAt: PropTypes.string,
  createdBy: PropTypes.instanceOf(Object),
  toAccount: PropTypes.instanceOf(Object),
  debitedAmount: PropTypes.number,
  netAmount: PropTypes.number,
  targetCurrency: PropTypes.string,
};

export default TransactionApproveReject;
