import { object, string } from 'yup';

import {
  CITY_ADDRESS_FIELD,
  COUNTRY_CODE,
  POSTAL_CODE_ADDRESS_FIELD,
  REGION_ADDRESS_FIELD,
  STREET_ADDRESS_FIELD,
} from '../../constants';

export const ChangeAddressSchema = object().shape({
  [COUNTRY_CODE]: string().required('Required field'),
  [CITY_ADDRESS_FIELD]: string().required('Required field'),
  [STREET_ADDRESS_FIELD]: string().required('Required field'),
  [REGION_ADDRESS_FIELD]: string(),
  [POSTAL_CODE_ADDRESS_FIELD]: string().required('Required field'),
});
