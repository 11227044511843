import PropTypes from 'prop-types';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { showGeneralToastError } from 'utils/errors';
import { GlobalLoaderContext } from 'contexts/global-loader';
import { viewProfile } from 'api/profile';
import { calculateUserPermissions } from 'utils/permissions';
import { getToken } from 'utils/storage';
import { getTokenPayload } from 'utils/token';

export const UserContext = createContext();

function UserProvider({ children }) {
  const [ permissions, setPermissions ] = useState({});
  const { startLoading, endLoading } = useContext(GlobalLoaderContext);
  const token = getToken();
  const [tokenPayload] = useState(getTokenPayload(token));
  const [ user, setUser ] = useState();

  useEffect(() => {
    if (!token) return;

    const loadProfile = async () => {
      startLoading();
      try {
        const profile = await viewProfile();

        setUser(profile);
      } catch (error) {
        showGeneralToastError(error);
      } finally {
        endLoading();
      }
    };

    loadProfile();
  }, []);

  useEffect(() => {
    if (!user) return;

    setPermissions(calculateUserPermissions(tokenPayload));
  }, [user]);

  const userContextValue = useMemo(() =>
    ({ tokenPayload, user, setUser, permissions }), [ user, tokenPayload, permissions ]);

  return (
    <UserContext.Provider value={userContextValue}>
      {children}
    </UserContext.Provider>
  );
}

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UserProvider;
