const config = {
  bffUrl: 'http://localhost:3051',
  envName: 'test',
  hubspotTrackingCode: '8471493',
  inactivityTimeout: 5,
  intercomId: '',
  survicateId: '',
  gtmId: '',
  onboardingUrl: 'http://localhost:3039/onboarding',
  registrationUrl: 'http://localhost:3039/onboarding',
  pciUrl: 'http://localhost:3061',
  sentryApiKey: '',
  featureFlags: {
    enableBeneficiariesTrustedFlow: true,
  },
};

export default config;
