import { Tabs, Title as DefaultTitle } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';

const Title = styled(DefaultTitle)`
  font-size: 1.625rem;
  margin-bottom: 1.625rem;

  @media ${DEVICE.tablet} {
    font-size: 2.625rem;
    margin: 0;
  }
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  padding: 0 1.25rem 2.7188rem 1.25rem;
  flex-direction: column;

  @media ${DEVICE.tablet} {
    padding: 1.0625rem 0 2.7188rem;
    flex-direction: row;
  }
`;

const TabsWrapper = styled.div`
  display: none;

  @media ${DEVICE.tablet} {
    display: block;
  }
`;

const TabsHead = styled(Tabs.Head)`
  margin: 0 0 0 1.1rem;
  padding: 0 0 0.8125rem 0;

  @media ${DEVICE.tablet} {
    margin: 0;
  }
`;

const MerchantsCount = styled.span`
  color: ${props => props.theme.colors.greyMediumDark};
  font-size: 0.875rem;
  margin-left: 0.3125rem;
`;

export default {
  Title,
  Header,
  TabsWrapper,
  TabsHead,
  MerchantsCount,
};
