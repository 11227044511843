import { Avatar as DefaultAvatar } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';
import { hex2rgba } from 'helpers/styles';

const User = styled.div`
  display: grid;
  padding: 1rem 1.25rem;
  background: ${props => props.theme.colors.white};
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  grid-template-columns: 2fr [profile] 2fr [status];
  align-items: flex-start;
  border-bottom: 0.025rem solid ${props => hex2rgba(props.theme.colors.black, 0.12)};

  @media ${DEVICE.tablet} {
    grid-template-columns: 2fr [profile] 2fr [roles] 1fr [status] 0fr [actions];
    align-items: center;
    box-shadow: 0 0.3125rem 1rem ${props => hex2rgba(props.theme.colors.black, 0.05)}, inset 0 0 0.0625rem ${props => hex2rgba(props.theme.colors.black, 0.3)};
    border-radius: 0.5rem;
    border-bottom: 0;
    margin: 0.625rem 0;

    &:hover {
      box-shadow: 0 0.3125rem 1rem ${props => hex2rgba(props.theme.colors.black, 0.12)}, inset 0 0 0.0625rem ${props => hex2rgba(props.theme.colors.black, 0.3)};
    }
  }

  @media ${DEVICE.laptop} {
    grid-template-columns: 2fr [profile] 2fr [email] 2fr [roles] 1fr [status] 0fr [actions];
  }
`;

const Profile = styled.div`
  margin: 0;
  display: flex;
  align-items: flex-start;

  @media ${DEVICE.tablet} {
    align-items: center;
  }
`;

const Avatar = styled(DefaultAvatar)`
  margin-right: 0.8rem;
  opacity: ${props => props.dimmed ? '0.5' : '1'};
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  flex-shrink: 0;
`;

const UserName = styled.p`
  font-weight: 600;
  display: inline-block;
  margin: 0;
  color: ${props => props.dimmed ? props.theme.colors.greyMedium : props.theme.colors.black};
`;

const Email = styled.p`
  display: none;
  margin: 0;
  font: ${props => props.theme.fonts.bodyS};
  color: ${props => props.theme.colors.greyMedium};

  @media ${DEVICE.laptop} {
    display: inline-block;
  }
`;

const RolesWrapper = styled.div`
  order: 3;
  grid-column: 1/3;
  margin-left: 2.8rem;

  @media ${DEVICE.tablet} {
    order: unset;
    grid-column: unset;
  }
`;

const Roles = styled.div`
  margin: 0;
  color: ${props => props.theme.colors.black};
`;

const Status = styled.div`
  margin: 0;
  text-align: right;
`;

const Actions = styled.div`
  margin: 0;
  align-items: center;
  display: none;

  @media ${DEVICE.tablet} {
    display: flex;
    margin-left: 1.875rem;
  }
`;

const Action = styled.div`
  font: ${props => props.theme.fonts.bodyS};
  color: ${props => props.red ? props.theme.colors.red : props.theme.colors.black};
  padding: 0.625rem 1rem;
`;

const Button = styled.a`
  align-items: center;
  border-radius: 0.25rem;
  border: none;
  background: ${props => hex2rgba(props.red ? props.theme.colors.red : props.theme.colors.blue, 0.08)};
  opacity: ${props => (props.disabled ? 0.6 : 1)};
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  display: flex;
  height: 1.75rem;
  justify-content: center;
  width: 1.75rem;
  z-index: 1;
  margin-left: 1rem;

  svg {
    fill: ${props => (props.red ? props.theme.colors.red : props.theme.colors.blue)};
    opacity: ${props => (props.disabled ? 0.6 : 1)};  
  }

  &:hover {
    svg {
      fill: ${props => (props.red ? props.theme.colors.red : props.theme.colors.blue)};
      opacity: 0.8;  
    }
  }
`;

export default {
  User,
  Profile,
  Avatar,
  UserName,
  Email,
  Roles,
  RolesWrapper,
  Status,
  Actions,
  Action,
  Button,
};
