import { Title as DefaultTitle } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';

const Title = styled(DefaultTitle)`
  font: ${props => props.theme.fonts.h3Bold};
  margin-bottom: 1.625rem;

  @media ${DEVICE.tablet} {
    font: ${props => props.theme.fonts.h1Bold};
    margin: 0;
  }
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  padding: 0 1.25rem 2.5rem 1.25rem;
  flex-direction: column;

  @media ${DEVICE.tablet} {
    padding: 1.0625rem 0 2.625rem 0;
    flex-direction: row;
  }

  @media ${DEVICE.laptop} {
    padding: 1.0625rem 0 2.625rem;
    flex-direction: row;
  }
`;

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0.5rem auto;
  padding: 0 1.25rem;
  width: 100%;

  @media ${DEVICE.tablet} {
    max-width: 44.75rem;
    margin-top: 0.1875rem;
    padding: 0;
  }

  @media ${DEVICE.desktop} {
    margin-top: 2.5rem;
  }
`;

const Paragraph = styled.p`
  color: ${props => props.theme.colors.black};
  font: ${props => props.theme.fonts.bodyMSemi};
  line-height: 1.5;
  margin: 2.5rem 0 1rem;
  text-align: center;

  @media ${DEVICE.tablet} {
    font: ${props => props.theme.fonts.bodyLSemi};
    max-width: 90%;
  }
`;

const SubParagraph = styled(Paragraph)`
  font: ${props => props.theme.fonts.bodyS};
  font-weight: 300;
  letter-spacing: 0.02em;
  margin: 0 0 1rem 0;
  max-width: 100%;

  @media ${DEVICE.tablet} {
    margin-bottom: 0;
  }
`;

const Link = styled.a`
  color: ${props => props.theme.colors.blue};
  font-weight: 500;
`;

const Image = styled.img`
  display: block;
  max-width: 15rem;
  width: 100%;

  @media ${DEVICE.tablet} {
    max-width: 25rem;
  }
`;

export default { Container, Header, Link, Paragraph, SubParagraph, Title, Image };
