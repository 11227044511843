import dayjs from 'dayjs';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { minutesTill } from 'utils/time';
import { TwoFaContext } from 'contexts/two-fa';
import { ERRORS } from 'constants/errors';
import { Hint } from 'components';
import { ReactComponent as MessageIcon } from 'assets/icons/message.svg';
import { ReactComponent as CallIcon } from 'assets/icons/call.svg';

import S from './styles';
import { CALLING, SMS, SMS_SENT, VOICE } from './constants';

const duration = require('dayjs/plugin/duration');

dayjs.extend(duration);

function TwoFa({ resend, CTAButtons, isModal, isVoiceCallEnabled }) {
  const { t } = useTranslation();
  const {
    handleSubmit,
    handleChange,
    values,
    touched,
    errors,
    isSubmitting,
    newChallenge,
    isNonceExpired,
    clearNonceInput,
    remainingTime,
    twoFaData,
  } = useContext(TwoFaContext);
  const [ smsOptionSelected, setSmsOptionSelected ] = useState(true);
  const {
    truncatedPhoneNumber,
    newChallengeAvailableAt,
  } = twoFaData;

  const onResend = factor => {
    setSmsOptionSelected(factor === SMS);
    resend(factor);
  };

  const getResendButtons = () => newChallenge?.isAvailable ? (
    <S.ResendContainer>
      <S.Link icon={<MessageIcon />}
        category="link"
        label={t('twoFa.form.resendButtons.resendCode')}
        onClick={() => onResend(SMS)}
        disabled={isSubmitting}
      />
      {isVoiceCallEnabled && (
        <>
          <S.Divider>or</S.Divider>
          <S.Link icon={<CallIcon />}
            category="link"
            label={t('twoFa.form.resendButtons.receiveCall')}
            onClick={() => onResend(VOICE)}
            disabled={isSubmitting}
          />
        </>
      )}
    </S.ResendContainer>
  ) : (
    <S.ResendContainer>
      <Hint text={ERRORS.tryInXMinutes(minutesTill(newChallengeAvailableAt))}>
        <S.ButtonWrapper>
          <S.Link icon={<MessageIcon />} category="link" label={t('twoFa.form.resendButtons.resendCode')} disabled />
        </S.ButtonWrapper>
      </Hint>
      {isVoiceCallEnabled && (
        <>
          <S.Divider>or</S.Divider>
          <Hint text={ERRORS.tryInXMinutes(minutesTill(newChallengeAvailableAt))}>
            <S.ButtonWrapper>
              <S.Link icon={<CallIcon />} category="link" label={t('twoFa.form.resendButtons.receiveCall')} disabled />
            </S.ButtonWrapper>
          </Hint>
        </>
      )}
    </S.ResendContainer>
  );

  const getTimer = () => {
    const timerDuration = dayjs.duration(remainingTime, 'seconds');
    const minutes = timerDuration.minutes();
    const minutesString = minutes > 0 ? pluralize('minute', minutes, true) : '';
    const secondsString = pluralize('second', timerDuration.seconds(), true);

    return t('twoFa.form.timer', { minutesString, secondsString });
  };

  const getContent = () => {
    const content = (
      <>
        <S.Subtitle>
          {smsOptionSelected ? SMS_SENT : CALLING}
          {' '}
          {truncatedPhoneNumber}
        </S.Subtitle>
        <S.Text isModal={isModal}>
          {isNonceExpired ? t('twoFa.form.invalidCode') : getTimer()}
        </S.Text>
        <S.Input
          name="nonce"
          id="nonce"
          label={t('twoFa.form.input.label')}
          onChange={handleChange}
          onClear={clearNonceInput}
          value={values.nonce}
          disabled={isSubmitting}
          error={touched.nonce && errors.nonce || errors?.code?.message}
          inputMode="numeric"
          autoComplete="one-time-code"
          autoFocus
        />
        <S.Resend>
          Did not receive SMS?
          {getResendButtons()}
        </S.Resend>
        {CTAButtons}
      </>
    );

    if (isModal) {
      return content;
    }

    return (
      <form onSubmit={handleSubmit}>
        {content}
      </form>
    );
  };

  return getContent();
}

TwoFa.propTypes = {
  resend: PropTypes.func.isRequired,
  CTAButtons: PropTypes.node,
  isModal: PropTypes.bool,
  isVoiceCallEnabled: PropTypes.bool,
};

export default TwoFa;
