import { useEffect, useState } from 'react';

// taken from https://usehooks.com/useWindowSize/
function useWindowSize() {
  const [ windowSize, setWindowSize ] = useState({
    width: null,
    height: null,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener('resize', handleResize);

    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
}

export default useWindowSize;
