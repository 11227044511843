import styled from 'styled-components';

const IconWrapper = styled.div`
  background-color: ${props => props.isActive
  ? props.theme.colors.greyLight
  : props.theme.colors.greyBright};
  height: ${props => `${props.size}rem` };
  width: ${props => `${props.size}rem` };
  padding: 0.5rem;
  opacity: ${props => props.isDisabled ? 0.3 : 1};
  border-radius: 50%;
  overflow: hidden;
  position: relative;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) ${props => props.size === 2 ? 'scale(0.66)' : 'scale(1)'};

    fill: ${props => props.theme.colors.black};

    path {
      fill: ${props => props.theme.colors.black};
    }
  }
`;

export default {
  IconWrapper,
};
