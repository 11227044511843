export const fxCurrencies = [
  'EUR',
  'USD',
  'GBP',
  'SEK',
  'NOK',
  'DKK',
  'PLN',
  'AUD',
  'CAD',
];

export const fxCurrenciesMap = fxCurrencies.reduce((res, currency) => ({ [currency]: currency, ...res }),
  {});

// FX begins on Sunday 17:02 ET and closes Friday 17:00 ET*
export const FX_DEFAULT_TIMEZONE = 'Europe/Berlin';
export const SUNDAY_DAY_NUMBER = 0;
export const FX_SUNDAY_START_HOUR = 23;
export const FX_SUNDAY_START_MINUTE = 2;
export const FRIDAY_DAY_NUMBER = 5;
export const FX_FRIDAY_END_HOUR = 23;
export const SATURDAY_DAY_NUMBER = 6;
export const FX_SATURDAY_END_HOUR = 23;
export const FX_SATURDAY_END_MINUTE = 59;
export const FX_SATURDAY_END_SECOND = 59;
