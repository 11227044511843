import PropTypes from 'prop-types';

import { cardCreationStatusMap } from 'pages/order-cards/constants';

export const cardShape = {
  card: PropTypes.shape({
    id: PropTypes.string,
    displayName: PropTypes.string,
    currency: PropTypes.string,
    availableBalance: PropTypes.number,
    type: PropTypes.string,
    status: PropTypes.string,
    brand: PropTypes.string,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    expiry: PropTypes.shape({
      year: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      month: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    }),
    account: PropTypes.shape({
      name: PropTypes.string,
      referenceId: PropTypes.string,
      iban: PropTypes.string,
    }),
    cardholder: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      id: PropTypes.string,
    }),
    embossedData: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    pan: PropTypes.shape({
      bin: PropTypes.string,
      lastFour: PropTypes.string,
    }),
  }),
};

export const cardCredentialsShape = PropTypes.shape({
  cvc2: PropTypes.string,
  pan: PropTypes.string,
});

export const cardHolderShape = PropTypes.shape({
  city: PropTypes.string,
  countryCode: PropTypes.string,
  email: PropTypes.string,
  firstName: PropTypes.string,
  id: PropTypes.string,
  identity: PropTypes.shape({
    id: PropTypes.string,
    kycStatus: PropTypes.string,
  }),
  lastName: PropTypes.string,
  phone: PropTypes.string,
  postalCode: PropTypes.string,
  region: PropTypes.string,
  street: PropTypes.string,
  label: PropTypes.string,
});

export const orderCardAddressShape = PropTypes.shape({
  city: PropTypes.string,
  countryCode: PropTypes.string,
  postalCode: PropTypes.string,
  region: PropTypes.string,
  street: PropTypes.string,
  type: PropTypes.string,
});

export const orderCardExpiryShape = PropTypes.shape({
  year: PropTypes.string,
  month: PropTypes.string,
});

export const corporationShape = PropTypes.shape({
  address: orderCardAddressShape,
  createdAt: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  status: PropTypes.string,
});

export const orderCardShape = PropTypes.shape({
  cardHolder: cardHolderShape,
  address: orderCardAddressShape,
  displayName: PropTypes.string,
  expiry: orderCardExpiryShape,
  status: PropTypes.oneOf(Object.values(cardCreationStatusMap)),
  id: PropTypes.string,
});
