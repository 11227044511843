import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { ViewContext } from 'contexts/view';
import { NavigationBackButton } from 'components';

import S from './styles';

function WizardLayout({ children, title, displayCancelButton, hideBackButton, handleCancel, isFullscreen }) {
  const { currentView, triggerView }  = useContext(ViewContext);

  return (
    <S.Main>
      <S.Wrapper isFullscreen={isFullscreen}>
        <S.Header>
          {!hideBackButton && (
            <NavigationBackButton onClick={() => triggerView(currentView)} />
          )}
          <S.Title backButtonHidden={hideBackButton}>
            {title}
          </S.Title>
          {
            displayCancelButton && (
              <S.CancelButton label="Cancel" category="secondary" handleClick={handleCancel}>
                Cancel
              </S.CancelButton>
            )}
        </S.Header>
        <S.Content isFullscreen={isFullscreen}>
          {children}
        </S.Content>
      </S.Wrapper>
    </S.Main>
  );
}

WizardLayout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  displayCancelButton: PropTypes.bool,
  hideBackButton: PropTypes.bool,
  isFullscreen: PropTypes.bool,
  handleCancel: PropTypes.func,
};

export default WizardLayout;
