import { object, ref, string } from 'yup';

import { cvc2DigitNumber, pinDigitNumber } from 'constants/card';

const ActivateCardSchema = object().shape({
  cvc2: string()
    .required('Please enter CVC2 number')
    .test('three-digits',
      'Card CVC2 length should be 3 characters',
      value => String(value).length === cvc2DigitNumber),
  pin: string()
    .required('Please enter PIN number')
    .test('four-digits', 'Length should be 4 characters', value => String(value).length === pinDigitNumber),
  confirmationPin: string()
    .required('Please enter Confirm PIN number')
    .oneOf([ ref('pin'), null ], 'Values in PIN and Confirm PIN fields are not matching'),
});

export default ActivateCardSchema;
