import PropTypes from 'prop-types';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { Dash } from 'components';

import S from './styles';

function Details({ list }) {
  return (
    <S.Grid data-testid="details-wrapper">
      {list.map(({ label, value }) => (
        <S.GridChild key={uuidv4()}>
          <S.Heading>
            {label}
          </S.Heading>
          <S.SubHeading>
            {value || <Dash />}
          </S.SubHeading>
        </S.GridChild>
      ))}
    </S.Grid>
  );
}

Details.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string,
    ]),
  })).isRequired,
};

export default Details;
