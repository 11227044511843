import React from 'react';
import { t } from 'i18next';
import upperFirst from 'lodash/upperFirst';
import get from 'lodash/get';

import { BENEFICIARY_PATHS_MAP, RAILS_MAP } from 'pages/send-funds/constants';
import { corporateCountries } from 'pages/order-cards/constants';
import { CURRENCY_FLAGS } from 'constants/currencies';

import S from './styles';

const BENEFICIARY_CURRENCY = 'beneficiary.fields.currency';
const BENEFICIARY_TYPE = 'beneficiary.fields.type';
const BENEFICIARY_NAME = 'beneficiary.fields.name';
const BENEFICIARY_FIRST_NAME = 'beneficiary.fields.firstName';
const BENEFICIARY_LAST_NAME = 'beneficiary.fields.lastName';
const BENEFICIARY_PAYMENT_TYPE = 'beneficiary.fields.paymentType';
const BENEFICIARY_BANK_COUNTRY = 'beneficiary.fields.bankCountry';
const BENEFICIARY_COUNTRY = 'beneficiary.fields.country';
const BENEFICIARY_CITY = 'beneficiary.fields.city';
const BENEFICIARY_ADDRESS = 'beneficiary.fields.address';
const BENEFICIARY_ACCOUNT_NUMBER = 'beneficiary.fields.number';
const BENEFICIARY_BANK_CODE = 'beneficiary.fields.bank_code';

export const detailsFields = [
  {
    label: t(BENEFICIARY_CURRENCY),
    fields: ['account.currency'],
    formatValue: value => (
      <S.FlagWrapper>
        <S.Flag countryCode={CURRENCY_FLAGS[value]} />
        {value}
      </S.FlagWrapper>
    ),
  },
  {
    label: t(BENEFICIARY_TYPE),
    fields: ['entityType'],
    formatValue: value => upperFirst(value),
  },
  {
    label: t(BENEFICIARY_FIRST_NAME),
    fields: ['firstName'],
  },
  {
    label: t(BENEFICIARY_LAST_NAME),
    fields: ['lastName'],
  },
  {
    label: t(BENEFICIARY_NAME),
    fields: ['name'],
    formatValue: (value, beneficiary) => (beneficiary.firstName && beneficiary.lastName) ? null : value,
  },
  {
    label: t(BENEFICIARY_PAYMENT_TYPE),
    fields: ['account.paymentRail'],
    formatValue: value => `${upperFirst(RAILS_MAP[value]?.binaryRail)} payment`,
  },
  {
    label: t(BENEFICIARY_BANK_COUNTRY),
    fields: ['account.country'],
    formatValue: value => corporateCountries[value],
  },
  {
    label: t(BENEFICIARY_COUNTRY),
    fields: ['address.country'],
    formatValue: value => corporateCountries[value],
  },
  {
    label: t(BENEFICIARY_CITY),
    fields: ['address.city'],
    formatValue: value => upperFirst(value),
  },
  {
    label: t(BENEFICIARY_ADDRESS),
    fields: [ 'address.region', 'address.street', 'address.postCode' ],
  },
  {
    label: t(BENEFICIARY_ACCOUNT_NUMBER),
    fields: ['account.number.value'],
    formatLabel: beneficiary => {
      const defaultLabel = t('beneficiary.fields.number');
      const numberType = get(beneficiary, 'account.number.type');

      return BENEFICIARY_PATHS_MAP[`account.number.${numberType}`]?.label || defaultLabel;
    },
  },
  {
    label: t(BENEFICIARY_BANK_CODE),
    fields: ['account.bankCode.value'],
    formatLabel: beneficiary => {
      const defaultLabel = t('beneficiary.fields.bank_code');
      const bankCodeType = get(beneficiary, 'account.bankCode.type');

      return BENEFICIARY_PATHS_MAP[`account.bankCode.${bankCodeType}`]?.label || defaultLabel;
    },
  },
];
