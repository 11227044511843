import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useHistory } from 'react-router';

import { filterNames } from 'pages/activity/constants';
import makeStore from 'utils/context';
import { getActiveAccounts, getParameterFromURL, getStatusFromURLArray, getTypeFromURL } from 'utils/helpers';

import { AccountsContext } from '../accounts';

export const FilterStore = makeStore();

function FiltersProvider({ children }) {
  const history = useHistory();
  const { accounts } = useContext(AccountsContext);

  const status = getParameterFromURL(filterNames.status, history.location);
  const type = getParameterFromURL(filterNames.type, history.location) || '';
  const selectedAccounts = getParameterFromURL(filterNames.accounts, history.location);
  const from = getParameterFromURL(filterNames.date.from, history.location) || undefined;
  const to = getParameterFromURL(filterNames.date.to, history.location) || undefined;
  const initialStatusList = getStatusFromURLArray(status);
  const initialTypeList = getTypeFromURL(type);
  const initialAccountList = getActiveAccounts(accounts, selectedAccounts);
  const dateFromUTC = from ? dayjs(from).toDate() : undefined;
  const dateToUTC = to ? dayjs(to).toDate() : undefined;

  const initialState = {
    [filterNames.status]: initialStatusList,
    [filterNames.type]: initialTypeList,
    [filterNames.accounts]: initialAccountList,
    [filterNames.date.general]: { from: dateFromUTC, to: dateToUTC },
  };

  return (
    <FilterStore.Provider initialState={initialState}>
      {children}
    </FilterStore.Provider>
  );
}

FiltersProvider.propTypes = {
  children: PropTypes.node,
};

export default FiltersProvider;
