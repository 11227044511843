import styled from 'styled-components';

import { TransactionSubHeading } from 'components/transaction-list/styles';
import { getColorsByStatus } from 'helpers/styles';

const TransactionStatus = styled(TransactionSubHeading)`
  font: inherit;
  margin: 0;
`;

const TransactionSubStatus = styled(TransactionSubHeading)`
  text-align: end;
`;

const LabelWrapper = styled.span`
  text-align: end;
  color: ${props => getColorsByStatus(props.status, props.theme.colors)};
  display: flex;
  align-items: center;
  line-height: 1.25rem;
`;

const TransactionHint = styled.div`
  display: flex;
  margin-left: 0.375rem;
`;

const SubLabelWrapper = styled.span`
  color: ${props => props.theme.colors.greyMedium};
  display: ${props => props.isHidden ? 'none' : 'inline'};

  ${props => (!props.isInline && !props.isHidden ? 'display: flex;' : '')};
`;

export default {
  TransactionStatus,
  LabelWrapper,
  SubLabelWrapper,
  TransactionSubStatus,
  TransactionHint,
};
