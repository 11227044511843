import { Checkbox } from '@general/intergiro-ui-kit';
import { useFormik } from 'formik';
import isEqual from 'lodash/isEqual';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { setPassword, verifyToken } from 'api/auth';
import config from 'config';
import { HIGH_SCORE_COMPLEXITY } from 'constants/inputs';
import { navigation } from 'constants/navigation';
import { showGeneralToastError } from 'utils/errors';
import { LoginLayout } from 'layouts';
import { GlobalLoaderContext } from 'contexts/global-loader';

import { formFields } from './constants';
import { SetPasswordSchema } from './schema';
import S from './styles';

function SetPassword() {
  const [ passwordScore, setPasswordScore ] = useState({});
  const [ accessToken, setAccessToken ] = useState(null);
  const { token: ephemeralToken } = useParams();
  const { startLoading, endLoading } = useContext(GlobalLoaderContext);

  const history = useHistory();
  const formik = useFormik({
    initialValues: {
      [formFields.confirmPassword.name]: '',
      [formFields.newPassword.name]: '',
      [formFields.privacy.name]: false,
    },
    validationSchema: SetPasswordSchema,
    validateOnChange: false,
    validateOnMount: true,
  });
  const {
    setFieldError,
    touched,
    errors,
    isSubmitting,
    setSubmitting,
    values,
    setFieldValue,
    setFieldTouched,
    isValid,
  } = formik;

  useEffect(() => {
    checkEphemeralToken(ephemeralToken);
  }, [ ephemeralToken, checkEphemeralToken ]);

  const checkEphemeralToken = useCallback(async tokenToVerify => {
    startLoading();
    try {
      const verificationResponse = await verifyToken(tokenToVerify);

      if (!verificationResponse?.accessToken) {
        toast.error(
          'Unfortunately, the invitation link is invalid or expired. Please contact customer support.',
          {
            autoClose: false,
            position: 'bottom-left',
          },
        );
        history.push(navigation.login);
      } else {
        setAccessToken(verificationResponse.accessToken);
      }
    } catch {
      toast.error(
        'Unfortunately, the invitation link is invalid or expired. Please contact customer support.',
        {
          autoClose: false,
          position: 'bottom-left',
        },
      );
      history.push(navigation.login);
    } finally {
      endLoading();
    }
  }, [history]);

  const onPasswordScoreChange = useCallback(event => {
    if (!isEqual(passwordScore, event)) {
      setPasswordScore(event);
    }
  }, [passwordScore]);

  const onChange = useCallback(event => {
    setFieldValue(event.target.name, event.target.value, true);
  }, [setFieldValue]);

  function setCheckboxChecked(name) {
    // eslint-disable-next-line func-names
    return function() {
      setFieldValue(name, true, true);
    };
  }

  const onBlur = useCallback(event => {
    setFieldTouched(event.target.name, true);
  }, [setFieldTouched]);

  const resetPassword = useCallback(async () => {
    const password = values[formFields.confirmPassword.name];
    const newPassword = values[formFields.newPassword.name];

    try {
      await setPassword(password, newPassword, accessToken);
      toast.success('You have successfully signed up. Please login to use your account.');
      history.push(navigation.login);
    } catch (error) {
      showGeneralToastError(error);
      history.push(navigation.notFound);
    }

  }, [ values, accessToken, history ]);

  const handleSubmit = useCallback(event => {
    event.preventDefault();

    const isLowScore = passwordScore?.score < passwordScore?.minScore;

    if (isLowScore) {
      setFieldError(formFields.newPassword.name, 'Password is not strong enough.');

      return;
    }
    setSubmitting(true);

    resetPassword();
  }, [ setFieldError, passwordScore?.score, passwordScore?.minScore, setSubmitting, resetPassword ]);

  return (
    <LoginLayout allowSignUp={false}>
      <S.Wrapper>
        <S.Title>Set new password</S.Title>
        <S.Form onSubmit={handleSubmit}>
          <S.Input
            disabled={isSubmitting}
            error={touched[formFields.newPassword.name] && errors[formFields.newPassword.name]}
            id={formFields.newPassword.name}
            isCheckingStrength
            scoreComplexity={HIGH_SCORE_COMPLEXITY}
            onScoreChange={onPasswordScoreChange}
            isPassword
            label={formFields.newPassword.label}
            name={formFields.newPassword.name}
            onChange={onChange}
            onBlur={onBlur}
          />
          <S.Input
            disabled={isSubmitting}
            error={touched[formFields.confirmPassword.name] && errors[formFields.confirmPassword.name]}
            id={formFields.confirmPassword.name}
            isCheckingStrength
            scoreComplexity={HIGH_SCORE_COMPLEXITY}
            isPassword
            label={formFields.confirmPassword.label}
            name={formFields.confirmPassword.name}
            onChange={onChange}
            onBlur={onBlur}
          />
          <S.CheckboxWrapper>
            <Checkbox
              checked={values[formFields.privacy.name]}
              isInverse
              name={formFields.privacy.name}
              onChange={setCheckboxChecked(formFields.privacy.name)}
            >
              I accept
              {' '}
              <S.Link
                onClick={setCheckboxChecked(formFields.privacy.name)}
                href={config.corporateSiteUrl + formFields.privacy.url}
                target="_blank"
              >
                Privacy Policy
              </S.Link>
            </Checkbox>
          </S.CheckboxWrapper>
          <S.ControlWrapper>
            <S.Button type="submit" label="Submit" disabled={isSubmitting || !isValid} />
          </S.ControlWrapper>
        </S.Form>
      </S.Wrapper>
    </LoginLayout>
  );
}

export default SetPassword;
