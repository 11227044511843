import { Button, Dropdown } from '@general/intergiro-ui-kit';
import { CircleFlag } from 'react-circle-flags';
import styled from 'styled-components';

import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right-thin.svg';
import { ReactComponent as ChevronDownIcon } from 'assets/icons/chevron-down.svg';
import { ReactComponent as ChevronUpIcon } from 'assets/icons/chevron-up.svg';
import { ReactComponent as DefaultCSVIcon } from 'assets/icons/csv.svg';
import { ReactComponent as DefaultPlusIcon } from 'assets/icons/plus.svg';
import { DEVICE } from 'constants/breakpoints';

const Row = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: ${props => (props.total ? '0.625rem' : 0)};

  @media ${DEVICE.laptop} {
    flex-direction: row;
    margin-top: ${props => (props.total ? '5rem' : 0)};
  }
`;

const ArrowRight = styled(ArrowRightIcon)`
  display: none;

  path {
    fill: ${props => props.theme.colors.greyLight};
  }

  @media ${DEVICE.laptop} {
    display: block;
    width: 7.9375rem;
    padding: 0 2.125rem;
  }
`;

const ActionColumn = styled.div`
  padding-left: 1.25rem;
  min-width: 5.6875rem;
`;

const PlusIconWrapper = styled.div`
  width: 2rem;
  height: 2rem;
  position: relative;
  background: ${props => props.theme.colors.greyBright};
  border-radius: 50%;
  margin-right: 0.75rem;
`;

const PlusIcon = styled(DefaultPlusIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.667);

  fill: ${props => props.theme.colors.greyMedium};
`;

const AddBeneficiaryOption = styled.div`
  display: flex;
  align-items: center;
`;

const AddBeneficiaryOptionLabel = styled.p`
  margin: 0;
`;

const Separator = styled.div`
  display: none;

  @media ${DEVICE.laptop} {
    display: block;
    width: 7.9375rem;
    padding: 0 2.125rem;
  }
`;

const InputContainer = styled.div`
  width: 100%;
  margin-bottom: 1.25rem;

  text-align: ${props => props.centerContent && 'center'};

  @media ${DEVICE.laptop} {
    margin-bottom: 0;
  }
`;

const OrSeparator = styled.p`
  color: ${props => props.theme.colors.greyMedium};
  font-size: 0.875rem;
  font-weight: 400;
  border-radius: 0.625rem;
  background: ${props => props.theme.colors.greyBright};
  padding: 0.375rem;
  display: inline-block;
`;

const TableRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TableNameRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.125rem 0 1.0625rem 1.125rem;
  align-items: center;
`;

const TableRowSeparator = styled.hr`
  background: ${props => props.theme.colors.black};
  opacity: 0.1;
  border-radius: 0.4375rem;
  border-top: 0.0625rem solid ${props => props.theme.colors.black};
  margin: 0 5.6875rem 0 0;
`;

const TableColumnName = styled.p`
  margin: 0;
  margin-right: ${props => (props.offset ? '2.5rem' : 0)};
  position: relative;
  font: ${props => props.theme.fonts.bodyS};
  color: ${props => props.theme.colors.greyMedium};
  text-align: ${props => (props.left ? 'left' : 'right')};
  flex: ${props => (props.wide ? 4 : 1)};
  width: 100%;
`;

const TotalWrapper = styled.div`
  flex: 2;
  margin-left: 2.5rem;
  display: ${props => (props.visible ? 'block' : 'none')};
  padding-top: 1.875rem;
`;

const CSVIconContainer = styled.div`
  border: 0.125rem dashed ${props => props.theme.colors.greyLight};
  border-radius: 0.5rem;
  padding: 1rem 1.25rem;
  max-height: 4.5rem;
  max-width: 4.5rem;
`;

const CSVIcon = styled(DefaultCSVIcon)`
  width: 2rem;
  height: 2.5rem;
`;

const Table = styled.div`
  margin: 4.875rem 0 0 0;
  padding-bottom: ${props => props.totalFooterContainer?.offsetHeight || 0}px;
`;

const TotalFooter = styled.div`
  padding: 1.25rem 10.5rem;
  border-top: 0.0625rem solid ${props => props.theme.colors.greyLight};
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  display: ${props => (props.visible ? 'flex' : 'none')};
  justify-content: space-between;
  align-items: center;
  backdrop-filter: blur(1.6875rem);
  -webkit-backdrop-filter: blur(1.6875rem); /* stylelint-disable-line property-no-vendor-prefix */
`;

const ContinueButton = styled(Button)`
  margin: 0;
  align-self: flex-end;
`;

const TotalAmountLabel = styled.p`
  font: ${props => props.theme.fonts.bodyMBold};
  margin: 0;
`;

const TotalAmountValue = styled.p`
  font: ${props => props.theme.fonts.bodyMBold};
  margin: 0;
  text-align: right;
`;

const TotalAmountLabelContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: 3.125rem;
  margin-bottom: ${props => (props.visible ? '1.3438rem' : 0)};
  position: relative;
`;

const TotalAmountFees = styled.p`
  margin: 0;
  font: ${props => props.theme.fonts.bodyS};
  color: ${props => props.theme.colors.greyMedium};
`;

const TotalAmountFeesValue = styled.p`
  margin: 0;
  font: ${props => props.theme.fonts.bodyS};
  color: ${props => props.theme.colors.greyMedium};
`;

const TotalAmountDetails = styled.p`
  margin: 0;
  font: ${props => props.theme.fonts.bodyXs};
  color: ${props => props.theme.colors.greyMedium};
  padding-left: 0.3125rem;
`;

const TotalFooterAmountContainer = styled.div`
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 25rem;
  padding-right: 5.6875rem;
`;

const ChevronUp = styled(ChevronUpIcon)`
  width: 1rem;
  height: 1rem;
  margin-right: 0.8125rem;
  fill: ${props => props.theme.colors.black};
  cursor: pointer;
  position: absolute;
  right: 100%;
`;

const ChevronDown = styled(ChevronDownIcon)`
  width: 1rem;
  height: 1rem;
  margin-right: 0.8125rem;
  fill: ${props => props.theme.colors.black};
  cursor: pointer;
  position: absolute;
  right: 100%;
`;

const TotalFooterAmountRow = styled.div`
  margin: 0;
  width: 100%;
  justify-content: space-between;
  display: ${props => (props.visible ? 'flex' : 'none')};
  margin-top: ${props => props.marginTop || '0'};
`;

const Flag = styled(CircleFlag)`
  height: 1.25rem;
  order: 1;
`;

const BeneficiaryDropdown = styled(Dropdown)`
  .select__option:hover ${/* sc-selector */ PlusIconWrapper},
  .select__option--is-focused ${/* sc-selector */ PlusIconWrapper} {
    background: ${props => props.theme.colors.greyLight};
  }

  .select__indicators {
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export default {
  AddBeneficiaryOption,
  AddBeneficiaryOptionLabel,
  PlusIcon,
  PlusIconWrapper,
  ArrowRight,
  InputContainer,
  Row,
  ActionColumn,
  Separator,
  OrSeparator,
  TableRow,
  TableColumnName,
  TableRowSeparator,
  TableNameRow,
  TotalWrapper,
  CSVIconContainer,
  CSVIcon,
  TotalFooter,
  Table,
  ContinueButton,
  TotalAmountDetails,
  TotalFooterAmountContainer,
  ChevronUp,
  ChevronDown,
  TotalAmountLabel,
  TotalAmountLabelContainer,
  TotalAmountValue,
  TotalAmountFees,
  TotalAmountFeesValue,
  TotalFooterAmountRow,
  BeneficiaryDropdown,
  Flag,
};
