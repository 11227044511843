import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import { moveFunds } from 'api/payment';
import { AccountName, ScrollToTop } from 'components';
import { GlobalLoaderContext } from 'contexts/global-loader';
import {
  fromAccountFieldName,
  MOVE_FUNDS_CONFIRMATION,
  MOVE_FUNDS_FINAL,
  MOVE_FUNDS_FORM,
  toAccountFieldName,
} from 'pages/move-funds/constants';
import { MoveFundsSchema as validationSchema } from 'pages/move-funds/services/schema';
import {
  getConfirmationFromBoxContent,
  getReceiveAmount,
  prepareMoveFundsRequest,
  preparePollContext,
  sanitizePolls,
  shouldRedirectToAmountError,
  updatePoll,
} from 'pages/move-funds/services/utils';

import S from './styles';

function MoveFundsConfirmation({
  formValues,
  setFormValues,
  setCreatedTransaction,
  setSubmissionErrors,
  currentPollId,
  setCurrentPollId,
  fxSide,
  getAccounts,
  setCurrentView,
}) {
  const { startLoading, endLoading } = useContext(GlobalLoaderContext);
  const { toAccount } = formValues;

  const [ pollStarted, setPollStarted ] = useState(false);
  const [ isSubmitting, setIsSubmitting ] = useState(false);

  const handleSubmit = async event => {
    event.preventDefault();

    startLoading();
    setIsSubmitting(true);

    try {
      const moveFundsRequest = prepareMoveFundsRequest(formValues, fxSide);
      const createdTransaction = await moveFunds(moveFundsRequest);
      setCreatedTransaction(createdTransaction);
      setCurrentView(MOVE_FUNDS_FINAL);
    } catch (error) {
      if (shouldRedirectToAmountError(error.data)) {
        setSubmissionErrors(error.data);

        return setCurrentView(MOVE_FUNDS_FORM);
      }

      return setCurrentView(MOVE_FUNDS_FINAL);
    } finally {
      endLoading();
      setIsSubmitting(false);
    }

    return null;
  };

  useEffect(() => {
    sanitizePolls(currentPollId, true);
  }, []);

  useEffect(() => {
    const accountExist =
      get(formValues, fromAccountFieldName) &&
      get(formValues, toAccountFieldName);
    if (accountExist) {
      validationSchema.isValid(formValues).then(isValid => !isValid && setCurrentView(MOVE_FUNDS_FORM));
    }
    if (!pollStarted && accountExist) {
      setPollStarted(true);
    }
  }, [formValues]);

  useEffect(() => {
    if (pollStarted) {
      updatePoll(preparePollContext(
        formValues,
        fxSide,
        (fieldName, fieldValue) =>
          setFormValues({ ...formValues, [fieldName]: fieldValue }),
        getAccounts,
        currentPollId,
        setCurrentPollId,
      ));
    }
  }, [pollStarted]);

  return (
    <form id={MOVE_FUNDS_CONFIRMATION} name={MOVE_FUNDS_CONFIRMATION} onSubmit={handleSubmit}>
      <ScrollToTop />
      <S.Title>Review and confirm</S.Title>
      <S.Row>
        <S.AmountAndAccountContainer>
          <S.Amount>
            {getReceiveAmount(formValues)}
          </S.Amount>
          <S.ArrowRight />
          <S.Account name={toAccount?.name} currency={toAccount?.currency} isLarger />
        </S.AmountAndAccountContainer>
      </S.Row>
      <S.Row>
        <S.TotalContainer content={getConfirmationFromBoxContent(formValues, AccountName)} />
      </S.Row>
      <S.MoveFundsButton label="Move funds" category="primary" type="submit" disabled={isSubmitting} />
      <S.CancelButton />
    </form>
  );
}

MoveFundsConfirmation.propTypes = {
  setCurrentView: PropTypes.func.isRequired,
  formValues: PropTypes.instanceOf(Object).isRequired,
  setFormValues: PropTypes.func.isRequired,
  currentPollId: PropTypes.number,
  setCurrentPollId: PropTypes.func.isRequired,
  setSubmissionErrors: PropTypes.func.isRequired,
  setCreatedTransaction: PropTypes.func.isRequired,
  fxSide: PropTypes.string,
  getAccounts: PropTypes.func.isRequired,
};

export default MoveFundsConfirmation;
