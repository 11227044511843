import { Avatar as DefaultAvatar, DropdownLink as DefaultDropdownLink } from '@general/intergiro-ui-kit';
import { NavLink, Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { ReactComponent as HamburgerIcon } from 'assets/icons/hamburger.svg';
import { NavigationBackButton } from 'components';
import { DEVICE } from 'constants/breakpoints';

function getOpenStyles(isOpen) {
  if (isOpen) {
    return css`
      opacity: 1;
      pointer-events: all;
      z-index: 2;
    `;
  }

  return css`
    opacity: 0;
    pointer-events: none;
  `;
}

const HamburgerWrapper = styled.button`
  align-items: center;
  background: ${props => props.theme.colors.greyBright};
  border-radius: 50%;
  border: none;
  cursor: pointer;
  display: flex;
  height: 2.5rem;
  justify-content: center;
  width: 2.5rem;

  ${props => getOpenStyles(!props.isOpen)}

  @media ${DEVICE.desktop} {
    display: none;
  }
`;

const Hamburger = styled(HamburgerIcon)`
  stroke: ${props => props.theme.colors.greyDark};
  flex-shrink: 0;
`;

const BackButton = styled(NavLink)`
  color: ${props => props.theme.colors.greyMedium};
  font: ${props => props.theme.fonts.bodyXs};
  display: flex;
  align-items: center;
`;

const BackIcon = styled(NavigationBackButton)`
  height: 2.5rem;
  margin-right: 1rem;
  width: 2.5rem;
`;

const BackButtonLabel = styled.span`
  font: ${props => props.theme.fonts.bodyMSemi};
  color: ${props => props.theme.colors.black};

  @media ${DEVICE.desktop} {
    display: inline;
  }
`;

const CustomBackButtonLabel = styled(BackButtonLabel)`
  display: inline;
`;

const HelpDropdownWrapper = styled.div`
  width: 10rem;
  margin-top: -0.7rem;
  margin-bottom: -0.9rem;
`;

const ProfileDropdownWrapper = styled.div`
  width: 13.25rem;
`;

const AccountInformationWrapper = styled.div`
  padding-bottom: 0.75rem;
  padding-top: 0.1875rem;
  position: relative;
  margin-bottom: .1rem;

  :after {
    background: ${props => props.theme.colors.greyBright};
    bottom: 0;
    content: '';
    display: block;
    height: 0.0625rem;
    left: -1rem;
    position: absolute;
    width: calc(100% + 2rem);
  }

  p {
    color: ${props => props.theme.colors.greyMedium};
    font: ${props => props.theme.fonts.bodyXs};
    margin: 0;
  }
`;

const Email = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const linksStyles = css`
  color: ${props => props.theme.colors.black};
  cursor: pointer;
  display: block;
  font-weight: 500;
  line-height: 1.25;
  margin: 0 -1rem;
  padding: 0.6rem 1rem;
  z-index: 2;

  :hover {
    background-color: ${props => props.theme.colors.greyBright};
  }
`;

const DropdownLinkAction = styled(Link)`
  ${linksStyles};
`;

const DropdownItem = styled.div`
  ${linksStyles}
`;

const DropdownAction = styled.a`
  ${linksStyles};
`;

const SignOutLink = styled.a`
  ${linksStyles};
  margin-bottom: -.9rem;
`;

const LinkSeparator = styled.div`
  background-color: ${props => props.theme.colors.greyBright};
  height: 0.1rem;
  margin: .1rem -1rem;
`;

const Avatar = styled(DefaultAvatar)`
  width: 2rem;
  height: 2rem;
  margin: 0.25rem 0 0 1rem;
  border-radius: 50%;
  overflow: hidden;

  > svg {
    width: inherit;
    height: inherit;
  }

  > p {
    top: 50%;
  }

  &:hover {
    opacity: 0.8;
  }
`;

const ProfileAvatarWrapper = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    &:hover, &:focus {
      background: none;
    }
  }
`;

const DropdownLink = styled(DefaultDropdownLink)`
  > div {
    z-index: 3;
  }
`;

const QuestionMarkWrapper = styled.div`
  margin-top: 0.5rem;

  :hover {
    opacity: 0.8;
  }
`;

export default {
  Avatar,
  BackButton,
  BackIcon,
  BackButtonLabel,
  CustomBackButtonLabel,
  Hamburger,
  DropdownItem,
  HamburgerWrapper,
  DropdownLinkAction,
  ProfileDropdownWrapper,
  HelpDropdownWrapper,
  DropdownAction,
  LinkSeparator,
  AccountInformationWrapper,
  Email,
  SignOutLink,
  ProfileAvatarWrapper,
  DropdownLink,
  QuestionMarkWrapper,
};
