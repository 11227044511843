import PropTypes from 'prop-types';
import React from 'react';

import S from './styles';

function AlertBlock({ icon, text, className }) {
  const Icon = icon;

  return (
    <S.Container className={className}>
      {icon && (
        <S.IconContainer>
          <Icon />
        </S.IconContainer>
      )}
      <S.InfoContainer>
        {text}
      </S.InfoContainer>
    </S.Container>
  );
}

AlertBlock.propTypes = {
  icon: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.instanceOf(Object),
  ]),
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
};

export default AlertBlock;
