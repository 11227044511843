import dayjs from 'dayjs';
import pick from 'lodash/pick';

import { CARD_EXPIRATION_IN_YEARS } from 'constants/card';
import { MONTH, YEAR } from 'constants/date-time';
import {
  CARD_HOLDER_FIELD,
  CITY_ADDRESS_FIELD,
  COUNTRY_CODE,
  deliveryCountryCodes,
  MAX_EXPIRATION_DATE_YEARS,
  MIN_EXPIRATION_DATE_MONTHS,
  POSTAL_CODE_ADDRESS_FIELD,
  REGION_ADDRESS_FIELD,
  STREET_ADDRESS_FIELD,
} from 'pages/order-cards/constants';

export function getFormattedAddress(address, type) {
  if (!address) {
    return null;
  }

  if (!deliveryCountryCodes.includes(address[COUNTRY_CODE])) {
    return null;
  }

  return {
    type,
    ...pick(address,
      [ COUNTRY_CODE, CITY_ADDRESS_FIELD, STREET_ADDRESS_FIELD, REGION_ADDRESS_FIELD, POSTAL_CODE_ADDRESS_FIELD ]),
  };
}

export function isValidAddress(address) {
  return Boolean(address &&
    address[COUNTRY_CODE] &&
    address[CITY_ADDRESS_FIELD] &&
    address[POSTAL_CODE_ADDRESS_FIELD] &&
    address[STREET_ADDRESS_FIELD]);
}

export function getMinAndMaxExpirationDate() {
  const minExpirationDate =
    dayjs()
      .add(MIN_EXPIRATION_DATE_MONTHS, MONTH)
      .startOf(MONTH);

  const maxExpirationDate =
    dayjs()
      .add(MAX_EXPIRATION_DATE_YEARS, YEAR)
      .endOf(MONTH);

  return {
    minExpirationMonth: minExpirationDate.get(MONTH) + 1,
    minExpirationYear: minExpirationDate.get(YEAR),
    maxExpirationMonth: maxExpirationDate.get(MONTH) + 1,
    maxExpirationYear: maxExpirationDate.get(YEAR),
  };
}

export function getExpiryYearOptions(maxYear) {
  const minYear = maxYear - CARD_EXPIRATION_IN_YEARS;

  return new Array(CARD_EXPIRATION_IN_YEARS + 1)
    .fill(null)
    .map((_, index) => getLastTwoDigitsOfYear(index + minYear));

}

export function getLastTwoDigitsOfYear(year) {
  return Number(year) % 100;
}

export function getDeliveryAddress(address) {
  if (!address) return '';

  const streetCity = [ address[STREET_ADDRESS_FIELD], address[CITY_ADDRESS_FIELD] ].filter(Boolean).join(' ');
  const fields = [ streetCity, address[POSTAL_CODE_ADDRESS_FIELD], address[COUNTRY_CODE] ];

  return fields.filter(Boolean).join(', ');
}

export function shouldDisableDateOption(month, year) {
  const {
    minExpirationMonth,
    minExpirationYear,
    maxExpirationMonth,
    maxExpirationYear,
  } = getMinAndMaxExpirationDate();
  const shouldDisable = (
    year === getLastTwoDigitsOfYear(minExpirationYear) && month < minExpirationMonth
  ) || (
    year === getLastTwoDigitsOfYear(maxExpirationYear) && month > maxExpirationMonth
  );

  return shouldDisable;
}

export function getExistingAccountId(order) {
  return order.lineItems?.[0].issueRequest.existingAccount;
}

export function getAccountById(accountId, accounts) {
  return accountId ? accounts.filter(account => account.id === accountId)?.[0] : null;
}

export function formatExpirationDateToLayoutFormat(expirationDate) {
  return expirationDate?.split?.('-') || [ null, null ];
}

export function getCardholderFromOrder(persons, order) {
  return persons?.find?.(p =>
    p.id === (order.issueRequest.card[CARD_HOLDER_FIELD] || order.issueRequest.cardholder.referenceId));
}

export function createCardsFromOrder(order, persons) {
  return order.lineItems?.map(item => {
    const { expiry, address, companyName, displayName } = item.issueRequest.card;
    const [ expirationYear, expirationMonth ] = formatExpirationDateToLayoutFormat(expiry);
    const cardHolder = getCardholderFromOrder(persons, item);
    if (cardHolder) cardHolder.label = `${cardHolder.firstName} ${cardHolder.lastName}`;

    return {
      id: item.id,
      address,
      companyName,
      displayName,
      cardHolder,
      expiry: {
        year: expirationYear,
        month: expirationMonth,
      },
    };
  });
}
