import { Button, Dropdown } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';

const Container = styled.div`
  padding: 1.25rem;
  margin-bottom: ${props => props.footerIsVisible ? '8.75rem' : '3.75rem'};

  @media ${DEVICE.tablet} {
    padding: 0;
  }
`;

const Select = styled(Dropdown)`
  margin-top: 1.25rem;

  @media ${DEVICE.tablet} {
    width: 22.5rem;
  }

  @media ${DEVICE.laptop} {
    width: 30rem;
  }
`;

const SaveChangesFooter = styled.div`
  padding: 1.25rem;
  backdrop-filter: blur(1.6875rem);
  -webkit-backdrop-filter: blur(1.6875rem); /* stylelint-disable-line property-no-vendor-prefix */
  border-top: 0.0625rem solid ${props => props.theme.colors.greyLight};
  position: fixed;
  left: ${props => props.sidebarWidth ? props.sidebarWidth : 0}px;
  right: 0;
  bottom: 0;
  width: 100%;
  display: ${props => (props.visible ? 'flex' : 'none')};
  align-items: center;

  @media ${DEVICE.desktop} {
    margin-left: 17.625rem;
  }
`;

const SaveButton = styled(Button)`
  margin: 0 1.25rem;
  align-self: flex-end;
  font-weight: 600;
`;

const DiscardButton = styled(Button)`
  margin: 0;
  align-self: flex-end;
  font-weight: 600;
`;

export default {
  Container,
  Select,
  SaveChangesFooter,
  SaveButton,
  DiscardButton,
};
