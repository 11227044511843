import { useContext, useEffect, useState } from 'react';

import { showGeneralToastError } from 'utils/errors';
import { getUsers } from 'api/users';
import { GlobalLoaderContext } from 'contexts/global-loader';

const useUsers = () => {
  const { startLoading, endLoading } = useContext(GlobalLoaderContext);
  const [ users, setUsers ] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      startLoading();

      try {
        const data = await getUsers();

        setUsers(data);
      } catch (error) {
        showGeneralToastError(error);
      } finally {
        endLoading();
      }
    };

    fetchUsers();
  }, [ endLoading, startLoading ]);

  return { users };
};

export default useUsers;
