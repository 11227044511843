import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';

import { navigation, resourceNavigationLinks } from 'constants/navigation';

import S from './styles';

function NotFoundPage() {
  const { t } = useTranslation();
  const [ isHover, setIsHover ] = useState(false);
  const [ pageContext, setPageContext ] = useState(null);
  const { state } = useLocation();

  useEffect(() => {
    const resource = resourceNavigationLinks[state?.resource?.type];

    setPageContext(resource
      ? {
          title: t('notFound.resource.title', { name: resource.title }),
          message: t('notFound.resource.message'),
          navigateTo: resource.navigateTo,
          link: t('notFound.resource.link', { name: resource.linkTitle }),
        }
      : {
          title: t('notFound.page.title'),
          message: t('notFound.page.message'),
          navigateTo: navigation.accounts,
          link: t('notFound.page.link'),
        });
  }, []);

  if (!pageContext) {
    return null;
  }

  return (
    <S.Container>
      <S.Title>
        {pageContext.title}
      </S.Title>
      <S.ContentWrapper onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
        <S.LogoWrapper>
          <S.LogoLayer />
          <S.Logo isActive={isHover} />
          <S.EmojiLogo isActive={!isHover} />
        </S.LogoWrapper>
        <S.Paragraph>
          {pageContext.message}
        </S.Paragraph>
        <S.AccountsLink to={pageContext.navigateTo}>
          {pageContext.link}
        </S.AccountsLink>
      </S.ContentWrapper>
    </S.Container>
  );
}

export default NotFoundPage;
