import { AnimateSharedLayout, motion } from 'framer-motion';
import PropTypes from 'prop-types';
import React from 'react';

import { cardCredentialsShape, cardShape } from 'local-prop-types';

import CardBack from './back';
import CardFront from './front';
import S from './styles';

const variants = {
  initialFront: { rotateY: 0 },
  animateFront: { rotateY: -180 },
  initialBack: { rotateY: 180 },
  animateBack: { rotateY: 0 },
};

function Card({ card, flipped, isNewCard, credentials, isMyCard }) {
  return (
    <AnimateSharedLayout>
      <S.CardContainer>
        <motion.div
          initial={variants.initialFront}
          animate={flipped ? variants.initialBack : variants.initialFront}
          transition={{ duration: 0.5 }}
          variants={variants}
          style={{
            WebkitBackfaceVisibility: 'hidden',
          }}
        >
          <CardFront card={card} isMyCard={isMyCard} />
        </motion.div>
        <motion.div
          initial={variants.initialBack}
          animate={flipped ? variants.animateBack : variants.initialBack}
          transition={{ duration: 0.5 }}
          variants={variants}
          style={{
            WebkitBackfaceVisibility: 'hidden',
          }}
        >
          <CardBack card={card} credentials={credentials} isNewCard={isNewCard} />
        </motion.div>
      </S.CardContainer>
    </AnimateSharedLayout>
  );
}

Card.propTypes = {
  card: cardShape.card.isRequired,
  credentials: cardCredentialsShape,
  flipped: PropTypes.bool,
  isNewCard: PropTypes.bool,
  isMyCard: PropTypes.bool,
};

export default Card;
