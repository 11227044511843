import { Tabs } from '@general/intergiro-ui-kit';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { CARD_DETAILS, CARD_LIMITS } from 'constants/card';
import CardProvider, { CardContext } from 'contexts/card';
import { NavigationContext } from 'contexts/navigation';
import { UserContext } from 'contexts/user';
import { useCard } from 'hooks';
import { isUserCard } from 'utils/card';

import S from './styles';
import CardLimits from './components/limits';
import CardHeader from './components/header';
import CardDetails from './components/details';

function CardPageContainer() {
  const { id } = useParams();
  const { card } = useCard(id);
  const { user } = useContext(UserContext);

  if (!card || !user?.id) return null;

  const isMyCard = isUserCard(card, user.id);

  return (
    <CardProvider card={card} isMyCard={isMyCard}>
      <CardPage />
    </CardProvider>
  );
}

function getCardDetailsNavigationContent(card) {
  const lastFour = card.pan?.lastFour && (
    <S.LastFour>
      ••
      {' '}
      {card.pan.lastFour}
    </S.LastFour>
  );

  return (
    <>
      {lastFour}
      {card.displayName && (
        <>
          <S.Dot />
          <S.CardName>
            {card.displayName}
          </S.CardName>
        </>
      )}
    </>
  );
}

function CardPage() {
  const [ activeTab, setActiveTab ] = useState(CARD_DETAILS);
  const { setBackButtonLabel } = useContext(NavigationContext);
  const { card } = useContext(CardContext);

  const handleChange = event => {
    setActiveTab(event.target.value);
  };

  useEffect(() => {
    setBackButtonLabel(getCardDetailsNavigationContent(card));

    return () => setBackButtonLabel();
  }, []);

  return (
    <>
      <CardHeader />
      <div>
        <div data-testid="card-page-header-tabs">
          <Tabs.Container name="tabs">
            <S.TabsHead id={CARD_DETAILS} isActive={activeTab === CARD_DETAILS} handleChange={handleChange}>
              Card details
            </S.TabsHead>
            <S.TabsHead id={CARD_LIMITS} isActive={activeTab === CARD_LIMITS} handleChange={handleChange}>
              Limits
            </S.TabsHead>
          </Tabs.Container>
        </div>
        {activeTab === CARD_DETAILS && <CardDetails />}
        {activeTab === CARD_LIMITS && <CardLimits />}
      </div>
    </>
  );
}

export default CardPageContainer;
