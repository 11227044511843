import { Tabs } from '@general/intergiro-ui-kit';
import React, {
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';

import { ReactComponent as CardsIcon } from 'assets/icons/cards.svg';
import { CheckboxGroup, EmptyState, FilterBarMobile, ViewTypeSwitch } from 'components';
import { navigation } from 'constants/navigation';
import { GRID_VIEW } from 'constants/viewType';
import { UserContext } from 'contexts/user';
import { useCards, useView } from 'hooks';
import AllCards from 'pages/cards/components/all-cards';
import { getInitialViewType, getStatusesQuery, getStatusOption } from 'pages/cards/utils';
import { isUserCard } from 'utils/card';
import { setCardsView } from 'utils/storage';
import FilterDropdown from 'components/filter-bar/components/dropdown';
import FilterDropdownMobile from 'components/filter-bar-mobile/components/filter-dropdown';
import { addParameterToURL, removeParameterFromURL } from 'utils/helpers';
import DropdownFilterCheckbox from 'pages/activity/components/dropdown-filter-checkbox';
import DropdownFilterControl from 'pages/activity/components/dropdown-filter-control';
import { cardBlockingReasons } from 'constants/card';

import S from './styles';
import {
  statusDropdownOptions,
  statusDropdownName,
  defaultActiveStatusDropdownOptions,
  statusDropdownValues,
} from './constants';

const ALL_CARDS_ID = 'all-cards';
const MY_CARDS_ID = 'my-cards';

function CardsPage() {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { isDesktop } = useView();
  const initialViewType = getInitialViewType();
  const [ numberOfAllCards, setNumberOfAllCards ] = useState(0);
  const [ numberOfMyCards, setNumberOfMyCards ] = useState(0);
  const statusesQuery = useMemo(() => getStatusesQuery(location), [location]);
  const { cards = [], fetchCards, isFetching } = useCards();
  const [ ownCards, setOwnCards ] = useState([]);
  const [ viewType, setViewType ] = useState(initialViewType);
  const [ isMobileFiltersOpen, setMobileFiltersOpen ] = useState(false);
  const {
    user,
    permissions: { canOrderCard, canViewOwnCards, canViewOthersCards },
  } = useContext(UserContext);
  const [ activeTab, setActiveTab ] = useState(canViewOwnCards ? MY_CARDS_ID : ALL_CARDS_ID);
  const [ statusOptions, setStatusOptions ] = useState(statusDropdownOptions.map(option =>
    getStatusOption(option, location)));
  const statusOptionsCount = useMemo(() => statusOptions.filter(option => option.checked).length, [location]);
  const ref = useRef();

  useEffect(() => {
    if (isDesktop) {
      setViewType(getInitialViewType());
    } else {
      setViewType(GRID_VIEW);
    }
  }, [isDesktop]);

  useEffect(() => {
    const filteredOwnCards = cards.filter(card => isUserCard(card, user.id));

    setOwnCards(filteredOwnCards);
    setNumberOfAllCards(cards?.length);
    setNumberOfMyCards(filteredOwnCards?.length);
  }, [cards]);

  useEffect(() => {
    fetchCards(statusesQuery);
  }, [statusesQuery]);

  const mapStatusOptionsToUrl = () => {
    const checkedOptions = statusOptions.reduce((prev, curr) => {
      const mappedBlockedReasons =
        curr.value === statusDropdownValues.blocked ? Object.values(cardBlockingReasons) : [curr.value];

      if (curr.checked) {
        return [
          ...prev,
          ...mappedBlockedReasons,
        ];
      }

      return prev;
    }, []);

    if (checkedOptions.length) {
      addParameterToURL(statusDropdownName,
        checkedOptions,
        history,
        location);
    } else {
      removeParameterFromURL(statusDropdownName, history, location);
    }
  };

  const handleChange = event => {
    const { value } = event.target;

    value && setActiveTab(value);
  };

  const onChangeViewType = value => {
    setViewType(value);
    setCardsView(value);
  };

  const navigateToOrderCards = () => {
    history.push(navigation.orderCards);
  };

  const handleStatusDropdownChange = event => {
    const { value, checked } = event.target;
    setStatusOptions(statusOptions.map(option => ({
      ...option,
      checked: option.value === value ? checked : option.checked,
    })));
  };

  const handleStatusDropdownClear = () => {
    setStatusOptions(statusOptions.map(option => ({
      ...option,
      checked: defaultActiveStatusDropdownOptions.includes(option.value),
    })));
  };

  const handleStatusDropdownSelectAll = () => {
    const selectAllApplied = statusOptions.every(option => option.checked);

    setStatusOptions(statusOptions.map(option => ({
      ...option,
      checked: !selectAllApplied,
    })));
  };

  const handleStatusDropdownApply = () => {
    ref.current.closeDropdown();
    mapStatusOptionsToUrl();
  };

  const handleMobileFilterApply = () => {
    setMobileFiltersOpen(false);
    mapStatusOptionsToUrl();
  };

  return (
    <>
      <S.Header data-testid="cards-page-header">
        <S.Title>Cards</S.Title>
        {canOrderCard && (
          <S.Button size="small" onClick={navigateToOrderCards} label="Order cards" icon={<CardsIcon />} type="primary" />
        )}
      </S.Header>
      <S.Content>
        <S.TabWrapper data-testid="cards-page-header-tabs">
          <Tabs.Container name="tabs">
            {canViewOwnCards && (
              <Tabs.Head id={MY_CARDS_ID} isActive={activeTab === MY_CARDS_ID} handleChange={handleChange}>
                My cards
                <S.TabHelperText>
                  {numberOfMyCards}
                </S.TabHelperText>
              </Tabs.Head>
            )}
            {canViewOthersCards && (
              <Tabs.Head id={ALL_CARDS_ID} isActive={activeTab === ALL_CARDS_ID} handleChange={handleChange}>
                All cards
                <S.TabHelperText>
                  {numberOfAllCards}
                </S.TabHelperText>
              </Tabs.Head>
            )}
            <S.SortBarWrapper>
              <S.StatusDropdown>
                <DropdownFilterControl
                  label={(
                    <FilterDropdown
                      name={statusDropdownName}
                      count={statusOptionsCount}
                      defaultLabel={t('cards.filters.status.title')}
                      selectedLabel={t('cards.filters.status.title')}
                    />
                  )}
                  ref={ref}
                >
                  <DropdownFilterCheckbox
                    list={statusOptions}
                    onChange={handleStatusDropdownChange}
                    onClear={handleStatusDropdownClear}
                    onSelectAll={handleStatusDropdownSelectAll}
                    onApply={handleStatusDropdownApply}
                  />
                </DropdownFilterControl>
              </S.StatusDropdown>
              <ViewTypeSwitch viewType={viewType} onChange={onChangeViewType} />
            </S.SortBarWrapper>
          </Tabs.Container>
        </S.TabWrapper>
        <S.MobileActionsWrapper>
          <S.FiltersWrapper onClick={() => setMobileFiltersOpen(true)}>
            <S.FiltersIcon />
            {t('cards.filters.title')}
          </S.FiltersWrapper>
        </S.MobileActionsWrapper>
        <Tabs.Panel>
          {!isFetching && (
            <>
              {activeTab === ALL_CARDS_ID && (
                !cards || cards?.length === 0 ? (
                  <EmptyState
                    title={t('cards.emptyState.allCards')}
                    subTitle={canOrderCard ? (
                      <Trans i18nKey="cards.emptyState.hasPermissionToOrder">
                        Click on &apos;
                        <S.Link to={navigation.orderCards}>Order cards</S.Link>
                        &apos; to issue your first card.
                      </Trans>
                    )
                      : t('cards.emptyState.noPermissionToOrder')}
                  />
                ) : (
                  canViewOthersCards && <AllCards isGrid={viewType === GRID_VIEW} allCards={cards} />
                )
              )}

              {activeTab === MY_CARDS_ID && (
                !ownCards || ownCards?.length === 0 ? (
                  <EmptyState
                    title={t('cards.emptyState.myCards')}
                    subTitle={canOrderCard ? (
                      <Trans i18nKey="cards.emptyState.hasPermissionToOrder">
                        Click on &apos;
                        <S.Link to={navigation.orderCards}>Order cards</S.Link>
                        &apos; to issue your first card.
                      </Trans>
                    )
                      : t('cards.emptyState.noPermissionToOrder')}
                  />
                ) : (
                  canViewOwnCards && <AllCards isGrid={viewType === GRID_VIEW} allCards={ownCards} />
                )
              )}
            </>
          )}
        </Tabs.Panel>
      </S.Content>
      <FilterBarMobile
        isOpen={isMobileFiltersOpen}
        closeFilterBar={() => setMobileFiltersOpen(false)}
        applyFilters={handleMobileFilterApply}
        cancelFilters={handleStatusDropdownClear}
        hideLabel
      >
        <FilterDropdownMobile title={t('cards.filters.status.title')}>
          <CheckboxGroup
            groupName={t('cards.filters.selectAll')}
            list={statusOptions}
            onChange={handleStatusDropdownChange}
            onSelectAll={handleStatusDropdownSelectAll}
          />
        </FilterDropdownMobile>
      </FilterBarMobile>
    </>
  );
}

export default CardsPage;
