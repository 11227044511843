import { Hint as IntergiroHint } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';
import { hex2rgba } from 'helpers/styles';

const PaymentsWrapper = styled.div`
  transition: ${props => props.theme.transition};
  width: 100%;

  @media ${DEVICE.laptop} {
    height: 100%;
    ${props => (props.isSidebarOpen ? 'width: calc(100% - 23rem)' : '')};
  }
`;

const Hint = styled(IntergiroHint)`
  position: relative;
  margin-left: 0.3125rem;
`;

const Header = styled.div`
  border-bottom: 0.0625rem solid ${props => hex2rgba(props.theme.colors.black, 0.3)};
  padding: 1rem 1.25rem 1.25rem;

  @media ${DEVICE.tablet} {
    padding: 1rem 0 1.25rem;
  }

  @media ${DEVICE.laptop} {
    display: flex;
    padding-left: 1rem;
  }
`;

const MaxPaymentsWrapper = styled.div`
  position: relative;
  color: ${props => props.theme.colors.greyMedium};
  font-size: 0.75rem;
  margin-left: auto;
  margin-top: auto;
  padding-right: 1.25rem;
  padding-left: 0;
`;

const PaymentSection = styled.div`
  padding: 0 1.25rem;
`;

export default {
  PaymentsWrapper,
  Header,
  MaxPaymentsWrapper,
  Hint,
  PaymentSection,
};
