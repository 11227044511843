import { object, string } from 'yup';

export const ResetPasswordSchema = object().shape({
  email: string()
    .email('Email must be a valid email')
    .max(254, 'Max. 254 characters')
    .required('Email is required'),
});

export const SetNewPasswordSchema = object().shape({
  password: string()
    .min(6, 'Min. 6 characters')
    .max(25, 'Max. 25 characters')
    .required('Password is required'),
});
