import PropTypes from 'prop-types';
import React, { memo } from 'react';

import S from './styles';

function Dash({ className }) {
  return (
    <S.DashWrapper className={className}> - </S.DashWrapper>
  );
}

Dash.propTypes = {
  className: PropTypes.string,
};

export default memo(Dash);
