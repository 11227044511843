import { useEffect, useState } from 'react';

const useSidebarInteractions = () => {
  const [ isSidebarOpen, setIsSidebarOpen ] = useState(false);
  const [ isUpArrowDisabled, setIsUpArrowDisabled ] = useState(false);
  const [ isTopArrowDisabled, setIsTopArrowDisabled ] = useState(false);

  function setOverflow() {
    document.body.classList.add('overflow-hidden-mobile');
  }

  function unsetOverflow() {
    document.body.classList.remove('overflow-hidden-mobile');
  }

  useEffect(() => {
    if (isSidebarOpen) {
      setOverflow();
    } else {
      unsetOverflow();
    }
  }, [isSidebarOpen]);

  return {
    isSidebarOpen,
    setIsSidebarOpen,
    isUpArrowDisabled,
    setIsUpArrowDisabled,
    isTopArrowDisabled,
    setIsTopArrowDisabled,
  };
};

export default useSidebarInteractions;
