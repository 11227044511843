import styled from 'styled-components';
import { RadioButton } from '@general/intergiro-ui-kit';

const Radio = styled(RadioButton)`
  margin-bottom: 0.75rem;
`;

export default {
  Radio,
};
