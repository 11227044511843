import React from 'react';
import { t } from 'i18next';

import { PHYSICAL_CARD_TYPE, VIRTUAL_CARD_TYPE } from 'constants/card';
import { ReactComponent as CardPhysicalIcon } from 'assets/icons/card-physical.svg';
import { ReactComponent as CardVirtualIcon } from 'assets/icons/card-virtual.svg';

import S from './styles';

export const cardTypeInitialValues = [
  {
    title: t('orderCards.selectType.cardsType.physical.title'),
    description: <S.CardDescription>
      <span>
        {t('orderCards.selectType.cardsType.physical.description.top')}
      </span>
      <span>
        {t('orderCards.selectType.cardsType.physical.description.bottom')}
      </span>
    </S.CardDescription>,
    label: t('orderCards.selectType.cardsType.physical.label'),
    value: PHYSICAL_CARD_TYPE,
    checked: true,
    image: <CardPhysicalIcon />,
  },
  {
    title: t('orderCards.selectType.cardsType.virtual.title'),
    description: <S.CardDescription>
      <span>
        {t('orderCards.selectType.cardsType.virtual.description.top')}
      </span>
      <span>
        {t('orderCards.selectType.cardsType.virtual.description.bottom')}
      </span>
    </S.CardDescription>,
    label: t('orderCards.selectType.cardsType.virtual.label'),
    value: VIRTUAL_CARD_TYPE,
    checked: false,
    image: <CardVirtualIcon />,
  },
];
