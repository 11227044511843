import { Input } from '@general/intergiro-ui-kit';
import { useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { getDropdownCurrencyOptions, ValidationWrapper } from 'components';
import { INPUT_PATTERNS } from 'constants/inputs';
import MoveFundsSelect from 'pages/move-funds/components/move-funds-form/select';
import MoveFundsNotification from 'pages/move-funds/components/move-funds-notification';
import {
  ACTION_CLEAR,
  BUY_FX_SIDE,
  DEBOUNCE_TIME,
  exchangeRateFieldName,
  fromAccountFieldName,
  MIN_AMOUNT_TO_GET_RATE,
  MOVE_FUNDS_CONFIRMATION,
  MOVE_FUNDS_FORM,
  moveAmountFieldName,
  receiveAmountFieldName,
  SELL_FX_SIDE,
  SUBMISSION_ERROR_AMOUNT,
  toAccountFieldName,
} from 'pages/move-funds/constants';
import { MoveFundsSchema as validationSchema } from 'pages/move-funds/services/schema';
import {
  accountsHaveDifferentCurrency,
  filterSelectedAccountOptions,
  getDefaultExchangeRate,
  getRateAndTotal,
  mapActiveOptions,
  preparePollContext,
  sanitizePolls,
  updatePoll,
} from 'pages/move-funds/services/utils';
import { reverseFormatNumber } from 'utils/amount';
import { mapErrorMessages } from 'utils/errors';
import { isFxWeekend } from 'utils/fx';

import S from './styles';

function MoveFundsForm({
  accounts,
  getAccounts,
  setCurrentView,
  predefinedFromAccountId,
  predefinedToAccountId,
  setPredefinedFromAccountId,
  setPredefinedToAccountId,
  formValues,
  setFormValues,
  submissionErrors,
  setSubmissionErrors,
  fxSide,
  setFxSide,
  currentPollId,
  setCurrentPollId,
}) {
  const [ fromAccountOptions, setFromAccountOptions ] = useState([]);
  const [ toAccountOptions, setToAccountOptions ] = useState([]);
  const [ rateAndTotalReady, setRateAndTotalReady ] = useState(false);
  const [ displayReceivedAmount, setDisplayReceivedAmount ] = useState(false);
  const [ pollingUpdates, setPollingUpdates ] = useState(0);
  const [ selectedFromOption, setSelectedFromOption ] = useState();
  const [ rateAndTotal, setRateAndTotal ] = useState(getRateAndTotal(formValues));
  const [ isFetchingRates, setIsFetchingRates ] = useState(true);
  const debounceRef = useRef();

  const onSubmit = values => {
    setFormValues(values);
    setCurrentView(MOVE_FUNDS_CONFIRMATION);
  };

  const formik = useFormik({
    initialValues: formValues,
    onSubmit,
    validationSchema,
  });

  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    values,
    touched,
    errors,
    isSubmitting,
    setFieldTouched,
    setFieldError,
    setErrors,
  } = formik;

  const disableContinueButton = isSubmitting || isFetchingRates ||
    accountsHaveDifferentCurrency(values) && isFxWeekend();

  useEffect(() => {
    setRateAndTotalReady(!isEmpty(rateAndTotal));
  }, [rateAndTotal]);

  useEffect(() => {
    if (accounts && isEmpty(values[fromAccountFieldName]) && isEmpty(values[toAccountFieldName])) {
      const options = getDropdownCurrencyOptions(accounts);
      setToAccountOptions(options);
      setFromAccountOptions(options);
    }
  }, [accounts]);

  useEffect(() => {
    const hasDifferentCurrency = accountsHaveDifferentCurrency(values);
    const accountOptions = getDropdownCurrencyOptions(accounts);

    const selectedFromAccount = values[fromAccountFieldName];
    const selectedToAccount = values[toAccountFieldName];

    const unselectedFromAccountOptions = filterSelectedAccountOptions(selectedToAccount, accountOptions);
    const unselectedToAccountOptions = filterSelectedAccountOptions(selectedFromAccount, accountOptions);

    const activeFromAccountOptions = mapActiveOptions(selectedToAccount, unselectedFromAccountOptions);
    const activeToAccountOptions = mapActiveOptions(selectedFromAccount, unselectedToAccountOptions);

    setFromAccountOptions(activeFromAccountOptions);
    setToAccountOptions(activeToAccountOptions);

    if (isNil(values[fromAccountFieldName]) || isNil(values[toAccountFieldName])) {
      setDisplayReceivedAmount(false);
    } else {
      setDisplayReceivedAmount(hasDifferentCurrency);
    }
  }, [ values[fromAccountFieldName], values[toAccountFieldName] ]);

  const onClear = name => {
    const options = getDropdownCurrencyOptions(accounts);
    setToAccountOptions(options);
    setFromAccountOptions(options);
    setFieldValue(name, null);
  };

  const handleSelect = async (selectedOption, { action, name }) => {
    if (action === ACTION_CLEAR) {
      onClear(name);
      setSelectedFromOption(null);

      return;
    }
    setFieldValue(name, selectedOption);

    const selectedAccount = selectedOption?.value;

    await setFieldValue(name, selectedAccount);
    await updateDefaultRate({ ...values, [name]: selectedAccount });
    setPollingUpdates(pollingUpdates + 1);
    const bindedAmountFieldName =
      name === fromAccountFieldName
        ? moveAmountFieldName
        : receiveAmountFieldName;
    if (isEmpty(selectedOption)) {
      setFxSide();
      await setFieldValue(bindedAmountFieldName, '');
      await setFieldTouched(bindedAmountFieldName, false);
    }
  };

  const updateRateValues = (selectedFxSide, changeEvent, fieldName) => {
    setFxSide(selectedFxSide);
    setPollingUpdates(pollingUpdates + 1);

    const { value } = changeEvent.target;

    if (!isEmpty(value)) {
      setFieldValue(fieldName, value);
    }
  };

  const updateDefaultRate = currentValues => {
    const canUpdatePolling =
      accountsHaveDifferentCurrency(currentValues)
      && !isFxWeekend();

    const moveAmount = Number(reverseFormatNumber(values[moveAmountFieldName]));

    return canUpdatePolling && (moveAmount > MIN_AMOUNT_TO_GET_RATE
      ? setPollingUpdates(pollingUpdates + 1)
      : getDefaultExchangeRate(currentValues).then(rate => setFieldValue(exchangeRateFieldName, rate)));
  };

  const updatePredefinedToAccount = () => {
    const toAccount = predefinedToAccountId &&
      accounts.find(({ id }) => id === predefinedToAccountId);
    if (!isEmpty(toAccount)) {
      const account = getDropdownCurrencyOptions([toAccount]);
      handleSelect(account[0], { action: null, name: toAccountFieldName });
      setPredefinedToAccountId();
    }
  };

  const onAmountChange = (fieldName, fxSideValue) => changeEvent => {
    setIsFetchingRates(true);
    sanitizePolls(currentPollId, true);
    setFieldTouched(fieldName);
    handleChange(changeEvent);
    setSubmissionErrors();

    clearTimeout(debounceRef.current);
    debounceRef.current = setTimeout(() => {
      updateRateValues(fxSideValue, changeEvent, fieldName);
    }, DEBOUNCE_TIME);
  };

  const updatePredefinedFromAccount = () => {
    const fromAccount =
        predefinedFromAccountId &&
        accounts.find(({ id }) => id === predefinedFromAccountId);
    if (predefinedFromAccountId && !isEmpty(fromAccount)) {
      const account = getDropdownCurrencyOptions([fromAccount]);
      handleSelect(account[0],
        { action: null, name: fromAccountFieldName });
      setPredefinedFromAccountId();
      setSelectedFromOption(account[0]);
    }
  };

  useEffect(() => {
    if (!isEmpty(accounts) && isEmpty(values[fromAccountFieldName])) {
      updatePredefinedFromAccount();
    }

    if (!isEmpty(accounts) && isEmpty(values[toAccountFieldName])) {
      updatePredefinedToAccount();
    }
  }, [accounts]);

  useEffect(() => {
    setRateAndTotal(getRateAndTotal(values));
  }, [values]);

  useEffect(() => {
    const updatePollAndLoader = async () => {
      await updatePoll(preparePollContext(
        values,
        fxSide,
        setFieldValue,
        getAccounts,
        currentPollId,
        setCurrentPollId,
        setFieldError,
        errors,
      ));

      setIsFetchingRates(false);
    };

    updatePollAndLoader();
  }, [pollingUpdates]);

  useEffect(() => {
    sanitizePolls(currentPollId, true);
    if (values[moveAmountFieldName] > 0) {
      setFieldTouched(moveAmountFieldName);
    }
  }, []);

  useEffect(() => {
    const mappedErrors = mapErrorMessages(submissionErrors);

    if (!isEmpty(mappedErrors)) {
      setErrors(mappedErrors);
    }
  }, [ submissionErrors, errors ]);

  const fromAmountTouched = useMemo(() => isNil(submissionErrors?.[SUBMISSION_ERROR_AMOUNT])
    ? Boolean(touched[moveAmountFieldName])
    : Boolean(submissionErrors?.[SUBMISSION_ERROR_AMOUNT]),
  [ submissionErrors?.[SUBMISSION_ERROR_AMOUNT], touched[moveAmountFieldName] ]);

  return (
    <form onSubmit={handleSubmit} id={MOVE_FUNDS_FORM} name={MOVE_FUNDS_FORM} >
      <S.Row>
        <S.InputContainer>
          <MoveFundsSelect
            name={fromAccountFieldName}
            placeholder="Select account"
            label="From"
            value={values[fromAccountFieldName] && getDropdownCurrencyOptions([values[fromAccountFieldName]])[0]}
            accountOptions={fromAccountOptions}
            handleSelect={handleSelect}
            isSubmitting={isSubmitting}
            error={touched[fromAccountFieldName] && errors[fromAccountFieldName]}
            defaultValue={selectedFromOption}
          />
        </S.InputContainer>
        <S.ArrowRight />
        <S.InputContainer>
          <MoveFundsSelect
            name={toAccountFieldName}
            placeholder="Select account"
            value={values[toAccountFieldName] && getDropdownCurrencyOptions([values[toAccountFieldName]])[0]}
            label="To"
            accountOptions={toAccountOptions}
            handleSelect={handleSelect}
            isSubmitting={isSubmitting}
            error={touched[toAccountFieldName] && errors[toAccountFieldName]}
          />
        </S.InputContainer>
      </S.Row>
      <MoveFundsNotification
        accountFrom={values[fromAccountFieldName]}
        accountTo={values[toAccountFieldName]}
      />
      <S.AmountRow>
        <S.InputContainer>
          <ValidationWrapper
            error={errors[moveAmountFieldName] || submissionErrors?.[SUBMISSION_ERROR_AMOUNT]}
            touched={fromAmountTouched}
          >
            <Input
              helperText={(
                <S.CurrencyLabel>
                  {values[fromAccountFieldName]?.currency}
                </S.CurrencyLabel>
)}
              currency={values[fromAccountFieldName]?.currency}
              label="Move"
              onChange={onAmountChange(moveAmountFieldName, SELL_FX_SIDE)}
              value={values.moveAmount}
              id={moveAmountFieldName}
              name={moveAmountFieldName}
              type="text"
              disabled={isSubmitting}
              onClear={() => setFieldValue(moveAmountFieldName, 0)}
              placeholder="0.00"
              inputPattern={INPUT_PATTERNS.amount}
            />
          </ValidationWrapper>
        </S.InputContainer>
        <S.Separator />
        <S.InputContainer>
          {displayReceivedAmount && (
            <Input
              label="Receive"
              helperText={(
                <S.CurrencyLabel>
                  {values[toAccountFieldName]?.currency}
                </S.CurrencyLabel>
)}
              currency={values[toAccountFieldName]?.currency}
              onChange={onAmountChange(receiveAmountFieldName, BUY_FX_SIDE)}
              value={values.receiveAmount}
              id={receiveAmountFieldName}
              name={receiveAmountFieldName}
              type="text"
              disabled={isSubmitting}
              error={touched[receiveAmountFieldName] && errors[receiveAmountFieldName]}
              onClear={() => setFieldValue(receiveAmountFieldName, 0)}
              placeholder="0.00"
              inputPattern={INPUT_PATTERNS.amount}
            />
          )}
        </S.InputContainer>
      </S.AmountRow>
      {rateAndTotalReady && (
        <S.Row>
          <S.TotalRow content={rateAndTotal} />
          <S.Separator />
          <S.InputContainer />
        </S.Row>
        )}
      <S.Separator />
      <S.InputContainer />
      <S.SubmitButton
        label="Continue"
        category="primary"
        type="submit"
        disabled={disableContinueButton}
        data-testid="move-funds-form-submit"
      />
      <S.CancelButton />
    </form>
  );
}

MoveFundsForm.propTypes = {
  accounts: PropTypes.instanceOf(Array),
  getAccounts: PropTypes.func.isRequired,
  setCurrentView: PropTypes.func.isRequired,
  predefinedFromAccountId: PropTypes.string,
  predefinedToAccountId: PropTypes.string,
  setPredefinedFromAccountId: PropTypes.func.isRequired,
  setPredefinedToAccountId: PropTypes.func.isRequired,
  formValues: PropTypes.instanceOf(Object).isRequired,
  submissionErrors: PropTypes.instanceOf(Object),
  setSubmissionErrors: PropTypes.func,
  setFormValues: PropTypes.func.isRequired,
  fxSide: PropTypes.string,
  setFxSide: PropTypes.func.isRequired,
  currentPollId: PropTypes.number,
  setCurrentPollId: PropTypes.func.isRequired,
};

export default MoveFundsForm;
