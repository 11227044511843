import { ActionButton as DefaultActionButton } from '@general/intergiro-ui-kit';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { ReactComponent as Back } from 'assets/icons/back.svg';
import { ReactComponent as Block } from 'assets/icons/block.svg';
import { ReactComponent as Frozen } from 'assets/icons/frozen.svg';
import { ReactComponent as Pin } from 'assets/icons/pin.svg';
import { ReactComponent as Security } from 'assets/icons/security.svg';
import { DEVICE } from 'constants/breakpoints';

const Main = styled.main`
  display: flex;
  justify-content: start;
  flex-direction: column;
  padding: 1.875rem 0 1.25rem;

  @media ${DEVICE.tablet} {
    flex-direction: row;
  }

  @media ${DEVICE.laptop} {
    flex-direction: row;
    padding-top: 1.875rem;
	}
`;

const CountDownContainer = styled.div`
    position: relative;
`;

const ActionsContainers = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 20rem;
  padding-top: 1.875rem;
`;

const ActionButton = styled(DefaultActionButton)`
  width: 3.2rem;
  
  > div {
    background: ${props => props.theme.colors.greyBright};
  }

  &:hover {
    > div {
      background: ${props => props.theme.colors.greyLight};
    }
  }
`;

const actionIconsStyle = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  path {
    fill: ${props => props.theme.colors.black};
  }
`;

const UnfreezeActionButton = styled(ActionButton)`
  > div {
    background: ${props => props.theme.colors.greyMedium};
  }
`;

const BackIcon = styled(Back)`
  ${actionIconsStyle}
  width: 1rem;
  height: 1rem;
`;
const BlockIcon = styled(Block)`
  ${actionIconsStyle}
  width: 1.25rem;
  height: 1.25rem;
`;
const FreezeIcon = styled(Frozen)`
  ${actionIconsStyle}
  width: 1.25rem;
  height: 1.375rem;
`;
const UnfreezeIcon = styled(Frozen)`
  ${actionIconsStyle}
  width: 1.25rem;
  height: 1.375rem;
  path {
    fill: ${props => props.theme.colors.white};
  }
`;
const PinIcon = styled(Pin)`
  ${actionIconsStyle}
  width: 1.5rem;
  height: 1rem;
`;

const SecurityIcon = styled(Security)`
  ${actionIconsStyle}
  width: 1.5rem;
  height: 1.5rem;
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: ${props => props.card ? 'center' : 'flex-start'};
  margin-top: ${props => props.marginTop ? '1.875rem' : ''};
  padding: ${props => props.card ? '0' : '0 1.25rem'};

  @media ${DEVICE.tablet} {
    align-items: flex-start;
    margin-top: 0;
    margin-right: 1.25rem;
    padding: 0;
	}

  @media ${DEVICE.laptop} {
    margin-right: 6.25rem;
  }
`;

const UserName = styled(Link)`
  color: ${props => props.theme.colors.blue};
  margin: 0;
`;

const CurrencyContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Currency = styled.p`
  padding-left: 0.5rem;
  margin: 0;
`;

export default {
  ActionsContainers,
  ActionButton,
  UnfreezeActionButton,
  BackIcon,
  BlockIcon,
  FreezeIcon,
  UnfreezeIcon,
  PinIcon,
  Main,
  Section,
  UserName,
  CurrencyContainer,
  Currency,
  CountDownContainer,
  SecurityIcon,
};
