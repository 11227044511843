import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { AlertBlock } from 'components';
import { isBol } from 'constants/payment-processor';
import { isFxWeekend } from 'utils/fx';
import { areBothSparkPaymentProcessor, isSamePaymentProcessor } from 'utils/payment-processor';

import {
  MOVE_FUNDS_FX_WEEKEND_MESSAGE,
  MOVE_FUNDS_GENERAL_MESSAGE,
  MOVE_FUNDS_NOT_SAME_PAYMENT_PROCESSOR_MESSAGE,
} from './constants';
import S from './styles';

function MoveFundsNotification({ className, accountFrom, accountTo }) {
  const [ message, setMessage ] = useState(null);

  useEffect(() => {
    switch (true) {
      case !accountTo && accountFrom && isBol(accountFrom.paymentProcessorAdapter):
        setMessage(MOVE_FUNDS_GENERAL_MESSAGE);

        return;

      case accountFrom && accountTo && !isFxWeekend() && !isSamePaymentProcessor(accountFrom, accountTo):
        setMessage(MOVE_FUNDS_NOT_SAME_PAYMENT_PROCESSOR_MESSAGE);

        return;

      case accountFrom && accountTo && isFxWeekend() && areBothSparkPaymentProcessor(accountFrom, accountTo):
        setMessage(MOVE_FUNDS_FX_WEEKEND_MESSAGE);

        return;

      default:
        setMessage(null);
    }
  }, [ accountFrom, accountTo ]);

  if (!message) return null;

  return (
    <S.Row>
      <S.MoveFundsNotificationBlock>
        <AlertBlock text={message} className={className} />
      </S.MoveFundsNotificationBlock>
    </S.Row>
  );
}

MoveFundsNotification.propTypes = {
  className: PropTypes.string,
  accountFrom: PropTypes.instanceOf(Object),
  accountTo: PropTypes.instanceOf(Object),
};

export default MoveFundsNotification;
