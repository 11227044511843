import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { formatAmount } from 'utils/amount';
import { navigation } from 'constants/navigation';

import S from './styles';

function BulkPaymentsFinal({ success, totals, currency }) {
  const history = useHistory();

  const backToAccounts = () => {
    history.push(navigation.accounts);
  };

  const onViewDetails = () => {
    history.push(navigation.activity);
  };

  const successMessage = `
      You sent ${formatAmount(totals[currency.toLowerCase()]?.net)} ${currency}
      to multiple beneficiaries.
    `;

  const failureMessage = `
      Something went wrong — your money was not sent. Please try again. 
      If this keeps failing, please contact Support.
    `;

  return (
    <div>
      <S.IconWrapper>
        {success ? <S.SuccessIcon /> : <S.ErrorIcon />}
      </S.IconWrapper>
      <S.Text>
        {success ? successMessage : failureMessage}
      </S.Text>
      <S.Button
        back
        label="Back to account"
        category="secondary"
        onClick={backToAccounts}
      />
      {success && (
        <S.Button
          label="View payment details"
          category="primary"
          onClick={onViewDetails}
        />
      )}
    </div>
  );
}

BulkPaymentsFinal.propTypes = {
  success: PropTypes.bool,
  totals: PropTypes.instanceOf(Object),
  currency: PropTypes.string,
};

export default BulkPaymentsFinal;
