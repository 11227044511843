import bff from 'utils/bff';

export const getAccounts = async queryParams => {
  const { data: accountList } = await bff.account.listAccounts({}, queryParams);

  return accountList.data
    .map(a => ({
      id: a.id,
      balance: a.balance,
      amount: a.balance,
      displayName: a.displayName,
      name: a.displayName,
      currency: a.currency,
      requisites: a.requisites,
      paymentProcessorAdapter: a.paymentProcessorAdapter,
      isHidden: a.isHidden || false,
      labels: a.labels || [],
      supportedRails: a.supportedRails,
    }));
};

export const getAccount = async id => {
  const { data } = await bff.account.viewAccount({}, { id });

  return data;
};

export const updateAccount = async (id, payload) => {
  const { data } = await bff.account.updateAccount(payload, { id });

  return data;
};

export const getAccountBankDetails = async id => {
  const { data } = await bff.account.viewBankDetails({}, { id });

  return data[0];
};
