import { Button as DefaultButton } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

const Content = styled.div`
  padding: 3.625rem 3.125rem 3.125rem 3.125rem;
  background: ${props => props.theme.colors.white};
  width: 36.25rem;
  border-radius: 0.5rem;
`;

const Title = styled.p`
  font: ${props => props.theme.fonts.h4Bold};
  color: ${props => props.theme.colors.black};
  margin: 0;
  margin-bottom: 1.25rem;
`;

const Message = styled.p`
  margin: 0;
  font: ${props => props.theme.fonts.bodyL};
  margin-bottom: 1.875rem;
`;

const Button = styled(DefaultButton)`
  margin-right: 0.625rem;
  min-width: fit-content;
  padding: 0.75rem 1.5rem;
`;

export default {
  Content,
  Title,
  Message,
  Button,
};
