import { Avatar as DefaultAvatar } from '@general/intergiro-ui-kit';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';

const Inner = styled.div`
  margin: 0;
  display: flex;
  align-items: flex-start;

  @media ${DEVICE.tablet} {
    align-items: center;
  }
`;

const Avatar = styled(DefaultAvatar)`
  margin-right: 0.8rem;
  opacity: 1;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  flex-shrink: 0;
`;

const Name = styled.p`
  font-weight: 600;
  display: inline-block;
  margin: 0;
  color: ${props => props.theme.colors.black};
`;

const Merchant = styled(Link)`
  align-items: center;
  border-radius: 0.5rem;
  box-shadow: ${props => props.theme.boxShadow};
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.25rem;
  min-height: 6.5625rem;
  padding: 1rem 1.25rem 1rem 1rem;
  width: 100%;

  @media ${DEVICE.desktop} {
    margin-bottom: ${props => (props.$isGrid ? '1.875rem' : '0.625rem')};
    min-height: ${props => (props.$isGrid ? '10rem' : 'auto')};
    padding: ${props => (props.$isGrid ? '1.25rem 1.875rem 1.25rem 1.25rem' : '1rem 1.25rem')};
    width: ${props => (props.$isGrid ? 'calc(50% - 0.9375rem)' : '100%')};
  }
`;

const Merchants = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 1.25rem;

  @media ${DEVICE.tablet} {
    padding: 1.25rem 0 2.5rem;
  }
`;

export default {
  Inner,
  Name,
  Avatar,
  Merchant,
  Merchants,
};
