import PropTypes from 'prop-types';
import React from 'react';

import { ActivityButtons } from 'components';
import { CURRENCY_FLAGS } from 'constants/currencies';
import { formatAmount } from 'utils/amount';

import S from '../styles';

function AccountHeader({ account }) {
  const [ amountFormatted, decimalFormatted ] = formatAmount(account.balance).split('.');
  const hasNegativeBalance = account.balance < 0;

  return (
    <S.Header>
      <S.HeaderWrapper>
        {account.id ? (
          <S.Balance>
            <S.Flag countryCode={CURRENCY_FLAGS[account.currency]} />
            <S.Currency>
              {account.currency}
            </S.Currency>
            <S.Amount isDanger={hasNegativeBalance}>
              {amountFormatted}
              .
              <S.Decimal>
                {decimalFormatted}
              </S.Decimal>
            </S.Amount>
          </S.Balance>
        ) : (
          <span />
        )}
        <ActivityButtons account={account} />
      </S.HeaderWrapper>
    </S.Header>
  );
}

AccountHeader.propTypes = {
  account: PropTypes.instanceOf(Object).isRequired,
};

export default AccountHeader;
