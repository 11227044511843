import Plausible from 'plausible-tracker';

import config from 'config';

function plausibleTrackPageviews() {
  if (config.envName === 'production') {
    const plausible = Plausible({ domain: config.analyticsDomainName });

    plausible.enableAutoPageviews();
  }
}

export default plausibleTrackPageviews;
