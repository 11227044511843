import styled from 'styled-components';

import { ReactComponent as ChevronLeft } from 'assets/icons/chevron-left.svg';
import { DEVICE } from 'constants/breakpoints';

const Container = styled.div`
  background: ${props => props.theme.colors.greyBright};
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${DEVICE.laptop} {
    width: 3rem;
    height: 3rem;
  }

  &:hover {
    cursor: pointer;
    background: ${props => props.theme.colors.greyLight};
  }
`;

const ChevronIcon = styled(ChevronLeft)`
  margin-right: 0.1875rem;
  path {
    fill: ${props => props.theme.colors.black};
  }
`;

export default {
  ChevronIcon,
  Container,
};
