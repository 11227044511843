import PropTypes from 'prop-types';
import React from 'react';

import S from './styles';

function FilterDropdown({ count, defaultLabel, name, selectedLabel, visible }) {
  return (
    <S.FilterElementWrapper isOpen={visible}>
      {count ? (
        <S.CounterLabelWrapper data-testid={`filter-dropdown-${name}`}>
          <span>
            {selectedLabel}
          </span>
          {' '}
          <S.CountBadge>
            {count}
          </S.CountBadge>
        </S.CounterLabelWrapper>
      ) : (
        defaultLabel
      )}
      <S.ChevronDown />
    </S.FilterElementWrapper>
  );
}

FilterDropdown.propTypes = {
  count: PropTypes.number,
  defaultLabel: PropTypes.string,
  name: PropTypes.string,
  selectedLabel: PropTypes.string,
  visible: PropTypes.bool,
};

export default FilterDropdown;
