export const formFields = {
  phone: {
    autocomplete: 'tel',
    name: 'phone',
  },
  username: {
    autocomplete: 'username',
    name: 'username',
  },
  newPassword: {
    autocomplete: 'new-password',
    name: 'newPassword',
  },
  confirmNewPassword: {
    autocomplete: 'new-password',
    name: 'confirmNewPassword',
  },
  currentPassword: {
    autocomplete: 'currentPassword',
    name: 'currentPassword',
  },
};

export const modalTypes = {
  password: 'password',
  phone: 'phone',
};

export const CONFIRM_PASSWORD_FORM = 'confirmPasswordForm';

// for testing
export const dataTestIds = {
  confirmUserUpdateButton: 'update-user-data-confirm-button',
};
