import { transactionStatus } from 'constants/transactions';

export function hex2rgba(hex, alpha = 1) {
  const [ r, g, b ] = hex.match(/\w\w/g).map(x => parseInt(x, 16));

  return `rgba(${r},${g},${b},${alpha})`;
}

export function getColorsByStatus(status, colors) {
  switch (status) {
    case transactionStatus.pending:
    case transactionStatus.pendingApproval:
      return colors.orange;
    case transactionStatus.failed:
    case transactionStatus.rejected:
      return colors.red;
    default:
      return colors.greyDark;
  }
}
