import dayjs from 'dayjs';

export function getPreviousMonthDateRange() {
  const prevMonth = dayjs().subtract(1, 'month');

  return {
    from: prevMonth.startOf('month').toDate(),
    to: prevMonth.endOf('month').toDate(),
  };
}

export const checkIfExpired = (currentDate, date) => currentDate.diff(date) > 0;

export const checkIfExpireSoon = (currentDate, date, daysBeforeExpiration) => {
  const diff = currentDate.diff(date, 'days', true);

  if (diff < 0 && Math.abs(diff) <= daysBeforeExpiration) return true;

  return false;
};

export const doesDateHasBothRanges = date => date?.from && date?.to;
