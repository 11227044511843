import countriesList from 'countries-list';

export const getCountriesOptions = () => Object.keys(countriesList.countries).map(key => ({
  label: countriesList.countries[key].name,
  value: key,
}));

export const getCountryOption = country =>
  ({ label: countriesList.countries[country].name, value: country });

export const countries = Object.keys(countriesList.countries)
  .sort((country1, country2) => {
    if (countriesList.countries[country1].name > countriesList.countries[country2].name) {
      return 1;
    }

    return -1;
  })
  .reduce((res, country) => ({
    ...res,
    [country]: countriesList.countries[country].name,
  }),
  {});
