import { Tabs } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';

const TransactionCounter = styled.span`
  color: ${props => props.theme.colors.red};
  font-size: 0.875rem;
  margin-left: 0.3125rem;
`;

const TabsHead = styled(Tabs.Head)`
  margin: 0 0 0 1.1rem;
  padding: 0 0 0.8125rem 0;

  @media ${DEVICE.tablet} {
    margin: 0;
  }
`;

export default {
  TransactionCounter,
  TabsHead,
};
