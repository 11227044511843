import PropTypes from 'prop-types';
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useSidebarInteractions } from 'hooks';
import { TransactionSidebar } from 'components';

import { dataTestIds } from './constants';
import S from './styles';

export const TransactionContext = createContext({});

function TransactionProvider({ isPendingApproval, children }) {
  const {
    isTopArrowDisabled,
    isUpArrowDisabled,
    isSidebarOpen,
    setIsSidebarOpen,
    setIsTopArrowDisabled,
    setIsUpArrowDisabled,
  } = useSidebarInteractions();
  const [ transactions, setTransactions ] = useState([]);
  const [ activeIndex, setActiveIndex ] = useState(null);
  const [ activeTransaction, setActiveTransaction ] = useState(null);

  useEffect(() => {
    if (transactions.length === 0) {
      setIsSidebarOpen(false);
    }
  }, [transactions]);

  const handleUpArrowClick = useCallback(() => {
    const nextStep = activeIndex === 0 ? transactions.length - 1 : activeIndex - 1;
    setActiveIndex(nextStep);
  }, [ activeIndex, transactions ]);

  const handleDownArrowClick = useCallback(() => {
    const nextStep = activeIndex === transactions.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextStep);
  }, [ activeIndex, transactions ]);

  function setActiveTransactionById(transactionId) {
    if (!transactionId) {
      setActiveIndex(null);

      return;
    }

    const index = transactions.findIndex(object => object.id === transactionId);
    setActiveIndex(index);
  }

  function mapTransactionByIndex(index) {
    const transaction = transactions[index];

    return {
      debitedAmount: transaction.debitedAmount,
      netTransactionAmount: transaction.netTransactionAmount,
      author: transaction.createdBy.name,
      createdAt: transaction.createdAt,
      createdBy: transaction.createdBy,
      sourceAmount: transaction.sourceAmount,
      targetAmount: transaction.targetAmount,
      externalFee: transaction.externalFee,
      exchangeRate: transaction.exchangeRate,
      fromAccount: transaction.fromAccount,
      toAccount: transaction.toAccount,
      merchant: transaction.merchant,
      cardholder: transaction.cardholder,
      totalFee: transaction.totalFee,
      id: transaction.id,
      reference: transaction.reference,
      purposeCode: transaction.purposeCode,
      sourceTarget: transaction.sourceTarget,
      sourceCurrency: transaction.sourceCurrency,
      status: transaction.status,
      statusReasons: transaction.statusReasons,
      targetCurrency: transaction.targetCurrency,
      isMultiCurrencyTransaction: transaction.isMultiCurrencyTransaction,
      type: transaction.type,
      approvalRequestId: transaction.approvalRequestId,
      approvedBy: transaction.approvedBy,
      rejectedBy: transaction.rejectedBy,
    };
  }

  const handleClose = useCallback(() => {
    setActiveIndex(null);
  }, []);

  useEffect(() => {
    if (activeIndex === null) {
      setActiveTransaction(null);
      setIsSidebarOpen(false);

      return;
    }

    const mappedTransaction = mapTransactionByIndex(activeIndex);
    setActiveTransaction(mappedTransaction);
    setIsSidebarOpen(true);
  }, [activeIndex]);

  const initialValue = useMemo(() => ({
    activeTransaction,
    isSidebarOpen,
    setActiveTransactionById,
    setTransactions,
    transactions,
    isPendingApproval,
  }), [
    activeTransaction,
    isSidebarOpen,
    setActiveTransactionById,
    setTransactions,
    transactions,
    isPendingApproval,
  ]);

  return (
    <TransactionContext.Provider value={initialValue}>
      {children}
      <S.ActivityWrapper data-testid={dataTestIds.activityWrapperDataTestId}>
        <S.Sidebar
          handleClose={handleClose}
          handleDownArrowClick={handleDownArrowClick}
          handleUpArrowClick={handleUpArrowClick}
          isCloseOutsideEnabled={false}
          isControlPanelVisible
          isOpen={isSidebarOpen}
          isTopArrowDisabled={isTopArrowDisabled}
          isUpArrowDisabled={isUpArrowDisabled}
          setIsTopArrowDisabled={setIsTopArrowDisabled}
          setIsUpArrowDisabled={setIsUpArrowDisabled}
          data-testid={dataTestIds.transactionSidebarDataTestId}
        >
          {isSidebarOpen && (
            <TransactionSidebar
              {...activeTransaction}
              isPendingApproval={isPendingApproval}
            />
          )}
        </S.Sidebar>
      </S.ActivityWrapper>
    </TransactionContext.Provider>
  );
}

TransactionProvider.propTypes = {
  isPendingApproval: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default TransactionProvider;
