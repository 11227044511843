import styled from 'styled-components';
import { Avatar as DefaultAvatar } from '@general/intergiro-ui-kit';

import { hex2rgba } from 'helpers/styles';
import { tableRows } from 'pages/order-cards/constants';
import { DEVICE } from 'constants/breakpoints';
import { Badge as DefaultBadge } from 'components';
import { ReactComponent as PencilIcon } from 'assets/icons/pencil.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';

const getSectionWidth = props => {
  switch (props.name) {
    case tableRows.name:
      return props.isVirtual ? '45%' : '31%';
    case tableRows.cardName:
      return props.isVirtual ? '43%' : '22.5%';
    case tableRows.deliverTo:
      return '31%';
    default:
      return props.isVirtual ? '12%' : '15.5%';
  }
};

const Table = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media ${DEVICE.desktop} {
    width: 71.5rem;
  }
`;

const Header = styled.header`
  width: 100%;
  display: none;
  height: 3.5rem;
  flex-direction: row;
  align-items: center;
  padding: 0 1rem;
  border-bottom: 0.0625rem solid ${props => props.theme.colors.greyBright};
  
  @media ${DEVICE.tablet} {
    display: flex;
  }
  
  @media ${DEVICE.desktop} {
    width: 66.25rem;
  }
`;

const Section = styled.div`
  display: flex;
  width: ${getSectionWidth};
  flex-direction: row;
  align-items: center;
  justify-content: ${props => props.name === tableRows.dueDate && 'flex-end'};
  margin-left: ${props => props.name ? 'none' : 'auto'};
  padding-right: ${props => props.name ? '1rem' : 'unset'};
  box-sizing: border-box;
`;

const SectionColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 2.75rem);
`;

const SectionValue = styled.div`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SectionName = styled.div`
  font: ${props => props.theme.fonts.bodyMSemi};
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const AccountDetails = styled.div`
  display: none;
  font: ${props => props.theme.fonts.bodyXs};
  color: ${props => props.theme.colors.greyMedium};
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${DEVICE.tablet} {
    display: flex;
    margin-top: 0.25rem;
  }
`;

const HeaderLabel = styled.span`
  font: ${props => props.theme.fonts.bodySBold};
  color: ${props => props.theme.colors.greyMedium};
`;

const Actions = styled.div`
  display: none;
  flex-direction: row;
  margin-left: 1.5rem;
  align-items: center;
`;

const EditIcon = styled(PencilIcon)`
  width: 1rem;
  height: 1rem;
  margin-right: 1.75rem;

  fill: ${props => props.theme.colors.blue};

  &:hover {
    cursor: pointer;
  }
`;

const DeleteIcon = styled(TrashIcon)`
  width: 1rem;
  height: 1rem;

  fill: ${props => props.theme.colors.red};

  &:hover {
    cursor: pointer;
  }
`;

const RowContent = styled.div`
  display: flex;
  width: 100%;
  padding: 1.25rem 1rem;
  flex-direction: row;
  transition: ${props => props.theme.transition};
  cursor: pointer;
  background-color: ${props => props.isSelected && hex2rgba(props.theme.colors.cream, 0.3)};
  ${props => props.isUnclickable && `
    pointer-event: none;
  `}
  ${props => props.isLastChild && `
    border-bottom: 0.0625rem solid ${props.theme.colors.greyBright};
  `}

  &:hover {
    background-color: ${props => !props.isSelected && props.theme.colors.greyBright}
  }

  @media ${DEVICE.desktop} {
    width: 66.25rem;
  }
`;

const Row = styled.div`
  width: 100%;
  display: none;
  flex-direction: row;
  align-items: center;

  @media ${DEVICE.tablet} {
    display: flex;
  }
  
  @media ${DEVICE.desktop} {
    &:hover ${Actions} {
      display: flex;
    }
  } 
`;

const MobileRow = styled.div`
  padding: 0.75rem 0;
  display: flex;
  ${props => props.isLastChild && `
    border-bottom: 0.0625rem solid ${props.theme.colors.greyBright};
  `}

  @media ${DEVICE.tablet} {
    display: none;
  }
`;

const MobileTitle = styled.div`
  font: ${props => props.theme.fonts.bodySSemi};
`;

const MobileSection = styled.div`
  font: ${props => props.theme.fonts.bodyXs};
  color: ${props => props.theme.colors.greyMedium};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 0.125rem;
`;

const MobileSectionRow = styled.div`
  display: flex;
  align-items: center;
`;

const MobileContent = styled.div`
  width: calc(100% - 3rem);
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const Avatar = styled(DefaultAvatar)`
  margin-right: 1rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  flex-shrink: 0;
  
  & p {
    top: 50%;
  }
  
  & svg {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  @media ${DEVICE.tablet} {
    margin-right: 0.75rem;
  }
`;

const Badge = styled(DefaultBadge)`
  ${props => props.isSuccess && `
    background: ${props.theme.colors.green};
  `}
  width: auto;
  display: inline-flex;
`;

export default {
  Table,
  Header,
  Section,
  SectionColumn,
  SectionValue,
  SectionName,
  HeaderLabel,
  MobileRow,
  MobileTitle,
  MobileSection,
  MobileSectionRow,
  MobileContent,
  Content,
  Row,
  RowContent,
  Avatar,
  Badge,
  AccountDetails,
  Actions,
  EditIcon,
  DeleteIcon,
};
