import { Button as DefaultButton, Input as DefaultInput } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';

const Container = styled.div`
  padding-bottom: 2.5rem;
`;

const Input = styled(DefaultInput)`
  @media ${DEVICE.tablet} {
    width: 22.5rem;
  }

  @media ${DEVICE.laptop} {
    width: 30rem;
  }
`;

const Button = styled(DefaultButton)`
  width: 100%;
  margin-top: 2rem;

  @media ${DEVICE.tablet} {
    min-width: auto;
    width: auto;
  }
`;

export default {
  Container,
  Input,
  Button,
};
