import get from 'lodash/get';
import truncate from 'lodash/truncate';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

import { ReactComponent as IntergiroLogo } from 'assets/icons/logo.svg';
import { Hint } from 'components';
import Permit from 'components/permit';
import { navigation, navigationLinks } from 'constants/navigation';
import { ApiKeysContext } from 'contexts/api-keys';
import { NavigationContext } from 'contexts/navigation';
import { PaymentCountContext } from 'contexts/payment-count';
import { UserContext } from 'contexts/user';
import { useView, useWindowHeight, useWindowSize } from 'hooks';

import checkIfLinkIsActive from './utils';
import { DataTestIds } from './testing';
import S from './styles';
import { MAX_SYMBOLS_IN_CORPORATION_NAME, OMIT_BY } from './constants';
import Header from './components/header';

function NavbarLayout({ children, backButton }) {
  const { count: { numberOfAllPayments } } = useContext(PaymentCountContext);
  const { setSidebarWidth } = useContext(NavigationContext);
  const { expiredAmount: expiredApiKeysCount } = useContext(ApiKeysContext);
  const [ isOpen, setIsOpen ] = useState(false);
  const match = useRouteMatch();
  const { user } = useContext(UserContext);
  const sidebarRef = useRef(null);
  const { isMobile } = useView();

  const { width } = useWindowSize();

  useWindowHeight();

  const corporationName = get(user, 'corporationName');

  const getCountLabel = to => {
    switch (to) {
      case navigation.payments:
        return numberOfAllPayments ? (
          <S.Badge>
            {numberOfAllPayments}
          </S.Badge>
        ) : null;
      case navigation.api:
        return expiredApiKeysCount ? (
          <S.Badge>
            {expiredApiKeysCount}
          </S.Badge>
        ) : null;
      default:
        return null;
    }
  };

  const getNavigation = () => (
    <nav>
      {navigationLinks.map(({ to, label, icon, count, extra, permissions }) => (
        <Permit permissions={permissions?.list} key={label} atLeastOne={permissions?.atLeastOne}>
          <S.Link
            exact
            activeClassName="active"
            key={label}
            to={to}
            isActive={() => checkIfLinkIsActive(match, to, extra)}
            onClick={closeMenu}
            data-intercom-target={`navbar-item-anchor-${label.toLowerCase()}`}
          >
            <S.IconWrapper>
              {icon}
            </S.IconWrapper>
            {label}
            {count && getCountLabel(to)}
          </S.Link>
        </Permit>
        ))}
    </nav>
  );

  const toggleOpen = useCallback(() => {
    setIsOpen(isOpenState => !isOpenState);
  }, []);

  function closeMenu() {
    setIsOpen(false);
  }

  const handleDocumentClick = useCallback(event => {
    if (sidebarRef.current.contains(event.target)) {
      return;
    }

    setIsOpen(false);
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleDocumentClick);

    return () => {
      document.removeEventListener('mousedown', handleDocumentClick);
    };
  }, []);

  useEffect(() => {
    if (sidebarRef.current && setSidebarWidth) {
      setSidebarWidth(sidebarRef.current.clientWidth);
    }
  }, [ setSidebarWidth, sidebarRef.current ]);

  const getCorporationName = () => {
    if (corporationName.length < MAX_SYMBOLS_IN_CORPORATION_NAME) {
      return corporationName;
    }
    const truncatedName = truncate(corporationName,
      { length: MAX_SYMBOLS_IN_CORPORATION_NAME, omission: OMIT_BY });

    return (
      <Hint text={corporationName}>
        {truncatedName}
      </Hint>
    );
  };

  return (
    <S.Main data-testid={DataTestIds.wrapper}>
      <aside ref={sidebarRef}>
        <S.Sidebar data-intercom-target={!isMobile && 'menu_anchor'} isHidden={!isOpen} innerWidth={width} data-testid={DataTestIds.sidebar}>
          <S.SidebarInner>
            <S.LogoWrapper>
              <Link to={navigation.accounts}>
                <IntergiroLogo />
              </Link>
              <S.CrossWrapper onClick={toggleOpen} skipBorder data-testid={DataTestIds.crossIcon}>
                <div>
                  <S.Cross />
                </div>
              </S.CrossWrapper>
            </S.LogoWrapper>
            <S.CorporationNameWrapper>
              <S.CorporationName>
                {getCorporationName()}
              </S.CorporationName>
            </S.CorporationNameWrapper>
            {getNavigation()}
          </S.SidebarInner>
        </S.Sidebar>
        <S.MobileMenu isOpen={isOpen}>
          <S.Header skipPadding>
            <IntergiroLogo />
            <S.CrossWrapper onClick={toggleOpen}>
              <div>
                <S.Cross />
              </div>
            </S.CrossWrapper>
          </S.Header>
          <S.CorporationNameWrapper data-testid={DataTestIds.corporationWrapper}>
            <S.CorporationName data-testid={DataTestIds.corporationWrapper} tag="h3">
              {corporationName}
            </S.CorporationName>
          </S.CorporationNameWrapper>
          {getNavigation()}
        </S.MobileMenu>
      </aside>
      <S.Container>
        <S.Header>
          <Header
            isMobile={isMobile}
            isOpen={isOpen}
            toggleOpen={toggleOpen}
            backButton={backButton}
          />
        </S.Header>
        <S.Content>
          {children}
        </S.Content>
      </S.Container>
    </S.Main>
  );
}

NavbarLayout.propTypes = {
  children: PropTypes.node.isRequired,
  backButton: PropTypes.shape({
    link: PropTypes.string,
    label: PropTypes.string,
  }),
};

export default NavbarLayout;
