import React, { useContext, useState } from 'react';
import { CircleFlag } from 'react-circle-flags';

import {
  Card,
  CardStatus,
  Dash,
  Dot,
  LastFourDigits,
  TwoFaModal,
} from 'components';
import {
  cardExpiredAndBlockedStatuses,
  cardStatusesMap,
  cardStatusReasonMap,
  cardBlockingReasons,
  labels,
  PHYSICAL_CARD_TYPE,
  cardScaActions,
  cardScaAuthenticationElements,
} from 'constants/card';
import { CURRENCY_FLAGS } from 'constants/currencies';
import { navigation } from 'constants/navigation';
import { CardContext } from 'contexts/card';
import { GlobalLoaderContext } from 'contexts/global-loader';
import TwoFaProvider from 'contexts/two-fa';
import { formatAmount } from 'utils/amount';
import { showGeneralToastError, rethrowTwoFaError } from 'utils/errors';
import { getScaData, getConfirmScaData, formatExpirationDate } from 'utils/card';
import {
  getCardCredentials,
  unFreezeCard,
  freezeCard,
  fraudCard,
  lostCard,
  stolenCard,
  terminateCard,
  createSca,
  confirmSca,
} from 'api/card';

import S from './styles';
import ResetPinModal from '../reset-pin-modal';
import DetailItem from '../detail-item';
import CountdownProgressBar from '../countdown-progress';
import ChangePinModal from '../change-pin-modal';
import BlockCardModal from '../block-card-modal';

function CardDetails() {
  const { startLoading, endLoading } = useContext(GlobalLoaderContext);
  const { card, isMyCard, setCard } = useContext(CardContext);
  const [ flipped, setFlipped ] = useState(false);
  const [ changePinModalIsOpen, setChangePinModalIsOpen ] = useState(false);
  const [ resetPinModalIsOpen, setResetPinModalIsOpen ] = useState(false);
  const [ blockModalIsOpen, setBlockModalIsOpen ] = useState(false);
  const [ credentials, setCredentials ] = useState(null);
  const [ twoFaModalIsOpen, setTwoFaModalIsOpen ] = useState(false);
  const [ twoFaData, setTwoFaData ] = useState(null);

  const hasPinRetriesExceeded =
    card.statusReason === cardStatusReasonMap.limitPinAttemptsReached;

  const updateCardStatus = async status => {
    startLoading();

    try {
      const updatedCard = await getUpdateCardApiMethodByStatus(status, card.id);

      setCard(updatedCard);
      setFlipped(false);
    } catch (error) {
      showGeneralToastError(error);
    } finally {
      endLoading();
    }
  };

  const handleDetailsClick = async () => {
    if (credentials) {
      setFlipped(!flipped);

      return;
    }

    startLoading();

    try {
      const sca = await createSca({
        action: cardScaActions.getCard,
        authenticationElements: [ cardScaAuthenticationElements.accessToken, cardScaAuthenticationElements.smsOtp ],
      });
      const challenge = getScaData(sca);

      setTwoFaData(challenge);
      setTwoFaModalIsOpen(true);
    } catch (error) {
      showGeneralToastError(error);
    } finally {
      endLoading();
    }
  };

  const handleConfirmCode = async challenge => {
    const data = getConfirmScaData(challenge);

    try {
      const { authenticationCode } = await confirmSca(challenge.scaId, data);
      const cardCredentials = await getCardCredentials(card.id, authenticationCode);

      setCredentials(cardCredentials);
      setTwoFaModalIsOpen(false);
      setFlipped(!flipped);
    } catch (error) {
      rethrowTwoFaError(error);
      setTwoFaModalIsOpen(false);
      showGeneralToastError(error);
    }
  };

  const handleResendCode = async () => {
    const sca = await createSca({
      action: cardScaActions.getCard,
      authenticationElements: [ cardScaAuthenticationElements.accessToken, cardScaAuthenticationElements.smsOtp ],
    });
    const challenge = getScaData(sca);

    setTwoFaData(challenge);

    return { challenge };
  };

  const freezeButton = card.status === cardStatusesMap.frozen
    ? (
      <S.UnfreezeActionButton
        key="unfreezeCard"
        label="Unfreeze card"
        icon={<S.UnfreezeIcon />}
        onClick={() => updateCardStatus(cardStatusesMap.active)}
      />
      )
    : (
      <S.ActionButton
        key="freezeCard"
        label="Freeze card"
        icon={<S.FreezeIcon />}
        onClick={() => updateCardStatus(cardStatusesMap.frozen)}
      />
      );
  const changePinButton = (
    <S.ActionButton
      key="changePin"
      label="Change PIN"
      icon={<S.PinIcon />}
      onClick={() => setChangePinModalIsOpen(true)}
    />
  );
  const resetPinButton = (
    <S.ActionButton
      key="resetPin"
      label="Retry PIN entry"
      icon={<S.SecurityIcon />}
      onClick={() => setResetPinModalIsOpen(true)}
    />
  );
  const blockButton = (
    <S.ActionButton
      key="blockCard"
      label="Block card"
      icon={<S.BlockIcon />}
      onClick={() => setBlockModalIsOpen(true)}
    />
  );
  const showDetailsButton = (
    <CountdownProgressBar
      key="cardDetails"
      flipped={flipped}
      setFlipped={setFlipped}
      onClick={handleDetailsClick}
    />
  );

  const getActionButtons = () => {
    if (!isMyCard) return [];

    switch (card.status) {
      case cardStatusesMap.blocked:
        return [];
      case cardStatusesMap.notActivated:
      case cardStatusesMap.frozen:
        return [
          freezeButton,
          blockButton,
        ];
      case cardStatusesMap.securityBlock:
        return [ hasPinRetriesExceeded && resetPinButton, blockButton ].filter(Boolean);
      case cardStatusesMap.active:
        return [
          showDetailsButton,
          card.type === PHYSICAL_CARD_TYPE ? changePinButton : null,
          freezeButton,
          blockButton,
        ].filter(Boolean);
      default:
        return [];
    }
  };

  return (
    <S.Main>
      <S.Section card>
        <Card card={card} credentials={credentials} isMyCard={isMyCard} flipped={flipped} />
        <S.ActionsContainers data-testid="card-action-buttons">
          {getActionButtons().map(btn => btn)}
        </S.ActionsContainers>
      </S.Section>
      <S.Section marginTop>
        <DetailItem label="Card Name" value={card.displayName || <Dash />} />
        <DetailItem label="Available balance" value={`${formatAmount(card.availableBalance, card.currency)} ${card.currency}`} />
        <DetailItem label="Card Number" value={<LastFourDigits lastFour={card.pan.lastFour} />} />
        <DetailItem label="Type"
          value={(
            <>
              {labels[card.fundingType]}
              <Dot />
              {labels[card.type]}
            </>
          )}
        />
        <DetailItem label="Card Account" value={card.account.name} />
      </S.Section>
      <S.Section>
        <DetailItem label="Cardholder"
          value={(
            <S.UserName to={`${navigation.users}/${card.cardholder.referenceId}`}>
              {card.cardholder.firstName}
              {' '}
              {card.cardholder.lastName}
            </S.UserName>
          )}
        />
        <DetailItem label="Currency"
          value={(
            <S.CurrencyContainer>
              <CircleFlag countryCode={CURRENCY_FLAGS[card.currency]} height="20" />
              <S.Currency>
                {card.currency}
              </S.Currency>
            </S.CurrencyContainer>
          )}
        />
        <DetailItem label="Valid thru" value={cardExpiredAndBlockedStatuses.includes(card.status) ? <Dash /> : formatExpirationDate(card.expiry)} />
        <DetailItem label="Status" value={<CardStatus status={card.status} statusReason={card.statusReason} />} />
      </S.Section>
      {changePinModalIsOpen && (
        <ChangePinModal
          id={card.id}
          isOpen={changePinModalIsOpen}
          setIsOpen={setChangePinModalIsOpen}
        />
      )}
      {blockModalIsOpen && (
        <BlockCardModal
          type={card.type}
          isOpen={blockModalIsOpen}
          setIsOpen={setBlockModalIsOpen}
          handleBlock={status => updateCardStatus(status)}
        />
      )}
      {resetPinModalIsOpen && (
        <ResetPinModal
          id={card.id}
          isOpen={resetPinModalIsOpen}
          setIsOpen={setResetPinModalIsOpen}
          setCard={setCard}
        />
      )}
      {twoFaModalIsOpen && (
        <TwoFaProvider twoFaData={twoFaData}>
          <TwoFaModal
            isOpen={twoFaModalIsOpen}
            setIsOpen={setTwoFaModalIsOpen}
            onSubmit={handleConfirmCode}
            onResend={handleResendCode}
            challenge={twoFaData}
          />
        </TwoFaProvider>
      )}
    </S.Main>
  );
}

function getUpdateCardApiMethodByStatus(status, cardId) {
  switch (status) {
    case cardStatusesMap.active:
      return unFreezeCard(cardId);

    case cardStatusesMap.frozen:
      return freezeCard(cardId);

    case cardBlockingReasons.fraud:
      return fraudCard(cardId);

    case cardBlockingReasons.lost:
      return lostCard(cardId);

    case cardBlockingReasons.stolen:
      return stolenCard(cardId);

    case cardBlockingReasons.terminate:
      return terminateCard(cardId);

    default:
      return {};
  }
}

export default CardDetails;
