import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from '@general/intergiro-ui-kit';

import S from './styles';

function SwitchBlock({ title, titleIcon, text, checked, onChange }) {
  return (
    <S.Container>
      <S.Notion>
        <S.Title>
          {titleIcon}
          {title}
        </S.Title>
        <S.Text>
          {text}
        </S.Text>
      </S.Notion>
      <Switch
        checked={checked}
        onChange={onChange}
      />
    </S.Container>
  );
}

SwitchBlock.propTypes = {
  title: PropTypes.string.isRequired,
  titleIcon: PropTypes.node,
  text: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SwitchBlock;
