import { getActiveCheckboxValues, getURLWithAlteredParams } from 'utils/helpers';

function updateURLToMatchParameters(list, name, history) {
  const urlList = getActiveCheckboxValues(list);
  const alteredURL = getURLWithAlteredParams(
    name, urlList, history.location.pathname, history.location.search,
  );
  history.push(alteredURL);
}

export default updateURLToMatchParameters;
