import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { navigateToNotFoundPage, showGeneralToastError } from 'utils/errors';
import { getAccount } from 'api/accounts';
import { GlobalLoaderContext } from 'contexts/global-loader';
import { NOT_FOUND } from 'constants/status-code';
import { resourceType } from 'constants/resource';

const useAccount = id => {
  const history = useHistory();
  const { startLoading, endLoading } = useContext(GlobalLoaderContext);
  const [ isLoading, setLoading ] = useState(false);
  const [ account, setAccount ] = useState({});

  useEffect(() => {
    if (!id) return;

    const fetchAccount = async () => {
      setLoading(true);
      startLoading();

      try {
        const acc = await getAccount(id);

        setAccount(acc);
      } catch (error) {
        error.status === NOT_FOUND
          ? navigateToNotFoundPage(history, resourceType.account)
          : showGeneralToastError(error);
      } finally {
        setLoading(false);
        endLoading();
      }
    };

    fetchAccount();
  }, [id]);

  return { isLoading, account };
};

export default useAccount;
