import styled from 'styled-components';

const ViewSwitcher = styled.button`
  align-items: center;
  align-self: flex-end;
  background-color: transparent;
  border: none;
  color: ${props => props.theme.colors.blue};
  cursor: pointer;
  display: flex;
  font: ${props => props.theme.fonts.bodyMSemi};
  margin: 0;
  padding: 0;
  position: ${props => props.absolute && 'absolute'};
  right: 0;
  top: 0;

  svg {
    fill: ${props => props.theme.colors.blue};
    margin-right: 0.375rem;
  }
`;

export default {
  ViewSwitcher,
};
