import { Modal } from '@general/intergiro-ui-kit';
import PropTypes from 'prop-types';
import React from 'react';

import S from './styles';

function PaymentErrorModal({ numberOfFailedTransactions, isOpen }) {
  const onRefresh = () => {
    window.location.reload(false);
  };

  return (
    <Modal isOpen={isOpen}>
      <S.Content>
        <S.Title>Payment(s) already processed</S.Title>
        <S.Message>
          Someone else already processed
          {' '}
          {numberOfFailedTransactions}
          {' '}
          of the payments
          you selected while you were on this page.
          Please refresh the page and try again.
        </S.Message>
        <S.Button category="primary" label="Refresh" onClick={onRefresh} />
      </S.Content>
    </Modal>
  );
}

PaymentErrorModal.propTypes = {
  numberOfFailedTransactions: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default PaymentErrorModal;
