import PropTypes from 'prop-types';
import React, { useState } from 'react';

import S from './styles';

function Dropdown(props) {
  const [ isOpen, setIsOpen ] = useState(false);
  const { children, title } = props;

  return (
    <S.DropdownWrapper isOpen={isOpen}>
      <S.DropdownContainer>
        <S.DropdownTitleWrapper isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
          <S.DropdownTitle>
            {title}
          </S.DropdownTitle>
          <S.ChevronDown />
        </S.DropdownTitleWrapper>
        <S.DropdownContent isOpen={isOpen}>
          {children}
        </S.DropdownContent>
      </S.DropdownContainer>
    </S.DropdownWrapper>
  );
}

Dropdown.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};

export default Dropdown;
