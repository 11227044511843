import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import sortBy from 'lodash/sortBy';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { navigateToNotFoundPage, showGeneralToastError } from 'utils/errors';
import { AlertBlock } from 'components';
import { AccountsContext } from 'contexts/accounts';
import { formatAmount } from 'utils/amount';
import { updateAccount } from 'api/accounts';
import RenameAccountModal from 'pages/accounts/components/rename-account-modal';
import { NOT_FOUND } from 'constants/status-code';
import { resourceType } from 'constants/resource';

import S from './styles';
import HideAccountModal from '../hide-account-modal';
import AccountItem from '../account-item';
import InfoText from './info-text';

function AccountsList({ isGrid, showHiddenAccounts }) {
  const history = useHistory();
  const { accounts, fetchAccounts } = useContext(AccountsContext);
  const [ hasAccountWithNegativeBalance, setHasAccountWithNegativeBalance ] = useState(false);
  const [ amount, setAmount ] = useState('');
  const [ account, setAccount ] = useState('');
  const [ isHidingModalOpen, setIsHidingModalOpen ] = useState(false);
  const [ isRenamingModalOpen, setIsRenamingModalOpen ] = useState(false);
  const [ selectedAccountDetails, setSelectedAccountDetails ] = useState({  });

  const { t } = useTranslation();

  useEffect(() => {
    const negativeAccounts = accounts.filter(({ balance }) => balance < 0);

    if (negativeAccounts.length) {
      const { balance, currency, displayName } = negativeAccounts[0];
      const [ amountFormatted, decimalFormatted ] = formatAmount(balance).split('.');

      setHasAccountWithNegativeBalance(true);
      setAmount(`${currency} ${-amountFormatted}.${decimalFormatted}`);
      setAccount(displayName);
    }
  }, [accounts]);

  const toggleIsAccountHidden = async ({ id, isHidden, currency }) => {
    const toggledHiddenValue = !isHidden;

    try {
      await updateAccount(id, { isHidden: toggledHiddenValue });
    } catch (error) {
      error.status === NOT_FOUND
        ? navigateToNotFoundPage(history, resourceType.account)
        : showGeneralToastError(error);

      return;
    }

    await fetchAccounts();

    if (toggledHiddenValue) {
      toast.info(t('accounts.accountHidden', { currency }));
    } else {
      toast.info(t('accounts.accountUnhidden', { currency }));
    }
  };

  return (
    <S.Accounts $isGrid={isGrid}>
      {
        hasAccountWithNegativeBalance && (
          <AlertBlock
            icon={S.InfoIcon}
            text={<InfoText account={account} amount={amount} />}
          />
      )}
      {
        sortBy(accounts, ['isHidden'])
        .filter(({ isHidden }) => showHiddenAccounts ? true : !isHidden)
        .map(({
          id,
          displayName,
          balance,
          currency,
          isHidden,
          labels,
          supportedRails,
          requisites: { number },
        }, index) => {
          const [ amountFormatted, decimalFormatted ] = formatAmount(balance).split('.');
          const hasNegativeBalance = balance < 0;

          // Basing on the backend data - first account on list is the main one
          const isMainAccount = index === 0;

          return (
            <AccountItem
              key={`account-${id}`}
              isGrid={isGrid}
              setSelectedAccountDetails={setSelectedAccountDetails}
              number={number}
              currency={currency}
              supportedRails={supportedRails}
              displayName={displayName}
              id={id}
              labels={labels}
              setIsHidingModalOpen={setIsHidingModalOpen}
              setIsRenamingModalOpen={setIsRenamingModalOpen}
              amountFormatted={amountFormatted}
              decimalFormatted={decimalFormatted}
              hasNegativeBalance={hasNegativeBalance}
              isHidden={isHidden}
              toggleIsAccountHidden={toggleIsAccountHidden}
              balance={balance}
              isMainAccount={isMainAccount}
            />
          );
        })
      }
      {isHidingModalOpen && (
        <HideAccountModal
          isOpen={isHidingModalOpen}
          setIsOpen={setIsHidingModalOpen}
          accountDetails={selectedAccountDetails}
          hideAccount={toggleIsAccountHidden}
        />
      )}
      {isRenamingModalOpen && (
        <RenameAccountModal
          isOpen={isRenamingModalOpen}
          setIsOpen={setIsRenamingModalOpen}
          accountDetails={selectedAccountDetails}
          updateAccount={updateAccount}
        />
      )}
    </S.Accounts>
  );
}

AccountsList.propTypes = {
  isGrid: PropTypes.bool.isRequired,
  showHiddenAccounts: PropTypes.bool.isRequired,
};

export default AccountsList;
