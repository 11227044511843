import { useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';

import { navigateToNotFoundPage, showGeneralToastError } from 'utils/errors';
import { validateBeneficiary } from 'api/beneficiaries';
import BeneficiaryDetails from 'pages/send-funds/components/beneficiary-details';
import RequiredFields from 'pages/send-funds/components/beneficiary-required-fields';
import {
  BENEFICIARY_CURRENCY,
  BENEFICIARY_DETAILS_RESPONSE,
  BENEFICIARY_FORM,
  BENEFICIARY_IBAN,
  SEND_FUNDS_AMOUNT_FORM,
} from 'pages/send-funds/constants';
import createBeneficiaryValidationSchema from 'pages/send-funds/schemas/beneficiary';
import { handleApiValidationErrors } from 'pages/send-funds/utils';
import { prepareBeneficiaryFromBeneficiaryFormValues } from 'pages/send-funds/utils/fx';
import { getBeneficiary } from 'api/beneficiary';
import { navigation } from 'constants/navigation';
import { NOT_FOUND } from 'constants/status-code';
import { resourceType } from 'constants/resource';

import S from './styles';
import { dataTestIds } from './constants';

function BeneficiaryForm({
  setCurrentView,
  updateAccounts,
  accounts,
  beneficiaries,
  beneficiaryFormValues,
  setBeneficiaryFormValues,
  selectedBeneficiary,
  setSelectedBeneficiary,
  resetAmountForm,
}) {
  const location = useLocation();
  const history = useHistory();
  const [ apiValidationErrors, setApiValidationErrors ] = useState({});
  const [ currencySelected, setCurrencySelected ] = useState(false);
  const [ sendingToOwnIban, setSendingToOwnIban ] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    updateAccounts();
    resetAmountForm();
  }, []);

  const onSubmit = async values => {
    setApiValidationErrors({});

    const beneficiaryDetails = prepareBeneficiaryFromBeneficiaryFormValues(values);
    const { error } = await validateBeneficiary(beneficiaryDetails);
    if (error) {
      handleApiValidationErrors(error, setApiValidationErrors, values);

      return;
    }
    await setBeneficiaryFormValues(values);
    await setCurrentView(SEND_FUNDS_AMOUNT_FORM);
  };

  const onMoveClick = () => {
    const accountId = accounts.find(account => account.requisites.number === values[BENEFICIARY_IBAN]?.label
      && account.currency === values[BENEFICIARY_CURRENCY]?.value)?.id;
    history.push(accountId ? `${navigation.moveFunds}?to=${accountId}` : `${navigation.moveFunds}`);
  };

  const formik = useFormik({
    initialValues: {
      ...beneficiaryFormValues,
    },
    onSubmit,
    enableReinitialize: true,
    validationSchema: createBeneficiaryValidationSchema,
  });

  const {
    submitting,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    values,
    errors,
    touched,
    setErrors,
    isSubmitting,
  } = formik;

  useEffect(() => {
    setCurrencySelected(!isEmpty(values?.[BENEFICIARY_CURRENCY]));
  }, [values[BENEFICIARY_CURRENCY]]);

  useEffect(() => {
    const beneficiaryId = new URLSearchParams(location.search).get('beneficiaryId');
    const getAndSetBeneficiary = async () => {
      try {
        const data = await getBeneficiary(beneficiaryId);

        setSelectedBeneficiary(data);
      } catch (error) {
        error.status === NOT_FOUND
          ? navigateToNotFoundPage(history, resourceType.beneficiary)
          : showGeneralToastError(error);
      }
    };

    if (beneficiaryId) {
      getAndSetBeneficiary();
    }
  }, [location.search]);

  useEffect(() => {
    if (!isEmpty(apiValidationErrors)) {
      setErrors(apiValidationErrors);
    }
  }, [apiValidationErrors]);

  useEffect(() => {
    // we need this to set all fields in formik's 'values' object in 'touched' object
    // it's supposed to be done automatically, but for some reason 'beneficiaryCountry' is never been set when
    // 'beneficiaryCountry' and 'bankAccountCountry' are the same
    if (!isSubmitting) return;
    const formikValuesKeysArr = Object.keys(values);

    formikValuesKeysArr.forEach(key => {
      if (touched[key]) return;

      if (!isEmpty(values[key])) {
        setFieldTouched(key);
      }
    });
  }, [isSubmitting]);

  return (
    <form name={BENEFICIARY_FORM} data-testid={dataTestIds.wrapper} onSubmit={handleSubmit}>
      <RequiredFields
        values={values}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
        errors={errors}
        touched={touched}
        accounts={accounts}
        setCurrencySelected={setCurrencySelected}
        selectedBeneficiary={selectedBeneficiary}
        setSelectedBeneficiary={setSelectedBeneficiary}
      />
      {!isEmpty(values[BENEFICIARY_DETAILS_RESPONSE]) && (
        <BeneficiaryDetails
          values={values}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          errors={errors}
          touched={touched}
          accounts={accounts}
          beneficiaries={beneficiaries}
          setSendingToOwnIban={setSendingToOwnIban}
        />
      )}
      <S.Button
        label={t('sendFunds.beneficiaryForm.continueBtn')}
        category="primary"
        type="submit"
        data-testid={dataTestIds.submitBtn}
        disabled={submitting || sendingToOwnIban}
        currencySelected={currencySelected}
      />
      {
        sendingToOwnIban && (
          <S.MoveButton
            label={t('sendFunds.beneficiaryForm.moveBtn')}
            category="secondary"
            onClick={onMoveClick}
          />
        )
      }
      <S.CancelButton currencySelected={currencySelected} />
    </form>
  );
}

BeneficiaryForm.propTypes = {
  setCurrentView: PropTypes.func.isRequired,
  updateAccounts: PropTypes.func.isRequired,
  accounts: PropTypes.instanceOf(Array).isRequired,
  beneficiaries: PropTypes.instanceOf(Array).isRequired,
  beneficiaryFormValues: PropTypes.instanceOf(Object).isRequired,
  selectedBeneficiary: PropTypes.instanceOf(Object).isRequired,
  setSelectedBeneficiary: PropTypes.func.isRequired,
  setBeneficiaryFormValues: PropTypes.func.isRequired,
  resetAmountForm: PropTypes.func.isRequired,
};

export default BeneficiaryForm;
