import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { InactivityTimeout } from 'components';
import { ERRORS } from 'constants/errors';
import { userStatusesMap } from 'constants/user';
import ApiKeysProvider from 'contexts/api-keys';
import NavigationProvider from 'contexts/navigation';
import PaymentCountProvider from 'contexts/payment-count';
import { UserContext } from 'contexts/user';
import { WithNavbarLayout } from 'layouts';
import { signOut } from 'utils/auth';
import { arrayNonEmptyPropType } from 'utils/helpers';
import { redirectToLogin, redirectToOnboarding } from 'utils/location';
import { getDefaultPathByPermissions, permit } from 'utils/permissions';
import { isSessionValid } from 'utils/token';

function AuthorizedRoute({ children, backButton, hideNavigation, permissions, ...rest }) {
  const history = useHistory();
  const [ canLoad, setCanLoad ] = useState(false);
  const { user, tokenPayload } = useContext(UserContext);

  useEffect(() => {
    if (!isSessionValid()) {
      signOut()
        .finally(redirectToLogin);

      return;
    }

    if (!user) return;

    // Check user status to access the app.
    if (user.status !== userStatusesMap.activated) {
      redirectToOnboarding();

      return;
    }

    setCanLoad(true);

    // Check user permissions to access the page. If the route has permissions attached.
    if (permissions) {
      const hasPermission = permit(permissions.list, tokenPayload.permissions, permissions.atLeastOne);

      if (!hasPermission) {
        toast.error(ERRORS.invalidPermissions);

        // Navigate user to default permitted page.
        history.replace({ pathname: getDefaultPathByPermissions() });

      }
    }
  }, [ history.location.pathname, user ]);

  if (!canLoad) return null;

  return (
    <>
      <InactivityTimeout/>
      <NavigationProvider>
        <Route
          {...rest}
          render={() =>
            hideNavigation ? (
              children
            ) : (
              <PaymentCountProvider>
                <ApiKeysProvider>
                  <WithNavbarLayout backButton={backButton}>
                    {children}
                  </WithNavbarLayout>
                </ApiKeysProvider>
              </PaymentCountProvider>
            )
          }
        />
      </NavigationProvider>
    </>
  );
}

AuthorizedRoute.propTypes = {
  children: PropTypes.node.isRequired,
  hideNavigation: PropTypes.bool,
  permissions: PropTypes.shape({
    list: arrayNonEmptyPropType,
    atLeastOne: PropTypes.bool.isRequired,
  }),
  backButton: PropTypes.shape({
    link: PropTypes.string.isRequired,
    label: PropTypes.string,
  }),
};

export default AuthorizedRoute;
