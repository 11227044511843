import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { prettifyAmount } from 'utils/amount';

import S from './styles';

const warningProgress = 75;

function CardLimitItem({ name, currency, max, remaining }) {
  const [ remainingAmount, setRemainingAmount ] = useState(null);
  const [ maxAmount, setMaxAmount ] = useState(null);
  const [ spentAmount, setSpentAmount ] = useState(null);
  const [ progress, setProgress ] = useState(0);

  useEffect(() => {
    const progressValue = Math.round(((max - remaining) * 100) / max);
    setProgress(Number.isNaN(progressValue) ? 0 : progressValue);
    setRemainingAmount(prettifyAmount(currency, remaining));
    setMaxAmount(prettifyAmount(currency, max));
    setSpentAmount(prettifyAmount(currency, max - remaining));
  }, [ max, remaining ]);

  return (
    <S.LimitItem>
      <S.DetailsContainer>
        <S.Subtitle>
          {name}
        </S.Subtitle>
        <S.DimmedText>
          <S.Label>Max limit:</S.Label>
          {' '}
          <S.TextValue>
            {maxAmount}
          </S.TextValue>
        </S.DimmedText>
      </S.DetailsContainer>
      <S.ProgressContainer>
        <S.Progress progress={progress} warningProgress={warningProgress} />
      </S.ProgressContainer>
      <S.DetailsContainer>
        <div>
          <S.Label>Spent:</S.Label>
          {' '}
          <S.TextValue>
            {spentAmount}
          </S.TextValue>
        </div>
        <div>
          <S.Label>Remaining:</S.Label>
          {' '}
          <S.TextValue>
            {remainingAmount}
          </S.TextValue>
        </div>
      </S.DetailsContainer>
    </S.LimitItem>
  );
}

CardLimitItem.propTypes = {
  currency: PropTypes.string,
  name: PropTypes.string,
  max: PropTypes.number,
  remaining: PropTypes.number,
};

export default CardLimitItem;
