import { Button as DefaultButton, Input as DataInput } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { ReactComponent as DefaultBulkPaymentsIcon } from 'assets/icons/bulk-payments.svg';
import { ReactComponent as PencilIcon } from 'assets/icons/pencil.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';

const ActionColumn = styled.div`
  padding-left: 1.25rem;
  width: 5.6875rem;
  align-self: center;
  height: 4.5rem;
  opacity: 0;
`;

const ActionColumnWrapper = styled.div`
  padding: 1.375rem 1.25rem 1.375rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;

const ActionEditIcon = styled(PencilIcon)`
  width: 0.8rem;
  height: 0.8rem;

  fill: ${props => props.theme.colors.blue};

  &&:hover {
    cursor: pointer;
  }
`;

const ActionDeleteIcon = styled(TrashIcon)`
  width: 0.8rem;
  height: 0.8rem;

  fill: ${props => props.theme.colors.red};

  &&:hover {
    cursor: pointer;
  }
`;

const TableRowContainer = styled.div`
  margin: 0;
  display: flex;
  flex-direction: column;
`;

const Separator = styled.hr`
  border-top: ${props => props.error ? `0.0625rem solid ${props.theme.colors.red}` : 'none'};
  margin: 0 5.6875rem 0 0;
  border-radius: 0.4375rem;
`;

const TableRow = styled.div`
  display: flex;
  justify-content: space-between;

  align-items: stretch;
  transition: 0s all;
  background: ${props => props.isChosen ? '#FCF6EF' : props.theme.colors.white};
  border-bottom: 0.0625rem solid ${props => props.theme.colors.white};

  &&:hover {
    background-color: #fafdfc;
    border-bottom: 0.0625rem solid #878787;
    transition-delay: 0.2s;

    > ${ActionColumn} {
      transition-delay: 0.2s;
      background: ${props => props.theme.colors.white};
      opacity: 1;
    }
  }
`;

const TableAmountReferenceInputWrapper = styled.div`
  flex: 1;
  margin-right: ${props => (props.offset ? '2.5rem' : 0)};
  padding: 1rem 0 1rem 0;
  position: relative;
  padding-bottom: ${props => (props.error ? '0.4375rem' : '1rem')};
`;

const ErrorMessage = styled.p`
  font: ${props => props.theme.fonts.bodyXs};
  color: ${props => props.theme.colors.red};
  margin: 0;
`;

const TableAmountReferenceInput = styled(DataInput)`
  position: relative;
  input {
    font: ${props => props.theme.fonts.bodyS};
    padding: 0.625rem 1rem 0.625rem 1rem;
    margin-left: ${props => (props.left ? 'auto' : 0)};
    margin-right: ${props => (props.left ? 0 : 'auto')};
    display: block;
  }
`;

const TableLabel = styled.div`
  flex: 4;
  padding: 0.9375rem 0 1rem 1.125rem;
`;

const TableTotal = styled.div`
  flex: 1;
  text-align: right;
  align-self: center;
  padding: 1.125rem 0 0.9375rem 0;
  color: ${props => props.theme.colors.black};

  padding-bottom: ${props => (props.error ? '2rem' : '0.9375rem')};
`;

const TableTotalNet = styled.p`
  margin: 0;
  text-align: right;
  align-self: center;
  color: ${props => props.theme.colors.black};
  font: ${props => props.theme.fonts.bodyS};
`;

const TableTotalFee = styled.p`
  margin: 0;
  text-align: right;
  align-self: center;
  padding-top: 0.25rem;
  color: ${props => props.theme.colors.greyMedium};
  font: ${props => props.theme.fonts.bodyXs};
`;

const LabelWrapper = styled.div`
  margin: 0;
  display: inline-block;
  cursor: pointer;
`;

const CurrencyLabel = styled.p`
  margin: 0;
  color: ${props => props.theme.colors.greyMedium};
  bottom: 0.125rem;
  position: absolute;
  font: ${props => props.theme.fonts.bodyS};
`;

const BeneficiaryName = styled.p`
  margin: 0;
  color: ${props => props.error ? props.theme.colors.red : props.theme.colors.black};
`;

const BeneficiaryMissingInfo = styled.p`
  margin: 0;
  display: inline;
  color: ${props => props.theme.colors.red};
`;

const BulkPaymentsIcon = styled(DefaultBulkPaymentsIcon)`
  width: ${props => props.theme.icons.large};
  height: ${props => props.theme.icons.large};
`;

const HelpIconWrapper = styled.span`
  display: inline-flex;
  align-self: start;
  margin-left: 0.3125rem;
  button {
    pointer-events: none;
  }
`;

const Icon = styled(DefaultButton)`
  color: ${props => props.theme.colors.black};
  align-items: center;
  margin: 0;
  padding: 0;

  svg {
    height: 0.875rem;
    width: 0.875rem;
  }
`;

const HintText = styled.div`
  font: ${props => props.theme.fonts.bodyXxs};
  line-height: 1rem;
`;

export default {
  ActionColumn,
  TableRow,
  TableAmountReferenceInputWrapper,
  TableAmountReferenceInput,
  TableLabel,
  TableTotal,
  TableTotalNet,
  TableTotalFee,
  ActionColumnWrapper,
  ActionEditIcon,
  ActionDeleteIcon,
  LabelWrapper,
  CurrencyLabel,
  ErrorMessage,
  BeneficiaryName,
  BulkPaymentsIcon,
  BeneficiaryMissingInfo,
  TableRowContainer,
  Separator,
  HelpIconWrapper,
  Icon,
  HintText,
};
