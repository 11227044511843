import PropTypes from 'prop-types';
import React from 'react';

import S from './styles';

function NavigationBackButton({ onClick, className }) {
  return (
    <S.Container data-testid="navigation-back-button" onClick={() => onClick()} className={className}>
      <S.ChevronIcon />
    </S.Container>
  );
}

NavigationBackButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
};
export default NavigationBackButton;
