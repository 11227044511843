import { ErrorCodes } from '@general/error-codes';
import { useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';

import { ModalContent, TwoFaModal } from 'components';
import {
  cardActivationCVC2FieldName,
  cardActivationSuccessMessage,
  changePinForm,
  confirmationPinFieldName,
  cvc2FieldName,
  pinFieldName,
  pinValidationErrors,
  cardScaActions,
  cardScaAuthenticationElements,
} from 'constants/card';
import { showGeneralToastError, errorsConsistOneOfErrorCodes, rethrowTwoFaError } from 'utils/errors';
import { INPUT_PATTERNS } from 'constants/inputs';
import { CardContext } from 'contexts/card';
import { GlobalLoaderContext } from 'contexts/global-loader';
import TwoFaProvider from 'contexts/two-fa';
import { getScaData, getConfirmScaData } from 'utils/card';
import { getCard, activateCard, createSca, confirmSca } from 'api/card';

import S from './styles';
import validationSchema from './schema';

function ActivateCardModal({ id, isOpen, setIsOpen }) {
  const { startLoading, endLoading } = useContext(GlobalLoaderContext);
  const { setCard } = useContext(CardContext);
  const [ pin, setPin ] = useState('');
  const [ confirmationPin, setConfirmationInput ] = useState('');
  const [ cvc2, setCvc2 ] = useState('');
  const [ cvc2ResponseError, setCvc2ResponseError ] = useState(false);
  const [ twoFaModalIsOpen, setTwoFaModalIsOpen ] = useState(false);
  const [ twoFaData, setTwoFaData ] = useState(null);

  const updateCardData = async () => {
    const data = await getCard(id);

    setCard(data);
  };

  const onSubmit = async () => {
    startLoading();

    try {
      const sca = await createSca({
        action: cardScaActions.activateCard,
        authenticationElements: [ cardScaAuthenticationElements.accessToken, cardScaAuthenticationElements.smsOtp ],
      });
      const challenge = getScaData(sca);

      setTwoFaData(challenge);
      setTwoFaModalIsOpen(true);
    } catch (error) {
      showGeneralToastError(error);
    } finally {
      endLoading();
    }
  };

  const handleConfirmCode = async challenge => {
    if (!isEmpty(errors)) return;

    const data = getConfirmScaData(challenge);

    setSubmitting(true);
    startLoading();

    try {
      const { authenticationCode } = await confirmSca(challenge.scaId, data);
      await activateCard(id, { pin, cvc2 }, authenticationCode);

      await updateCardData();
      toast.success(cardActivationSuccessMessage);
      setIsOpen(false);
    } catch (error) {
      rethrowTwoFaError(error);

      // Case when number of attempts are exceeded.
      if (error?.data?.default?.code === ErrorCodes.activationAttemptsExceeded) {
        await updateCardData();
        toast.error(error?.data?.default?.message);
        setIsOpen(false);
        setTwoFaModalIsOpen(false);

        return;
      }

      const cvc2Error = error?.data?.[cardActivationCVC2FieldName];
      // Cases when wrong cvc2 is provided but not yet exceed.
      if (cvc2Error) {
        setCvc2ResponseError(cvc2Error.message);
        setTwoFaModalIsOpen(false);

        return;
      }

      // Cases when wrong pin is invalid.
      if (errorsConsistOneOfErrorCodes(error.data, pinValidationErrors)) {
        setErrors(error.data);
        setTwoFaModalIsOpen(false);

        return;
      }

      showGeneralToastError(error);
    } finally {
      setSubmitting(false);
      endLoading();
    }
  };

  const handleResendCode = async () => {
    const sca = await createSca({
      action: cardScaActions.activateCard,
      authenticationElements: [ cardScaAuthenticationElements.accessToken, cardScaAuthenticationElements.smsOtp ],
    });
    const challenge = getScaData(sca);

    setTwoFaData(challenge);

    return { challenge };
  };

  const formik = useFormik({
    initialValues: { pin, confirmationPin, cvc2 },
    onSubmit,
    validationSchema,
  });

  const {
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    setSubmitting,
    setErrors,
    touched,
    errors,
    isSubmitting,
    isValid,
    dirty,
  } = formik;

  const handleChange = (value, fieldName, setFunction) => {
    setFunction(value);
    setFieldValue(fieldName, value);
  };

  const handleCvc2Change = value => {
    setCvc2(value);
    setFieldValue(cvc2FieldName, value);
  };

  const handleBlur = fieldName => {
    setFieldTouched(fieldName, true);
  };

  const disableActivateButton = isSubmitting || !isValid || !dirty;

  const CTAButtons = (
    <>
      <S.Button label="Activate" category="primary" type="submit" disabled={disableActivateButton} />
      <S.Button label="Cancel" extraMargin category="secondary" onClick={() => setIsOpen(false)} />
    </>
  );

  return (
    <>
      <ModalContent
        title="Card activation"
        hasCloseIcon
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        CTAButtons={CTAButtons}
        onSubmit={handleSubmit}
        formName={changePinForm}
      >
        <S.HelperText>Please provide a 3-digit CVC2 number from the back of your card</S.HelperText>
        <S.Input
          onBlur={() => handleBlur(cvc2FieldName)}
          onChange={e => handleCvc2Change(e.target.value)}
          id={cvc2FieldName}
          name={cvc2FieldName}
          isPassword
          label="CVC2"
          value={cvc2}
          disabled={isSubmitting}
          error={touched.cvc2 && (errors.cvc2 || cvc2ResponseError)}
          inputPattern={INPUT_PATTERNS.cvc2}
        />
        <S.HelperText>Please choose a 4-digit PIN</S.HelperText>
        <S.Input
          onBlur={() => handleBlur(pinFieldName)}
          onChange={e => handleChange(e.target.value, pinFieldName, setPin)}
          id="pin"
          name="pin"
          isPassword
          label="PIN"
          value={pin}
          disabled={isSubmitting}
          error={touched.pin && (errors.pin?.message || errors.pin)}
          inputPattern={INPUT_PATTERNS.pin}
        />
        <S.Input
          onBlur={() => handleBlur(confirmationPinFieldName)}
          onChange={e => handleChange(e.target.value, confirmationPinFieldName, setConfirmationInput)}
          id="confirmationPin"
          name="confirmationPin"
          isPassword
          label="Confirm PIN"
          value={confirmationPin}
          disabled={isSubmitting}
          error={touched.confirmationPin && errors.confirmationPin}
          inputPattern={INPUT_PATTERNS.pin}
        />
      </ModalContent>
      {twoFaModalIsOpen && (
        <TwoFaProvider twoFaData={twoFaData}>
          <TwoFaModal
            isOpen={twoFaModalIsOpen}
            setIsOpen={setTwoFaModalIsOpen}
            onSubmit={handleConfirmCode}
            onResend={handleResendCode}
            challenge={twoFaData}
          />
        </TwoFaProvider>
      )}
    </>
  );
}

ActivateCardModal.propTypes = {
  id: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default ActivateCardModal;
