export const viewMap = {
  creationForm: 'api-creation-form',
  result: 'api-creation-result',
};

export const layoutConfig = {
  [viewMap.creationForm]: {
    displayCancelButton: true,
    hideBackButton: false,
  },
  [viewMap.result]: {
    displayCancelButton: false,
    hideBackButton: true,
  },
};

// for testing
export const dataTestIds = {
  apiCreationForm: 'api-creation-form',
  apiCreationResult: 'api-creation-result',
  apiCreationFormInput: 'api-creation-form-input',
  apiCreationFormButton: 'api-creation-form-button',
  apiCreationResultButton: 'api-creation-result-button',
};
