import { Modal } from '@general/intergiro-ui-kit';
import PropTypes from 'prop-types';
import React from 'react';

import S from './styles';

function AlertModal({ isOpen, setIsOpen, discardForm }) {
  return (
    <Modal isOpen={isOpen}>
      <S.Content>
        <S.CloseIcon onClick={() => setIsOpen(false)} />
        <S.Title>Are you sure you want to close the form?</S.Title>
        <S.SubTitle>
          If you close the form before confirming it you&apos;ll lose all
          specified data.
        </S.SubTitle>
        <S.ButtonsContainer>
          <S.Button
            label="Close the form"
            category="primary"
            onClick={() => discardForm(false)}
          />
          <S.Button
            label="Cancel"
            category="secondary"
            onClick={() => setIsOpen(false)}
          />
        </S.ButtonsContainer>
      </S.Content>
    </Modal>
  );
}

AlertModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  discardForm: PropTypes.func.isRequired,
};

export default AlertModal;
