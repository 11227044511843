import { Button as DefaultButton, Tabs } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';
import { ReactComponent as Filters } from 'assets/icons/filters.svg';

const TabsWrapper = styled.div`
  display: none;

  @media ${DEVICE.tablet} {
    display: block;
  }
`;

const TabsHead = styled(Tabs.Head)`
  margin: 0 0 0 1.1rem;
  padding: 0 0 0.8125rem 0;

  @media ${DEVICE.tablet} {
    margin: 0;
  }
`;

const SwitchesWrapper = styled.div`
  display: none;
  position: absolute;
  right: 0;
  top: 0;

  @media ${DEVICE.tablet} {
    display: flex;
  }
`;

const MobileActionsWrapper = styled.div`
  margin-left: 1.5rem;

  @media ${DEVICE.tablet} {
    display: none;
  }
`;

const FiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.blue};
  font: ${props => props.theme.fonts.bodyMSemi};
`;

const FiltersIcon = styled(Filters)`
  fill: ${props => props.theme.colors.blue};
  margin-right: 0.4375rem;
`;

// MODAL shared styles
const ModalContent = styled.div`
  font: ${props => props.theme.fonts.text};
  font-weight: 500;
  white-space: pre-line;
  color: ${props => props.theme.colors.greyMedium};
`;

const ModalButton = styled(DefaultButton)`
  margin-right: 0;
  min-width: fit-content;
  padding: 0.75rem 1.5rem;
  margin-top: 1rem;
  width: 100%;
  
  @media ${DEVICE.tablet} {
    margin-top: 0;
    margin-right: 0.625rem;
    width: auto;
  }
`;

export default {
  TabsWrapper,
  TabsHead,
  SwitchesWrapper,
  MobileActionsWrapper,
  FiltersIcon,
  FiltersWrapper,
  ModalContent,
  ModalButton,
};
