import { Button as DefaultButton, Title as DefaultTitle } from '@general/intergiro-ui-kit';
import { Link as DefaultLink } from 'react-router-dom';
import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';
import { ReactComponent as Filters } from 'assets/icons/filters.svg';

const Title = styled(DefaultTitle)`
  font: ${props => props.theme.fonts.h3Bold};
  margin-bottom: 1.625rem;

  @media ${DEVICE.tablet} {
    font: ${props => props.theme.fonts.h1Bold};
    margin: 0;
  }
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  padding: 0 1.25rem 1.4375rem 1.25rem;
  flex-direction: column;

  @media ${DEVICE.tablet} {
    padding: 1.0625rem 0 1.4375rem;
    flex-direction: row;
  }
`;

const TabHelperText = styled.span`
  font: ${props => props.theme.fonts.bodyXsSemi};
  color: ${props => props.theme.colors.greyMediumDark};
  padding-left: 0.3125rem;
`;

const TabWrapper = styled.div`
  [role='presentation']:last-of-type {
    margin: auto 0 auto auto;
  }
`;

const SortBarWrapper = styled.div`
  align-items: center;
  display: none;
  grid-template-columns: repeat(2, max-content);

  @media ${DEVICE.laptop} {
    display: grid;
  }
`;

const Button = styled(DefaultButton)`
  padding: 0.4rem 0;
  width: 10.375rem;

  @media ${DEVICE.laptop} {
    margin: 0;
	}
`;

const Content = styled.div`
  padding: 0 1.25rem;

  @media ${DEVICE.tablet} {
    padding: 0;
	}
`;

const Link = styled(DefaultLink)`
  color: ${props => props.theme.colors.blue};
`;

const StatusDropdown = styled.div`
  margin-right: 1rem;
`;

const MobileActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 1.5rem 0.25rem;
  width: calc(100% - 2.5rem);
  justify-content: space-between;

  @media ${DEVICE.laptop} {
    display: none;
  }
`;

const FiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.blue};
  font: ${props => props.theme.fonts.bodyMSemi};
`;

const FiltersIcon = styled(Filters)`
  fill: ${props => props.theme.colors.blue};
  margin-right: 0.4375rem;
`;

export default {
  Title,
  Header,
  TabWrapper,
  TabHelperText,
  SortBarWrapper,
  StatusDropdown,
  MobileActionsWrapper,
  FiltersWrapper,
  FiltersIcon,
  Button,
  Content,
  Link,
};
