export const CURRENCY_FLAGS = {
  AED: 'ae',
  AUD: 'au',
  CAD: 'ca',
  CHF: 'ch',
  CNY: 'cn',
  CZK: 'cz',
  DKK: 'dk',
  EUR: 'european_union',
  GBP: 'gb',
  HKD: 'hk',
  HRK: 'hr',
  HUF: 'hu',
  ILS: 'il',
  JPY: 'jp',
  MXN: 'mx',
  NOK: 'no',
  NZD: 'nz',
  PLN: 'pl',
  RON: 'ro',
  RUB: 'ru',
  SAR: 'sa',
  SEK: 'se',
  SGD: 'sg',
  TRY: 'tr',
  USD: 'us',
  ZAR: 'za',
};

export const CURRENCIES = {
  AED: 'AED',
  AUD: 'AUD',
  CAD: 'CAD',
  CHF: 'CHF',
  CNY: 'CNY',
  CZK: 'CZK',
  DKK: 'DKK',
  EUR: 'EUR',
  GBP: 'GBP',
  HKD: 'HKD',
  HRK: 'HRK',
  HUF: 'HUF',
  ILS: 'ILS',
  JPY: 'JPY',
  MXN: 'MXN',
  NOK: 'NOK',
  NZD: 'NZD',
  PLN: 'PLN',
  RON: 'RON',
  RUB: 'RUB',
  SAR: 'SAR',
  SEK: 'SEK',
  SGD: 'SGD',
  TRY: 'TRY',
  USD: 'USD',
  ZAR: 'ZAR',
};

export const CURRENCY_NAMES = {
  AED: 'UAE Dirham',
  AUD: 'Australian Dollar',
  CAD: 'Canadian Dollar',
  CHF: 'Swiss Franc',
  CNY: 'Chinese Yuan Renminbi',
  CZK: 'Czech Koruna',
  DKK: 'Danish Krone',
  EUR: 'Euro',
  GBP: 'British Pound',
  HKD: 'Hong Kong Dollar',
  HRK: 'Croatian Kuna',
  HUF: 'Hungarian Forint',
  ILS: 'Israeli Shekel',
  JPY: 'Japanese Yen',
  MXN: 'Mexican Peso',
  NOK: 'Norwegian Krone',
  NZD: 'New Zealand Dollar',
  PLN: 'Polish Zloty',
  RON: 'Romanian Leu',
  RUB: 'Russian Ruble',
  SAR: 'Saudi Riyal',
  SEK: 'Swedish Krona',
  SGD: 'Singapore Dollar',
  TRY: 'Turkish Lira',
  USD: 'US Dollar',
  ZAR: 'South African Rand',
};

export const coinsInUnit = {
  USD: 100,
  EUR: 100,
  NOK: 100,
  DKK: 100,
  GBP: 100,
  SEK: 100,
  AED: 100,
  AUD: 100,
  CAD: 100,
  CHF: 100,
  CNY: 100,
  CZK: 100,
  HKD: 100,
  HRK: 100,
  HUF: 100,
  ILS: 100,
  JPY: 100,
  MXN: 100,
  NZD: 100,
  PLN: 100,
  RON: 100,
  RUB: 100,
  SAR: 100,
  SGD: 100,
  TRY: 100,
  ZAR: 100,
};
