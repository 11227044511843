import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React from 'react';

import { shortDateFormat } from 'constants/date-time';
import { UserDetails as Details } from 'components';

import { companyDetailsFieldsList } from './list';
import S from './styles';

function CompanyDetails({ data }) {
  if (isEmpty(data)) return null;

  const details = data;

  if (details.createdAt) {
    details.createdAt = dayjs(details.createdAt).format(shortDateFormat);
  }

  return (
    <S.Wrapper>
      <Details data={details} fieldsList={companyDetailsFieldsList} />
    </S.Wrapper>
  );
}

CompanyDetails.propTypes = {
  data: PropTypes.instanceOf(Object),
};

export default CompanyDetails;
