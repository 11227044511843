export const paymentRails = {
  swift: 'SWIFT',
  sepa: 'SEPA',
  local: 'Local',
};

export const feeTypes = {
  currencyExchange: 'currency-exchange',
  transferOutgoing: 'transfer-outgoing',
};

export const feeReasonsMap = {
  free: 'free',
  velocityOverflow: 'velocity-overflow',
  volumeOverflow: 'volume-overflow',
  noThresholdsSet: 'no-thresholds-set',
  notSepa: 'not-sepa',
};

export const sepaThresholdsReachedText = `
  As you’ve reached your monthly allowance for free SEPA
  transfers, this transfer will incur a fee. To upgrade
  your plan and get more free SEPA transfers, please
  contact Support.
`;
