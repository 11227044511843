import PropTypes from 'prop-types';
import React from 'react';

import { NOTIFICATION_TEXTS } from 'pages/send-funds/constants';

import S from './styles';

function NotificationBlock({ type }) {
  return (
    <S.Container
      icon={S.Icon}
      text={NOTIFICATION_TEXTS[type]}
    />
  );
}

NotificationBlock.propTypes = {
  type: PropTypes.string.isRequired,
};

export default NotificationBlock;
