import { Title as TitleComponent } from '@general/intergiro-ui-kit';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { ReactComponent as LogoIcon } from 'assets/icons/logo.svg';
import { ReactComponent as EmojiLogoIcon } from 'assets/icons/logo-emoji.svg';
import { ReactComponent as LogoLayerIcon } from 'assets/icons/logo-layer.svg';
import { DEVICE } from 'constants/breakpoints';

const Container = styled.div`
    padding: 0 1.25rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    @media ${DEVICE.tablet} {
        padding: 0;
    }
`;

const Title = styled(TitleComponent)`
    margin-bottom: 4.125rem;
    font: ${props => props.theme.fonts.h3Bold};

    @media ${DEVICE.tablet} {
        margin-bottom: 9.75rem;
        font: ${props => props.theme.fonts.h1Bold};
    }
`;

const ContentWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const LogoWrapper = styled.div`
    position: relative;
    width: 6.75rem;
    height: 6.75rem;
    margin-bottom: 3.75rem;
`;

const LogoLayer = styled(LogoLayerIcon)`
    position: absolute;
    width: 6.75rem;
    height: 6.75rem;
    top: 0;
    left: 0;
`;

const Logo = styled(LogoIcon)`
    position: absolute;
    width: 6.75rem;
    height: 6.75rem;
    top: 0;
    left: 0;
    transition: ${props => props.theme.transition};
    opacity: ${props => props.isActive ? '1' : '0'};
`;

const EmojiLogo = styled(EmojiLogoIcon)`
    position: absolute;
    width: 6.75rem;
    height: 6.75rem;
    top: 0;
    left: 0;
    transition: ${props => props.theme.transition};
    opacity: ${props => props.isActive ? '1' : '0'};
`;

const Paragraph = styled.p`
    font: ${props => props.theme.fonts.bodyM};
    margin: 0 0 1rem;
    text-align: center;

    @media ${DEVICE.tablet} {
        font: ${props => props.theme.fonts.bodyL};
    }
`;

const AccountsLink = styled(Link)`
    font: ${props => props.theme.fonts.bodyM};
`;

export default {
  Container,
  Title,
  ContentWrapper,
  LogoWrapper,
  LogoLayer,
  Logo,
  EmojiLogo,
  Paragraph,
  AccountsLink,
};
