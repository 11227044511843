import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Calendar } from '@general/intergiro-ui-kit';

import { dateRangeResetValues } from 'constants/date-time';
import { DownloadStatementsButton, DownloadStatementsModal, FilterBar, FilterBarMobile } from 'components';
import DateRangeFilter from 'components/filter-bar/components/daterange';
import FilterDropdown from 'components/filter-bar-mobile/components/filter-dropdown';
import { AccountsContext } from 'contexts/accounts';
import { UserContext } from 'contexts/user';
import { useFilterDates } from 'hooks';
import { getActiveAccounts, isDateSelected, transformDateToURL } from 'utils/helpers';

function AccountFilterBar({ account }) {
  const { permissions: { canDownloadStatements } } = useContext(UserContext);
  const { accounts } = useContext(AccountsContext);
  const [ isOpen, setIsOpen ] = useState(false);
  const [ dateRange, setDateRange ] = useState(null);
  const { date, setDate } = useFilterDates();
  const [ dateDraft, setDateDraft ] = useState(date);
  const [ selectedCount, setSelectedCount ] = useState(isDateSelected(date));
  const [ downloadStatementsModalIsOpen, setDownloadStatementsModalIsOpen ] = useState(false);
  const history = useHistory();
  const accountsOptionsForDownloadStatements = getActiveAccounts(accounts, [account.id]);

  useEffect(() => {
    setDateRange(date);
  }, [date]);

  const onDateRangeChange = useCallback(newDate => {
    newDate ? setDateDraft(newDate) : setDateDraft(dateRangeResetValues);
  }, []);

  const openFilterBar = useCallback(() => {
    setIsOpen(true);
  }, []);

  const closeFilterBar = useCallback(() => {
    setIsOpen(false);
  }, []);

  const cancelFilters = useCallback(() => {
    const dateURL = transformDateToURL(dateRangeResetValues, history, history.location.pathname);
    setDate(dateRangeResetValues);
    setDateDraft(dateRangeResetValues);
    history.push(dateURL);
    setSelectedCount(isDateSelected(false));
    closeFilterBar();
  }, [ dateDraft, history.location ]);

  const applyFilters = useCallback(() => {
    const dateURL = transformDateToURL(dateDraft, history, history.location.pathname);
    setDate(dateDraft);
    history.push(dateURL);
    setSelectedCount(isDateSelected(dateDraft));
    closeFilterBar();
  }, [ dateDraft, history.location ]);

  return (
    <div>
      <FilterBarMobile
        cancelFilters={cancelFilters}
        closeFilterBar={closeFilterBar}
        isOpen={isOpen}
        openFilterBar={openFilterBar}
        selectedCount={selectedCount}
        applyFilters={applyFilters}
        updateFilters={applyFilters}
        setDownloadStatementsModalIsOpen={setDownloadStatementsModalIsOpen}
        showDownloadStatements={canDownloadStatements}
      >
        <FilterDropdown title="Date">
          <Calendar value={dateDraft} onChange={onDateRangeChange} hideActionButtons />
        </FilterDropdown>
      </FilterBarMobile>
      <FilterBar filterNumber={2}>
        <DateRangeFilter />
        {canDownloadStatements && (
          <DownloadStatementsButton setIsOpen={setDownloadStatementsModalIsOpen} />
        )}
      </FilterBar>

      {/* Modals */}
      {downloadStatementsModalIsOpen && (
        <DownloadStatementsModal
          isOpen={downloadStatementsModalIsOpen}
          setIsOpen={setDownloadStatementsModalIsOpen}
          isAccountDisabled
          accountList={accountsOptionsForDownloadStatements}
          defaultDateRange={dateRange}
        />
)}
    </div>
  );
}

AccountFilterBar.propTypes = {
  account: PropTypes.instanceOf(Object),
};

export default AccountFilterBar;
