import {
  Button as DefaultButton,
  DatePicker as DefaultDatePicker,
  Dropdown as Select,
  Input as DataInput,
} from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { ReactComponent as Delete } from 'assets/icons/delete.svg';
import { Hint } from 'components';
import { DEVICE } from 'constants/breakpoints';
import { ReactComponent as CrossIcon } from 'assets/icons/cross-2.svg';
import { hex2rgba } from 'helpers/styles';

const Container = styled.div`
  display: flex;
  margin-bottom: 2.5rem;
  flex-direction: column;
  order: 1;

  @media ${DEVICE.laptop} {
    flex-direction: column;
    margin: 0;
    order: 0;
  }
`;

const Form = styled.form`
  margin-top: 2.5rem;
`;

const InputsContainer = styled.div`
  margin: 0;
  width: 100%;
  order: 3;
`;

const CardContainer = styled.div`
  position: relative;
  width: 20rem;
  margin: 0 auto 1.25rem;
`;

const Input = styled(DataInput)`
  margin-top: 1.25rem;
`;

const DatePicker = styled(DefaultDatePicker)`
  width: 100%;
  padding: 0;

  .react-date-picker {
    display: inherit;
  }

  @media ${DEVICE.laptop} {
    width: 13.75rem;
  }
`;

const DatePickerInput = styled(DataInput)`
  width: 100%;
  button {
    display: none;
  }
`;

const ProfileSelection = styled(Select)`
  width: 100%;
`;

const MultipleInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.25rem;
  align-items: flex-start;
  
  @media ${DEVICE.tablet} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1.25rem;
  }
`;

const ExpirationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const ReadOnlyContainer = styled.div`
  background: ${props => props.theme.colors.greyBright};
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  margin: 1.25rem 0 0 0;
  border: 0.13rem solid ${props => props.error ? props.theme.colors.red : 'transparent'};
`;

const DeliveryAddressErrorInfo = styled.div`
  position: absolute;
  padding-left: 1rem;
  margin-top: 0.125rem;
  color: ${props => props.theme.colors.red};
  font: ${props => props.theme.fonts.bodyXs};
`;

const ProfileSelectionContainer = styled.div`
  width: 100%;
  margin-top: 1.25rem;

  @media ${DEVICE.tablet} {
    margin-top: 0;
  }
`;

const ReadOnlyContent = styled.div`
  font: ${props => props.theme.fonts.bodyL};
  margin-top: 0.3125rem;
  margin-bottom: 0.3125rem;
  word-wrap: break-word;
`;

const ReadOnlyLabel = styled.p`
  font: ${props => props.theme.fonts.bodyXs};
  margin: 0;
  color: ${props => props.theme.colors.greyMedium};
`;

const ReadOnlyCTA = styled.button`
  border: none;
  background: none;
  color: ${props => props.theme.colors.blue};
  font: ${props => props.theme.fonts.bodyS};
  padding: 0;
  cursor: pointer;
`;

const DeleteContainer = styled.div`
  cursor: pointer;
  width: fit-content;
  height: fit-content;
  background: none;
  display: flex;
  align-items: center;
  order: 0;
  border-radius: 0.25rem;
  margin: 0 auto 1.25rem;
`;

const DeleteIcon = styled(Delete)`
  width: 1rem;
  height: 1.0625rem;

  path {
    fill: ${props => props.theme.colors.red};
  }
`;

const DeleteLabel = styled.span`
  font: ${props => props.theme.fonts.bodyM};
  display: inline-block;
  color: ${props => props.theme.colors.red};
  padding-left: 0.5rem;
`;

const DeliveryAddressHint = styled(Hint)`
  margin-left: 0.5rem;
`;

const SmallInputContainer = styled.div`
  width: 100%;
  
  .select__control {
    height: 4.5rem;
  }
`;

const MonthDateSlash = styled.p`
  padding: 0 0.625rem;
  display: flex;
  align-items: center;
  font: ${props => props.theme.fonts.bodyL};
`;

const DateElement = styled.span`
  cursor: ${props => props.isDisabled ? 'not-allowed' : 'pointer'};
  color: ${props => props.isDisabled ? props.theme.colors.grey : props.theme.colors.black};
`;

const CloseIcon = styled(CrossIcon)`
  fill: ${props => props.theme.colors.greyDark};
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
`;

const ConfirmationContainer = styled.div`
  width: 100%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  margin-top: 1.875rem;
  border-top: 0.0625rem solid ${props => hex2rgba(props.theme.colors.black, 0.1)};
`;

const ConfirmationButton = styled(DefaultButton)`
  width: 100%;
`;

export default {
  ReadOnlyContainer,
  InputsContainer,
  Container,
  CardContainer,
  Input,
  Form,
  MultipleInputContainer,
  DatePicker,
  ProfileSelection,
  ReadOnlyContent,
  ReadOnlyLabel,
  ReadOnlyCTA,
  DeleteContainer,
  DeleteIcon,
  DatePickerInput,
  DeleteLabel,
  DeliveryAddressHint,
  DeliveryAddressErrorInfo,
  SmallInputContainer,
  MonthDateSlash,
  DateElement,
  ExpirationContainer,
  ProfileSelectionContainer,
  CloseIcon,
  ConfirmationContainer,
  ConfirmationButton,
};
