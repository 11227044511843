import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';

const FilterBarWrapper = styled.div`
  align-items: center;
  display: none;
  gap: 0.9375rem;
  grid-template-columns: ${props => `repeat(${props.filterNumber}, max-content)`};

  @media ${DEVICE.desktop} {
    display: grid;
    margin-top: -0.75rem;
  }
`;

export default { FilterBarWrapper };
