import PropTypes from 'prop-types';
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useSidebarInteractions } from 'hooks';
import { TransactionSidebar } from 'components';

import S from '../transaction/styles';

export const PaymentContext = createContext({});

function PaymentProvider({ children }) {
  const {
    isTopArrowDisabled,
    isUpArrowDisabled,
    isSidebarOpen,
    setIsSidebarOpen,
    setIsTopArrowDisabled,
    setIsUpArrowDisabled,
  } = useSidebarInteractions();
  const [ payments, setPayments ] = useState([]);
  const [ activeIndex, setActiveIndex ] = useState(null);
  const [ activePayment, setActivePayment ] = useState(null);

  useEffect(() => {
    if (payments.length === 0) {
      setIsSidebarOpen(false);
    }
  }, [payments]);

  const handleUpArrowClick = useCallback(() => {
    const nextStep = activeIndex === 0 ? payments.length - 1 : activeIndex - 1;
    setActiveIndex(nextStep);
  }, [ activeIndex, payments ]);

  const handleDownArrowClick = useCallback(() => {
    const nextStep = activeIndex === payments.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextStep);
  }, [ activeIndex, payments ]);

  function setActivePaymentById(paymentId) {
    if (!paymentId) {
      setActiveIndex(null);

      return;
    }

    const index = payments.findIndex(object => object.bulkPaymentId === paymentId);
    setActiveIndex(index);
  }

  const handleClose = useCallback(() => {
    setActiveIndex(null);
  }, []);

  useEffect(() => {
    if (activeIndex === null) {
      setActivePayment(null);
      setIsSidebarOpen(false);

      return;
    }

    setActivePayment(payments[activeIndex]);
    setIsSidebarOpen(true);
  }, [activeIndex]);

  const initialValue = useMemo(() => ({
    activePayment,
    isSidebarOpen,
    setActivePaymentById,
    setPayments,
    payments,
  }), [
    activePayment,
    isSidebarOpen,
    setActivePaymentById,
    setPayments,
    payments,
  ]);

  return (
    <PaymentContext.Provider value={initialValue}>
      {children}
      <S.ActivityWrapper>
        <S.Sidebar
          handleClose={handleClose}
          handleDownArrowClick={handleDownArrowClick}
          handleUpArrowClick={handleUpArrowClick}
          isCloseOutsideEnabled={false}
          isControlPanelVisible
          isOpen={isSidebarOpen}
          isTopArrowDisabled={isTopArrowDisabled}
          isUpArrowDisabled={isUpArrowDisabled}
          setIsTopArrowDisabled={setIsTopArrowDisabled}
          setIsUpArrowDisabled={setIsUpArrowDisabled}
        >
          {isSidebarOpen && (
            <TransactionSidebar
              {...activePayment}
              isApiPayment
              author="API"
            />
          )}
        </S.Sidebar>
      </S.ActivityWrapper>
    </PaymentContext.Provider>
  );
}

PaymentProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PaymentProvider;
