import styled from 'styled-components';

import { hex2rgba } from 'helpers/styles';

const PaymentHeading = styled.div`
  height: calc(3.75rem - 1px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${props => hex2rgba(props.theme.colors.black, 0.1)};
`;

const PaymentFrom = styled.div`
  font: ${props => props.theme.fonts.bodyM};
  color: ${props => props.theme.colors.greyMedium};
`;

const PaymentFromBold = styled.span`
  font-weight: 500;
  color: ${props => props.theme.colors.black};
`;

const PaymentFromLength = styled.span`
  font: ${props => props.theme.fonts.bodyXs};
`;

export default {
  PaymentHeading,
  PaymentFrom,
  PaymentFromBold,
  PaymentFromLength,
};
