import {
  Button as ButtonComponent,
  Input as DefaultInput,
  Paragraph as ParagraphComponent,
} from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';

const ResendContainer = styled.span`
	display: flex;
	flex-wrap: wrap;
	align-items: center;

	@media ${DEVICE.tablet} {
		margin-left: .5rem;
	}
`;

const Link = styled(ButtonComponent)`
	color: ${props => props.theme.colors.greyMedium};
	align-items: center;
	font: ${props => props.theme.fonts.bodyS};
    line-height: 1.5rem;

	svg {
		fill: ${props => props.theme.colors.greyMedium};
		margin-right: 0.25rem;
	}

	&:hover {
		> span {
			color: ${props => props.theme.colors.greyDark};
		}
	}

`;

const Divider = styled.span`
	margin: 0 0.4rem;
`;

const ButtonWrapper = styled.span`
  display: inline-flex;
  cursor: not-allowed;

  button {
    pointer-events: none;
  }
`;

const Subtitle = styled(ParagraphComponent)`
	color: ${props => props.theme.colors.black};
    font: ${props => props.theme.fonts.bodyMSemi};
	margin-bottom: 0.3125rem;
`;

const Text = styled(ParagraphComponent)`
	color: ${props => props.theme.colors.greyMedium};
	font: ${props => props.theme.fonts.bodyS};
	padding-bottom: ${props => props.isModal ? '1.25rem' : '2.5rem'};
`;

const Resend = styled(ParagraphComponent)`
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	align-items: center;
	padding-top: 0.3125rem;

	@media ${DEVICE.tablet} {
		flex-direction: row;
	}
`;

const Input = styled(DefaultInput)`
	max-width: 30rem;
`;

export default {
  ResendContainer,
  Link,
  Divider,
  ButtonWrapper,
  Subtitle,
  Resend,
  Text,
  Input,
};
