import { Button as DefaultButton, Sidebar as DefaultSidebar } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';
import { hex2rgba } from 'helpers/styles';

const Sidebar = styled(DefaultSidebar)`
  z-index: 1;
  overflow-y: auto;
`;

const Container = styled.div`
  padding-bottom: 1.875rem;
`;

const Button = styled(DefaultButton)`
  margin: 0;
  width: 100%;

  margin-top: ${props => props.extraMargin ? '1rem' : '0'};

  @media ${DEVICE.tablet} {
    margin-right: 0.5rem;
    min-width: auto;
    width: auto;
  }
`;

const Separator = styled.hr`
  border-top: ${props => hex2rgba(props.theme.colors.black, 0.1)};
  margin: 1.875rem 0;
`;

const TableWrapper = styled.div`
  margin-bottom: 2.5rem;

  @media ${DEVICE.tablet} {
    margin-bottom: 3.75rem;
  }
`;

export default {
  Sidebar,
  Button,
  Separator,
  Container,
  TableWrapper,
};
