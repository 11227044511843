import isEmpty from 'lodash/isEmpty';

import { cardOrderStatusMap } from 'constants/card';
import {
  ACCOUNT_SELECTION,
  ACCOUNT_TYPE,
  CARD_TYPE_SWITCH,
  cardProfileOptions,
  EXISTING_ACCOUNT,
} from 'pages/order-cards/constants';
import bff from 'utils/bff';
import { cleanCardOrderRequestBody, convertDateToApiFormat } from 'utils/card';

export async function getCardOrder(id) {
  const { data } = await bff.cardOrder.getCardOrder({}, { id });

  return data;
}

export async function canOrderCards(data) {
  try {
    const { data: { canOrder } } = await bff.cardOrder.canCreateCardOrder(data);

    return { canOrder };
  } catch (err) {
    return { error: err.data };
  }
}

export async function updateCardOrder(cardOrder) {

  try {
    const order = cleanCardOrderRequestBody(cardOrder);
    const { data } = await bff.cardOrder.submitCardOrder({ cardOrder: order }, { id: cardOrder.id });

    return { data };
  } catch (error) {
    return { error };
  }
}

async function getCardOrderById(id) {
  try {
    const { data } = await bff.cardOrder.getCardOrder({}, { id });

    return (data);
  } catch (error) {
    return { error };
  }
}

export async function pollCardOrderState(cardOrder) {
  let result = await getCardOrderById(cardOrder.id);
  while (result.status === cardOrderStatusMap.readyForProcessing) {
    // eslint-disable-next-line no-await-in-loop
    await wait(10000);
    // eslint-disable-next-line no-await-in-loop
    result = await getCardOrderById(cardOrder.id);

    if (result.status === cardOrderStatusMap.processed) {
      return result;
    }
  }

  return result;
}

function wait(ms = 10000) {
  return new Promise(resolve => {
    setTimeout(resolve, ms);
  });
}

export async function createCardOrder(orderDetailsData, cards) {
  try {
    const { data } = await bff.cardOrder.createCardOrder({
      cardOrder: {
        cardType: orderDetailsData[CARD_TYPE_SWITCH],
        lineItems: mapFormValuesToIssueRequest(orderDetailsData, cards),
      },
    });

    return { data };
  } catch (error) {
    return { error };
  }
}

export function updatePendingCardOrder(cardOrder, orderDetailsData, cards) {
  return isEmpty(cardOrder)
    ? createCardOrder(orderDetailsData, cards)
    : updateCardOrder(cardOrder);
}

function mapFormValuesToIssueRequest(orderDetailsData, allCards) {
  return allCards.map((card, index) => ({
    issueRequest: {
      card: {
        address: card.address,
        cardHolder: card.cardHolder.id,
        companyName: card.companyName,
        displayName: card.displayName === '' ? undefined : card.displayName,
        expiry: convertDateToApiFormat(card.expiry),
        profile: cardProfileOptions[0].value,
        isExistingUser: true,
      },
      existingAccount: orderDetailsData[ACCOUNT_TYPE] === EXISTING_ACCOUNT
        ? orderDetailsData[ACCOUNT_SELECTION].id
        : undefined,
      isExistingAccount: orderDetailsData[ACCOUNT_TYPE] === EXISTING_ACCOUNT,
      index,
    },
  }));
}
