import { Accordion as DefaultAccordion } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

const Section = styled.div`
  margin-bottom: 1.875rem;
`;

const Accordion = styled(DefaultAccordion)`
  > div {
    width: 100%;

    > div {
      overflow-y: visible;
    }
  }
`;

export default {
  Section,
  Accordion,
};
