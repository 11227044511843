import PropTypes from 'prop-types';
import React, { cloneElement } from 'react';

function ValidationWrapper({ children, touched, error, submitFailed }) {
  const displayError = (touched || submitFailed) && (error?.label || error?.message || error);

  return (
    <>
      {cloneElement(children, { error: displayError })}
    </>
  );
}

ValidationWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  touched: PropTypes.bool,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Object),
  ]),
  submitFailed: PropTypes.bool,
};

export default ValidationWrapper;
