import PropTypes from 'prop-types';
import React, { memo, useEffect, useState } from 'react';

import { formatAmount } from 'utils/amount';
import { isFxTransaction, isIncomingTransaction, isOutgoingTransaction } from 'utils/transactions';

import Amount from './components/amount';
import S from './styles';

function TransactionAmount({
  debitedAmount,
  applyCustomFormatting,
  netTransactionAmount,
  sourceCurrency,
  targetCurrency,
  isMultiCurrencyTransaction,
  type,
  showNetAmount,
}) {
  const [ readyToBeRendered, setReadyToBeRendered ] = useState(false);
  const [ formattedSourceAmount, setFormattedSourceAmount ] = useState();
  const [ formattedTargetAmount, setFormattedTargetAmount ] = useState();
  const [ targetAmount, setTargetAmount ] = useState();

  useEffect(() => {
    const formattedSource = formatAmount(debitedAmount);
    const formattedTarget = formatAmount(netTransactionAmount);

    setFormattedSourceAmount(formattedSource);
    setFormattedTargetAmount(formattedTarget);

    setTargetAmount(`${formattedTarget} ${targetCurrency}`);

    setReadyToBeRendered(true);
  }, [ netTransactionAmount, debitedAmount, targetCurrency ]);

  if (!readyToBeRendered) return null;

  // Check for 'Fx' transaction type should be the first.
  if (isFxTransaction(type)) {
    return (
      <S.Amount applyCustomFormatting={applyCustomFormatting}>
        <Amount
          indicator="-"
          amount={formattedSourceAmount}
          currency={sourceCurrency}
          applyCustomFormatting={applyCustomFormatting}
        />
        <S.SubAmount applyCustomFormatting={applyCustomFormatting}>
          +
          {' '}
          {targetAmount}
        </S.SubAmount>
      </S.Amount>
    );
  }

  if (isIncomingTransaction(type)) {
    return (
      <S.Amount isIncoming applyCustomFormatting={applyCustomFormatting}>
        <Amount
          indicator="+"
          amount={formattedTargetAmount}
          currency={targetCurrency}
          applyCustomFormatting={applyCustomFormatting}
        />
      </S.Amount>
    );
  }

  if (isOutgoingTransaction(type)) {
    const netAmount = `${formattedTargetAmount} ${sourceCurrency}`;

    return (
      <S.Amount applyCustomFormatting={applyCustomFormatting}>
        <Amount
          indicator="-"
          amount={formattedSourceAmount}
          currency={sourceCurrency}
          applyCustomFormatting={applyCustomFormatting}
        />
        {isMultiCurrencyTransaction && (
          <S.SubAmount applyCustomFormatting={applyCustomFormatting}>
            -
            {' '}
            {targetAmount}
          </S.SubAmount>
        )}
        {showNetAmount && (
          <S.NetAmountContainer>
            <S.NetAmountText>excl. fees: </S.NetAmountText>
            <S.NetAmount>
              -
              {netAmount}
            </S.NetAmount>
          </S.NetAmountContainer>
        )}
      </S.Amount>
    );
  }

  return null;
}

TransactionAmount.propTypes = {
  debitedAmount: PropTypes.number.isRequired,
  netTransactionAmount: PropTypes.number.isRequired,
  showNetAmount: PropTypes.bool,
  applyCustomFormatting: PropTypes.bool,
  sourceCurrency: PropTypes.string.isRequired,
  targetCurrency: PropTypes.string.isRequired,
  isMultiCurrencyTransaction: PropTypes.bool,
  type: PropTypes.string.isRequired,
};

export default memo(TransactionAmount);
