import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';

import { showGeneralToastError } from 'utils/errors';
import { getBeneficiaries } from 'api/beneficiary';
import { beneficiariesRequestOrderBy } from 'constants/beneficiaries';
import { GlobalLoaderContext } from 'contexts/global-loader';

const useBeneficiaries = (params = {}) => {
  const { startLoading, endLoading } = useContext(GlobalLoaderContext);
  const [ isFetching, setIsFetching ] = useState(true);
  const [ isInitiallyFetched, setIsInitiallyFetched ] = useState(false);
  const [ beneficiaries, setBeneficiaries ] = useState([]);
  const [ beneficiariesMeta, setBeneficiariesMeta ] = useState({});
  const [ paramsCache, setParamsCache ] = useState(null);

  const fetchBeneficiaries = async () => {
    startLoading();
    setParamsCache(params);
    setIsFetching(true);

    try {
      const { data, ...meta } = await getBeneficiaries(params);

      setBeneficiariesMeta(meta);
      setBeneficiaries(data);
    } catch (error) {
      showGeneralToastError(error);
    } finally {
      setIsInitiallyFetched(true);
      setIsFetching(false);
      endLoading();
    }
  };

  useEffect(() => {
    if (!isEqual(params, paramsCache)) fetchBeneficiaries();
  }, [params]);

  return { beneficiaries, beneficiariesMeta, isInitiallyFetched, isFetching, fetchBeneficiaries };
};

useBeneficiaries.propTypes = {
  params: PropTypes.shape({
    name: PropTypes.string,
    orderBy: PropTypes.oneOf(Object.keys(beneficiariesRequestOrderBy)),
    limit: PropTypes.number,
  }),
};

export default useBeneficiaries;
