import isEqual from 'lodash/isEqual';
import { useContext, useEffect, useState } from 'react';

import { showGeneralToastError } from 'utils/errors';
import { getTransactionList } from 'api/activities';
import { maxTransactionLimit } from 'constants/transactions';
import { GlobalLoaderContext } from 'contexts/global-loader';
import { PaymentCountContext } from 'contexts/payment-count';
import { TransactionContext } from 'contexts/transaction';

function isPreviousQueryMatchesCurrent(previousQuery, query) {
  return isEqual(previousQuery, query);
}

function useTransactionList(query, type) {
  const { startLoading, endLoading, setLoadingDelay, resetLoadingDelay } = useContext(GlobalLoaderContext);
  const { count } = useContext(PaymentCountContext);
  const countNumber = count?.[type];
  const { setTransactions } = useContext(TransactionContext);

  const [ isLoading, setIsLoading ] = useState(false);
  const [ meta, setMeta ] = useState(null);
  const [ previousQuery, setPreviousQuery ] = useState(null);
  const [ isRefetching, setIsRefetching ] = useState(false);

  async function getAllActivities(refetching = false) {
    try {
      setIsRefetching(refetching);
      setIsLoading(true);
      previousQuery && setLoadingDelay();
      startLoading();
      const { data, numberOfRecords, hasMore } = await getTransactionList(query);

      setMeta({ numberOfRecords, hasMore });
      const transactionLimited = data.length > maxTransactionLimit ? data.slice(0, maxTransactionLimit) : data;
      setTransactions(transactionLimited);
    } catch (error) {
      showGeneralToastError(error);
    } finally {
      setIsLoading(false);
      resetLoadingDelay();
      endLoading();
      setIsRefetching(false);
    }
  }

  useEffect(() => {
    const isQueryEqual = isPreviousQueryMatchesCurrent(previousQuery, query);
    if (isQueryEqual && previousQuery !== null) {
      return;
    }

    setPreviousQuery(query);
    getAllActivities();
  }, [query]);

  useEffect(() => {
    if (isLoading || countNumber === null) {
      return;
    }

    getAllActivities(true);
  }, [countNumber]);

  return { isLoading, meta, isRefetching };
}

export default useTransactionList;
