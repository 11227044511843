/* eslint-disable import/no-cycle */
// ! the import/no-cycle rule was disabled for this file
// ! consider another file and import structure when refactoring this file

import bff from 'utils/api';
import { cleanStorage, getToken } from 'utils/storage';
import { isTokenValid } from 'utils/token';

export const signOut = async () => {
  const token = getToken();

  if (!token) return;

  if (!isTokenValid(token)) {
    cleanStorage();

    return;
  }

  try {
    await bff.auth.signout();
  } catch {
    // All unexpected attempts will be kept silent.
  } finally {
    cleanStorage();
  }
};
