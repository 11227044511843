import { useContext, useEffect, useState } from 'react';

import { showGeneralToastError } from 'utils/errors';
import { getRoles } from 'api/roles';
import { GlobalLoaderContext } from 'contexts/global-loader';

const useRoles = () => {
  const { startLoading, endLoading } = useContext(GlobalLoaderContext);
  const [ roles, setRoles ] = useState([]);

  useEffect(() => {
    const fetchRoles = async () => {
      startLoading();

      try {
        const data = await getRoles();

        setRoles(data);
      } catch (error) {
        showGeneralToastError(error);
      } finally {
        endLoading();
      }
    };

    fetchRoles();
  }, [ startLoading, endLoading ]);

  const removeRoleById = id => {
    setRoles(roles.filter(role => role.id !== id));
  };

  return { roles, removeRoleById };
};

export default useRoles;
