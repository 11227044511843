import PropTypes from 'prop-types';
import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { isCardTransaction as checkForCardTransaction } from 'utils/transactions';
import { transactionType, transactionTypeLabel } from 'constants/transactions';
import { accountShape } from 'local-prop-types';

import { UNKNOWN_TRANSACTION_LABEL } from '../constants';
import CardAccountInfoBlock from '../card-account-info-block';
import S from './styles';

function DataBlock({
  id,
  type,
  author,
  merchant,
  cardholder,
  fromAccount,
  toAccount,
  reference,
  sourceCurrency,
  targetCurrency,
  isIncomingTransaction,
  isApiPayment,
  approvedBy,
  rejectedBy,
}) {
  const [ readyToBeRendered, setReadyToBeRendered ] = useState(false);
  const [ isCardTransaction, setIsCardTransaction ] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    recalculateIsCardTransaction();

    setReadyToBeRendered(true);
  }, []);

  useEffect(() => {
    recalculateIsCardTransaction();
  }, [type]);

  function recalculateIsCardTransaction() {
    setIsCardTransaction(checkForCardTransaction(type));
  }

  if (!readyToBeRendered) return null;

  return (
    <S.DataWrapper>
      <S.Grid isHidden={isApiPayment}>
        <S.Label>
          {t('activity.transactionSidebar.details.type')}
        </S.Label>
        <S.Detail>
          {transactionTypeLabel[type] || UNKNOWN_TRANSACTION_LABEL}
        </S.Detail>
      </S.Grid>

      <CardAccountInfoBlock
        reference={reference}
        fromAccount={fromAccount}
        toAccount={toAccount}
        sourceCurrency={sourceCurrency}
        targetCurrency={targetCurrency}
        isIncomingTransaction={isIncomingTransaction}
        type={type}
        isCardTransaction={isCardTransaction}
      />

      <S.Grid isHidden={!window.location || !isCardTransaction || type === transactionType.cardIncoming}>
        <S.Label>
          {t('activity.transactionSidebar.details.location')}
        </S.Label>
        <S.Detail>
          {merchant?.location}
          ,
          {merchant?.country}
        </S.Detail>
      </S.Grid>

      <S.Grid isHidden={isApiPayment}>
        <S.Label>
          {t('activity.transactionSidebar.details.traceId')}
        </S.Label>
        <S.Detail>
          {id}
        </S.Detail>
      </S.Grid>

      <S.Grid isHidden={isCardTransaction}>
        <S.Label>
          {t('activity.transactionSidebar.details.createdBy')}
        </S.Label>
        <S.Detail>
          {author}
        </S.Detail>
      </S.Grid>

      <S.Grid isHidden={!isCardTransaction || !cardholder}>
        <S.Label>
          {t('activity.transactionSidebar.details.cardholder')}
        </S.Label>
        <S.Detail>
          {cardholder}
        </S.Detail>
      </S.Grid>

      <S.Grid isHidden={!approvedBy}>
        <S.Label>
          {t('activity.transactionSidebar.details.approvedBy')}
        </S.Label>
        <S.Detail>
          {approvedBy?.name}
        </S.Detail>
      </S.Grid>

      <S.Grid isHidden={!rejectedBy}>
        <S.Label>
          {t('activity.transactionSidebar.details.rejectedBy')}
        </S.Label>
        <S.Detail>
          {rejectedBy?.name}
        </S.Detail>
      </S.Grid>
    </S.DataWrapper>
  );
}

DataBlock.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  merchant: PropTypes.instanceOf(Object),
  cardholder: PropTypes.string,
  fromAccount: accountShape.isRequired,
  toAccount: accountShape,
  reference: PropTypes.string,
  sourceCurrency: PropTypes.string.isRequired,
  targetCurrency: PropTypes.string.isRequired,
  isIncomingTransaction: PropTypes.bool.isRequired,
  isApiPayment: PropTypes.bool,
  rejectedBy: PropTypes.instanceOf(Object),
  approvedBy: PropTypes.instanceOf(Object),
};

export default memo(DataBlock);
