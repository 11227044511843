import { fxCurrencies, fxCurrenciesMap } from 'constants/fx';
import { adapters } from 'constants/payment-processor';

export const SEND_FUNDS_RECENT_BENEFICIARIES = 'sendFundsRecentBeneficiaries';
export const SEND_FUNDS_BENEFICIARY_FORM = 'sendFundsBeneficiaryForm';
export const SEND_FUNDS_AMOUNT_FORM = 'sendFundsAmountForm';
export const SEND_FUNDS_CONFIRMATION = 'sendFundsConfirmation';
export const SEND_FUNDS_FINAL = 'sendFundsFinal';

// beneficiary
export const BENEFICIARY_FORM = 'beneficiaryFrom';
export const RAILS_FIELD_NAME = 'rails';
export const BENEFICIARY_CURRENCY = 'currency';
export const BENEFICIARY_TYPE = 'beneficiaryType';
export const BENEFICIARY_BANK_ACCOUNT_COUNTRY = 'bankAccountCountry';
export const BENEFICIARY_COUNTRY = 'beneficiaryCountry';
export const BENEFICIARY_NAME = 'beneficiaryName';
export const BENEFICIARY_DETAILS_RESPONSE = 'beneficiaryDetailsResponse';

export const BENEFICIARY_STATE = 'beneficiaryState';
export const BENEFICIARY_CITY = 'beneficiaryCity';
export const BENEFICIARY_ADDRESS = 'beneficiaryAddress';
export const BENEFICIARY_POSTAL_CODE = 'beneficiaryPostalCode';
export const BENEFICIARY_IBAN = 'beneficiaryIban';
export const BENEFICIARY_BIC = 'beneficiaryBic';
export const BENEFICIARY_ACCOUNT_NUMBER = 'beneficiaryAccountNumber';
export const BENEFICIARY_SORT_CODE = 'beneficiarySortCode';
export const BENEFICIARY_ROUTING_NUMBER = 'beneficiaryRoutingNumber';
export const BENEFICIARY_INSTITUTION_NUMBER = 'beneficiaryInstitutionNumber';
export const BENEFICIARY_CLABE = 'beneficiaryClabe';
export const BENEFICIARY_CNAPS = 'beneficiaryCnaps';
export const BENEFICIARY_IFSC = 'beneficiaryIfsc';
export const BENEFICIARY_BANK_CODE = 'beneficiaryBankCode';
export const BENEFICIARY_BSB_CODE = 'beneficiaryBsbCode';
export const BENEFICIARY_BRANCH_CODE = 'beneficiaryBranchCode';

export const BENEFICIARY_FIRST_NAME = 'firstName';
export const BENEFICIARY_LAST_NAME = 'lastName';

export const POLL_TIMEOUT = 30000;
export const COMPANY_BENEFICIARY_TYPE = 'company';
export const INDIVIDUAL_BENEFICIARY_TYPE = 'individual';

export const DEBOUNCE_DELAY = 1000;

// amount
export const AMOUNT_FORM = 'amountForm';
export const FROM_ACCOUNT = 'fromAccount';
export const SEND_AMOUNT = 'sendAmount';
export const RECEIVE_AMOUNT = 'receiveAmount';
export const PURPOSE_CODE = 'purposeCode';
export const PURPOSE_CODE_REQUIRED = 'purposeCodeRequired';
export const PAYMENT_REFERENCE = 'paymentReference';
export const PAYMENT_REFERENCE_TOUCHED = 'paymentReferenceTouched';
export const TOTAL_FEE_AMOUNT = 'totalFeeAmount';
export const FX_RATE = 'fxRate';
export const FX_SIDE = 'fxSide';

export const BUY_FX_SIDE = 'buy';
export const SELL_FX_SIDE = 'sell';

export const FROM_ACCOUNT_ID = 'fromAccountId';

export const MAX_PAYMENT_REFERENCE_LENGTH = 140;

export const MIN_AMOUNT = 0.01;

export const FX_TRANSACTION_TYPE = 'fx';
export const TRANSFER_TRANSACTION_TYPE = 'transfer';

export const PAYMENT_REFERENCE_INVALID_CHARACTERS_PATTERN = /[^a-zA-Z0-9/?:().,+\s-]/g;

export const BENEFICIARY_TYPE_OPTIONS = [
  {
    label: 'Company',
    value: COMPANY_BENEFICIARY_TYPE,
  },
  {
    label: 'Individual',
    value: INDIVIDUAL_BENEFICIARY_TYPE,
  },
];

export const LOCAL_RAILS = 'local';
export const INTERNATIONAL_RAILS = 'international';

export const EXCLUDED_PAYMENT_PROCESSOR_ADAPTERS = [ adapters.onpex, adapters.saxo ];

// confirmation
export const CONFIRMATION_FORM = 'confirmationForm';

export const EXCLUDED_COUNTRIES = [
  'Cuba',
  'Iran',
  'North Korea',
  'Libya',
  'Myanmar [Burma]',
  'Sudan',
  'South Sudan',
  'Syria',
  'Venezuela',
];

const ADDRESS_PATH = 'address';
const BANK_CODE_ACCOUNT_PATH = 'bankCode';
const ACCOUNT_NUMBER_PATH = 'number';

export const BENEFICIARY_FORM_INITIAL_STATE = {
  [BENEFICIARY_CURRENCY]: '',
  [RAILS_FIELD_NAME]: LOCAL_RAILS,
  [BENEFICIARY_BANK_ACCOUNT_COUNTRY]: null,
  [BENEFICIARY_COUNTRY]: null,
  [BENEFICIARY_TYPE]: null,
  [BENEFICIARY_NAME]: null,
  [BENEFICIARY_DETAILS_RESPONSE]: [],
};

export const AMOUNT_FORM_INITIAL_STATE = {
  [FROM_ACCOUNT]: {},
  [SEND_AMOUNT]: null,
  [RECEIVE_AMOUNT]: null,
  [PAYMENT_REFERENCE]: null,
  formMetaData: {
    [PAYMENT_REFERENCE_TOUCHED]: false,
  },
};

export const BONDED_FIELDS = {
  [BENEFICIARY_FIRST_NAME]: {
    [BENEFICIARY_LAST_NAME]: true,
    [BENEFICIARY_FIRST_NAME]: false,
  },
};

export const BENEFICIARY_PATHS_MAP = {
  name: {
    label: 'Beneficiary name',
    fieldName: BENEFICIARY_NAME,
    sort: 1,
  },
  [BENEFICIARY_FIRST_NAME]: {
    label: 'Beneficiary first name',
    fieldName: BENEFICIARY_FIRST_NAME,
    sort: 2,
  },
  [BENEFICIARY_LAST_NAME]: {
    label: 'Beneficiary last name',
    fieldName: BENEFICIARY_LAST_NAME,
    sort: 3,
  },
  email: {
    label: 'Beneficiary email',
    fieldName: 'email',
    sort: 4,
  },
  [`${ADDRESS_PATH}.street`]: {
    label: 'Beneficiary address',
    fieldName: BENEFICIARY_ADDRESS,
    sort: 5,
  },
  [`${ADDRESS_PATH}.region`]: {
    label: 'State/Region',
    fieldName: BENEFICIARY_STATE,
    sort: 6,
  },
  [`${ADDRESS_PATH}.city`]: {
    label: 'Beneficiary city',
    fieldName: BENEFICIARY_CITY,
    sort: 7,
  },
  [`${ADDRESS_PATH}.postCode`]: {
    label: 'Postal code',
    fieldName: BENEFICIARY_POSTAL_CODE,
    sort: 8,
  },
  [`account.${ACCOUNT_NUMBER_PATH}.iban`]: {
    label: 'IBAN',
    fieldName: BENEFICIARY_IBAN,
    sort: 9,
  },
  [`account.${ACCOUNT_NUMBER_PATH}.number`]: {
    label: 'Account number',
    fieldName: BENEFICIARY_ACCOUNT_NUMBER,
    sort: 9,
  },
  [`account.${BANK_CODE_ACCOUNT_PATH}.bic`]: {
    label: 'BIC',
    fieldName: BENEFICIARY_BIC,
    sort: 10,
  },
  [`account.${BANK_CODE_ACCOUNT_PATH}.sort_code`]: {
    label: 'Sort code (local clearing number)',
    fieldName: BENEFICIARY_SORT_CODE,
    sort: 10,
  },
  [`account.${BANK_CODE_ACCOUNT_PATH}.aba`]: {
    label: 'Routing number',
    fieldName: BENEFICIARY_ROUTING_NUMBER,
    sort: 10,
  },
  [`account.${BANK_CODE_ACCOUNT_PATH}.institution_no`]: {
    label: 'Financial institution number',
    fieldName: BENEFICIARY_INSTITUTION_NUMBER,
    sort: 10,
  },
  [`account.${BANK_CODE_ACCOUNT_PATH}.clabe`]: {
    label: 'CLABE',
    fieldName: BENEFICIARY_CLABE,
    sort: 10,
  },
  [`account.${BANK_CODE_ACCOUNT_PATH}.cnaps`]: {
    label: 'CNAPS',
    fieldName: BENEFICIARY_CNAPS,
    sort: 10,
  },
  [`account.${BANK_CODE_ACCOUNT_PATH}.ifsc`]: {
    label: 'IFSC',
    fieldName: BENEFICIARY_IFSC,
    sort: 10,
  },
  [`account.${BANK_CODE_ACCOUNT_PATH}.bank_code`]: {
    label: 'Bank code (local clearing number)',
    fieldName: BENEFICIARY_BANK_CODE,
    sort: 10,
  },
  [`account.${BANK_CODE_ACCOUNT_PATH}.bsb_code`]: {
    label: 'BSB Code (local clearing number)',
    fieldName: BENEFICIARY_BSB_CODE,
    sort: 10,
  },
  'account.branchCode': {
    label: 'Transit (branch) number',
    fieldName: BENEFICIARY_BRANCH_CODE,
    sort: 11,
  },
};

export const BENEFICIARIES_FIELDS_NAMES_MAP = Object.keys(BENEFICIARY_PATHS_MAP).reduce((resultObject, path) => ({
  ...resultObject,
  [BENEFICIARY_PATHS_MAP[path]?.fieldName]: path.match(/^(\w+).(\w+)/)[0],
}),
{});

export const FORM_FIELDS_TO_BENEFICIARY_REQUEST_MAP = {
  [BENEFICIARY_BANK_ACCOUNT_COUNTRY]: 'account.country',
  [BENEFICIARY_COUNTRY]: 'address.country',
  [BENEFICIARY_TYPE]: 'entityType',
  [BENEFICIARY_NAME]: 'name',
  [BENEFICIARY_CURRENCY]: 'account.currency',
  ...BENEFICIARIES_FIELDS_NAMES_MAP,
};

export const RAILS_MAP = {
  sepa: {
    binaryRail: LOCAL_RAILS,
    currencies: [fxCurrenciesMap.EUR],
    paymentProcessorAdapters: [adapters.bol],
  },
  swift: {
    binaryRail: INTERNATIONAL_RAILS,
    currencies: fxCurrencies,
    paymentProcessorAdapters: [adapters.spark],
  },
  'uk-local': {
    binaryRail: LOCAL_RAILS,
    currencies: [fxCurrenciesMap.GBP],
    paymentProcessorAdapters: [adapters.spark],
  },
  'se-local': {
    binaryRail: LOCAL_RAILS,
    currencies: [fxCurrenciesMap.SEK],
    paymentProcessorAdapters: [adapters.spark],
  },
  'us-local': {
    binaryRail: LOCAL_RAILS,
    currencies: [fxCurrenciesMap.USD],
    paymentProcessorAdapters: [adapters.spark],
  },
  'dk-local': {
    binaryRail: LOCAL_RAILS,
    currencies: [fxCurrenciesMap.DKK],
    paymentProcessorAdapters: [adapters.spark],
  },
  'no-local': {
    binaryRail: LOCAL_RAILS,
    currencies: [fxCurrenciesMap.NOK],
    paymentProcessorAdapters: [adapters.spark],
  },
  'au-local': {
    binaryRail: LOCAL_RAILS,
    currencies: [fxCurrenciesMap.AUD],
    paymentProcessorAdapters: [adapters.spark],
  },
  'pl-local': {
    binaryRail: LOCAL_RAILS,
    currencies: [fxCurrenciesMap.PLN],
    paymentProcessorAdapters: [adapters.spark],
  },
  'ca-local': {
    binaryRail: LOCAL_RAILS,
    currencies: [fxCurrenciesMap.CAD],
    paymentProcessorAdapters: [adapters.spark],
  },
};

export const ADDITIONAL_REQUIRED_FIELDS = [
  { name: 'account.currency' },
  { name: 'account.paymentRail' },
  { name: 'entityType' },
  { name: 'address.country' },
  { name: 'account.country' },
];

export const PRESELECTED_LOCAL_BANK_ACCOUNT_COUNTRIES = {
  [fxCurrenciesMap.GBP]: 'GB',
  [fxCurrenciesMap.USD]: 'US',
  [fxCurrenciesMap.SEK]: 'SE',
  [fxCurrenciesMap.DKK]: 'DK',
  [fxCurrenciesMap.NOK]: 'NO',
  [fxCurrenciesMap.AUD]: 'AU',
  [fxCurrenciesMap.PLN]: 'PL',
};

export const ADDITIONAL_FIELDS_NAMES_DEFAULTS_MAP = {
  [BENEFICIARY_NAME]: null,
  [BENEFICIARY_FIRST_NAME]: null,
  [BENEFICIARY_LAST_NAME]: null,
  [BENEFICIARY_CITY]: null,
  [BENEFICIARY_STATE]: null,
  [BENEFICIARY_ADDRESS]: null,
  [BENEFICIARY_POSTAL_CODE]: null,
  [BENEFICIARY_IBAN]: null,
  [BENEFICIARY_BIC]: null,
  [BENEFICIARY_SORT_CODE]: null,
  [BENEFICIARY_ROUTING_NUMBER]: null,
  [BENEFICIARY_INSTITUTION_NUMBER]: null,
  [BENEFICIARY_CLABE]: null,
  [BENEFICIARY_CNAPS]: null,
  [BENEFICIARY_IFSC]: null,
  [BENEFICIARY_BANK_CODE]: null,
  [BENEFICIARY_BSB_CODE]: null,
  [BENEFICIARY_BRANCH_CODE]: null,
  [BENEFICIARY_ACCOUNT_NUMBER]: null,
  [BENEFICIARY_DETAILS_RESPONSE]: [],
};

export const REQUIRED_FIELDS_NAMES_DEFAULTS_MAP = {
  [BENEFICIARY_BANK_ACCOUNT_COUNTRY]: null,
  [BENEFICIARY_TYPE]: null,
  [BENEFICIARY_COUNTRY]: null,
};

export const ACCOUNT_NUMBER_FIELD_NAMES = Object.keys(BENEFICIARY_PATHS_MAP)
  .filter(path => path.match(ACCOUNT_NUMBER_PATH))
  .map(path => BENEFICIARY_PATHS_MAP[path]?.fieldName);

export const BANK_CODE_FIELD_NAMES = Object.keys(BENEFICIARY_PATHS_MAP)
  .filter(path => path.match(BANK_CODE_ACCOUNT_PATH))
  .map(path => BENEFICIARY_PATHS_MAP[path]?.fieldName);

export const BENEFICIARY_FIELDS_TO_TRIM = [ BENEFICIARY_IBAN, BENEFICIARY_BIC ];

export const NON_SEPA_IBAN_NOTIFICATION = 'nonSepaIban';
export const OWN_IBAN_NOTIFICATION = 'ownIban';
export const EXCHANGE_RATE_CHANGED_NOTIFICATION = 'exchangeRateChanged';

export const NOTIFICATION_TEXTS = {
  [NON_SEPA_IBAN_NOTIFICATION]: `
    IBAN you provided is not SEPA (Single Euro Payments Area) 
    enabled, which may cause this transfer to be rejected
  `,
  [OWN_IBAN_NOTIFICATION]: `
    This IBAN is one of your own. For moving money between 
    your own accounts in the same currency, it’s easier to use the 
    “Move” button from the main account list.
  `,
  [EXCHANGE_RATE_CHANGED_NOTIFICATION]: `
    Oops...The exchange rate changed while you tried to 
    complete this transaction. Please try again.
  `,
};

export const MOBILE_MAX_ACCOUNT_NUMBER_CHARS = 12;

export const ACTION_CLEAR = 'clear';
