import React, { memo, useRef, useState } from 'react';
import { Avatar, DropdownBeneficiaryOption } from '@general/intergiro-ui-kit';
import capitalize from 'lodash/capitalize';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { INTERNATIONAL_RAILS, RAILS_MAP } from 'pages/send-funds/constants';
import { beneficiaryShape } from 'local-prop-types';
import { navigation } from 'constants/navigation';
import { useView } from 'hooks';
import DeleteModal from 'pages/beneficiary/components/delete-modal';

import S from './styles';
import DotsActionDropdown from '../dots-action-dropdown';
import { dataTestIds } from './constants';

function BeneficiaryRow({ beneficiary, onDelete, openToTop }) {
  const [ isDeleteModalOpen, setDeleteModalOpen ] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  const rowRef = useRef();
  const { isMobile } = useView();

  const {
    name,
    id,
    isTrusted,
    account: {
      currency,
      number: { value: accountNumber },
      bankCode: { value: bankCode },
      paymentRail,
    },
  } = beneficiary;

  const getPaymentLocationText = binaryRail =>
    capitalize(binaryRail === INTERNATIONAL_RAILS ? t('sendFunds.recentBeneficiaries.international') : binaryRail);

  const handleRowClick = e => {
    if (e.target === rowRef.current) {
      handleView();
    }
  };

  const handleSend = () => {
    history.push(`${navigation.sendFunds}?beneficiaryId=${beneficiary.id}`);
  };

  const handleView = () => {
    history.push(`${navigation.beneficiaries}/${id}`);
  };

  const handleDelete = () => {
    setDeleteModalOpen(true);
  };

  return (
    <>
      <S.Row ref={rowRef} data-testid={dataTestIds.row} onClick={handleRowClick}>
        <S.Column onClick={handleView} mainColumn>
          <DropdownBeneficiaryOption
            name={(
              <S.BeneficiaryName>
                {name}
              </S.BeneficiaryName>
          )}
            avatar={memo(() => <Avatar small name={name} param1={accountNumber} param2={bankCode} />)}
            accountNumber={!isMobile && getPaymentLocationText(RAILS_MAP[paymentRail].binaryRail)}
            currencyShort={!isMobile && currency}
            sortCode={` ${accountNumber}`}
            isTrusted={isTrusted}
          />
        </S.Column>
        <S.Column actionsColumn isHiddenOnMobile>
          <DotsActionDropdown openToTop={openToTop}
            list={[
              <S.Action data-testid={dataTestIds.send} onClick={handleSend}>
                {t('beneficiaries.actions.send')}
              </S.Action>,
              <S.Action data-testid={dataTestIds.view} onClick={handleView}>
                {t('beneficiaries.actions.view')}
              </S.Action>,
              <S.Action data-testid={dataTestIds.delete} red onClick={handleDelete}>
                {t('beneficiaries.actions.delete')}
              </S.Action>,
          ]}
          />
        </S.Column>
      </S.Row>
      <DeleteModal
        isOpen={isDeleteModalOpen}
        setIsOpen={setDeleteModalOpen}
        beneficiary={beneficiary}
        onDelete={onDelete}
      />
    </>
  );
}

BeneficiaryRow.propTypes = {
  beneficiary: PropTypes.shape(beneficiaryShape).isRequired,
  onDelete: PropTypes.func.isRequired,
  openToTop: PropTypes.bool,
};

export default BeneficiaryRow;
