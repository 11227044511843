import { toast } from 'react-toastify';

import { approveApiPayment, rejectApiPayment } from 'api/api-payments';
import { approve, bulkApprove, bulkReject, reject } from 'api/approval-requests';
import { approvalActions } from 'constants/api';

export function getApprovalRequestMethodByAction(action, data) {
  switch (action) {
    case approvalActions.approve:
      return approve(data);

    case approvalActions.reject:
      return reject(data);

    case approvalActions.bulkApprove:
      return bulkApprove(data);

    case approvalActions.bulkReject:
      return bulkReject(data);

    case approvalActions.approveApiPayment:
      return approveApiPayment(data);

    case approvalActions.rejectApiPayment:
      return rejectApiPayment(data);

    default:
      return {};
  }
}

export function showSuccessNotificationByAction(action) {
  switch (action) {
    case approvalActions.approve:
      return toast.success('Payment approved');

    case approvalActions.reject:
      return toast.error('Payment rejected');

    case approvalActions.bulkApprove:
      return toast.success('Multiple payments approved');

    case approvalActions.bulkReject:
      return toast.error('Multiple payments rejected');

    case approvalActions.approveApiPayment:
      return toast.success('API payment approved');

    case approvalActions.rejectApiPayment:
      return toast.error('API payment rejected');

    default:
      return {};
  }
}
