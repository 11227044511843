import { Tabs } from '@general/intergiro-ui-kit';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { v4 as uuidv4 } from 'uuid';

import { EmptyState, TransactionList, TransactionPagination } from 'components';
import { defaultMetadata, metaTypes, queryTypes, paymentsCountTypes } from 'constants/transactions';
import { TransactionContext } from 'contexts/transaction';
import { UserContext } from 'contexts/user';
import { useFilters, useTransactionList } from 'hooks';
import { getParameterFromURL } from 'utils/helpers';
import { groupTransactionsByDate } from 'utils/transactions';

import S from './styles';
import ActivityFilterBar from '../activity-filter-bar';

let currentMeta = {};
function Transactions() {
  const location = useLocation();
  const { isSidebarOpen, transactions } = useContext(TransactionContext);
  const {
    permissions: { canViewAccounts },
    tokenPayload: { permissions: userPermissions },
  } = useContext(UserContext);
  const { filters } = useFilters(canViewAccounts, userPermissions);
  const [ query, setQuery ] = useState();

  useEffect(() => {
    setQuery(getPageQuery());
  }, [filters]);

  const { isLoading, meta } = useTransactionList(query, paymentsCountTypes.numberOfAllPayments);
  currentMeta = meta;

  const refreshQuery = () => {
    setQuery(getPageQuery());
  };

  function getPageQuery() {
    const limit = getParameterFromURL(metaTypes.limit, location);
    const startingAfter = getParameterFromURL(metaTypes.startingAfter, location);
    const endingBefore = getParameterFromURL(metaTypes.endingBefore, location);

    return {
      filters,
      [metaTypes.general]: {
        ...defaultMetadata,
        ...currentMeta,
        ...(limit && { [queryTypes.limit]: limit }),
        ...(startingAfter && { [queryTypes.startingAfter]: startingAfter }),
        ...(endingBefore && { [queryTypes.endingBefore]: endingBefore }),
      },
    };
  }

  function renderTransactions() {
    if (!isLoading && transactions.length === 0) {
      return <EmptyState title="There is no activity to display yet." />;
    }

    const groupedTransactionsByDate = Object.entries(groupTransactionsByDate(transactions));

    return (
      <TransactionPagination refreshQuery={refreshQuery} transactions={transactions} meta={meta}>
        <div data-testid="transactions-all-activity-wrapper">
          {groupedTransactionsByDate.map(([ date, activities ]) => (
            <TransactionList
              key={uuidv4()}
              date={date}
              activities={activities}
            />
          ))}
        </div>
      </TransactionPagination>
    );
  }

  return (
    <S.Wrapper data-testid="transactions-wrapper">
      <div>
        <S.TabWrapper>
          <Tabs.Container name="tabs">
            <S.TabsHead id="all-activity" isActive>
              All activity
            </S.TabsHead>
            <ActivityFilterBar />
          </Tabs.Container>
        </S.TabWrapper>
        <Tabs.Panel>
          <S.TransactionsWrapper isSidebarOpen={isSidebarOpen}>
            {renderTransactions()}
          </S.TransactionsWrapper>
        </Tabs.Panel>
      </div>
    </S.Wrapper>
  );
}

export default Transactions;
