import styled from 'styled-components';

import { ReactComponent as Icon } from 'assets/icons/info.svg';
import { DEVICE } from 'constants/breakpoints';

const Accounts = styled.div`
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  gap: 1.2rem;
  margin-top: 1.875rem;
  margin-bottom: 1.875rem;

  @media ${DEVICE.tablet} {
    gap: 1.875rem;
  }
  
  @media ${DEVICE.laptop} {
    gap: ${props => (props.$isGrid ? '1.875rem' : '.65rem')};
  }
`;

const InfoIcon = styled(Icon)`
  width: 2rem;
  height: 2rem;
  fill: ${props => props.theme.colors.red};
`;

export default {
  Accounts,
  InfoIcon,
};
