import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { navigation } from 'constants/navigation';
import { sanitizePolls } from 'pages/move-funds/services/utils';
import { formatAmount } from 'utils/amount';

import S from './styles';

function MoveFundsFinal({ createdTransaction, history, setLayoutProps, currentPollId }) {
  const successMessage = transaction => {
    const amount = formatAmount(transaction.targetAmount, transaction.targetCurrency);

    return transaction ? `
      You moved ${amount}
      ${transaction.targetCurrency} to
      ${transaction.targetName}.
    ` : '';
  };

  const failureMessage = `
      Please try again and if unsuccessful again, please contact our
      customer support for assistance.
    `;

  useEffect(() => {
    sanitizePolls(currentPollId, true);
  }, [currentPollId]);

  const onViewDetails = () => {
    history.push(navigation.activity);
  };

  const backToAccounts = () => {
    history.push('/');
  };

  useEffect(() => {
    setLayoutProps({
      title: createdTransaction ? 'Funds moved' : 'Payment unsuccessful',
      displayCancelButton: false,
      hideBackButton: true,
    });
  }, [createdTransaction]);

  return (
    <div>
      <S.IconWrapper>
        {createdTransaction ? <S.SuccessIcon /> : <S.ErrorIcon />}
      </S.IconWrapper>
      <S.Text>
        {createdTransaction ? successMessage(createdTransaction) : failureMessage}
      </S.Text>
      <S.Button back onClick={backToAccounts} label="Back to accounts" category="secondary" />
      {
        createdTransaction &&
          <S.Button onClick={onViewDetails} label="View payment details" category="primary" />
      }
    </div>
  );
}

MoveFundsFinal.propTypes = {
  createdTransaction: PropTypes.instanceOf(Object),
  currentPollId: PropTypes.number,
  history: PropTypes.instanceOf(Object).isRequired,
  setLayoutProps: PropTypes.func,
};

export default MoveFundsFinal;
