import { Checkbox as IntergiroCheckbox } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';
import { hex2rgba } from 'helpers/styles';

const CheckboxGroupSelectAllWrapper = styled.div`
  border-bottom: 0.0625rem solid ${props => hex2rgba(props.theme.colors.black, 0.1)};
  margin-top: 1rem;
  padding-bottom: 1rem;

  @media ${DEVICE.desktop} {
    padding-right: 1rem;
  }
`;

const Checkbox = styled(IntergiroCheckbox)`
  width: auto;

  > span:last-of-type {
    display: block;
    height: 100%;
    width: 100%;
  }
`;

const CheckboxSingleWrapper = styled.div`
  align-items: center;
  display: flex;
  margin: 0 0 1rem;
  
   > label {
     align-items: center;
   }
`;

const SecondaryLabel = styled.span`
  display: flex;
  font: ${props => props.theme.fonts.label};
  color:${props => props.theme.colors.greyMediumDark};
`;

const CheckboxGroupAllList = styled.div`
  padding-top: 1rem;
`;

export default {
  CheckboxGroupSelectAllWrapper,
  Checkbox,
  CheckboxSingleWrapper,
  CheckboxGroupAllList,
  SecondaryLabel,
};
