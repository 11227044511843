import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Dropdown, Input } from '@general/intergiro-ui-kit';
import first from 'lodash/first';

import { DROPDOWN_INPUT_BLUR_ACTION, DROPDOWN_INPUT_CHANGE_ACTION } from 'constants/dropdown';
import ModalStyles from 'pages/accounts/styles';

import S from './styles';

function UpdateAccountForm({
  handleChange,
  handleBlur,
  setFieldValue,
  setFieldTouched,
  values,
  touched,
  errors,
  isSubmitting,
}) {
  const { t } = useTranslation();
  const [ dropdownInputValue, setDropdownInputValue ] = useState('');

  const handleLabelsChange = labelsValue => {
    setDropdownInputValue('');
    setFieldValue('labels', labelsValue);
  };

  const handleLabelsBlur = () => {
    setFieldTouched('labels', true);
  };

  const labelsError = touched.labels && (
    Array.isArray(errors.labels)
      ? first(errors.labels.filter(Boolean)).value
      : errors.labels?.message || errors.labels
  );

  const handleDropdownBlur = () => {
    const value = dropdownInputValue?.trim() || '';
    const labelExists = values.labels.find(({ label }) => label === value);

    if (!value || labelExists) {
      return;
    }

    const option = { label: value, value };

    setFieldValue('labels', [ ...(values.labels || []), option ]);

    setDropdownInputValue('');
  };

  const onInputChange = (inputValue, { action }) => {
    if (action === DROPDOWN_INPUT_CHANGE_ACTION) {
      setDropdownInputValue(inputValue);
    }
    if (action === DROPDOWN_INPUT_BLUR_ACTION) {
      handleDropdownBlur();
    }
  };

  return (
    <ModalStyles.ModalContent>
      <Input
        autoFocus
        label={t('accounts.renameAccountModal.nameInputLabel')}
        onChange={handleChange}
        name="name"
        error={touched.name && (errors.name?.message || errors.name)}
        value={values.name}
        onBlur={handleBlur}
        disabled={isSubmitting}
      />

      <S.LabelsWrapper>
        <Dropdown
          value={values.labels}
          label={t('accounts.renameAccountModal.labelInputLabel')}
          name="labels"
          onChange={handleLabelsChange}
          onBlur={handleLabelsBlur}
          error={labelsError}
          isCreatable
          isMulti
          disableDropdown
          isClearable={false}
          isDisabled={isSubmitting}
          onInputChange={onInputChange}
          assistiveText={(
            <Trans i18nKey="accounts.renameAccountModal.labelAssistiveText">
              {/* eslint-disable-next-line react/no-unescaped-entities,react/jsx-one-expression-per-line */}
              'Press Enter <S.AssistiveTextIcon>↵</S.AssistiveTextIcon> to add more'
            </Trans>
          )}
        />
      </S.LabelsWrapper>
    </ModalStyles.ModalContent>
  );
}

UpdateAccountForm.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  values: PropTypes.shape({
    name: PropTypes.string,
    labels: PropTypes.instanceOf(Object),
  }),
  touched: PropTypes.shape({
    name: PropTypes.bool,
    labels: PropTypes.oneOfType([ PropTypes.bool, PropTypes.array ]),
  }),
  errors: PropTypes.shape({
    name: PropTypes.oneOfType([ PropTypes.instanceOf(Object), PropTypes.string ]),
    labels: PropTypes.oneOfType([ PropTypes.instanceOf(Object), PropTypes.string ]),
  }),
};

export default UpdateAccountForm;
