import { boolean, object, ref, string } from 'yup';

import { formFields } from './constants';

export const SetPasswordSchema = object().shape({
  [formFields.newPassword.name]: string()
    .required('Please enter a password')
    .min(6, 'Min. 6 characters')
    .max(25, 'Max. 25 characters'),
  [formFields.confirmPassword.name]: string()
    .oneOf([ ref(formFields.newPassword.name), null ], 'Passwords must match')
    .required('Please confirm your password'),
  [formFields.privacy.name]: boolean().oneOf([true], 'You must accept privacy policy.'),
});
