import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import set from 'lodash/set';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { WizardLayout } from 'layouts';
import { usePermissionCategories } from 'hooks';
import { ViewContext } from 'contexts/view';
import { GlobalLoaderContext } from 'contexts/global-loader';
import { navigation } from 'constants/navigation';
import PermissionsList from 'components/permissions-list';
import { createRole } from 'api/roles';

import S from './styles';

function AddRole() {
  const { t } = useTranslation();
  const history = useHistory();

  const { permissionCategories, setPermissionCategories } = usePermissionCategories();
  const { startLoading, endLoading } = useContext(GlobalLoaderContext);

  const [ role, setRole ] = useState({ permissions: [] });
  const [ error, setError ] = useState(null);

  const hasChanges = !isEmpty(role);

  function handleBackToRoles() {
    history.push(navigation.roles);
  }

  const handleChangeName = useCallback(event => {
    const name = event.target.value;

    setError(null);
    setRole({ ...role, name });
  }, []);

  const handlePermissionChange = (key, path) => {
    const permission = get({ permissionCategories }, path);
    const updatedRole = set({ permissionCategories }, `${path}.value`, !permission.value);

    setPermissionCategories(updatedRole.permissionCategories);
    setRole({
      name: role.name,
      permissions: [
        ...role.permissions,
        {
          title: permission.title,
          key,
          value: permission.value,
        },
      ],
    });
  };

  const handleCreate = async () => {
    startLoading();

    try {
      await createRole(role);

      toast.success(t('addRole.successMessage'));
      history.push(navigation.roles);
    } catch (err) {
      setError(err.data);
    } finally {
      endLoading();
    }
  };

  const viewValues = useMemo(() => ({ triggerView: handleBackToRoles }), []);
  const isContentLoading = isEmpty(permissionCategories);

  return (
    <ViewContext.Provider value={viewValues}>
      <WizardLayout title={t('addRole.title')}>
        {!isContentLoading && (
          <S.Container>
            <S.Input
              name="name"
              label={t('addRole.form.name.label')}
              assistiveText={t('addRole.form.name.assistiveText')}
              value={role.name}
              error={error?.name?.message}
              onChange={handleChangeName}
              isClearable={false}
              autoFocus
            />
            <PermissionsList
              permissionCategories={permissionCategories}
              handlePermissionChange={handlePermissionChange}
            />
            <S.Button
              label={t('addRole.form.button.create')}
              disabled={!hasChanges}
              onClick={handleCreate}
            />
          </S.Container>
        )}
      </WizardLayout>
    </ViewContext.Provider>
  );
}

export default AddRole;
