import { envVariablesHelper } from '@general/config-manager';

export const config = {
  envName: envVariablesHelper.getString('REACT_APP_ENVIRONMENT_NAME', window.env),
  bffUrl: envVariablesHelper.getString('REACT_APP_BFF_URL', window.env),
  corporateSiteUrl: envVariablesHelper.getString('REACT_APP_CORPORATE_SITE_URL', window.env),
  merchantPageUrl: envVariablesHelper.getString('REACT_APP_MERCHANT_PAGE_URL', window.env),
  pciUrl: envVariablesHelper.getString('REACT_APP_PCI_URL', window.env),
  inactivityTimeout: envVariablesHelper.getNumber('REACT_APP_INACTIVITY_TIMEOUT', window.env),
  onboardingUrl: envVariablesHelper.getString('REACT_APP_ONBOARDING_URL', window.env),
  registrationUrl: envVariablesHelper.getString('REACT_APP_REGISTRATION_URL', window.env),
  analyticsDomainName: envVariablesHelper.getString('REACT_APP_ANALYTICS_DOMAIN_NAME', window.env),
  hubspotTrackingCode: envVariablesHelper.getString('REACT_APP_HUBSPOT_TRACKING_CODE', window.env),
  intercomId: envVariablesHelper.getString('REACT_APP_INTERCOM_APP_ID', window.env),
  intercomApiBase: envVariablesHelper.getString('REACT_APP_INTERCOM_API_BASE', window.env),
  survicateId: envVariablesHelper.getString('REACT_APP_SURVICATE_APP_ID', window.env),
  gtmId: envVariablesHelper.getString('REACT_APP_GTM_ID', window.env),
  sentryApiKey: envVariablesHelper.getString('REACT_APP_SENTRY_API_KEY', window.env),
  featureFlags: {
    enableBeneficiariesTrustedFlow: envVariablesHelper.getBoolean('REACT_APP_FF_ENABLE_BENEFICIARIES_TRUSTED_FLOW', window.env),
    disabledLocalCurrencies: envVariablesHelper.getString('REACT_APP_FF_DISABLED_LOCAL_CURRENCIES', window.env),
  },
};

export default config;
