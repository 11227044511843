import React from 'react';
import PropTypes from 'prop-types';
import { RadioButton } from '@general/intergiro-ui-kit';

import S from './styles';

function ImageRadio({ title, description, label, name, id, checked, image, handleChange }) {
  return (
    <S.Container htmlFor={id} isChecked={checked}>
      <S.Image isChecked={checked}>
        {image}
      </S.Image>
      <S.Content>
        <S.Title>
          {title}
        </S.Title>
        <S.Description>
          {description}
        </S.Description>
        <RadioButton name={name} id={id} checked={checked} onChange={handleChange} label={label} />
      </S.Content>
    </S.Container>
  );
}

ImageRadio.propTypes = {
  title: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  id: PropTypes.string.isRequired,
  image: PropTypes.element.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default ImageRadio;
