import styled from 'styled-components';

import { Detail, Grid, Label } from 'components/transaction-sidebar/styles';

const DataWrapper = styled.span`
  width: 100%;
`;

const Section = styled.span`
  display: ${props => props.isHidden ? 'none' : 'block'};
`;

export default {
  DataWrapper,
  Section,
  Grid,
  Detail,
  Label,
};
