import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { canOrderCards } from 'api/cards';
import { getDropdownCurrencyOptions, ImageRadio } from 'components';
import { PHYSICAL_CARD_TYPE } from 'constants/card';
import { AccountsContext } from 'contexts/accounts';
import { GlobalLoaderContext } from 'contexts/global-loader';
import CanOrderErrorModal from 'pages/order-cards/components/can-order-error-modal';
import {
  ACCOUNT_SELECTION,
  ACCOUNT_TYPE,
  CARD_TYPE_SELECTION,
  CARD_TYPE_SWITCH,
  CARDS_DETAILS,
  EXISTING_ACCOUNT,
  NEW_ACCOUNT,
} from 'pages/order-cards/constants';

import { OrderCardsSchema as validationSchema } from './schema';
import S from './styles';
import { cardTypeInitialValues } from './constants';

function SelectType({ setCurrentView, setOrderDetailsData }) {
  const { t } = useTranslation();
  const { accounts } = useContext(AccountsContext);
  const [ accountOptions, setAccountOptions ] = useState([]);
  const [ isAccountSelectionDisabled, setIsAccountSelectionDisabled ] = useState(false);
  const { startLoading, endLoading } = useContext(GlobalLoaderContext);
  const [ switchValues, setSwitchValues ] = useState([
    { name: 'Existing account', value: EXISTING_ACCOUNT, selected: true },
    { name: 'New account', value: NEW_ACCOUNT, selected: false },
  ]);
  const [ cardTypeValues, setCardTypeValues ] = useState(cardTypeInitialValues);
  const [ errorModalIsOpen, setErrorModalIsOpen ] = useState(false);
  const [ canOrderError, setCanOrderError ] = useState();

  useEffect(() => {
    if (accounts?.length > 0) {
      const options = getDropdownCurrencyOptions(accounts);
      setAccountOptions(options);
    }
  }, [accounts]);

  const initialValues = {
    [CARD_TYPE_SWITCH]: PHYSICAL_CARD_TYPE,
    [ACCOUNT_TYPE]: EXISTING_ACCOUNT,
    [ACCOUNT_SELECTION]: null,
  };

  const onSubmit = async () => {
    startLoading(startLoading);
    const { canOrder, error } = await canOrderCards({ cardType: values[CARD_TYPE_SWITCH], cardsRequested: 1 });
    endLoading();

    if (error) {
      setCanOrderError(error);
      setErrorModalIsOpen(true);

      return;
    }

    // Do nothing if canOrder is false.
    if (!canOrder) return;

    setOrderDetailsData({
      accountType: values.accountType,
      cardTypeSwitch: values.cardTypeSwitch,
      accountSelection: values.accountSelection?.value,
    });
    setCurrentView(CARDS_DETAILS);
  };

  const {
    handleSubmit,
    setFieldValue,
    values,
    touched,
    errors,
    isSubmitting,
  } = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const handleSwitch = option => {
    const newValues = switchValues.map(c => ({ ...c, selected: c.value === option }));
    setSwitchValues(newValues);
    const selectedType = newValues.find(e => e.selected);
    setFieldValue(ACCOUNT_TYPE, selectedType.value);

    if (newValues[0].selected) {
      setIsAccountSelectionDisabled(false);
    } else {
      setIsAccountSelectionDisabled(true);
      setFieldValue(ACCOUNT_SELECTION, null);
    }
  };

  const handleChange = event => {
    const option = event.target.id;
    const newValues = cardTypeValues.map(c => ({ ...c, checked: c.value === option }));
    setCardTypeValues(newValues);
    const selectedType = newValues.find(e => e.checked);
    setFieldValue(CARD_TYPE_SWITCH, selectedType.value);
  };

  const handleSelect = (selectedOption, { name }) => {
    setFieldValue(name, selectedOption);
  };

  return (
    <S.Container id={CARD_TYPE_SELECTION} name={CARD_TYPE_SELECTION} onSubmit={handleSubmit}>
      <S.SectionHeader>
        {t('orderCards.selectType.title')}
      </S.SectionHeader>
      <S.CardRadioContainer>
        {cardTypeValues.map(card => (
          <S.CardRadio key={card.value}>
            <ImageRadio
              description={card.description}
              label={card.label}
              title={card.title}
              image={card.image}
              name={CARD_TYPE_SWITCH}
              checked={card.checked}
              id={card.value}
              handleChange={handleChange}
            />
          </S.CardRadio>
        ))}
      </S.CardRadioContainer>
      <S.SectionHeader>
        {t('orderCards.selectType.cardsAccount.title')}
      </S.SectionHeader>
      <S.Toggle
        values={switchValues}
        handleChange={handleSwitch}
        id={ACCOUNT_TYPE}
        name={ACCOUNT_TYPE}
      />
      {isAccountSelectionDisabled ? (
        <S.Alert icon={S.InfoIcon} text={t('orderCards.selectType.cardsAccount.createNew')} />
      ) : (
        <S.Select
          placeholder={t('orderCards.selectType.cardsAccount.selectPlaceholder')}
          label={t('orderCards.selectType.cardsAccount.selectLabel')}
          isCurrencyOption
          options={accountOptions}
          id={ACCOUNT_SELECTION}
          name={ACCOUNT_SELECTION}
          value={values[ACCOUNT_SELECTION]}
          onChange={handleSelect}
          isDisabled={isAccountSelectionDisabled || isSubmitting}
          error={!isAccountSelectionDisabled && touched[ACCOUNT_SELECTION] && errors[ACCOUNT_SELECTION]}
          isClearable={false}
        />
      )}

      <S.Separator />
      <S.Button label={t('orderCards.selectType.continueBtn')} category="primary" type="submit" disabled={isSubmitting} isAccountSelection={!isAccountSelectionDisabled} />
      <S.CancelButton />

      {errorModalIsOpen && (
        <CanOrderErrorModal
          isOpen={errorModalIsOpen}
          setIsOpen={setErrorModalIsOpen}
          error={canOrderError}
          isFirstItemInOrderList
        />
      )}
    </S.Container>
  );
}

SelectType.propTypes = {
  setCurrentView: PropTypes.func.isRequired,
  setOrderDetailsData: PropTypes.func.isRequired,
};

export default SelectType;
