import { CircleFlag } from 'react-circle-flags';
import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';

const getIconSize = props => {
  const { isLarger } = props;

  if (isLarger) {
    return `
      width: 1.25rem;
      height: 1.25rem;

      @media ${DEVICE.tablet} {
        width: 2rem;
        height: 2rem;
      }
    `;
  }

  return `
    width: 1.25rem;
    height: 1.25rem;
  `;
};

const getTextSize = props => {
  const { isLarger } = props;

  if (isLarger) {
    return `
      font: ${props.theme.fonts.bodyL};

      @media ${DEVICE.tablet} {
        font: ${props.theme.fonts.h3Bold};
      }
    `;
  }

  return `
    font: ${props.theme.fonts.bodyS};

    @media ${DEVICE.tablet} {
      font: ${props.theme.fonts.bodyM};
    }
  `;
};

const Account = styled.div`
  display: flex;
  align-items: center;
`;

const Name = styled.p`
  margin: 0;
  color: ${props => props.theme.colors.black};
  ${props => getTextSize(props)};
`;

const FlagIcon = styled(CircleFlag)`
  ${props => getIconSize(props)};
  margin-right: 0.5rem;
`;

export default {
  Account,
  FlagIcon,
  Name,
};
