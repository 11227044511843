import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import React, { memo, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { CURRENCY_FLAGS } from 'constants/currencies';
import { navigation } from 'constants/navigation';
import { AccountsContext } from 'contexts/accounts';
import { UserContext } from 'contexts/user';

import S from './styles';

function AccountInfoBlock({ label, currency, name, showNameAsLink }) {
  const { permissions: { canViewAccounts } } = useContext(UserContext);
  const { accounts } = useContext(AccountsContext);
  const [ showFlagIcon, setShowFlagIcon ] = useState(null);
  const [ showAsLink, setShowAsLink ] = useState(null);
  const [ accountId, setAccountId ] = useState(null);

  useEffect(() => {
    const account = accounts.find(w => w.name === name);
    const accId = account?.id;
    setAccountId(accId);
    setShowAsLink(accId && showNameAsLink && canViewAccounts);
    setShowFlagIcon(Boolean(account));
  }, [ name, showAsLink ]);

  if (isNil(showFlagIcon)) return null;

  return (
    <S.Grid>
      <S.Label>
        {label}
      </S.Label>
      <S.Account>
        {showFlagIcon && <S.FlagIcon countryCode={CURRENCY_FLAGS[currency]} />}
        {
          showAsLink ? (
            <Link to={`${navigation.account}/${accountId}`}>
              {name}
            </Link>
          ) : name
        }
      </S.Account>
    </S.Grid>
  );
}

AccountInfoBlock.propTypes = {
  label: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  showNameAsLink: PropTypes.bool,
};

export default memo(AccountInfoBlock);
