import { isBol, isSpark } from 'constants/payment-processor';

export const isSamePaymentProcessor = (fromAccount, toAccount) =>
  fromAccount?.paymentProcessorAdapter === toAccount?.paymentProcessorAdapter;

export const areAccountsBolOrSparkPaymentProcessors = (fromAccount, toAccount) => (
  (isBol(fromAccount?.paymentProcessorAdapter) || isSpark(fromAccount?.paymentProcessorAdapter)) &&
    (isBol(toAccount?.paymentProcessorAdapter) || isSpark(toAccount?.paymentProcessorAdapter))
);
export const areBothSparkPaymentProcessor = (fromAccount, toAccount) =>
  isSpark(fromAccount?.paymentProcessorAdapter) && isSpark(toAccount?.paymentProcessorAdapter);
