import { DropdownCurrencyOption as CurrencyOption } from '@general/intergiro-ui-kit';
import PropTypes from 'prop-types';
import React from 'react';
import { CircleFlag } from 'react-circle-flags';
import upperFirst from 'lodash/upperFirst';

import { CURRENCIES, CURRENCY_FLAGS } from 'constants/currencies';
import { formatAmount } from 'utils/amount';
import { supportedUIPaymentRails } from 'constants/payment-processor';

function getDropdownCurrencyOptions(accounts) {
  return accounts.map(account => {
    const { amount, currency, name, supportedRails } = account;

    let paymentRailLabel = '';
    if (currency === CURRENCIES.EUR) {
      paymentRailLabel = supportedRails
        .filter(rail => supportedUIPaymentRails.includes(rail))
        .map(upperFirst)
        .join(' · ');
    }

    return {
      value: account,
      label: (
        <CurrencyOption
          currencyShort={currency}
          icon={() => <CircleFlag countryCode={CURRENCY_FLAGS[currency]} height="20" />}
          name={name}
          amount={formatAmount(amount)}
          paymentRail={paymentRailLabel}
        />),
    };
  });
}

getDropdownCurrencyOptions.propTypes = {
  accounts: PropTypes.instanceOf(Array).isRequired,
};

export default getDropdownCurrencyOptions;
