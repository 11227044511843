import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import flatten from 'lodash/flatten';
import values from 'lodash/values';
import mapValues from 'lodash/mapValues';
import sumBy from 'lodash/sumBy';
import chunk from 'lodash/chunk';

import { ResolveApiPayment, ResolveModal, TwoFaModal } from 'components';
import { approvalActions } from 'constants/api';
import { navigation } from 'constants/navigation';
import { PaymentCountContext } from 'contexts/payment-count';
import { TransactionContext } from 'contexts/transaction';
import TwoFaProvider from 'contexts/two-fa';
import { transactionActivityShape } from 'local-prop-types';
import { showGeneralToastError, rethrowTwoFaError } from 'utils/errors';
import { getSummarizedTransactionDetails } from 'utils/helpers';
import { getApprovalRequestMethodByAction, showSuccessNotificationByAction } from 'utils/pending-approval';
import { getBeneficiaries } from 'api/beneficiary';
import config from 'config';

import S from './styles';
import PaymentErrorModal from '../payment-error-modal';

function TransactionSummary({
  count,
  isSummaryVisible,
  groupedByCurrency,
  bulkPaymentId,
  isApiPayment,
  paymentSummary,
}) {
  const { t } = useTranslation();
  const { updateCount } = useContext(PaymentCountContext);
  const { setActiveTransactionById } = useContext(TransactionContext);
  const history = useHistory();

  const [ isTotalVisible, setIsTotalVisible ] = useState(false);

  const [ resolveTransactionModalIsOpen, setResolveTransactionModalIsOpen ] = useState(false);
  const [ resolveTransactionAction, setResolveTransactionAction ] = useState(null);
  const [ resolveTransactionData, setResolveTransactionData ] = useState(null);

  const [ showTrustBeneficiaryToggle, setShowTrustBeneficiaryToggle ] = useState(false);
  const [ resolveParams, setResolveParams ] = useState(null);

  const [ twoFaModalIsOpen, setTwoFaModalIsOpen ] = useState(false);
  const [ twoFaData, setTwoFaData ] = useState(null);

  const [ paymentErrorModalIsOpen, setPaymentErrorModalIsOpen ] = useState(false);
  const [ numberOfFailedTransactions, setNumberOfFailedTransactions ] = useState(false);

  const currencies = Object.keys(groupedByCurrency);

  const currencyArray = flatten(values(groupedByCurrency));

  const { approvalRequestIds } = getSummarizedTransactionDetails(currencyArray);
  const isSingleTransaction = approvalRequestIds.length === 1 && currencies?.length === 1;

  function getScaData(challenge) {
    const sca = challenge ? { challenge } : {};

    const amountByCurrency = mapValues(groupedByCurrency, transactions => sumBy(transactions, 'netTransactionAmount'));

    return isSingleTransaction
      ? {
          approvalRequestId: approvalRequestIds[0],
          amount: currencyArray[0].netTransactionAmount,
          currency: currencyArray[0].targetCurrency,
          beneficiary: currencyArray[0].toAccount.name,
          ...sca,
        }
      : {
          approvalRequestIds,
          amountByCurrency,
          beneficiary: t('beneficiary.sca.multipleBeneficiaries'),
          ...sca,
        };
  }

  const toggleTotalVisible = () => {
    setIsTotalVisible(!isTotalVisible);
  };

  function handleOpenBulkResolveTransactionModal(action) {
    return async event => {
      event.stopPropagation();

      if (config.featureFlags.enableBeneficiariesTrustedFlow &&
        [ approvalActions.approve, approvalActions.bulkApprove ].includes(action)) {
        // TODO: Use single request approach (IDE-17235)
        const beneficiaryPromises = chunk(approvalRequestIds, 20)
          .map(ids => getBeneficiaries({ approvalRequestIds: ids, isTrusted: false, limit: 1 }));
        const beneficiaryResults = await Promise.all(beneficiaryPromises);

        setShowTrustBeneficiaryToggle(beneficiaryResults.some(({ numberOfRecords }) => numberOfRecords > 0) &&
          [ approvalActions.approve, approvalActions.bulkApprove ].includes(action));
      }

      if ([ approvalActions.reject, approvalActions.bulkReject ].includes(action)) {
        setShowTrustBeneficiaryToggle(false);
      }

      if (isSingleTransaction) {
        const actionLabel = action === approvalActions.bulkApprove
          ? approvalActions.approve : approvalActions.reject;
        const firstTransaction = currencyArray[0];
        const data = {
          action: actionLabel,
          amount: firstTransaction.debitedAmount,
          createdAt: firstTransaction.createdAt,
          createdByAuthor: firstTransaction.createdBy?.name,
          currency: firstTransaction.targetCurrency,
        };

        setResolveTransactionAction(actionLabel);
        setResolveTransactionModalIsOpen(true);
        setResolveTransactionData(data);

        return;
      }

      setResolveTransactionAction(action);
      setResolveTransactionModalIsOpen(true);
      setResolveTransactionData(null);
    };
  }

  const handleBulkResolveTransaction = ({ isTrusted }) => {
    const data = getScaData();

    return getApprovalRequestMethodByAction(resolveTransactionAction, { ...data, isTrusted })
      .then(({ challenge }) => {
        if (challenge) {
          setTwoFaData(challenge);
          setResolveParams({ isTrusted });
          setTwoFaModalIsOpen(true);

          return;
        }

        updateCount();
        setActiveTransactionById(null);
        showSuccessNotificationByAction(resolveTransactionAction);
      })
      .catch(err => showGeneralToastError(err, { extractFromApi: true }));
  };

  const handleConfirmCode = challenge => {
    const data = getScaData(challenge);

    return getApprovalRequestMethodByAction(resolveTransactionAction, { ...data, ...resolveParams })
      .then(() => {
        updateCount();
        setActiveTransactionById(null);
        setTwoFaModalIsOpen(false);

        showSuccessNotificationByAction(resolveTransactionAction);
      })
      .catch(error => {
        rethrowTwoFaError(error);

        setTwoFaModalIsOpen(false);
        setPaymentErrorModalIsOpen(true);
        setNumberOfFailedTransactions(Object.keys(error.data).length);
      });
  };

  const handleResendCode = async (factor, scaId) => {
    const data = getScaData({ preferredFactor: factor, scaId });

    const response = await getApprovalRequestMethodByAction(resolveTransactionAction, data);

    setTwoFaData(response?.challenge);

    return response;
  };

  const onPaymentApprove = () => {
    history.push(navigation.apiPayments);
  };

  return (
    <S.TransactionSummary data-testid="transaction-summary-wrapper" isSummaryVisible={isSummaryVisible}>
      <S.TransactionSummaryContainer>
        <S.TransactionSummaryTotal>
          <S.TransactionTotalAccordion isTotalVisible={isTotalVisible} onClick={toggleTotalVisible}>
            <S.TransactionSummaryRow isTotalHeading>
              <S.TransactionChevronWrapper>
                <S.ChevronDown />
              </S.TransactionChevronWrapper>
              <S.TransactionSummaryColumn>
                <S.TransactionSummaryRow>
                  <S.TransactionSummaryLeftColumn hasDoubleMargin>
                    <S.TransactionSummaryRow data-testid="transaction-summary-total-count" isTotalRow>
                      <S.TransactionTotalHeading>Total</S.TransactionTotalHeading>
                      {' '}
                      <S.TransactionTotalCount>
                        of
                        {' '}
                        {count}
                        {' '}
                        {count === 1 ? 'payment' : 'payments'}
                      </S.TransactionTotalCount>
                    </S.TransactionSummaryRow>
                  </S.TransactionSummaryLeftColumn>
                  <div />
                </S.TransactionSummaryRow>
                <S.TransactionDropdownRevealedContent isVisible={isTotalVisible}>
                  <S.TransactionSummaryRow data-testid="transaction-summary-without-fees-title">
                    <S.TransactionSummaryLeftColumn>
                      <S.TransactionSummarySubHeading>Excluding fees</S.TransactionSummarySubHeading>
                    </S.TransactionSummaryLeftColumn>
                  </S.TransactionSummaryRow>
                  <S.TransactionSummaryRow data-testid="transaction-summary-fees-title">
                    <S.TransactionSummaryLeftColumn>
                      <S.TransactionSummarySubHeading>Fees</S.TransactionSummarySubHeading>
                    </S.TransactionSummaryLeftColumn>
                  </S.TransactionSummaryRow>
                </S.TransactionDropdownRevealedContent>
              </S.TransactionSummaryColumn>
              <S.TransactionsByCurrenciesContainer>
                <TransactionsByCurrencies isTotalVisible={isTotalVisible}
                  transactions={groupedByCurrency}
                  paymentSummary={paymentSummary}
                />
              </S.TransactionsByCurrenciesContainer>
            </S.TransactionSummaryRow>
          </S.TransactionTotalAccordion>
        </S.TransactionSummaryTotal>

        <S.TransactionButtonContainer>
          {isApiPayment ? (
            <ResolveApiPayment
              totalAmount={`${paymentSummary?.amount} ${Object.keys(groupedByCurrency)[0]}`}
              bulkPaymentId={bulkPaymentId}
              approveButton={<S.TransactionApproveButton label="Approve"/>}
              rejectButton={<S.TransactionRejectButton label="Reject" />}
              onSuccess={onPaymentApprove}
            />
          ) : (
            <>
              <S.TransactionApproveButton
                label="Approve selected"
                onClick={handleOpenBulkResolveTransactionModal(approvalActions.bulkApprove)}
              />
              <S.TransactionRejectButton
                label="Reject selected"
                onClick={handleOpenBulkResolveTransactionModal(approvalActions.bulkReject)}
              />
            </>
          )}
        </S.TransactionButtonContainer>
      </S.TransactionSummaryContainer>

      {/* Modals */}
      {resolveTransactionModalIsOpen && (
        <ResolveModal
          isOpen={resolveTransactionModalIsOpen}
          setIsOpen={setResolveTransactionModalIsOpen}
          onSubmit={handleBulkResolveTransaction}
          action={resolveTransactionAction}
          data={resolveTransactionData}
          showTrustBeneficiaryToggle={showTrustBeneficiaryToggle}
        />
      )}
      {twoFaModalIsOpen && (
        <TwoFaProvider twoFaData={twoFaData}>
          <TwoFaModal
            isOpen={twoFaModalIsOpen}
            setIsOpen={setTwoFaModalIsOpen}
            onSubmit={handleConfirmCode}
            onResend={handleResendCode}
            challenge={twoFaData}
          />
        </TwoFaProvider>
      )}
      {paymentErrorModalIsOpen && (
        <PaymentErrorModal isOpen={paymentErrorModalIsOpen} numberOfFailedTransactions={numberOfFailedTransactions} />
      )}
    </S.TransactionSummary>
  );
}

function TransactionsByCurrencies({ isTotalVisible, transactions, paymentSummary }) {
  return Object.entries(transactions).map(transaction => (
    <TransactionByCurrency
      currency={transaction[0]}
      key={uuidv4()}
      isTotalVisible={isTotalVisible}
      transaction={transaction[1]}
      paymentSummary={paymentSummary}
    />
  ));
}

function TransactionByCurrency({ currency, isTotalVisible, transaction, paymentSummary }) {
  const { amount, netSummary, totalFees } = paymentSummary || getSummarizedTransactionDetails(transaction);

  return (
    <S.TransactionSummaryMultiCurrencyContainer>
      <S.TransactionSummaryAmount data-testid="transaction-summary-amount" isBold hasDoubleMargin>
        -
        {' '}
        {amount}
        {' '}
        {currency}
      </S.TransactionSummaryAmount>
      <S.TransactionDropdownRevealedContent isVisible={isTotalVisible}>
        <S.TransactionSummaryAmount data-testid="transaction-net-summary-amount">
          -
          {' '}
          {netSummary}
          {' '}
          {currency}
        </S.TransactionSummaryAmount>
        <S.TransactionSummaryAmount data-testid="transaction-fees-summary-amount">
          -
          {' '}
          {totalFees}
          {' '}
          {currency}
        </S.TransactionSummaryAmount>
      </S.TransactionDropdownRevealedContent>
    </S.TransactionSummaryMultiCurrencyContainer>
  );
}

TransactionSummary.propTypes = {
  count: PropTypes.number,
  isSummaryVisible: PropTypes.bool,
  groupedByCurrency: PropTypes.oneOfType([ PropTypes.instanceOf(Object), PropTypes.instanceOf(Array) ]),
  bulkPaymentId: PropTypes.string,
  isApiPayment: PropTypes.bool,
  paymentSummary: PropTypes.instanceOf(Object),
};

TransactionByCurrency.propTypes = {
  currency: PropTypes.string,
  isTotalVisible: PropTypes.bool,
  transaction: PropTypes.arrayOf(PropTypes.shape(transactionActivityShape)),
  paymentSummary: PropTypes.instanceOf(Object),
};

export default TransactionSummary;
