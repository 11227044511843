import { Tabs } from '@general/intergiro-ui-kit';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';

import { Security } from 'pages/profile/components';
import { profileTypes } from 'local-prop-types';
import { UserContext } from 'contexts/user';
import { UserCard, UserDetails as Details } from 'components';

import S from './styles';
import { userProfileFieldsList } from './list';
import { mapUserDetails } from './helpers';

const profile = '/profile';
const security = '/profile/security';

function Profile() {
  const { path } = useRouteMatch();
  const [ activeTab, setActiveTab ] = useState(path === security ? security : profile);
  const history = useHistory();
  const isProfile = activeTab === profile;
  const isSecurity = activeTab === security;
  const { user, setUser } = useContext(UserContext);
  const [ fullName, setFullName ] = useState();

  useEffect(() => {
    if (!user) return;

    setFullName(`${user.firstName} ${user.lastName}`);
  }, [user]);

  const onProfileTab = useCallback(() => {
    setActiveTab(profile);
    history.push(profile);
  }, []);

  const onSecurityTab = useCallback(() => {
    setActiveTab(security);
    history.push(security);
  }, []);

  function renderTab() {
    return isProfile ? (
      <UserDetails user={user} fullName={fullName} />
    ) : (
      <Security user={user} setUser={setUser} />
    );
  }

  if (!user) return null;

  return (
    <>
      <S.Header data-testid="heading-name">
        <S.Title>
          {fullName}
        </S.Title>
      </S.Header>
      <>
        <Tabs.Container name="tabs">
          <S.TabsHead id="profile" isActive={isProfile} handleChange={onProfileTab}>
            Profile details
          </S.TabsHead>
          <S.TabsHead id="security" isActive={isSecurity} handleChange={onSecurityTab}>
            Security
          </S.TabsHead>
        </Tabs.Container>
        <Tabs.Panel>
          {renderTab()}
        </Tabs.Panel>
      </>
    </>
  );
}

function UserDetails({ user, fullName }) {
  const userData = mapUserDetails(user);

  return (
    <S.Wrapper>
      <UserCard userName={fullName} status={user.status} email={user.email} />
      <Details data={userData} fieldsList={userProfileFieldsList} />
    </S.Wrapper>
  );
}

UserDetails.propTypes = {
  user: profileTypes,
  fullName: PropTypes.string,
  email: PropTypes.string,
};

export default Profile;
