import bff from 'utils/bff';

export const getRoles = async () => {
  const { data: roles } = await bff.role.getRoles();

  return roles;
};

export const getRole = async id => {
  const { data: role } = await bff.role.getRole({}, { id });

  return role;
};

export const getPermissionCategories = async () => {
  const { data: permissionCategories } = await bff.role.getPermissionCategories();

  return permissionCategories;
};

export const createRole = async data => {
  const { data: role } = await bff.role.createRole(data);

  return role;
};

export const updateRole = async (id, data) => {
  const { data: role } = await bff.role.updateRole(data, { id });

  return role;
};

export const deleteRole = async id => {
  const { data: role } = await bff.role.deleteRole({}, { id });

  return role;
};
