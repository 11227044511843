import styled from 'styled-components';

import { ReactComponent as AppStoreAction } from 'assets/icons/app-store-action.svg';
import { DEVICE } from 'constants/breakpoints';

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentText = styled.div`
  font: ${props => props.theme.fonts.bodyLBold};
  color: ${props => props.theme.colors.greyMedium};
`;

const ActionRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;

  @media ${DEVICE.tablet} {
    justify-content: flex-start;
    flex-direction: row;
    padding: 0 0.625rem;
  }
`;

const Action = styled.a`
  cursor: pointer;
`;

const ContentBackground = styled.div`
  height: 18.125rem;
  background: ${props => props.theme.colors.greyLighter};
  margin-bottom: 1.25rem;
  border-radius: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Background = styled.img`
  width: 100%;
`;

const AppStore = styled(AppStoreAction)`
  margin: 0;
`;

export default {
  Content,
  ContentText,
  ActionRow,
  Action,
  AppStore,
  ContentBackground,
  Background,
};
