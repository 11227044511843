import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { WizardLayout } from 'layouts';
import { ViewContext } from 'contexts/view';
import { navigation } from 'constants/navigation';

import ApiCreationForm from './components/api-creation-form';
import ApiCreationResult from './components/api-creation-result';
import { layoutConfig, viewMap } from './constants';

function APICreationPage() {
  const [ currentView, setCurrentView ] = useState(viewMap.creationForm);
  const [ apiKey, setApiKey ] = useState('');
  const { t } = useTranslation();
  const history = useHistory();

  const triggerView = () => {
    history.push(navigation.api);
  };

  const getPageContent = () => {
    switch (currentView) {
      case viewMap.creationForm:
        return <ApiCreationForm setView={setCurrentView} setApiKey={setApiKey} />;
      case viewMap.result:
        return <ApiCreationResult apiKey={apiKey} />;
      default:
        return null;
    }
  };

  const viewValues = useMemo(() => ({ currentView, triggerView }), [currentView]);

  return (
    <ViewContext.Provider value={viewValues}>
      <WizardLayout title={t('createApi.title')} {...layoutConfig[currentView]} handleCancel={triggerView}>
        {getPageContent()}
      </WizardLayout>
    </ViewContext.Provider>
  );
}

export default APICreationPage;
