import { Tabs } from '@general/intergiro-ui-kit';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ViewTypeSwitch, WithNavbarHeader } from 'components';
import { GRID_VIEW } from 'constants/viewType';
import AccountsProvider from 'contexts/accounts';
import { useView } from 'hooks';
import { getAccountsListView, setAccountsListView } from 'utils/storage';
import { UserContext } from 'contexts/user';

import S from './styles';
import AccountsList from './components/accounts-list';
import FilterMobile from './components/filter-bar-mobile';
import VisibleAccountsSwitch from './components/visible-accounts-switch';

function Accounts() {
  const { permissions: { canManageAccounts } } = useContext(UserContext);
  const initialViewType = getAccountsListView() || GRID_VIEW;
  const { isDesktop } = useView();

  const [ viewType, setViewType ] = useState(initialViewType);
  const [ showHiddenAccounts, setShowHiddenAccounts ] = useState(false);
  const [ isMobileFiltersOpen, setIsMobileFiltersOpen ] = useState(false);

  const { t } = useTranslation();

  const onChangeViewType = value => {
    setViewType(value);
    setAccountsListView(value);
  };

  const onChangeShowHiddenAccounts = showHidden => {
    setShowHiddenAccounts(showHidden);
  };

  useEffect(() => {
    if (isDesktop) {
      setViewType(getAccountsListView());
    } else {
      setViewType(GRID_VIEW);
    }
  }, [isDesktop]);

  return (
    <AccountsProvider>
      <WithNavbarHeader title={t('accounts.title')} />
      <S.TabsWrapper>
        <Tabs.Container name="tabs">
          <S.TabsHead id={t('accounts.tabTitle')} isActive handleChange={() => { }}>
            {t('accounts.tabTitle')}
          </S.TabsHead>
          <S.SwitchesWrapper>
            {
              canManageAccounts && (
                <VisibleAccountsSwitch
                  onChange={onChangeShowHiddenAccounts}
                  showHiddenAccounts={showHiddenAccounts}
                />
              )
            }
            <ViewTypeSwitch viewType={viewType} onChange={onChangeViewType} />
          </S.SwitchesWrapper>
        </Tabs.Container>
      </S.TabsWrapper>
      <S.MobileActionsWrapper>
        <S.FiltersWrapper onClick={() => setIsMobileFiltersOpen(true)}>
          <S.FiltersIcon />
          {t('accounts.filters.title')}
        </S.FiltersWrapper>
      </S.MobileActionsWrapper>
      <Tabs.Panel>
        <AccountsList isGrid={viewType === GRID_VIEW} showHiddenAccounts={showHiddenAccounts}/>
      </Tabs.Panel>
      <FilterMobile
        isMobileFiltersOpen={isMobileFiltersOpen}
        setIsMobileFiltersOpen={setIsMobileFiltersOpen}
        showHiddenAccounts={showHiddenAccounts}
        onChangeShowHiddenAccounts={onChangeShowHiddenAccounts}
      />
    </AccountsProvider>
  );
}

export default Accounts;
