import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { CircleFlag } from 'react-circle-flags';
import { useTranslation } from 'react-i18next';

import { apiPaymentShape, mappedTransactionShape } from 'local-prop-types';
import { CURRENCY_FLAGS } from 'constants/currencies';
import { PaymentList } from 'components';
import { groupTransactionsByAccounts } from 'utils/transactions';

import S from './styles';

function PaymentListGroup({ payments, handlePaymentClick, activePayment, isGroupPayments }) {
  const { t } = useTranslation();
  const groupedPayments = useMemo(() => Object.entries(groupTransactionsByAccounts(payments)), [payments]);

  return (
    <>
      {groupedPayments.map(([ id, paymentList ]) => (
        <section key={id}>
          <S.PaymentHeading>
            <S.PaymentFrom>
              {t('payments.apiPayments.from')}
              {' '}
              <S.PaymentFromBold>
                {paymentList.name}
              </S.PaymentFromBold>
              {' '}
              <S.PaymentFromLength>
                (
                {paymentList.length}
                )
              </S.PaymentFromLength>
            </S.PaymentFrom>
            <CircleFlag height={32} countryCode={CURRENCY_FLAGS[paymentList.currency]} />
          </S.PaymentHeading>
          <PaymentList
            payments={paymentList}
            handlePaymentClick={handlePaymentClick}
            activePayment={activePayment}
            isGroupPayments={isGroupPayments}
          />
        </section>
      ))}
    </>
  );
}

PaymentListGroup.propTypes = {
  payments: PropTypes.oneOfType([
    PropTypes.arrayOf(apiPaymentShape),
    PropTypes.arrayOf(PropTypes.shape(mappedTransactionShape)),
  ]),
  handlePaymentClick: PropTypes.func.isRequired,
  activePayment: PropTypes.oneOfType([ apiPaymentShape, PropTypes.shape(mappedTransactionShape) ]),
  isGroupPayments: PropTypes.bool,
};

export default PaymentListGroup;
