import { ErrorCodes } from '@general/error-codes';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import { AccountsContext } from 'contexts/accounts';
import { showGeneralToastError } from 'utils/errors';

import { errorModalNameMap } from './constants';
import InsufficientFundsErrorModal from './insufficient-funds';
import LimitReachedErrorModal from './limit-reached';
import UnableAddCardErrorModal from './unable-add-card';

function CanOrderErrorModal({ isOpen, setIsOpen, error, isFirstItemInOrderList }) {
  const { accounts } = useContext(AccountsContext);
  const [ errorCode, setErrorCode ] = useState();
  const [ mainAccount, setMainAccount ] = useState();

  useEffect(() => {
    // Since GET accounts without specified sortOrder property returns accounts sorted by created date.
    // Thus, the MAIN account will be the first account in the list.
    const mainAccountValue = accounts[0];

    if (!error || !mainAccountValue) return;

    setMainAccount(mainAccountValue);

    // Case when not enough funds to add new or an additional card.
    if (error.default?.code === ErrorCodes.insufficientFunds) {
      setErrorCode(isFirstItemInOrderList
        ? errorModalNameMap.NEW_CARD_INSUFFICIENT_FUNDS
        : errorModalNameMap.ADDITIONAL_CARD_INSUFFICIENT_FUNDS);

      return;
    }

    // Case when card issuing limit is reached.
    if (error.default?.code === ErrorCodes.cardsLimitExceeded) {
      setErrorCode(errorModalNameMap.LIMIT_REACHED);

      return;
    }

    showGeneralToastError(error);
    setIsOpen(false);
  }, []);

  if (!isOpen) return null;

  switch (errorCode) {
    case errorModalNameMap.NEW_CARD_INSUFFICIENT_FUNDS:
      return <InsufficientFundsErrorModal isOpen={isOpen} setIsOpen={setIsOpen} account={mainAccount} />;
    case errorModalNameMap.ADDITIONAL_CARD_INSUFFICIENT_FUNDS:
      return <UnableAddCardErrorModal isOpen={isOpen} setIsOpen={setIsOpen} account={mainAccount} />;
    case errorModalNameMap.LIMIT_REACHED:
      return <LimitReachedErrorModal isOpen={isOpen} setIsOpen={setIsOpen} />;
    default:
      return null;
  }
}

CanOrderErrorModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  error: PropTypes.instanceOf(Object).isRequired,
  isFirstItemInOrderList: PropTypes.bool.isRequired,
};

export default CanOrderErrorModal;
