import PropTypes from 'prop-types';
import React, { useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { deleteRole } from 'api/roles';
import { showGeneralToastError } from 'utils/errors';
import { navigation } from 'constants/navigation';
import { UserContext } from 'contexts/user';
import { DotsActionDropdown } from 'components';

import S from './styles';
import ConfirmationModal from '../confirmation-modal';

function RoleItem({ role, onRemoveRole, openToTop }) {
  const history = useHistory();
  const { t } = useTranslation();
  const { permissions: { canUpdateUserRole, canDeleteUserRole } } = useContext(UserContext);

  const [ confirmationModalIsOpen, setConfirmationModalIsOpen ] = useState(false);

  const isEditButtonDisabled = !canUpdateUserRole || role.isSystem;
  const isDeleteButtonDisabled = !canDeleteUserRole || role.isSystem;

  const handleActionDropdownClick = e => {
    e.stopPropagation();
  };

  const handleEditRole = useCallback(event => {
    event.stopPropagation();

    history.push(`${navigation.roles}/${role.id}/edit`);
  }, []);

  const handleDeleteRole = useCallback(event => {
    event.stopPropagation();

    setConfirmationModalIsOpen(true);
  }, []);

  const handleDeleteConfirm = useCallback(async () => {
    try {
      await deleteRole(role.id);
      onRemoveRole(role.id);
      toast.success(t('role.delete.successMessage'));
    } catch (error) {
      showGeneralToastError(error);
    } finally {
      setConfirmationModalIsOpen(false);
    }
  }, []);

  return (
    <>
      <S.Role onClick={handleEditRole}>
        <S.Title>
          {role.name}
        </S.Title>
        <S.Actions onClick={handleActionDropdownClick}>
          <DotsActionDropdown
            openToTop={openToTop}
            list={[
              ...isEditButtonDisabled ? [
                <S.Action data-testid="role-edit-btn" onClick={handleEditRole}>
                  {t('roles.actions.view')}
                </S.Action>,
              ] : [],
              ...!isEditButtonDisabled ? [
                <S.Action data-testid="role-edit-btn" onClick={handleEditRole}>
                  {t('roles.actions.edit')}
                </S.Action>,
              ] : [],
              ...!isDeleteButtonDisabled ? [
                <S.Action data-testid="role-delete-btn" red onClick={handleDeleteRole}>
                  {t('roles.actions.delete')}
                </S.Action>,
              ] : [],
            ]}
          />
        </S.Actions>
      </S.Role>
      {confirmationModalIsOpen && (
        <ConfirmationModal
          isOpen={confirmationModalIsOpen}
          setIsOpen={setConfirmationModalIsOpen}
          onSubmit={handleDeleteConfirm}
        />
      )}
    </>
  );
}

RoleItem.propTypes = {
  role: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    isSystem: PropTypes.bool,
  }),
  onRemoveRole: PropTypes.func,
  openToTop: PropTypes.bool,
};

export default RoleItem;
