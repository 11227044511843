import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';

const Container = styled.div`
  align-items: flex-start;
  flex-direction: column;
  border-radius: 0.5rem;
  border: 0.0625rem solid ${props => props.theme.colors.greyBright};
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.1875rem;
  margin-bottom: 1rem;
  width: 100%;

  @media ${DEVICE.tablet} {
    flex-direction: row;
    align-items: center;
    padding: 1.1875rem;
    justify-content: flex-start;
  }
`;

const IconContainer = styled.div`
  flex-shrink: 0;
  margin-bottom: 0.375rem;

  @media ${DEVICE.tablet} {
    margin-bottom: 0;
    margin-right: 1.25rem;
  }
`;

const InfoContainer = styled.div`
  font: ${props => props.theme.fonts.bodyXs};
`;

export default {
  Container,
  InfoContainer,
  IconContainer,
};
