import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useIntercom } from 'react-use-intercom';

import { showGeneralToastError } from 'utils/errors';
import { ReactComponent as QuestionMarkIcon } from 'assets/icons/question-mark-icon.svg';
import { navigation } from 'constants/navigation';
import { UserContext } from 'contexts/user';
import config from 'config';
import { signOut } from 'utils/auth';
import { redirectToLogin } from 'utils/location';
import { NavigationContext } from 'contexts/navigation';
import { MobileAppDownloadModal } from 'components';

import { DataTestIds } from '../testing';
import S from './styles';

function Header({ backButton, isMobile, isOpen, toggleOpen }) {
  const [ avatar, setAvatar ] = useState(null);
  const [ mobileAppModalShown, setMobileAppModalShown ] = useState(false);

  const { user, permissions } = useContext(UserContext);
  const { backButtonLabel } = useContext(NavigationContext);

  const history = useHistory();
  const helpDropdownRef = useRef(null);
  const profileDropdownRef = useRef(null);

  const { shutdown } = useIntercom();

  const { t } = useTranslation();

  const logout = useCallback(async () => {
    profileDropdownRef.current.closeDropdown();

    try {
      await signOut();
      shutdown();
    } catch (error) {
      showGeneralToastError(error);
    } finally {
      redirectToLogin();
    }
  }, []);

  function getAvatar() {
    const name = get(user, 'firstName');
    const surname = get(user, 'lastName');
    const fullName = name && surname ? `${name} ${surname}` : '';

    return <S.Avatar name={fullName} small alt={fullName} />;
  }

  useEffect(() => {
    setAvatar(getAvatar());
  }, []);

  function getBackButton() {
    if (backButton) {
      return (
        <S.BackButton to={backButton.link} data-testid={DataTestIds.backButton}>
          <S.BackIcon onClick={() => history.push(backButton.link)} />
          {backButtonLabel ? (
            <S.CustomBackButtonLabel>
              {backButtonLabel}
            </S.CustomBackButtonLabel>
          ) : (
            <S.BackButtonLabel>
              {backButton.label}
            </S.BackButtonLabel>
          )}
        </S.BackButton>
      );
    }

    return (
      <S.HamburgerWrapper data-intercom-target={isMobile && 'menu_anchor'} isOpen={isOpen} onClick={toggleOpen}>
        <S.Hamburger />
      </S.HamburgerWrapper>
    );
  }

  const handleProfileDropdownActionClick = useCallback(() => {
    profileDropdownRef.current.closeDropdown();
  }, []);

  const handleHelpDropdownItemClick = () => {
    helpDropdownRef.current.closeDropdown();
  };

  const handleMobileAppModalOpen = () => {
    setMobileAppModalShown(true);
  };

  const name = get(user, 'firstName');
  const surname = get(user, 'lastName');
  const email = get(user, 'email', '');
  const fullName = name && surname ? `${name} ${surname}` : '';

  return (
    <>
      {getBackButton()}
      <S.ProfileAvatarWrapper data-intercom-target="navbar-item-anchor-avatar">
        <S.DropdownLink label={(
          <S.QuestionMarkWrapper>
            <QuestionMarkIcon />
          </S.QuestionMarkWrapper>
          )}
          ref={helpDropdownRef}
        >
          <S.HelpDropdownWrapper>
            <S.DropdownAction data-testid={DataTestIds.helpCenterLink} href={config.helpCenterUrl} target="_blank" onClick={handleHelpDropdownItemClick}>
              {t('navigation.helpNavbar.helpCenter')}
            </S.DropdownAction>
            <S.DropdownAction data-testid={DataTestIds.faqsLink} href={config.faqsUrl} target="_blank" onClick={handleHelpDropdownItemClick}>
              {t('navigation.helpNavbar.faq')}
            </S.DropdownAction>
          </S.HelpDropdownWrapper>
        </S.DropdownLink>
        <S.DropdownLink label={avatar} ref={profileDropdownRef}>
          <S.ProfileDropdownWrapper>
            <S.AccountInformationWrapper>
              <strong>
                {fullName}
              </strong>
              <S.Email title={email}>
                {email}
              </S.Email>
            </S.AccountInformationWrapper>
            <S.DropdownLinkAction
              data-testid={DataTestIds.profileLink}
              to={navigation.profile}
              onClick={handleProfileDropdownActionClick}
            >
              {t('navigation.helpNavbar.profile')}
            </S.DropdownLinkAction>
            {permissions.canViewCompanyDetails && (
              <S.DropdownLinkAction
                data-testid={DataTestIds.companyLink}
                to={navigation.company}
                onClick={handleProfileDropdownActionClick}
              >
                {t('navigation.helpNavbar.company')}
              </S.DropdownLinkAction>
            )}
            <S.LinkSeparator />
            <S.DropdownItem
              data-testid={DataTestIds.mobileApp}
              onClick={handleMobileAppModalOpen}
            >
              {t('navigation.helpNavbar.getMobileApp')}
            </S.DropdownItem>
            <S.SignOutLink data-testid={DataTestIds.logoutLink} onClick={logout}>
              {t('navigation.helpNavbar.logout')}
            </S.SignOutLink>
          </S.ProfileDropdownWrapper>
        </S.DropdownLink>
      </S.ProfileAvatarWrapper>
      <MobileAppDownloadModal isOpen={mobileAppModalShown} setIsOpen={setMobileAppModalShown} />
    </>
  );
}

Header.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  backButton: PropTypes.shape({
    link: PropTypes.string,
    label: PropTypes.string,
  }),
};

export default Header;
