import { Dropdown } from '@general/intergiro-ui-kit';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

import { Card } from 'components';
import {
  ADDRESS_FIELD,
  CARD_HOLDER_FIELD,
  CARD_TYPE_SWITCH,
  CARDHOLDER_REQUIRED_ERROR_MESSAGE,
  DISPLAY_NAME_FIELD,
  EXPIRY,
  EXPIRY_MONTH,
  EXPIRY_YEAR,
  MONTH_NUMBERS,
  sidebarMode,
  deliveryAddressTypeMap,
} from 'pages/order-cards/constants';
import {
  getFormattedAddress,
  getDeliveryAddress,
  getExpiryYearOptions,
  getLastTwoDigitsOfYear,
  getMinAndMaxExpirationDate,
  shouldDisableDateOption,
} from 'pages/order-cards/utils';
import { cardStatusesMap, PHYSICAL_CARD_TYPE } from 'constants/card';
import { corporationShape, orderCardShape } from 'local-prop-types';
import { MONTH, XXI_CENTURY, YEAR } from 'constants/date-time';
import { getMonthInTwoDigits } from 'utils/card';

import { CardDetailsSchema as validationSchema } from './schema';
import ChangeAddressModal from '../change-address-modal';
import S from './styles';

function CardDetailsItem({
  cards,
  selectedCard,
  corporation,
  deleteItem,
  displayErrors,
  orderDetailsData,
  persons,
  updateItem,
  confirmCard,
  isFirstCardAddressElement,
  updateAddress,
  sidebarState,
  handleSidebarClose,
  addressModalIsOpen,
  setAddressModalIsOpen,
}) {
  const isCardCreation = sidebarState === sidebarMode.addNew;
  const isCardEdition = sidebarState === sidebarMode.edit;
  const { expiry: expiration, id, cardHolder, displayName, address } = selectedCard;
  const { t } = useTranslation();

  const [ deliveryAddress, setDeliveryAddress ] = useState(address);
  const [ flipped, setFlipped ] = useState(false);
  const [ cardHolderAddress, setCardHolderAddress ] = useState(null);

  const initialValues = {
    [CARD_HOLDER_FIELD]: null,
    [DISPLAY_NAME_FIELD]: '',
    [EXPIRY_MONTH]: expiration?.[MONTH],
    [EXPIRY_YEAR]: expiration?.[YEAR],
    [ADDRESS_FIELD]: null,
  };

  const { handleSubmit, setFieldValue, values, touched, errors, setFieldError, setFieldTouched }
    = useFormik({
      initialValues,
      validationSchema,
    });

  const cardDetails = useMemo(() => ({
    id,
    currency: orderDetailsData.currency,
    type: orderDetailsData[CARD_TYPE_SWITCH],
    account: {
      name: orderDetailsData.displayName,
    },
    cardholder: values[CARD_HOLDER_FIELD],
    expiry: expiration,
    status: cardStatusesMap.active,
    companyName: corporation.name,
  }), [ expiration, orderDetailsData, values, corporation ]);

  useEffect(() => {
    const cardHolderData = values[CARD_HOLDER_FIELD];

    cardHolderData && updateUserDeliveryAddress(values[CARD_HOLDER_FIELD]);
    setCardHolderAddress(getFormattedAddress(cardHolderData, deliveryAddressTypeMap.cardholderAddress));
  }, [values[CARD_HOLDER_FIELD]]);

  useEffect(() => {
    if (!touched[CARD_HOLDER_FIELD]) {
      setFieldError([CARD_HOLDER_FIELD], CARDHOLDER_REQUIRED_ERROR_MESSAGE);
    }

    updateItem(id, 'errors', errors);
  }, [errors]);

  useEffect(() => {
    cardHolder && onChange(CARD_HOLDER_FIELD, cardHolder);
    displayName && onChange(DISPLAY_NAME_FIELD, displayName);
    if (expiration) {
      onChange(EXPIRY_MONTH, expiration[MONTH]);
      onChange(EXPIRY_YEAR, expiration[YEAR]);
    }
  }, [cardHolder]);

  useEffect(() => {
    displayName && onChange(DISPLAY_NAME_FIELD, displayName);
  }, [displayName]);

  useEffect(() => {
    if (!expiration) return;

    onChange(EXPIRY_MONTH, expiration[MONTH]);
    onChange(EXPIRY_YEAR, expiration[YEAR]);
  }, [expiration]);

  const getPersonsOptions = () =>
    persons.map(person => ({ ...person, label: `${person.firstName} ${person.lastName}` }));

  const expirySelection = async (fieldName, date) => {
    const newDate = fieldName === EXPIRY_YEAR ? String(XXI_CENTURY + date) : date;

    await setFieldValue(fieldName, newDate);
    setFlipped(true);
    await setFieldTouched(fieldName, true);

    const expiry =
      fieldName === EXPIRY_YEAR
        ? { year: newDate, month: values[EXPIRY_MONTH] }
        : { year: values[EXPIRY_YEAR], month: newDate };

    updateItem(id, EXPIRY, expiry);
  };

  const onChange = async (fieldName, value = '') => {
    updateItem(id, fieldName, value);

    await setFieldValue(fieldName, value);
    await setFieldTouched(fieldName, true);
    if (fieldName === CARD_HOLDER_FIELD) setFlipped(true);
  };

  const onChangeDeliveryAddress = async value => {
    setDeliveryAddress(value);

    await setFieldValue(ADDRESS_FIELD, value);
    await setFieldTouched(ADDRESS_FIELD, !!value);

    if (!isCardCreation) {
      updateAddress(value, cardHolder);
    }
  };

  const updateUserDeliveryAddress = newCardHolder => {
    if (!newCardHolder) return;

    const card = cards.find(item => item.cardHolder.id === newCardHolder.id && item.id !== selectedCard.id );
    const existingAddress = card?.address || selectedCard.address;

    const formattedAddress = existingAddress
      ? getFormattedAddress(existingAddress, existingAddress.type)
      : getFormattedAddress(corporation.address, deliveryAddressTypeMap.companyAddress);

    onChangeDeliveryAddress(formattedAddress);
  };

  const handleError = fieldName => (displayErrors ? errors[fieldName] : touched[fieldName] && errors[fieldName]);

  const { maxExpirationYear } = useCallback(getMinAndMaxExpirationDate(), []);

  const getMonthsOptions = () => {
    const expiryYear = getLastTwoDigitsOfYear(values[EXPIRY_YEAR]);

    return MONTH_NUMBERS.map(month => {
      const isDisabled = shouldDisableDateOption(month, expiryYear);
      const twoDigitMonth = getMonthInTwoDigits(month);

      return {
        isDisabled,
        value: twoDigitMonth,
        label: (
          <S.DateElement isDisabled={isDisabled}>
            {twoDigitMonth}
          </S.DateElement>
        ),
      };
    });
  };

  const getYearOptions = () => {
    const years = getExpiryYearOptions(maxExpirationYear);
    const expiryMonth = Number(values[EXPIRY_MONTH]);

    return years.map(year => {
      const isDisabled = shouldDisableDateOption(expiryMonth, year);

      return {
        isDisabled,
        value: year,
        label: (
          <S.DateElement isDisabled={isDisabled}>
            {year}
          </S.DateElement>
        ),
      };
    });
  };

  return (
    <>
      {isCardCreation && <S.CloseIcon onClick={handleSidebarClose} />}
      <S.Form onSubmit={handleSubmit}>
        <S.Container>
          <S.CardContainer>
            <Card
              card={cardDetails}
              flipped={flipped}
              isNewCard
            />
          </S.CardContainer>
          <S.InputsContainer>
            {isCardEdition && (
              <S.DeleteContainer onClick={() => deleteItem(id)}>
                <S.DeleteIcon />
                <S.DeleteLabel>
                  {t('orderCards.cardDetails.removeCard')}
                </S.DeleteLabel>
              </S.DeleteContainer>
            )}
            <Dropdown
              id={CARD_HOLDER_FIELD}
              name={CARD_HOLDER_FIELD}
              onChange={value => {
                onChange(CARD_HOLDER_FIELD, value);
              }}
              options={getPersonsOptions()}
              value={values[CARD_HOLDER_FIELD] || {}}
              label={t('orderCards.cardDetails.cardholderName')}
              error={handleError(CARD_HOLDER_FIELD)}
              isClearable={false}
            />
            <S.Input
              id={`${DISPLAY_NAME_FIELD}-${id}`}
              label={t('orderCards.cardDetails.cardName')}
              name={DISPLAY_NAME_FIELD}
              onChange={e => onChange(DISPLAY_NAME_FIELD, e.target.value)}
              onClear={() => onChange(DISPLAY_NAME_FIELD)}
              value={values[DISPLAY_NAME_FIELD]}
              error={handleError(DISPLAY_NAME_FIELD)}
              type="text"
            />
            <S.MultipleInputContainer>
              <S.ExpirationContainer>
                <S.SmallInputContainer>
                  <Dropdown
                    id={EXPIRY_MONTH}
                    name={EXPIRY_MONTH}
                    onChange={({ value }) => expirySelection(EXPIRY_MONTH, value)}
                    options={getMonthsOptions()}
                    value={{
                      value: expiration.month,
                      label: expiration.month,
                    }}
                    label={t('orderCards.cardDetailsItem.expirationField.month')}
                    error={handleError(EXPIRY_MONTH)}
                    isClearable={false}
                    isOptionDisabled={option => option.isDisabled === true}
                    isSearchable={false}
                  />
                </S.SmallInputContainer>
                <S.MonthDateSlash>/</S.MonthDateSlash>
                <S.SmallInputContainer>
                  <Dropdown
                    id={EXPIRY_YEAR}
                    name={EXPIRY_YEAR}
                    onChange={({ value }) => expirySelection(EXPIRY_YEAR, value)}
                    options={getYearOptions()}
                    value={{
                      value: getLastTwoDigitsOfYear(expiration.year),
                      label: getLastTwoDigitsOfYear(expiration.year),
                    }}
                    label={t('orderCards.cardDetailsItem.expirationField.year')}
                    error={handleError(EXPIRY_YEAR)}
                    isClearable={false}
                    isOptionDisabled={option => option.isDisabled === true}
                    isSearchable={false}
                  />
                </S.SmallInputContainer>
              </S.ExpirationContainer>
            </S.MultipleInputContainer>

            {values[CARD_HOLDER_FIELD] && orderDetailsData[CARD_TYPE_SWITCH] === PHYSICAL_CARD_TYPE && (
              <>
                <S.ReadOnlyContainer
                  error={handleError(ADDRESS_FIELD)}
                >
                  <S.ReadOnlyLabel>
                    {t('orderCards.cardDetails.deliverTo')}
                  </S.ReadOnlyLabel>
                  <S.ReadOnlyContent>
                    {getDeliveryAddress(deliveryAddress)}
                  </S.ReadOnlyContent>
                  {isFirstCardAddressElement && (
                    <S.ReadOnlyCTA type="button" onClick={() => setAddressModalIsOpen(true)}>
                      {t('orderCards.cardDetails.change')}
                    </S.ReadOnlyCTA>
                  )}
                </S.ReadOnlyContainer>
                {handleError(ADDRESS_FIELD) && (
                  <S.DeliveryAddressErrorInfo>
                    {handleError(ADDRESS_FIELD)}
                  </S.DeliveryAddressErrorInfo>
                )}
              </>
            )}
            {isCardCreation && (
              <S.ConfirmationContainer onClick={() => confirmCard(deliveryAddress, cardHolder)}>
                <S.ConfirmationButton type="button" label={t('orderCards.cardDetailsItem.confirmationBtn')} />
              </S.ConfirmationContainer>
            )}
          </S.InputsContainer>
        </S.Container>
        {addressModalIsOpen && (
          <ChangeAddressModal
            isOpen={addressModalIsOpen}
            hasManyCardHolders={persons.length > 1}
            setIsOpen={setAddressModalIsOpen}
            selectedAddress={selectedCard.address}
            corporationAddress={corporation.address}
            setDeliveryAddress={onChangeDeliveryAddress}
            cardHolderAddress={cardHolderAddress}
          />
        )}
      </S.Form>
    </>
  );
}

CardDetailsItem.propTypes = {
  selectedCard: orderCardShape.isRequired,
  corporation: corporationShape.isRequired,
  deleteItem: PropTypes.func.isRequired,
  displayErrors: PropTypes.bool.isRequired,
  isFirstCardAddressElement: PropTypes.bool,
  orderDetailsData: PropTypes.instanceOf(Object).isRequired,
  persons: PropTypes.instanceOf(Array).isRequired,
  updateAddress: PropTypes.func,
  updateItem: PropTypes.func.isRequired,
  confirmCard: PropTypes.func.isRequired,
  sidebarState: PropTypes.string.isRequired,
  handleSidebarClose: PropTypes.func.isRequired,
  addressModalIsOpen: PropTypes.bool.isRequired,
  cards: PropTypes.arrayOf(orderCardShape),
  setAddressModalIsOpen: PropTypes.func.isRequired,
};

export default CardDetailsItem;
