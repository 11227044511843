import React from 'react';
import { t } from 'i18next';

import { transactionStatus, transactionType } from 'constants/transactions';

import S from './styles';
import { Hint } from '../index';

export const mapTransactionStatusHint = ( status, type ) => {
  if (status === transactionStatus.pending && type === transactionType.bankOutgoing) {
    return (
      <S.TransactionHint>
        <Hint placement="bottom" isInfo text={t('activity.pendingTransactionLabel')} width="22.375rem" />
      </S.TransactionHint>
    );
  }

  return null;
};
