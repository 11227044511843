import PropTypes from 'prop-types';
import React from 'react';

import S from './styles';

function DetailItem({ label, value }) {

  return (
    <S.DetailItem>
      <S.Label>
        {label}
      </S.Label>
      <S.Value>
        {value}
      </S.Value>
    </S.DetailItem>
  );
}

DetailItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]),
};

export default DetailItem;
