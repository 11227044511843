import { Button as DefaultButton, Dropdown, RadioButton, Toggle as DefaultToggle } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { AlertBlock, CancelButton as Cancel } from 'components';
import { DEVICE } from 'constants/breakpoints';
import { hex2rgba } from 'helpers/styles';
import { ReactComponent as Icon } from 'assets/icons/info.svg';

const Container = styled.form`
  width: auto;

  @media ${DEVICE.desktop} {
    width: 30rem;
  }
`;

const SectionHeader = styled.h3`
  padding: 0;
  font: ${props => props.theme.fonts.bodyLBold};
  margin-top: 0;

  @media ${DEVICE.laptop} {
    font-size: 1.375rem;
  }
`;

const Select = styled(Dropdown)`
  width: auto;

  @media ${DEVICE.desktop} {
    width: 30rem;
  }
`;

const Radio = styled(RadioButton)`
  margin-top: 1.875rem;
`;

const Toggle = styled(DefaultToggle)`
  margin-bottom: 1.375rem;
  width: 100%;
`;

const Separator = styled.hr`
  border-top: ${props => hex2rgba(props.theme.colors.black, 0.1)};
  margin: 2rem 0 1.25rem 0;
  display: block;

  @media ${DEVICE.tablet} {
    display: none;
  }
`;

const Button = styled(DefaultButton)`
  margin: 0;
  margin-bottom: 0.9375rem;
  width: 100%;

  @media ${DEVICE.tablet} {
    margin-top: ${props => props.isAccountSelection ? '2.5rem' : '2rem'};
    width: auto;
    transition: none;
  }
`;

const CancelButton = styled(Cancel)`
  display: block;
  width: 100%;

  @media ${DEVICE.tablet} {
    display: none;
  }
`;

const InfoIcon = styled(Icon)`
  width: 2rem;
  height: 2rem;
  fill: ${props => props.theme.colors.blue};
`;

const CardRadioContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 2.5rem;

  @media ${DEVICE.tablet} {
    flex-direction: row;
  }
`;

const CardDescription = styled.div`
  height: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const CardRadio = styled.div`
  &:first-child {
    margin-bottom: 1.25rem;
  }

  @media ${DEVICE.tablet} {
    &:first-child {
      margin-right: 0.625rem;
      margin-bottom: 0;
    }
  }
`;

const Alert = styled(AlertBlock)`
  margin-bottom: 0;
`;

export default {
  Container,
  SectionHeader,
  Button,
  Select,
  Radio,
  Toggle,
  CancelButton,
  Separator,
  InfoIcon,
  CardRadio,
  CardDescription,
  CardRadioContainer,
  Alert,
};
