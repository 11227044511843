import { ErrorCodes } from '@general/error-codes';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { TwoFaContext } from 'contexts/two-fa';
import { ModalContent, TwoFa } from 'components';
import { showGeneralToastError, errorsConsistOneOfErrorCodes } from 'utils/errors';

import S from './styles';
import { TWO_FA_MODAL_FORM } from './constants';

function TwoFaModal({ isOpen, setIsOpen, onSubmit, onResend, isVoiceCallEnabled }) {
  const {
    setErrors,
    isSubmitting,
    setSubmitting,
    stopTimer,
    newChallenge,
    startTimer,
    challengeId,
    scaId,
    setHandleSubmitFunc,
    values,
    setFieldValue,
    setFieldTouched,
    handleSubmit,
  } = useContext(TwoFaContext);

  const [ isTwoFaCodeExpired, setIsTwoFaCodeExpired ] = useState(false);
  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  const resend = factor => {
    stopTimer();
    setSubmitting(true);

    return onResend(factor, scaId)
      .then(data => {
        const {
          challenge: { newChallengeAvailableAt, nonce },
        } = data;

        newChallenge.setAvailableAt(newChallengeAvailableAt);
        setFieldValue('nonce', nonce);
        setIsTwoFaCodeExpired(false);
        startTimer();
        setSubmitting(false);
      })
      .catch(error => {
        if (errorsConsistOneOfErrorCodes(error.data, [ ErrorCodes.messageBlocked, ErrorCodes.regionBlocked ])) {
          toast.error(error.data.default?.message, { autoClose: false });

          return;
        }
        showGeneralToastError(error);
      });
  };

  useEffect(() => {
    setHandleSubmitFunc(() => onSubmitData);
  }, [ challengeId, values?.nonce ]);

  const onSubmitData = async () => {
    const { nonce } = values;

    setFieldTouched('nonce', true);
    try {
      await onSubmit({ nonce, id: challengeId, scaId });
    } catch (error) {
      setErrors({ nonce: getErrorMessage(error) });

      if (errorsConsistOneOfErrorCodes(error.data, [ErrorCodes.expired])) {
        setIsTwoFaCodeExpired(true);
      }
    } finally {
      setSubmitting(false);
    }
  };

  const getErrorMessage = error => {
    const result = Object.values(error.data).find(({ message }) => message);

    return result?.message;
  };

  const CTAButtons = (
    <>
      <S.Button
        label="Confirm"
        category="primary"
        type="submit"
        disabled={isSubmitting || isTwoFaCodeExpired}
        offset="true"
      />
      <S.Button isCancel label="Cancel" category="secondary" onClick={closeModal} />
    </>
  );

  return (
    <ModalContent
      title="Enter your verification code"
      isOpen={isOpen}
      CTAButtons={CTAButtons}
      setIsOpen={setIsOpen}
      formName={TWO_FA_MODAL_FORM}
      onSubmit={handleSubmit}
      hasCloseIcon
    >
      <TwoFa resend={resend} isModal isVoiceCallEnabled={isVoiceCallEnabled} />
    </ModalContent>
  );
}

TwoFaModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onResend: PropTypes.func.isRequired,
  challenge: PropTypes.shape({
    id: PropTypes.string,
    nonce: PropTypes.string,
    codeExpirationSec: PropTypes.number,
    truncatedPhoneNumber: PropTypes.string,
    newChallengeAvailableAt: PropTypes.number,
  }),
  isVoiceCallEnabled: PropTypes.bool,
};

export default TwoFaModal;
