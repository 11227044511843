import PropTypes from 'prop-types';
import React from 'react';

import RoleItem from '../role-item';
import S from './styles';

function RolesList({ roles, onRemoveRole }) {
  const renderRoleItem = (role, idx) => {
    const isLastChild = roles.length - 2 <= idx;

    return <RoleItem role={role} key={role.id} onRemoveRole={onRemoveRole} openToTop={isLastChild} />;
  };

  return (
    <S.ListContainer>
      {roles.map(renderRoleItem)}
    </S.ListContainer>
  );
}

RolesList.propTypes = {
  roles: PropTypes.instanceOf(Array),
  onRemoveRole: PropTypes.func,
};

export default RolesList;
