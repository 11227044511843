import { Tabs } from '@general/intergiro-ui-kit';
import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useContext } from 'react';
import { useHistory } from 'react-router';
import { NavLink } from 'react-router-dom';

import { useUsers } from 'hooks';
import { UserContext } from 'contexts/user';
import { navigation } from 'constants/navigation';

import S from './styles';
import UsersList from './components/users-list';

function Users() {
  const history = useHistory();
  const { users } = useUsers();
  const {
    permissions: { canCreateUser, canViewUserRoles },
  } = useContext(UserContext);

  const showInviteUserButton = canCreateUser && canViewUserRoles;
  const showRolesAndPermissionsTab = canViewUserRoles;

  const pushToRolesAndPermission = useCallback(() => {
    history.push(navigation.roles);
  }, [history]);

  return (
    <>
      <S.Header>
        <S.Title>Users</S.Title>
        {showInviteUserButton && (
          <NavLink to={navigation.inviteUser}>
            <S.Button size="small" label="Invite user" icon={<S.PlusIcon />} type="primary" />
          </NavLink>
        )}
      </S.Header>
      <div>
        <Tabs.Container name="tabs">
          <Tabs.Head id="all-users" isActive>
            Users
            {!isEmpty(users) && (
              <S.TabHelperText>
                {users.length}
              </S.TabHelperText>
            )}
          </Tabs.Head>
          {showRolesAndPermissionsTab && (
            <Tabs.Head id="all-roles" handleChange={pushToRolesAndPermission}>
              Roles and permissions
            </Tabs.Head>
          )}
        </Tabs.Container>
      </div>
      <Tabs.Panel>
        <UsersList users={users} />
      </Tabs.Panel>
    </>
  );
}

export default Users;
