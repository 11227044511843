import { RadioButton } from '@general/intergiro-ui-kit';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import FilterDropdown from 'components/filter-bar-mobile/components/filter-dropdown';
import { FilterBarMobile } from 'components';

import S from './styles';
import { RADIO_SHOW_HIDDEN_MOBILE_NAME } from './constants';

function FilterMobile({
  isMobileFiltersOpen,
  setIsMobileFiltersOpen,
  showHiddenAccounts,
  onChangeShowHiddenAccounts,
}) {
  const { t } = useTranslation();

  const [ areAccountsHidden, setAreAccountsHidden ] = useState(showHiddenAccounts);

  const applyChanges = () => {
    onChangeShowHiddenAccounts(areAccountsHidden);
  };

  return (
    <FilterBarMobile
      isOpen={isMobileFiltersOpen}
      closeFilterBar={() => setIsMobileFiltersOpen(false)}
      applyFilters={applyChanges}
      cancelFilters={() => setIsMobileFiltersOpen(false)}
      hideLabel
    >
      <FilterDropdown title={t('accounts.filters.subtitle')}>
        <S.Radio
          id="showActive"
          label={t('accounts.filters.activeAccounts')}
          name={RADIO_SHOW_HIDDEN_MOBILE_NAME}
          checked={!areAccountsHidden}
          onChange={() => setAreAccountsHidden(false)}
        />
        <RadioButton
          id="showAll"
          label={t('accounts.filters.allAccounts')}
          name={RADIO_SHOW_HIDDEN_MOBILE_NAME}
          checked={areAccountsHidden}
          onChange={() => setAreAccountsHidden(true)}
        />
      </FilterDropdown>
    </FilterBarMobile>
  );
}

FilterMobile.propTypes = {
  isMobileFiltersOpen: PropTypes.bool.isRequired,
  setIsMobileFiltersOpen: PropTypes.func.isRequired,
  showHiddenAccounts: PropTypes.bool.isRequired,
  onChangeShowHiddenAccounts: PropTypes.func.isRequired,
};

export default FilterMobile;
