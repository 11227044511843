import apiSpec from '@apispec/customer-portal-bff';
import { ErrorCodes } from '@general/error-codes';

import config from 'config';
import { navigation } from 'constants/navigation';
import { FORBIDDEN } from 'constants/status-code';
import { redirectToOnboarding, redirectToPage } from 'utils/location';

import createApiClient from './api';

const timeout = 50 * 1000;

export default createApiClient({
  apiSpec,
  options: {
    baseURL: config.bffUrl,
    timeout,
  },
  tokenBuilder: {
    name: 'Authorization',
    build: token => `Bearer ${token}`,
  },
  baseApiClient: null,
  responseStatusActions: {
    [FORBIDDEN]: handlePermissionDenied,
  },
});

// Private functions

const permissionsDeniedPathMapper = {
  questionnaire: () => handleRedirectToOnboarding(),
  'corporate-questionnaire': () => handleRedirectToOnboarding(),
  'initial-application': () => handleRedirectToOnboarding(),
  verification: () => handleRedirectToOnboarding(),
  profile: () => redirectToPage(navigation.profile),
};

function handlePermissionDenied(errors) {
  if (!errors.length) return false;

  const shouldRedirectToOnboarding = ({ code }) => code === ErrorCodes.notOnboarded;

  if (errors?.find(shouldRedirectToOnboarding)) {
    handleRedirectToOnboarding();

    return true;
  }

  const applyRedirection = permissionsDeniedPathMapper[errors[0].field];
  if (applyRedirection) {
    applyRedirection();
  }

  return false;
}

function handleRedirectToOnboarding() {
  redirectToOnboarding();
}
