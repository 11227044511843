import React, { useEffect, useMemo, useState } from 'react';
import { matchPath, useHistory, useParams } from 'react-router-dom';
import capitalize from 'lodash/capitalize';
import { useTranslation } from 'react-i18next';

import { cardAccountsQuery } from 'constants/account';
import { PHYSICAL_CARD_TYPE } from 'constants/card';
import { navigation } from 'constants/navigation';
import AccountsProvider from 'contexts/accounts';
import { ViewContext } from 'contexts/view';
import { WizardLayout } from 'layouts';
import CancelFlowModal from 'pages/order-cards/components/cancel-flow-modal';

import CardsDetails from './components/cards-details';
import SelectType from './components/select-type';
import Summary from './components/summary';
import { CARD_TYPE_SWITCH, CARDS_DETAILS, SELECT_TYPE, SUMMARY } from './constants';

function OrderCardsPage() {
  const history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation();
  const [ currentView, setCurrentView ] = useState(SELECT_TYPE);
  const [ orderDetailsData, setOrderDetailsData ] = useState(null);
  const [ orderId, setOrderId ] = useState(null);
  const [ cards, setCards ] = useState([]);
  const [ requestSubmitted, setRequestSubmitted ] = useState(false);
  const [ isCancelPopupShown, setIsCancelPopupShown ] = useState(false);
  const [ layoutProps, setLayoutProps ] = useState({
    title: '',
    displayCancelButton: true,
  });

  useEffect(() => {
    if (currentView === SELECT_TYPE) {
      setLayoutProps({
        ...layoutProps,
        title: 'Order cards',
      });

      return;
    }

    updateTitle();

    if (currentView === SUMMARY) {
      history.replace(`${navigation.orderCards}/${orderId}`);
    }

  }, [currentView]);

  useEffect(() => {
    updateTitle();
  }, [ orderDetailsData, requestSubmitted ]);

  useEffect(() => {
    const summaryPage = matchPath(history.location.pathname, {
      path: `${navigation.orderCards}/:id`,
      exact: true,
    });

    if (summaryPage) {
      setOrderId(summaryPage.params.id);
      setCurrentView(SUMMARY);
    }
  }, []);

  useEffect(() => {
    if (!requestSubmitted) return;
    const hideBackButton = Boolean(requestSubmitted);
    const displayCancelButton = !requestSubmitted;

    setLayoutProps(prevLayoutProps => ({ ...prevLayoutProps, hideBackButton, displayCancelButton }));
  }, [requestSubmitted]);

  const updateTitle = () => {
    if (orderDetailsData && currentView !== SELECT_TYPE) {
      const typeLabel = orderDetailsData?.[CARD_TYPE_SWITCH] === PHYSICAL_CARD_TYPE ? t('orderCards.pageTitle.physical') : t('orderCards.pageTitle.virtual');
      setLayoutProps(prevLayoutProps => ({
        ...prevLayoutProps,
        title: requestSubmitted ?
          t('orderCards.pageTitle.requestSubmitted', { cardType: capitalize(typeLabel) }) :
          t('orderCards.pageTitle.requestPending', { cardType: typeLabel }),
      }));
    }
  };

  const triggerView = view => {
    switch (view) {
      case SELECT_TYPE:
        return history.push(navigation.cards);
      case SUMMARY: {
        if (requestSubmitted) {
          setRequestSubmitted(false);

          return history.push(navigation.cards);
        }
        history.push(navigation.orderCards);

        return setCurrentView(CARDS_DETAILS);
      }
      case CARDS_DETAILS: {
        setCards([]);
        setOrderDetailsData(null);

        return setCurrentView(SELECT_TYPE);
      }
      default:
        return {};
    }
  };

  const handleCancelClick = () => {
    switch (currentView) {
      case SELECT_TYPE:
        return history.replace(navigation.cards);
      default:
        return setIsCancelPopupShown(true);
    }
  };

  const getPageContent = () => {
    if (id) {
      return (
        <Summary
          orderId={id}
          submitted={requestSubmitted}
          setSubmitted={setRequestSubmitted}
          cards={cards}
          setCards={setCards}
          orderDetailsData={orderDetailsData}
          setOrderDetailsData={setOrderDetailsData}
          setIsCancelPopupShown={setIsCancelPopupShown}
        />
      );
    }

    switch (currentView) {
      case SELECT_TYPE:
        return (
          <SelectType
            setCurrentView={setCurrentView}
            setOrderDetailsData={setOrderDetailsData}
            data-testid="page-content"
          />
        );
      case CARDS_DETAILS:
        return (
          <CardsDetails
            setCurrentView={setCurrentView}
            orderDetailsData={orderDetailsData}
            setOrderId={setOrderId}
            cards={cards}
            setCards={setCards}
          />
        );
      default:
        return null;
    }
  };

  const viewValues = useMemo(() => ({ currentView, triggerView }), [currentView]);

  return (
    <>
      <AccountsProvider queryParams={cardAccountsQuery}>
        <ViewContext.Provider value={viewValues}>
          <WizardLayout {...layoutProps} handleCancel={handleCancelClick} >
            <div data-testid="page-content">
              {getPageContent()}
            </div>
          </WizardLayout>
        </ViewContext.Provider>
      </AccountsProvider>
      <CancelFlowModal
        isOpen={isCancelPopupShown}
        setIsOpen={setIsCancelPopupShown}
        cardType={orderDetailsData?.cardTypeSwitch}
      />
    </>
  );
}

export default OrderCardsPage;
