import 'react-toastify/dist/ReactToastify.css';

import React, { useCallback } from 'react';

import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';

import S from './styles';

function Notification() {
  const closeIcon = useCallback(({ closeToast }) => <CrossIcon onClick={closeToast} />, []);

  return (
    <S.StyledContainer
      autoClose={5000}
      position="top-right"
      hideProgressBar
      draggable={false}
      closeButton={closeIcon}
      icon={false}
    />
  );
}

export default Notification;
