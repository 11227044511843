import { Button as DefaultButton, Dropdown as DefaultSelect, Input as DefaultInput } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { ReactComponent as DefaultInfoIcon } from 'assets/icons/info.svg';
import { DEVICE } from 'constants/breakpoints';

const Input = styled(DefaultInput)`
  margin: 1.25rem 0;
`;

const Button = styled(DefaultButton)`
  margin-right: 0;
  min-width: fit-content;
  padding: 0.75rem 1.5rem;
  margin-top: ${props => props.category === 'secondary' ? '1rem' : 0};
  width: 100%;

  @media ${DEVICE.tablet} {
    margin-top: 0;
    margin-right: 0.625rem;
    width: auto;
  }
`;

const Dropdown = styled(DefaultSelect)`
  margin: 1.25rem 0;
  width: auto;

  @media ${DEVICE.tablet} {
    width: 30rem;
  }
`;

const DoubleInputContainer = styled.div`
  display: flex;
  margin-top: 1.25rem;
  flex-direction: column;

  @media ${DEVICE.tablet} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const CountryDropdown = styled(DefaultSelect)`
  width: auto;

  @media ${DEVICE.tablet} {
    width: 14.375rem;
  }
`;

const ShortTextInput = styled(DefaultInput)`
  width: auto;
  margin-top: ${props => props.hasMargin ? '1.25rem' : 0};

  @media ${DEVICE.tablet} {
    width: 14.375rem;
    margin-top: 0;
  }
`;

const AlertBlockWrapper = styled.div`
  width: 100%;
  max-width: 30rem;
  margin-top: 1.3rem;
`;

const InfoIcon = styled(DefaultInfoIcon)`
  width: 2rem;
  height: 2rem;
  fill: ${props => props.theme.colors.blue};
`;

export default {
  Dropdown,
  Input,
  Button,
  DoubleInputContainer,
  CountryDropdown,
  ShortTextInput,
  AlertBlockWrapper,
  InfoIcon,
};
