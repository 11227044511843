import { Button as DefaultButton, Title as DefaultTitle } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { ReactComponent as Cross } from 'assets/icons/cross.svg';
import { DEVICE } from 'constants/breakpoints';
import { hex2rgba } from 'helpers/styles';

const Content = styled.div`
  width: ${`${window.screen.availWidth}px`};
  height: ${`${window.screen.availHeight}px`};
  padding: 1.875rem 1.25rem;
  
  @media ${DEVICE.tablet} {
    padding: 1.875rem;
    width: 33.75rem;
    height: auto;
  }
`;

const CloseIcon = styled(Cross)`
  position: absolute;
  right: 1.875rem;
  top: 1.875rem;
  cursor: pointer;
`;

const Title = styled(DefaultTitle)`
  font: ${props => props.theme.fonts.h4Bold};
  padding: 0;
`;

const Separator = styled.hr`
  border-top: ${props => hex2rgba(props.theme.colors.black, 0.1)};
  margin: 1.25rem 0;
`;

const HelperText = styled.p`
  margin: 0;
  font: ${props => props.theme.fonts.bodyMBold};
  margin-bottom: 1.4375rem;
`;

const CTAContainer = styled.div`

  @media ${DEVICE.tablet} {
    display: flex;
    align-items: center;
    justify-content: start;
  }
`;

const Button = styled(DefaultButton)`
  font-weight: 500;
  margin: 0;
  width: 100%;

  margin-top: ${props => props.extraMargin ? '1rem' : '0'};

  @media ${DEVICE.tablet} {
    margin-top: 0;
    margin-right: 0.5rem;
    min-width: auto;
    width: 6.25rem;
  }
`;

export default {
  Title,
  CloseIcon,
  Content,
  Separator,
  HelperText,
  CTAContainer,
  Button,
};
