// for testing
export const dataTestIds = {
  title: 'api-page-title',
  removeModalButton: 'api-page-remove',
  renewModalButton: 'api-page-renew',
  renewSubmitButton: 'api-renew-modal-submit',
  removeSubmitButton: 'api-remove-modal-submit',
  apiLink: 'api-page-api-link',
  apiCreationButton: 'api-page-api-creation',
  badge: 'api-page-expired-badge',
  emptyState: 'api-page-placeholder',
};
