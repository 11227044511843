
import PropTypes from 'prop-types';
import React, { createContext, useMemo, useState } from 'react';

export const NavigationContext = createContext({});

function NavigationProvider({ children }) {
  const [ sidebarWidth, setSidebarWidth ] = useState(0);
  const [ backButtonLabel, setBackButtonLabel ] = useState(undefined);

  const initialValues = useMemo(() =>
    ({ backButtonLabel, sidebarWidth, setSidebarWidth, setBackButtonLabel }), [ sidebarWidth, backButtonLabel ]);

  return (
    <NavigationContext.Provider value={initialValues}>
      {children}
    </NavigationContext.Provider>
  );
}

NavigationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default NavigationProvider;
