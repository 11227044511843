import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { ModalContent } from 'components';
import { MODAL_TYPE } from 'constants/modal';
import { navigation } from 'constants/navigation';

import S from './styles';

function UnableAddCardErrorModal({ isOpen, setIsOpen, account }) {
  const history = useHistory();

  const handleGoToCards = () => {
    setIsOpen(false);
    history.push(navigation.cards);
  };

  const CTAButtons = (
    <>
      <S.Button label="Continue order" category="primary" type="submit" onClick={() => setIsOpen(false)} />
      <S.Button label="Go to cards" category="secondary" type="submit" onClick={handleGoToCards} />
    </>
  );

  return (
    <ModalContent
      title="Unable to add new card"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      type={MODAL_TYPE.message}
      CTAButtons={CTAButtons}
      hasCloseIcon
    >
      <S.Message>
        Sorry, you have insufficient funds on your account
        {' '}
        <S.Name>
          {account.displayName}
        </S.Name>
        {' '}
        to add more cards.
        Please top up your account by moving funds internally or transferring from an external source.
      </S.Message>
    </ModalContent>
  );
}

UnableAddCardErrorModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  account: PropTypes.instanceOf(Object).isRequired,
};

export default UnableAddCardErrorModal;
