import PropTypes from 'prop-types';
import React from 'react';

import S from './styles';

function FilterBar({ children, isHidden, filterNumber = 5 }) {
  if (isHidden) {
    return null;
  }

  return (
    <S.FilterBarWrapper data-testid="filter-bar-desktop" filterNumber={filterNumber}>
      {children}
    </S.FilterBarWrapper>
  );
}

FilterBar.propTypes = {
  children: PropTypes.node,
  filterNumber: PropTypes.number,
  isHidden: PropTypes.bool,
};

export default FilterBar;
