import { Avatar, Dropdown, DropdownBeneficiaryOption as BeneficiaryOption } from '@general/intergiro-ui-kit';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import { useIntercom } from 'react-use-intercom';

import { showGeneralToastError } from 'utils/errors';
import { addBulkPaymentItem, createBulkPayment, getBulkPayment, updateBulkPayment } from 'api/bulk-payments';
import { getDropdownCurrencyOptions } from 'components';
import { GlobalLoaderContext } from 'contexts/global-loader';
import AlertModal from 'pages/bulk-payments/components/alert-modal';
import BeneficiaryRow from 'pages/bulk-payments/components/beneficiary-row';
import BeneficiarySidebar from 'pages/bulk-payments/components/beneficiary-sidebar';
import CSVUploader from 'pages/bulk-payments/components/csv-upload';
import TotalContainer from 'pages/bulk-payments/components/total-container';
import { viewMap } from 'pages/bulk-payments/constants';
import { missingValuesError } from 'pages/bulk-payments/constants/errors';
import {
  addBeneficiaryFieldName,
  beneficiaryNameFieldName,
  bicFieldName,
  ibanFieldName,
  notEnoughFundsCode,
  selectAccountFieldName,
  selectBeneficiaryFieldName,
} from 'pages/bulk-payments/constants/form';
import { formatBeneficiaries, getBeneficiaryPayload } from 'pages/bulk-payments/helpers';
import { checkOptionIncludesValue } from 'pages/move-funds/services/utils';
import { formatAmount } from 'utils/amount';
import { DEBOUNCE_TIMEOUT } from 'constants/common';

import S from './styles';

function BulkPaymentsForm({
  accounts,
  beneficiaries,
  isFetchingBeneficiaries,
  setBeneficiariesQuery,
  setTotals,
  setCurrency,
  setAccount,
  bulkPaymentData,
  account,
  predefinedAccountId,
  setCurrentView,
  setNumberOfDisplayedBeneficiaries,
  setBulkPaymentData,
}) {
  const tableContainer = useRef(null);

  const { startLoading, endLoading } = useContext(GlobalLoaderContext);

  const [ totalFooterContainer, setTotalFooterContainer ] = useState(null);
  const [ accountOptions, setAccountOptions ] = useState([]);
  const [ selectedAccountOption, setSelectedAccountOption ] = useState(null);
  const [ accountCurrency, setAccountCurrency ] = useState(null);
  const [ beneficiariesOptions, setBeneficiariesOptions ] = useState([]);
  const [ displayedBeneficiaries, setDisplayedBeneficiaries ] = useState([]);
  const [ chosenBeneficiary, setChosenBeneficiary ] = useState(null);
  const [ chosenBeneficiaryIndex, setChosenBeneficiaryIndex ] = useState(null);
  const [ beneficiariesAreVisible, setBeneficiariesAreVisible ] = useState(false);
  const [ csvUploaded, setCsvUploaded ] = useState(false);
  const [ beneficiarySidebarOpen, setBeneficiarySidebarOpen ] = useState(false);
  const [ bulkPayment, setBulkPayment ] = useState(null);
  const [ totalFooterVisible, setTotalFooterVisible ] = useState(false);
  const [ totalsByCurrency, setTotalsByCurrency ] = useState(null);
  const [ globalErrors, setGlobalErrors ] = useState(null);
  const [ totalAmountDetailsVisible, setTotalAmountDetailsVisible ] = useState(false);
  const [ alertModalIsOpen, setAlertModalIsOpen ] = useState(false);
  const [ hasNotEnoughFundsOnAccount, setHasNotEnoughFundsOnAccount ] = useState(false);
  const [ disableContinueButton, setDisableContinueButton ] = useState(false);
  const { update: updateIntercom } = useIntercom();
  const debounceRef = useRef();
  const beneficiaryRowRefs = [];

  const markAllBeneficiaryRowsTouched = () => {
    Object.values(beneficiaryRowRefs).map(ref => ref.markFieldsTouched());
  };

  useEffect(() => {
    if (displayedBeneficiaries && displayedBeneficiaries.length <= 0) {
      setBeneficiariesAreVisible(false);
      setCsvUploaded(false);
    }
  }, [displayedBeneficiaries]);

  useEffect(() => {
    beneficiarySidebarOpen ? updateIntercom({
      hideDefaultLauncher: true,
    }) : updateIntercom({
      hideDefaultLauncher: false,
    });
  }, [beneficiarySidebarOpen]);

  useEffect(() => {
    const getBulkPaymentItems = async () => {
      startLoading();

      try {
        const data = await getBulkPayment(bulkPaymentData.id);
        const { items, totals, errors } = formatBeneficiaries(data);

        setAccountCurrency(account.value.currency);
        setBulkPayment(bulkPaymentData);
        setSelectedAccountOption(account);
        setTotalsByCurrency(totals);
        addBeneficiaryToList(items);
        setGlobalErrors(errors);
      } catch (error) {
        showGeneralToastError(error);
      } finally {
        endLoading();
      }
    };

    if (bulkPaymentData) {
      getBulkPaymentItems();
    }
  }, [bulkPaymentData]);

  useEffect(() => {
    if (!globalErrors) {
      return;
    }
    const notEnoughFundsOnAccount = globalErrors.some(err => err.code === notEnoughFundsCode);
    setHasNotEnoughFundsOnAccount(notEnoughFundsOnAccount);
  }, [globalErrors]);

  useEffect(() => {
    if (
      !selectedAccountOption ||
      selectedAccountOption.value.id === bulkPayment?.accountId
    ) {
      return;
    }

    const getBulkPaymentInstance = async () => {
      setDisableContinueButton(true);

      try {
        const data = await updateBulkPayment(bulkPayment.id, selectedAccountOption.value.id);
        const { items, totals, errors } = formatBeneficiaries(data);

        setBulkPayment(data);
        addBeneficiaryToList(items);
        setTotalsByCurrency(totals);
        setGlobalErrors(errors);
        setCsvUploaded(true);
      } catch (error) {
        showGeneralToastError(error);
      } finally {
        setDisableContinueButton(false);
      }
    };

    if (bulkPayment) {
      getBulkPaymentInstance();
    }
  }, [selectedAccountOption]);

  const handleDropdownSearch = value => {
    clearTimeout(debounceRef.current);

    debounceRef.current = setTimeout(() => {
      setBeneficiariesQuery(value);
    }, DEBOUNCE_TIMEOUT);
  };

  const addAddBeneficiaryOption = () => ({
    onChange: () => {
      if (!beneficiarySidebarOpen) {
        setBeneficiarySidebarOpen(true);
      }
    },
    value: {
      name: addBeneficiaryFieldName,
    },
    label: (
      <S.AddBeneficiaryOption>
        <S.PlusIconWrapper>
          <S.PlusIcon />
        </S.PlusIconWrapper>
        <S.AddBeneficiaryOptionLabel>
          New beneficiary
        </S.AddBeneficiaryOptionLabel>
      </S.AddBeneficiaryOption>
    ),
  });

  const avatarComponent = useCallback((name, iban, bic) => <Avatar small name={name} param1={iban} param2={bic} />, []);

  const getBeneficiaryOptions = (beneficiariesObj, markAllTouched = false) =>
    beneficiariesObj.map(beneficiary => {
      const {
        name,
        account: {
          currency,
          number: { value: iban },
          bankCode: { value: bic },
        },
        isTrusted,
      } = beneficiary;

      return {
        touched: markAllTouched,
        value: beneficiary,
        label: (
          <BeneficiaryOption
            name={name}
            avatar={() => avatarComponent(name, iban, bic)}
            accountNumber={iban}
            currencyShort={currency}
            sortCode={` ${bic}`}
            isTrusted={isTrusted}
          />
        ),
      };
    });

  useEffect(() => {
    const options = getDropdownCurrencyOptions(accounts);
    setAccountOptions(options);
  }, [ accounts, hasNotEnoughFundsOnAccount ]);

  useEffect(() => {
    const options = getBeneficiaryOptions(beneficiaries);
    options.unshift(addAddBeneficiaryOption());
    setBeneficiariesOptions(options);
  }, [beneficiaries]);

  useEffect(() => {
    if (!predefinedAccountId || accountOptions.length === 0) {
      return;
    }

    const accountOpt = accountOptions.find(item => item.value.id === predefinedAccountId);
    setSelectedAccountOption(accountOpt);
    setAccountCurrency(accountOpt.value.currency);
  }, [ predefinedAccountId, accountOptions ]);

  useEffect(() => {
    if (!beneficiariesAreVisible) {
      return;
    }
    const { offsetTop, offsetHeight } = tableContainer.current;
    const offset = offsetTop + offsetHeight + 300;

    if (offset >= window.innerHeight) {
      setTotalFooterVisible(true);
    } else {
      setTotalFooterVisible(false);
    }
  }, [tableContainer?.current?.offsetHeight]);

  const addBeneficiaryToList = (items, markAllTouched = false) => {
    const beneficiaryOptions = getBeneficiaryOptions(items, markAllTouched);

    setDisplayedBeneficiaries(beneficiaryOptions);
    setNumberOfDisplayedBeneficiaries(beneficiaryOptions.length);
    setBeneficiariesAreVisible(beneficiaryOptions.length > 0);
  };

  const onBeneficiarySelect = async selectedOption => {
    if (!selectedOption) return;

    let bulkPaymentInstance = bulkPayment;
    const {
      value: { name: optionName, account: acc },
    } = selectedOption;
    const { currency, id: accountId } = selectedAccountOption.value;

    if (optionName === addBeneficiaryFieldName) {
      const onAddBeneficiary = selectedOption.onChange;
      onAddBeneficiary();

      return;
    }

    setDisableContinueButton(true);
    startLoading();

    if (!bulkPayment) {
      try {
        const bulkPaymentItem = await createBulkPayment({ accountId: selectedAccountOption.value.id });
        bulkPaymentInstance = bulkPaymentItem;
        setBulkPayment(bulkPaymentItem);
      } catch (error) {
        showGeneralToastError(error);
      }
    }

    const values = {
      [beneficiaryNameFieldName]: optionName,
      [ibanFieldName]: acc.number.value,
      [bicFieldName]: acc.bankCode.value,
    };
    const payload = getBeneficiaryPayload(values, accountId, currency);

    try {
      const data = await addBulkPaymentItem(bulkPaymentInstance.id, payload);

      const { items, totals, errors } = formatBeneficiaries(data);

      setBulkPayment(data);
      setTotalsByCurrency(totals);
      setGlobalErrors(errors);
      addBeneficiaryToList(items);
    } catch (error) {
      showGeneralToastError(error);
    } finally {
      setDisableContinueButton(false);
      endLoading();
    }
  };

  const onAccountSelect = selectedOption => {
    setSelectedAccountOption(selectedOption);
    setAccountCurrency(selectedOption.value.currency);
  };

  const onBeneficiarySidebarClose = hasValidationError => {
    if (hasValidationError) {
      setAlertModalIsOpen(true);
    } else {
      setBeneficiarySidebarOpen(false);
      setChosenBeneficiary(null);
    }
  };

  const discardForm = () => {
    setBeneficiarySidebarOpen(false);
    setAlertModalIsOpen(false);
  };

  const handleBeneficiaryClick = beneficiary => {
    setChosenBeneficiary(beneficiary);
    const index = displayedBeneficiaries.findIndex(item => item.value.id === beneficiary.id);
    setChosenBeneficiaryIndex(index);
    setBeneficiarySidebarOpen(true);
  };

  const handleSidebarArrowClick = (currentlyOpen, moveUp) => {
    const index = displayedBeneficiaries.findIndex(item => item.value.id === currentlyOpen);
    setChosenBeneficiary(displayedBeneficiaries[moveUp ? index - 1 : index + 1].value);
    setChosenBeneficiaryIndex(moveUp ? index - 1 : index + 1);
  };

  const handleSubmit = e => {
    e.preventDefault();
    toast.dismiss();

    markAllBeneficiaryRowsTouched();

    if (globalErrors?.length > 0) {
      const notEnoughFundsErrors = globalErrors.reduce((acc, err) => {
        if (err.code === notEnoughFundsCode) {
          acc.push(err);
        }

        return acc;
      }, []);

      if (notEnoughFundsErrors.length !== globalErrors.length) {
        toast.error(missingValuesError);
      }
    } else {
      setBulkPaymentData(bulkPayment);
      setTotals(totalsByCurrency);
      setCurrency(selectedAccountOption.value.currency);
      setAccount(selectedAccountOption);
      setCurrentView(viewMap.bulkPaymentsConfirmation);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <S.Row>
          <S.InputContainer>
            <Dropdown
              placeholder="Select account"
              label="From"
              isCurrencyOption
              options={accountOptions}
              id={selectAccountFieldName}
              name={selectAccountFieldName}
              onChange={onAccountSelect}
              value={selectedAccountOption || ''}
              filterOption={checkOptionIncludesValue}
              isClearable={false}
            />
          </S.InputContainer>
          {selectedAccountOption ? (
            <>
              <S.ArrowRight />
              <S.InputContainer>
                <S.BeneficiaryDropdown
                  label="Send to"
                  options={beneficiariesOptions}
                  isBeneficiaryOption
                  id={selectBeneficiaryFieldName}
                  name={selectBeneficiaryFieldName}
                  onChange={onBeneficiarySelect}
                  onInputChange={handleDropdownSearch}
                  filterOption={() => true}
                  isValidNewOption={() => false}
                  isLoading={isFetchingBeneficiaries}
                />
              </S.InputContainer>
              <S.ActionColumn>
                {csvUploaded && (
                  <S.CSVIconContainer>
                    <S.CSVIcon />
                  </S.CSVIconContainer>
                )}
              </S.ActionColumn>
            </>
          ) : (
            <>
              <S.Separator />
              <S.InputContainer />
              <S.ActionColumn />
            </>
          )}
        </S.Row>
        {selectedAccountOption &&
          (beneficiariesAreVisible || csvUploaded ? (
            <>
              <S.Table
                ref={tableContainer}
                totalFooterContainer={totalFooterContainer}
              >
                <S.TableNameRow>
                  <S.TableColumnName left="true" wide>
                    Name
                  </S.TableColumnName>
                  <S.TableColumnName
                    left="true"
                    offset="true"
                  >
                    Reference
                  </S.TableColumnName>
                  <S.TableColumnName>
                    Amount
                  </S.TableColumnName>
                  <S.TableColumnName>Total</S.TableColumnName>
                  <S.ActionColumn />
                </S.TableNameRow>
                <S.TableRowSeparator />
                {displayedBeneficiaries.map(({ value, label, touched }, index) => (
                  <BeneficiaryRow
                    isTouched={touched}
                    beneficiaryRowRefs={beneficiaryRowRefs}
                    value={value}
                    label={label}
                    index={index}
                    key={`${value.id}${String(index)}`}
                    handleBeneficiaryClick={handleBeneficiaryClick}
                    account={selectedAccountOption}
                    bulkPaymentId={bulkPayment?.id}
                    setBulkPayment={setBulkPayment}
                    addBeneficiaryToList={addBeneficiaryToList}
                    setTotalsByCurrency={setTotalsByCurrency}
                    isChosen={chosenBeneficiary?.id === value.id}
                    setGlobalErrors={setGlobalErrors}
                    setDisableContinueButton={setDisableContinueButton}
                  />
                  ))}
                <S.TableRowSeparator />
              </S.Table>
              {/* TODO: Move to separate components - https://ftcs-tech.atlassian.net/browse/IDE-10957 */}
              {!totalFooterVisible && (
                <>
                  <S.Row>
                    <S.TableColumnName wide />
                    <S.TableColumnName />
                    <S.TotalWrapper
                      visible={!totalFooterVisible}
                    >
                      <TotalContainer
                        totalAmount={
                          formatAmount(totalsByCurrency[
                            accountCurrency.toLowerCase()
                          ]?.total)
                        }
                        currency={accountCurrency}
                        fees={
                          formatAmount(totalsByCurrency[
                            accountCurrency.toLowerCase()
                          ]?.fee)
                        }
                        netAmount={
                          formatAmount(totalsByCurrency[
                            accountCurrency.toLowerCase()
                          ]?.net)
                        }
                      />
                    </S.TotalWrapper>
                    <S.ActionColumn />
                  </S.Row>
                  <S.ContinueButton
                    label="Continue"
                    category="primary"
                    type="submit"
                    disabled={disableContinueButton}
                  />
                </>
              )}
              {totalFooterVisible && (
                <S.TotalFooter
                  ref={setTotalFooterContainer}
                  visible={totalFooterVisible}
                >
                  <S.ContinueButton
                    label="Continue"
                    category="primary"
                    type="submit"
                    disabled={disableContinueButton}
                  />
                  <S.TotalFooterAmountContainer>
                    <S.TotalFooterAmountRow visible>
                      <S.TotalAmountLabelContainer
                        visible={totalAmountDetailsVisible}
                      >
                        {totalAmountDetailsVisible
                          ? <S.ChevronDown onClick={() => setTotalAmountDetailsVisible(!totalAmountDetailsVisible)} />
                          : <S.ChevronUp onClick={() => setTotalAmountDetailsVisible(!totalAmountDetailsVisible)} />}
                        <S.TotalAmountLabel>
                          Total
                        </S.TotalAmountLabel>
                        <S.TotalAmountDetails>
                          of
                          {' '}
                          {displayedBeneficiaries.length}
                          {' '}
                          payments
                        </S.TotalAmountDetails>
                      </S.TotalAmountLabelContainer>
                      <S.TotalAmountValue>
                        {formatAmount(totalsByCurrency[
                            accountCurrency.toLowerCase()
                          ]?.total)}
                        {' '}
                        {accountCurrency}
                      </S.TotalAmountValue>
                    </S.TotalFooterAmountRow>
                    <S.TotalFooterAmountRow visible={totalAmountDetailsVisible}>
                      <S.TotalAmountFees>
                        Total without fees
                      </S.TotalAmountFees>
                      <S.TotalAmountFeesValue>
                        {formatAmount(totalsByCurrency[
                            accountCurrency.toLowerCase()
                          ]?.net)}
                        {' '}
                        {accountCurrency}
                      </S.TotalAmountFeesValue>
                    </S.TotalFooterAmountRow>

                    <S.TotalFooterAmountRow
                      visible={totalAmountDetailsVisible}
                      marginTop="0.8125rem"
                    >
                      <S.TotalAmountFees>
                        Transfer fee
                      </S.TotalAmountFees>
                      <S.TotalAmountFeesValue>
                        {formatAmount(totalsByCurrency[
                            accountCurrency.toLowerCase()
                          ]?.fee)}
                        {' '}
                        {accountCurrency}
                      </S.TotalAmountFeesValue>
                    </S.TotalFooterAmountRow>
                  </S.TotalFooterAmountContainer>
                </S.TotalFooter>
              )}
            </>
          ) : (
            <>
              <S.Row>
                <S.InputContainer />
                <S.Separator />
                <S.InputContainer centerContent>
                  <S.OrSeparator>or</S.OrSeparator>
                </S.InputContainer>
                <S.ActionColumn />
              </S.Row>
              <S.Row>
                <S.InputContainer />
                <S.Separator />
                <S.InputContainer>
                  <CSVUploader
                    accountId={selectedAccountOption.value.id}
                    csvUploaded={csvUploaded}
                    setCsvUploaded={setCsvUploaded}
                    addBeneficiaryToList={addBeneficiaryToList}
                    setBulkPayment={setBulkPayment}
                    setTotalsByCurrency={setTotalsByCurrency}
                    setGlobalErrors={setGlobalErrors}
                    setDisableContinueButton={setDisableContinueButton}
                  />
                </S.InputContainer>
                <S.ActionColumn />
              </S.Row>
            </>
          ))}
      </form>

      {selectedAccountOption && beneficiarySidebarOpen && (
        <BeneficiarySidebar
          isOpen={beneficiarySidebarOpen}
          onBeneficiarySidebarClose={onBeneficiarySidebarClose}
          account={selectedAccountOption}
          isEditMode={Boolean(chosenBeneficiary)}
          beneficiary={chosenBeneficiary}
          bulkPaymentId={bulkPayment?.id}
          addBeneficiaryToList={addBeneficiaryToList}
          setTotalsByCurrency={setTotalsByCurrency}
          setBulkPayment={setBulkPayment}
          handleSidebarArrowClick={handleSidebarArrowClick}
          setGlobalErrors={setGlobalErrors}
          numberOfDisplayedBeneficiaries={
            displayedBeneficiaries.length
          }
          chosenBeneficiaryIndex={chosenBeneficiaryIndex}
          setChosenBeneficiaryIndex={setChosenBeneficiaryIndex}
          setDisableContinueButton={setDisableContinueButton}
        />
      )}

      <AlertModal
        isOpen={alertModalIsOpen}
        setIsOpen={setAlertModalIsOpen}
        discardForm={discardForm}
      />
    </>
  );
}

BulkPaymentsForm.propTypes = {
  accounts: PropTypes.instanceOf(Array),
  beneficiaries: PropTypes.instanceOf(Array),
  isFetchingBeneficiaries: PropTypes.bool,
  setBeneficiariesQuery: PropTypes.func,
  bulkPaymentData: PropTypes.instanceOf(Object),
  account: PropTypes.instanceOf(Object),
  predefinedAccountId: PropTypes.string,
  setTotals: PropTypes.func.isRequired,
  setCurrency: PropTypes.func.isRequired,
  setAccount: PropTypes.func.isRequired,
  setCurrentView: PropTypes.func.isRequired,
  setNumberOfDisplayedBeneficiaries: PropTypes.func.isRequired,
  setBulkPaymentData: PropTypes.func.isRequired,
};

export default BulkPaymentsForm;
