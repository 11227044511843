import React, {
  memo,
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Calendar } from '@general/intergiro-ui-kit';

import {
  CheckboxGroup,
  DownloadStatementsButton,
  DownloadStatementsModal,
  FilterBar,
  FilterBarMobile,
} from 'components';
import AccountsFilter from 'components/filter-bar/components/accounts';
import DateRangeFilter from 'components/filter-bar/components/daterange';
import StatusFilter from 'components/filter-bar/components/status';
import TypeFilter from 'components/filter-bar/components/type';
import FilterDropdown from 'components/filter-bar-mobile/components/filter-dropdown';
import { UserContext } from 'contexts/user';
import { useFilters } from 'hooks';
import { filterNames, dataTestIds } from 'pages/activity/constants';
import {
  getActiveCheckboxValues,
  getAllSelectedCount,
  getInvertedCheckboxList,
  getSelectedAllCheckboxesList,
  getURLWithAlteredParams,
  setFiltersToUnchecked,
  transformDateToURL,
} from 'utils/helpers';
import { doesDateHasBothRanges } from 'utils/date-time';
import { dateRangeResetValues } from 'constants/date-time';

function ActivityFilterBar() {
  const {
    permissions: { canViewAccounts, canDownloadStatements },
    tokenPayload: { permissions: userPermissions },
  } = useContext(UserContext);
  const [filterNumber] = useState(5 - (canViewAccounts ? 0 : 1) - (canDownloadStatements ? 0 : 1));
  const [ isOpen, setIsOpen ] = useState(false);
  const { filters, setFilters } = useFilters(canViewAccounts, userPermissions);
  const history = useHistory();
  const [ dateRange, setDateRange ] = useState(null);
  const [ initialAccountList, setInitialAccountList ] = useState([]);
  const [ accountDraftList, setAccountDraftList ] = useState([]);
  const [ typeDraftList, setTypeDraftList ] = useState(filters.type);
  const [ statusDraftList, setStatusDraftList ] = useState(filters.status);
  const [ dateDraft, setDateDraft ] = useState(filters.date);
  const [ selectedCount, setSelectedCount ] = useState(getAllSelectedCount(filters));
  const [ downloadStatementsModalIsOpen, setDownloadStatementsModalIsOpen ] = useState(false);
  const isFilterCancelling = useRef(false);
  const wrapperRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (initialAccountList.length === 0) {
      setInitialAccountList(filters?.accounts);
    }
    if (accountDraftList.length === 0) {
      setAccountDraftList(filters?.accounts);
    }
  }, [filters?.accounts]);

  const openFilterBar = useCallback(() => {
    setIsOpen(true);
  }, []);

  const closeFilterBar = useCallback(() => {
    setIsOpen(false);
  }, []);

  const onAccountChange = useCallback(event => {
    const selectedId = event.target.name;
    const updatedList = getInvertedCheckboxList(selectedId, accountDraftList);
    setAccountDraftList(updatedList);
  }, [accountDraftList]);

  const onAccountSelectAll = useCallback(() => {
    const updatedAllSelected = getSelectedAllCheckboxesList(accountDraftList);
    setAccountDraftList(updatedAllSelected);
  }, [accountDraftList]);

  const onTypeChange = useCallback(event => {
    const selectedId = event.target.name;
    const updatedList = getInvertedCheckboxList(selectedId, typeDraftList);
    setTypeDraftList(updatedList);
  }, [typeDraftList]);

  const onTypeSelectAll = useCallback(() => {
    const updatedAllSelected = getSelectedAllCheckboxesList(typeDraftList);
    setTypeDraftList(updatedAllSelected);
  }, [typeDraftList]);

  const onStatusChange = useCallback(event => {
    const selectedId = event.target.name;
    const updatedList = getInvertedCheckboxList(selectedId, statusDraftList);
    setStatusDraftList(updatedList);
  }, [statusDraftList]);

  const onStatusSelectAll = useCallback(() => {
    const updatedAllSelected = getSelectedAllCheckboxesList(statusDraftList);
    setStatusDraftList(updatedAllSelected);
  }, [statusDraftList]);

  const onDateRangeChange = useCallback(newDate => {
    if (!newDate) {
      setDateDraft(dateRangeResetValues);

      return;
    }

    setDateDraft(newDate);
  }, []);

  function getUpdatedFiltersState() {
    return {
      [filterNames.date.general]: dateDraft,
      [filterNames.status]: statusDraftList,
      [filterNames.type]: typeDraftList,
      [filterNames.accounts]: accountDraftList,
    };

  }

  const applyFilters = useCallback(() => {
    const updatedFiltersState = getUpdatedFiltersState();
    setFilters(updatedFiltersState);
    const typeURL = getURLWithAlteredParams(
      filterNames.type,
      getActiveCheckboxValues(typeDraftList),
      '',
      history.location.search,
    );
    const statusURL = getURLWithAlteredParams(
      filterNames.status,
      getActiveCheckboxValues(statusDraftList),
      '',
      typeURL,
    );
    const accountURL = getURLWithAlteredParams(
      filterNames.accounts,
      getActiveCheckboxValues(accountDraftList),
      '',
      statusURL,
    );

    const dateURL = transformDateToURL(dateDraft, accountURL, history.location.pathname);
    history.push(dateURL);

    setSelectedCount(getAllSelectedCount(updatedFiltersState));
  }, [
    dateDraft,
    getActiveCheckboxValues,
    getUpdatedFiltersState,
    history,
    setFilters,
    statusDraftList,
    typeDraftList,
    accountDraftList,
    filterNames,
  ]);

  const cancelFilters = useCallback(() => {
    setStatusDraftList(setFiltersToUnchecked(filters.status));
    setTypeDraftList(setFiltersToUnchecked(filters.type));
    setAccountDraftList(setFiltersToUnchecked(initialAccountList));

    setDateDraft(dateRangeResetValues);

    setFilters(getUpdatedFiltersState());
    isFilterCancelling.current = true;
    closeFilterBar();
  }, [ filters, initialAccountList, isFilterCancelling ]);

  useEffect(() => {
    setDateRange(doesDateHasBothRanges(filters.date) ? filters.date : null);
  }, [filters.date]);

  useEffect(() => {
    if (isFilterCancelling.current) {
      applyFilters();
      isFilterCancelling.current = false;
    }
  }, [filters]);

  return (
    <div>
      <FilterBarMobile
        cancelFilters={cancelFilters}
        closeFilterBar={closeFilterBar}
        isOpen={isOpen}
        openFilterBar={openFilterBar}
        selectedCount={selectedCount}
        applyFilters={applyFilters}
        setDownloadStatementsModalIsOpen={setDownloadStatementsModalIsOpen}
        showDownloadStatements={canDownloadStatements}
        wrapperRef={wrapperRef}
      >
        {canViewAccounts && (
          <FilterDropdown title={t('activity.activityFilterBar.filterDropdown.account.title')}>
            <CheckboxGroup
              groupName={t('activity.activityFilterBar.filterDropdown.account.checkboxGroup.name')}
              onChange={onAccountChange}
              onSelectAll={onAccountSelectAll}
              list={accountDraftList}
              testId={dataTestIds.accountsSelectAllDataTestId}
            />
          </FilterDropdown>
        )}

        <FilterDropdown title={t('activity.activityFilterBar.filterDropdown.type.title')}>
          <CheckboxGroup
            groupName={t('activity.activityFilterBar.filterDropdown.type.checkboxGroup.name')}
            onChange={onTypeChange}
            onSelectAll={onTypeSelectAll}
            list={typeDraftList}
            testId={dataTestIds.typesSelectAllDataTestId}
          />
        </FilterDropdown>

        <FilterDropdown title={t('activity.activityFilterBar.filterDropdown.status.title')}>
          <CheckboxGroup
            groupName={t('activity.activityFilterBar.filterDropdown.status.checkboxGroup.name')}
            onChange={onStatusChange}
            onSelectAll={onStatusSelectAll}
            list={statusDraftList}
            testId={dataTestIds.statusesSelectAllDataTestId}
          />
        </FilterDropdown>

        <FilterDropdown title={t('activity.activityFilterBar.filterDropdown.date.title')}>
          <Calendar
            value={dateDraft}
            onChange={onDateRangeChange}
            hideActionButtons
          />
        </FilterDropdown>
      </FilterBarMobile>
      <FilterBar filterNumber={filterNumber}>
        {canViewAccounts && <AccountsFilter />}
        <TypeFilter userPermissions={userPermissions} />
        <StatusFilter canViewAccounts={canViewAccounts} />
        <DateRangeFilter />
        {canDownloadStatements && (
          <DownloadStatementsButton setIsOpen={setDownloadStatementsModalIsOpen} />
        )}
      </FilterBar>

      {/* Modals */}
      {downloadStatementsModalIsOpen && (
        <DownloadStatementsModal
          isOpen={downloadStatementsModalIsOpen}
          setIsOpen={setDownloadStatementsModalIsOpen}
          isAccountDisabled={false}
          accountList={filters.accounts}
          defaultDateRange={dateRange}
        />
      )}
    </div>
  );
}

export default memo(ActivityFilterBar);
