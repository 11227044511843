import PropTypes from 'prop-types';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import { blockUser, unblockUser } from 'api/users';
import { ReactComponent as ArrowCounterclockwiseIcon } from 'assets/icons/arrow-counterclockwise.svg';
import { ReactComponent as BlockIcon } from 'assets/icons/block.svg';
import { UserCard, Details } from 'components';
import { showGeneralToastError } from 'utils/errors';
import { userStatusesMap } from 'constants/user';
import { GlobalLoaderContext } from 'contexts/global-loader';
import ConfirmationModal from 'pages/users/components/confirmation-modal';

import S from './styles';
import { mapUserDetails } from './helpers';
import EditUserDetailsModal from '../edit-user-details-modal';
import { userDetailsFields } from './constants';

function UserDetails({ user, setUser, isEditable }) {
  const [ editUserDetailsModalIsOpen, setEditUserDetailsModalIsOpen ] = useState(false);
  const [ confirmationModalIsOpen, setConfirmationModalIsOpen ] = useState(false);
  const [ action, setAction ] = useState(null);

  const { startLoading, endLoading } = useContext(GlobalLoaderContext);

  const isBlocked = user.status === userStatusesMap.blocked;
  const fullName = `${user.firstName} ${user.lastName}`;

  const userData = mapUserDetails(user);

  const handleEdit = useCallback(() => {
    setEditUserDetailsModalIsOpen(true);
  }, []);

  const handleBlock = useCallback(() => {
    setAction('block');
    setConfirmationModalIsOpen(true);
  }, []);

  const handleRestore = useCallback(() => {
    setAction('restore');
    setConfirmationModalIsOpen(true);
  }, []);

  const handleConfirm = useCallback(async () => {
    startLoading();

    try {
      const updatedUser = action === 'block'
        ? await blockUser(user.id)
        : await unblockUser(user.id);

      setUser(updatedUser);
    } catch (error) {
      showGeneralToastError(error);
    } finally {
      endLoading();
      setConfirmationModalIsOpen(false);
    }
  }, [ user.id, startLoading, action, setUser, endLoading ]);

  const userDetailsList = useMemo(() =>
    userDetailsFields.map(({ label, field, formatValue }) => {
      const value = get(userData, field);
      const payload = { handleEdit };

      return {
        label,
        value: formatValue ? formatValue(value, payload) : value,
      };
    }), [userData]);

  if (isEmpty(user)) {
    return null;
  }

  return (
    <>
      <S.Wrapper>
        <UserCard
          userName={fullName}
          status={user.status}
          email={user.email}
        />
        <Details list={userDetailsList} />
      </S.Wrapper>
      {isEditable && (
        <div>
          {isBlocked
            ? <S.Button label="Restore user" icon={<ArrowCounterclockwiseIcon />} category="secondary" onClick={handleRestore} />
            : <S.Button label="Block user" icon={<BlockIcon />} type="primary" isDanger onClick={handleBlock} />}
        </div>
      )}

      {/* Modals */}
      {editUserDetailsModalIsOpen && (
        <EditUserDetailsModal
          isOpen={editUserDetailsModalIsOpen}
          setIsOpen={setEditUserDetailsModalIsOpen}
          user={user}
          setUser={setUser}
        />
      )}
      {confirmationModalIsOpen && (
        <ConfirmationModal
          isOpen={confirmationModalIsOpen}
          setIsOpen={setConfirmationModalIsOpen}
          onSubmit={handleConfirm}
          action={action}
          userFullName={fullName}
        />
      )}
    </>
  );
}

UserDetails.propTypes = {
  user: PropTypes.shape.isRequired,
  setUser: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
};

export default UserDetails;
