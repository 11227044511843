import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ModalContent } from 'components';
import { MODAL_TYPE } from 'constants/modal';
import { navigation } from 'constants/navigation';

import S from './styles';

function CancelFlowModal({ isOpen, setIsOpen, cardType }) {
  const history = useHistory();
  const { t } = useTranslation();
  const CTAButtons = (
    <>
      <S.Button label={t('orderCards.cancelModal.cancel')} category="primary" type="submit" onClick={() => history.replace(navigation.cards)} />
      <S.Button label={t('orderCards.cancelModal.continue')} category="secondary" type="submit" onClick={() => setIsOpen(false)} />
    </>
  );

  return (
    <ModalContent
      title={t('orderCards.cancelModal.title', { cardType: cardType?.toLowerCase() })}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      CTAButtons={CTAButtons}
      type={MODAL_TYPE.message}
      hasCloseIcon
    >
      <S.Message>
        {t('orderCards.cancelModal.text')}
      </S.Message>
    </ModalContent>
  );
}

CancelFlowModal.propTypes = {
  cardType: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default CancelFlowModal;
