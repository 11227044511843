import { ErrorCodes } from '@general/error-codes';
import { Input } from '@general/intergiro-ui-kit';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { showGeneralToastError, errorsConsistOneOfErrorCodes } from 'utils/errors';
import { minutesTill } from 'utils/time';
import { BAD_REQUEST, TOO_MANY_REQUESTS } from 'constants/status-code';
import { ERRORS } from 'constants/errors';
import { signin } from 'api/auth';

import S from './styles';
import { LoginSchema as validationSchema } from './schema';

function LoginForm({ onSubmit }) {
  const { t } = useTranslation();

  const logIn = async values => {
    try {
      const response = await signin(values);

      await onSubmit({ ...response, ...values });
    } catch (error) {
      if (error.status === TOO_MANY_REQUESTS) {
        toast.error(ERRORS.tryInXMinutes(minutesTill(error.data.newChallengeAvailableAt?.message)));

        return;
      }

      if (error.status === BAD_REQUEST) {
        setErrors({
          email: error.data.email?.message,
          password: error.data.default?.message,
        });

        return;
      }

      if (errorsConsistOneOfErrorCodes(error.data, [ ErrorCodes.messageBlocked, ErrorCodes.regionBlocked ])) {
        toast.error(error.data.default?.message, { autoClose: false });

        return;
      }

      showGeneralToastError(error);
    }
  };

  const formik = useFormik({
    initialValues: { email: '', password: '' },
    onSubmit: logIn,
    validationSchema,
  });

  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    setErrors,
    values,
    touched,
    errors,
    isSubmitting,
  } = formik;

  return (
    <S.Wrapper>
      <S.Title>
        {t('login.form.title')}
      </S.Title>
      <S.Form onSubmit={handleSubmit}>
        <Input label={t('login.form.input.emailLabel')}
          onChange={handleChange}
          value={values.email}
          id="email"
          name="email"
          type="email"
          data-testid="email"
          disabled={isSubmitting}
          error={touched.email && errors.email}
          onClear={() => setFieldValue('email', '')}
          autoFocus
        />
        <Input label={t('login.form.input.passwordLabel')}
          onChange={handleChange}
          id="password"
          name="password"
          isPassword
          disabled={isSubmitting}
          error={touched.password && errors.password}
        />
        <S.ControlWrapper>
          <S.Button type="submit" size="large" label={t('login.form.button.login')} disabled={isSubmitting} />
        </S.ControlWrapper>
      </S.Form>
    </S.Wrapper>
  );
}

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default LoginForm;
