import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import { ReactComponent as CrossSmallIcon } from 'assets/icons/cross-small.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';

import S from './styles';

function PermissionsList({ permissionCategories, disabled, handlePermissionChange }) {
  return (
    <div>
      {permissionCategories.map((category, index) => (
        <PermissionCategory
          permissionCategory={category}
          key={category.title}
          path={`permissionCategories[${index}]`}
          disabled={disabled}
          handlePermissionChange={handlePermissionChange}
        />
      ))}
    </div>
  );
}

function PermissionCategory({ permissionCategory, disabled, path, handlePermissionChange }) {
  return (
    <div>
      <S.CategoryTitle>
        {permissionCategory.title}
      </S.CategoryTitle>
      {permissionCategory.description && (
        <S.CategoryDescription>
          {permissionCategory.description}
        </S.CategoryDescription>
      )}
      {permissionCategory.groups.map((group, index) => (
        <PermissionGroup
          group={group}
          key={group.key}
          path={`${path}.groups[${index}]`}
          disabled={disabled}
          handlePermissionChange={handlePermissionChange}
        />
))}
    </div>
  );
}

function PermissionGroup({ group, path, disabled, handlePermissionChange }) {
  const handleClick = useCallback((key, cPath) => {
    !disabled && handlePermissionChange(key, cPath);
  }, [handlePermissionChange]);

  return (
    <S.Group>
      <S.GroupTitle>
        {group.title}
      </S.GroupTitle>
      <div>
        {group.permissions.map((permission, index) => (
          <S.Button
            key={permission.key}
            selected={permission.value}
            disabled={disabled}
            onClick={() => handleClick(permission.key, `${path}.permissions[${index}]`)}
          >
            {permission.value ? <CheckIcon /> : <CrossSmallIcon />}
            {' '}
            {permission.title}
          </S.Button>
        ))}
      </div>
    </S.Group>
  );
}

PermissionsList.propTypes = {
  permissionCategories: PropTypes.arrayOf.isRequired,
  disabled: PropTypes.bool.isRequired,
  handlePermissionChange: PropTypes.func,
};

PermissionCategory.propTypes = {
  permissionCategory: PropTypes.shape.isRequired,
  path: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  handlePermissionChange: PropTypes.func,
};

PermissionGroup.propTypes = {
  group: PropTypes.shape.isRequired,
  path: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  handlePermissionChange: PropTypes.func,
};

// TODO remove default props for required fields
PermissionsList.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  permissionCategories: [],
  // eslint-disable-next-line react/default-props-match-prop-types
  disabled: false,
  handlePermissionChange: () => {},
};

export default PermissionsList;
