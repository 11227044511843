import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { printFormat as formatIban } from 'iban';
import { useTranslation } from 'react-i18next';
import sortBy from 'lodash/sortBy';
import intersection from 'lodash/intersection';

import { CURRENCY_FLAGS, CURRENCIES } from 'constants/currencies';
import { navigation } from 'constants/navigation';
import { UserContext } from 'contexts/user';
import { labelShape } from 'pages/accounts/components/prop-types';
import { supportedUIPaymentRails } from 'constants/payment-processor';

import S from './styles';
import DotsActionDropdown from './dots-action-dropdown';

function AccountItem({
  isGrid,
  id,
  displayName,
  number,
  currency,
  supportedRails,
  labels,
  isHidden,
  setSelectedAccountDetails,
  setIsHidingModalOpen,
  setIsRenamingModalOpen,
  amountFormatted,
  decimalFormatted,
  hasNegativeBalance,
  toggleIsAccountHidden,
  balance,
  isMainAccount,
}) {
  const { t } = useTranslation();
  const { permissions: { canManageAccounts } } = useContext(UserContext);
  const [ uiLabels, setUiLabels ] = useState();

  useEffect(() => {
    const sortedLabels = sortBy(labels, ({ isManageable }) => isManageable)
      .filter(({ isManageable } ) => currency === CURRENCIES.EUR ? true : isManageable )
      .map(({ label, isManageable }) => (
        <S.Label key={`${label}_${isManageable}`}>
          {label}
        </S.Label>
      ));

    setUiLabels(sortedLabels);
  }, [labels]);

  const iban = useMemo(
    () => currency === CURRENCIES.EUR &&
    intersection(supportedUIPaymentRails, supportedRails).length > 0 ?
      formatIban(number) :
      null
    , [ supportedRails, number ],
  );

  const accountDetails = {
    id, displayName, isHidden, currency, labels, supportedRails,
  };

  return (
    <S.AccountLink to={`${navigation.account}/${id}`} $isGrid={isGrid} $isHidden={isHidden}>
      { canManageAccounts && (
        <DotsActionDropdown
          accountDetails={accountDetails}
          setSelectedAccountDetails={setSelectedAccountDetails}
          setIsHidingModalOpen={setIsHidingModalOpen}
          toggleIsAccountHidden={toggleIsAccountHidden}
          setIsRenamingModalOpen={setIsRenamingModalOpen}
          balance={balance}
          isMainAccount={isMainAccount}
        />
      )}
      <S.Name $isGrid={isGrid}>
        {displayName}
      </S.Name>
      <S.Iban $isGrid={isGrid}>
        {iban}
      </S.Iban>

      <S.Flag countryCode={CURRENCY_FLAGS[currency]} $isGrid={isGrid} />

      <S.Balance $isGrid={isGrid}>
        {currency}
          &nbsp;
        <S.BalanceAmount isDanger={hasNegativeBalance}>
          {amountFormatted}
          .
          <S.Decimal>
            {decimalFormatted}
          </S.Decimal>
        </S.BalanceAmount>
      </S.Balance>

      <S.LabelsWrapper $isGrid={isGrid}>
        {
            isMainAccount && (
              <S.Label>
                {t('accounts.mainAccountLabel')}
              </S.Label>
            )
          }
        {uiLabels}
      </S.LabelsWrapper>
    </S.AccountLink>
  );
}

AccountItem.propTypes = {
  isMainAccount: PropTypes.bool,
  isGrid: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  supportedRails: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
  labels: PropTypes.arrayOf(
    labelShape,
  ),
  number: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  isHidden: PropTypes.bool.isRequired,
  setSelectedAccountDetails: PropTypes.func.isRequired,
  setIsHidingModalOpen: PropTypes.func.isRequired,
  setIsRenamingModalOpen: PropTypes.func.isRequired,
  amountFormatted: PropTypes.string.isRequired,
  decimalFormatted: PropTypes.string.isRequired,
  hasNegativeBalance: PropTypes.bool.isRequired,
  toggleIsAccountHidden: PropTypes.func.isRequired,
  balance: PropTypes.number.isRequired,
};

export default AccountItem;
