import capitalize from 'lodash/capitalize';
import truncate from 'lodash/truncate';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import { blockUser, unblockUser } from 'api/users';
import { DotsActionDropdown, Hint } from 'components';
import UserStatus from 'components/user-status';
import { showGeneralToastError } from 'utils/errors';
import { navigation } from 'constants/navigation';
import { activatedUserStatuses, userStatusesMap } from 'constants/user';
import { GlobalLoaderContext } from 'contexts/global-loader';
import { UserContext } from 'contexts/user';

import S from './styles';
import ConfirmationModal from '../confirmation-modal';

const ROLES_MAX_LENGTH = 26;

function UserItem({ user, openToTop }) {
  const history = useHistory();
  const { t } = useTranslation();
  const { startLoading, endLoading } = useContext(GlobalLoaderContext);
  const { permissions: { canUpdateUser } } = useContext(UserContext);

  const [ status, setStatus ] = useState(user.status);
  const [ confirmationModalIsOpen, setConfirmationModalIsOpen ] = useState(false);
  const [ action, setAction ] = useState(null);

  const isUserActivated = activatedUserStatuses.includes(user?.status) || false;

  const isDimmed = status === userStatusesMap.notInvited;
  const isBlocked = status === userStatusesMap.blocked;
  const isEditable = canUpdateUser && isUserActivated;

  const fullName = `${user.firstName} ${user.lastName}`;

  const handleActionDropdownClick = e => {
    e.stopPropagation();
  };

  const handleView = useCallback(() => {
    history.push(`${navigation.users}/${user.id}`);
  }, [ history, user.id ]);

  const handleBlock = useCallback(event => {
    event.stopPropagation();

    setAction('block');
    setConfirmationModalIsOpen(true);
  }, []);

  const handleRestore = useCallback(event => {
    event.stopPropagation();

    setAction('restore');
    setConfirmationModalIsOpen(true);
  }, []);

  const handleConfirm = useCallback(async () => {
    startLoading();

    try {
      const data = action === 'block'
        ? await blockUser(user.id)
        : await unblockUser(user.id);

      setStatus(data.status);
    } catch (error) {
      showGeneralToastError(error);
    } finally {
      endLoading();
      setConfirmationModalIsOpen(false);
    }
  }, [ user.id, action, startLoading, endLoading ]);

  function getFullRolesList(roles) {
    return roles.map(role => capitalize(role.name)).join(', ');
  }

  function getTruncatedRolesList(roles) {
    const rolesList = roles.map(role => capitalize(role.name)).join(', ');

    return truncate(rolesList, { length: ROLES_MAX_LENGTH });
  }

  return (
    <>
      <S.User onClick={handleView}>
        <S.Profile>
          <S.Avatar
            name={`${user.firstName} ${user.lastName}`}
            small
            dimmed={isDimmed}
          />
          <S.UserName dimmed={isDimmed}>
            {fullName}
          </S.UserName>
        </S.Profile>
        <S.Email>
          {user.email}
        </S.Email>
        <S.RolesWrapper>
          {getFullRolesList(user.roles).length > ROLES_MAX_LENGTH
            ? (
              <Hint text={getFullRolesList(user.roles)}>
                <S.Roles>
                  {getTruncatedRolesList(user.roles)}
                </S.Roles>
              </Hint>
              )
            : (
              <S.Roles>
                {getFullRolesList(user.roles)}
              </S.Roles>
              )}
        </S.RolesWrapper>
        <S.Status>
          <UserStatus status={status} />
        </S.Status>
        <S.Actions onClick={handleActionDropdownClick}>
          <DotsActionDropdown
            openToTop={openToTop}
            list={[
              <S.Action onClick={handleView}>
                {t('user.actions.view')}
              </S.Action>,
              ...isEditable && isBlocked ? [
                <S.Action onClick={handleRestore}>
                  {t('user.actions.restore')}
                </S.Action>,
              ] : [],
              ...isEditable && !isBlocked ? [
                <S.Action red onClick={handleBlock}>
                  {t('user.actions.block')}
                </S.Action>,
              ] : [],
            ]}
          />
        </S.Actions>
      </S.User>
      {confirmationModalIsOpen && (
        <ConfirmationModal
          isOpen={confirmationModalIsOpen}
          setIsOpen={setConfirmationModalIsOpen}
          onSubmit={handleConfirm}
          action={action}
          userFullName={fullName}
        />
      )}
    </>
  );
}

UserItem.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    roles: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string.isRequired,
    })),
    status: PropTypes.string,
  }),
  openToTop: PropTypes.bool,
};

export default UserItem;
