import { useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';

import { GlobalLoaderContext } from 'contexts/global-loader';
import TwoFaProvider from 'contexts/two-fa';
import { INPUT_PATTERNS } from 'constants/inputs';
import { showGeneralToastError, errorsConsistOneOfErrorCodes, rethrowTwoFaError } from 'utils/errors';
import {
  changePinForm,
  confirmationPinFieldName,
  pinFieldName,
  pinValidationErrors,
  cardScaActions,
  cardScaAuthenticationElements,
} from 'constants/card';
import { ModalContent, TwoFaModal } from 'components';
import { getScaData, getConfirmScaData } from 'utils/card';
import { createSca, confirmSca, setPin as setPinPci } from 'api/card';

import S from './styles';
import { PinSchema as validationSchema } from './schema';

function ChangePinModal({ id, isOpen, setIsOpen }) {
  const { startLoading, endLoading } = useContext(GlobalLoaderContext);
  const [ pin, setPin ] = useState('');
  const [ confirmationPin, setConfirmationInput ] = useState('');
  const [ twoFaModalIsOpen, setTwoFaModalIsOpen ] = useState(false);
  const [ twoFaData, setTwoFaData ] = useState(null);

  const onSubmit = async () => {
    startLoading();

    try {
      const sca = await createSca({
        action: cardScaActions.setCardPin,
        authenticationElements: [ cardScaAuthenticationElements.accessToken, cardScaAuthenticationElements.smsOtp ],
      });
      const challenge = getScaData(sca);

      setTwoFaData(challenge);
      setTwoFaModalIsOpen(true);
    } catch (error) {
      showGeneralToastError(error);
    } finally {
      endLoading();
    }
  };

  const handleConfirmCode = async challenge => {
    if (!isEmpty(errors)) {
      return;
    }

    const data = getConfirmScaData(challenge);

    setSubmitting(true);
    startLoading();

    try {
      const { authenticationCode } = await confirmSca(challenge.scaId, data);
      await setPinPci(id, { pin }, authenticationCode);

      toast.success('PIN code was successfully changed');
      setIsOpen(false);
    } catch (error) {
      rethrowTwoFaError(error);

      if (errorsConsistOneOfErrorCodes(error.data, pinValidationErrors)) {
        setErrors(error.data);
        setTwoFaModalIsOpen(false);

        return;
      }

      showGeneralToastError(error);
    } finally {
      setSubmitting(false);
      endLoading();
    }
  };

  const handleResendCode = async () => {
    const sca = await createSca({
      action: cardScaActions.setCardPin,
      authenticationElements: [ cardScaAuthenticationElements.accessToken, cardScaAuthenticationElements.smsOtp ],
    });
    const challenge = getScaData(sca);

    setTwoFaData(challenge);

    return { challenge };
  };

  const formik = useFormik({
    initialValues: { pin, confirmationPin },
    onSubmit,
    validationSchema,
  });

  const {
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    setSubmitting,
    setErrors,
    touched,
    errors,
    isSubmitting,
    isValid,
    dirty,
  } = formik;

  const handleChange = (value, fieldName, setFunction) => {
    setFunction(value);
    setFieldValue(fieldName, value);
  };

  const handleBlur = fieldName => {
    setFieldTouched(fieldName, true);
  };

  const disableChangeButton = isSubmitting || !isValid || !dirty;

  const CTAButtons = (
    <>
      <S.Button label="Change" category="primary" type="submit" disabled={disableChangeButton} />
      <S.Button label="Cancel" category="secondary" onClick={() => setIsOpen(false)} />
    </>
  );

  return (
    <>
      <ModalContent
        title="Change PIN"
        hasCloseIcon
        isOpen={isOpen}
        CTAButtons={CTAButtons}
        setIsOpen={setIsOpen}
        formName={changePinForm}
        onSubmit={handleSubmit}
      >
        <S.HelperText>Please choose a 4-digit PIN</S.HelperText>
        <S.Input
          onBlur={() => handleBlur(pinFieldName)}
          onChange={e => handleChange(e.target.value, pinFieldName, setPin)}
          id={pinFieldName}
          name={pinFieldName}
          isPassword
          label="PIN"
          value={pin}
          disabled={isSubmitting}
          error={touched.pin && (errors.pin?.message || errors.pin)}
          inputPattern={INPUT_PATTERNS.pin}
        />
        <S.Input
          onBlur={() => handleBlur(confirmationPinFieldName)}
          onChange={e => handleChange(e.target.value, confirmationPinFieldName, setConfirmationInput)}
          id={confirmationPinFieldName}
          name={confirmationPinFieldName}
          isPassword
          label="Confirm PIN"
          value={confirmationPin}
          disabled={isSubmitting}
          error={touched.confirmationPin && errors.confirmationPin}
          inputPattern={INPUT_PATTERNS.pin}
        />
      </ModalContent>
      {twoFaModalIsOpen && (
        <TwoFaProvider twoFaData={twoFaData}>
          <TwoFaModal
            isOpen={twoFaModalIsOpen}
            setIsOpen={setTwoFaModalIsOpen}
            onSubmit={handleConfirmCode}
            onResend={handleResendCode}
            challenge={twoFaData}
          />
        </TwoFaProvider>
      )}
    </>
  );
}

ChangePinModal.propTypes = {
  id: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
};

export default ChangePinModal;
