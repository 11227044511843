import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';

const ListContainer = styled.div`
  @media ${DEVICE.tablet} {
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: 100%;
  }
`;

export default {
  ListContainer,
};
