import { Input as DataInput } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';

const Input = styled(DataInput)`
  width: 100%;

  @media ${DEVICE.laptop} {
    max-width: 30rem;
  }
`;

export default {
  Input,
};
