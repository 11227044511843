import PropTypes from 'prop-types';
import React, { memo, useEffect, useState } from 'react';

import S from './styles';

function Amount({ amount, currency, indicator, applyCustomFormatting }) {
  const [ readyToBeRendered, setReadyToBeRendered ] = useState(false);
  const [ wholeAmount, setWholeAmount ] = useState();
  const [ decimalAmount, setDecimalAmount ] = useState();

  useEffect(() => {
    if (applyCustomFormatting) {
      const [ wholePart, decimalPart ] = amount.split('.');
      setWholeAmount(wholePart);
      setDecimalAmount(decimalPart);
    }

    setReadyToBeRendered(true);
  }, [ indicator, amount, currency, applyCustomFormatting ]);

  if (!readyToBeRendered) return null;

  if (!applyCustomFormatting) {
    return (
      <S.Amount data-testid="transaction-amount-wrapper">
        {indicator}
        {' '}
        {amount}
        {' '}
        {currency}
      </S.Amount>
    );
  }

  return (
    <S.CustomFormattedAmount data-testid="transaction-amount-wrapper">
      {indicator}
      {' '}
      {wholeAmount}
      .
      <S.DecimalAmount>
        {decimalAmount}
      </S.DecimalAmount>
      {' '}
      <S.Currency>
        {currency}
      </S.Currency>
    </S.CustomFormattedAmount>
  );
}

Amount.propTypes = {
  amount: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  indicator: PropTypes.string.isRequired,
  applyCustomFormatting: PropTypes.bool,
};

export default memo(Amount);
