import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { CardStatus, LastFourDigits } from 'components';
import { cardStatusesMap, labels, MASTERCARD, PREPAID_FUNDING_TYPE } from 'constants/card';
import { navigation } from 'constants/navigation';
import { cardShape } from 'local-prop-types';
import { formatAmount } from 'utils/amount';
import { formatExpirationDate, getCardHolderTruncatedName } from 'utils/card';

import S from './styles';

function CardListItem({ card }) {
  const isDimmed = useMemo(() => (card.status === cardStatusesMap.notActivated), [card.status]);
  const truncatedName = useMemo(() => getCardHolderTruncatedName(card.cardholder, 26), [card.cardholder]);

  return (
    <Link to={`${navigation.cards}/${card.id}`}>
      <S.Card dimmed={isDimmed}>
        <S.User>
          <S.Avatar
            name={card.cardholder.firstName}
            param1={card.cardholder.lastName}
            param2={card.cardholder.id}
            small
            dimmed={isDimmed}
          />
          <S.Text dimmed={isDimmed}>
            {truncatedName}
          </S.Text>
        </S.User>
        <S.Text dimmed={isDimmed}>
          {card.displayName}
        </S.Text>
        <S.Account>
          {card.account.name}
        </S.Account>
        <S.LastFour dimmed={isDimmed}>
          <LastFourDigits lastFour={card.pan.lastFour} />
        </S.LastFour>
        <S.Expiry dimmed={isDimmed}>
          {formatExpirationDate(card.expiry)}
        </S.Expiry>
        <S.Brand>
          <S.FundingType dimmed={card.fundingType === PREPAID_FUNDING_TYPE || isDimmed}>
            {labels[card.fundingType]}
          </S.FundingType>
          {card.brand === MASTERCARD
            ? <S.Mastercard />
            : <S.Visa />
          }
        </S.Brand>
        <S.BalanceContainer dimmed={isDimmed}>
          <S.BalanceCurrency>
            {card.currency}
          </S.BalanceCurrency>
          {' '}
          <S.BalanceAmount>
            {formatAmount(card.availableBalance)}
          </S.BalanceAmount>
        </S.BalanceContainer>
        <S.Status>
          <CardStatus status={card.status} statusReason={card.statusReason} />
        </S.Status>
      </S.Card>
    </Link>
  );
}

CardListItem.propTypes = cardShape;

export default CardListItem;
