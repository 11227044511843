import PropTypes from 'prop-types';
import React from 'react';

import UserStatus from 'components/user-status';

import S from './styles';

function UserCard({ userName, status, email }) {
  return (
    <S.CardWrapper data-testid="user-card">
      <S.Grid>
        <div>
          {userName && (
            <S.UserName>
              {userName}
            </S.UserName>
          )}
          {status && (
            <S.UserStatusWrapper>
              <UserStatus status={status}/>
            </S.UserStatusWrapper>
          )}
        </div>
        <S.Avatar name={userName} />
      </S.Grid>
      <S.CardFooter>
        <S.Grid>
          <S.Email>
            {email}
          </S.Email>
        </S.Grid>
      </S.CardFooter>
    </S.CardWrapper>
  );
}

UserCard.propTypes = {
  userName: PropTypes.string,
  status: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
};

export default UserCard;
