import { Input } from '@general/intergiro-ui-kit';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import { BAD_REQUEST } from 'constants/status-code';
import { showGeneralToastError } from 'utils/errors';
import { resetPassword } from 'api/auth';

import S from './styles';
import { ResetPasswordSchema } from './schema';

function ResetPasswordForm({ onResetPassword }) {
  const onSubmit = async ({ email }) => {
    try {
      await resetPassword({ email });
      onResetPassword();
    } catch (error) {
      if (error.status === BAD_REQUEST) {
        setErrors(error.data);

        return;
      }

      showGeneralToastError(error);
    }
  };

  const formik = useFormik({
    initialValues: { email: '' },
    onSubmit,
    validationSchema: ResetPasswordSchema,
  });

  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    setErrors,
    values,
    touched,
    errors,
    isSubmitting,
  } = formik;

  return (
    <S.Wrapper>
      <S.Title>Forgot password?</S.Title>
      <S.Paragraph>Enter your email address to reset your password</S.Paragraph>
      <S.Form onSubmit={handleSubmit}>
        <Input label="Email"
          onChange={handleChange}
          value={values.email}
          id="email"
          name="email"
          type="text"
          data-testid="email"
          disabled={isSubmitting}
          error={touched.email && errors.email}
          onClear={() => setFieldValue('email', '')}
          autoFocus
        />
        <S.ControlWrapper>
          <S.Button size="large" type="submit" label="Reset password" disabled={isSubmitting} />
        </S.ControlWrapper>
      </S.Form>
    </S.Wrapper>
  );
}

ResetPasswordForm.propTypes = {
  onResetPassword: PropTypes.func.isRequired,
};

export default ResetPasswordForm;
