import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { cardShape } from 'local-prop-types';
import {
  cardStatusesMap,
  cardStatusReasonMap,
  labels,
  PHYSICAL_CARD_TYPE,
  VISA,
} from 'constants/card';
import { Dot } from 'components';

import ActivateCardModal from './components/activate-card-modal';
import SecurityBlockInfoModal from './components/security-block-info';
import S from './styles';

function CardFront({ card, isMyCard }) {
  const [ isPhysical, setIsPhysical ] = useState(false);
  const [ isOpen, setIsOpen ] = useState(false);
  const [ isDimmed, setIsDimmed ] = useState(false);

  const hasPinRetriesExceeded =
        card.statusReason === cardStatusReasonMap.limitPinAttemptsReached;
  const activeModal = {
    [cardStatusesMap.notActivated]: <ActivateCardModal id={card.id} isOpen={isOpen} setIsOpen={setIsOpen} />,
    [cardStatusesMap.securityBlock]: !hasPinRetriesExceeded ? (
      <SecurityBlockInfoModal isOpen={isOpen} setIsOpen={setIsOpen} />
    )
      : null,
  };
  const displayActivateBtn = isMyCard && isPhysical && activeModal[card.status];

  useEffect(() => {
    setIsDimmed(card.status === cardStatusesMap.notActivated);
    setIsPhysical(card.type === PHYSICAL_CARD_TYPE);
  }, [card]);

  return (
    <>
      <S.Card physical={isPhysical} dimmed={isDimmed}>
        <S.Row>
          <S.Intergiro $physical={isPhysical} $dimmed={isDimmed} />
          <S.Type physical={isPhysical} dimmed={isDimmed}>
            {labels[card.fundingType]}
            {labels[card.fundingType] && <Dot />}
            {labels[card.type]}
          </S.Type>
        </S.Row>
        {card.status === cardStatusesMap.frozen && <S.Frozen $physical={isPhysical} />}
        {displayActivateBtn && <S.ActivateButton label="Activate" category="secondary" physical={isPhysical} onClick={() => setIsOpen(true)} />}
        {isPhysical && <S.Chip $dimmed={isDimmed} />}
        <S.Row>
          <S.Details isPhysical={isPhysical}>
            {card.pan?.lastFour && (
              <S.LastFour physical={isPhysical}>
                ••
                {' '}
                {card.pan.lastFour}
              </S.LastFour>
            )}
            <S.CardName physical={isPhysical} dimmed={isDimmed}>
              {card.displayName}
            </S.CardName>
          </S.Details>
        </S.Row>
        <S.Processor>
          {card.brand === VISA
            ? <S.Visa $physical={isPhysical} $dimmed={isDimmed} />
            : <S.Mastercard $dimmed={isDimmed} />
          }
        </S.Processor>
      </S.Card>
      {isOpen && displayActivateBtn && activeModal[card.status]}
    </>
  );
}

CardFront.propTypes = {
  card: cardShape.card.isRequired,
  isMyCard: PropTypes.bool,
};

export default CardFront;
