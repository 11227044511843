import isEmpty from 'lodash/isEmpty';
import * as transliteration from 'transliteration';

const NUMBERS = '1234567890'.split('');
const ALPHABET = 'abcdefghijklmnopqrstuvwxyz'.toUpperCase().split('');
const EMBOSSED_SPECIAL_CHARACTERS = [ '&', '.', '/', '+', '‘', '-', ' ' ];
const DIACRITICAL_CHARACTERS = 'áäčďéěëíňóöřťúůüýžŕĺľôőű'.toUpperCase().split('');

const ALLOWED_EMBOSSED_SYMBOLS = [
  ...NUMBERS,
  ...ALPHABET,
  ...EMBOSSED_SPECIAL_CHARACTERS,
  ...DIACRITICAL_CHARACTERS,
];
const MAX_LENGTH = 17;
const SEPARATOR_SYMBOLS = [ '-', ' ' ];
const SEPARATOR_PATTERN = new RegExp(`[${SEPARATOR_SYMBOLS.join('')}]`);
const COMPANY_SEPARATOR_SYMBOLS = [ '-', ' ', '/' ];
const COMPANY_SEPARATOR_REPLACE_PATTERN = new RegExp(`[${COMPANY_SEPARATOR_SYMBOLS}]+[^${COMPANY_SEPARATOR_SYMBOLS}]*?$`);

const escapeRegexCharacters = str => str.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
const removeInvalidSymbols = (str, allowedSymbols) => {
  const invalidCharactersPattern = new RegExp(`[^${allowedSymbols.map(escapeRegexCharacters).join('')}]`, 'g');

  return str.replace(invalidCharactersPattern, '');
};
const applySanitizer = (str, sanitizer) => (sanitizer ? sanitizer(str) : str);
const isValidLength = (...strings) => strings.reduce((sum, str) => sum + str.length, 0) <= MAX_LENGTH;
const getFirstPartOfString = str => str.split(SEPARATOR_PATTERN)[0];

function abbreviateString(str) {
  const isSeparatorSymbol = symbol => SEPARATOR_SYMBOLS.includes(symbol);
  const isLastSymbol = (arr, index) => arr.length === index + 1;

  let word = '';

  return str.split('').reduce((
    result, symbol, index, arr,
  ) => {
    if (isSeparatorSymbol(symbol)) {
      const abbreviation = word[0];

      word = '';

      return result.concat(abbreviation, symbol);
    }

    word = word.concat(symbol);

    return isLastSymbol(arr, index) ? result.concat(word) : result;
  }, '');
}

function removeLastNameAbbreviations(firstName, lastName) {
  const words = lastName.split(SEPARATOR_PATTERN);

  if (words.length === 1 || isValidLength(firstName, lastName)) {
    return lastName;
  }

  // remove: abbreviation (one symbol) + separator (one symbol)
  const croppedLastName = lastName.slice(2);

  return removeLastNameAbbreviations(firstName, croppedLastName);
}

export function mapCardHolderFullName(data) {
  if (isEmpty(data)) return null;

  const { firstName, lastName } = prepareEmbossedName(data);

  return (firstName || lastName) ? `${firstName} ${lastName}` : null;
}

function prepareEmbossedName(embossedData) {
  let { firstName = '', lastName = '' } = embossedData;

  firstName = transliterateEmbossedName(firstName);
  lastName = transliterateEmbossedName(lastName);

  if (isValidLength(firstName, lastName)) {
    return { firstName, lastName };
  }

  firstName = getFirstPartOfString(firstName);
  if (isValidLength(firstName, lastName)) {
    return { firstName, lastName };
  }

  lastName = abbreviateString(lastName);
  if (isValidLength(firstName, lastName)) {
    return { firstName, lastName };
  }

  firstName = `${firstName[0]}`;
  if (isValidLength(firstName, lastName)) {
    return { firstName, lastName };
  }

  lastName = removeLastNameAbbreviations(firstName, lastName);
  if (isValidLength(firstName, lastName)) {
    return { firstName, lastName };
  }

  return {
    firstName,
    lastName: lastName.substr(0, MAX_LENGTH - firstName.length),
  };
}

function transliterateEmbossedName(value) {
  return transliterate(value, ALLOWED_EMBOSSED_SYMBOLS, str => str.toUpperCase());
}

/**
 * @param {string} str
 * @param {string[]} allowedSymbols
 * @param {function?} sanitizer
 * @returns {*}
 */

function transliterate(str, allowedSymbols, sanitizer) {
  const transliteratedString = transliteration.transliterate(applySanitizer(str, sanitizer), {
    ignore: allowedSymbols,
  });

  return removeInvalidSymbols(applySanitizer(transliteratedString, sanitizer), allowedSymbols);
}

export function prepareEmbossedCompanyName(companyName) {
  let name = companyName.replace(/\\/g, '/');

  name = transliterateEmbossedName(name);

  if (isValidCompanyNameLength(name)) {
    return name;
  }

  do {
    const previousName = name;
    name = shortenCompanyNameToLastSeparator(name);

    // if they're the same that means there is no more separators to shorten to
    if (name === previousName) {
      break;
    }
  } while (!isValidCompanyNameLength(name));

  if (isValidCompanyNameLength(name)) {
    return name;
  }

  return name.substr(0, MAX_LENGTH);
}

function isValidCompanyNameLength(name) {
  return name.length <= MAX_LENGTH;
}

function shortenCompanyNameToLastSeparator(name) {
  return name.replace(COMPANY_SEPARATOR_REPLACE_PATTERN, '');
}
