import styled, { css } from 'styled-components';

import { CancelButton as Cancel } from 'components';
import { DEVICE, SIZE } from 'constants/breakpoints';

const Wrapper = styled.div`
  padding: 1.875rem 1.25rem 0 1.25rem;
  display: flex;
  flex-direction: column;

  ${props => props.isFullscreen && 'height: 100vh;'}

  @media ${DEVICE.tablet} {
    padding: 3.75rem 2.5rem 0 2.5rem;
  }

  @media ${DEVICE.laptop} {
    padding: 3.75rem 3.75rem 0 3.75rem;
  }

  @media ${DEVICE.wideDesktop} {
    margin: 0 auto;
    padding: unset;
    width: ${SIZE.wideDesktop};
    padding-top: 3.75rem;
  }
`;

const Main = styled.main`
  min-height: 100vh;
  padding-bottom: 1.25rem;

  @media ${DEVICE.tablet} {
    padding-bottom: 0;
  }
`;

const Header = styled.div`
  display: flex;
  padding-bottom: 2.5rem;
  align-items: center;

  @media ${DEVICE.laptop} {
    padding-bottom: 3.75rem;
  }
`;

const Title = styled.p`
  font: ${props => props.theme.fonts.h3Bold};
  padding-left: ${props => props.backButtonHidden ? '0' : '0.75rem'};
  margin: 0;

  @media ${DEVICE.tablet} {
    padding-left: ${props => props.backButtonHidden ? '4.375rem' : '1.875rem'};
    font: ${props => props.theme.fonts.h1Bold};
    flex: 2;
  }

  @media ${DEVICE.laptop} {
    padding-left: ${props => props.backButtonHidden ? '6.75rem' : '3.75rem'};
    font: ${props => props.theme.fonts.h1Bold};
    flex: 2;
  }
`;

const Content = styled.div`
  width: 100%;
  max-width: 30rem;
  margin: 0 auto;
  
  ${props => props.isFullscreen &&
    css`
      flex: 1;
      display: flex;
      flex-direction: column;
    `
  }

  @media ${DEVICE.tablet} {
    width: calc(100% - 4.375rem);
    margin-left: 4.375rem;
    padding: 0;
    max-width: unset;
  }

  @media ${DEVICE.laptop} {
    width: calc(100% - 6.75rem);
    margin-left: 6.75rem;
    padding: 0;
    max-width: unset;
  }
`;

const CancelButton = styled(Cancel)`
  display: none;
  min-width: auto;

  @media ${DEVICE.tablet} {
    display: block;
  }
`;

export default { Main, Header, Title, Content, CancelButton, Wrapper };
