import React from 'react';

import MerchantImage from 'assets/icons/merchants-background.svg';
import config from 'config';

import S from './styles';

function MerchantsPlaceHolder() {
  return (
    <>
      <S.Header>
        <S.Title>Merchants</S.Title>
      </S.Header>
      <S.Container data-testid="merchants-placeholder">
        <S.Image src={MerchantImage} alt="Merchants" />
        <S.Paragraph>
          The “Merchants” feature is our own integrated PSP, which enables you to take payments on your website faster
          and more conveniently.
        </S.Paragraph>
        <S.SubParagraph>
          If you are interested in getting early access to this feature, contact us via
          {' '}
          <S.Link href={`mailto:${config.salesEmail}`}>
            {config.salesEmail}
          </S.Link>
          .
          <br />
        </S.SubParagraph>
        <S.SubParagraph>
          For more information
          {' '}
          <S.Link target="_blank" rel="noopener noreferrer" href={config.merchantPageUrl}>
            visit the Merchants section of our website
          </S.Link>
          .
        </S.SubParagraph>
      </S.Container>
    </>
  );
}

export default MerchantsPlaceHolder;
