import { Button } from '@general/intergiro-ui-kit';
import styled from 'styled-components';
import { Link as DefaultLink } from 'react-router-dom';

import { DEVICE } from 'constants/breakpoints';
import { getColorsByStatus, hex2rgba } from 'helpers/styles';

const SidebarBody = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: calc(100% + 3.5rem);
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  padding-bottom: 0;
  padding-right: 1.25rem;

  @media ${DEVICE.tablet} {
    padding-bottom: 3.3rem;
  }

`;

const SidebarHeader = styled.div`
  height: 3rem;
  width: 3rem;
  margin: 0 auto;
`;

export const Grid = styled.div`
  align-items: ${props => (props.alignTop ? 'start' : 'center')};
  display: ${props => (props.isHidden ? 'none' : 'grid')};
  grid-template-columns: repeat(2, auto);
  grid-gap: 2.5rem;
  justify-content: space-between;
  margin-bottom: ${props => (props.skipBottomMargin ? '0' : '1rem')};
  width: 100%;

  @media ${DEVICE.laptop} {
    margin-bottom: ${props => (props.skipBottomMargin ? '0' : '1.25rem')};
  }
`;

const Separator = styled.hr`
  border-top: ${props => hex2rgba(props.theme.colors.black, 0.1)};
  margin: 0;
  margin-bottom: 1rem;
  width: 100%;
`;

export const Label = styled.span`
  color: ${props => props.theme.colors.greyMedium};
  display: block;
  font: ${props => props.theme.fonts.bodySSemi};
  
  ${props => (props.isSpacedBottom ? 'margin-bottom: 0.5rem' : '')};
  
  @media ${DEVICE.tablet} {
    font: ${props => props.isSmaller ? props.theme.fonts.bodySSemi : props.theme.fonts.bodyMSemi};
  }
`;

const DateLabel = styled(Label)`
  font: ${props => props.theme.fonts.bodyS};
`;

const TransactionStatusWrapper = styled.span`
  color: ${props => getColorsByStatus(props.status, props.theme.colors)};
  display: block;
  font: ${props => props.theme.fonts.bodySBold};
`;

export const Detail = styled.span`
  color: ${props => (props.status ? getColorsByStatus(props.status, props.theme.colors) : props.theme.colors.black)};
  display: block;
  font: ${props => props.theme.fonts.bodySSemi};
  text-align: right;

  ${props => (props.isStronger ? 'font-weight: 600' : '')};
  ${props => (props.isStrong ? 'font-weight: 500' : '')};

  @media ${DEVICE.tablet} {
    font: ${props => props.isSmaller ? props.theme.fonts.bodySSemi : props.theme.fonts.bodyMSemi};
  }
`;

const FooterWrapper = styled.div`
  border-top: 0.0625rem solid ${props => props.theme.colors.greyLight};
  margin-top: auto;
  padding-top: 1.25rem;
  width: 100%;
`;

const ApproveRejectButtonsWrapper = styled.div`
  margin-top: auto;
  margin-bottom: 1.2rem;
  width: 100%;

  @media ${DEVICE.tablet} {
    display: flex;
    margin-top: 1rem;
  }
`;

const TransactionApproveButton = styled(Button)`
  width: 100%;
  background: ${props => props.theme.colors.green};
  border: 0.13rem solid ${props => props.theme.colors.green};
  color: ${props => props.theme.colors.white};
  margin-bottom: 1rem;

  &:enabled:hover {
    background: ${props => props.theme.colors.green};
    border: 0.13rem solid ${props => props.theme.colors.green};
    color: ${props => props.theme.colors.white};
    opacity: 0.8;
  }

  @media ${DEVICE.tablet} {
    margin-right: 1rem;
  }
`;

const TransactionRejectButton = styled(Button)`
  width: 100%;
  background: ${props => props.theme.colors.white};
  border: 0.13rem solid ${props => props.theme.colors.red};
  color: ${props => props.theme.colors.red};

  &:enabled:hover {
    background: ${props => props.theme.colors.white};
    border: 0.13rem solid ${props => props.theme.colors.red};
    color: ${props => props.theme.colors.red};
    opacity: 0.8;
  }
`;

const Link = styled(DefaultLink)`
  font: ${props => props.theme.fonts.bodyMSemi};
  color: ${props => props.theme.colors.blue};
  margin: 0 0 1rem auto;
`;

export default {
  TransactionStatusWrapper,
  Detail,
  FooterWrapper,
  Grid,
  Label,
  Separator,
  SidebarBody,
  SidebarHeader,
  ApproveRejectButtonsWrapper,
  TransactionApproveButton,
  TransactionRejectButton,
  DateLabel,
  Link,
};
