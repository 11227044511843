import { useContext, useEffect, useState } from 'react';

import getApprovedAccountUsers from 'api/persons';
import { GlobalLoaderContext } from 'contexts/global-loader';

const usePersons = () => {
  const { startLoading, endLoading } = useContext(GlobalLoaderContext);
  const [ persons, setPersons ] = useState({});

  useEffect(() => {
    const fetchPersons = async () => {
      startLoading();

      try {
        const data = await getApprovedAccountUsers();
        setPersons(data);
      } catch (error) {
        // do nothing
      } finally {
        endLoading();
      }
    };

    fetchPersons();
  }, [ startLoading, endLoading ]);

  return { persons };
};

export default usePersons;
