import PropTypes from 'prop-types';
import React, { createContext, useContext, useMemo } from 'react';

import { UserContext } from 'contexts/user';
import useAccounts from 'hooks/use-accounts';

export const AccountsContext = createContext({ accounts: [] });

function AccountsProvider({ children, queryParams }) {
  const { permissions: { canViewAccounts } } = useContext(UserContext);
  const { accounts, fetchAccounts } = useAccounts(canViewAccounts, queryParams);

  const accountsContextValues =  useMemo(() => ({ accounts, fetchAccounts }), [ accounts, fetchAccounts ]);

  return (
    <AccountsContext.Provider value={accountsContextValues}>
      {children}
    </AccountsContext.Provider>
  );
}

AccountsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  queryParams: PropTypes.instanceOf(Object),
};

export default AccountsProvider;
