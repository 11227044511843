import PropTypes from 'prop-types';
import React, { memo } from 'react';

import { ReactComponent as ApiPaymentIcon } from 'assets/icons/api-payment.svg';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow-right.svg';
import { ReactComponent as CardIcon } from 'assets/icons/cards-outlined.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { transactionType } from 'constants/transactions';
import {
  isCardTransaction,
  isIncomingTransaction,
  isOutgoingTransaction,
  isTransactionFailed,
} from 'utils/transactions';

import S from './styles';

function TransactionIcon({ type, isActive, status, size }) {
  return (
    <S.IconWrapper isActive={isActive} isDisabled={isTransactionFailed(status)} size={size}>
      {getIconByTransactionType(type)}
    </S.IconWrapper>
  );
}

TransactionIcon.propTypes = {
  type: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  size: PropTypes.number,
};

TransactionIcon.defaultProps = {
  isActive: false,
  size: 2,
};

export default memo(TransactionIcon);

// Private functions

function getIconByTransactionType(type) {
  if (type === transactionType.apiPayment) return <ApiPaymentIcon />;

  // Check for Card transaction type should be the first.
  if (isCardTransaction(type)) return <CardIcon />;

  if (isIncomingTransaction(type)) return <PlusIcon />;

  if (isOutgoingTransaction(type)) return <ArrowIcon />;

  return null;
}
