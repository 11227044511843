import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { t } from 'i18next';

import { AuthorizedRoute, PublicRoute } from 'components/routes';
import { navigation } from 'constants/navigation';
import { permissionsMap } from 'constants/permissions';
import { useChatbot } from 'hooks';
import {
  Account,
  Accounts,
  Activity,
  AddRole,
  API,
  APICreationPage,
  APIPaymentsList,
  Beneficiaries,
  Beneficiary,
  BulkPayments,
  Card,
  Cards,
  Company,
  InviteUser,
  Login,
  Merchant,
  Merchants,
  MoveFunds,
  NotFoundPage,
  OrderCards,
  Payments,
  Profile,
  ResetPassword,
  Role,
  Roles,
  SendFunds,
  SetPassword,
  User,
  Users,
} from 'pages';

function Router() {
  useChatbot();

  return (
    <Switch>
      <PublicRoute path={navigation.login} exact>
        <Login />
      </PublicRoute>

      <PublicRoute path={navigation.resetPassword} exact>
        <ResetPassword />
      </PublicRoute>

      <PublicRoute path={navigation.setPassword} exact>
        <SetPassword />
      </PublicRoute>

      <AuthorizedRoute path={navigation.accounts}
        permissions={{
        list: [permissionsMap.corporateWalletsManagementRead],
        atLeastOne: true,
      }}
        exact
      >
        <Accounts />
      </AuthorizedRoute>

      <AuthorizedRoute path={navigation.beneficiaries}
        permissions={{
          list: [permissionsMap.transactionsManagementCreateTransfer],
          atLeastOne: true,
        }}
        exact
      >
        <Beneficiaries />
      </AuthorizedRoute>

      <AuthorizedRoute path={`${navigation.beneficiaries}/:id`}
        permissions={{
          list: [permissionsMap.transactionsManagementCreateTransfer],
          atLeastOne: true,
        }}
        backButton={{ link: navigation.beneficiaries, label: t('beneficiary.backButton') }}
        exact
      >
        <Beneficiary />
      </AuthorizedRoute>

      <AuthorizedRoute
        path={`${navigation.account}/:id`}
        permissions={{
          list: [permissionsMap.corporateWalletsManagementRead],
          atLeastOne: true,
        }}
        backButton={{ link: '/' }}
        exact
      >
        <Account />
      </AuthorizedRoute>

      <AuthorizedRoute
        path={navigation.activity}
        permissions={{
          list: [permissionsMap.transactionsManagementRead],
          atLeastOne: true,
        }}
        exact
      >
        <Activity />
      </AuthorizedRoute>

      <AuthorizedRoute
        path={navigation.cards}
        permissions={{
          list: [ permissionsMap.individualCardsManagementRead, permissionsMap.otherCardsManagementRead ],
          atLeastOne: true,
        }}
        exact
      >
        <Cards />
      </AuthorizedRoute>

      <AuthorizedRoute
        path={`${navigation.cards}/:id`}
        permissions={{
          list: [ permissionsMap.individualCardsManagementRead, permissionsMap.otherCardsManagementRead ],
          atLeastOne: true,
        }}
        backButton={{ link: navigation.cards }}
        exact
      >
        <Card />
      </AuthorizedRoute>

      <AuthorizedRoute
        path={`${navigation.apiPayments}/:id`}
        permissions={{
          list: [
            permissionsMap.transactionsManagementRead,
            permissionsMap.transactionsManagementApproveTransfer,
          ],
          atLeastOne: false,
        }}
        hideNavigation
        exact
      >
        <APIPaymentsList />
      </AuthorizedRoute>

      <AuthorizedRoute
        path={navigation.payments}
        permissions={{
          list: [
            permissionsMap.transactionsManagementRead,
            permissionsMap.transactionsManagementApproveTransfer,
          ],
          atLeastOne: false,
        }}
      >
        <Payments to={navigation.payments} />
      </AuthorizedRoute>

      <AuthorizedRoute
        path={navigation.merchants}
        permissions={{
          list: [permissionsMap.corporateAccountsManagementRead],
          atLeastOne: true,
        }}
        exact
      >
        <Merchants />
      </AuthorizedRoute>

      <AuthorizedRoute
        path={`${navigation.merchants}/:id`}
        permissions={{
          list: [permissionsMap.corporateAccountsManagementRead],
          atLeastOne: true,
        }}
        backButton={{ link: navigation.merchants, label: 'Merchants' }}
        exact
      >
        <Merchant />
      </AuthorizedRoute>

      <AuthorizedRoute
        path={navigation.api}
        permissions={{
          list: [permissionsMap.corporateApiKeysManagementRead],
          atLeastOne: true,
        }}
        exact
      >
        <API />
      </AuthorizedRoute>

      <AuthorizedRoute
        path={navigation.createApiKey}
        hideNavigation
        exact
        permissions={{
          list: [permissionsMap.corporateApiKeysManagementCreate],
          atLeastOne: true,
        }}
      >
        <APICreationPage />
      </AuthorizedRoute>

      <AuthorizedRoute path={[ `${navigation.profile}/security`, navigation.profile ]} exact>
        <Profile />
      </AuthorizedRoute>

      <AuthorizedRoute
        path={[ navigation.companyVerification, navigation.company ]}
        permissions={{
          list: [permissionsMap.corporateAccountsManagementRead],
          atLeastOne: true,
        }}
        exact
      >
        <Company />
      </AuthorizedRoute>

      <AuthorizedRoute
        path={navigation.users}
        permissions={{
          list: [ permissionsMap.personsManagementRead, permissionsMap.personsRolesManagementRead ],
          atLeastOne: true,
        }}
        exact
      >
        <Users />
      </AuthorizedRoute>

      <AuthorizedRoute
        path={[ `${navigation.users}/:id`, `${navigation.users}/:id/edit` ]}
        permissions={{
          list: [ permissionsMap.personsManagementRead, permissionsMap.personsRolesManagementRead ],
          atLeastOne: true,
        }}
        backButton={{ link: navigation.users, label: 'Users' }}
        exact
      >
        <User />
      </AuthorizedRoute>

      <AuthorizedRoute
        path={navigation.roles}
        permissions={{
          list: [permissionsMap.personsRolesManagementRead],
          atLeastOne: true,
        }}
        exact
      >
        <Roles />
      </AuthorizedRoute>

      <AuthorizedRoute
        path={`${navigation.roles}/:id/edit`}
        permissions={{
          list: [permissionsMap.personsRolesManagementUpdate],
          atLeastOne: true,
        }}
        exact
        hideNavigation
      >
        <Role />
      </AuthorizedRoute>

      <AuthorizedRoute
        path={[ `${navigation.moveFunds}/:id`, navigation.moveFunds ]}
        permissions={{
          list: [
            permissionsMap.corporateWalletsManagementRead,
            permissionsMap.transactionsManagementCreateTransfer,
          ],
          atLeastOne: true,
        }}
        hideNavigation
        exact
      >
        <MoveFunds />
      </AuthorizedRoute>

      <AuthorizedRoute
        path={[ `${navigation.sendFunds}/:id`, navigation.sendFunds ]}
        permissions={{
          list: [
            permissionsMap.corporateWalletsManagementRead,
            permissionsMap.transactionsManagementCreateTransfer,
          ],
          atLeastOne: true,
        }}
        hideNavigation
        exact
      >
        <SendFunds />
      </AuthorizedRoute>

      <AuthorizedRoute
        path={[ `${navigation.bulkPayments}/:id`, navigation.bulkPayments ]}
        permissions={{
          list: [
            permissionsMap.corporateWalletsManagementRead,
            permissionsMap.transactionsManagementCreateTransfer,
          ],
          atLeastOne: true,
        }}
        hideNavigation
        exact
      >
        <BulkPayments />
      </AuthorizedRoute>

      <AuthorizedRoute
        path={navigation.inviteUser}
        permissions={{
          list: [ permissionsMap.personsManagementCreate, permissionsMap.personsRolesManagementRead ],
          atLeastOne: true,
        }}
        hideNavigation
        exact
      >
        <InviteUser />
      </AuthorizedRoute>

      <AuthorizedRoute
        path={`${navigation.inviteUser}/:id`}
        permissions={{
          list: [ permissionsMap.personsManagementCreate, permissionsMap.personsRolesManagementRead ],
          atLeastOne: true,
        }}
        hideNavigation
        exact
      >
        <InviteUser />
      </AuthorizedRoute>

      <AuthorizedRoute
        path={[ `${navigation.orderCards}/:id`, navigation.orderCards ]}
        permissions={{
          list: [ permissionsMap.individualCardsManagementCreate, permissionsMap.otherCardsManagementCreate ],
          atLeastOne: true,
        }}
        hideNavigation
        exact
      >
        <OrderCards />
      </AuthorizedRoute>

      <AuthorizedRoute
        path={navigation.addRole}
        permissions={{
          list: [permissionsMap.personsRolesManagementCreate],
          atLeastOne: true,
        }}
        hideNavigation
        exact
      >
        <AddRole />
      </AuthorizedRoute>

      <AuthorizedRoute exact to={navigation.notFound}>
        <NotFoundPage />
      </AuthorizedRoute>

      <Redirect to={navigation.notFound} />
    </Switch>
  );
}

export default Router;
