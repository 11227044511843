import styled from 'styled-components';
import { Button } from '@general/intergiro-ui-kit';

const DropdownWrapper = styled.div`
  background-color: ${props => props.theme.colors.white};
  border-radius: 0.375rem;
  box-shadow: ${props => props.theme.boxShadow2};
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 17.5rem;
  padding: 0.25rem 1.25rem 0.625rem 1.25rem;
`;

const DropdownClearFilters = styled.div`
  border-top: 0.0625rem solid ${props => props.theme.colors.greyBright};
  margin: 0 -1rem;
  padding: 1rem 1rem 0.5rem;
  display: flex;
  justify-content: space-between;
`;

const DropdownClearFilterButton = styled.button`
  background: none;
  border: none;
  color: ${props => props.theme.colors.blue};
  cursor: pointer;
  font-weight: 600;
  margin: 0;
  padding: 0;
`;

const DropdownApplyFilterButton = styled(Button)``;

export default { DropdownClearFilters, DropdownClearFilterButton, DropdownWrapper, DropdownApplyFilterButton };
