import { Pagination as IntergiroPagination } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';

const Pagination = styled(IntergiroPagination)`
  z-index: 1;
  padding: 1.0625rem 0.875rem 0.875rem 0.75rem;

  @media ${DEVICE.tablet} {
    padding-left: 0.125rem;
    padding-right: 0.25rem;
  }
`;

export default { Pagination };
