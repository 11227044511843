export const defaultDateFormat = 'D MMM YYYY';

export const defaultTimeFormat = 'HH:mm';

export const defaultFullTimeFormat = 'HH:mm:ss';

export const defaultDateTimeFormat = 'DD MMM YYYY, HH:mm';

export const defaultFullDateTimeFormat = 'DD MMM YYYY, HH:mm:ss';

export const defaultFullDateTimeLocalFormat = 'YYYY-MM-DD HH:mm:ss';

export const shortDateFormat = 'YYYY-MM-DD';

export const cardExpirationDateFormat = 'MM/YY';

export const cardExpirationFullDateFormat = 'MM/YYYY';

export const transactionGroupedByDateFormat = 'D MMM';

export const YEAR = 'year';
export const MONTH = 'month';
export const XXI_CENTURY = 2000;

export const dateRangeResetValues = { from: undefined, to: undefined };
