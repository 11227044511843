import { ErrorCodes } from '@general/error-codes';

export const DEBIT_FUNDING_TYPE = 'DEBIT';
export const PREPAID_FUNDING_TYPE = 'PREPAID';

export const PHYSICAL_CARD_TYPE = 'PHYSICAL';
export const VIRTUAL_CARD_TYPE = 'VIRTUAL';

export const labels = {
  [PHYSICAL_CARD_TYPE]: 'Physical',
  [VIRTUAL_CARD_TYPE]: 'Virtual',
  [DEBIT_FUNDING_TYPE]: 'Debit',
  [PREPAID_FUNDING_TYPE]: 'Prepaid',
};

export const cardListQuery = {
  orderby: 'createdAt',
  sortOrder: 'DESC',
};

export const MASTERCARD = 'MASTERCARD';
export const VISA = 'VISA';

export const cardStatusesMap = {
  active: 'ACTIVE',
  notActivated: 'NOT_ACTIVATED',
  frozen: 'FROZEN',
  cancelled: 'CANCELLED',
  lost: 'LOST',
  stolen: 'STOLEN',
  fraud: 'FRAUD',
  pending: 'PENDING',
  securityBlock: 'SECURITY_BLOCK',
  blocked: 'BLOCKED',
  expired: 'EXPIRED',
  closed: 'CLOSED',
};

export const cardStatusesLabels = {
  ACTIVE: 'Active',
  NOT_ACTIVATED: 'Not activated',
  FROZEN: 'Frozen',
  CANCELLED: 'Cancelled',
  LOST: 'Blocked',
  STOLEN: 'Blocked',
  FRAUD: 'Blocked',
  PENDING: 'Not activated',
  SECURITY_BLOCK: 'Security block',
  BLOCKED: 'Blocked',
  EXPIRED: 'Expired',
  CLOSED: 'Archived',
};

export const pinDigitNumber = 4;
export const cvc2DigitNumber = 3;

export const CARD_DETAILS = 'cardDetails';
export const CARD_LIMITS = 'cardLimits';

export const pinFieldName = 'pin';
export const confirmationPinFieldName = 'confirmationPin';
export const cvc2FieldName = 'cvc2';

export const changePinForm = 'changePin';
export const activateCardForm = 'activateCard';

export const BACK_PREVIEW_IN_S = 15;
export const BACK_PREVIEW_IN_MS = BACK_PREVIEW_IN_S * 1000;

export const defaultCardCreationData = {
  card: {
    brand: 'MASTERCARD',
    region: 'EUROPE',
    currency: 'EUR',
  },
  templateId: 'INTERGIRO_TEMPLATE',
};

export const cardOrderStatusMap = {
  pending: 'pending',
  processed: 'processed',
  readyForProcessing: 'ready_for_processing',
};

export const cardBlockingReasons = {
  fraud: 'FRAUD',
  lost: 'LOST',
  stolen: 'STOLEN',
  terminate: 'BLOCKED',
};

export const cardExpiredAndBlockedStatuses = [
  ...Object.values(cardBlockingReasons),
  cardStatusesMap.expired,
];

export const cardActivationCVC2FieldName = 'cvc_2';

export const cardStatusReasonMap = {
  limitPinAttemptsReached: 'LIMIT_PIN_ATTEMPTS_REACHED',
  limitActivationAttemptsReached: 'LIMIT_ACTIVATION_ATTEMPTS_REACHED',
};

export const cardStatusReasonDescriptionMap = {
  LIMIT_PIN_ATTEMPTS_REACHED:
  'You have reached the limit for incorrect PIN entries.',
  LIMIT_ACTIVATION_ATTEMPTS_REACHED: `
  You have reached the limit
  for activation attempts.
  Please contact Support to activate your card.
  `,
};

export const cardStatusReasonKeys = Object.keys(cardStatusReasonDescriptionMap);

export const cardActivationSuccessMessage = 'Your card is now active. Please keep your card and card details safe at all times. You may need to pay with PIN in store or at an ATM before making your first offline or contactless payment.';

export const CARD_EXPIRATION_IN_YEARS = 3;

export const pinValidationErrors = [ ErrorCodes.pinWeak, ErrorCodes.pinSameDigits, ErrorCodes.pinConsecutiveDigits ];

export const cardScaActions = {
  getCard: 'get-card',
  activateCard: 'activate-card',
  setCardPin: 'set-card-pin',
};

export const cardScaAuthenticationElements = {
  accessToken: 'access-token',
  smsOtp: 'sms-otp',
};
