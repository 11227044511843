export const formFields = {
  name: 'name',
  permissions: 'permissions',
};

export const defaultFormValues = {
  [formFields.name]: '',
  [formFields.permissions]: [],
};

export const keyPermissions = {
  createPayments: 'Create payments',
  getAllAccounts: 'Get all accounts',
  getAllTransactionDetails: 'Get all transaction details',
};

export const permissionsOptions = [
  {
    value: keyPermissions.getAllAccounts,
    label: keyPermissions.getAllAccounts,
    id: keyPermissions.getAllAccounts,
  },
  {
    value: keyPermissions.getAllTransactionDetails,
    label: keyPermissions.getAllTransactionDetails,
    id: keyPermissions.getAllTransactionDetails,
  },
  {
    value: keyPermissions.createPayments,
    label: keyPermissions.createPayments,
    id: keyPermissions.createPayments,
  },
];
