import { Avatar, DropdownBeneficiaryOption } from '@general/intergiro-ui-kit';
import capitalize from 'lodash/capitalize';
import PropTypes from 'prop-types';
import React, {
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { useBeneficiaries } from 'hooks';
import { showGeneralToastError } from 'utils/errors';
import { beneficiariesRequestOrderBy } from 'constants/beneficiaries';
import { getBeneficiaries } from 'api/beneficiary';
import { DEBOUNCE_TIMEOUT } from 'constants/common';
import config from 'config';

import { INTERNATIONAL_RAILS, RAILS_MAP, LOCAL_RAILS, SEND_FUNDS_BENEFICIARY_FORM } from '../../constants';
import { addNewBeneficiaryField, beneficiaryDropdownField, dataTestIds } from './constants';
import S from './styles';

function RecentBeneficiaries({ setCurrentView, onBeneficiarySelect }) {
  const {
    beneficiaries: recentBeneficiaries,
    isInitiallyFetched: isBeneficiariesFetched,
  } = useBeneficiaries({ orderBy: beneficiariesRequestOrderBy.lastUsedAt, limit: 10 });
  const [ beneficiariesQuery, setBeneficiariesQuery ] = useState('');
  const [ isSearchingByName, setIsSearchingByName ] = useState(false);
  const [ dropdownBeneficiariesOptions, setDropdownBeneficiariesOptions ] = useState([]);
  const [ recentBeneficiariesOptions, setRecentBeneficiariesOptions ] = useState([]);
  const { t } = useTranslation();
  const debounceRef = useRef();

  useEffect(() => {
    if (!recentBeneficiariesOptions.length && isBeneficiariesFetched) setCurrentView(SEND_FUNDS_BENEFICIARY_FORM);
  }, [ recentBeneficiariesOptions, isBeneficiariesFetched ]);

  useEffect(() => {
    recentBeneficiaries.length &&
      setRecentBeneficiariesOptions(getBeneficiaryOptions(recentBeneficiaries.filter(filterBeneficiaries)));
  }, [recentBeneficiaries]);

  useEffect(() => {
    clearTimeout(debounceRef.current);

    debounceRef.current = setTimeout(() => {
      fetchAndSetDropdownBeneficiariesOptions();
    }, DEBOUNCE_TIMEOUT);
  }, [beneficiariesQuery]);

  const fetchAndSetDropdownBeneficiariesOptions = useCallback(async () => {
    setIsSearchingByName(true);
    const beneficiaries = await fetchBeneficiariesByName(beneficiariesQuery);

    await setDropdownBeneficiariesOptions(beneficiaries?.length ?
      getBeneficiaryOptions(beneficiaries.filter(filterBeneficiaries)) : [renderNewBeneficiaryOption()]);
    setIsSearchingByName(false);
  }, [beneficiariesQuery]);

  const fetchBeneficiariesByName = async (nameQuery, limit = 10) => {
    try {
      const { data } = await getBeneficiaries({ ...(nameQuery && { name: nameQuery } ), limit });

      return data;
    } catch (error) {
      return showGeneralToastError(error);
    }
  };

  const getBeneficiaryOptions = (beneficiariesList, markAllTouched = false) => {
    const formattedBeneficiaries = beneficiariesList?.map(beneficiary => ({
      touched: markAllTouched,
      value: beneficiary,
      label: renderBeneficiary(beneficiary),
    }));

    formattedBeneficiaries.unshift(renderNewBeneficiaryOption());

    return formattedBeneficiaries;
  };

  // https://ftcs-tech.atlassian.net/browse/IDE-18490
  const filterBeneficiaries = beneficiary => {
    const disabledLocalCurrencies = config.featureFlags.disabledLocalCurrencies.split(',');

    const beneficiaryCurrency = beneficiary?.account?.currency;
    const beneficiaryPaymentRail = beneficiary?.account?.paymentRail;

    return !(disabledLocalCurrencies.includes(beneficiaryCurrency)
      && RAILS_MAP[beneficiaryPaymentRail].binaryRail === LOCAL_RAILS);
  };

  const renderNewBeneficiaryOption = () => ({
    value: {
      name: addNewBeneficiaryField,
    },
    label: (
      <S.AddBeneficiaryOption>
        <S.PlusIconWrapper>
          <S.BeneficiaryPlusIcon />
        </S.PlusIconWrapper>
        <S.AddBeneficiaryOptionLabel>
          {t('sendFunds.recentBeneficiaries.newBeneficiary')}
        </S.AddBeneficiaryOptionLabel>
      </S.AddBeneficiaryOption>
    ),
  }
  );

  const handleBeneficiarySelect = option => {
    const optionName = option?.value?.name;

    if (!optionName) return;

    if (optionName === addNewBeneficiaryField) {
      onBeneficiarySelect({});

      return;
    }

    onBeneficiarySelect(option.value);
  };

  const handleDropdownInput = value => {
    setBeneficiariesQuery(value);
  };

  const renderRecentBeneficiaries = () =>
    recentBeneficiaries.filter(filterBeneficiaries).map(beneficiary => (
      <S.BeneficiaryWrapper key={beneficiary.id} onClick={() => onBeneficiarySelect(beneficiary)}>
        {renderBeneficiary(beneficiary)}
      </S.BeneficiaryWrapper>
    ));

  const renderBeneficiary = beneficiary => {
    const {
      name,
      account: {
        currency,
        number: { value: accountNumber },
        bankCode: { value: bankCode },
        paymentRail,
      },
      isTrusted,
    } = beneficiary;

    return (
      <DropdownBeneficiaryOption
        name={(
          <S.BeneficiaryName>
            {name}
          </S.BeneficiaryName>
        )}
        avatar={memo(() => <Avatar small name={name} param1={accountNumber} param2={bankCode} />)}
        accountNumber={getPaymentLocationText(RAILS_MAP[paymentRail].binaryRail)}
        currencyShort={currency}
        sortCode={` ${accountNumber}`}
        isTrusted={isTrusted}
      />
    );
  };

  const getPaymentLocationText = binaryRail =>
    capitalize(binaryRail === INTERNATIONAL_RAILS ? t('sendFunds.recentBeneficiaries.international') : binaryRail);

  if (!isBeneficiariesFetched) return null;

  return (
    <S.Wrapper data-testid={dataTestIds.wrapper}>
      <S.Row>
        <S.Title>
          {t('sendFunds.recentBeneficiaries.title')}
        </S.Title>
        <S.ActionButton
          onClick={() => onBeneficiarySelect({})}
          data-testid={dataTestIds.addButton}
        >
          <S.PlusIcon />
          {' '}
          {t('sendFunds.recentBeneficiaries.button')}
        </S.ActionButton>
      </S.Row>
      <S.BeneficiaryDropdown
        label={t('sendFunds.recentBeneficiaries.findBeneficiary')}
        options={dropdownBeneficiariesOptions}
        isBeneficiaryOption
        id={beneficiaryDropdownField}
        name={beneficiaryDropdownField}
        onChange={handleBeneficiarySelect}
        onInputChange={handleDropdownInput}
        filterOption={() => true}
        isValidNewOption={() => false}
        isLoading={isSearchingByName}
      />
      <S.Subtitle>
        {t('sendFunds.recentBeneficiaries.subtitle')}
      </S.Subtitle>
      {renderRecentBeneficiaries()}
    </S.Wrapper>
  );
}

RecentBeneficiaries.propTypes = {
  setCurrentView: PropTypes.func.isRequired,
  onBeneficiarySelect: PropTypes.func.isRequired,
};

export default RecentBeneficiaries;
