import { Checkbox } from '@general/intergiro-ui-kit';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { pollCardOrderState, updatePendingCardOrder } from 'api/cards';
import { showGeneralToastError } from 'utils/errors';
import { navigation } from 'constants/navigation';
import { AccountsContext } from 'contexts/accounts';
import { GlobalLoaderContext } from 'contexts/global-loader';
import { UserContext } from 'contexts/user';
import { usePersons } from 'hooks';
import { useGetCardOrder } from 'hooks/use-cards';
import {
  ACCOUNT_SELECTION,
  ACCOUNT_TYPE,
  ADDRESS_FIELD,
  CARD_HOLDER_FIELD,
  CARD_TYPE_SWITCH,
  cardCreationStatusMap,
  DISPLAY_NAME_FIELD,
  EXISTING_ACCOUNT,
  EXPIRY,
  NEW_ACCOUNT,
  newAccount,
} from 'pages/order-cards/constants';
import { createCardsFromOrder, getAccountById, getExistingAccountId } from 'pages/order-cards/utils';
import CardAccountDetails from 'pages/order-cards/components/card-account-details';
import CardOrderTable from 'pages/order-cards/components/card-order-table';
import { MobileAppDownloadModal } from 'components';
import { VIRTUAL_CARD_TYPE } from 'constants/card';

import CardSummarySidebar from '../card-summary-sidebar';
import S from './styles';

function Summary({
  orderId,
  submitted,
  setSubmitted,
  cards,
  setCards,
  orderDetailsData,
  setIsCancelPopupShown,
  setOrderDetailsData,
}) {
  const scrollRef = useRef(null);
  const history = useHistory();
  const { t } = useTranslation();
  const { startLoading, endLoading } = useContext(GlobalLoaderContext);
  const { accounts } = useContext(AccountsContext);
  const { user } = useContext(UserContext);
  const { cardOrder } = useGetCardOrder(orderId);
  const { persons } = usePersons();
  const [ checked, setChecked ] = useState(false);
  const [ order, setOrder ] = useState({});
  const [ successfulIssues, setSuccessfulIssues ] = useState(5);
  const [ failedIssues, setFailedIssues ] = useState(1);
  const [ selectedCard, setSelectedCard ] = useState({});
  const [ isSidebarOpen, setIsSidebarOpen ] = useState(false);
  const [ isOrderSummaryShown, setIsOrderSummaryShown ] = useState(false);
  const [ isMobileModalOpen, setIsMobileModalOpen ] = useState(false);
  const [ orderWithStatuses, setOrderWithStatuses ] = useState([]);
  const [ failedIssueToastShown, setFailedIssueToastShown ] = useState(false);

  useEffect(() => {
    setOrder(cardOrder);
  }, [cardOrder]);

  const updateOrderDetailsData = useCallback(() => {
    if (orderDetailsData && !accounts.length) return;

    const existingAccountId = getExistingAccountId(order);

    setOrderDetailsData({
      [CARD_TYPE_SWITCH]: order.cardType.toUpperCase(),
      [ACCOUNT_TYPE]: existingAccountId ? EXISTING_ACCOUNT : NEW_ACCOUNT,
      [ACCOUNT_SELECTION]: getAccountById(existingAccountId, accounts),
    });
  }, [ order, accounts ]);

  const updateCards = useCallback(() => {
    const personsExists = persons && Object.keys(persons).length;
    const cardsExists = cards?.length;
    const shouldUpdateCards = personsExists && !cardsExists;

    if (shouldUpdateCards) {
      setCards(createCardsFromOrder(order, persons));
    }
  }, [ persons, order ]);

  const checkIfOrderSubmitted = useCallback(() => {
    if (order.lineItems.every(({ status }) => [ cardCreationStatusMap.success, cardCreationStatusMap.fail ]
      .includes(status))) {
      setSubmitted(true);
      updateIssues(order);
    }
  }, [order]);

  useEffect(() => {
    const orderExists = Object.keys(order).length;

    if (orderExists) {
      updateOrderDetailsData();
      updateCards();
      checkIfOrderSubmitted();
      scrollIntoView();
      setOrderWithStatuses(formatOrderWithStatuses);
    }
  }, [ order, accounts, updateCards, updateOrderDetailsData, checkIfOrderSubmitted ]);

  const goToCards = () => {
    history.push(navigation.cards);
  };

  const formatOrderWithStatuses = () => order?.lineItems?.map(card => ({
    status: card.status,
    id: card.id,
    cardHolder: getCardHolder(card),
    address: card.issueRequest.card[ADDRESS_FIELD],
    displayName: card.issueRequest.card[DISPLAY_NAME_FIELD],
    expiry: card.issueRequest.card[EXPIRY],
  }));

  const handleSubmit = async () => {
    if (!checked) return;

    startLoading();
    const { data, error } = await updatePendingCardOrder(cardOrder);

    if (error) {
      showGeneralToastError(error);
      endLoading();

      return;
    }

    const submittedOrder = await pollCardOrderState(data);

    updateIssues(submittedOrder);
    setOrder(submittedOrder);
    setSubmitted(true);
    endLoading();
  };

  const updateIssues = submittedOrder => {
    const { success, failed } = submittedOrder.lineItems.reduce((acc, item) => {
      if (item.status === cardCreationStatusMap.success) {
        acc.success += 1;
      } else {
        acc.failed += 1;
      }

      return acc;
    },
    {
      success: 0,
      failed: 0,
    });

    if (!failedIssueToastShown) {
      setFailedIssueToastShown(prevValue => {
        if (failed > 0 && !prevValue) toast.error(t('orderCards.summary.submitted.issueToast', { count: failed }));

        return true;
      });
    }

    setSuccessfulIssues(success);
    setFailedIssues(failed);
  };

  const getCardHolder = useCallback(item => {
    if (!persons || isEmpty(persons)) {
      return user;
    }

    return persons.find(p =>
      p.id === (item.issueRequest.card[CARD_HOLDER_FIELD] || item.issueRequest.cardholder.referenceId));
  }, [persons]);

  const scrollIntoView = () => {
    scrollRef?.current?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  const handleViewCard = card => {
    setSelectedCard(card);
    setIsSidebarOpen(true);
  };

  const handleSidebarClose = () => {
    setSelectedCard({});
    setIsSidebarOpen(false);
  };

  return (
    <S.Container>
      {submitted ? (
        <S.SubmittedContainer>
          <S.Title>
            {t('orderCards.summary.submitted.title')}
          </S.Title>
          <S.DetailsContainer isActive={isOrderSummaryShown}
            onClick={() => setIsOrderSummaryShown(!isOrderSummaryShown)}
          >
            <S.DetailsLabel>
              {isOrderSummaryShown ? t('orderCards.summary.submitted.detailsDropdownHide') : t('orderCards.summary.submitted.detailsDropdownShow')}
            </S.DetailsLabel>
            <S.ChevronIcon />
          </S.DetailsContainer>
          {!isEmpty(order) && isOrderSummaryShown && (
            <S.Table>
              <CardOrderTable
                orderCardData={orderWithStatuses}
                selectedCard={selectedCard}
                account={orderDetailsData?.accountSelection || newAccount}
                isVirtual={orderDetailsData.cardTypeSwitch === VIRTUAL_CARD_TYPE}
              />
            </S.Table>
          )}
          <S.TotalBlock>
            <S.TotalLabel>
              {t('orderCards.summary.total')}
            </S.TotalLabel>
            <S.TotalContent>
              {failedIssues ? (
                <S.Issue>
                  <S.Badge>
                    {t('orderCards.summary.submitted.notIssued')}
                  </S.Badge>
                  <S.IssueText>
                    {t('orderCards.summary.submitted.issuedAmount', { count: failedIssues })}
                  </S.IssueText>
                </S.Issue>
              ) : null}
              {successfulIssues ? (
                <S.Issue>
                  <S.Badge isSuccess>
                    {t('orderCards.summary.submitted.issued')}
                  </S.Badge>
                  <S.IssueText>
                    {t('orderCards.summary.submitted.issuedAmount', { count: successfulIssues })}
                  </S.IssueText>
                </S.Issue>
              ) : null}
            </S.TotalContent>
          </S.TotalBlock>
          <S.Alert icon={S.InfoIcon}
            text={(
              <S.InfoContent>
                <S.InfoLink onClick={() => setIsMobileModalOpen(true)}>
                  {t('orderCards.summary.submitted.infoLink')}
                </S.InfoLink>
                <S.InfoText>
                  {t('orderCards.summary.submitted.infoText')}
                </S.InfoText>
              </S.InfoContent>
          )}
          />
          <S.Button label={t('orderCards.summary.submitted.button')} category="secondary" extraMargin onClick={goToCards} />
        </S.SubmittedContainer>
      ) : (
        <>
          <CardAccountDetails
            companyName={user?.corporationName}
            account={orderDetailsData?.accountSelection || newAccount}
            isVirtual={orderDetailsData?.cardTypeSwitch === VIRTUAL_CARD_TYPE}
          />
          {!isEmpty(order) && (
            <S.Table>
              <CardOrderTable
                handleRowClick={handleViewCard}
                orderCardData={cards}
                selectedCard={selectedCard}
                account={orderDetailsData?.accountSelection || newAccount}
                isVirtual={orderDetailsData?.cardTypeSwitch === VIRTUAL_CARD_TYPE}
              />
            </S.Table>
          )}
          <CardSummarySidebar
            address={selectedCard[ADDRESS_FIELD]}
            cardHolder={selectedCard.cardHolder}
            companyName={get(user, 'corporationName')}
            displayName={selectedCard[DISPLAY_NAME_FIELD]}
            expiration={selectedCard[EXPIRY]}
            id={selectedCard.id}
            status={selectedCard.status}
            type={cardOrder.cardType?.toUpperCase()}
            account={orderDetailsData?.accountSelection || newAccount}
            cards={cards}
            isSidebarOpen={isSidebarOpen}
            setSelectedCard={setSelectedCard}
            handleSidebarClose={handleSidebarClose}
          />
          <S.Total>
            {t('orderCards.summary.totalOrder', { count: cardOrder?.lineItems?.length })}
          </S.Total>
          <S.ConfirmationBlock ref={scrollRef}>
            <Checkbox onChange={() => setChecked(!checked)} checked={checked} isInverse>
              {t('orderCards.summary.confirmation')}
            </Checkbox>
            <S.Button disabled={!checked} label={t('orderCards.summary.submitOrder')} category="primary" onClick={handleSubmit} />
            <S.CancelButton handleClick={() => setIsCancelPopupShown(true)} />
          </S.ConfirmationBlock>
        </>
      )}
      <MobileAppDownloadModal isOpen={isMobileModalOpen} setIsOpen={setIsMobileModalOpen} />
    </S.Container>
  );
}

Summary.propTypes = {
  orderId: PropTypes.string.isRequired,
  submitted: PropTypes.bool.isRequired,
  setSubmitted: PropTypes.func.isRequired,
  cards: PropTypes.instanceOf(Array),
  setCards: PropTypes.func.isRequired,
  orderDetailsData: PropTypes.instanceOf(Object),
  setOrderDetailsData: PropTypes.func.isRequired,
  setIsCancelPopupShown: PropTypes.func.isRequired,
};

export default Summary;
