import PropTypes from 'prop-types';
import React, { useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import { useFilterType } from 'hooks';
import DropdownFilterCheckbox from 'pages/activity/components/dropdown-filter-checkbox';
import DropdownFilterControl from 'pages/activity/components/dropdown-filter-control';
import { filterNames } from 'pages/activity/constants';
import { getInvertedCheckboxList, setFiltersToUnchecked } from 'utils/helpers';

import updateURLToMatchParameters from './helpers';
import FilterDropdown from './dropdown';
import { handleSelectAll } from '../utils';

function TypeFilter({ userPermissions }) {
  const { t } = useTranslation();
  const history = useHistory();
  const ref = useRef(null);
  const { selectedCount, type, setType } = useFilterType(userPermissions);
  const [ availableTypes, setAvailableTypes ] = useState(type);

  const onChange = useCallback(event => {
    const selectedId = event.target.name;
    const updatedList = getInvertedCheckboxList(selectedId, availableTypes);
    setAvailableTypes(updatedList);
  }, [availableTypes]);

  const onClear = useCallback(() => {
    const updatedFilter = setFiltersToUnchecked(type);
    setType(updatedFilter);
    setAvailableTypes(updatedFilter);
    updateURLToMatchParameters(updatedFilter, filterNames.type, history);
    ref.current.closeDropdown();
  }, [ type, setType, history ]);

  const onApply = useCallback(() => {
    setType(availableTypes);
    updateURLToMatchParameters(availableTypes, filterNames.type, history);

    ref.current.closeDropdown();
  }, [ type, availableTypes ]);

  const onSelectAll = useCallback(() => {
    const allSelectedTypes = handleSelectAll(availableTypes);
    setAvailableTypes(allSelectedTypes);
  }, [availableTypes]);

  return (
    <DropdownFilterControl
      label={(
        <FilterDropdown
          count={selectedCount}
          defaultLabel={t('components.filterBar.type.dropdown.defaultLabel')}
          name="type"
          selectedLabel={t(selectedCount > 1 ? 'components.filterBar.type.dropdown.selectedPluralLabel' : 'components.filterBar.type.dropdown.selectedLabel')}
        />
      )}
      ref={ref}
    >
      <DropdownFilterCheckbox
        name={filterNames.type}
        onChange={onChange}
        list={availableTypes}
        onClear={onClear}
        onApply={onApply}
        onSelectAll={onSelectAll}
      />
    </DropdownFilterControl>
  );
}

TypeFilter.propTypes = {
  userPermissions: PropTypes.instanceOf(Object),
};

export default TypeFilter;
