import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { navigation } from 'constants/navigation';
import { transactionStatusCodes } from 'constants/transactions';
import { useView } from 'hooks';
import { getBeneficiaryName } from 'pages/send-funds/utils';
import { getAmount, toMainUnit } from 'utils/amount';

import S from './styles';

function SendFundsFinal({
  createdTransaction,
  beneficiaryFormValues,
  setLayoutProps,
}) {
  const history = useHistory();
  const { isMobile } = useView();

  const { name: beneficiaryName } = getBeneficiaryName(beneficiaryFormValues);

  const isPendingApprovalTransaction =
    createdTransaction?.status === transactionStatusCodes.groupedCode.pendingApproval;

  const successTitle = isPendingApprovalTransaction ? 'Payment created' : 'Payment sent';
  const failureTitle = 'Payment unsuccessful';

  useEffect(() => {
    setLayoutProps({
      title: createdTransaction ? successTitle : failureTitle,
      displayCancelButton: false,
      hideBackButton: isMobile,
    });
  }, [createdTransaction]);

  const successMessage = () => {
    const amount = getAmount(toMainUnit(createdTransaction.targetAmount));

    return isPendingApprovalTransaction
      ? `
        We’ll process your payment just as soon
        as your approver authorises it.
      ` :
      `
        You sent ${amount}
        ${createdTransaction.targetCurrency} to
        ${beneficiaryName}.
      `;
  };

  const failureMessage = `
    Something went wrong - your money was not sent. Please try again.
    If this keeps failing, please contact Support.
  `;

  const onViewDetails = () => {
    history.push(navigation.activity);
  };

  const backToAccounts = () => {
    history.push(navigation.accounts);
  };

  return (
    <div>
      <S.IconWrapper>
        {createdTransaction ? <S.SuccessIcon /> : <S.ErrorIcon />}
      </S.IconWrapper>
      <S.Text>
        {createdTransaction ? successMessage() : failureMessage}
      </S.Text>
      <S.Button back onClick={backToAccounts} label="Back to accounts" category="secondary" />
      {
        createdTransaction &&
          <S.Button onClick={onViewDetails} label="View payment details" category="primary" />
      }
    </div>
  );
}

SendFundsFinal.propTypes = {
  createdTransaction: PropTypes.instanceOf(Object),
  beneficiaryFormValues: PropTypes.instanceOf(Object).isRequired,
  setLayoutProps: PropTypes.func.isRequired,
};

export default SendFundsFinal;
