import React from 'react';

import EditButton from './edit-button';

export const userDetailsFields = [
  { label: 'First name', field: 'firstName' },
  { label: 'Last name', field: 'lastName' },
  { label: 'Role', field: 'roles' },
  { label: 'Position', field: 'position' },
  { label: 'Ownership', field: 'ownership' },
  { label: 'Company', field: 'corporationName' },
  { label: 'Gender', field: 'gender' },
  { label: 'Email', field: 'email' },
  { label: 'Nationality', field: 'nationalityName' },
  { label: 'Other phone', field: 'otherPhone', formatValue: (value, { handleEdit }) => <EditButton value={value} onEdit={handleEdit} /> },
  { label: 'Country of residence', field: 'address.countryName' },
  { label: 'Social security number', field: 'socialSecurityNumber' },
  { label: 'Region', field: 'address.region' },
  { label: 'Date of birth', field: 'dateOfBirth' },
  { label: 'City', field: 'address.city' },
  { label: 'Postal code', field: 'address.postalCode' },
  { label: 'Street', field: 'address.street' },
];
