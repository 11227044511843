import copy from 'clipboard-copy';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import S from './styles';

const oneSecond = 1000;

function CopyButton({ label = '', text }) {
  const [ isCopied, setIsCopied ] = useState(false);

  const showLable = Boolean(label);

  const handleCopy = () => {
    copy(text);

    setIsCopied(true);
    setTimeout(() => setIsCopied(false), oneSecond);
  };

  return (
    <S.Button
      category="link"
      icon={isCopied ? <S.SuccessIcon /> : <S.CopyIcon />}
      label={isCopied && !showLable ? 'Copied!' : label}
      showLable={showLable}
      onClick={handleCopy}
    />
  );
}

CopyButton.propTypes = {
  label: PropTypes.string,
  text: PropTypes.string.isRequired,
};

export default CopyButton;
