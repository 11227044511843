import PropTypes from 'prop-types';
import React from 'react';

import { userStatusesLabels } from 'constants/user';

import S from './styles';

function UserStatus({ status }) {
  return (
    <S.Status status={status}>
      {userStatusesLabels[status]}
    </S.Status>
  );
}

UserStatus.propTypes = {
  status: PropTypes.string,
};

export default UserStatus;
