import PropTypes from 'prop-types';
import React, { memo, useContext, useEffect, useState } from 'react';

import { Dot } from 'components';
import { transactionStatusLabels, transactionStatusMap } from 'constants/transactions';
import { TransactionContext } from 'contexts/transaction';
import { useView } from 'hooks';

import S from './styles';
import { mapTransactionStatusHint } from './utils';

function TransactionStatus({ status, type, statusReasons, isInline, displayStatus }) {
  const { isPendingApproval } = useContext(TransactionContext);
  const [ label, setLabel ] = useState(null);
  const [ subLabel, setSubLabel ] = useState(null);
  const { isMobile } = useView();

  useEffect(() => {
    const statusLabel = transactionStatusLabels[status];
    const showReason = statusLabel === transactionStatusMap.failed && statusReasons?.length;

    setLabel(statusLabel);
    setSubLabel(showReason ? statusReasons.join(', ') : null);
  }, [ status, statusReasons ]);

  if (!displayStatus && isMobile && isPendingApproval) {
    return null;
  }

  return (
    <S.TransactionStatus>
      <S.LabelWrapper status={status} isInline={isInline}>
        {label}
        {mapTransactionStatusHint(status, type)}
      </S.LabelWrapper>
      <S.SubLabelWrapper isInline={isInline} isHidden={!subLabel}>
        <Dot isHidden={!isInline} />
        <S.TransactionSubStatus >
          {subLabel}
        </S.TransactionSubStatus>
      </S.SubLabelWrapper>
    </S.TransactionStatus>
  );
}

TransactionStatus.propTypes = {
  status: PropTypes.string.isRequired,
  type: PropTypes.string,
  statusReasons: PropTypes.arrayOf(PropTypes.string),
  isInline: PropTypes.bool,
  displayStatus: PropTypes.bool,
};

export default memo(TransactionStatus);
