import styled from 'styled-components';
import { CircleFlag } from 'react-circle-flags';
import { Button as IntergiroButton } from '@general/intergiro-ui-kit';

import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { ReactComponent as DefaultTrustedIcon } from 'assets/icons/trusted.svg';
import { DEVICE } from 'constants/breakpoints';

const Wrapper = styled.div`
  margin: 0.5rem 1.25rem 0;
  max-width: calc(100vw - 2.5rem);

  @media ${DEVICE.laptop} {
    margin: 2.5rem 0 0;
  }
`;

const DetailsContainer = styled.div`
  margin-bottom: 1.5rem;

  @media ${DEVICE.laptop} {
    margin-bottom: 0;
  }
`;

const FlagWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Flag = styled(CircleFlag)`
  height: 1.25rem;
  margin-right: .5rem;
`;

const ActionRow = styled.div`
  margin-top: 1.875rem;
  margin-bottom: 1.5rem;
`;

const Button = styled(IntergiroButton)`
  width: 100%;

  @media ${DEVICE.tablet} {
    width: 8.5rem;
  }
`;

const DeleteIcon = styled(TrashIcon)`
  path {
    fill: ${props => props.theme.colors.white};
  }
`;

const TrustedIcon = styled(DefaultTrustedIcon)`
  width: 0.875rem;
  height: 0.875rem;
  margin-right: 0.375rem;

  path {
    fill: ${props => props.theme.colors.greyMedium};
    fill-rule: evenodd;
  }
`;

export default {
  Wrapper,
  DetailsContainer,
  FlagWrapper,
  ActionRow,
  Button,
  DeleteIcon,
  Flag,
  TrustedIcon,
};
