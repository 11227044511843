export const statementFormatsMap = {
  pdf: 'pdf',
  csv: 'csv',
};

export const statementFormats = Object.values(statementFormatsMap);

export const statementFormatsOptions = statementFormats.map(format => ({
  label: format.toUpperCase(),
  value: format,
}));
