import { isValidNumber } from 'libphonenumber-js';
import { object, ref, string } from 'yup';

import { formFields } from './constants';

export const PhoneSchema = object().shape({
  [formFields.phone.name]: string()
    .required('security.validation.phone.required')
    .test('phone_validation', 'security.validation.phone.invalid', value => {
      const number = value?.charAt(0) === '+' ? value : `+${value}`;

      return isValidNumber(number);
    })
    .required('security.validation.phone.required'),
  [formFields.newPassword.name]: string()
    .required('security.validation.password.required')
    .min(6, 'security.validation.password.min')
    .max(25, 'security.validation.password.max'),
  [formFields.confirmNewPassword.name]: string()
    .oneOf([ ref(formFields.newPassword.name), null ], 'security.validation.password.mustMatch')
    .required('security.validation.password.required'),
  [formFields.currentPassword.name]: string()
    .required('security.validation.password.required')
    .min(6, 'security.validation.password.min')
    .max(25, 'security.validation.password.max'),
});
