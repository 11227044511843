import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, {
  Fragment,
  memo,
  useContext,
  useEffect,
  useState,
} from 'react';

import { Dot, ShowOnMobile, TransactionStatus } from 'components';
import { defaultDateFormat, defaultTimeFormat } from 'constants/date-time';
import { TransactionContext } from 'contexts/transaction';
import { useView } from 'hooks';

import S from './styles';
import { mapTransactionStatusHint } from '../transaction-status/utils';

function TransactionTo({
  targetName,
  status,
  type,
  createdAt,
  createdBy,
  showDetailsAlways,
  showDetailsOnMobile,
}) {
  const [ readyToBeRendered, setReadyToBeRendered ] = useState(false);
  const [ date, setDate ] = useState();
  const [ time, setTime ] = useState();

  useEffect(() => {
    setDate(dayjs(createdAt).format(defaultDateFormat));
    setTime(dayjs(createdAt).format(defaultTimeFormat));

    setReadyToBeRendered(true);
  }, [createdAt]);

  function renderDetails() {
    if (!readyToBeRendered) return null;

    if (showDetailsOnMobile) {
      return (
        <ShowOnMobile isInline>
          <Details date={date} time={time} createdBy={createdBy} />
        </ShowOnMobile>
      );
    }

    if (showDetailsAlways) {
      return (
        <Details date={date} time={time} createdBy={createdBy} status={status} />
      );
    }

    return null;
  }

  return (
    <>
      <S.TransactionToLabel>
        To:
        {' '}
        <S.TransactionHighlighted data-testid="transaction-recipient">
          {targetName}
        </S.TransactionHighlighted>
      </S.TransactionToLabel>
      <S.TransactionDetailsWrapper>
        <S.TransactionStatusWrapper>
          <TransactionStatus status={status} isInline />
          {mapTransactionStatusHint(status, type)}
        </S.TransactionStatusWrapper>

        <S.TransactionSubHeading>
          {renderDetails()}
        </S.TransactionSubHeading>
      </S.TransactionDetailsWrapper>
    </>
  );
}

TransactionTo.propTypes = {
  targetName: PropTypes.string,
  status: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  createdBy: PropTypes.string.isRequired,
  showDetailsAlways: PropTypes.bool,
  showDetailsOnMobile: PropTypes.bool,
};

export default memo(TransactionTo);

// Helper functions

function Details({ date, time, createdBy }) {
  const { isPendingApproval } = useContext(TransactionContext);
  const { isMobile } = useView();

  const details = {
    date: isPendingApproval ? date : undefined,
    time,
    createdBy,
  };

  if (isMobile && isPendingApproval) {
    delete details.createdBy;
  }

  const detailsArr = Object.values(details);
  const numberOfDetails = detailsArr.length - 1;

  const renderedDetails = detailsArr.map((detail, index) => {
    if (!detail) return null;
    const showDot = index !== numberOfDetails;

    return (
      <Fragment key={detail}>
        <S.TransactionSubHeading>
          {detail}
        </S.TransactionSubHeading>
        {showDot && <Dot />}
      </Fragment>
    );
  });

  return (
    <>
      <S.DetailsWrapper>
        {!isMobile && <Dot />}
        {renderedDetails}
      </S.DetailsWrapper>
      {(isPendingApproval && isMobile) && (
        <S.DetailsWrapper nextLine>
          <S.TransactionSubHeading>
            {createdBy}
          </S.TransactionSubHeading>
        </S.DetailsWrapper>
      )}
    </>
  );
}

Details.propTypes = {
  createdBy: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
};
