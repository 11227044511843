import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ModalContent } from 'components';
import { millisecondsToSeconds, minutesToMilliseconds } from 'utils/time';

import S from './styles';

function MerchantTimeoutModal({ isOpen, setIsOpen, sessionTimeout }) {
  const [ timeLeft, setTimeLeft ] = useState(minutesToMilliseconds(sessionTimeout));
  const { t } = useTranslation();

  useEffect(() => {
    if (timeLeft > 0) {
      setTimeout(() => setTimeLeft(timeLeft - 1000), 1000);

      return;
    }

    handleReload();
  }, [timeLeft]);

  const handleReload = () => {
    setIsOpen(false);
    window.location.reload(false);
  };

  const CTAButtons = (
    <S.Button
      label={t('merchants.timeoutModal.buttons.reload')}
      category="secondary"
      type="submit"
      onClick={handleReload}
    />
  );

  return (
    <ModalContent
      title={t('timeoutModal.title')}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      CTAButtons={CTAButtons}
      hasCloseIcon={false}
      shouldCloseOnOverlayClick={false}
    >
      <S.Text>
        {t('merchants.timeoutModal.text')}
      </S.Text>
      <S.TimeLeftText>
        <Trans i18nKey="merchants.timeoutModal.timeLeftText">
          Automatic reload in
          {' '}
          <span>
            {{ secondsLeft: millisecondsToSeconds(timeLeft) }}
          </span>
          {' '}
          seconds.
        </Trans>
      </S.TimeLeftText>
    </ModalContent>
  );
}

MerchantTimeoutModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  sessionTimeout: PropTypes.number.isRequired,
};

export default MerchantTimeoutModal;
