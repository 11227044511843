import { useContext, useEffect, useState } from 'react';

import { showGeneralToastError } from 'utils/errors';
import { getAccountBankDetails } from 'api/accounts';
import { GlobalLoaderContext } from 'contexts/global-loader';

const useAccountBankDetails = id => {
  const { startLoading, endLoading } = useContext(GlobalLoaderContext);
  const [ account, setAccount ] = useState({});

  useEffect(() => {
    if (!id) return;

    const fetchAccountBankDetails = async () => {
      startLoading();

      try {
        const bankDetails = await getAccountBankDetails(id);

        setAccount(bankDetails);
      } catch (error) {
        showGeneralToastError(error);
      } finally {
        endLoading();
      }
    };

    fetchAccountBankDetails();
  }, [id]);

  return { account };
};

export default useAccountBankDetails;
