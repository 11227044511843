import { Dropdown } from '@general/intergiro-ui-kit';
import PropTypes from 'prop-types';
import React from 'react';

import { checkOptionIncludesValue } from 'pages/move-funds/services/utils';

function MoveFundsSelect({
  placeholder,
  label,
  isSubmitting,
  handleSelect,
  accountOptions,
  error,
  value,
  defaultValue,
  name,
}) {

  return (
    <Dropdown
      placeholder={placeholder}
      label={label}
      options={accountOptions}
      id={name}
      name={name}
      onChange={handleSelect}
      filterOption={checkOptionIncludesValue}
      disabled={isSubmitting}
      error={error}
      value={value}
      defaultValue={defaultValue}
      isCurrencyOption
      isClearable
    />
  );
}

MoveFundsSelect.propTypes = {
  placeholder: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool,
  accountOptions: PropTypes.instanceOf(Array).isRequired,
  handleSelect: PropTypes.func.isRequired,
  error: PropTypes.bool,
  value: PropTypes.instanceOf(Object),
  defaultValue: PropTypes.instanceOf(Object),
};

export default MoveFundsSelect;
