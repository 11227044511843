import { toast } from 'react-toastify';
import { ErrorCodes } from '@general/error-codes';
import has from 'lodash/has';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import mapValues from 'lodash/mapValues';
import findKey from 'lodash/findKey';

import { navigation } from 'constants/navigation';
import { SYSTEM_TO_UI_ERROR_MESSAGES, ERRORS } from 'constants/errors';

/**
 * @param {array|object} errors
 * @param errorCodes
 * @return {boolean|*}
 */
export function errorsConsistOneOfErrorCodes(errors, errorCodes = []) {
  if (isArray(errors)) {
    return errors.some(error => errorCodes.includes(error.errorCode || error.code));
  }
  if (isObject(errors)) {
    return Object.values(errors).some(({ code }) => errorCodes.includes(code));
  }

  return false;
}

export function reduceApiErrorsToObject(errors) {
  return errors.reduce((obj, error) => {
    const fieldName = error.field || 'default';
    const code = error.code || error.errorCode;

    return has(obj, error.field)
      ? obj
      : { ...obj, [fieldName]: { code, message: error.message } };
  }, {});
}

export function mapBusinessError(error) {
  const fieldName = error.field || 'default';
  const code = error.code || error.errorCode;

  return { [fieldName]: { code, message: error.message } };
}

/**
 * @example
 * { name: { code: 'required', message: 'Required field' } } -> { name: 'Required field' }
 *
 * @param {object} errors
 * @return {object}
 */
export function mapErrorMessages(errors) {
  return mapValues(errors, errorValue => errorValue.message);
}

export function rethrowTwoFaError(error) {
  const twoFaErrorCodes = [ ErrorCodes.invalidValue, ErrorCodes.expired ];
  const isTwoFaError = errorsConsistOneOfErrorCodes(error.data, twoFaErrorCodes);

  if (isTwoFaError) {
    throw error;
  }
}

export function getUiErrorMessage(apiErrorMessage, defaultErrorMessage) {
  const uiErrorMessage = SYSTEM_TO_UI_ERROR_MESSAGES.find(({ system }) => apiErrorMessage?.match(system));

  return uiErrorMessage ? uiErrorMessage.ui : defaultErrorMessage;
}

export function navigateToNotFoundPage(history, resourceType) {
  history.push({
    pathname: navigation.notFound,
    state: { resource: { type: resourceType } },
  });
}

export function showGeneralToastError(error, { extractFromApi } = {}) {
  const extractedErrorMessage = extractMessage(error, extractFromApi);

  toast.error(extractedErrorMessage || ERRORS.generalError);
}

// Private functions

function extractMessage(error, extractFromApi) {
  if (!error || !error.data) return null;

  if (extractFromApi) {
    const extractedMessage = tryToExtractFromErrorsObject(error);

    if (extractedMessage) {
      return extractedMessage;
    }
  }

  // TODO https://ftcs-tech.atlassian.net/browse/IDE-15753
  return null;
}

function tryToExtractFromErrorsObject(error) {
  // Try to find the first key for which message is defined.
  const errKey = findKey(error.data, 'message');

  return errKey
    ? error.data[errKey].message
    : null;
}
