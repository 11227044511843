export const filterNames = {
  date: {
    from: 'range[from]',
    general: 'date',
    to: 'range[to]',
  },
  status: 'status',
  type: 'type',
  account: 'account',
  accounts: 'accounts',
};

// for testing
export const dataTestIds = {
  accountsSelectAllDataTestId: 'accounts-select-all',
  typesSelectAllDataTestId: 'types-select-all',
  statusesSelectAllDataTestId: 'statuses-select-all',
};

