import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';

const ShowOnMobile = styled.div`
  display: ${props => (props.isInline ? 'inline' : 'block')};

  @media ${DEVICE.tablet} {
    display: none;
  }
`;

export default ShowOnMobile;
