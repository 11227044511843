import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';

const GridContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  flex-wrap: wrap;
  margin: 0;
  gap: 0.9375rem;

  @media ${DEVICE.laptop} {
    flex-grow: 20;
    gap: 1.875rem;
  }
`;

const ListContainer = styled.div`
  margin: 0;
  width: 100%;
`;

export default {
  GridContainer,
  ListContainer,
};
