import { Tabs } from '@general/intergiro-ui-kit';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import AccountsProvider from 'contexts/accounts';
import FiltersProvider from 'contexts/filters';
import { NavigationContext } from 'contexts/navigation';
import TransactionProvider from 'contexts/transaction';
import { UserContext } from 'contexts/user';
import { useAccount } from 'hooks';

import { AccountFilterBar, BankDetails, Transactions } from './components';
import AccountHeader from './components/account-header';
import S from './components/styles';

function Account() {
  const { permissions: { canViewTransactions } } = useContext(UserContext);
  const { setBackButtonLabel } = useContext(NavigationContext);
  const { id } = useParams();
  const { account } = useAccount(id);
  const [ activeTab, setActiveTab ] = useState(canViewTransactions ? 'transactions' : 'details');

  const handleTabChange = tab => () => setActiveTab(tab);

  const showFilterBar = activeTab === 'transactions';

  function getAccountNameTopbar() {
    const displayName = get(account, 'displayName');

    return (
      <S.AccountName>
        {displayName}
      </S.AccountName>
    );
  }

  useEffect(() => {
    if (isEmpty(account)) return;

    setBackButtonLabel(getAccountNameTopbar());
  }, [account]);

  useEffect(() => () => setBackButtonLabel(), []);

  return (
    <AccountsProvider>
      <TransactionProvider>
        <FiltersProvider>
          <AccountHeader account={account} setActiveTab={setActiveTab} />
          <S.TabWrapper showFilterBar={showFilterBar}>
            <Tabs.Container name="tabs">
              {canViewTransactions && (
                <S.TabsHead
                  id="transactions"
                  isActive={activeTab === 'transactions'}
                  handleChange={handleTabChange('transactions')}
                >
                  Transactions
                </S.TabsHead>
                )}
              <S.TabsHead id="details" isActive={activeTab === 'details'} handleChange={handleTabChange('details')}>
                Account details
              </S.TabsHead>
              {showFilterBar && <AccountFilterBar account={account} />}
            </Tabs.Container>
          </S.TabWrapper>
          <Tabs.Panel>
            {activeTab === 'details' && <BankDetails id={id} />}
            {canViewTransactions && activeTab === 'transactions' && <Transactions accountId={id} />}
          </Tabs.Panel>
        </FiltersProvider>
      </TransactionProvider>
    </AccountsProvider>
  );
}

export default Account;
