import { CircleFlag } from 'react-circle-flags';
import styled from 'styled-components';

import { Grid, Label } from 'components/transaction-sidebar/styles';

const FlagIcon = styled(CircleFlag)`
  height: 1.25rem;
  margin-right: 0.5rem;
  width: 1.25rem;
  vertical-align: bottom;
`;

const Account = styled.span`
  overflow-wrap: anywhere;
  text-align: right;
  color: ${props => props.theme.colors.black};
  font: ${props => props.theme.fonts.bodyMSemi};
`;

export default {
  FlagIcon,
  Account,
  Grid,
  Label,
};
