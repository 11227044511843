import { Modal } from '@general/intergiro-ui-kit';
import PropTypes from 'prop-types';
import React from 'react';

import { AlertBlock } from 'components';
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';

import S from './styles';

function SecurityBlockInfoModal({ isOpen, setIsOpen }) {

  const alertText = 'You reached activation attempts limit. Please contact Support team to activate your card.';

  return (
    <Modal isOpen={isOpen} shouldCloseOnOverlayClick>
      <S.Content>
        <S.Title>Security block</S.Title>
        <S.CloseIcon onClick={() => setIsOpen(false)} />
        <S.Separator />

        <S.HelperText>Activation for this card is currently unavailable.</S.HelperText>

        <AlertBlock icon={WarningIcon} text={alertText} />

        <S.Separator />

        <S.CTAContainer>
          <S.Button label="OK" category="primary" onClick={() => setIsOpen(false)} />
        </S.CTAContainer>

      </S.Content>
    </Modal>
  );
}

SecurityBlockInfoModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default SecurityBlockInfoModal;
