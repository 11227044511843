import { Button } from '@general/intergiro-ui-kit';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { UserContext } from 'contexts/user';
import { ApiKeysContext } from 'contexts/api-keys';
import { navigation } from 'constants/navigation';
import config from 'config/default';

import S from './styles';
import { dataTestIds } from './constants';
import APIKeysTable from './components/api-keys-table';

function API() {
  const { t } = useTranslation();
  const history = useHistory();
  const { permissions: { canCreateApiKeys } } = useContext(UserContext);
  const { apiKeys, removeApiKey, renewApiKey } = useContext(ApiKeysContext);

  const redirectToApiCreationPage = () => {
    history.push(navigation.createApiKey);
  };

  return (
    <S.Container>
      <S.Header>
        <S.Title data-testid={dataTestIds.title}>
          {t('api.title')}
        </S.Title>
        {canCreateApiKeys && (
          <Button data-testid={dataTestIds.apiCreationButton}
            size="small"
            onClick={redirectToApiCreationPage}
            label={t('api.button')}
            icon={<S.PlusIcon />}
            type="primary"
          />
        )}
      </S.Header>
      <S.Notification>
        <S.NotificationContent>
          <S.NotificationTitle>
            {t('api.notification.title')}
          </S.NotificationTitle>
          <S.NotificationText>
            {t('api.notification.text')}
          </S.NotificationText>
        </S.NotificationContent>
        <S.NotificationLink data-testid={dataTestIds.apiLink} href={config.apiDocumentationUrl} target="_blank">
          {t('api.notification.link')}
          <S.ChevronIcon />
        </S.NotificationLink>
      </S.Notification>
      <S.Subtitle>
        {t('api.subtitle')}
      </S.Subtitle>
      <APIKeysTable data={apiKeys} removeApiKey={removeApiKey} renewApiKey={renewApiKey} />
    </S.Container>
  );
}
export default API;
