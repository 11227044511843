import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';

const Wrapper = styled.div`
  padding: 1.25rem;

  @media ${DEVICE.tablet} {
    align-items: flex-start;
    display: grid;
    grid-gap: 3.75rem;
    grid-template-columns: 20.9375rem 1fr;
    margin-top: 1.875rem;
    padding: 0;
  }
`;

export default { Wrapper };
