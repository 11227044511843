import PropTypes from 'prop-types';
import React from 'react';

import S from './styles';

function Badge(props) {
  const { children, className } = props;

  return (
    <S.Badge data-testid="badge-content" className={className}>
      <S.Indicator>
        {children}
      </S.Indicator>
    </S.Badge>
  );
}

Badge.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Badge;
