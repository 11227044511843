import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import { ModalContent } from 'components';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';

import S from './styles';

function ConfirmationModal({ isOpen, setIsOpen, onSubmit }) {
  const handleConfirm = useCallback(() => {
    onSubmit();
  }, [onSubmit]);

  const handleCancel = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const CTAButtons = (
    <>
      <S.Button
        label="Delete"
        icon={<DeleteIcon />}
        category="primary"
        onClick={handleConfirm}
      />
      <S.Button
        label="Cancel"
        category="secondary"
        onClick={handleCancel}
      />
    </>
  );

  return (
    <ModalContent
      title="Are you sure you want to delete this role?"
      hasCloseIcon
      isOpen={isOpen}
      CTAButtons={CTAButtons}
      setIsOpen={setIsOpen}
    />
  );
}

ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ConfirmationModal;
