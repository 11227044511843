import { LoaderWithDelay } from '@general/intergiro-ui-kit';
import PropTypes from 'prop-types';
import React, { createContext, useEffect, useMemo, useState } from 'react';

export const GlobalLoaderContext = createContext({});

function GlobalLoaderProvider({ children }) {
  const [ loading, setLoading ] = useState(false);
  const [ delay, setDelay ] = useState(0);
  const [ numberOfRequests, setNumberOfRequests ] = useState(0);

  useEffect(() => {
    setLoading(numberOfRequests > 0);
  }, [numberOfRequests]);

  function startLoading() {
    setNumberOfRequests(mostUpdatedState => {
      const mostUpdated = mostUpdatedState + 1;

      return mostUpdated;
    });
  }

  function endLoading() {
    setNumberOfRequests(mostUpdatedState => mostUpdatedState > 0 ? mostUpdatedState - 1 : 0);
  }

  function setLoadingDelay() {
    setDelay(1000);
  }

  function resetLoadingDelay() {
    setDelay(0);
  }

  const loaderValues = useMemo(() => ({ startLoading, endLoading, setLoadingDelay, resetLoadingDelay }), []);

  return (
    <GlobalLoaderContext.Provider value={loaderValues}>
      <LoaderWithDelay loading={loading} delay={delay} />
      {children}
    </GlobalLoaderContext.Provider>
  );
}

GlobalLoaderProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GlobalLoaderProvider;
