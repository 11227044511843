import PropTypes from 'prop-types';
import React, { memo, useEffect } from 'react';

import config from 'config';

function MerchantMonitor({ privateKey }) {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = config.intergiroMonitor.scriptUrl;
    script.async = true;
    script.type = 'module';

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <intergiro-monitor
      data-testid="merchant-monitor"
      server={config.intergiroMonitor.serverUrl}
      api-key={privateKey}
    />
  );
}

MerchantMonitor.propTypes = {
  privateKey: PropTypes.string,
};

export default memo(MerchantMonitor);
