import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';

import InfoIcon from 'assets/icons/info-toast.svg';
import SuccessIcon from 'assets/icons/success.svg';
import WarningIcon from 'assets/icons/warning.svg';
import { hex2rgba } from 'helpers/styles';

const StyledContainer = styled(ToastContainer)`
  @media only screen and (min-width: 30rem) {
    min-width: 22.5rem;
  }

  .Toastify__toast {
    align-items: center;
    background-color: ${props => props.theme.colors.white};
    background-position: 1.25rem center;
    background-repeat: no-repeat;
    border-radius: 0.5rem;
    box-shadow: 0 0.375rem 1rem ${props => hex2rgba(props.theme.colors.black, 0.15)};
    color: ${props => props.theme.colors.black};
    display: flex;
    margin-bottom: 0.625rem;
    padding: 1.25rem 1.25rem 1.25rem 4.5rem;
  }

  .Toastify__toast-body {
    max-width: calc(100% - 2rem);
    padding-right: 1.25rem;
    font: ${props => props.theme.fonts.bodyM};
  }

  .Toastify__toast--error {
    background-image: url(${WarningIcon});
  }

  .Toastify__toast--info {
    background-image: url(${InfoIcon});
  }

  .Toastify__toast--success {
    background-image: url(${SuccessIcon});
  }
`;

export default { StyledContainer };
