import React, { useCallback, useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useParams } from 'react-router-dom';

import { EmptyState, PaymentListGroup, TransactionPagination } from 'components';
import { navigation } from 'constants/navigation';
import { defaultMetadata, metaTypes, queryTypes, transactionStatusCodes } from 'constants/transactions';
import { TransactionContext } from 'contexts/transaction';
import { useApiPayment, useTransactionList } from 'hooks';
import TransactionSummary from 'pages/activity/components/transaction-summary';
import { getParameterFromURL, getPaymentSummary, getTransactionsGroupedByCurrency } from 'utils/helpers';

import S from './styles';
import { dataTestIds } from './constants';

let currentMeta = {};
function PaymentList() {
  const { activeTransaction, isSidebarOpen, setActiveTransactionById, transactions } = useContext(TransactionContext);
  const params = useParams();
  const [ query, setQuery ] = useState({
    [metaTypes.general]: {
      [queryTypes.bulkPaymentId]: params.id,
      [queryTypes.statuses]: [transactionStatusCodes.groupedCode.pendingApproval],
    },
  });
  const { isLoading: isLoadingTransactions, meta } = useTransactionList(query);
  const { isLoading: isLoadingApiPayment, apiPayment } = useApiPayment(params.id);
  const { t } = useTranslation();
  const location = useLocation();
  currentMeta = meta;

  const { groupedByCurrency } = getTransactionsGroupedByCurrency(transactions);

  const getPageQuery = () => {
    const limit = getParameterFromURL(metaTypes.limit, location);
    const startingAfter = getParameterFromURL(metaTypes.startingAfter, location);
    const endingBefore = getParameterFromURL(metaTypes.endingBefore, location);

    return {
      [metaTypes.general]: {
        ...defaultMetadata,
        ...currentMeta,
        ...(limit && { [queryTypes.limit]: limit }),
        ...(startingAfter && { [queryTypes.startingAfter]: startingAfter }),
        ...(endingBefore && { [queryTypes.endingBefore]: endingBefore }),
        [queryTypes.bulkPaymentId]: params.id,
        [queryTypes.statuses]: [transactionStatusCodes.groupedCode.pendingApproval],
      },
    };
  };

  const refreshQuery = useCallback(() => {
    setQuery(getPageQuery());
  }, [getPageQuery()]);

  if (!isLoadingTransactions && !transactions.length) return (
    <EmptyState
      title={t('paymentList.emptyState.title')}
      subTitle={(
        <Trans
          i18nKey="paymentList.emptyState.subTitle"
        >
          Go to the
          <S.Link to={navigation.payments}>Payments</S.Link>
          {' '}
          section to see all API payments.
        </Trans>
      )}
    />
  );

  if (isLoadingApiPayment) return null;

  return (
    <TransactionPagination
      meta={meta}
      transactions={transactions}
      refreshQuery={refreshQuery}
    >
      <S.PaymentsWrapper isSidebarOpen={isSidebarOpen} data-testid={dataTestIds.wrapper}>
        <S.Content>
          <PaymentListGroup
            payments={transactions}
            handlePaymentClick={id => setActiveTransactionById(id)}
            activePayment={activeTransaction}
          />
        </S.Content>
        <TransactionSummary
          isSummaryVisible
          count={apiPayment ? apiPayment.numberOfTransactions : 0}
          groupedByCurrency={groupedByCurrency}
          bulkPaymentId={params.id}
          paymentSummary={getPaymentSummary(apiPayment)}
          isApiPayment
        />
      </S.PaymentsWrapper>
    </TransactionPagination>
  );
}

export default PaymentList;
