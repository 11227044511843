import {
  Button as DefaultButton,
  Input as DefaultInput,
  RadioButton,
  Title as DefaultTitle,
} from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { ReactComponent as Cross } from 'assets/icons/cross.svg';
import { hex2rgba } from 'helpers/styles';

const Content = styled.div`
  background-color: ${props => props.theme.colors.white};
  border-radius: 0.5rem;
  bottom: auto;
  left: 50%;
  margin-right: -50%;
  padding: 1.875rem;
  position: absolute;
  right: auto;
  transform: translate(-50%, -50%);
  width: 33.75rem;
  top: 50%;
`;

const CloseIcon = styled(Cross)`
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
`;

const Title = styled(DefaultTitle)`
  font: ${props => props.theme.fonts.h4};
  padding: 0;
`;

const Separator = styled.hr`
  border-top: ${props => hex2rgba(props.theme.colors.black, 0.1)};
  margin: 1.25rem 0;
`;

const HelperText = styled.p`
  margin: 0;
  font: ${props => props.theme.fonts.bodyMBold};
  margin-bottom: 1.4375rem;
`;

const Input = styled(DefaultInput)`
  margin: 1.25rem 0;
`;

const CTAContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
`;

const Button = styled(DefaultButton)`
  margin-right: 0.625rem;
  min-width: fit-content;
  padding: 0.75rem 1.5rem;
`;

const Radio = styled(RadioButton)`
  margin-bottom: 1.625rem;
`;

const ValidationMessageBlock = styled.div`
  color: ${props => props.theme.colors.red};
  margin-top: 1.375rem;
  margin-bottom: 1.875rem;
`;

export default {
  Title,
  CloseIcon,
  Content,
  Separator,
  HelperText,
  ValidationMessageBlock,
  Input,
  CTAContainer,
  Button,
  Radio,
};
