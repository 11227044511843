
import { array, object, string } from 'yup';

const inviteUserSchema = object().shape({
  firstName: string()
    .trim()
    .required('First name is required')
    .max(100, 'Max. 100 characters'),

  lastName: string()
    .trim()
    .required('Last name is required')
    .max(100, 'Max. 100 characters'),

  email: string()
    .trim()
    .required('Email is required')
    .max(254, 'Max. 254 characters')
    .email('Invalid email format'),

  roles: array()
    .min(1, 'Roles are required'),
});

export default inviteUserSchema;
