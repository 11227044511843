import { Button as IntergiroButton } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { ReactComponent as DownloadIconComponent } from 'assets/icons/download.svg';

const Button = styled(IntergiroButton)`
  color: ${props => props.theme.colors.blue};
  font-weight: 500;

  &:hover {
    // TODO create a new component for such button in ui-kit.
    color: ${props => props.theme.colors.blue} !important;
  }
`;

const DownloadIcon = styled(DownloadIconComponent)`
  path {
    fill: ${props => props.theme.colors.blue};
  }
`;

export default { Button, DownloadIcon };
