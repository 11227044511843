// This function is to suppress console.warn generated by react-daterange-picker
// More info: https://github.com/wojtekmaj/react-datetime-picker/issues/137

function suppressFitWarning() {
  const nativeConsoleWarn = console.warn;

  function consoleWarningFiltered(...args) {
    const [firstMessage] = args;
    if (firstMessage.includes('<Fit')) {
      return null;
    }

    return nativeConsoleWarn(...args);
  }

  // eslint-disable-next-line no-process-env
  const isDevelopment = window.env.REACT_APP_ENVIRONMENT_NAME === 'development';
  const isTest = window.env.REACT_APP_ENVIRONMENT_NAME === 'test';

  if (!isDevelopment && !isTest) {
    return;
  }

  console.warn = consoleWarningFiltered;
}

export default suppressFitWarning;
