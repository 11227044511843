import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { navigateToNotFoundPage, showGeneralToastError } from 'utils/errors';
import { getMerchantPrivateKey } from 'api/merchants';
import { GlobalLoaderContext } from 'contexts/global-loader';
import { NOT_FOUND } from 'constants/status-code';
import { resourceType } from 'constants/resource';

const useMerchantPrivateKey = id => {
  const history = useHistory();
  const [ privateKey, setPrivateKey ] = useState(null);
  const { startLoading, endLoading } = useContext(GlobalLoaderContext);

  useEffect(() => {
    const fetchMerchantPrivateKey = async () => {
      startLoading();
      try {
        const data = await getMerchantPrivateKey(id);

        setPrivateKey(data);
      } catch (error) {
        error.status === NOT_FOUND
          ? navigateToNotFoundPage(history, resourceType.merchant)
          : showGeneralToastError(error);
      } finally {
        endLoading();
      }
    };

    fetchMerchantPrivateKey();
  }, []);

  return { privateKey };
};

export default useMerchantPrivateKey;
