import { Title as TitleComponent } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { ReactComponent as Chevron } from 'assets/icons/chevron-left.svg';
import { ReactComponent as Plus } from 'assets/icons/plus.svg';
import { DEVICE } from 'constants/breakpoints';

const Container = styled.div`
  max-width: 100vw;

  @media ${DEVICE.tablet} {
    max-width: calc(100vw - 5rem);
  }

  @media ${DEVICE.desktop} {
    max-width: calc(100vw - 25.125rem);
  }
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  padding: 0 1.25rem 2.5rem 1.25rem;
  flex-direction: column;
  align-items: flex-start;

  @media ${DEVICE.tablet} {
    padding: 1.0625rem 0 2.625rem 0;
    flex-direction: row;
  }

  @media ${DEVICE.laptop} {
    padding: 1.0625rem 0 2.625rem;
  }
`;

const Title = styled.span`
  font: ${props => props.theme.fonts.h3Bold};
  margin-bottom: 1.25rem;

  @media ${DEVICE.tablet} {
    font: ${props => props.theme.fonts.h1Bold};
  }
`;

const PlusIcon = styled(Plus)`
  width: 1.25rem;
  height: 1.25rem;
  
  path {
    fill: ${props => props.theme.colors.white};
    stroke: ${props => props.theme.colors.white};
  }
`;

const Notification = styled.div`
  align-items: flex-start;
  flex-direction: column;
  border-radius: 0.5rem;
  border: 0.0625rem solid ${props => props.theme.colors.greyBright};
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.25rem;
  margin: 0 1.25rem 2.5rem;
  width: calc(100% - 2.5rem);

  @media ${DEVICE.tablet} {
    margin: 0 0 3.75rem;
    width: 100%;
  }

  @media ${DEVICE.laptop} {
    flex-direction: row;
    padding: 1.25rem;
  }
`;

const NotificationContent = styled.div`
  max-width: 33.5625rem;
  margin-bottom: 1rem;

  @media ${DEVICE.laptop} {
    margin-bottom: 0;
  }
`;

const NotificationTitle = styled.p`
  font: ${props => props.theme.fonts.bodyMSemi};
  margin: 0 0 .625rem;

  @media ${DEVICE.tablet} {
    font: ${props => props.theme.fonts.bodyLSemi};
  }
`;

const NotificationText = styled.p`
  font: ${props => props.theme.fonts.bodyS};
  white-space: pre-line;
  margin: 0;
`;

const NotificationLink = styled.a`
  display: flex;
  align-items: center;
  font: ${props => props.theme.fonts.bodyMSemi};
  color: ${props => props.theme.colors.blue};
`;

const ChevronIcon = styled(Chevron)`
  margin-left: 0.5935rem;
  width: 0.4rem;
  fill: ${props => props.theme.colors.blue};
  transform: rotate(180deg);
`;

const Subtitle = styled(TitleComponent)`
  font: ${props => props.theme.fonts.h1Bold};
  margin: 0 0 0.625rem 1.25rem;
  font-size: 1.375rem;

  @media ${DEVICE.tablet} {
    margin: 0 0 0.625rem 0;
  }
`;

export default {
  Container,
  Header,
  Title,
  PlusIcon,
  Notification,
  NotificationContent,
  NotificationTitle,
  NotificationText,
  NotificationLink,
  ChevronIcon,
  Subtitle,
};
