import PropTypes from 'prop-types';
import React from 'react';

import { LOCAL_RAILS } from 'pages/send-funds/constants';

import { emptyCurrencyMessages, internationalMessages, localCurrenciesMessages } from './constants';
import S from './styles';

function RailsNotificationBlock({ paymentRails, currency }) {
  const isLocal = paymentRails === LOCAL_RAILS;

  const messagesByRails = isLocal
    ? localCurrenciesMessages[currency]
    : internationalMessages;

  const messages = (!currency && !messagesByRails) || !messagesByRails
    ? emptyCurrencyMessages
    : messagesByRails;

  return (
    <S.InfoBlock>
      <S.List>
        {messages.map(message => (
          <S.InfoText key={message}>
            {message}
          </S.InfoText>
        ))}
      </S.List>
    </S.InfoBlock>
  );
}

RailsNotificationBlock.propTypes = {
  paymentRails: PropTypes.string.isRequired,
  currency: PropTypes.string,
};

export default RailsNotificationBlock;
