import { Button as IntergiroButton } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { ReactComponent as DownloadIconComponent } from 'assets/icons/download.svg';
import { Detail as DetailComponent, Grid, Label } from 'components/transaction-sidebar/styles';

const Wrapper = styled.span`
  width: 100%;
`;

const Section = styled.span`
  display: ${props => props.isHidden ? 'none' : 'block'};
`;

const Button = styled(IntergiroButton)`
  color: ${props => props.theme.colors.blue};
  font-weight: 500;
  height: auto;

  &:hover {
    // TODO create a new component for such button in ui-kit.
    color: ${props => props.theme.colors.blue} !important;
  }
`;

const DownloadIcon = styled(DownloadIconComponent)`
  path {
    fill: ${props => props.theme.colors.blue};
  }
`;

const Detail = styled(DetailComponent)`
  display: flex;
`;

export default {
  Wrapper,
  Section,
  Grid,
  Detail,
  Label,
  Button,
  DownloadIcon,
};
