import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import React from 'react';

import S from './styles';

function TotalBlock({ className, content }) {
  const lines = Object.keys(content)
    .filter(key => !isEmpty(content[key]))
    .map(key => ({
      key,
      label: content[key]?.label,
      value: content[key]?.value,
      hintText: content[key]?.hintText,
    }));

  return (
    <S.Container className={className}>
      {lines.map(line => (
        <S.Row key={line.key} data-testid={line.key}>
          {!isNil(line.label) && (
            <S.Text isTotal={line.key === 'total'} isLabel>
              {line.label}
            </S.Text>
          )}

          {!isNil(line.value) && (
            <S.Text isTotal={line.key === 'total'}>
              {line.hintText && <S.Hint text={line.hintText} isInfo />}
              {line.value}
            </S.Text>
          )}

          {isNil(line.value) && line.component}
        </S.Row>
      ))}
    </S.Container>
  );
}

TotalBlock.propTypes = {
  content: PropTypes.instanceOf(Object).isRequired,
  className: PropTypes.string,
};

export default TotalBlock;
