import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ModalContent } from 'components';
import { ReactComponent as GooglePlay } from 'assets/icons/play-market-action.svg';
import BackgroundImage from 'assets/images/app-modal-bg.png';
import config from 'config';

import S from './styles';

function MobileAppDownloadModal({ isOpen, setIsOpen }) {
  const { t } = useTranslation();

  return (
    <ModalContent
      title={t('mobileAppDownloadModal.title')}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      hasCloseIcon
      CTAButtons={(
        <S.ActionRow>
          <S.Action target="_blank" href={config.appStoreUrl}>
            <S.AppStore />
          </S.Action>
          <S.Action target="_blank" href={config.googlePlayUrl}>
            <GooglePlay />
          </S.Action>
        </S.ActionRow>
      )}
    >
      <S.Content>
        <S.ContentBackground>
          <S.Background alt={t('mobileAppDownloadModal.title')} src={BackgroundImage} />
        </S.ContentBackground>
        <S.ContentText>
          {t('mobileAppDownloadModal.text')}
        </S.ContentText>
      </S.Content>
    </ModalContent>
  );
}

MobileAppDownloadModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default MobileAppDownloadModal;
