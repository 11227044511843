import { Button as DefaultButton } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right-thin.svg';
import { ReactComponent as DefaultTrustedIcon } from 'assets/icons/trusted.svg';
import { TotalBlock } from 'components';
import { DEVICE } from 'constants/breakpoints';

const Title = styled.p`
  margin: 0 0 1.625rem;
  font: ${props => props.theme.fonts.h3Bold};
`;

const AmountAndAccountContainer = styled.div`
  display: flex;
  align-items: center;
  font: ${props => props.theme.fonts.bodyL};

  @media ${DEVICE.laptop} {
    font: ${props => props.theme.fonts.bodyM};
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: ${props => (props.marginTop ? '1.25rem;' : 0)};

  @media ${DEVICE.laptop} {
    flex-direction: row;
    margin-top: ${props => (props.marginTop ? '1.25rem' : 0)};
  }
`;

const ArrowRight = styled(ArrowRightIcon)`
  display: none;

  path {
    fill: ${props => props.theme.colors.greyLight};
  }

  @media ${DEVICE.laptop} {
    display: block;
    width: 7.9375rem;
    padding: 0 2.125rem;
  }
`;

const Amount = styled.p`
  font: ${props => props.theme.fonts.h3Bold};
  color: ${props => props.theme.colors.greyMedium};
  flex: none;
`;

const Payments = styled.p`
  font: ${props => props.theme.fonts.h3Bold};
  color: ${props => props.theme.colors.blue};
  flex: none;
`;

const TotalContainer = styled(TotalBlock)`
  margin-top: 1.25rem;

  @media ${DEVICE.tablet} {
    max-width: 30rem;
    width: 100%;
  }

  @media ${DEVICE.laptop} {
    margin-top: 1.25rem;
  }
`;

const CancelButton = styled.button`
  display: block;
  border: 0.125rem solid ${props => props.theme.colors.black};
  border-radius: 1.875rem;
  background: none;
  padding: 0.5rem 1rem;
  font: ${props => props.theme.fonts.bodyMBold};
  width: 100%;

  &:hover {
    cursor: pointer;
  }
`;

const ContinueButton = styled(DefaultButton)`
  margin-top: 3.75rem;
  margin-bottom: 0.9375rem;
`;

const TrustedIcon = styled(DefaultTrustedIcon)`
  width: 0.875rem;
  height: 0.875rem;
  margin-right: 0.375rem;

  path {
    fill: ${props => props.theme.colors.greyMedium};
    fill-rule: evenodd;
  }
`;

export default {
  Amount,
  AmountAndAccountContainer,
  ArrowRight,
  CancelButton,
  ContinueButton,
  Row,
  Title,
  TotalContainer,
  Payments,
  TrustedIcon,
};
