import { Button as ButtonUi } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { ReactComponent as Plus } from 'assets/icons/plus.svg';
import Success from 'assets/icons/success.svg';
import { DEVICE } from 'constants/breakpoints';

const Icon = styled.div`
  background-image: url(${Success});
  background-size: cover;
  height: 0;
  padding: 0 0 2.5rem;
  width: 2.5rem;
  margin: 0 auto;

  @media ${DEVICE.laptop} {
    margin: 0;
    width: 4.5rem;
    padding-bottom: 4.5rem;
  }
`;

const Text = styled.p`
  margin: 0;
  font: ${props => props.theme.fonts.bodyM};
  padding: 2.5rem 0 1.875rem 0;
  text-align: center;

  @media ${DEVICE.laptop} {
    margin: 0;
    text-align: left;
    font: ${props => props.theme.fonts.h3Bold};
    padding: 1.875rem 0 3.75rem 0;
  }
`;

const Button = styled(ButtonUi)`
  width: calc(100vw - 3.5rem);
  margin-bottom: 0.9375rem;

  @media ${DEVICE.laptop} {
    width: inherit;
    margin-right: ${({ back }) => back && '0.5rem'};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${DEVICE.laptop} {
    flex-direction: row;
  }
`;

const  PlusIcon = styled(Plus)`
  path {
    fill: ${props => props.theme.colors.white};
    stroke: ${props => props.theme.colors.white};
  }
`;

export default {
  Button,
  ButtonContainer,
  Icon,
  Text,
  PlusIcon,
};
