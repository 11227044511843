import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { generateDefaultPaymentReference } from 'pages/send-funds/components/payment-reference-field/utils';
import { ValidationWrapper } from 'components';

import S from './styles';

function PaymentReference({
  name,
  value,
  currency,
  rails,
  corporationName,
  accountNumber,
  error,
  touched,
  setPaymentReferenceFieldValue,
  setPaymentReferenceFieldTouched,
}) {
  const [ hintText, setHintText ] = useState('');

  useEffect(() => {
    if (!touched) {
      const {
        value: defaultValue,
        hintText: defaultHintText,
      } = generateDefaultPaymentReference(
        currency,
        rails,
        corporationName,
        accountNumber,
      );

      setPaymentReferenceFieldValue(defaultValue);
      setHintText(defaultHintText);
    }
  }, [accountNumber]);

  const label = 'Payment reference';

  return (
    <ValidationWrapper
      error={error}
      touched={touched}
    >
      <S.Input
        name={name}
        label={label}
        value={value}
        onChange={event => {
          setPaymentReferenceFieldValue(event.target.value);
          setPaymentReferenceFieldTouched();
        }}
        hint={hintText}
      />
    </ValidationWrapper>
  );
}

PaymentReference.propTypes = {
  name: PropTypes.string,
  currency: PropTypes.string,
  rails: PropTypes.string,
  corporationName: PropTypes.string,
  accountNumber: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  touched: PropTypes.bool,
  setPaymentReferenceFieldValue: PropTypes.func.isRequired,
  setPaymentReferenceFieldTouched: PropTypes.func.isRequired,
};

export default PaymentReference;
