import { object, ref, string } from 'yup';

import { pinDigitNumber } from 'constants/card';

export const PinSchema = object().shape({
  pin: string()
    .required('Please enter PIN number')
    .test('four-digits', 'Length should be 4 characters', value => String(value).length === pinDigitNumber),
  confirmationPin: string()
    .required('Please enter Confirm PIN number')
    .oneOf([ ref('pin'), null ], 'Values in PIN and Confirm PIN fields are not matching'),
});
