import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';

const Container = styled.div`
  width: 100%;
  padding: 1.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
  border: 0.0625rem solid ${props => props.theme.colors.greyBright};
  border-radius: 0.5rem;

  @media ${DEVICE.tablet} {
    max-width: 30rem;
    margin-top: 0;
  }
`;

const Notion = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  @media ${DEVICE.tablet} {
    max-width: 20rem;
    margin-bottom: 0;
  }
`;

const Title = styled.div`
  font: ${props => props.theme.fonts.bodySSemi};
  margin-bottom: 0.5rem;

  @media ${DEVICE.tablet} {
    font: ${props => props.theme.fonts.bodyMSemi};
  }
`;

const Text = styled.div`
  font: ${props => props.theme.fonts.bodyXsSemi};
  color: ${props => props.theme.colors.greyMedium};

  @media ${DEVICE.tablet} {
    font: ${props => props.theme.fonts.bodySSemi};
  }
`;

export default {
  Container,
  Title,
  Text,
  Notion,
};
