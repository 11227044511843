
import PropTypes from 'prop-types';
import React from 'react';

import { cardStatusesLabels, cardStatusReasonDescriptionMap, cardStatusReasonKeys } from 'constants/card';

import S from './styles';

function Status({ className, status, statusReason }) {
  const showStatusReason = cardStatusReasonKeys.includes(statusReason);

  return (
    <S.StatusWrapper>
      <S.Status status={status} data-testid="card-item-status" className={className}>
        {cardStatusesLabels[status]}
      </S.Status>

      {showStatusReason && <S.Hint text={cardStatusReasonDescriptionMap[statusReason]} />}
    </S.StatusWrapper>
  );
}

Status.propTypes = {
  className: PropTypes.string,
  status: PropTypes.string,
  statusReason: PropTypes.string,
};

export default Status;
