import bff from 'utils/bff';

export const getUsers = async () => {
  const { data: users } = await bff.user.getUsers();

  return users;
};

export const getUser = async id => {
  const { data: user } = await bff.user.getUser({}, { id });

  return user;
};

export async function inviteUser(data) {
  const { data: user } = await bff.user.inviteUser(data);

  return user;
}

export async function reinviteUser(id, data) {
  const { data: user } = await bff.user.reinviteUser(data, { id });

  return user;
}

export async function updateUser(id, data) {
  const { data: user } = await bff.user.updateUser(data, { id });

  return user;
}

export async function blockUser(id) {
  const { data: user } = await bff.user.blockUser({}, { id });

  return user;
}

export async function unblockUser(id) {
  const { data: user } = await bff.user.unblockUser({}, { id });

  return user;
}
