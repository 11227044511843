import { Checkbox as IntergiroCheckbox } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { ReactComponent as ChevronDownIcon } from 'assets/icons/chevron-down.svg';

const DateRangeWrapper = styled.div`
  z-index: 2;

  > div {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
  }

  .react-calendar {
    margin: 1rem 0 0 !important;
  }

  .react-daterange-picker__inputGroup__input:invalid {
    background-color: inherit;
  }
`;

const FilterElementWrapper = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 0.25rem;
  justify-content: space-between;

  svg {
    margin: 0.1875rem 0 0 0.125rem;
    ${props => (props.isOpen ? 'transform: rotate(180deg)' : 'transform: rotate(0deg)')};
    transition: 0.2s transform ease;
  }
`;

const Checkbox = styled(IntergiroCheckbox)`
  margin-bottom: 0.625rem;
`;

const CounterLabelWrapper = styled.div`
  align-items: baseline;
  display: flex;
  justify-content: center;
`;

const CountBadge = styled.span`
  align-items: center;
  background: ${props => props.theme.colors.greyBright};
  border-radius: 0.1875rem;
  display: flex;
  font-size: 0.875rem;
  height: 1.25rem;
  justify-content: center;
  margin: 0 0.375rem;
  padding: 0.0625rem 0.375rem 0.125rem;
  width: 1.25rem;
`;

const ChevronDown = styled(ChevronDownIcon)`
  fill: ${props => props.theme.colors.blue};
`;

export default {
  Checkbox,
  CounterLabelWrapper,
  CountBadge,
  DateRangeWrapper,
  FilterElementWrapper,
  ChevronDown,
};
