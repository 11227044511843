import { Button } from '@general/intergiro-ui-kit';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { showGeneralToastError } from 'utils/errors';
import { dataTestIds, viewMap } from 'pages/api-create/constants';
import config from 'config/default';
import { AlertBlock, CheckboxGroup } from 'components';
import { createKey } from 'api/api-keys';

import S from './styles';
import { ApiCreationSchema as validationSchema } from './schema';
import { defaultFormValues, formFields, permissionsOptions } from './constants';

function ApiCreationForm({ setView, setApiKey }) {
  const { t } = useTranslation();

  const onSubmit = async formData => {
    if (!isValid) return;

    try {
      const response = await createKey(formData);
      const apiKey = response.plaintextApiKey;
      setApiKey(apiKey);
      setView(viewMap.result);
    } catch (error) {
      showGeneralToastError(error);
    }
  };

  const formik = useFormik({
    initialValues: defaultFormValues,
    onSubmit,
    validationSchema,
  });

  const {
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    touched,
    errors,
    isSubmitting,
    isValid,
    values,
  } = formik;

  const NotificationText = useCallback(() => (
    <>
      <S.NotificationTitle>
        {t('createApi.form.notification.title')}
      </S.NotificationTitle>
      <S.NotificationContent>
        {t('createApi.form.notification.text')}
      </S.NotificationContent>
    </>
  ), []);

  const handleChange = (value, fieldName) => {
    setFieldTouched(fieldName);
    setFieldValue(fieldName, value);
  };

  const onPermissionsChange = event => {
    const key = event.target.value;
    const value = event.target.checked;

    setFieldTouched(formFields.permissions);
    if (value) {
      setFieldValue(formFields.permissions, [ ...values.permissions, key ]);
    } else {
      setFieldValue(formFields.permissions, values.permissions.filter(permission => key !== permission));
    }
  };

  return (
    <div data-testid={dataTestIds.apiCreationForm}>
      <S.Input
        name={formFields.name}
        label={t('createApi.form.keyName')}
        type="text"
        isClearable={false}
        disabled={isSubmitting}
        onChange={e => handleChange(e.target.value, formFields.name)}
        error={touched[formFields.name] && t(errors[formFields.name])}
        data-testid={dataTestIds.apiCreationFormInput}
      />
      <S.AlertBlockWrapper>
        <AlertBlock icon={S.InfoIcon} text={<NotificationText />} />
      </S.AlertBlockWrapper>
      <S.Subtitle>
        {t('createApi.form.permissions.title')}
      </S.Subtitle>
      <CheckboxGroup
        groupName="Any account"
        onChange={onPermissionsChange}
        list={permissionsOptions}
        showSelectAll={false}
        isInverse={false}
      />
      {touched[formFields.permissions] && errors[formFields.permissions] && (
        <S.ErrorMessage>
          {t(errors[formFields.permissions])}
        </S.ErrorMessage>
      )}
      <S.Terms>
        <Trans
          i18nKey="createApi.form.terms"
        >
          By proceeding you agree with our Business
          <S.Link href={config.businessTermsUrl} target="_blank">
            Terms and Conditions
          </S.Link>
          .
          <br />
          API keys are valid for 90 days and will be frozen unless renewed before the end of the 90-day period.
        </Trans>
      </S.Terms>
      <Button data-testid={dataTestIds.apiCreationFormButton} label={t('createApi.form.button')} onClick={handleSubmit} />
    </div>
  );
}

ApiCreationForm.propTypes = {
  setView: PropTypes.func.isRequired,
  setApiKey: PropTypes.func.isRequired,
};

export default ApiCreationForm;
