import { useContext, useEffect, useState } from 'react';

import { showGeneralToastError } from 'utils/errors';
import { getPermissionCategories } from 'api/roles';
import { GlobalLoaderContext } from 'contexts/global-loader';

const usePermissionCategories = () => {
  const { startLoading, endLoading } = useContext(GlobalLoaderContext);
  const [ permissionCategories, setPermissionCategories ] = useState([]);

  useEffect(() => {
    const fetchPermissionCategories = async () => {
      startLoading();

      try {
        const permissionCategoriesData = await getPermissionCategories();

        setPermissionCategories(permissionCategoriesData);
      } catch (error) {
        showGeneralToastError(error);
      } finally {
        endLoading();
      }
    };

    fetchPermissionCategories();
  }, [ startLoading, endLoading ]);

  return { permissionCategories, setPermissionCategories };
};

export default usePermissionCategories;
