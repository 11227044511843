import { formFields, modalTypes } from './constants';

export function parsePhoneNumber(phone) {
  if (!phone) {
    return '';
  }

  return phone?.charAt(0) === '+' ? phone.slice(1) : phone;
}

export function generateRequestDetails(type, values) {
  switch (type) {
    case modalTypes.password:
      return {
        currentPassword: values[formFields.currentPassword.name],
        update: {
          newPassword: values[formFields.newPassword.name],
          newPasswordRepeat: values[formFields.confirmNewPassword.name],
        },
      };

    case modalTypes.phone:
      return {
        currentPassword: values[formFields.currentPassword.name],
        update: { newPhone: values[formFields.phone.name] },
      };

    default:
      return {};
  }
}
