import React, { useContext } from 'react';
import { CircleFlag } from 'react-circle-flags';

import { CURRENCY_FLAGS } from 'constants/currencies';
import { CardContext } from 'contexts/card';
import { getFractionalAndDecimalParts } from 'utils/amount';

import S from './styles';

function CardHeader() {
  const { card: { availableBalance, currency } } = useContext(CardContext);
  const { fractionalPart, integerPart } = getFractionalAndDecimalParts(availableBalance, currency);

  return (
    <S.Header data-testid="card-page-header">
      <CircleFlag countryCode={CURRENCY_FLAGS[currency]} height="32" />
      <S.TitleContainer>
        <S.Currency>
          {currency}
        </S.Currency>
        <S.Balance>
          {integerPart}
          .
        </S.Balance>
        <S.BalanceFractionalPart>
          {fractionalPart}
        </S.BalanceFractionalPart>
      </S.TitleContainer>
    </S.Header>
  );
}

export default CardHeader;
