import { Input as DefaultInput } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { ReactComponent as DefaultInfoIcon } from 'assets/icons/info.svg';

const Input = styled(DefaultInput)`
  width: 100%;
  max-width: 30rem;
  margin-bottom: 1.25rem;
`;

const AlertBlockWrapper = styled.div`
  width: 100%;
  max-width: 30rem;
  margin-bottom: 2.5rem;
`;

const InfoIcon = styled(DefaultInfoIcon)`
  width: 2rem;
  height: 2rem;
  fill: ${props => props.theme.colors.blue};
`;

const NotificationTitle = styled.p`
  font: ${props => props.theme.fonts.bodyS};
  margin: 0 0 .5rem;
  font-weight: 600;
`;

const NotificationContent = styled.p`
  font: ${props => props.theme.fonts.bodyXs};
  margin: 0;
`;

const Subtitle = styled.p`
  font: ${props => props.theme.fonts.h3Bold};
  font-size: 1.375rem;
  margin: 0 0 0.25rem;
`;

const Terms = styled.p`
  font: ${props => props.theme.fonts.bodyMSemi};
  line-height: 1.5;
  margin: 2.5rem 0;
`;

const Link = styled.a`
  font: ${props => props.theme.fonts.bodyMSemi};
  color: ${props => props.theme.colors.blue};
`;

const ErrorMessage = styled.p`
  font: ${props => props.theme.fonts.bodyXs};
  color: ${props => props.theme.colors.red};
  margin: 0;
`;

export default {
  Input,
  AlertBlockWrapper,
  InfoIcon,
  NotificationContent,
  NotificationTitle,
  Subtitle,
  Terms,
  Link,
  ErrorMessage,
};
