import bff from 'utils/bff';

export async function signin(payload) {
  const { data } = await bff.auth.signin(payload);

  return data;
}

export async function confirmChallenge(payload) {
  const { data } = await bff.auth.confirmChallenge(payload);

  return data;
}

export async function setPassword(password, confirmPassword, token) {
  const data = await bff.auth.setPassword({ password, confirmPassword, token });

  return data;
}

export async function verifyToken(token) {
  const { data: verificationResult } = await bff.auth.verifyToken({ token });

  return verificationResult;
}

export async function resetPassword(payload) {
  const { data } = await bff.auth.resetPassword(payload);

  return data;
}

export async function verifyResetPasswordChallenge(challengeId) {
  const { data } = await bff.auth.verifyResetPasswordChallenge({}, { challengeId });

  return data;
}

export async function confirmResetPassword(payload) {
  const { data } = await bff.auth.confirmResetPassword(payload);

  return data;
}
