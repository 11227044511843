import styled from 'styled-components';
import { Tabs, Button as IntergiroButton } from '@general/intergiro-ui-kit';

import { ReactComponent as ArrowRightIconComponent } from 'assets/icons/arrow-right.svg';
import { DEVICE } from 'constants/breakpoints';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeadingRow = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 1.25rem 1.5rem;
  max-width: calc(100vw - 2.5rem);

  @media ${DEVICE.tablet} {
    max-width: calc(100vw - 5rem);
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0 2rem;
  }
  
  @media ${DEVICE.desktop} {
    max-width: calc(100vw - 25.125rem);
  }
`;

const Title = styled.h1`
  max-width: 100%;
  font: ${props => props.theme.fonts.bodyLBold};
  margin: 0 0 1.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media ${DEVICE.tablet} {
    max-width: 70%;
    margin: 0;
    font: ${props => props.theme.fonts.h1Bold};
  }
`;

const Button = styled(IntergiroButton)`
  width: 6.6875rem;

  @media ${DEVICE.tablet} {
    width: auto;
  }
`;

const ArrowRightTopIcon = styled(ArrowRightIconComponent)`
  width: ${props => props.theme.icons.medium};
  height: ${props => props.theme.icons.medium};
  transform: rotate(-45deg);
`;

const TabsHead = styled(Tabs.Head)`
  margin: 0 0 0 1.1rem;

  @media ${DEVICE.tablet} {
    margin: 0;
  }
`;

export default {
  Wrapper,
  HeadingRow,
  Title,
  Button,
  ArrowRightTopIcon,
  TabsHead,
};
