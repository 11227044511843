import PropTypes from 'prop-types';

export const beneficiaryShape = {
  id: PropTypes.string,
  entityType: PropTypes.string,
  account: PropTypes.shape({
    currency: PropTypes.string,
    paymentRail: PropTypes.string.isRequired,
    country: PropTypes.string,
    number: PropTypes.shape({
      type: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired,
    bankCode: PropTypes.shape({
      type: PropTypes.string,
      value: PropTypes.string.isRequired,
    }).isRequired,
    address: PropTypes.shape({
      country: PropTypes.string,
      city: PropTypes.string,
      region: PropTypes.string,
      street: PropTypes.string,
      postCode: PropTypes.string,
    }),
  }),
  name: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  isTrusted: PropTypes.bool.isRequired,
};
