export const formFields = {
  newPassword: {
    autocomplete: 'new-password',
    label: 'Password',
    name: 'newPassword',
  },
  confirmPassword: {
    autocomplete: 'new-password',
    label: 'Confirm password',
    name: 'confirmPassword',
  },
  privacy: {
    id: 'privacy',
    name: 'privacy',
    url: 'business-privacy-policy',
  },
};
