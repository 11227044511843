import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import giro from 'assets/giro.mp4';
import config from 'config';
import { navigation } from 'constants/navigation';
import { useWindowHeight } from 'hooks';

import S from './styles';

function LoginLayout({ children, links, allowSignUp }) {
  useWindowHeight();

  return (
    <S.Main>
      <S.Container>
        <S.Inner>
          <Link to={navigation.accounts}>
            <S.Logo />
          </Link>
          <S.Content>
            {children}
          </S.Content>
          <S.LinksContainer>
            {links.map(({ to, href, label }) => (
              <S.Link label={label} category="link" link={to ? Link : 'a'} linkProps={{ to, href }} key={label}>
                {label}
              </S.Link>
            ))}
          </S.LinksContainer>
        </S.Inner>
      </S.Container>
      <S.AnimatedContainer>
        <S.AnimatedInner>
          <S.Header>
            <S.Title>Online banking, built for modern businesses</S.Title>
            {allowSignUp && (
              <S.Button label="Sign up" category="secondary" link="a" linkProps={{ href: config.registrationUrl }} />
            )}
          </S.Header>
          <S.VideoContainer>
            <S.Video autoPlay loop playsInline muted>
              <source src={giro} type="video/mp4" />
            </S.Video>
          </S.VideoContainer>
          <S.Paragraph>
            Run your business online with Intergiro. Make and take payments, issue cards, manage your spending on the go
            {' '}
            and add features as your business grows.
          </S.Paragraph>
        </S.AnimatedInner>
      </S.AnimatedContainer>
    </S.Main>
  );
}

LoginLayout.propTypes = {
  children: PropTypes.node.isRequired,
  links: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    to: PropTypes.string,
    href: PropTypes.string,
  })),
  allowSignUp: PropTypes.bool,
};

LoginLayout.defaultProps = {
  links: [],
  allowSignUp: true,
};

export default LoginLayout;
