import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { EmptyState } from 'components';
import { navigation } from 'constants/navigation';
import { TransactionContext } from 'contexts/transaction';
import { usePendingApprovalList } from 'hooks';

import S from './styles';
import TransactionPendingActivities from './components/transaction-pending-activities';

function PendingApproval() {
  const { isSidebarOpen, transactions } = useContext(TransactionContext);
  const { isLoading } = usePendingApprovalList();
  const { t } = useTranslation();

  function renderPendingActivity() {
    if (isLoading) {
      return null;
    }

    if (transactions.length === 0) {
      return (
        <aside data-testid="pending-approval-empty-state">
          <EmptyState
            title={t('pendingApproval.emptyState.title')}
            subTitle={(
              <span>
                <Trans
                  i18nKey="pendingApproval.emptyState.subTitle"
                >
                  If you want to enable this feature, please go to
                  <S.Link to={navigation.users}>Users</S.Link>
                  {' '}
                  section and turn on payment approval.
                </Trans>
              </span>
            )}
          />
        </aside>
      );
    }

    return (
      <S.TransactionPendingActivitiesWrapper data-testid="transactions-pending-wrapper">
        <TransactionPendingActivities />
      </S.TransactionPendingActivitiesWrapper>
    );
  }

  return (
    <S.PageWrapper data-testid="transactions-wrapper">
      <S.PageContentWrapper>
        <S.TabsPanel>
          <S.TransactionActivityWrapper isSidebarOpen={isSidebarOpen}>
            {renderPendingActivity()}
          </S.TransactionActivityWrapper>
        </S.TabsPanel>
      </S.PageContentWrapper>
    </S.PageWrapper>
  );
}

export default PendingApproval;
