import { CircleFlag } from 'react-circle-flags';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';

const AccountLink = styled(Link)`
  border-radius: 0.5rem;
  box-shadow: ${props => props.theme.boxShadow};
  background: ${props => props.$isHidden ? props.theme.colors.greyBright : props.theme.colors.white};
  padding: 1.5rem 1.25rem 1rem 1.4rem;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 1.5rem;
  flex-basis: 100%;

  @media ${DEVICE.tablet} {
    margin: 0;
    ${props => (props.$isGrid && 'flex-basis: calc(50% - (1.875rem/2))')};
    padding: ${props => (props.$isGrid ? '1.5rem 1.25rem 1.5rem 1.4rem' : '0.6rem 0 1.3rem 1.5rem')};
  }
`;

const Name = styled.span`
  color: ${props => props.theme.colors.black};
  font: ${props => props.theme.fonts.bodyMBold};
  flex-grow: 1;
  ${props => (!props.$isGrid && `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `)};

  @media ${DEVICE.tablet} {
    flex-basis: ${props => (props.$isGrid ? 'auto' : '20%')};
    margin-left: ${props => (props.$isGrid ? '0' : '.5rem')};
    margin-top:${props => (props.$isGrid ? '0' : '.9rem')};
  }
`;

const Iban = styled.div`
  font: ${props => props.theme.fonts.bodyXsSemi};
  color: ${props => (props.theme.colors.greyMediumDark)};
  margin-top: .6rem;
  flex-basis: ${props => (props.$isGrid ? '100%' : '19%')}; 
  margin-left: ${props => (props.$isGrid ? '0' : '2rem')};
  display: block;
  
  @media ${DEVICE.tablet} {
    margin-top: ${props => (props.$isGrid ? '.6rem' : '1rem')};
    display: ${props => (props.$isGrid ? 'block' : 'none')};
  }
  
  @media ${DEVICE.desktop} {
      display: block;
      flex-grow: 1;
   }
`;

const Flag = styled(CircleFlag)`
  height: 2rem;
  margin-right: .4rem;
  margin-top: 1.15rem;
  order: ${props => (props.$isGrid ? '0' : '-1')};

  @media ${DEVICE.tablet} {
    margin-top: ${props => (props.$isGrid ? '1.2rem' : '.65rem')};
    margin-bottom: ${props => (props.$isGrid ? '0' : '-0.15rem')};
  }
  
  @media ${DEVICE.laptop} {
    margin-bottom: 0;
  }
`;

const Balance = styled.div`
  color: ${props => props.theme.colors.black};
  font: ${props => props.theme.fonts.h4};
  margin-top: 1.5rem;
  margin-right: 3.5rem;
  margin-left: .2rem;
  text-align: right;
  
  ${props => !props.$isGrid ? `
    order: 2;
    flex-basis: 10rem;
  ` : `
    align-self: flex-end;
  `};
  
  @media ${DEVICE.tablet} {
    margin-top: ${props => (props.$isGrid ? '1.5rem' : '0.7rem')};
  }
`;

const BalanceAmount = styled.b`
  color: ${props => props.isDanger ? props.theme.colors.red : props.theme.colors.black};
`;

const LabelsWrapper = styled.div`
  display: flex;
  gap: 0.4rem;
  flex-wrap: wrap;

  ${props => !props.$isGrid ? `
    order: 1;
    justify-content: right;
    flex-grow: 1;
    flex-basis: 20%;
    margin-top: .9rem;
  ` : `
      flex-basis: 100%;
      margin-top:1.5rem;
      margin-right:1rem
  `};
`;

const Label = styled.div`
  padding: 0.1rem 0.8rem;
  background: ${props => props.theme.colors.greyBright};
  border-radius: .8rem;
  font: ${props => props.theme.fonts.label};
  font-weight: 500;
  color: ${props => props.theme.colors.black};
`;

const Decimal = styled.span`
  font: ${props => props.theme.fonts.bodyLBold};
`;

const DotsActionDropdown = styled.div`
  position: absolute;
  right: 1.4rem;
  top: 1.4rem;

   > div > div:nth-of-type(1) > div {
     ${props => props.$isHidden && `background:${  props.theme.colors.greyBright}`};  
   }
`;

const Action = styled.div`
  font: ${props => props.theme.fonts.bodyS};
  color: ${props => props.theme.colors.black};
  padding: 0.625rem 1rem;
`;

export default {
  Balance,
  BalanceAmount,
  Decimal,
  Flag,
  Iban,
  Name,
  AccountLink,
  DotsActionDropdown,
  Action,
  LabelsWrapper,
  Label,
};
