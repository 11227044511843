import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { getDefaultPathByPermissions } from 'utils/permissions';
import { isSessionValid } from 'utils/token';

function PublicRoute({ children, ...props }) {
  return (
    <Route
      {...props}
      render={() => isSessionValid()
        ? <Redirect to={getDefaultPathByPermissions()} />
        : children}
    />
  );
}

PublicRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PublicRoute;
