import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { navigateToNotFoundPage, showGeneralToastError } from 'utils/errors';
import { getUser } from 'api/users';
import { GlobalLoaderContext } from 'contexts/global-loader';
import { NOT_FOUND } from 'constants/status-code';
import { resourceType } from 'constants/resource';

const useUser = id => {
  const history = useHistory();
  const { startLoading, endLoading } = useContext(GlobalLoaderContext);
  const [ isLoading, setLoading ] = useState(false);
  const [ user, setUser ] = useState({});

  useEffect(() => {
    if (!id) return;

    const fetchUser = async () => {
      setLoading(true);
      startLoading();

      try {
        const data = await getUser(id);

        setUser(data);
      } catch (error) {
        error.status === NOT_FOUND
          ? navigateToNotFoundPage(history, resourceType.user)
          : showGeneralToastError(error);
      } finally {
        setLoading(false);
        endLoading();
      }
    };

    fetchUser();
  }, [id]);

  return { isLoading, user, setUser };
};

export default useUser;
