import bff from 'utils/bff';

export const listApiPayments = async () => {
  const { data: apiPayments } = await bff.apiPayment.listApiPayments({});

  return apiPayments;
};

export const approveApiPayment = async payload => {
  const { bulkPaymentId, ...data } = payload;

  try {
    const { data: apiPayment } = await bff.apiPayment.approveApiPayment(data, { id: bulkPaymentId });

    return apiPayment;
  } catch (error) {
    return Promise.reject((error));
  }
};

export const rejectApiPayment = async payload => {
  const { bulkPaymentId, ...data } = payload;

  try {
    const { data: apiPayment } = await bff.apiPayment.rejectApiPayment(data, { id: bulkPaymentId });

    return apiPayment;
  } catch (error) {
    return Promise.reject((error));
  }
};

export const getApiPayment = async id => {
  const { data: apiPayment } = await bff.apiPayment.getApiPayment({}, { id });

  return apiPayment;
};
