import { coinsInUnit } from 'constants/currencies';

const coefficientsIndivisibleParticles = {
  BTC: 8,
  BCH: 8,
  LTC: 8,
  default: 2,
};

export const formatAmount = (amountInIndivisibleParticles = 0, currency = 'USD') => {
  const fixedCoefficient = coefficientsIndivisibleParticles[currency] || coefficientsIndivisibleParticles.default;
  const amount = toMainUnit(amountInIndivisibleParticles, currency);

  return toCommaFormat(amount, { currency, fixedCoefficient });
};

export function toIndivisibleUnit(amount, currency = 'USD') {
  const currencyCoefficient = coinsInUnit[currency] || 100;

  return Math.round((Number(amount) || 0) * currencyCoefficient);
}

export const prettifyAmount = (currency, amount) => `${formatAmount(amount)} ${currency}`;

export const getAmount = amount =>
  new Intl.NumberFormat('en-US',
    { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(amount));

export const getFractionalAndDecimalParts = (amount, currency) => {
  const formattedAmount = toMainUnit(amount, currency);
  const [ integer, fractional ] = String(formattedAmount).split('.');

  return {
    integerPart: new Intl.NumberFormat('en-US').format(Number(integer)),
    fractionalPart: String(fractional || '').padEnd(2, 0).substr(0, 2),
  };
};

export const reverseFormatNumber = val => {
  const locale = 'en-US';
  if (!val) return 0;
  const group = new Intl.NumberFormat(locale).format(1111).replace(/1/g, '');
  const decimal = new Intl.NumberFormat(locale).format(1.1).replace(/1/g, '');
  const reversedVal = String(val)
    .replace(new RegExp(`\\${  group}`, 'g'), '')
    .replace(new RegExp(`\\${  decimal}`, 'g'), '.');

  return Number.isNaN(reversedVal) ? 0 : reversedVal;
};

export function toMainUnit(amount, currency = 'USD') {
  const currencyCoefficient = coinsInUnit[currency] || 100;

  return Number((Number(amount || 0) / currencyCoefficient).toFixed(2));
}

// Split amount digit to the following format: 1000000 -> 1,000,000
function toCommaFormat(amount, options = { currency: 'USD', fixedCoefficient: 2 }) {
  return new Intl.NumberFormat('en-US', {
    currency: options.currency,
    minimumFractionDigits: options.fixedCoefficient,
  }).format(Number(amount));
}

export const transformSchemaAmount = (value, originalValue) => {
  if (!Number.isNaN(value)) {
    return toIndivisibleUnit(value);
  }

  return toIndivisibleUnit(Number(reverseFormatNumber(originalValue)));
};
