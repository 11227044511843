import { Title as TitleComponent } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  padding: ${props => props.isActivityButtonsHidden ? '0 1.25rem' : '0 1.25rem 2.5rem 1.25rem'};
  flex-direction: column;

  @media ${DEVICE.tablet} {
    padding: 1.0625rem 0 2.625rem 0;
    flex-direction: row;
  }

  @media ${DEVICE.laptop} {
    padding: 1.0625rem 0 2.625rem;
    flex-direction: row;
  }
`;

const Title = styled(TitleComponent)`
  font: ${props => props.theme.fonts.h3Bold};
  margin-bottom: 1.625rem;

  @media ${DEVICE.tablet} {
    font: ${props => props.theme.fonts.h2Bold};
    margin: 0;
  }

  @media ${DEVICE.tablet} {
    font: ${props => props.theme.fonts.h1Bold};
    margin: 0;
  }
`;

export default {
  Title,
  Header,
};
