export const tableRows = {
  name: 'name',
  creationDate: 'creation_date',
  freezeDate: 'freeze_date',
};

export const DAYS_BEFORE_API_KEY_EXPIRATION = 14;

export const approvalActions = {
  approve: 'approve',
  reject: 'reject',
  bulkApprove: 'bulkApprove',
  bulkReject: 'bulkReject',
  approveApiPayment: 'approveApiPayment',
  rejectApiPayment: 'rejectApiPayment',
};

