import { Avatar as DefaultAvatar } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

const CardWrapper = styled.div`
  background: ${props => props.theme.colors.greyBright};
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  max-width: 20.9375rem;
  min-height: 11.25rem;
  padding: 1.25rem;
  width: 100%;
`;

const UserName = styled.h3`
  color: ${props => props.theme.colors.black};
  font: ${props => props.theme.fonts.bodyMBold};
  margin: 0;
  padding: 0;
`;

const UserStatusWrapper = styled.div`
  margin-top: 0.625rem;
`;

const Grid = styled.div`
  align-items: flex-start;
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: space-between;
`;

const AvatarWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Avatar = styled(DefaultAvatar)`
  display: block;
  margin-right: 0;
  border-radius: 50%;
  overflow: hidden;

  > svg {
    width: inherit;
    height: inherit;
  }
`;

const Badge = styled.span`
  background-color: ${props => (props.color ? props.theme.colors[props.color] : props.theme.colors.greyBright)};
  border-radius: 0.8125rem;
  color: ${props => props.theme.colors.white};
  display: inline-block;
  font: ${props => props.theme.fonts.bodyXsSemi};
  padding: 0.0625rem 0.625rem 0.1875rem;
  text-align: center;
`;

const CardFooter = styled.div`
  margin-top: auto;
`;

const Email = styled.p`
  color: ${props => props.theme.colors.black};
  font: ${props => props.theme.fonts.bodyXsSemi};
  margin: 0;
`;

export default {
  AvatarWrapper,
  Avatar,
  Badge,
  CardWrapper,
  CardFooter,
  Grid,
  UserName,
  UserStatusWrapper,
  Email,
};
