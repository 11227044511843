import PropTypes from 'prop-types';

import { accountShape } from './transactions';

export const apiPaymentShape = PropTypes.shape({
  createdAt: PropTypes.string.isRequired,
  createdBy: PropTypes.shape({
    name: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
  }).isRequired,
  debitedAmount: PropTypes.number.isRequired,
  fromAccount: accountShape.isRequired,
  bulkPaymentId: PropTypes.string.isRequired,
  netTransactionAmount: PropTypes.number.isRequired,
  numberOfTransactions: PropTypes.number.isRequired,
  sourceCurrency: PropTypes.string.isRequired,
  sourceAmount: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  targetAmount: PropTypes.number.isRequired,
  targetCurrency: PropTypes.string.isRequired,
  totalFee: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
});
