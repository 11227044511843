import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';

import { ReactComponent as Cross } from 'assets/icons/cross.svg';
import { MODAL_SIZE, MODAL_TYPE } from 'constants/modal';
import { useView } from 'hooks';

import S from './styles';

function ModalContent({
  isOpen,
  title,
  hasCloseIcon,
  children,
  CTAButtons,
  setIsOpen,
  formName,
  onSubmit,
  size,
  type,
  hasStickyFooter,
  unsetOverflow,
  shouldCloseOnOverlayClick = true,
}) {
  const [ footerHeight, setFooterHeight ] = useState(0);
  const { isMobile } = useView();
  const displaySeparators = useMemo(() => type !== MODAL_TYPE.message, [type]);

  const footerRef = useCallback(node => {
    if (node !== null) {
      setFooterHeight(node.getBoundingClientRect().height);
    }
  }, []);

  const getContent = () => {
    const content = (
      <>
        {isValidChildren(children) && (
          <S.BodyWrapper data-testid="modal-content-body">
            {children}
          </S.BodyWrapper>
        )}
        <S.Footer hasStickyFooter={hasStickyFooter} ref={footerRef} footerHeight={footerHeight}>
          {displaySeparators && <S.Separator />}
          <S.CTAContainer hasExtraTopPadding={!displaySeparators}>
            {CTAButtons}
          </S.CTAContainer>
        </S.Footer>
      </>
    );

    if (formName) {
      return (
        <form id={formName} name={formName} onSubmit={onSubmit} data-testid="modal-content-form">
          {content}
        </form>
      );
    }

    return content;
  };

  return (
    <S.Modal
      isOpen={isOpen}
      openModal={setIsOpen}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      unsetOverflow={unsetOverflow}
    >
      <S.Content isLarge={size === MODAL_SIZE.large} data-testid="modal-content">
        <S.Header>
          <S.HeaderInner data-testid="modal-content-title">
            <S.Title>
              {title}
            </S.Title>
            {hasCloseIcon && <S.CloseIcon as={!isMobile && Cross} onClick={() => setIsOpen(false)} />}
          </S.HeaderInner>
          {children && displaySeparators && <S.Separator />}
        </S.Header>
        {getContent()}
      </S.Content>
    </S.Modal>
  );
}

// Private functions

function isValidChildren(children) {
  if (Array.isArray(children)) {
    return children.some(child => child);
  }

  return children;
}

ModalContent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  formName: PropTypes.string,
  hasCloseIcon: PropTypes.bool,
  size: PropTypes.string,
  type: PropTypes.string,
  children: PropTypes.node,
  CTAButtons: PropTypes.node.isRequired,
  setIsOpen: PropTypes.func,
  onSubmit: PropTypes.func,
  hasStickyFooter: PropTypes.bool,
  unsetOverflow: PropTypes.bool,
  shouldCloseOnOverlayClick: PropTypes.bool,
};

ModalContent.defaultProps = {
  size: MODAL_SIZE.medium,
  type: MODAL_TYPE.form,
};

export default ModalContent;
