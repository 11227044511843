import { Button as DefaultButton, Title as DefaultTitle } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { ReactComponent as Plus } from 'assets/icons/plus.svg';
import { DEVICE } from 'constants/breakpoints';

const Title = styled(DefaultTitle)`
  font-size: 1.625rem;
  margin-bottom: 1.625rem;

  @media ${DEVICE.tablet} {
    font-size: 2.625rem;
    margin: 0;
  }
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  padding: 0 1.25rem 1.4375rem 1.25rem;
  flex-direction: column;

  @media ${DEVICE.tablet} {
    padding: 1.0625rem 0 1.4375rem;
    flex-direction: row;
  }
`;

const TabHelperText = styled.span`
  font: ${props => props.theme.fonts.bodyXsSemi};
  color: ${props => props.theme.colors.greyMediumDark};
  padding-left: 0.3125rem;
`;

const Button = styled(DefaultButton)`
  width: 9.5rem;
  padding: 0.375rem 0.875rem;

  @media ${DEVICE.laptop} {
    margin: 0;
	}
`;

const  PlusIcon = styled(Plus)`
  path {
    fill: ${props => props.theme.colors.white};
    stroke: ${props => props.theme.colors.white};
  }
`;

export default {
  Title,
  Header,
  TabHelperText,
  Button,
  PlusIcon,
};
