import { Tabs } from '@general/intergiro-ui-kit';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { useTranslation } from 'react-i18next';

import MerchantsPlaceholder from 'pages/merchants-placeholder';
import { useMerchants } from 'hooks';

import MerchantList from './components/merchant-list';
import S from './styles';

function MerchantsPageContainer() {
  const { t } = useTranslation();
  const { merchants } = useMerchants();

  if (!merchants) {
    return null;
  }

  if (isEmpty(merchants)) {
    return (
      <MerchantsPlaceholder />
    );
  }

  return (
    <>
      <S.Header>
        <S.Title>
          {t('merchants.title')}
        </S.Title>
      </S.Header>
      <S.TabsWrapper>
        <Tabs.Container name="tabs">
          <S.TabsHead id="all-merchants" isActive>
            {t('merchants.tabs.allMerchants')}
            <S.MerchantsCount>
              {merchants.length}
            </S.MerchantsCount>
          </S.TabsHead>
        </Tabs.Container>
      </S.TabsWrapper>
      <Tabs.Panel>
        <MerchantList merchants={merchants} />
      </Tabs.Panel>
    </>
  );
}

export default MerchantsPageContainer;
