export const DataTestIds = {
  mobileApp: 'with-navbar-mobile-app',
  wrapper: 'with-navbar',
  crossIcon: 'with-navbar-cross',
  corporationWrapper: 'with-navbar-corporation',
  sidebar: 'with-navbar-sidebar',
  backButton: 'with-navbar-back',
  profileLink: 'with-navbar-profile',
  companyLink: 'with-navbar-company',
  logoutLink: 'with-navbar-logout',
  helpCenterLink: 'with-navbar-help-center',
  faqsLink: 'with-navbar-faq',
  mobileAppLandingPageLink: 'with-navbar-mobile-app-landing-page',
};

export const CORPORATION_NAME = 'corporation';
