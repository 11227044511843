import PropTypes from 'prop-types';
import React from 'react';
import { CircleFlag } from 'react-circle-flags';
import { useTranslation } from 'react-i18next';

import { CURRENCIES, CURRENCY_FLAGS } from 'constants/currencies';
import { MIN_COMPANY_NAME_LENGTH_THRESHOLD } from 'pages/order-cards/constants';
import { prepareEmbossedCompanyName } from 'utils/card-details';

import S from './styles';

function CardAccountDetails({
  account,
  companyName,
  isVirtual,
}) {
  const { t } = useTranslation();

  return (
    <S.Container>
      <S.Row>
        <S.Label>
          {t('orderCards.cardDetails.cardAccount')}
        </S.Label>
        <S.Value>
          <S.Flag>
            <CircleFlag countryCode={CURRENCY_FLAGS[account?.currency]} height="20" />
          </S.Flag>
          <S.ValueText>
            {account?.displayName}
          </S.ValueText>
        </S.Value>
      </S.Row>
      <S.Row>
        <S.Label>
          {t('orderCards.cardDetails.profile')}
        </S.Label>
        <S.Value>
          <S.ValueText>
            {t('orderCards.cardDetails.eurDefault')}
          </S.ValueText>
        </S.Value>
      </S.Row>
      {!isVirtual && (
        <S.Row>
          <S.Label>
            {t('orderCards.cardDetails.companyName')}
            {companyName.length > MIN_COMPANY_NAME_LENGTH_THRESHOLD &&
              <S.Hint width="12.5rem" text={t('orderCards.cardDetailsItem.companyName.assistiveText')} />}
          </S.Label>
          <S.Value>
            <S.ValueText>
              {prepareEmbossedCompanyName(companyName)}
            </S.ValueText>
          </S.Value>
        </S.Row>
      )}
    </S.Container>
  );
}

CardAccountDetails.propTypes = {
  account: PropTypes.shape({
    currency: PropTypes.oneOf(Object.values(CURRENCIES)),
    displayName: PropTypes.string,
  }),
  companyName: PropTypes.string,
  isVirtual: PropTypes.bool,
};

export default CardAccountDetails;
