import dayjs from 'dayjs';
import flatten from 'lodash/flatten';
import get from 'lodash/get';
import upperFirst from 'lodash/upperFirst';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import pick from 'lodash/pick';
import sumBy from 'lodash/sumBy';
import queryString from 'query-string';

import i18n from 'config/i18n';
import { filterNames } from 'pages/activity/constants';
import {
  defaultMetadata,
  pendingApprovalDefaultMeta,
  queryTypes,
  transactionStatusList,
  transactionStatusMap,
} from 'constants/transactions';
import typeList from 'constants/filters';
import { doesDateHasBothRanges } from 'utils/date-time';
import { CURRENCIES } from 'constants/currencies';
import { supportedUIPaymentRails } from 'constants/payment-processor';

import { formatAmount } from './amount';

export const getParameterFromURL = (name, location = {}) => {
  if (!location.search) {
    return null;
  }

  const search = queryString.parse(location.search, { arrayFormat: 'bracket' });

  return search[name];
};

export const removeParameterFromURL = (name, history, location = {}) => {
  if (!location.search) {
    return;
  }

  const params = new URLSearchParams(location.search);
  params.delete(name);
  history.replace({
    pathname: location.pathname,
    search: `${ params }${location.hash}`,
  });
};

export const addParameterToURL = (name, value, history, location = {}) => {
  const params = new URLSearchParams(location.search);
  params.set(name, value);
  history.replace({
    pathname: location.pathname,
    search: `${ params }${location.hash}`,
  });
};

export function getURLWithAlteredParams(
  key, value, prefix = '/', params = '',
) {
  const parameters = queryString.parse(params, { arrayFormat: 'bracket' });
  const isEmpty = !value || value.length === 0;
  const isArray = !isEmpty && value.toString().includes(',');

  if (!isEmpty) {
    parameters[key] = isArray ? value.toString().split(',') : value;
  } else {
    delete parameters[key];
  }

  const stringifiedURL = queryString.stringifyUrl({
    url: prefix,
    query: { ...parameters },
  },
  { arrayFormat: 'bracket' });

  return stringifiedURL;
}

export function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}

export function getStatusFromURLArray(status) {
  return transactionStatusList.map((element, id) => ({
    ...element,
    checked: status ? status.includes(element.value) : false,
    id: `status-${id}`,
  }));
}

export function getTypeFromURL(type) {
  return typeList.map((element, index) => ({
    ...element,
    checked: type ? type.includes(element.value) : false,
    id: `type-${index}`,
  }));
}

export function getActiveAccounts(accounts, selectedAccounts) {
  return accounts.map(({ displayName, id, currency, supportedRails }, index) => {
    const filteredRails = supportedRails.filter(rail => supportedUIPaymentRails.includes(rail));

    const supportedRailsLabel =
      currency === CURRENCIES.EUR ?
        ` · ${ upperFirst(filteredRails.join(' · '))}` :
        '';

    const mainLabel = index === 0 ?
      ` · ${upperFirst(i18n.t('accounts.mainAccountLabel'))} ` :
      '';

    return {
      label: displayName,
      secondaryLabel: `${currency}${mainLabel}${supportedRailsLabel}`,
      value: id,
      checked: selectedAccounts ? selectedAccounts.includes(id) : false,
      id: `account-${index}`,
    };
  });
}

export function getInvertedCheckboxList(id, list) {
  const updateElementIndex = list.findIndex(element => element.id === id);
  const elementToUpdate = list[updateElementIndex];
  const updateElement = { ...elementToUpdate, checked: !elementToUpdate.checked };

  return updateElementInArray(updateElementIndex, list, updateElement);
}

export function updateElementInArray(index, list, updatedElement) {
  return [ ...list.slice(0, index), updatedElement, ...list.slice(index + 1) ];
}

export function getSelectedAllCheckboxesList(list) {
  const allSelectedAccounts = list.filter(element => element.checked);
  const isAllSelected = allSelectedAccounts.length === list.length;
  const isNoneSelected = allSelectedAccounts.length === 0;
  const isAnySelected = allSelectedAccounts.length > 0;

  let checked;

  if (isAllSelected) {
    checked = false;
  } else if (isNoneSelected) {
    checked = true;
  } else {
    checked = isAnySelected;
  }

  return list.map(element => ({
    ...element,
    checked,
  }));
}

export function transformDateToURL(date, params, pathname) {
  if (!doesDateHasBothRanges(date)) {
    const urlWithFrom = getURLWithAlteredParams(
      filterNames.date.from, undefined, '', params,
    );
    const urlWithTo = getURLWithAlteredParams(
      filterNames.date.to, undefined, pathname, urlWithFrom,
    );

    return urlWithTo;
  };

  const { from, to } = mapDatesToUtcFormat(date);

  const urlWithFrom = getURLWithAlteredParams(
    filterNames.date.from, from, '', params,
  );
  const urlWithTo = getURLWithAlteredParams(
    filterNames.date.to, to, pathname, urlWithFrom,
  );

  return urlWithTo;
}

export function getActiveCheckboxValues(list) {
  const mappedList = list.map(element => (element.checked ? element.value : undefined));

  return mappedList.filter(Boolean);
}

export function getSelectedCheckboxCountDetails(array) {
  const checkedIds = [];
  let totalCount = 0;
  const checkedCount = array.reduce((accumulator, currentElement) => {
    const countInner = currentElement.content.reduce((innerAccumulator, innerElement) => {
      const isChecked = innerElement.checked;
      isChecked && checkedIds.push(innerElement.id);

      return isChecked ? innerAccumulator + 1 : innerAccumulator;
    }, 0);

    totalCount += currentElement.content.length;

    return accumulator + countInner;
  }, 0);

  return { checkedCount, checkedIds, totalCount };
}

export function getSingleArray(array) {
  const arrayWithoutKeys = array.map(([ _, subArray ]) => subArray);

  return flatten(arrayWithoutKeys);
}

export function getSelectedTransactionsById(activities, ids) {
  const singleArray = getSingleArray(activities);

  return singleArray.filter(element => ids.includes(element.id));
}

export function getTransactionsGroupedByCurrency(transactions) {
  const groupedByCurrency = groupBy(transactions, 'sourceCurrency');

  return { groupedByCurrency };
}

export function getSummarizedTransactionDetails(transactions = []) {
  const approvalRequestIds = transactions.map(transaction => transaction.approvalRequestId);

  const amount = sumBy(transactions, 'debitedAmount');
  const netSummary = sumBy(transactions, 'netTransactionAmount');
  const totalFees = sumBy(transactions, 'totalFee');

  const formattedAmount = formatAmount(amount ?? 0);
  const formattedNetAmount = formatAmount(netSummary ?? 0);
  const formattedFeesAmount = formatAmount(totalFees ?? 0);

  return {
    approvalRequestIds,
    amount: formattedAmount,
    netSummary: formattedNetAmount,
    totalFees: formattedFeesAmount,
  };
}

export function getPaymentSummary(payment = {}) {
  const formattedAmount = formatAmount(payment?.debitedAmount ?? 0);
  const formattedNetAmount = formatAmount(payment?.netTransactionAmount ?? 0);
  const formattedFeesAmount = formatAmount(payment?.totalFee ?? 0);

  return {
    amount: formattedAmount,
    netSummary: formattedNetAmount,
    totalFees: formattedFeesAmount,
  };
}

export function getCheckedIds(array) {
  return array.filter(element => element.checked).map(({ value }) => value);
}

export function normalizeListToQuery(query) {
  const { meta = defaultMetadata, filters } = query || {};
  const statuses = getCheckedIds(get(filters, filterNames.status, []));
  const types = getCheckedIds(get(filters, filterNames.type, []));
  const accounts = getCheckedIds(get(filters, filterNames.accounts, []));
  const { from, to } = mapDatesToUtcFormat(get(filters, filterNames.date.general, []));

  return {
    [queryTypes.accounts]: accounts,
    [queryTypes.types]: types,
    [queryTypes.createdFrom]: from,
    [queryTypes.createdTo]: to,
    [queryTypes.statuses]: statuses,
    ...meta,
  };
}

export function setFiltersToUnchecked(filter) {
  return filter.map(innerFilter => ({ ...innerFilter, checked: false }));
}

function filterPendingApprovals(filter) {
  return filter.label === transactionStatusMap.pendingApproval
    ? { ...filter, checked: true }
    : { ...filter, checked: false };
}

export function generatePendingApprovalFilters(filters) {
  const pendingApprovalFilters = mapValues(filters, (filter, name) => {
    if (name === filterNames.date.general) {
      return [ undefined, undefined ];
    }

    if (name === filterNames.status) {
      return filter.map(filterPendingApprovals);
    }

    return setFiltersToUnchecked(filter);
  });

  return {
    filters: pendingApprovalFilters,
    meta: pendingApprovalDefaultMeta,
  };
}

export function getCheckedCount(array) {
  if (!array) {
    return 0;
  }

  return array.filter(element => element.checked).length;
}

export function isDateSelected(date) {
  return doesDateHasBothRanges(date) === undefined ? 0 : 1;
}

export function getAllSelectedCount(filters) {
  const arrayFilters = pick(filters, [ filterNames.status, filterNames.type, filterNames.accounts ]);
  const singleArray = getSingleArray(Object.entries(arrayFilters));
  const isSelectedDate = isDateSelected(filters[filterNames.date.general]);

  return getCheckedCount(singleArray) + isSelectedDate;
}

export function getTimezone() {
  return dayjs.tz.guess();
}

export const arrayNonEmptyPropType = (props, propName) => {
  const myProp = props[propName];
  if (!Array.isArray(myProp)) return new Error(`${propName} must be an array.`);
  if (myProp.length < 1) return new Error(`${propName} must have at least one element.`);

  return null;
};

// Private Helpers

function mapDatesToUtcFormat(date) {
  const { from, to } = date;
  const dateFromUTC = from ? dayjs(from).startOf('day').toJSON() : undefined;
  const dateToEndOfDay = to ? dayjs(to).endOf('day').toJSON() : undefined;

  return {
    from: dateFromUTC,
    to: dateToEndOfDay,
  };
}
