export const transactionViewFilterMap = {
  cardIncoming: 'card-incoming',
  cardOutgoing: 'card-outgoing',
  bankIncoming: 'bank-incoming',
  bankOutgoing: 'bank-outgoing',
  feePayment: 'fee-payment',
  invoicePayment: 'invoice-payment', // TODO TEST
};

export const transactionReferences = {
  movementBetweenAccounts: 'movement between accounts',
};

export const createdByMap = {
  person: 'person',
};

export const transactionType = {
  bankIncoming: 'bank-incoming',
  bankOutgoing: 'bank-outgoing',
  cardIncoming: 'card-incoming',
  cardOutgoing: 'card-outgoing',
  settlementIncoming: 'settlement-incoming',
  settlementOutgoing: 'settlement-outgoing',
  invoicePayment: 'invoice-payment',
  fx: 'fx',
  cardFee: 'card-fee',
  apiPayment: 'api-payment',
};

export const transactionTypeLabel = {
  [transactionType.bankIncoming]: 'Incoming transfer',
  [transactionType.settlementIncoming]: 'Incoming transfer',
  [transactionType.bankOutgoing]: 'Outgoing transfer',
  [transactionType.settlementOutgoing]: 'Outgoing transfer',
  [transactionType.cardIncoming]: 'Incoming card payment',
  [transactionType.cardOutgoing]: 'Outgoing card payment',
  [transactionType.invoicePayment]: 'Fee payment',
  [transactionType.cardFee]: 'Fee payment',
  [transactionType.fx]: 'FX',
};

export const transactionStatus = {
  pending: 'pending',
  completed: 'completed',
  failed: 'failed',
  rejected: 'rejected',
  pendingApproval: 'pending_approval',
};

export const transactionStatusLabels = {
  [transactionStatus.pending]: 'Pending',
  [transactionStatus.completed]: 'Successful',
  [transactionStatus.failed]: 'Failed',
  [transactionStatus.rejected]: 'Failed',
  [transactionStatus.pendingApproval]: 'Pending approval',
};

export const outgoingTransactionTypes = [
  transactionType.bankOutgoing,
  transactionType.cardOutgoing,
  transactionType.settlementOutgoing,
  transactionType.fx,
  transactionType.cardFee,
  transactionType.invoicePayment,
];

export const incomingTransactionTypes = [
  transactionType.bankIncoming,
  transactionType.cardIncoming,
  transactionType.settlementIncoming,
];

export const cardTransactionTypes = [
  transactionType.cardIncoming,
  transactionType.cardOutgoing,
];

const transactionErrorMessagesMap = {
  system: 'System error',
  refunded: 'Refunded',
  validation: 'Validation error',
  rejectedByApprover: 'Rejected by approver',
};

export const metaTypes = {
  endingBefore: 'meta[ending_before]',
  general: 'meta',
  limit: 'meta[limit]',
  sortBy: 'meta[sort_by]',
  sortType: 'meta[sort_type]',
  startingAfter: 'meta[starting_after]',
};

export const queryTypes = {
  createdFrom: 'createdFrom',
  createdTo: 'createdTo',
  endingBefore: 'endingBefore',
  limit: 'limit',
  sortBy: 'sortBy',
  sortType: 'sortType',
  startingAfter: 'startingAfter',
  statuses: 'statuses',
  types: 'types',
  accounts: 'wallets',
  bulkPaymentId: 'bulkPaymentId',
};

export const defaultMetadata = {
  [queryTypes.limit]: '10',
};

export const pendingApprovalDefaultMeta = {
  [queryTypes.limit]: '100',
};

export const transactionStatusMap = {
  pending: 'Pending',
  successful: 'Successful',
  failed: 'Failed',
  pendingApproval: 'Pending approval',
};

export const transactionStatusList = [
  {
    label: transactionStatusMap.successful,
    value: 'completed',
  },
  {
    label: transactionStatusMap.pending,
    value: 'pending',
  },
  {
    label: transactionStatusMap.failed,
    value: 'failed',
  },
  {
    label: transactionStatusMap.pendingApproval,
    value: 'pending_approval',
  },
];

export const transactionStatusCodes = {
  status: {
    0: transactionStatusMap.successful,
    1: transactionStatusMap.pending,
    2: transactionStatusMap.pending,
    3: transactionStatusMap.pending,
    4: transactionStatusMap.pending,
    5: transactionStatusMap.pending,
    6: transactionStatusMap.pending,
    7: transactionStatusMap.pending,
    8: transactionStatusMap.successful,
    10: transactionStatusMap.failed,
    20: transactionStatusMap.failed,
    21: transactionStatusMap.failed,
    22: transactionStatusMap.failed,
    30: transactionStatusMap.failed,
    40: transactionStatusMap.pending,
    41: transactionStatusMap.pending,
    42: transactionStatusMap.pending,
    43: transactionStatusMap.pending,
    45: transactionStatusMap.pendingApproval,
  },
  code: {
    // get codes from server payment-processor/adapters/codes.js
    APPROVED: 0,
    AUTHORIZED: 1,
    PAYMENT_COMPLETE: 8,
    DECLINED: 10,
    CHARGEBACKED: 20,
    REFUNDED: 21,
    HOLD: 22,
    REJECTED: 30,
    PENDING: 40,
    PENDING_AUTH: 41,
    UNDER_REVIEW: 42,
    INITIALIZED: 43,
    PENDING_APPROVAL: 45,
  },
  groupedCode: {
    successful: 'completed',
    pending: 'pending',
    failed: 'failed',
    pendingApproval: 'pending_approval',
  },
  errorMessage: {
    10: transactionErrorMessagesMap.system,
    20: transactionErrorMessagesMap.system,
    21: transactionErrorMessagesMap.refunded,
    22: transactionErrorMessagesMap.system,
    30: transactionErrorMessagesMap.validation,
  },
};

export const maxTransactionLimit = 100;

export const paymentsCountTypes = {
  numberOfPendingApprovalPayments: 'numberOfPendingApprovalPayments',
  numberOfApiPayments: 'numberOfApiPayments',
  numberOfAllPayments: 'numberOfAllPayments',
};
