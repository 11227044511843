import { Button as DefaultButton } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';

const Container = styled.div`
  @media ${DEVICE.laptop} {
    max-width: 32rem;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  @media ${DEVICE.laptop} {
    flex-direction: row;
    margin-bottom: 2rem;
  }
`;

const InputContainer = styled.div`
  width: 100%;
  margin-bottom: 1.25rem;
  margin-right: 1.25rem;

  @media ${DEVICE.laptop} {
		margin-bottom: 0;
  }
`;

const Button = styled(DefaultButton)`
  width: 100%;
  margin-top: 2rem;

  @media ${DEVICE.laptop} {
    width: inherit;
  }
`;

export default {
  Container,
  Row,
  InputContainer,
  Button,
};
