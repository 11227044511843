import { Button as DefaultButton } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';

const Message = styled.p`
  margin: 0;
  font: ${props => props.theme.fonts.bodyL};
  color: ${props => props.theme.colors.greyMedium};
`;

const Button = styled(DefaultButton)`
  min-width: fit-content;
  padding: 0.75rem 1.5rem;
  margin-top: ${props => props.category === 'secondary' ? '1rem' : 0};
  width: 100%;

  @media ${DEVICE.tablet} {
    margin-top: 0;
    margin-right: 0.625rem;
    width: auto;
  }
`;

export default {
  Message,
  Button,
};
