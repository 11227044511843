import { adapters } from 'constants/payment-processor';

export const AMOUNT_IS_TOO_SMALL_ERROR_CODE = 'amount_is_too_small';
export const CONVERSION_BELOW_LIMIT_ERROR_CODE = 'conversion_below_limit';

export const AMOUNT_IS_TOO_SMALL_ERROR_MESSAGE =
    'The smallest payment amount must be minimum 1 GBP.';
export const SMALLEST_MOVE_FX_AMOUNT = 100;
export const MIN_AMOUNT = 0.01;
export const BUY_FX_SIDE = 'buy';
export const SELL_FX_SIDE = 'sell';
export const POLL_TIMEOUT = 30000;
export const MIN_AMOUNT_TO_GET_RATE = 100;
export const DEBOUNCE_TIME = 600;

export const fromAccountFieldName = 'fromAccount';
export const toAccountFieldName = 'toAccount';
export const moveAmountFieldName = 'moveAmount';
export const receiveAmountFieldName = 'receiveAmount';
export const exchangeRateFieldName = 'exchangeRate';

export const SUBMISSION_ERROR_AMOUNT = 'amount';

export const MOVE_FUNDS_FORM = 'moveFundsForm';
export const MOVE_FUNDS_CONFIRMATION = 'confirmation';
export const MOVE_FUNDS_FINAL = 'final';

export const ACTION_CLEAR = 'clear';

export const excludedPaymentProcessorAdapters = [ adapters.onpex, adapters.saxo ];
