import isNil from 'lodash/isNil';

import { PHYSICAL_CARD_TYPE } from 'constants/card';

export const getToken = () => localStorage.getItem('token');
export const getRefreshToken = () => localStorage.getItem('refreshToken');
export const setToken = (token, refreshToken) => {
  localStorage.setItem('token', token);
  localStorage.setItem('refreshToken', refreshToken);
};

export const cleanStorage = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('refreshToken');
};

// Local settings

export const getAccountsListView = () => localStorage.getItem('accountsListView');
export const setAccountsListView = data => localStorage.setItem('accountsListView', data);

export const getCardsView = () => localStorage.getItem('cardsView');
export const setCardsView = data => localStorage.setItem('cardsView', data);

export const isCardsAccordionOpen = type => {
  const selectedType = type === PHYSICAL_CARD_TYPE ? 'physicalCardsAccordionIsOpen' : 'virtualCardsAccordionIsOpen';
  const accordionState = localStorage.getItem(selectedType);

  return isNil(accordionState) ? true : JSON.parse(accordionState);
};

export const toggleCardsAccordionOpenState = type => {
  const selectedType = type === PHYSICAL_CARD_TYPE ? 'physicalCardsAccordionIsOpen' : 'virtualCardsAccordionIsOpen';
  const isOpen = isCardsAccordionOpen(type);
  localStorage.setItem(selectedType, String(!isOpen));
};
