import PropTypes from 'prop-types';
import React from 'react';

import { DownloadStatementsButton, HiddenOnMobile } from 'components';

import S from './styles';

function FilterBarMobile({
  cancelFilters,
  children,
  closeFilterBar,
  isOpen,
  openFilterBar,
  selectedCount,
  applyFilters,
  setDownloadStatementsModalIsOpen,
  showDownloadStatements,
  wrapperRef,
  hideLabel,
}) {
  const onApplyFiltersClick = () => {
    applyFilters();
    closeFilterBar();
  };

  return (
    <S.FilterBarWrapper data-testid="filter-bar-mobile">
      <S.FilterIconWrapper showDownloadStatements={showDownloadStatements}>
        {!hideLabel && (
          <S.FilterBarMobile onClick={openFilterBar}>
            <S.FilterLabelWrapper>
              <S.FilterBarMobileIcon />
              <S.FilterLabelWrapper data-testid="filter-count">
                <HiddenOnMobile>
                  <S.FilterLabel>Filter </S.FilterLabel>
                </HiddenOnMobile>
                {selectedCount > 0 && (
                  <S.FiltersBadge>
                    {selectedCount}
                  </S.FiltersBadge>
                )}
              </S.FilterLabelWrapper>
            </S.FilterLabelWrapper>
          </S.FilterBarMobile>
        )}
        <HiddenOnMobile>
          {showDownloadStatements && (
            <DownloadStatementsButton setIsOpen={setDownloadStatementsModalIsOpen} />
          )}
        </HiddenOnMobile>
      </S.FilterIconWrapper>
      <S.Sidebar
        handleClose={closeFilterBar}
        isCloseOutsideEnabled={false}
        isControlPanelVisible={false}
        isOpen={isOpen}
      >
        <S.FilterBarHeadingWrapper ref={wrapperRef}>
          <S.FilterBarHeading>Filters</S.FilterBarHeading>
          <S.FilterBarCrossButton onClick={closeFilterBar}>
            <S.CrossIcon />
          </S.FilterBarCrossButton>
        </S.FilterBarHeadingWrapper>

        {children}

        <S.ControlButtonsWrapper>
          <S.Button label="Apply filters" category="primary" width="100%" onClick={onApplyFiltersClick} />
          <S.Button label="Clear filters" category="secondary" width="100%" onClick={cancelFilters} />
        </S.ControlButtonsWrapper>
      </S.Sidebar>
    </S.FilterBarWrapper>
  );
}

FilterBarMobile.propTypes = {
  cancelFilters: PropTypes.func,
  children: PropTypes.node,
  closeFilterBar: PropTypes.func,
  isOpen: PropTypes.bool,
  openFilterBar: PropTypes.func,
  selectedCount: PropTypes.number,
  applyFilters: PropTypes.func,
  setDownloadStatementsModalIsOpen: PropTypes.func,
  showDownloadStatements: PropTypes.bool,
  hideLabel: PropTypes.bool,
  wrapperRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

export default FilterBarMobile;
