import dayjs from 'dayjs';
import React, { memo, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import {
  ResolveApiPayment,
  TransactionAmount,
  TransactionApproveReject,
  TransactionIcon,
  TransactionStatus,
} from 'components';
import { defaultDateFormat, defaultFullTimeFormat } from 'constants/date-time';
import { navigation } from 'constants/navigation';
import { transactionType } from 'constants/transactions';
import { PaymentContext } from 'contexts/payment';
import { PaymentCountContext } from 'contexts/payment-count';
import { TransactionContext } from 'contexts/transaction';
import { useApiPayments, useView } from 'hooks';
import { mappedTransactionShape } from 'local-prop-types';
import {
  isFeeTransaction as checkForFeeTransaction,
  isIncomingTransaction as checkForIsIncomingTransaction,
} from 'utils/transactions';

import AccountInfoBlock from './components/account-info-block';
import DataBlock from './components/data-block';
import DescriptionBlock from './components/description-block';
import FeesBlock from './components/fees-block';
import TransferConfirmation from './components/transfer-confirmation';
import S from './styles';

function TransactionSidebar({
  id,
  type,
  fromAccount,
  toAccount,
  merchant,
  cardholder,
  totalFee,
  externalFee,
  debitedAmount,
  bulkPaymentId,
  netTransactionAmount,
  numberOfTransactions,
  sourceAmount,
  targetAmount,
  author,
  createdAt,
  createdBy,
  exchangeRate,
  reference,
  purposeCode,
  sourceCurrency,
  status,
  statusReasons,
  targetCurrency,
  isMultiCurrencyTransaction,
  isPendingApproval,
  isApiPayment,
  approvalRequestId,
  approvedBy,
  rejectedBy,
}) {
  const { setActiveTransactionById } = useContext(TransactionContext);
  const { setActivePaymentById } = useContext(PaymentContext);
  const { fetchApiPayments } = useApiPayments();
  const { updateCount } = useContext(PaymentCountContext);
  const [ readyToBeRendered, setReadyToBeRendered ] = useState(false);
  const [ isFeeTransaction, setIsFeeTransaction ] = useState(false);
  const [ isIncomingTransaction, setIsIncomingTransaction ] = useState(false);
  const [ parsedDate, setParsedDate ] = useState();
  const { isDesktop } = useView();
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    setIsFeeTransaction(checkForFeeTransaction(type));
    setIsIncomingTransaction(checkForIsIncomingTransaction(type));
    setParsedDate(dayjs(createdAt).format(`${defaultDateFormat} · ${defaultFullTimeFormat}`));

    setReadyToBeRendered(true);
  }, [ type, createdAt ]);

  const onTransactionApprove = () => {
    setActiveTransactionById(null);
    updateCount();
  };

  const onPaymentApprove = () => {
    updateCount();
    fetchApiPayments();
    setActivePaymentById(null);
  };

  const getIconType = () => location.pathname.includes(navigation.apiPayments) ? transactionType.apiPayment : type;

  const getNumberOfTransactions = () => numberOfTransactions ?
      `${numberOfTransactions} ${numberOfTransactions === 1 ? t('payments.apiPayments.label.single') : t('payments.apiPayments.label.multiple')}` :
    null;

  if (!readyToBeRendered) return null;

  return (
    <S.SidebarBody data-testid="transaction-sidebar-wrapper">

      <S.SidebarHeader>
        <TransactionIcon type={getIconType()} status={status} size={3} />
      </S.SidebarHeader>

      <TransactionAmount
        debitedAmount={debitedAmount}
        netTransactionAmount={netTransactionAmount}
        applyCustomFormatting
        sourceCurrency={sourceCurrency}
        targetCurrency={targetCurrency}
        isMultiCurrencyTransaction={isMultiCurrencyTransaction}
        type={type}
      />

      <S.Grid alignTop>
        <S.DateLabel isSmaller>
          {parsedDate}
        </S.DateLabel>
        <S.TransactionStatusWrapper>
          <TransactionStatus status={status} type={type} statusReasons={statusReasons} displayStatus />
        </S.TransactionStatusWrapper>
      </S.Grid>

      <S.Separator />

      <AccountInfoBlock label="From" currency={sourceCurrency} name={fromAccount.name} />
      <AccountInfoBlock label="To" currency={targetCurrency} name={isApiPayment ? getNumberOfTransactions() : toAccount.name} />

      {isApiPayment && (
        <S.Link onClick={() => setActivePaymentById(null)} to={`${navigation.apiPayments  }/${  bulkPaymentId}`}>
          See all
        </S.Link>
        )}

      <S.Separator />

      <DescriptionBlock
        type={type}
        reference={reference}
        purposeCode={purposeCode}
        category={merchant?.category}
        isFeeTransaction={isFeeTransaction}
        account={isIncomingTransaction ? fromAccount : toAccount}
      />

      <TransferConfirmation
        id={id}
        type={type}
        status={status}
      />

      <FeesBlock
        type={type}
        totalFee={totalFee}
        sourceCurrency={sourceCurrency}
        targetCurrency={targetCurrency}
        netTransactionAmount={netTransactionAmount}
        showNetAmount={isPendingApproval || isApiPayment}
        sourceAmount={sourceAmount}
        targetAmount={targetAmount}
        externalFee={externalFee}
        exchangeRate={exchangeRate}
        isFeeTransaction={isFeeTransaction}
        isIncomingTransaction={isIncomingTransaction}
        isMultiCurrencyTransaction={isMultiCurrencyTransaction}
        isApiPayment={isApiPayment}
      />

      <DataBlock
        id={id}
        type={type}
        author={author}
        merchant={merchant}
        fromAccount={fromAccount}
        toAccount={toAccount}
        reference={reference}
        sourceCurrency={sourceCurrency}
        targetCurrency={targetCurrency}
        cardholder={cardholder}
        isIncomingTransaction={isIncomingTransaction}
        isApiPayment={isApiPayment}
        approvedBy={approvedBy}
        rejectedBy={rejectedBy}
      />
      {isPendingApproval && !isDesktop && (
        <S.ApproveRejectButtonsWrapper>
          <TransactionApproveReject
            approvalRequestId={approvalRequestId}
            createdAt={createdAt}
            createdBy={createdBy}
            debitedAmount={debitedAmount}
            targetCurrency={targetCurrency}
            toAccount={toAccount}
            approveButton={<S.TransactionApproveButton label="Approve"/>}
            rejectButton={<S.TransactionRejectButton label="Reject" />}
            onSuccess={onTransactionApprove}
          />
        </S.ApproveRejectButtonsWrapper>
        )}
      {isApiPayment && (
        <S.ApproveRejectButtonsWrapper>
          <ResolveApiPayment
            netAmount={netTransactionAmount}
            currency={sourceCurrency}
            bulkPaymentId={bulkPaymentId}
            approveButton={<S.TransactionApproveButton label="Approve"/>}
            rejectButton={<S.TransactionRejectButton label="Reject" />}
            onSuccess={onPaymentApprove}
          />
        </S.ApproveRejectButtonsWrapper>
        )}
    </S.SidebarBody>
  );
}

TransactionSidebar.propTypes = mappedTransactionShape;

export default memo(TransactionSidebar);
