import { useEffect } from 'react';

import config from 'config';

const useSurvicate = () => {
  const { survicateId } = config;

  useEffect(() => {
    if (!survicateId) return null;

    const s = document.createElement('script');
    s.src = `https://survey.survicate.com/workspaces/${survicateId}/web_surveys.js`;
    s.async = true;

    document.body.appendChild(s);

    return () => {
      document.body.removeChild(s);
    };
  }, [survicateId]);
};

export default useSurvicate;
