import PropTypes from 'prop-types';
import * as React from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';

import { ReactComponent as QuestionMarkIcon } from 'assets/icons/question-mark.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';

import { StyledTooltip, StyledTrigger, StyledWrapper } from './styles';

function Hint({ text, children, isInfo = false, width, placement = 'top', ...props }) {
  const isTablet = window.innerWidth <= 768;
  const {
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({
    placement,
    trigger: isTablet ? 'click' : 'hover',
    delayHide: 100,
    delayShow: 500,
  });
  const Icon = (
    <StyledTrigger >
      {isInfo ? <InfoIcon /> : <QuestionMarkIcon />}
    </StyledTrigger>
  );

  return (
    <>
      <StyledWrapper {...props} ref={setTriggerRef}>
        {children || Icon}
      </ StyledWrapper>
      {visible && (
        <StyledTooltip width={width} ref={setTooltipRef} {...getTooltipProps({ className: 'tooltip-container' })}>
          {text}
        </StyledTooltip>
      )}
    </>
  );
}

Hint.propTypes = {
  text: PropTypes.oneOfType([ PropTypes.string, PropTypes.node ]).isRequired,
  isInfo: PropTypes.bool,
  children: PropTypes.node,
  width: PropTypes.string,
  placement: PropTypes.string,
};

export default Hint;
