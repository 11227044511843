import PropTypes from 'prop-types';
import React, {
  cloneElement,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import S from './styles';

const DropdownFilterControl = forwardRef((props, parentRef) => {
  const { children, label } = props;

  const [ visible, setVisibility ] = useState(false);

  const ref = useRef(null);

  useEffect(() => {
    document.addEventListener('mousedown', handleDocumentClick);

    return () => {
      document.removeEventListener('mousedown', handleDocumentClick);
    };
  }, []);

  function handleDocumentClick(event) {
    if (!ref.current.contains(event.target)) {
      setVisibility(false);
    }
  }

  const handleDropdownClick = useCallback(() => {
    setVisibility(!visible);
  }, [visible]);

  useImperativeHandle(parentRef, () => ({
    closeDropdown() {
      setVisibility(false);
    },
  }));

  return (
    <S.Bar ref={ref}>
      <S.FilterBarButton onClick={handleDropdownClick}>
        {cloneElement(label, { visible })}
      </S.FilterBarButton>
      <S.Wrapper>
        <S.Container visible={visible}>
          {children}
        </S.Container>
      </S.Wrapper>
    </S.Bar>
  );
});

DropdownFilterControl.displayName = 'DropdownFilterControl';

DropdownFilterControl.propTypes = {
  children: PropTypes.node,
  label: PropTypes.oneOfType([ PropTypes.string, PropTypes.node, PropTypes.shape ]).isRequired,
};

export default DropdownFilterControl;
