import styled from 'styled-components';

import { hex2rgba } from 'helpers/styles';
import { DEVICE } from 'constants/breakpoints';

const Row = styled.div`
  height: 4.5rem;
  max-width: 100vw;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.875rem 0.75rem;
  cursor: pointer;
  border-bottom: 0.0625rem solid ${props => props.theme.colors.greyBright};
  
  &:hover {
    background: ${props => hex2rgba(props.theme.colors.greyBright, 0.3)};
  }
`;

const Column = styled.div`
  width: 100%;
  display: ${props => props.isHiddenOnMobile ? 'none' : 'inline-block'};
  margin-right: ${props => props.mainColumn && 'auto'};

  @media ${DEVICE.tablet} {
    width: auto;
    display: inline-block;
    margin-left: ${props => props.actionsColumn && '1.875rem'};
  }
`;

const BeneficiaryName = styled.p`
  display: inline;
  margin: 0;
  color: ${props => props.theme.colors.black};
`;

const Action = styled.div`
  font: ${props => props.theme.fonts.bodyS};
  color: ${props => props.red ? props.theme.colors.red : props.theme.colors.black};
  padding: 0.625rem 1rem;
`;

export default {
  Row,
  Column,
  BeneficiaryName,
  Action,
};
