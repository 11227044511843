import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';
import { hex2rgba } from 'helpers/styles';
import { Hint as HintComponent } from 'components';

const Container = styled.div`
  width: auto;
  padding: 1rem;
  border: 0.0625rem solid ${props => hex2rgba(props.theme.colors.black, 0.1)};
  border-radius: 0.5rem;
  margin-bottom: 2rem;

  @media ${DEVICE.tablet} {
    width: 30rem;
    margin-bottom: 2.5rem;
  }
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  
  &:not(:last-child) {
    margin-bottom: 1.5625rem;
  }
`;

const Label = styled.div`
  display: inline;
  align-items: center;
  width: 40%;
  font: ${props => props.isTotal ? props.theme.fonts.bodySBold : props.theme.fonts.bodySSemi};
  color: ${props => props.isTotal ? props.theme.colors.black : props.theme.colors.greyMedium};

  @media ${DEVICE.laptop} {
    display: flex;
    width: 50%;
    font: ${props => props.isTotal ? props.theme.fonts.bodyMBold : props.theme.fonts.bodyMSemi};
  }
`;

const Hint = styled(HintComponent)`
  margin-left: 0.5rem;
`;

const Flag = styled.div`
  display: flex;
  margin-right: 0.5rem;
`;

const Value = styled.div`
  display: flex;
  align-items: center;
  font: ${props => props.isTotal ? props.theme.fonts.bodyMBold : props.theme.fonts.bodyMSemi};
  color: ${props => props.theme.colors.black};
  white-space: nowrap;
  font-size: 1rem;
  width: 50%;
  justify-content: flex-end;
`;

const ValueText = styled.span`
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default {
  Container,
  Row,
  Label,
  Hint,
  Flag,
  Value,
  ValueText,
};
