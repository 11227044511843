import { Button, ReadOnlyField } from '@general/intergiro-ui-kit';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { dataTestIds } from 'pages/api-create/constants';
import { navigation } from 'constants/navigation';
import { AlertBlock } from 'components';

import S from './styles';

function ApiCreationResult({ apiKey }) {
  const { t } = useTranslation();
  const history = useHistory();

  const handleClick = () => {
    history.push(navigation.api);
  };

  const NotificationText = useCallback(() => (
    <>
      <S.NotificationSection>
        {t('createApi.result.notification.copy')}
      </S.NotificationSection>
      <S.NotificationSection>
        {t('createApi.result.notification.onlyTime')}
      </S.NotificationSection>
    </>
  ), []);

  return (
    <div data-testid={dataTestIds.apiCreationResult}>
      <S.Title>
        {t('createApi.result.title')}
      </S.Title>
      <S.InputWrapper>
        <ReadOnlyField label={t('createApi.result.keyToken')} content={apiKey} withCopying />
      </S.InputWrapper>
      <S.AlertBlockWrapper>
        <AlertBlock icon={S.InfoIcon} text={<NotificationText />} />
      </S.AlertBlockWrapper>
      <Button data-testid={dataTestIds.apiCreationResultButton} label={t('createApi.result.button')} onClick={handleClick} />
    </div>
  );
}

ApiCreationResult.propTypes = {
  apiKey: PropTypes.string.isRequired,
};

export default ApiCreationResult;
