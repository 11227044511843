import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  margin-top: 1.6rem;
`;

export default {
  Container,
};
