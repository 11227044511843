import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';
import { AlertBlock, ModalContent } from 'components';
import { cardBlockingReasons, PHYSICAL_CARD_TYPE } from 'constants/card';

import S from './styles';

function BlockCardModal({ type, isOpen, setIsOpen, handleBlock }) {
  const { t } = useTranslation();
  const [ reason, setReason ] = useState();
  const [ isValidationVisible, setIsValidationVisible ] = useState(false);
  const [ reasons, setReasons ] = useState({
    [cardBlockingReasons.fraud]: false,
    [cardBlockingReasons.lost]: false,
    [cardBlockingReasons.stolen]: false,
    [cardBlockingReasons.terminate]: false,
  });

  const handleReasons = e => {
    const current = { ...reasons };
    Object.keys(current).forEach(value => {
      current[value] = e.target.id === value;
    });
    setReasons(current);
    setReason(e.target.id);
    setIsValidationVisible(false);
  };

  const onSubmit = () => {
    if (!reason) {
      setIsValidationVisible(true);

      return;
    }

    setIsOpen(false);
    handleBlock(reason);
  };

  const CTAButtons = (
    <>
      <S.Button label={t('card.blockModal.blockBtn')} category="primary" type="submit" onClick={onSubmit} />
      <S.Button label={t('card.blockModal.cancelBtn')} category="secondary" onClick={() => setIsOpen(false)} />
    </>
  );

  return (
    <ModalContent title={t('card.blockModal.title')} hasCloseIcon isOpen={isOpen} CTAButtons={CTAButtons} setIsOpen={setIsOpen}>
      <S.HelperText>Why do you want to block this card?</S.HelperText>

      {type === PHYSICAL_CARD_TYPE && (
        <>
          <S.Radio
            id={cardBlockingReasons.lost}
            name="reasons"
            label={t('card.blockModal.lost')}
            checked={reasons.lost}
            onChange={handleReasons}
          />
          <S.Radio
            id={cardBlockingReasons.stolen}
            name="reasons"
            label={t('card.blockModal.stolen')}
            checked={reasons.stolen}
            onChange={handleReasons}
          />
        </>
      )}
      <S.Radio
        id={cardBlockingReasons.fraud}
        name="reasons"
        label={t('card.blockModal.fraud')}
        checked={reasons.fraud}
        onChange={handleReasons}
      />
      <S.Radio
        id={cardBlockingReasons.terminate}
        name="reasons"
        label={t('card.blockModal.terminate')}
        checked={reasons.terminate}
        onChange={handleReasons}
      />

      {isValidationVisible && (
        <S.ValidationMessageBlock>
          {t('card.blockModal.selectReason')}
        </S.ValidationMessageBlock>
      )}
      <AlertBlock icon={WarningIcon} text={t('card.blockModal.alertText')} />
    </ModalContent>
  );
}

BlockCardModal.propTypes = {
  type: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  handleBlock: PropTypes.func.isRequired,
};

export default BlockCardModal;
