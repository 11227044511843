import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { checkIfExpired, checkIfExpireSoon } from 'utils/date-time';
import { navigateToNotFoundPage, showGeneralToastError } from 'utils/errors';
import { DAYS_BEFORE_API_KEY_EXPIRATION } from 'constants/api';
import { listKeys, removeKey, renewKey } from 'api/api-keys';
import { NOT_FOUND } from 'constants/status-code';
import { resourceType } from 'constants/resource';

import { UserContext } from './user';
import { GlobalLoaderContext } from './global-loader';

export const ApiKeysContext = createContext();

function ApiKeysProvider({ children }) {
  const history = useHistory();
  const { t } = useTranslation();
  const { startLoading, endLoading } = useContext(GlobalLoaderContext);
  const { permissions: { canViewApiKeys } } = useContext(UserContext);
  const [ apiKeys, setApiKeys ] = useState([]);

  const expiredAmount = useMemo(() => {
    const currentDate = dayjs().utc();

    if (!apiKeys.length) return 0;

    return apiKeys.filter(apiKey => checkIfExpired(currentDate, apiKey.expiresAt)
        || checkIfExpireSoon(currentDate, apiKey.expiresAt, DAYS_BEFORE_API_KEY_EXPIRATION)).length;
  }, [apiKeys]);

  const getAPIKeys = async () => {
    startLoading();

    try {
      const data = await listKeys();

      setApiKeys(data);
    } catch (error) {
      showGeneralToastError(error);
    } finally {
      endLoading();
    }
  };

  useEffect(() => {
    if (canViewApiKeys) getAPIKeys();
  }, []);

  const renewApiKey = async id => {
    try {
      await renewKey(id);

      await getAPIKeys();

      toast.success(t('api.table.renewToast'));
    } catch (error) {
      error.status === NOT_FOUND
        ? navigateToNotFoundPage(history, resourceType.apiKey)
        : showGeneralToastError(error);
    }
  };

  const removeApiKey = async id => {
    try {
      await removeKey(id);

      await getAPIKeys();

      toast.success(t('api.table.removeToast'));
    } catch (error) {
      error.status === NOT_FOUND
        ? navigateToNotFoundPage(history, resourceType.apiKey)
        : showGeneralToastError(error);
    }
  };

  const initialValues = useMemo(() =>
    ({ apiKeys, expiredAmount, renewApiKey, removeApiKey }),
  [ apiKeys, expiredAmount ]);

  return (
    <ApiKeysContext.Provider value={initialValues}>
      {children}
    </ApiKeysContext.Provider>
  );
}

ApiKeysProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ApiKeysProvider;
