import { Button as DefaultButton, Sidebar as IntergiroSidebar } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { ReactComponent as Cross } from 'assets/icons/cross-2.svg';
import { ReactComponent as Filters } from 'assets/icons/filters.svg';
import { DEVICE } from 'constants/breakpoints';

const FilterBarWrapper = styled.div`
  @media ${DEVICE.desktop} {
    display: none;
  }
`;

const FilterBarMobile = styled.button`
  align-items: baseline;
  background: transparent;
  border: none;
  display: flex;
  justify-content: center;
  margin-top: -0.75rem;
  padding: 0.75rem;

  @media ${DEVICE.tablet} {
    margin-top: 0;
    padding: 0;
  }

  @media ${DEVICE.desktop} {
    display: none;
  }
`;

const FilterBarMobileIcon = styled(Filters)`
  fill: ${props => props.theme.colors.blue};
`;

const Sidebar = styled(IntergiroSidebar)`
  overflow: auto;
  z-index: 2;
  width: 100%;
  ${props => !props.isOpen && 'box-shadow: none'};

  @media ${DEVICE.tablet} {
    width: 25rem;
  }
`;

const FilterBarHeadingWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

const FilterBarHeading = styled.h3`
  font: ${props => props.theme.fonts.h3Bold};
  margin: 0.625rem 0;
`;

const FilterBarCrossButton = styled.button`
  align-items: center;
  background: ${props => props.theme.colors.white};
  border: none;
  cursor: pointer;
  display: flex;
  height: 2rem;
  width: 2rem;
  justify-content: center;
  padding: 0;
  margin: 0;
`;

const CrossIcon = styled(Cross)`
  circle {
    fill: none;
  }

  path {
    fill: ${props => props.theme.colors.black};
    stroke: ${props => props.theme.colors.black};
  }
`;

const ControlButtonsWrapper = styled.div`
  margin: 2rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Button = styled(DefaultButton)`
  margin-top: 1rem;
`;

const FilterLabel = styled.span`
  color: ${props => props.theme.colors.blue};
  font-size: 1.125rem;
  font-weight: 500;
  margin: 0 0.3125rem 0 0.625rem;
`;

const FilterIconWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;

  @media ${DEVICE.tablet} {
    grid-gap: ${props => props.showDownloadStatements ? '1.5rem' : 0};
    margin-top: -0.75rem;
  }
`;

const FilterLabelWrapper = styled.div`
  align-items: baseline;
  display: flex;
`;

const FiltersBadge = styled.span`
  align-items: center;
  background: ${props => props.theme.colors.greyBright};
  border-radius: 0.1875rem;
  color: ${props => props.theme.colors.blue};
  display: flex;
  font-weight: 500;
  flex-direction: column;
  height: 1.25rem;
  justify-content: center;
  margin-left: 0.3125rem;
  padding: 0.0625rem 0.375rem 0.125rem;
  width: 1.25rem;
`;

export default {
  Button,
  ControlButtonsWrapper,
  CrossIcon,
  FilterBarCrossButton,
  FilterBarHeading,
  FilterBarHeadingWrapper,
  FilterBarMobile,
  FilterBarMobileIcon,
  FilterBarWrapper,
  FilterIconWrapper,
  FilterLabel,
  FilterLabelWrapper,
  FiltersBadge,
  Sidebar,
};
