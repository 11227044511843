import {
  Button as IntergiroButton,
  DropdownCurrencyOption as IntergiroCurrencyOption,
  Paragraph as ParagraphComponent,
  Tabs,
  Title as TitleComponent,
} from '@general/intergiro-ui-kit';
import { CircleFlag } from 'react-circle-flags';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { DEVICE } from 'constants/breakpoints';

const Amount = styled.span`
  font: ${props => props.theme.fonts.h3Bold};
  color: ${props => props.isDanger && props.theme.colors.red};

  @media ${DEVICE.tablet} {
    font: ${props => props.theme.fonts.h2Bold};
  }

  @media ${DEVICE.desktop} {
    font: ${props => props.theme.fonts.h1Bold};
  }
`;

const BackButton = styled(Link)`
  display: none;
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 0.75rem;

  svg {
    width: 100%;
    height: 100%;

    circle {
      fill: ${props => props.theme.colors.greyBright};
    }

    path {
      fill: ${props => props.theme.colors.greyMedium};
    }
  }
`;

const Balance = styled.span`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 1.25rem 0;
  line-height: 1.2;

  @media ${DEVICE.tablet} {
    margin: 0.5rem 0;
  }
`;

const Currency = styled.span`
  font: ${props => props.theme.fonts.h4};
  margin: 0 0.5rem;

  @media ${DEVICE.tablet} {
    font: ${props => props.theme.fonts.h2};
  }

  @media ${DEVICE.desktop} {
    font: ${props => props.theme.fonts.h1};
  }
`;

const Decimal = styled.span`
  font: ${props => props.theme.fonts.bodyLBold};
  
  @media ${DEVICE.tablet} {
    font-size: 1.75rem;
  }

  @media ${DEVICE.desktop} {
    font-size: 2rem;
  }
`;

const Flag = styled(CircleFlag)`
  border: 0.0625rem solid ${props => props.theme.colors.greyBright};
  border-radius: 50%;
  height: 1.875rem;

  @media ${DEVICE.tablet} {
    height: 2rem;
  }

  @media ${DEVICE.desktop} {
    margin-top: 0.25rem;
  }
`;

const Header = styled.header`
  padding: 0 1.25rem;

  @media ${DEVICE.tablet} {
    padding: 0;
  }
`;

const HeaderInner = styled.div`
  align-items: center;
  display: flex;
  width: 100%;

  @media ${DEVICE.tablet} {
    display: none;
  }
`;

const HeaderWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 0 1.25rem 2.625rem 0;

  @media ${DEVICE.tablet} {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 0 2.625rem;
  }
`;

const Title = styled(TitleComponent)`
  font-size: 1.375rem;
  margin: 1.875rem 0 0;
  padding: 0 1.25rem;

  @media ${DEVICE.tablet} {
    margin: 2.5rem 0 1.25rem;
    padding: 0;
  }
`;

const Container = styled.div`
  padding: 1.25rem;

  @media ${DEVICE.tablet} {
    padding: 1.25rem 0;
  }
`;

const Note = styled(ParagraphComponent)`
  color: ${props => props.theme.colors.greyMedium};
  margin-top: ${props => props.marginTop ? '1.25rem' : 0};
  margin-bottom: 0.5rem;
`;

const Section = styled.div`
  margin-top: 1.4375rem;
`;

const Subtitle = styled(ParagraphComponent)`
  color: ${props => props.theme.colors.greyMedium};
  font: ${props => props.theme.fonts.bodyMSemi};
  margin-bottom: 0.3125rem;
`;

const Paragraph = styled(ParagraphComponent)`
  align-items: center;
  display: flex;
  font: ${props => props.theme.fonts.bodyM};
`;

const AccountTitle = styled.h2`
  color: ${props => props.theme.colors.greyMedium};
  font: ${props => props.theme.fonts.bodyL};
  line-height: 1.2;
  margin: -0.625rem 0 0 0;
  text-align: center;
  width: 100%;
`;

const TabsHead = styled(Tabs.Head)`
  margin: 0 0 0 1.1rem;
  padding: 0 0 0.8125rem 0;

  @media ${DEVICE.tablet} {
    margin: 0;
  }
`;

const TabWrapper = styled.div`
  ${props => props.showFilterBar && `
    [role='presentation']:last-of-type {
      margin: auto 0 auto auto;
    }`
  };
`;

const CopyButtonWrapper = styled.div`
  margin-top: 1.875rem;
`;

const CurrencyOption = styled(IntergiroCurrencyOption)`
  .select__single-value & {
    [data-testid='currency-option-icon-wrapper'],
    [data-testid='currency-option-bottom-line-wrapper'] {
      display: none;
    }

    [data-testid='currency-option-name'] {
      font: ${props => props.theme.fonts.bodyMBold};
      line-height: 1.2;
    }
  }
`;

const SubHeading = styled.h3`
  color: ${props => props.theme.colors.black};
  font: ${props => props.theme.fonts.bodyLSemi};
  letter-spacing: 0.02em;
  line-height: 1.2;
  margin-top: 0.625rem;


  @media ${DEVICE.tablet} {
    margin-top: 1.375rem;
  }
`;

const Button = styled(IntergiroButton)`
  margin-top: 3.25rem;
  width: 100%;

  @media ${DEVICE.tablet} {
    margin-top: 1.875rem;
    max-width: 11.25rem;
  }
`;

const TabsWrapper = styled.div`
	position: relative;
`;

const AccountName = styled.p`
  margin: 0.125rem 0 0;
  color: ${props => props.theme.colors.black};
  font: ${props => props.theme.fonts.bodyMSemi};
`;

export default {
  AccountName,
  Amount,
  Button,
  BackButton,
  CopyButtonWrapper,
  CurrencyOption,
  Balance,
  Container,
  Currency,
  Decimal,
  Flag,
  Header,
  HeaderInner,
  HeaderWrapper,
  Note,
  Paragraph,
  Section,
  Subtitle,
  SubHeading,
  TabsHead,
  Title,
  TabWrapper,
  AccountTitle,
  TabsWrapper,
};
