import { Avatar as DefaultAvatar } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { ReactComponent as MastercardIcon } from 'assets/icons/mastercard.svg';
import { ReactComponent as VisaIcon } from 'assets/icons/visa.svg';
import { hex2rgba } from 'helpers/styles';

const Card = styled.div`
  display: grid;
  grid-template-columns: 2fr [user] 2fr [card-name] 2fr [account] 1fr [last-four] 1fr [expiration] 2fr [brand] 2fr [balance] 2fr [status];
  align-items: center;
  padding: 0.875rem 1.25rem;
  background: ${props => (props.dimmed ? props.theme.colors.greyBright : props.theme.colors.white)};
  box-shadow: 0 0.3125rem 1rem ${props => hex2rgba(props.theme.colors.black, 0.05)},
    inset 0 0 0.0625rem ${props => hex2rgba(props.theme.colors.black, 0.3)};
  border-radius: 0.5rem;
  margin: 0.625rem 0;
`;

const BalanceContainer = styled.div`
  margin: 0;
  padding: 0;
  text-align: right;
  color: ${props => (props.dimmed ? props.theme.colors.greyMedium : props.theme.colors.black)};
  font: ${props => props.theme.fonts.bodyS};
`;

const BalanceAmount = styled.p`
  font-weight: 600;
  display: inline-block;
  margin: 0;
`;

const BalanceCurrency = styled.p`
  display: inline-block;
  margin: 0;
`;

const Account = styled.p`
  margin: 0;
  color: ${props => props.theme.colors.greyMedium};
  font: ${props => props.theme.fonts.bodyS};
`;

const Brand = styled.div`
  margin: 0;
  display: flex;
  align-items: center;
  justify-self: end;
`;

const FundingType = styled.p`
  margin: 0;
  padding-right: 0.625rem;
  color: ${props => props.dimmed ? props.theme.colors.greyMedium : props.theme.colors.black};
  font: ${props => props.theme.fonts.bodyS};
`;

const LastFour = styled.div`
  color: ${props => (props.dimmed ? props.theme.colors.greyMedium : props.theme.colors.black)};
  font: ${props => props.theme.fonts.bodySSemi};
  margin: 0;
  text-align: right;
  padding-right: 1.875rem;
`;

const Text = styled.p`
  margin: 0;
  color: ${props => (props.dimmed ? props.theme.colors.greyMedium : props.theme.colors.black)};
  font: ${props => props.theme.fonts.bodySSemi};
`;

const Visa = styled(VisaIcon)`
  fill: #15195a;
  width: 2.5rem;
  height: 0.8125rem;
`;

const Mastercard = styled(MastercardIcon)`
  width: 2rem;
  height: 1.25rem;
`;

const Expiry = styled.p`
  margin: 0;
  color: ${props => (props.dimmed ? props.theme.colors.greyMedium : props.theme.colors.black)};
  font: ${props => props.theme.fonts.bodyS};
`;

const Status = styled.div`
  margin: 0;
  text-align: right;
`;

const User = styled.div`
  margin: 0;
  display: flex;
  align-items: center;
`;

const Avatar = styled(DefaultAvatar)`
  border-radius: 50%;
  flex-shrink: 0;
  height: 1.5rem;
  margin-right: 0.3125rem;
  opacity: ${props => (props.dimmed ? '0.5' : '1')};
  overflow: hidden;
  width: 1.5rem;

  > svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  > p {
    top: 50%;
    font-size: 0.625rem;
  }
`;

export default {
  Card,
  BalanceContainer,
  BalanceAmount,
  BalanceCurrency,
  Account,
  Brand,
  FundingType,
  LastFour,
  Visa,
  Expiry,
  Mastercard,
  Status,
  Text,
  User,
  Avatar,
};
