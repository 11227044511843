import { array, object, string } from 'yup';

import i18n from 'config/i18n';

import { unmanageableLabels } from './constants';

const accountMetadataSchema = object().shape({
  name: string()
    .min(1, i18n.t('accounts.renameAccountModal.validation.name.minLength'))
    .max(50, i18n.t('accounts.renameAccountModal.validation.name.maxLength'))
    .matches(/^[\w\p{L}\p{Extended_Pictographic}\s]+$/gu, {
      excludeEmptyString: true,
      message: i18n.t('accounts.renameAccountModal.validation.name.chars'),
    })
    .required(i18n.t('accounts.renameAccountModal.validation.name.required')),

  labels: array()
    .of(
      object().shape({
        value: string()
          .min(1, i18n.t('accounts.renameAccountModal.validation.labels.minLength'))
          .max(30, i18n.t('accounts.renameAccountModal.validation.labels.maxLength'))
          .matches(/^[\w\p{L}\s]+$/gu, {
            excludeEmptyString: true,
            message: i18n.t('accounts.renameAccountModal.validation.labels.chars'),
          })
          .notOneOf(unmanageableLabels, i18n.t('accounts.renameAccountModal.validation.labels.alreadyExists')),
      }),
    )
    .max(4, i18n.t('accounts.renameAccountModal.validation.labels.maxCount')),
});

export default accountMetadataSchema;
