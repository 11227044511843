export const userStatusesMap = {
  notInvited: 'not_invited',
  invited: 'invited',
  dropped: 'dropped',
  rejected: 'rejected',
  activated: 'activated',
  blocked: 'blocked',
  offboarded: 'offboarded',
};

export const userStatusesLabels = {
  [userStatusesMap.notInvited]: 'Not invited',
  [userStatusesMap.invited]: 'Invited',
  [userStatusesMap.dropped]: 'Dropped',
  [userStatusesMap.rejected]: 'Rejected',
  [userStatusesMap.activated]: 'Activated',
  [userStatusesMap.blocked]: 'Blocked',
  [userStatusesMap.offboarded]: 'Offboarded',
};

export const activatedUserStatuses = [
  userStatusesMap.activated,
  userStatusesMap.blocked,
];
