import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import dayjs from 'dayjs';

import { ReactComponent as ThumbsDownIcon } from 'assets/icons/thumbs-down.svg';
import { ReactComponent as ThumbsUpIcon } from 'assets/icons/thumbs-up.svg';
import { ModalContent, SwitchBlock } from 'components';
import { approvalActions } from 'constants/api';
import { defaultDateTimeFormat } from 'constants/date-time';
import { formatAmount } from 'utils/amount';

import S from './styles';

const titleActionMap = {
  [approvalActions.approve]: 'Approve payment?',
  [approvalActions.reject]: 'Reject payment?',
  [approvalActions.bulkApprove]: 'Approve multiple payments?',
  [approvalActions.bulkReject]: 'Reject multiple payments?',
  [approvalActions.approveApiPayment]: 'Approve API payment?',
  [approvalActions.rejectApiPayment]: 'Reject API payment?',
};

const buttonLabelActionMap = {
  [approvalActions.approve]: 'Approve',
  [approvalActions.reject]: 'Reject',
  [approvalActions.bulkApprove]: 'Approve',
  [approvalActions.bulkReject]: 'Reject',
  [approvalActions.approveApiPayment]: 'Approve',
  [approvalActions.rejectApiPayment]: 'Reject',
};

const buttonIconActionMap = {
  [approvalActions.approve]: <ThumbsUpIcon />,
  [approvalActions.reject]: <ThumbsDownIcon />,
  [approvalActions.bulkApprove]: <ThumbsUpIcon />,
  [approvalActions.bulkReject]: <ThumbsDownIcon />,
  [approvalActions.approveApiPayment]: <ThumbsUpIcon />,
  [approvalActions.rejectApiPayment]: <ThumbsDownIcon />,
};

const trustedTitleActionMap = {
  [approvalActions.approve]: 'Trust this beneficiary',
  [approvalActions.bulkApprove]: 'Trust these beneficiaries',
  [approvalActions.approveApiPayment]: 'Trust these beneficiaries',
};

const trustedText = 'We won’t ask for authentication for future payments to this account';

function ResolveModal({ isOpen, setIsOpen, onSubmit, action, data, showTrustBeneficiaryToggle }) {
  const [ isTrusted, setIsTrusted ] = useState(false);

  const handleSwitchChange = () => {
    setIsTrusted(!isTrusted);
  };

  const handleConfirm = useCallback(() => {
    const params = { isTrusted };

    onSubmit(params);
    setIsOpen(false);
  }, [isTrusted]);

  const handleCancel = useCallback(() => {
    setIsOpen(false);
  }, []);

  const CTAButtons = (
    <>
      <S.Button
        data-testid="resolve-button"
        label={buttonLabelActionMap[action]}
        icon={buttonIconActionMap[action]}
        category="primary"
        onClick={handleConfirm}
      />
      <S.Button
        label="Cancel"
        category="secondary"
        onClick={handleCancel}
      />
    </>
  );

  return (
    <ModalContent
      title={titleActionMap[action]}
      type="message"
      isOpen={isOpen}
      CTAButtons={CTAButtons}
      setIsOpen={setIsOpen}
    >
      {data && action === approvalActions.approve && (
        <>
          <S.SubTitle>
            -
            {' '}
            {formatAmount(data.amount)}
            {' '}
            {data.currency}
          </S.SubTitle>
          <S.DetailsContainer marginBottom={showTrustBeneficiaryToggle}>
            <S.DetailsRow>
              <S.Label>Created by</S.Label>
              {data.createdByAuthor}
            </S.DetailsRow>
            <S.DetailsRow>
              <S.Label>Date</S.Label>
              {dayjs(data.createdAt).format(defaultDateTimeFormat)}
            </S.DetailsRow>
          </S.DetailsContainer>
        </>
      )}
      {showTrustBeneficiaryToggle && (
        <SwitchBlock
          title={trustedTitleActionMap[action]}
          titleIcon={<S.TrustedIcon />}
          text={trustedText}
          checked={isTrusted}
          onChange={handleSwitchChange}
        />
      )}
    </ModalContent>
  );
}

ResolveModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  data: PropTypes.shape({
    amount: PropTypes.number,
    currency: PropTypes.string,
    createdByAuthor: PropTypes.string,
    createdAt: PropTypes.string,
  }),
  action: PropTypes.oneOf([
    approvalActions.approve,
    approvalActions.reject,
    approvalActions.bulkApprove,
    approvalActions.bulkReject,
    approvalActions.rejectApiPayment,
    approvalActions.approveApiPayment,
  ]).isRequired,
  showTrustBeneficiaryToggle: PropTypes.bool,
};

export default ResolveModal;
