import { Button } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right-grey.svg';
import { AccountName, CancelButton as Cancel, TotalBlock } from 'components';
import { DEVICE } from 'constants/breakpoints';

const Title = styled.p`
  margin: 0 0 1.625rem 0;
  font: ${props => props.theme.fonts.bodyM};

  @media ${DEVICE.tablet} {
    font: ${props => props.theme.fonts.h3Bold};
    margin-bottom: 2.5rem;
  }
`;

const AmountAndAccountContainer = styled.div`
  display: flex;
  align-items: center;
  font: ${props => props.theme.fonts.bodyLBold};
  flex-wrap: wrap;

  @media ${DEVICE.tablet} {
    font: ${props => props.theme.fonts.h3Bold};
    flex-wrap: nowrap;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 1.875rem;
  margin-bottom: 1.875rem;

  @media ${DEVICE.tablet} {
		flex-direction: row;
    margin-bottom: 3.75rem;
    margin-top: 0;
	}
`;

const ArrowRight = styled(ArrowRightIcon)`
  margin: 0 1rem;
  
  path {
    fill: ${props => props.theme.colors.greyLight};
  }
`;

const Amount = styled.p`
  margin: 0;
`;

const Account = styled(AccountName)`
  flex-basis: 100%;
  margin-top: 1rem;

  @media ${DEVICE.tablet} {
    flex-basis: unset;
    margin-top: 0;
  }
`;

const TotalContainerRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${DEVICE.laptop} {
    width: 32vw;
  }
`;

const Label = styled.p`
  margin: 0;
  color: ${props => props.theme.colors.greyMedium};
  font: ${props => props.theme.fonts.bodyM};
  padding: 1rem 0;

  @media ${DEVICE.laptop} {
    font: ${props => props.theme.fonts.bodyL};
    padding: 1rem 0;
  }
`;

const TotalText = styled.p`
  font: ${props => props.theme.fonts.bodyMBold};
  margin: 0;
  padding: 1rem 0;
`;

const CancelButton = styled(Cancel)`
  display: block;
  width: 100%;

  @media ${DEVICE.tablet} {
    display: none;
  }
`;

const MoveFundsButton = styled(Button)`
  width: 100%;
  margin-bottom: 0.9375rem;

  @media ${DEVICE.tablet} {
    width: inherit;
  }
`;

const TotalContainer = styled(TotalBlock)`
  margin-top: 0;
  margin-bottom: 0;

  @media ${DEVICE.tablet} {
    max-width: 30rem;
    width: 100%;
  }
`;

export default {
  Amount,
  AmountAndAccountContainer,
  ArrowRight,
  CancelButton,
  Label,
  MoveFundsButton,
  Row,
  Title,
  TotalContainer,
  TotalContainerRow,
  TotalText,
  Account,
};
