import { Tabs } from '@general/intergiro-ui-kit';
import isEmpty from 'lodash/isEmpty';
import React, { Fragment } from 'react';

import { useCompanyDetails } from 'hooks';

import CompanyDetails from './components/company-details';
import S from './styles';

function Company() {
  const { companyDetails } = useCompanyDetails();

  function renderTab() {
    if (isEmpty(companyDetails)) return null;

    return <CompanyDetails data={companyDetails} />;
  }

  return (
    <>
      <S.Header>
        <S.Title>
          {companyDetails.name}
        </S.Title>
      </S.Header>
      <div>
        <Tabs.Container name="tabs">
          <Tabs.Head id="company-details" isActive>
            Company details
          </Tabs.Head>
        </Tabs.Container>
      </div>
      <Tabs.Panel>
        <Tabs.Panel>
          {renderTab()}
        </Tabs.Panel>
      </Tabs.Panel>
    </>
  );
}

export default Company;
