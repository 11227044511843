import capitalize from 'lodash/capitalize';

import { formatPhone } from 'utils/format';

export function mapUserDetails(user) {
  return {
    ...user,
    roles: mapRoles(user.roles),
    ownership: user.ownership && `${user.ownership}%`,
    gender: user.gender && capitalize(user.gender),
    otherPhone: user.otherPhone && formatPhone(user.otherPhone),
  };
}

// Private functions

function mapRoles(roles = []) {
  return roles.map(role => role.name).join(', ');
}
